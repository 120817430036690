import React, { useMemo } from "react";
import Table from "@/common/components/Table";
import { getHeadersArray } from "./helpers/getHeadersArray";
import { getRaws } from "./helpers/getRaws";
import { generateFiles } from "./helpers/generateFiles";
import moment from "moment";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { triggerDocs } from "@/API/repositories/mailing-report";
import Loading from "@/common/components/Loading";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { DOC_BLOCKERS } from "@/common/constants/docBlockers";

const ShortInfoDataTable = ({ data, month, loadDocsJobs, docsJobs }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSend = async () => {
    const fileData = await generateFiles(data, moment(month).format("MM-YYYY"));

    const payload = {
      files: [
        {
          ...fileData,
        },
      ],
      month: moment(month).format("MM.YYYY"),
      unlock_blocker: DOC_BLOCKERS.PAYMENT,
      market_short: data.name.split("_")[0].toLowerCase(),
    };

    const response = await makeRequest(triggerDocs.bind(null, payload));

    if (!response?.data) {
      return addMessage("Wystąpił błąd podczas wysyłania plików", "error");
    }

    addMessage("Pliki zostały wysłane", "success");
    loadDocsJobs();
  };

  const reportConfirmed = useMemo(() => {
    const docsJob =
      docsJobs?.[
        `${moment(month).format("MM.YYYY")}_${data.name
          .split("_")[0]
          .toLowerCase()}`
      ];

    if (!docsJob) {
      return;
    }

    if (!docsJob?.blocker_condition?.payment_kdr_block) {
      return docsJob;
    }

    return {};
  }, [docsJobs, month, data]);

  return (
    <>
      {hasUnfilledRequest(triggerDocs) && <Loading />}
      <Table
        className="styled-table"
        headersArray={getHeadersArray(data, handleSend, reportConfirmed)}
        raws={getRaws(data)}
      />
    </>
  );
};

export default ShortInfoDataTable;
