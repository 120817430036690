import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");
const { axios: api } = Api;

const PREFIX = "valid-claim";

export const createValidClaim = (validClaim) =>
  errorHandler(async () => {
    const data = await api.post(`/${PREFIX}/create`, validClaim);
    return { data: data.data };
  });

export const updateClaimCommentForAccounting = (validClaim) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/${PREFIX}/update-accounting-comment/${validClaim._id}`,
      validClaim
    );
    return { data: data.data };
  });

export const getValidClaims = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/${PREFIX}/get?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );

    return { data: data.data };
  });

export const updateValidClaim = (id, paylaod) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/update/${id}`, paylaod);

    return { data: data.data };
  });

export const payValidClaim = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/pay/${id}`, payload);

    return { data: data.data };
  });

export const cancelValidClaim = (id) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/cancel/${id}`);

    return { data: data.data };
  });

export const addFileToValidClaim = (claimId, formData) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/add-file/${claimId}`, formData);

    return { data: data.data };
  });

export const convertBankDataToIBAN = (payload) =>
  errorHandler(async () => {
    const data = await api.post(
      `/${PREFIX}/convert-bank-data-to-iban`,
      payload
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
