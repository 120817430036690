import { ROLE_VALUES } from "@/pages/admin/user/components/userForm/constants";

export const BANK_ACCOUNT_COUNTRIES = ["cz", "hu", "lt"];
export const IBAN_COUNTRIES = ["ro", "sk", "hr"];

export const INTERNAL_STATUSES = {
  TO_DO: "to_do",
  DONE: "done",
};

export const CLAIM_STATUSES = {
  NONE: {
    value: "none",
    label: "None",
    allow_from: ["none"],
    from_roles_blocked: [],
    priority: 0,
  },
  TO_PAY: {
    value: "to_pay",
    label: "To pay",
    allow_from: ["to_pay", "correct_in_progress"],
    from_roles_blocked: [ROLE_VALUES.FINANCE_ANALYST],
    priority: 4,
  },
  TO_CORRECT: {
    value: "to_correct",
    label: "To correct",
    message_required: true,
    allow_from: ["to_pay", "to_correct", "check_in_progress"],
    from_roles_blocked: [ROLE_VALUES.SUPPORT],
    priority: 5,
  },
  CORRECT_IN_PROGRESS: {
    value: "correct_in_progress",
    label: "Correct in progress",
    allow_from: ["to_correct", "correct_in_progress"],
    from_roles_blocked: [ROLE_VALUES.FINANCE_ANALYST],
    priority: 6,
  },
  TO_CHECK: {
    value: "to_check",
    label: "To check",
    message_required: true,
    allow_from: ["paid"],
    from_roles_blocked: [ROLE_VALUES.FINANCE_ANALYST],
    priority: 2,
  },
  CHECK_IN_PROGRESS: {
    value: "check_in_progress",
    label: "Check in progress",
    allow_from: ["to_check", "check_in_progress"],
    from_roles_blocked: [ROLE_VALUES.SUPPORT],
    priority: 3,
  },
  PAID_IN_PROGRESS: {
    value: "paid_in_progress",
    label: "Paid in progress",
    allow_from: ["paid_in_progress"],
    from_roles_blocked: [],
    priority: 1,
  },
  PAID: {
    value: "paid",
    label: "Paid",
    allow_from: ["check_in_progress", "paid"],
    from_roles_blocked: [ROLE_VALUES.SUPPORT],
    priority: 0,
  },
};

export const CLAIM_ROLE_STATUSES = {
  [ROLE_VALUES.FINANCE_ANALYST]: {
    TO_DO: {
      value: INTERNAL_STATUSES.TO_DO,
      label: "To do",
      claim_statuses: [
        CLAIM_STATUSES.TO_PAY,
        CLAIM_STATUSES.TO_CHECK,
        CLAIM_STATUSES.CHECK_IN_PROGRESS,
        CLAIM_STATUSES.PAID_IN_PROGRESS
      ],
    },
    DONE: {
      value: INTERNAL_STATUSES.DONE,
      label: "Done",
      claim_statuses: [
        CLAIM_STATUSES.PAID,
      ],
    }
  },
  [ROLE_VALUES.SUPPORT]: {
    TO_DO: {
      value: INTERNAL_STATUSES.TO_DO,
      label: "To do",
      claim_statuses: [
        CLAIM_STATUSES.TO_CORRECT,
        CLAIM_STATUSES.CORRECT_IN_PROGRESS,
      ],
    },
    DONE: {
      value: INTERNAL_STATUSES.DONE,
      label: "Done",
      claim_statuses: [
        CLAIM_STATUSES.PAID,
      ],
    }
  },
  [ROLE_VALUES.ADMIN]: {
    TO_DO: {
      value: INTERNAL_STATUSES.TO_DO,
      label: "To do",
      claim_statuses: [
        CLAIM_STATUSES.TO_CORRECT,
        CLAIM_STATUSES.CORRECT_IN_PROGRESS,
        CLAIM_STATUSES.TO_PAY,
        CLAIM_STATUSES.TO_CHECK,
        CLAIM_STATUSES.CHECK_IN_PROGRESS,
        CLAIM_STATUSES.PAID_IN_PROGRESS,
      ],
    },
    DONE: {
      value: INTERNAL_STATUSES.DONE,
      label: "Done",
      claim_statuses: [
        CLAIM_STATUSES.PAID,
      ],
    }
  }
};
