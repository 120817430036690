import { findMonthInfoWithPayoutSlips } from "@/API/repositories/consultantHours";
import { Colors } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import SelectInput from "@/common/components/SelectInput";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useRef, useState } from "react";
import { FilterWrapper, SearchBar } from "./payoutSlip.styles";
import AddPayoutSlip from "./components/AddPayoutSlip";
import PayoutSlipTable from "./components/PayoutSlipTable";
import Input from "@/common/components/Input";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import {
  getFileById,
  saveConsultantPdfToAws,
} from "@/API/repositories/storedDocument";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const PayoutSlip = () => {
  const fileRef = useRef();
  const [data, setData] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [addPayoutSlip, setAddPayoutSlip] = useState();
  const [filterRegex, setFilterRegex] = useState();

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const hanldeAddFile = async (e) => {
    e && e.preventDefault();

    if (!fileRef.current?.files[0]) return;

    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);
    formData.append("type", "payout_slip");
    formData.append("_id", addPayoutSlip._id);

    const payload = {};

    payload.type = "payout_slip";
    payload.month_info_id = addPayoutSlip._id;

    const response = await makeRequest(
      saveConsultantPdfToAws.bind(null, payload, formData)
    );

    if (response.data) {
      await hanldeSearch();
      setAddPayoutSlip(() => null);
      addMessage("File saved", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 2) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  const hanldeSearch = async () => {
    const payload = {};
    payload.month = selectedMonth.value.toISOString();

    const response = await makeRequest(
      findMonthInfoWithPayoutSlips.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  const { addMessage } = useMessageQueueContext();

  useEffect(() => {
    setData(() => []);
  }, [selectedMonth]);

  return (
    <>
      <WrapperWithScroll>
        {hasUnfilledRequest(
          findMonthInfoWithPayoutSlips,
          saveConsultantPdfToAws,
          getFileById
        ) && <Loading />}
        <SearchBar>
          <FilterWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SelectInput
                name="Month"
                options={LAST_YEAR_MONTHS}
                color={Colors.darkBlue}
                width={50}
                setSelected={setSelectedMonth}
                selected={selectedMonth}
              />
              <Input
                width={80}
                inputWidth={120}
                onChange={(e) => handleChangeRegex(e)}
                name="Includer"
                color={Colors.darkBlue}
              />
              <div>
                <ActionButton
                  onClick={(e) => hanldeSearch(e)}
                  defaultText="Search"
                />
              </div>
            </div>
          </FilterWrapper>
        </SearchBar>
        {data[0] && (
          <PayoutSlipTable
            filterRegex={filterRegex}
            data={data}
            handleDownloadFile={handleDownloadFile}
            setAddPayoutSlip={setAddPayoutSlip}
          />
        )}
      </WrapperWithScroll>
      {addPayoutSlip && (
        <AddPayoutSlip
          setAddPayoutSlip={setAddPayoutSlip}
          fileRef={fileRef}
          hanldeAddFile={hanldeAddFile}
        />
      )}
    </>
  );
};

export default PayoutSlip;
