import { calcWorkingHours } from "@/common/functions/calcWorkingHours";
import moment from "moment";

export const getExcelBodyForHoursReport = (
  data,
  selectedConsultant,
  consultants,
  workingFromDate,
  workingToDate,
  filterRegex,
  headers
) => {
  return data
    .sort((a, b) => a._id.localeCompare(b._id))
    .filter((e) =>
      filterRegex
        ? (e?._month_working_info?.contract_type || e?._user?.contract_type)
          ?.toLowerCase()
          .includes(filterRegex.toLowerCase())
        : true
    )
    .filter((c) => selectedConsultant.map((c1) => c1.value).includes(c._id))
    .map((consultantData, i) => ({
      [headers[0]]: i + 1 + ".",
      [headers[1]]:
        consultants.find((c) => c.value === consultantData._id)?.label ||
        "------",
      [headers[2]]: consultantData._month_working_info?.month_hours || "------",
      [headers[3]]:
        (consultantData._month_working_info?.working_from &&
          moment(consultantData._month_working_info?.working_from).format(
            "YYYY-MM-DD"
          )) ||
        workingFromDate,
      [headers[4]]:
        (consultantData._month_working_info?.working_to &&
          moment(consultantData._month_working_info?.working_to).format(
            "YYYY-MM-DD"
          )) ||
        workingToDate,
      [headers[5]]: calcWorkingHours(consultantData.data),
      [headers[6]]:
        consultantData.data.reduce(
          (prev, next) => prev + next.attendance_at_work + next.manager_hours,
          0
        ) || 0,
      [headers[7]]:
        consultantData.data.reduce(
          (prev, next) => prev + next.holiday_leave,
          0
        ) || 0,
      [headers[8]]:
        consultantData.data.reduce(
          (prev, next) => prev + (next.special_leave || 0),
          0
        ) || 0,
      [headers[9]]:
        consultantData.data.reduce((prev, next) => prev + next.sick_leave, 0) ||
        0,
      [headers[10]]:
        consultantData.data.reduce(
          (prev, next) => prev + next.excused_unpaid_leave,
          0
        ) || 0,
      [headers[11]]:
        consultantData.data.reduce(
          (prev, next) => prev + next.absence_without_excuse,
          0
        ) || 0,
    }));
};
