import { formatToDateTamplate } from "@/common/functions/dateFormater";
import moment from "moment";

export const getRaws = (data) => {
  const raws = data.map((claim, idx) => (
    <tr key={claim._id}>
      <td>{idx + 1}</td>

      <td>{claim.market.name}</td>

      <td>{moment(claim.transfers.at(-1).initiated_at).format("YYYY-MM")}</td>

      <td>
        {formatToDateTamplate(
          claim.order.shipping.status_delivered_at ||
            claim.order.shipping.status_delivered_no_payment_at
        )}
      </td>

      <td>{formatToDateTamplate(claim.transfers.at(-1).initiated_at)}</td>

      <td>{claim.transfers.at(-1).full_name}</td>

      <td>
        {claim.transfers.at(-1).amount} {claim.transfers.at(-1).currency}
      </td>

      <td>
        {claim.transfers.at(-1).original_amount
          ? `${claim.transfers.at(-1).original_amount} ${
              claim.transfers.at(-1).original_currency
            }`
          : "----"}
      </td>
      <td>
        {claim.correction_invoice?.valid_invoice.correction_number || "----"}
      </td>
      <td>{claim.comment_for_accounting || "----"}</td>
    </tr>
  ));

  const totals = {};

  data.forEach((claim) => {
    const currency = claim.transfers.at(-1).currency;
    const amount = claim.transfers.at(-1).amount;
    const originalAmount = claim.transfers.at(-1).original_amount;
    const originalCurrency = claim.transfers.at(-1).original_currency;

    if (currency in totals) {
      totals[currency].amount += amount;
      if (originalAmount) {
        totals[currency].originalAmount += originalAmount;
      }
    } else {
      totals[currency] = {
        amount,
        originalAmount,
        originalCurrency,
      };
    }
  });

  const totalRow = (
    <tr>
      <td style={{ fontSize: "16px", textAlign: "left" }} colSpan="10">
        Sum:{" "}
        {Object.entries(totals)
          .map(([currency, { amount, originalAmount, originalCurrency }]) => {
            const originalAmountText = originalAmount
              ? ` - ${originalAmount.toFixed(2)} ${originalCurrency}`
              : "";
            return `${amount.toFixed(2)} ${currency}${originalAmountText}`;
          })
          .join(", ")}
      </td>
    </tr>
  );

  raws.push(totalRow);

  return raws;
};
