import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  gap: 50px;
  padding: 20px 50px 50px 50px;
  overflow: auto;
`;

export const TopViewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 30px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;
