import React, { useContext } from "react";
import { RealtimeContext } from "../context/RealtimeContext";
import {
  WraptimeNow,
  TalkingNow,
  CallingNow,
  IncomingCalls,
  WorkingData,
  BreakNow,
  LoginNow
} from "./realtime";
import { ActionWrapper, ElementsWrapper, UserWrapper } from "./Users.styled";

const Users = () => {
  const { data, incomingCalls, workingData } = useContext(RealtimeContext);

  return (
    <>
      <UserWrapper />
      <ActionWrapper>
        <ElementsWrapper>
          <WraptimeNow data={data.wraptime} />
          <TalkingNow data={data.talking} />
          <CallingNow data={data.calling} />
          <IncomingCalls data={incomingCalls} />
        </ElementsWrapper>
        <ElementsWrapper>
          <WorkingData workingData={workingData} />
          <BreakNow data={data.break} />
          <LoginNow data={data.login} />
        </ElementsWrapper>
      </ActionWrapper>
    </>
  );
};

export default Users;
