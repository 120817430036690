import Input from "@/common/components/Input";
import Loading from "@/common/components/Loading";
import SelectInput from "@/common/components/SelectInput";
import { SearchBarRaw } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import React, { useRef, useState } from "react";
import { ButtonWrapper } from "./SuspicionReport.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getSuspicions } from "@/API/repositories/reports";
import SuspicionTable from "./components/suspicionTable/SuspicionTable";
import userManager from "@/API/userManager";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import { filterQueueOptionsForManager } from "./components/suspicionTable/helpers/filterQueueOptionsForManager";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const SuspicioinReport = () => {
  const [selectedQueues, setSelectedQueues] = useState();
  const [suspicions, setSuspicions] = useState();

  const user = userManager.getUser();
  const isConsultantManager = CONSULTANT_MANAGER_ROLES.includes(
    user.role?.toUpperCase()
  );

  const fromRef = useRef();
  const toRef = useRef();

  const {
    commonData: { users },
    options: { queuesOptions },
  } = useCommonDataContext();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSearch = async () => {
    const from = fromRef.current?.value;
    const to = toRef.current?.value;

    if (!selectedQueues?.length || !from || !to) {
      return addMessage("Select all data", "error");
    }

    const payload = {};

    payload.from = from;
    payload.to = to;
    payload.queues = selectedQueues.map((c) => c.value._id);

    const response = await makeRequest(getSuspicions.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    if (!response?.data?.length) {
      return addMessage("Not found");
    }

    setSuspicions(() => response.data);
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getSuspicions) && <Loading />}
      <SearchBarRaw>
        <div>
          <Input
            inputRef={fromRef}
            name="From"
            type="date"
            color={Colors.darkBlue}
            width={30}
            inputWidth={150}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="date"
            color={Colors.darkBlue}
            width={30}
            inputWidth={150}
          />
        </div>
        <div>
          <SelectInput
            name="Queues"
            options={
              isConsultantManager
                ? filterQueueOptionsForManager(queuesOptions, users, user.id)
                : queuesOptions
            }
            color={Colors.darkBlue}
            width={140}
            setSelected={setSelectedQueues}
            selected={selectedQueues}
            multiple
            selectWidth={400}
          />
        </div>
        <ButtonWrapper>
          <ActionButton
            onClick={() => handleSearch()}
            defaultText="Search"
          />
        </ButtonWrapper>
      </SearchBarRaw>
      {suspicions && <SuspicionTable suspicions={suspicions} />}
    </WrapperWithScroll>
  );
};

export default SuspicioinReport;
