import React, { useEffect, useMemo } from "react";
import {
  ButtonWrapper,
  ScoringWrapper,
} from "../consultantManagerScoring.styles";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createListeningScoring } from "@/API/repositories/listeningScoring";
import { preventFn } from "@/common/functions/preventEvent";
import TextArea from "@/common/components/TextArea";
import SelectInput from "@/common/components/SelectInput";
import { SCORING_OPTIONS } from "@/common/constants/scoringOptions";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const ScoringCreate = ({
  setCurrentScore,
  currentScore,
  handleLoadData,
  setToScore,
  showEditCurrent,
  toScore,
}) => {
  const result = useMemo(() => ({}), []);

  const { makeRequest } = useRequestsContext();

  const handleChange = async (id, label, option) => {
    result[id] = { value: option.value, label };
  };

  const handleTextChange = async (text, id, label) => {
    result[id] = { value: text, label };
  };

  const handleRate = async (e) => {
    e && e.preventDefault();

    const paylod = {};
    paylod.calling_information = currentScore._id;
    paylod.scores = Object.entries(result).map(([key, value]) => ({
      score_id: key,
      grade: value.value,
      label: value.label,
    }));

    const response = await makeRequest(
      createListeningScoring.bind(null, paylod)
    );

    if (response.data) {
      await handleLoadData();
      setToScore((prev) => ({
        ...prev,
        generated: prev.generated.filter((p) => p._id !== currentScore._id),
      }));
      setCurrentScore(() => null);
    }
  };

  const mediaPlayer = useMemo(
    () => (
      <audio
        controlsList={"nodownload"}
        controls="controls"
        source
        src={[currentScore.url]}
        type="audio/mp3"
      />
    ),
    [currentScore.url]
  );

  useEffect(() => {
    document.addEventListener("contextmenu", preventFn);

    return () => document.removeEventListener("contextmenu", preventFn);
  }, []);

  return (
    <>
      <PopUp setShow={setCurrentScore}>
        <ScoringWrapper>
          {mediaPlayer}
          <form onSubmit={(e) => handleRate(e)}>
            <table className="styled-table">
              <thead>
                <tr>
                  <th colSpan={2}>
                    Rating Call - Yes = 1, No = 0 - Explain here if 0 or not
                    applicable (na)
                  </th>
                </tr>
                <tr>
                  <th>Description</th>
                  <th>Mark</th>
                </tr>
              </thead>
              <tbody className="queue">
                {toScore.table.map((element, i) => (
                  <tr key={element.value + i}>
                    <td>{element.label}</td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <SelectInput
                        showLabel={false}
                        selectWidth={160}
                        id={element.value}
                        required={true}
                        options={SCORING_OPTIONS}
                        setSelected={handleChange.bind(
                          this,
                          element.value,
                          element.label
                        )}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>Additional Info</td>
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    <TextArea
                      width="400px"
                      onChange={(e) =>
                        handleTextChange(
                          e.target.value,
                          "additional_info",
                          "Additional info"
                        )
                      }
                      id={"additional_info"}
                      defaultValue={
                        showEditCurrent?.scores?.find(
                          (s) => s.score_id === "additional_info"
                        )?.grade
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <ButtonWrapper>
              <SubmitButton text="Rate" />
            </ButtonWrapper>
          </form>
        </ScoringWrapper>
      </PopUp>
    </>
  );
};

export default ScoringCreate;
