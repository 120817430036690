import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import ListeningGeneratorCreate from "./ListeningGeneratorCreate";
import {
  createListeningGenerator,
  deleteListeningGenerator,
  getAllListeningGenerator,
  updateListeningGenerator,
} from "../../../API/repositories/listeningGenerator";
import ListeningGeneratorEdit from "./ListeningGeneratorEdit";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { Colors } from "@/common/colors/colors";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  gap: 50px;
`;

const ButtonCreareWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const MODE = {
  CREATE: "create",
  EDIT: "edit",
};

const LISTENERS_ROLES = ["call_center_manager", "head_cc_manager"];

const ListeningGeneratorList = () => {
  const [mode, setMode] = useState();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  const handleLoadData = async () => {
    const responseData = await makeRequest(getAllListeningGenerator);

    if (responseData.data) {
      setData(responseData.data);
    }
  };

  const handleDeleteConfig = async (id) => {
    const responseData = await makeRequest(
      deleteListeningGenerator.bind(null, id)
    );

    if (!responseData?.data) {
      return addMessage("Something went wrong!", "error");
    }

    addMessage("Success", "success");
    handleLoadData();
  };

  const { addMessage } = useMessageQueueContext();

  useEffect(() => {
    handleLoadData();
  }, [mode]);

  return (
    <>
      {hasUnfilledRequest([
        createListeningGenerator,
        getAllListeningGenerator,
        updateListeningGenerator,
      ]) && <Loading />}
      <Wrapper>
        <ButtonCreareWrapper>
          <ActionButton
            onClick={() => {
              setMode(() => MODE.CREATE);
            }}
            defaultText="Create"
          />
        </ButtonCreareWrapper>
        {data[0] && (
          <table className="styled-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Created at</th>
                <th>Manager</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data.map((d, i) => (
                <tr key={d.created_at}>
                  <td>{i + 1}.</td>
                  <td>{moment(d.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    {users.find((manager) => manager._id === d.manager)
                      ?.username || "----"}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        style={{ cursor: "pointer" }}
                        className="fa fa-edit"
                        onClick={() => {
                          setMode(() => MODE.EDIT);
                          setEdit(() => d);
                        }}
                      />
                      <i
                        style={{ cursor: "pointer", color: Colors.red }}
                        className="fa fa-trash"
                        onClick={() => handleDeleteConfig(d._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {mode === MODE.CREATE && (
          <ListeningGeneratorCreate
            managers={users
              .filter((d) => LISTENERS_ROLES.includes(d.role?.toLowerCase()))
              .map((u) => ({ label: u.username, value: u._id }))}
            setShow={setMode}
          />
        )}
        {mode === MODE.EDIT && (
          <ListeningGeneratorEdit
            managers={users
              .filter((d) => LISTENERS_ROLES.includes(d.role?.toLowerCase()))
              .map((u) => ({ label: u.username, value: u._id }))}
            users={users}
            setShow={setMode}
            edit={edit}
          />
        )}
      </Wrapper>
    </>
  );
};

export default ListeningGeneratorList;
