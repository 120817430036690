import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getSearchedContactWithOrders } from "../../../../API/repositories/contact";
import { Colors } from "../../../../common/colors/colors";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import PopUp from "../../../../common/components/PopUp";
import ContactView from "../ticket/ContactView/ContactView";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ErrorText = styled.h2`
  text-align: center;
  color: ${Colors.red};
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchBar = ({ handleEndCall }) => {
  const [showData, setShowData] = useState(null);
  const searchRef = useRef();
  const { makeRequest } = useRequestsContext();
  const [showContact, setShowContact] = useState();

  const { translation } = useTranslationContext();

  const handleKeyUp = (e) => {
    if (e.key === "Enter") handleSearch(e);
  };

  const handleSearch = async (e) => {
    console.group(searchRef.current.value);
    const searchData = searchRef.current.value;
    setShowData(() => null);
    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, searchData)
    );
    console.log(response?.data);
    if (response.data) {
      setShowData(() => response.data);
    }
  };

  return (
    <>
      <SearchBox>
        <input
          ref={searchRef}
          className="offInputStyle"
          type="text"
          style={{
            width: "300px",
            border: `1px solid ${Colors.darkBlue}`,
          }}
          onKeyUp={(e) => handleKeyUp(e)}
          placeholder={translation.placeholder}
        />
        <i
          className="fa fa-search animation-scale"
          onClick={(e) => handleSearch(e)}
          style={{
            cursor: "pointer",
            marginLeft: "-30px",
            color: Colors.darkBlue,
          }}
        ></i>
      </SearchBox>
      {showData && showData.length > 0 && (
        <PopUp setShow={setShowData}>
          <table className="styled-table" style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <th>{translation.no}</th>
                <th>{translation.number}</th>
                <th>{translation.email}</th>
                <th>{translation.full_name}</th>
                <th>{translation.action}</th>
              </tr>
            </thead>
            <tbody className="queue">
              {showData.map((contact, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{contact.phone_number}</td>
                  <td>{contact.email}</td>
                  <td>{contact.full_name}</td>
                  <td>
                    <i
                      className="fa fa-edit animation-scale"
                      onClick={() => {
                        setShowContact(() => contact);
                        setShowData(() => null);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PopUp>
      )}
      {showData && showData.length === 0 && (
        <PopUp setShow={setShowData}>
          <ErrorText>{translation["there_is_no_client"]}</ErrorText>
        </PopUp>
      )}
      {showContact && (
        <ContactView
          setContactView={setShowContact}
          contact={showContact._id}
          handleEndCall={handleEndCall}
        />
      )}
    </>
  );
};

export default SearchBar;
