import React from "react";
import { TabWrapper, TabsWrapper, Tab, RotatedText } from "./Tabs.styled";
import { TABS } from "../../constants/tabs";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Tabs = ({ selectedTab, setSelectedTab }) => {
  const { translation } = useTranslationContext();

  return (
    <TabsWrapper>
      <TabWrapper top={true} selected={selectedTab === TABS.user}>
        <Tab
          topLeft={true}
          bottomLeft={selectedTab === TABS.user}
          bottomRight={selectedTab !== TABS.user}
          onClick={() => setSelectedTab(TABS.user)}
          selected={selectedTab === TABS.user}
        >
          <RotatedText>{translation.you}</RotatedText>
        </Tab>
      </TabWrapper>
      <TabWrapper bottom={true} selected={selectedTab === TABS.market}>
        <Tab
          bottomLeft={true}
          topLeft={selectedTab === TABS.market}
          topRight={selectedTab !== TABS.market}
          onClick={() => setSelectedTab(TABS.market)}
          selected={selectedTab === TABS.market}
        >
          <RotatedText>{translation.market}</RotatedText>
        </Tab>
      </TabWrapper>
    </TabsWrapper>
  );
};

export default Tabs;
