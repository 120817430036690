import React from "react";
import {
  CalendarElementWrapper,
  FreeDayNameWrapper,
  TopContentCalendarElementWrapper,
} from "../calendar.styles";
import { Colors } from "@/common/colors/colors";
import Icon from "@/common/components/Icon";
import moment from "moment";
import { IconWrapper } from "@/pages/consultant/consultantManagerScoringListening/consultantManagerScoring.styles";
import { calculateCalendatElementsBaner } from "@/common/functions/calculateCalendatElementsBaner";
import ActionButton from "@/common/components/buttons/ActionButton";

const CalendarElement = ({
  data,
  day,
  freeDay,
  setWorkingHoursOneSave,
  setAddWorkedHours,
  setAddWorkingHoursNew,
  handleDownloadFile,
  handleDeclineFile,
}) => {
  return (
    <CalendarElementWrapper
      style={{
        background: `${freeDay ? Colors.orange : ""}`,
      }}
    >
      <TopContentCalendarElementWrapper>
        <h3>{moment(day).format("DD")}</h3>
        <IconWrapper>
          <ActionButton
            color="transparent"
            disabled={freeDay}
            style={{ border: "none", padding: "0" }}
            onClick={() => {
              setWorkingHoursOneSave(() => {
                const result = {};

                data?.hours?.forEach((hour) => {
                  result[hour.hour_time] = {
                    sign: hour.sign,
                    bonusId: hour.bonus,
                  }
                });
                return result;
              });
              setAddWorkedHours(day.format("YYYY-MM-DD"));
            }}
          >
            <Icon name={"fa fa-edit"} color={Colors.red} />
          </ActionButton>
          {data?._ticket && (
            <>
              <ActionButton
                color="transparent"
                style={{ border: "none", padding: "0" }}
                onClick={() =>
                  handleDownloadFile(data?._ticket?._stored_document?._id)
                }
              >
                <Icon name={"fa fa-file"} color={Colors.green} />
              </ActionButton>
              <ActionButton
                color="transparent"
                style={{ border: "none", padding: "0" }}
                onClick={() => handleDeclineFile(data?._ticket._id)}
              >
                <Icon name={"fa fa-file fa-trash"} color={Colors.red} />
              </ActionButton>
            </>
          )}
          <ActionButton
            color="transparent"
            disabled={freeDay}
            style={{ border: "none", padding: "0" }}
            onClick={() => setAddWorkingHoursNew(() => true)}
          >
            <Icon name={"fa fa-plus"} color={Colors.green} />
          </ActionButton>
        </IconWrapper>
      </TopContentCalendarElementWrapper>
      {freeDay && (
        <FreeDayNameWrapper>
          <p>{freeDay?.name}</p>
        </FreeDayNameWrapper>
      )}
      {data && calculateCalendatElementsBaner(data)}
    </CalendarElementWrapper>
  );
};

export default CalendarElement;
