import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
padding: 20px;
  display: flex;
  justify-content: center;
`;

export const Circle = styled.div`
display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
`;

export const CircleNumber = styled.h4`
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: bold;
`;

export const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
`;

export const Line = styled.span`
  width: 50px;
  height: 2px;
  background-color: ${(props) => props.color};
`;

export const Title = styled.h2`
  text-align: center;
  text-decoration: underline;
  color: ${Colors.darkBlue};
  margin: -20px 0 40px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 25px -30px 25px;
`;

export const QuestionName = styled.h3`
  color: ${Colors.darkBlue};
  text-align: center;
  margin-top: 40px;
  font-style: italic;
`;

export const AnswerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  min-height: 80px;
  height: fit-content;
  border: 3px solid
    ${(props) => (props.selected ? Colors.green : Colors.darkBlue)};
  transform: scale(${(props) => (props.selected ? 1.05 : 1)});
  gap: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  :hover {
    border: 2px solid ${Colors.green};
    background-color: ${Colors.lightGreen};
    color: white;
  }
`;

export const AnswersWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const TestReultWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  color: ${(props) => props.resultColor};
  font-size: 22px;
  font-weight: bold;
  max-width: 600px;
  text-align: center;
  flex-direction: column;
`;

export const TestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScoreWrapper = styled.h1`
  display: flex;
  justify-content: center;
  margin: -60px 0 20px 0;
  color: ${Colors.darkBlue};
`;