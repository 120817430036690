import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./DynamicMailContent.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { createDynamicMailContent, getAllDynamicMailContent, updateDynamicMailContent } from "@/API/repositories/dynamicMailContent";
import DynamicMailContentTable from "./components/dynamicMailContentTable/DynamicMailContentTable";
import DynamicMailContentForm from "./components/dynamicMailContentForm/DynamicMailContentForm";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const DynamicMailContent = () => {
  const [dynamicMailContent, setDynamicMailContent] = useState();
  const [isDynamicMailContentFormOpen, setIsDynamicMailContentFormOpen] = useState();
  const [selectedDynamicContent, setSelectedDynamicContent] = useState();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getAllDynamicMailContent);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDynamicMailContent(() => response.data);
  };

  const handleSetDynamicMailContentEdit = (content) => {
    setSelectedDynamicContent(() => content);
    setIsDynamicMailContentFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsDynamicMailContentFormOpen(() => false);
    setSelectedDynamicContent(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getAllDynamicMailContent,
        createDynamicMailContent,
        updateDynamicMailContent
      ) && <Loading />}
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content" }}
          defaultText="Add dynamic mail content"
          onClick={() => setIsDynamicMailContentFormOpen(true)}
        />
      </ContentWrapper>
      {isDynamicMailContentFormOpen && (
        <DynamicMailContentForm
          selectedDynamicContent={selectedDynamicContent}
          setShow={handleCloseForm}
          reload={loadData}
        />
      )}
      {!!dynamicMailContent?.length && (
        <DynamicMailContentTable
          handleSetDynamicMailContentEdit={handleSetDynamicMailContentEdit}
          dynamicMailContent={dynamicMailContent}
        />
      )}
    </PageWrapper>
  );
};

export default DynamicMailContent;
