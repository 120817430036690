import styled from 'styled-components';
import { Colors } from '../colors/colors';
import { Link } from 'react-router-dom';

const Button = styled.button`
 ::after {
    content: "${(props) => props.tooltip}";
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    padding: 5px;
    background-color: ${Colors.darkGray2};
    color: #fff;
    border-radius: 5px;
    z-index: 99999;
  }

  &:hover::after {
    display: ${(props) => (props.tooltip ? "block" : "none")};
  }
`;

export const StyledButton = styled(Button)`
	display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  position: relative;

  font-size: 14px;
  font-weight: bold;
  padding: 5px;

  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  background-color: transparent;
  border-radius: 10px;
  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    color: white;
    border: 1px solid ${(props) => props.color};
    background-color: ${(props) => props.color};
    transform: scale(1.1);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid gray;
    background-color: ${Colors.lightGray};
    color: #666;
    cursor: not-allowed;
    transform: scale(1);
  }
`;

export const StyledSecondaryButton = styled(Button)`
  background-color: ${({ color }) => color};
  color: white;
  padding: 6px 9px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid ${({ color }) => color};
  transition: all 0.3s;
  
  &:hover&:not(:disabled) {
    background-color: transparent;
    color: ${({ color }) => color};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledLink = styled(Link)`
	display: flex;
	justify-content: center;
	gap: 5px;
	align-items: center;
	white-space: nowrap;
	position: relative;
	text-decoration: none;

	font-size: 14px;
	font-weight: bold;
	padding: 5px;

	color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};
	background-color: transparent;
	border-radius: 10px;
	transition: 0.3s all;

	&:hover {
		cursor: pointer;
		color: white;
		border: 1px solid ${(props) => props.color};
		background-color: ${(props) => props.color};
		transform: ${(props) => `scale(${props.scale})`};
	}

	&:disabled,
	&[disabled] {
		border: 1px solid gray;
		background-color: ${Colors.lightGray};
		color: #666666;
		cursor: not-allowed;
		transform: scale(1);
	}

	&::after {
		content: "${(props) => props.tooltip}";
		position: absolute;
		bottom: calc(100% + 5px);
		left: 50%;
		transform: translateX(-50%);
		display: none;
		padding: 5px;
		background-color: ${Colors.darkGray2};
		color: #fff;
		border-radius: 5px;
		z-index: 99999;
	}

	&:hover::after {
		display: ${(props) => (props.tooltip ? "block" : "none")};
	}
`;
