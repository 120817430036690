import { DEFAULT_REDUCER_STATE, VALID_CLAIMS_ACTIONS } from "../constants/reducer";

export const validClaimsReducer = (state, action) => {
	switch (action.type) {
		case VALID_CLAIMS_ACTIONS.RESET:
			return DEFAULT_REDUCER_STATE;
		case VALID_CLAIMS_ACTIONS.SET_MARKETS:
			return { ...state, [VALID_CLAIMS_ACTIONS.SET_MARKETS]: action.payload };
		case VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD:
			return { ...state, [VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD]: action.payload };
		default:
			return state;
	}
};
