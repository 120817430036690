import React, { useState } from "react";
import {
  ActionIcon,
  ActionsWrapper,
  ContactAddIconWrapper,
  Hover,
  Label,
  LabelWrapper,
} from "../../ConversationGroup.styled";
import userManager from "@/API/userManager";
import { useNavigate } from "react-router-dom";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  addCCManagersToDw,
  markTicketAsClose,
  markTicketAsDone,
  updateDocumentTicket,
} from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";
import { NOT_ABLE_TO_CLOSE, CALLING_TICKETS } from "./constants";
import AddContact from "./components/addContact/AddContact";
import {
  getFileById,
  getRecordingById,
} from "@/API/repositories/storedDocument";
import { getTranscriptionById } from "@/API/repositories/transcription";
import MediaPlayer from "@/common/components/MediaPlayer";
import Transcriptions from "@/common/components/transcriptions/Transcriptions";
import MakeDecision from "@/common/components/makeDecision/MakeDecision";
import CallHistoryTimeline from "@/common/components/callHistoryTimeline/CallHistoryTimeline";
import { getCallHistory } from "@/API/repositories/contact";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const ConversationActions = ({
  selectedTicket,
  isCallingTicket,
  handleCloseTicketView,
  setRecording,
  loadData,
  setShowlisteningObjections,
  setIsContactCallOpen,
}) => {
  const [isAddContactOpen, setIsAddContactOpen] = useState();
  const [isAttachmentLoading, setIsAttachmentLoading] = useState();
  const [isCCManagerDwLoading, setIsCCManagerDwLoading] = useState();
  const [isAudioLoading, setIsAudioLoading] = useState();
  const [audio, setAudio] = useState();
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState();
  const [transcription, setTranscription] = useState();
  const [isDeciding, setIsDeciding] = useState();
  const [callHistoryTimeline, setCallHistoryTimeline] = useState();

  const navigate = useNavigate();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const currentUserId = userManager.getUser().id;
  const isConsultantManager = CONSULTANT_MANAGER_ROLES.includes(
    userManager.getUser().role
  );

  const handleNavigateToContact = () => {
    handleCloseTicketView();
    navigate(`/consultant/contact/${selectedTicket.contact}`);
  };

  const handleNavigateToCandidate = () =>{
    handleCloseTicketView();
    navigate(`/consultant/team-leader/recruitment?candidate=${selectedTicket.notification.call_center_candidate}`);
  }

  const handleCloseTicket = async () => {
    if (selectedTicket.type === "document") {
      handleFinishDocumentTicket();

      return;
    }

    const response = await makeRequest(
      markTicketAsClose.bind(null, selectedTicket._id)
    );

    if (response.data) {
      await loadData();
    }
  };

  const handleFinishDocumentTicket = async () => {
    const payload = {};

    if (selectedTicket.status === "accepted_pending") {
      payload.ticket_status = "accepted";
    }

    if (selectedTicket.status === "declined_pending") {
      payload.ticket_status = "declined";
    }

    const response = await makeRequest(
      updateDocumentTicket.bind(null, selectedTicket._id, payload)
    );

    if (response.data) {
      loadData();
    }
  };

  const handleMarkAsDone = async () => {
    const response = await makeRequest(
      markTicketAsDone.bind(null, selectedTicket._id)
    );

    if (response.data) {
      await loadData();
    }
  };

  const handleAddCCManagersToDw = async () => {
    setIsCCManagerDwLoading(() => true);
    const response = await makeRequest(
      addCCManagersToDw.bind(null, selectedTicket._id)
    );

    if (response.data) {
      await loadData();
    }

    setIsCCManagerDwLoading(() => false);
  };

  const handleShowListeningInformation = async () => {
    setShowlisteningObjections(selectedTicket);

    const response = await makeRequest(
      getRecordingById.bind(
        null,
        selectedTicket.calling_information[0].stored_data
      )
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(() => url);
    }
  };

  const handleListenSuspicious = async () => {
    setIsAudioLoading(() => true);
    const response = await makeRequest(
      getFileById.bind(null, selectedTicket.suspicion.recording, true)
    );
    setIsAudioLoading(() => false);
    if (response.data) {
      await loadData();
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setAudio(() => url);
    }
  };

  const handleTranscript = async () => {
    setIsTranscriptionLoading(() => true);
    const response = await makeRequest(
      getTranscriptionById.bind(null, selectedTicket.suspicion.recording)
    );
    setIsTranscriptionLoading(() => false);
    if (response.data) {
      await loadData();
      setTranscription(() => response.data);
    }
  };

  const loadCompleteCallHistory = async () => {
    const response = await makeRequest(
      getCallHistory.bind(null, selectedTicket.contact)
    );

    if (!response?.data) {
      return addMessage("Couldn't load", "error");
    }
    return setCallHistoryTimeline(() => response.data);
  };

  const isCallHistoryLoading = hasUnfilledRequest(getCallHistory);

  return (
    <ActionsWrapper>
      {selectedTicket.contact && (
        <>
          {isCallingTicket && (
            <Hover label="Call client">
              <ActionIcon
                className="fa fa-headset animation-scale"
                color="grey"
                onClick={() => setIsContactCallOpen(() => true)}
              />
            </Hover>
          )}
          <LabelWrapper>
            <ActionIcon
              rotate
              className="fa fa-address-book"
              color="grey"
              onClick={() => handleNavigateToContact()}
            />
            <Label>Contact info</Label>
          </LabelWrapper>
        </>
      )}
      {selectedTicket.todo?.user && !selectedTicket.dw?.length && (
        <Hover
          className="animation-scale"
          onClick={() => handleAddCCManagersToDw()}
          label="Add HEAD_CC_MANAGER"
        >
          <ActionIcon
            className={
              isCCManagerDwLoading ? "fa fa-spinner fa-spin" : "fa fa-plus"
            }
            color={Colors.green}
            onClick={() => handleAddCCManagersToDw()}
          />
        </Hover>
      )}
      {selectedTicket.type === "suspicion" &&
        selectedTicket?.status === "open" && (
          <>
            {currentUserId !== selectedTicket.suspicion?.consultant &&
              isConsultantManager && (
                <Hover label="See complete call history">
                  <ActionIcon
                    onClick={() => loadCompleteCallHistory()}
                    className={
                      isCallHistoryLoading
                        ? "fa fa-spinner fa-spin"
                        : "fa fa-folder-tree"
                    }
                    color={Colors.darkBlue}
                  />
                </Hover>
              )}
            {(selectedTicket.suspicion_document.first_download_at ||
              selectedTicket.suspicion_document.first_transcription_at) &&
              currentUserId !== selectedTicket.suspicion?.consultant && (
                <Hover label="Make responsible decision">
                  <ActionIcon
                    onClick={() => setIsDeciding(true)}
                    className="fa fa-gavel"
                    color={Colors.darkBlue}
                  />
                </Hover>
              )}
            <Hover label="Listen">
              <ActionIcon
                onClick={() => handleListenSuspicious()}
                className={
                  isAudioLoading ? "fa fa-spinner fa-spin" : "fa fa-volume-low"
                }
                color={Colors.darkBlue}
              />
            </Hover>
            <Hover label="Transcript">
              <ActionIcon
                onClick={() => handleTranscript()}
                className={
                  isTranscriptionLoading
                    ? "fa fa-spinner fa-spin"
                    : "fa fa-scroll"
                }
                color={Colors.darkBlue}
              />
            </Hover>
          </>
        )}
      {selectedTicket?.notification?.call_center_candidate && (
        <Hover label="Navigate to candidate">
          <ActionIcon
            onClick={() => handleNavigateToCandidate()}
            className="fa fa-user"
            color={Colors.darkBlue}
          />
        </Hover>
      )}
      {selectedTicket.type === "question" && !selectedTicket.contact && (
        <LabelWrapper>
          <div
            style={{ position: "relative" }}
            onClick={() => setIsAddContactOpen(() => true)}
          >
            <ActionIcon rotate className="fa fa-address-book" color="grey" />
            <ContactAddIconWrapper>
              <i
                className={
                  isAttachmentLoading
                    ? "fa-solid fa-spinner fa-spin"
                    : "fa fa-plus"
                }
              />
            </ContactAddIconWrapper>
          </div>
          <Label>Add contact</Label>
        </LabelWrapper>
      )}
      {((selectedTicket.created_by === currentUserId &&
        !NOT_ABLE_TO_CLOSE.includes(selectedTicket.type)) ||
        (selectedTicket.type === "document" &&
          (selectedTicket.status === "declined_pending" ||
            selectedTicket.status === "accepted_pending")) ||
        (selectedTicket.todo && selectedTicket.status === "open") || (selectedTicket.notification && selectedTicket.status === 'open')) && (
          <Hover label="Close ticket">
            <ActionIcon
              className="fa fa-trash animation-scale"
              color="red"
              onClick={() => handleCloseTicket()}
            />
          </Hover>
        )}
      {selectedTicket.type === "correction" && (
        <Hover label="Mark as done">
          <ActionIcon
            color="green"
            className="fa fa-check animation-scale"
            onClick={() => handleMarkAsDone()}
          />
        </Hover>
      )}
      {CALLING_TICKETS.includes(selectedTicket.type) && (
        <Hover label="Listen">
          <ActionIcon
            className="fa  fa-circle-info animation-scale"
            color={Colors.darkBlue}
            onClick={() => handleShowListeningInformation()}
          />
        </Hover>
      )}
      {isAddContactOpen && (
        <AddContact
          selectedTicket={selectedTicket}
          loadData={loadData}
          setIsAttachmentLoading={setIsAttachmentLoading}
          setIsAddContactOpen={setIsAddContactOpen}
        />
      )}
      {audio && (
        <MediaPlayer recording={audio} setClose={setAudio} noDownload={true} />
      )}
      {transcription && (
        <Transcriptions
          transcriptions={transcription}
          setClose={setTranscription}
        />
      )}
      {isDeciding && (
        <MakeDecision
          reloadFn={loadData}
          ticket={selectedTicket}
          setShow={setIsDeciding}
        />
      )}
      {callHistoryTimeline && (
        <CallHistoryTimeline
          currentSuspicionRecording={selectedTicket.suspicion.recording}
          handleCloseTicketView={handleCloseTicketView}
          setShow={setCallHistoryTimeline}
          callHistoryTimeline={callHistoryTimeline}
          setAudio={setAudio}
          setTranscription={setTranscription}
          loadData={loadData}
        />
      )}
    </ActionsWrapper>
  );
};

export default ConversationActions;
