import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getOrderWithContact,
  updateOrder,
} from "../../../API/repositories/order";
import Card from "../../../common/components/Card";
import Input from "../../../common/components/Input";
import Loading from "../../../common/components/Loading";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { QUEUE_STATUSES } from "../../../common/constants/statuses";
import { ORDER_STATUSES } from "../../../common/constants/statuses";
import { SOURCE_REASONS } from "../../../common/constants/statuses";
import { SHIPPING_STATUSES } from "../../../common/constants/statuses";
import { getUserNameAndSurnameById } from "../../../API/repositories/user";
import { getHisotryOrderId } from "../../../API/repositories/queue";
import { Colors } from "../../../common/colors/colors";
import TimeLine from "../../../common/components/TimeLine";
import UTMsView from "./components/utmsView/UTMsView";
import { getMailsForContact } from "../../../API/repositories/mail";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getProductsWithCouriers } from "@/API/repositories/lms";
import { findProductById } from "@/common/functions/findProductById";
import OrderPayment from "./components/OrderPayment";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import ContactView from "./components/contactView/ContactView";
import OrderButtons from "./components/orderButtons/OrderButtons";

const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  gap: 20px;
  max-height: 90vh;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

const SubTitle = styled.h5`
  margin: 10px;
  color: ${Colors.darkBlue};
`;

const dumb = (change, currentKey = "", current = {}) => {
  if (Array.isArray(change)) {
    change.map((value, i) => {
      dumb(value, currentKey + "." + i, current);
    });

    return current;
  }

  if (typeof change === "object") {
    if (!change) {
      current[currentKey] = change;

      return current;
    }

    Object.keys(change).map((changeKey) => {
      dumb(change[changeKey], currentKey + "." + changeKey, current);
    });

    return current;
  }

  current[currentKey] = change;

  return current;
};

const getDumyData = (change) => {
  return Object.entries(change).map(([key, value]) => {
    return [dumb(value[0], key), dumb(value[1], key)];
  });
};

const Order = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  const [selectedOrderStatus, setSelectedOrderStatus] = useState();
  const [selectedResignReaseon, setSelectedResignReason] = useState();
  const [selectedQueueStatus, setSelectedQueueStatus] = useState();
  const [selectedShippingStatus, setSelectedShippingStatus] = useState();
  const [mails, setMails] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [selectedConfirmedByUser, setSelectedConfirmByUser] = useState();
  const [chosenVariants, setChosenVariants] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionPrice, setSelectedOptionPrice] = useState();
  const [history, setHisotry] = useState();
  const [selectedCourier, setSelectedCourier] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const {
    options: { usersOptions, productsOptions },
    commonData: { products },
  } = useCommonDataContext();

  const [selectedProduct, setSelectedProduct] = useState();

  const handleChange = (key, value) => {
    setOrder((prev) => {
      const keyParts = key.split(".");
      let current = prev;
      while (keyParts.length > 1) {
        if (!current[keyParts[0]]) {
          current[keyParts[0]] = Object();
        }
        current = current[keyParts[0]];
        keyParts.shift();
      }

      current[keyParts[0]] = value;
      return prev;
    });
  };

  const handleSaveOrder = async (e) => {
    e.preventDefault();

    order.order_items = chosenVariants.map((variant) => {
      return {
        product: order.product,
        price: variant.price,
        product_variant: variant.value,
      };
    });

    order.product = selectedProduct.value;
    order.confirmed_by = selectedConfirmedByUser?.value;

    const response = await makeRequest(updateOrder.bind(null, id, order));

    if (response.data) {
      addMessage("saved", "success");
      await handleGetOrderWithContact();
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const hadnleDelteProductItem = (_id) => {
    let data = chosenVariants.filter((item) => item._id !== _id);
    let id = 1;
    data = data.map((data) => {
      data._id = id;
      id++;
      return data;
    });
    setChosenVariants(data);
  };

  const hadnleAddProduct = (e) => {
    e.preventDefault();

    if (selectedOption && selectedOptionPrice)
      setChosenVariants((prev) => {
        const items = [
          ...prev,
          {
            _id: chosenVariants.length + 1,
            name: selectedOption.label,
            value: selectedOption.value,
            price: selectedOptionPrice.value,
          },
        ];
        handleChange("order_items", items);
        return items;
      });
  };

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!selectedProduct || !productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (product) =>
        findProductById(selectedProduct.value, products)?.short ===
        product.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(
      options.find((option) => option.value === order?.shipping?.curier_lms_id) ||
      options[0]
    );
  };

  const handleGetOrderWithContact = async () => {
    setOrder(() => null);

    const history = await makeRequest(getHisotryOrderId.bind(null, id));

    if (history.data) {
      setHisotry(() => history.data);
    }

    const response = await makeRequest(getOrderWithContact.bind(null, id));

    if (response.data) {
      setOrder(() => response.data);

      const mailResponse = await makeRequest(getMailsForContact.bind(null, id));

      if (mailResponse.data) {
        setMails(() => mailResponse.data);
      }

      setSelectedOrderStatus(() =>
        ORDER_STATUSES.find((status) => status.value === response.data.status)
      );
      setSelectedQueueStatus(() =>
        QUEUE_STATUSES.find(
          (status) => status.value === response.data.queue_status
        )
      );
      setSelectedResignReason(() =>
        SOURCE_REASONS.find(
          (status) => status.value === response.data.resign_reason
        )
      );
      setSelectedShippingStatus(() =>
        SHIPPING_STATUSES.find(
          (status) => status.value === response.data.shipping?.status
        )
      );

      setChosenVariants(
        response.data.order_items.length > 0
          ? response.data.order_items.map((order, i) => {
            return {
              _id: i + 1,
              name: response.data._product.variants.find(
                (variant) => variant.short === order.product_variant
              )?.name,
              value: order.product_variant,
              price: order.price,
            };
          })
          : []
      );

      if (response.data.confirmed_by) {
        const confirmed_by = await getUserNameAndSurnameById(
          response.data.confirmed_by
        );

        response.data.confirmed_by_name = confirmed_by.data;
      }
    }
  };

  useEffect(() => {
    if (selectedCourier && order && !order?.shipping?.curier_lms_id) {
      handleChange("shipping.curier_lms_id", selectedCourier?.value);
    }
  }, [selectedCourier]);

  const createTimeLineData = (
    order,
    { inbound, outbound },
    changes,
    comments,
    mails
  ) => {
    return [
      {
        created_at: order.created_at,
        type: "creation",
      },
      ...[
        ...inbound.map((his) => {
          his.source = "inbound";
          his.type = "call_history";
          his.user_id = his.consultant;
          return his;
        }),
        ...outbound.map((o) => ({
          ...o,
          type: "call_history",
          user_id: o.consultant,
        })),
      ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      ...changes
        .filter((change) => !!change.changeset)
        .map((change) => ({
          changeset: getDumyData(change.changeset),
          created_at: change.created_at,
          type: "change_set",
          user_id: change.user_id,
          log: change.log,
        })),
      ...comments.map((c) => ({
        ...c,
        type: "comment",
        user_id: c._user?._id,
      })),
      ...mails.map((c) => ({
        ...c,
        type: "mail",
        user_id: c.sender,
      })),
    ];
  };

  useEffect(() => {
    handleGetOrderWithContact();
  }, [id]);

  useEffect(() => {
    setSelectedProduct(() =>
      productsOptions.find(
        (product) => order && product.value === order.product
      )
    );
    setSelectedConfirmByUser(() =>
      usersOptions.find((user) => order && user.value === order.confirmed_by)
    );
  }, [productsOptions, usersOptions, order]);

  useEffect(() => {
    loadCouriers();
  }, []);

  useEffect(() => {
    handleSetCouriersData();
  }, [selectedProduct, productsWithCouriers]);

  return (
    <div style={{ padding: "20px" }}>
      {hasUnfilledRequest(updateOrder, getProductsWithCouriers) && <Loading />}
      {order && mails && history ? (
        <Wrapper>
          <Flex>
            <ContactView order={order} />
            <OrderButtons
              order={order}
              handleGetOrderWithContact={handleGetOrderWithContact}
            />
          </Flex>
          <Flex>
            <Card>
              <form onSubmit={(e) => handleSaveOrder(e)}>
                <h3>
                  <u>Order:</u>
                </h3>
                <h5 style={{ textAlign: "center" }}>
                  Created at:
                  <h5 style={{ color: Colors.darkBlue }}>
                    {moment(order.created_at).format("YYYY/MM/DD HH:mm")}
                  </h5>
                </h5>
                <div style={{ padding: "10px" }}>
                  <SubTitle>Statuses:</SubTitle>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <SelectInput
                        name="Status"
                        width={150}
                        options={ORDER_STATUSES}
                        selected={selectedOrderStatus}
                        setSelected={setSelectedOrderStatus}
                        onChange={(value) => handleChange("status", value)}
                      />
                    </div>
                    <div>
                      <SelectInput
                        name="Queue status"
                        width={150}
                        options={QUEUE_STATUSES}
                        selected={selectedQueueStatus}
                        setSelected={setSelectedQueueStatus}
                        onChange={(value) =>
                          handleChange("queue_status", value)
                        }
                      />
                      <SelectInput
                        name="Shipping Status"
                        width={150}
                        options={SHIPPING_STATUSES}
                        selected={selectedShippingStatus}
                        setSelected={setSelectedShippingStatus}
                        onChange={(value) =>
                          handleChange("shipping.status", value)
                        }
                      />
                    </div>
                  </div>
                  {order?.payment?.length > 0 && (
                    <>
                      {" "}
                      <SubTitle>Payment:</SubTitle>{" "}
                      <OrderPayment orderId={order._id} setOrder={setOrder} data={order?.payment || []} />
                    </>
                  )}

                  <SubTitle>Shipping:</SubTitle>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Input
                        name="Suggested Delivery Date"
                        width={150}
                        type="date"
                        onChange={(e) =>
                          handleChange(
                            "shipping.suggested_delivery_date",
                            e.target.value
                          )
                        }
                        value={
                          order.shipping?.suggested_delivery_date
                            ? moment(
                              order.shipping?.suggested_delivery_date
                            ).format("YYYY-MM-DD")
                            : null
                        }
                      />
                      <Input
                        name="Created at"
                        width={150}
                        type="date"
                        value={
                          order.shipping
                            ? moment(order.shipping.created_at).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        disabled={true}
                      />
                      <Input
                        name="Comment"
                        width={150}
                        value={order.shipping?.shipping_comment}
                        onChange={(e) =>
                          handleChange(
                            "shipping.shipping_comment",
                            e.target.value
                          )
                        }
                      />
                      <Input
                        name="Awizo at"
                        width={150}
                        type="date"
                        value={order.shipping?.status_awizo_at}
                        disabled
                      />
                      <Input
                        name="Confirmation"
                        type="checkbox"
                        id="Confirmation"
                        width={150}
                        checked={!!order.shipping?.is_confirmation_email_sent}
                        onChange={(e) =>
                          handleChange(
                            "shipping.is_confirmation_email_sent",
                            e.target.checked
                          )
                        }
                      />
                      <Input
                        name="Confirmation sent"
                        id="Confiramtion sent"
                        type="checkbox"
                        width={150}
                        checked={
                          !!order.shipping?.is_sent_confirmation_email_sent
                        }
                        onChange={(e) =>
                          handleChange(
                            "shipping.is_sent_confirmation_email_sent",
                            e.target.checked
                          )
                        }
                      />
                      <Input
                        name="Is sent"
                        id="Is sent"
                        type="checkbox"
                        width={150}
                        checked={!!order.shipping?.is_order_sent}
                        onChange={(e) =>
                          handleChange(
                            "shipping.is_order_sent",
                            e.target.checked
                          )
                        }
                      />
                      <Input
                        name="Waybill Number"
                        width={150}
                        value={order.shipping?.waybill_number}
                        onChange={(e) =>
                          handleChange(
                            "shipping.waybill_number",
                            e.target.value
                          )
                        }
                      />
                      <SelectInput
                        name="Courier name"
                        width={150}
                        disabled={order.shipping?.waybill_number}
                        options={courierOptions}
                        setSelected={setSelectedCourier}
                        selected={selectedCourier}
                        onChange={(value) =>
                          handleChange("shipping.curier_lms_id", value)
                        }
                      />
                    </div>
                    <div>
                      <Input
                        name="Order number"
                        width={180}
                        inputWidth={130}
                        type="text"
                        value={order.shipping?.order_number}
                        disabled
                      />
                      <Input
                        name="Money transfer at"
                        width={180}
                        inputWidth={130}
                        type="date"
                        value={
                          order.shipping?.payment_transfer_date
                            ? moment(
                              order.shipping?.payment_transfer_date
                            ).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                      />
                      <Input
                        name="Delivered at"
                        width={180}
                        inputWidth={130}
                        type="date"
                        value={
                          order.shipping?.status_delivered_at
                            ? moment(
                              order.shipping?.status_delivered_at
                            ).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                      />

                      <Input
                        name="Lms sent at"
                        width={180}
                        inputWidth={130}
                        type="date"
                        value={
                          order.shipping?.status_lms_sent_at
                            ? moment(order.shipping?.status_lms_sent_at).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        disabled
                      />
                      <Input
                        name="Delivered no payment at"
                        type="date"
                        width={180}
                        inputWidth={130}
                        value={
                          order.shipping?.status_delivered_no_payment_at
                            ? moment(
                              order.shipping?.status_delivered_no_payment_at
                            ).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                      />
                      <Input
                        name="Return at"
                        width={180}
                        inputWidth={130}
                        type="date"
                        value={
                          order.shipping?.status_return_at
                            ? moment(order.shipping?.status_return_at).format(
                              "YYYY-MM-DD"
                            )
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <SubTitle>Items:</SubTitle>
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "20px",
                          display: "flex",
                          padding: "0px 30px",
                        }}
                      >
                        <strong style={{ marginRight: "20px" }}>Name:</strong>{" "}
                        {order._product.name}
                      </label>
                      <SelectInput
                        name={"Variants"}
                        width={80}
                        selectWidth={150}
                        selected={selectedOption}
                        setSelected={setSelectedOption}
                        options={order._product.variants.map((varaint) => {
                          return { label: varaint.name, value: varaint.short };
                        })}
                      />
                      <SelectInput
                        name={"Price"}
                        selectWidth={150}
                        width={40}
                        selected={selectedOptionPrice}
                        setSelected={setSelectedOptionPrice}
                        options={order._product.prices.map((price) => {
                          return { label: price.value, value: price.value };
                        })}
                      />
                      <div style={{ display: "flex", justifyContent: "right" }}>
                        <ActionButton
                          text="Add"
                          onClick={(e) => hadnleAddProduct(e)}
                        />
                      </div>
                    </div>

                    {chosenVariants?.length > 0 && (
                      <div
                        style={{
                          marginTop: "30px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{ fontSize: "20px", textAlign: "left" }}
                              >
                                Variant
                              </th>
                              <th
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Short
                              </th>
                              <th
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Price
                              </th>
                              <th
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {chosenVariants.map((data) => (
                              <tr style={{}}>
                                <td
                                  style={{
                                    width: "100px",
                                    fontSize: "18px",
                                    borderTop: "1px solid grey",
                                    textAlign: "left",
                                    padding: "11px 0px",
                                  }}
                                >
                                  {data.name}
                                </td>
                                <td
                                  style={{
                                    width: "200px",
                                    fontSize: "18px",
                                    borderTop: "1px solid grey",
                                    textAlign: "center",
                                  }}
                                >
                                  {data.value}
                                </td>
                                <td
                                  style={{
                                    width: "200px",
                                    fontSize: "18px",
                                    borderTop: "1px solid grey",
                                    textAlign: "center",
                                  }}
                                >
                                  {data.price} {order.currency}
                                </td>
                                <td
                                  style={{
                                    width: "80px",
                                    fontSize: "18px",
                                    borderTop: "1px solid grey",
                                    textAlign: "center",
                                  }}
                                >
                                  <ActionButton
                                    onClick={() =>
                                      hadnleDelteProductItem(data._id)
                                    }
                                    style={{
                                      background: "none",
                                      border: "none",
                                    }}
                                  >
                                    <i
                                      class="fa fa-remove"
                                      style={{ color: "red", fontSize: "20px" }}
                                    />
                                  </ActionButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <SubTitle>Basic:</SubTitle>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <SelectInput
                        name="product"
                        width={150}
                        selected={selectedProduct}
                        setSelected={setSelectedProduct}
                        options={productsOptions}
                      />
                      <SelectInput
                        name="confirmed by"
                        width={150}
                        selected={selectedConfirmedByUser}
                        setSelected={setSelectedConfirmByUser}
                        options={usersOptions}
                      />
                      <Input
                        name="market"
                        width={150}
                        value={order._market?.name}
                        disabled
                      />
                      <Input
                        name="contact attempts queue count"
                        width={150}
                        value={
                          order.contact_attempts_queue_count
                            ? order.contact_attempts_queue_count
                            : "-----"
                        }
                        disabled
                      />
                      <Input
                        name="resigned comment"
                        width={150}
                        value={
                          order.resign_comment ? order.resign_comment : "-----"
                        }
                        disabled
                      />
                    </div>
                    <div>
                      <Input
                        name="confirmation date"
                        width={150}
                        value={order.confirmation_date}
                        disabled
                      />
                      <Input
                        name="last status by"
                        width={150}
                        value={order._last_status_by?.name || "System"}
                        disabled
                      />
                      <Input
                        name="current_queue"
                        width={150}
                        value={order._current_queue?.name}
                        disabled
                      />
                      <Input
                        name="last_queue"
                        width={150}
                        value={order._last_queue?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <UTMsView order={order} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                    margin: "10px 0 30px",
                  }}
                >
                  <SubmitButton style={{ width: '80px', heigh: '33px' }} text="Save" />
                </div>
              </form>
            </Card>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {order && history && (
                <TimeLine
                  timeline={createTimeLineData(
                    order,
                    history[0],
                    order?._changes,
                    order._comments,
                    mails
                  )}
                  users={usersOptions}
                />
              )}
            </div>
          </Flex>
        </Wrapper>
      ) : hasUnfilledRequest(
        getHisotryOrderId,
        getOrderWithContact,
        getMailsForContact
      ) ? (
        <Loading />
      ) : (
        <h4 style={{ color: Colors.red }}>Order with this id doesnt exists</h4>
      )}
    </div>
  );
};

export default Order;
