import Icon from "@/common/components/Icon";
import { ThCellFlex } from "../ValidClaimsReport.styled";
import { FILTER_ACTIONS } from "./filter";
import { Colors } from "@/common/colors/colors";

export const getHeadersArray = (setAction, filters) => [
  [
    <th>No</th>,
    <th>
      <ThCellFlex>
        Rynek
        <Icon
          onClick={() => setAction(FILTER_ACTIONS.SET_MARKETS)}
          color={
            filters?.[FILTER_ACTIONS.SET_MARKETS]?.length
              ? Colors.orange
              : "#fff"
          }
          name="fa fa-filter"
        />
      </ThCellFlex>
    </th>,
    <th>Miesiąc wykonania zwrotu</th>,
    <th>Data sprzedaży usługi</th>,
    <th>Data wykonania zwrotu środków</th>,
    <th>Nazwa Klienta</th>,
    <th>
      <ThCellFlex>
        Wartość pobrania (Brutto waluta)
        <Icon
          onClick={() => setAction(FILTER_ACTIONS.SET_CURRENCIES)}
          color={
            filters?.[FILTER_ACTIONS.SET_CURRENCIES]?.length
              ? Colors.orange
              : "#fff"
          }
          name="fa fa-filter"
        />
      </ThCellFlex>
    </th>,
    <th>Wartość zwrotu środków (pln)</th>,
    <th>Korekta Faktury</th>,
    <th>NOTATKA</th>,
  ],
];

export const getExcelClaimsHeaderMap = (currency, originalCurency) => {
  return originalCurency
    ? {
        idx: "No.",
        market: "Rynek",
        month: "Miesiąc wykonania zwrotu",
        form: "Forma zwrotu",
        date: "Data sprzedaży usługi",
        refundDate: "Data wykonania zwrotu środków",
        fullName: "Nazwa Klienta",
        amount: `Wartość pobrania (Brutto ${currency})`,
        originalAmount: "Wartość zwrotu środków (Brutto PLN)",
        invoiceCorrection: "Korekta Faktury",
        commentForAccounting: "NOTATKA",
      }
    : {
        idx: "No.",
        market: "Rynek",
        month: "Miesiąc wykonania zwrotu",
        form: "Forma zwrotu",
        date: "Data sprzedaży usługi",
        refundDate: "Data wykonania zwrotu środków",
        fullName: "Nazwa Klienta",
        amount: `Wartość pobrania (Brutto ${currency})`,
        invoiceCorrection: "Korekta Faktury",
        commentForAccounting: "NOTATKA",
      };
};

export const FAILD_CLAIMS_HEADERS_MAP = {
  IDX: "No.",
  MARKET: "Rynek",
  NAME: "Nazwa Klienta",
  FIRST_TRANSFER_AT: "Data pierwszego przelewu",
  AMOUNT: "Kwota zwrotu (Brutto waluta)",
  PROVIDER: "Forma zwrotu",
  BACK_DATE: "Przelew wrócił na konto(data)",
  NEXT_TRANSFER_AT: "Data ponownego przelewu",
  NEXT_AMOUNT: "Kwota zwrotu (Brutto waluta) ponownego przelewu",
  NEXT_NAME: "Nazwa Klienta ponownego przelewu",
};
