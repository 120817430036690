import React from "react";
import { ORDERS_WIDTH } from "./constants/orders";
import {
  HoverRegulation,
  ProgressBarHover,
  SubTitle,
  TitleIcon,
} from "../../../../Gamification.styled";
import {
  ProgressBarItem,
  ProgressBarWrapper,
  ProgressBarItemFiller,
} from "./Orders.styled";
import { getProgressBarItemFillerWidth } from "./helpers/getProgressBarItemFillerWidth";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";
import { findUserById } from "../../../../helpers/findUserById";
import Stars from "../../../../common/components/stars/Stars";
import { getPercent } from "@/common/functions/getPercent";
import { getWorkingDaysForMonth } from "@/common/functions/getWorkingDaysForMonth";
import { getDeliveredRange } from "@/common/functions/getDeliveredRange";
import { getOrdersRange } from "@/common/functions/getOrdersRange";
import { getUserGoal } from "@/common/functions/getUserGoal";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Orders = ({ userData, regulation }) => {
  const {
    stars_percents,
    package_percents,
    normal_mode_constant,
    calling_mode_constant,
    delivered_amount,
    total_amount,
  } = userData;

  const {
    commonData: { markets, users, queues },
  } = useCommonDataContext();
  const { translation } = useTranslationContext();

  const currentUserId = userManager.getUser().id;
  const currentUser = findUserById(currentUserId, users);
  const dayHours = currentUser?.contract_hours;
  const constantMultiplier = currentUser?.calling_system
    ? calling_mode_constant
    : normal_mode_constant;
  const userMarket = findMarketByContractType(
    markets,
    findUserById(currentUserId, users)
  );
  const workingDays = getWorkingDaysForMonth(userMarket);
  const ordersRange = getOrdersRange({
    stars_percents,
    workingDays,
    dayHours,
    constantMultiplier,
  });
  const deliveredRange = getDeliveredRange(package_percents);
  const deliveredPercent = getPercent(delivered_amount, total_amount);
  const userGoal = getUserGoal({
    user: currentUser,
    gamificationUserStats: userData,
    markets,
    queues,
  });

  const orderPercent =
    getPercent(total_amount, userGoal) > 100
      ? 100
      : getPercent(total_amount, userGoal);

  return (
    <>
      <SubTitle>
        {translation.orders}
        <HoverRegulation regulation={regulation}>
          <TitleIcon className="fa fa-thin fa-circle-info" />
        </HoverRegulation>
      </SubTitle>
      {!!ordersRange?.length && (
        <>
          <Stars
            ordersRange={ordersRange}
            deliveredPercent={deliveredPercent}
            deliveredRange={deliveredRange}
            totalAmount={total_amount}
            orderWidth={ORDERS_WIDTH}
          />
          <ProgressBarHover percent={orderPercent} label={total_amount}>
            <ProgressBarWrapper>
              {ordersRange.map((step, i) => (
                <ProgressBarItem
                  width={`${ORDERS_WIDTH / ordersRange.length}px`}
                  step={step}
                >
                  <ProgressBarItemFiller
                    width={getProgressBarItemFillerWidth(
                      ordersRange[i - 1],
                      total_amount,
                      step
                    )}
                  />
                </ProgressBarItem>
              ))}
            </ProgressBarWrapper>
          </ProgressBarHover>
        </>
      )}
    </>
  );
};

export default Orders;
