import React, { useMemo, useState } from "react";
import PopUp from "@/common/components/PopUp";
import moment from "moment";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { WORKING_HOURS } from "@/common/constants/CC";
import {
  ButtonWrapper,
  TitleWrapper,
  WorkingHoursWrapper,
} from "@/pages/admin/ccHours/CCHours.styled";
import styled from 'styled-components';
import Icon from "@/common/components/Icon";
import HourForm from "@/common/components/hourForm/HourForm";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { getValidBonuses } from "@/common/functions/getValidBonuses";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const HoursContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Hour = styled.span`
  color: ${({ isSet }) => (isSet ? Colors.green : Colors.darkBlue)};
  font-weight: bold;
`;

const AddDayHours = ({
  setIsDayHoursOpen,
  date,
  handleSubmit,
  workingHoursOnSave,
  handleHoursChange,
  bonuses,
  handleRemoveConsultantHours
}) => {
  const [showHourFill, setShowHourFill] = useState();
  const { addMessage } = useMessageQueueContext();


  const availableBonuses = useMemo(() => {
    return getValidBonuses(date, bonuses, addMessage);
  }, [bonuses]);

  const isHourSet = (hour) => {
    return workingHoursOnSave[hour] && workingHoursOnSave[hour].sign && workingHoursOnSave[hour].bonusId;
  };
  const handleRemoveHour = (hour) => {
    handleRemoveConsultantHours(hour);
  }


  return (
    <PopUp setShow={setIsDayHoursOpen}>
      <TitleWrapper>
        Hours for day: {formatToDateTamplate(moment(date))}
      </TitleWrapper>
      <WorkingHoursWrapper>
        {WORKING_HOURS.map((hour, i) => (
          <HoursContainer key={i}>
            <Hour isSet={isHourSet(hour)}>{hour}</Hour>
            <Icon onClick={() => setShowHourFill(hour)} color={isHourSet(hour) ? Colors.green : Colors.darkBlue} name={isHourSet(hour) ? 'fa fa-check' : 'fa fa-edit'} />
            {isHourSet(hour) && <Icon color={Colors.red} name="fa fa-trash" onClick={() => handleRemoveHour(hour)} />}
          </HoursContainer>
        ))}
        {showHourFill && (
          <HourForm
            availableBonuses={availableBonuses}
            setShowHourFill={setShowHourFill}
            showHourFill={showHourFill}
            workingHoursOneSave={workingHoursOnSave}
            hanldeOnChange={handleHoursChange}
          />
        )}
      </WorkingHoursWrapper>
      <ButtonWrapper>
        <ActionButton disabled={!availableBonuses?.length} color={Colors.red} onClick={handleSubmit} text="Save" />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddDayHours;
