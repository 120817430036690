export const { Colors } = require("@/common/colors/colors");
export const { default: styled } = require("styled-components");

export const UserWrapper = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${Colors.darkBlue};
  margin-top: -10px;
  border-radius: 5px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
`;