import React from "react";
import styled from "styled-components";
import Navbar from "@/pages/admin/dashboard/components/navbar/Navbar";
import { AdminDashboardProvider } from "@/common/contexts/adminDashboard/adminDashboardContext";
import InfoBar from "./pages/admin/infobar/InfoBar";

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 100vw;
`;

const ChildrenWrapper = styled.div`
  height: 90vh;
  overflow-y: auto;
`;

const AdminDashboardView = ({ children }) => {
  return (
    <AdminDashboardProvider>
      <Wrapper>
        <Navbar />
        <div style={{ width: "100%" }}>
          <InfoBar />
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </div>
      </Wrapper>
    </AdminDashboardProvider>
  );
};

export default AdminDashboardView;
