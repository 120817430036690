import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { createProduct } from "../../../API/repositories/product";
import { Colors } from "../../../common/colors/colors";
import Form from "../../../common/components/Form";
import Input from "../../../common/components/Input";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getKeysByType } from "@/API/repositories/key";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import SelectInput from "@/common/components/SelectInput";
import { KEY_TYPES } from "@/common/constants/keyTypes";
import { getCompanies } from "@/API/repositories/company";
import { PRODUCT_TYPES } from "./ProductShow";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 30px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 800px;
`;

const ItemsTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${Colors.darkBlue};
  width: 110px;
`;

const Item = styled.div`
  cursor: pointer;
  background-color: ${Colors.green};
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 10px;
  font-size: 15px;
`;

const Splitter = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.darkGray};
  margin: 18px 0;
`;

const Title = styled.h3`
  color: ${Colors.darkBlue};
`;

const ProductNew = ({ close }) => {
  const [variants, setVaraints] = useState([]);
  const [prices, setPrices] = useState([]);
  const [mailNames, setMailNames] = useState([]);
  const [keys, setKeys] = useState();
  const [selectedKey, setSelectedKey] = useState();
  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedType, setSelectedType] = useState();

  const nameRef = useRef();
  const varaintNameRef = useRef();
  const varaintShortRef = useRef();
  const shortRef = useRef();
  const priceRef = useRef();
  const mailNameRef = useRef();
  const mailShortRef = useRef();
  const platformLinkRef = useRef();
  const itemFakturowniaRef = useRef();

  const { addMessage } = useMessageQueueContext();
  const { queryFunctions } = useCommonDataContext();
  const { refetch } = queryFunctions;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleAddMailName = (e) => {
    e && e.preventDefault();
    const name = mailNameRef.current.value;
    const short = mailShortRef.current.value;
    if (!name || !short) return;

    const _id = mailNames?.length + 1;
    setMailNames((prev) => [...prev, { _id, name, short }]);

    mailNameRef.current.value = "";
    mailShortRef.current.value = "";
  };

  const handleDeleteMailName = (e, _id) => {
    e && e.preventDefault();
    let filteredMailNames = mailNames.filter((mail) => mail._id !== _id);

    _id = 1;
    filteredMailNames = filteredMailNames.map((variant) => {
      variant._id = _id;
      _id++;
      return variant;
    });

    setMailNames(() => filteredMailNames);
  };

  const handleAddVaraint = (e) => {
    e.preventDefault();
    const name = varaintNameRef.current.value;
    const short = varaintShortRef.current.value;

    if (!name || !short) return;

    const _id = variants.length + 1;
    setVaraints((prev) => [...prev, { _id: _id, name: name, short: short }]);

    varaintNameRef.current.value = "";
    varaintShortRef.current.value = "";
  };

  const handleDeleteVariant = (e, _id) => {
    e.preventDefault();
    let filtredVaraints = variants.filter((variant) => variant._id !== _id);

    _id = 1;
    filtredVaraints = filtredVaraints.map((variant) => {
      variant._id = _id;
      _id++;
      return variant;
    });
    setVaraints(() => filtredVaraints);
  };

  const handleAddPrice = (e) => {
    e.preventDefault();
    const value = priceRef.current.value;
    const _id = prices.length + 1;

    setPrices((prev) => [...prev, { _id: _id, value: value }]);
    priceRef.current.value = "";
  };

  const handleDeletePrice = (e, _id) => {
    e.preventDefault();
    let filtredPrices = prices.filter((price) => price._id !== _id);

    _id = 1;
    filtredPrices = filtredPrices.map((price) => {
      price._id = _id;
      _id++;
      return price;
    });
    setPrices(() => filtredPrices);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const product = {};
    product.name = nameRef.current.value;
    product.short = shortRef.current.value;
    product.variants = variants;
    product.prices = prices;
    product.mail_names = mailNames;
    product.sendgrid_key = selectedKey.value;
    product.platformLink = platformLinkRef?.current?.value;
    product.company = selectedCompany.value;
    product.fakturownia_item_title = itemFakturowniaRef.current.value;
    product.type = selectedType.value;

    const response = await makeRequest(createProduct.bind(null, product));
    if (response.data) {
      addMessage("Created", "success");
      refetch();
      close();
    } else {
      addMessage("Couldn't create", "error");
    }
  };

  const loadData = async () => {
    const keysResponse = await makeRequest(
      getKeysByType.bind(null, KEY_TYPES.SENDGRID)
    );

    if (!keysResponse?.data) {
      return addMessage("Loading keys error", "error");
    }

    setKeys(() => handleMapToOptions(keysResponse.data, "label", "_id"));

    const companiesResponse = await makeRequest(getCompanies);

    if (!companiesResponse?.data) {
      return addMessage("Loading companies error", "error");
    }

    setCompanies(() =>
      handleMapToOptions(companiesResponse.data, "name", "_id")
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(createProduct, getKeysByType, getCompanies) && (
        <Loading />
      )}
      <Title>Create Product</Title>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div style={{ display: "flex" }}>
          <Input
            inputRef={nameRef}
            name="Name"
            value=""
            required={true}
            disabled={false}
            width={155}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={shortRef}
            name="Short"
            value=""
            required={true}
            disabled={false}
            width={100}
            color={Colors.darkBlue}
          />
        </div>
        <div style={{ display: "flex" }}>
          <SelectInput
            name="Sendgrid key"
            color={Colors.darkBlue}
            width={155}
            options={keys}
            setSelected={setSelectedKey}
            required
            hideStar
          />
          <SelectInput
            name="Company"
            color={Colors.darkBlue}
            selected={selectedCompany}
            setSelected={setSelectedCompany}
            options={companies}
            width={100}
            required
            hideStar
          />
        </div>
        <div style={{ display: "flex" }}>
          <Input
            name="Item fakturownia"
            inputRef={itemFakturowniaRef}
            value=""
            required={true}
            width={155}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={platformLinkRef}
            name="Platfrom Link"
            value=""
            disabled={false}
            width={100}
            color={Colors.darkBlue}
          />
        </div>
        <div>
          <SelectInput
            name="Type"
            color={Colors.darkBlue}
            selected={selectedType}
            setSelected={setSelectedType}
            options={PRODUCT_TYPES}
            width={155}
          />
        </div>

        <Splitter />
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Input
            inputRef={mailNameRef}
            name="name"
            value=""
            disabled={false}
            width={60}
            inputWidth={140}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={mailShortRef}
            name="short"
            value=""
            disabled={false}
            width={60}
            inputWidth={140}
            color={Colors.darkBlue}
          />
          <ActionButton
            onClick={(e) => handleAddMailName(e)}
            text="add mail names"
          />
        </div>
        <Wrapper>
          <ItemsTitle>Mail names:</ItemsTitle>
          <ItemsWrapper>
            {!!mailNames?.length
              ? mailNames.map((varaint) => (
                <Item onClick={(e) => handleDeleteMailName(e, varaint._id)}>
                  <p style={{ fontSize: "15px" }}>
                    {varaint.name} {varaint.short}
                  </p>
                </Item>
              ))
              : ""}
          </ItemsWrapper>
        </Wrapper>
        <Splitter />
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Input
            inputRef={varaintNameRef}
            name="name"
            value=""
            disabled={false}
            width={60}
            inputWidth={140}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={varaintShortRef}
            name="short"
            value=""
            disabled={false}
            width={60}
            inputWidth={140}
            color={Colors.darkBlue}
          />

          <ActionButton
            onClick={(e) => handleAddVaraint(e)}
            text="add variants"
          />
        </div>
        <Wrapper>
          <ItemsTitle>Varaiants:</ItemsTitle>
          <ItemsWrapper>
            {variants.length > 0
              ? variants.map((varaint) => (
                <Item onClick={(e) => handleDeleteVariant(e, varaint._id)}>
                  <p style={{ fontSize: "15px" }}>
                    {varaint.name} {varaint.short}
                  </p>
                </Item>
              ))
              : ""}
          </ItemsWrapper>
        </Wrapper>
        <Splitter />
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Input
            inputRef={priceRef}
            name="price"
            value=""
            width={60}
            inputWidth={140}
            type="number"
            color={Colors.darkBlue}
          />
          <ActionButton
            onClick={(e) => handleAddPrice(e)}
            text="add price"
          />
        </div>
        <Wrapper>
          <ItemsTitle>Prices:</ItemsTitle>
          {prices.length > 0
            ? prices.map((price) => (
              <div
                onClick={(e) => handleDeletePrice(e, price._id)}
                style={{
                  cursor: "pointer",
                  background: Colors.green,
                  padding: "5px",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                <p style={{ fontSize: "15px" }}>{price.value}</p>
              </div>
            ))
            : ""}
        </Wrapper>
        <Splitter />
        <SubmitButton
          style={{ float: "right", marginRight: "30px", marginBottom: '-30px' }}
          text="Save"
        />
      </Form>
    </>
  );
};

export default ProductNew;
