import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import ListTable from "../../../common/components/ListTable";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import MarketNew from "./MarketNew";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const MarketList = () => {
  const [row, setRow] = useState(null);
  const column = ["no", "name", "short", "currency"];
  const [showNew, setShowNew] = useState(false);
  const { commonData, queryValues } = useCommonDataContext();
  const { isLoading } = queryValues;
  const { markets } = commonData;

  const handleGetMarkets = async () => {
    setRow(() => null);

    setRow(() =>
      markets.map((element) => {
        let rowToDisplay = [];
        rowToDisplay.push(element.name);
        rowToDisplay.push(element.short);
        rowToDisplay.push(element.currency);
        return {
          data: rowToDisplay,
          _id: element._id,
        };
      })
    );
  };

  useLayoutEffect(() => {
    handleGetMarkets();
  }, [showNew, markets]);

  return (
    <>
      {isLoading && <Loading />}
      {row && (
        <Wrapper>
          <ActionButton
            style={{ width: "fit-content" }}
            onClick={() => setShowNew(true)}
            defaultText="Create market"
          />
          <ListTable
            column={column}
            row={row}
            entityName={"market"}
          ></ListTable>
        </Wrapper>
      )}
      {showNew && (
        <PopUp setShow={setShowNew}>
          <MarketNew close={() => setShowNew(false)} />
        </PopUp>
      )}
    </>
  );
};

export default MarketList;
