import { CONVERSION_NAMES } from "../components/validClaimForm/constants/accountsConversion";
import { accountsDataReducer } from "../components/validClaimForm/helpers/accountsDataReducer";
import { genItemsIds } from "../components/validClaimForm/helpers/getItemsIds";
import { VALID_CLAIM_PRIORITIES } from "../constants/priorities";
import { CLAIM_REASONS } from "../constants/support";
import { ClAIM_METHODS, CLAIM_TYPES } from "../constants/validClaims";
import { findMarketById } from "../functions/findMarketById";
import { useCommonDataContext } from "../hooks/commonDataContext";

const { createContext, useState, useRef, useReducer } = require("react");

export const ValidClaimContext = createContext();

export const ValidClaimProvider = ({
  children,
  claim,
  order,
  savedTags,
  savedContact,
}) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();
  const country = findMarketById(claim?.iban_market || order.market, markets);

  const [accountsDataState, dispatchAccountsData] = useReducer(
    accountsDataReducer,
    {
      [CONVERSION_NAMES.PREFIX]: claim?.prefix || "",
      [CONVERSION_NAMES.ACCOUNT]: claim?.account || "",
      [CONVERSION_NAMES.BANK_CODE]: claim?.bank_code || "",
    }
  );

  const [claimType, setClaimType] = useState(claim?.type || CLAIM_TYPES.REFUND);
  const [selectedOrderItems, setSelectedOrderItems] = useState(
    genItemsIds(claim || order)
  );
  const [claimPriority, setClaimPriority] = useState(
    claim?.priority || VALID_CLAIM_PRIORITIES.NONE.value
  );
  const [contact, setContact] = useState(savedContact);
  const [tagsOptions, setTagsOptions] = useState(savedTags || []);
  const [selectedTags, setSelectedTags] = useState(
    tagsOptions?.filter((o) => claim?.tags.includes(o.value)) || []
  );
  const [claimReason, setClaimReason] = useState(
    CLAIM_REASONS.find((r) => r.value === claim?.reason) || null
  );
  const [allMessages, setAllMessages] = useState(claim?.messages || []);
  const [claimStatus, setClaimStatus] = useState(claim?.status || "none");
  const [claimMethod, setClaimMethod] = useState(
    claim?.payment_method || ClAIM_METHODS.BANK_TRANSFER.value
  );
  const [showPayDate, setShowPayDate] = useState(false);
  const [ibanCountry, setIbanCountry] = useState({
    value: country._id,
    label: country.short.toUpperCase(),
  });
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const feedbackRef = useRef({ value: claim?.feedback || "" });
  const ibanRef = useRef({ value: claim?.iban || "" });
  const payDateRef = useRef();
  const commentForAccoutningRef = useRef();

  return (
    <ValidClaimContext.Provider
      value={{
        claimType,
        commentForAccoutningRef,
        setClaimType,
        selectedOrderItems,
        setSelectedOrderItems,
        claimPriority,
        setClaimPriority,
        contact,
        setContact,
        tagsOptions,
        setTagsOptions,
        selectedTags,
        setSelectedTags,
        claimReason,
        setClaimReason,
        allMessages,
        setAllMessages,
        claimStatus,
        setClaimStatus,
        claimMethod,
        setClaimMethod,
        showPayDate,
        setShowPayDate,
        ibanCountry,
        setIbanCountry,
        showAreYouSure,
        setShowAreYouSure,
        feedbackRef,
        ibanRef,
        payDateRef,
        savedContact,
        claim,
        order,
        savedTags,
        accountsDataState,
        dispatchAccountsData,
      }}
    >
      {children}
    </ValidClaimContext.Provider>
  );
};
