import styled from 'styled-components';

export const InputsWrapper = styled.div`
	width: 1000px;
	display: grid;
	gap: 40px;
	padding: 10px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
`;
