import moment from "moment";
import { getOrderItems } from "./getOrderItems";
import EditItem from "@/common/components/EditItem";

export const getRaws = (orders) => orders
.sort(
	(a, b) =>
		new Date(b.created_at).getDate() -
		new Date(a.created_at).getDate()
)
.map((order, i) => {
	return (
		<tr>
			<td>{i + 1}</td>
			<td>{order.status}</td>
			<td>
				{moment(order.created_at).format(
					"YYYY/MM/DD HH:mm:ss"
				)}
			</td>
			<td>
				{order._confirmed_by?.name
					? order._confirmed_by.name +
						" " +
						order._confirmed_by.surname
					: "-----"}
			</td>
			<td>{order._product.name}</td>
			<td>
				{getOrderItems(order).length
					? getOrderItems(order)
							.map((item) => item.product_variant)
							.join(", ")
					: "--------"}
			</td>
			<td style={{ textAlign: "center" }}>
				{getOrderItems(order).length
					? getOrderItems(order)
							.reduce((prev, next) => prev + next.price, 0)
							?.toFixed(2)
					: "------"}
			</td>
			<td>
				{order.shipping?.status
					? order.shipping.status
					: "-----"}
			</td>
			<td style={{ textAlign: "center" }}>
				<EditItem
					link={`/dashboard/order/${order._id}`}
					width={60}
				/>
			</td>
		</tr>
	);
})