import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import { CONSULTANT_MANAGER_ROLES, CONSULTANT_ROLES } from '@/common/constants/consultantRoles'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { ButtonWrapper } from '../../Bonus.styled'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { createBonus, updateBonus } from '@/API/repositories/bonus'
import Loading from '@/common/components/Loading'
import moment from 'moment'
import userManager from '@/API/userManager'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import SubmitButton from '@/common/components/buttons/SubmitButton'

const EDITABLE_DATE_ROLES = ["ADMIN"];

const BonusForm = ({ setShow, selectedBonus, reload = () => {} }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { 
    commonData: { users }, 
    filterUsersByRoles, 
    options: { usersOptions, productsOptions } 
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const canEditDate = useMemo(() => {
    const currentRole = userManager.getUser().role;

    return EDITABLE_DATE_ROLES.includes(currentRole) || !selectedBonus;
  }, [selectedBonus]);

  const consultantsOptions = useMemo(
    () => filterUsersByRoles(CONSULTANT_ROLES),
    [usersOptions]
  );
  const formik = useFormik({
    initialValues: {
      consultant: consultantsOptions.find(o => o.value === selectedBonus?.consultant),
      deliveredCommision: selectedBonus?.delivered_commision,
      mangerPercent: selectedBonus?.manger_percent,
      validFrom: selectedBonus ? moment(selectedBonus?.valid_from).format('YYYY-MM-DD') : null,
      validTo: selectedBonus ? moment(selectedBonus?.valid_to).format('YYYY-MM-DD') : null,
      product: productsOptions.find(o => o.value === selectedBonus?.product),
      additionalCommision: selectedBonus?.additional_commision,
      hourlyRate: selectedBonus?.hourly_rate,
      managerHourlyRate: selectedBonus?.manager_hourly_rate,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    const payload = {
      consultant: values.consultant.value,
      delivered_commision: values.deliveredCommision,
      manger_percent: values.mangerPercent,
      valid_from: new Date(values.validFrom).toISOString(),
      valid_to: new Date(values.validTo).toISOString(),
      product: values.product.value,
      additional_commision: values.additionalCommision,
      hourly_rate: values.hourlyRate,
      manager_hourly_rate: values.managerHourlyRate,
    }

    console.log(payload);

    if (!payload.mangerPercent) {
      delete payload.mangerPercent;
    }

    const requestFn = selectedBonus ? updateBonus : createBonus

    const response = await makeRequest(requestFn.bind(null, payload, selectedBonus?._id));

    if (!response?.data) {
      return addMessage("Check your data and try again", "error");
    }

    addMessage("Bonus created successfully", "success");
    setShow(false);
    reload();
  };

  const isManagerRoleSelected = (value) => {
    const user = users.find((user) => user._id === (value || formik.values.consultant?.value));
    return CONSULTANT_MANAGER_ROLES.includes(user?.role);
  }

  
  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(createBonus) && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <SelectInput
          color={Colors.darkBlue}
          name="Consultant"
          required
          hideStar
          options={consultantsOptions}
          width={250}
          setSelected={(value) => {
            formik.setFieldValue('consultant', value);
            if (!isManagerRoleSelected(value.value)) {
              formik.setFieldValue('mangerPercent', '');
            }
          }}
          selected={formik.values.consultant}
          value={formik.values.consultant}
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Product"
          required
          hideStar
          options={productsOptions}
          width={250}
          setSelected={(value) => formik.setFieldValue('product', value)}
          selected={formik.values.product}
          value={formik.values.product}
        />
        <Input
          id={"deliveredCommision"}
          color={Colors.darkBlue}
          name="Delivered commision"
          required
          width={250}
          type="number"
          value={formik.values.deliveredCommision}
          onChange={formik.handleChange}
        />
        <Input
          id={"additionalCommision"}
          onChange={formik.handleChange}
          name="Additional item commision"
          value={formik.values.additionalCommision}
          required
          color={Colors.darkBlue}
          type="number"
          width={250}
        />
        {isManagerRoleSelected() && (
         <>
          <Input
            id={"mangerPercent"}
            value={formik.values.mangerPercent}
            onChange={formik.handleChange}
            name="Manager percent"
            required
            color={Colors.darkBlue}
            type="number"
            width={250}
          />
          <Input
            id={"managerHourlyRate"}
            onChange={formik.handleChange}
            value={formik.values.managerHourlyRate}
            name="Manager hourly rate"
            required
            color={Colors.darkBlue}
            type="number"
            width={250}
          />
         </>
        )}
        <Input
          id={"hourlyRate"}
          onChange={formik.handleChange}
          value={formik.values.hourlyRate}
          name="Hourly rate"
          required
          color={Colors.darkBlue}
          type="number"
          width={250}
        />
        {canEditDate && (
          <>
            <Input
              id={"validFrom"}
              onChange={formik.handleChange}
              value={formik.values.validFrom}
              name="Valid from"
              required
              color={Colors.darkBlue}
              type="date"
              width={250} 
            />
            <Input
              id={"validTo"}
              onChange={formik.handleChange}
              value={formik.values.validTo}
              name="Valid to"
              required
              color={Colors.darkBlue}
              type="date"
              width={250} 
            />
          </>
        )}
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: "-30px" }} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default BonusForm
