import React, { useState } from "react";
import { getCCEffectiveSalaryPayment } from "../../../API/repositories/reports";
import { Colors } from "../../../common/colors/colors";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { handleMapToOptions } from "../../../common/functions/handleMapToOptions";
import Loading from "../../../common/components/Loading";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import { InputWrapper, SearchBar } from "./CCPayment.styled";
import PaymentData from "./components/paymentData/PaymentData";
import ActionButton from "@/common/components/buttons/ActionButton";

const CCPayment = () => {
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);
  const [consultantsFilterOption, setConsultantFilterOption] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState();
  const [paymentData, setPaymentData] = useState();
  const [forexRates, setForexRates] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const {
    commonData: { users },
  } = useCommonDataContext();

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.month = selectedMonth.value;

    const response = await makeRequest(
      getCCEffectiveSalaryPayment.bind(null, selectedMonth.value.toISOString())
    );

    if (response.data.result.length > 0) {
      const usersFromReport = response.data.result.map((result) => result._id);

      const result = users.filter((user) => usersFromReport.includes(user._id));

      const usersOptions = handleMapToOptions(result, "username", "_id");

      setConsultantFilterOption(() => usersOptions);
      setSelectedConsultant(() => usersOptions);
      setPaymentData(() => response.data.result);
      setForexRates(() => response.data.forexRates);

      return;
    }

    setPaymentData(() => []);
    setForexRates(() => []);
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getCCEffectiveSalaryPayment) && <Loading />}
      <SearchBar>
        <InputWrapper>
          <SelectInput
            name="Month"
            options={LAST_YEAR_MONTHS}
            color={Colors.darkBlue}
            width={50}
            setSelected={setSelectedMonth}
            selected={selectedMonth}
          />
          <div>
            <ActionButton
              defaultText="Search"
              onClick={(e) => hanldeSearch(e)}
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          {paymentData && (
            <SelectInput
              showLabel={false}
              options={consultantsFilterOption}
              color={Colors.darkBlue}
              width={100}
              selectWidth={850}
              setSelected={setSelectedConsultant}
              selected={selectedConsultant}
              multiple={true}
            />
          )}
        </InputWrapper>
      </SearchBar>
      {!!paymentData?.length && (
        <PaymentData
          paymentData={paymentData}
          selectedConsultant={selectedConsultant}
          forexRates={forexRates}
        />
      )}
    </WrapperWithScroll>
  );
};

export default CCPayment;
