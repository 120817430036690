import PopUp from '@/common/components/PopUp'
import React from 'react'
import Input from '@/common/components/Input'
import { Colors } from '@/common/colors/colors'
import { CANDIDATE_INTERACTION_STATUSES } from '@/common/constants/recruitmentStatuses'
import { ButtonWrapper } from '../../../../RecruitmentManager.styled';
import SubmitButton from '@/common/components/buttons/SubmitButton'

const StatusChange = ({ setShow, formik, status }) => {
  return (
    <PopUp setShow={setShow}>
     <form onSubmit={formik.handleSubmit}>
     {[CANDIDATE_INTERACTION_STATUSES.NO_RESPONSE, CANDIDATE_INTERACTION_STATUSES.SHIFT_CALL].includes(status) && (
        <Input
          id={"recall_date"}
          color={Colors.darkBlue}
          name="Recall date"
          value={formik.values.recall_date}
          onChange={(e) => {
            formik.setFieldValue('status', formik.values.stage_two_date ? CANDIDATE_INTERACTION_STATUSES.NEXT_PENDING : CANDIDATE_INTERACTION_STATUSES.PENDING)
            formik.setFieldValue('recall_date', e.target.value)
          }}
          required
          type='datetime-local'
          width={150}
        />
      )}
      {status === CANDIDATE_INTERACTION_STATUSES.ACCEPT && (
        <Input
          id={"stage_two_date"}
          color={Colors.darkBlue}
          name="Next stage date"
          value={formik.values.stage_two_date}
          onChange={(e) => {
            formik.setFieldValue('status', CANDIDATE_INTERACTION_STATUSES.NEXT_NEW)
            formik.setFieldValue('stage_two_date', e.target.value)
          }}
          required
          type='datetime-local'
          width={150}
        />
      )}
      <ButtonWrapper>
        <SubmitButton
          style={{ marginBottom: "-30px"}}
          text="Save"
        />
      </ButtonWrapper>
     </form>
    </PopUp>
  )
}

export default StatusChange
