import styled from "styled-components";

export const FormHeader = styled.h2`
  color: rgb(3, 80, 114);
  font-size: 25px;
  font-weight: bolder;
`;

export const RecipientsListContainer = styled.div`
  width: 800px;
  margin: 15px 0 0 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  grid-area: button;
`;

export const Title = styled.p`
  color: rgb(3, 80, 114);
  margin-top: 10px;
  font-size: 18px;
`;
