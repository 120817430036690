import { Colors } from "@/common/colors/colors";
import CSV from "@/common/components/CSV";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import React from "react";
import { HEADERS_CSV } from "../sentOrdersTable/constants/headers";
import ActionButton from "@/common/components/buttons/ActionButton";

const GroupOptions = [
  {
    label: "Lms Sent",
    value: "lms_sent",
  },
  {
    label: "Transport",
    value: "transport",
  },
];

const SentOrdersSearchBar = ({
  minusDateRef,
  handleSearch,
  selectedType,
  setSelectedType,
  results,
}) => {
  return (
    <SearchBarCenter>
      <div>
        <Input
          name="Minus days"
          width={180}
          color={Colors.darkBlue}
          inputRef={minusDateRef}
          type="number"
          required
          requiredSign
          inputWidth={140}
          min={0}
        />
      </div>
      <div>
        <SelectInput
          color={Colors.darkBlue}
          name="Status"
          options={GroupOptions}
          width={100}
          setSelected={setSelectedType}
          selected={selectedType}
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {results.length > 0 && <CSV header={HEADERS_CSV} data={results} />}
        <ActionButton defaultText="Search" onClick={(e) => handleSearch(e)} />
      </div>
    </SearchBarCenter>
  );
};

export default SentOrdersSearchBar;
