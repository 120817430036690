import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  addHoursByDateRangeConsultant,
  saveConsultantHours,
} from "@/API/repositories/consultantHours";
import { useRequestsContext } from "@/common/hooks/requestHook";
import CalendarElement from "./components/CalendarElement";
import {
  CalendarElementEmptyWrapper,
  CalendarWrapper,
} from "./calendar.styles";
import TopDates from "./components/TopDates";
import AddWorkedHours from "./components/AddWorkedHours";
import Loading from "@/common/components/Loading";
import { updateDocumentTicket } from "@/API/repositories/tickets";
import { HOURS_POSIBLES } from "@/common/constants/CC";
import styled from "styled-components";
import { getFileById } from "@/API/repositories/storedDocument";
import NewAddWorkedHours from "./components/NewAddWorkedHours";
import userManager from "@/API/userManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-left: -50px;
`;

const TopWrapperElement = styled.div`
  min-width: 150px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
`;

const Calendar = ({
  data,
  month,
  hanldeSearch,
  freeDays,
  bonuses,
}) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [workingHoursOneSave, setWorkingHoursOneSave] = useState({});
  const [generatedArray, setGeneratedArray] = useState([]);
  const [addWorkedHours, setAddWorkedHours] = useState();
  const [addWorkingHoursNew, setAddWorkingHoursNew] = useState();

  const {
    commonData: { users }
  } = useCommonDataContext();
  const { translation } = useTranslationContext();
  const { addMessage } = useMessageQueueContext();

  const firstDayOfTheMonth = moment(month.toDate()).utc(true).startOf("month");

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDeclineFile = async (ticketId) => {
    const payload = {};

    payload.ticket_status = "declined_pending";

    const response = await makeRequest(
      updateDocumentTicket.bind(null, ticketId, payload)
    );

    if (response?.data) {
      await hanldeSearch();
    }
  };

  const hanldeOnChange = (hour, sign, bonusId) => {
    if (HOURS_POSIBLES.includes(sign)) {
      workingHoursOneSave[hour] = {
        sign,
        bonusId,
      };
      setWorkingHoursOneSave(() => workingHoursOneSave);
      return;
    }
  };

  const handleSaveConsultantHours = async (e) => {
    e && e.preventDefault();

    const invalidFileds = Object.values(workingHoursOneSave).filter(
      (hour) => !HOURS_POSIBLES.includes(hour.sign)
    );

    if (invalidFileds.length > 0) {
      addMessage("Invalid sign in your worktime", "error");
      return;
    }

    const noBouns = Object.values(workingHoursOneSave).filter(
      (hour) => !hour.bonusId
    );

    if (noBouns.length > 0) {
      addMessage("Please select product for all hours", "error");
      return;
    }

    const response = await makeRequest(
      saveConsultantHours.bind(null, workingHoursOneSave, addWorkedHours)
    );

    if (response.data) {
      setWorkingHoursOneSave(() => null);
      setAddWorkedHours(() => null);
      await hanldeSearch();
    }
  };

  const handleRemoveConsultantHours = async (hour) => {
    setWorkingHoursOneSave((prev) => {
      delete prev[hour];
      return { ...prev };
    });
  }

  const generateArray = () => {
    const weekId = firstDayOfTheMonth.isoWeekday();

    const result = [...Array(weekId - 1).keys()].map(() => ({
      element: <CalendarElementEmptyWrapper />,
    }));

    result.push(
      ...[...Array(firstDayOfTheMonth.daysInMonth()).keys()]
        .map((number, i) => {
          const currentDay = firstDayOfTheMonth.clone().add(number, "days");

          const freeDay = freeDays.find((checkedFreeDay) =>
            checkedFreeDay.value.includes(currentDay.format("YYYY-MM-DD"))
          );

          const currentData = data?.find(
            (cd) =>
              moment(cd.for_day).format("YYYY-MM-DD") ===
              currentDay.format("YYYY-MM-DD")
          );

          return currentDay.isoWeekday() !== 7
            ? {
                element: (
                  <div
                    className={`fade__in__animation`}
                    style={{ animationDelay: `${i * 100 + 600}ms` }}
                  >
                    <CalendarElement
                      number={number}
                      day={currentDay}
                      freeDay={freeDay}
                      data={currentData}
                      setWorkingHoursOneSave={setWorkingHoursOneSave}
                      handleDeclineFile={handleDeclineFile}
                      setAddWorkedHours={setAddWorkedHours}
                      handleDownloadFile={handleDownloadFile}
                      setAddWorkingHoursNew={setAddWorkingHoursNew}
                    />
                  </div>
                ),
              }
            : {};
        })
        .filter((e) => e.element)
    );
    return setGeneratedArray(() => result);
  };

  const handleNewSaveWorkedHours = async (payload) => {
    const userId = userManager.getUser().id;
    const user = users.find((u) => u._id === userId);

    if (!user) {
      return addMessage("Refresh the page and try again", "error");
    }

    payload.consultant = user._id;
    payload.dailyHours = parseInt(user.contract_hours);
    payload.dont_include = freeDays.map((f) => f.date);

    const response = await makeRequest(addHoursByDateRangeConsultant.bind(null, payload));

    if (!response?.data) {
      return addMessage("Error while saving hours", "error");
    }
    
    await hanldeSearch(null, true);
    setAddWorkingHoursNew(() => null);
  };

  useEffect(() => {
    generateArray();
  }, [data]);


  return (
    <>
      {hasUnfilledRequest(getFileById) && <Loading />}
      {addWorkedHours && (
        <AddWorkedHours
          bonuses={bonuses}
          workingHoursOneSave={workingHoursOneSave}
          hanldeOnChange={hanldeOnChange}
          handleSaveConsultantHours={handleSaveConsultantHours}
          setAddWorkedHours={setAddWorkedHours}
          addWorkedHours={addWorkedHours}
          handleRemoveConsultantHours={handleRemoveConsultantHours}
        />
      )}
      {addWorkingHoursNew && (
        <NewAddWorkedHours
          addWorkingHoursNew={addWorkingHoursNew}
          bonuses={bonuses}
          handleNewSaveWorkedHours={handleNewSaveWorkedHours}
          setAddWorkingHoursNew={setAddWorkingHoursNew}
        />
      )}
      <TopWrapper>
        {Array.of(
          translation["Monday"],
          translation["Tuesday"],
          translation["Wednesday"],
          translation["Thursday"],
          translation["Friday"],
          translation["Saturday"]
        ).map((dayName, i) => (
          <TopWrapperElement
            className={`fade__in__animation`}
            style={{ animationDelay: `${i * 100}ms` }}
          >
            {dayName}
          </TopWrapperElement>
        ))}
      </TopWrapper>
      <CalendarWrapper>
        {data?.data && <TopDates />}
        {generatedArray.map((element) => element.element)}
      </CalendarWrapper>
    </>
  );
};

export default Calendar;
