
export const INVOICE_STATUSES_OPTIONS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "To correct",
    value: "to_correct",
  },
  {
    label: "Issued",
    value: "issued",
  },
  {
    label: "Done",
    value: "done",
  },
];