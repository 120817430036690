export const HEADERS = [
	"No.",
	"Sender",
	"Customer",
	"Type",
	"Opened Times",
	"Clicked Times",
	"Spam Reported Times",
	"Created At",
	"Updated At",
	"Processed At",
	"Delivered At",
	"Dropped At",
	"Bounced At",
	"Bounced Type",
	"First Open At",
	"First Click At",
	"Fisrt Spam Report At",
	"Actions",
];
