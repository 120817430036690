import SelectInput from "@/common/components/SelectInput";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { BorderLine, ColorTitle, OptionWrapper } from "../../../../FilterOptons.styled";

export const FilterOptionFinderObjectId = ({
  fieldData,
  filters,
  dispatchFilters,
  markets,
  products,
  consultants,
}) => {
  const { commonData: { queues } } = useCommonDataContext();
  const handleOnChangeEnum = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      value: value.map((v) => v.value),
      type_field: "Ref",
    });
  };

  let options = [];
  const current = filters[fieldData.label]?.value;

  if (fieldData.value.ref === "Queue") {
    options = handleMapToOptions(queues, 'name', '_id');
  }

  if (fieldData.value.ref === "Product") {
    options = products;
  }

  if (fieldData.value.ref === "Market") {
    options = markets;
  }

  if (fieldData.value.ref === "User") {
    options = consultants;
  }

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={options}
        showLabel={false}
        selected={options.filter((option) => current?.includes(option.value))}
        setSelected={(e) => handleOnChangeEnum(e)}
        multiple={true}
        selectWidth={500}
      />
    </OptionWrapper>
  );
};