import { getOperatorPaymentReport } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  HeadingWrapper,
  TableWrapper,
} from "./OperatorPaymentReport.styled";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import Table from "@/common/components/Table";
import { getOperatorPaymentRows } from "./helpers/getOperatorPaymentRows";
import { formatToDateTamplate } from "@/common/functions/dateFormater";

const REFUNDED = "REFUNDED";

const HEADERS_EXCEL = [
  "Lp.",
  "Email",
  "Status Completed Date",
  "Price",
  "Currency",
  "Exchange Rate",
  "Converted Amount",
  "Converted Currency",
  "Payment Type",
  "Fee",
];
const HEADERS = [
  [
    <th>No</th>,
    <th>Customer</th>,
    <th>Transaction Date</th>,
    <th>Amount</th>,
    <th>Currency</th>,
    <th>Currency Exchange</th>,
    <th>Converted Amount</th>,
    <th>Converted Currency</th>,
    <th>Type</th>,
    <th>Fee</th>,
  ],
];

const MONTHS_OPTIONS = () => {
  const start = moment("2024-07-01");
  const today = moment().startOf("month");

  const diff = today.diff(start, "month");

  const months = [];

  for (let i = 0; i <= diff; i++) {
    const currentMonth = today.clone().subtract(i, "month");
    months.push({
      label: currentMonth.format("MMMM YYYY"),
      value: currentMonth.format("YYYY-MM-DD"),
    });
  }

  return months;
};

const OperatorPaymentReport = () => {
  const [selectedMonth, setSelectedMonth] = useState(MONTHS_OPTIONS()[0]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const [data, setData] = useState([]);

  const excelData = useMemo(() => {
    if (data) {
    }

    return [];
  }, [data]);

  const tableData = useMemo(() => {
    if (selectedCurrency && data) {
      const dataToMap = data.filter(
        (d) => d.currency === selectedCurrency.value
      );

      return getOperatorPaymentRows(dataToMap);
    }

    return [];
  }, [data, selectedCurrency]);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const sortDataByTypeAndDate = (data) => {
    return data.sort((a, b) => {
      const aIsRefund = a.payment.type === REFUNDED;
      const bIsRefund = b.payment.type === REFUNDED;

      const aDate = aIsRefund
        ? new Date(a.payment.status_refunded_date)
        : new Date(a.payment.status_complated_date);
      const bDate = bIsRefund
        ? new Date(b.payment.status_refunded_date)
        : new Date(b.payment.status_complated_date);

      return bDate.getTime() - aDate.getTime();
    });
  };

  const loadData = async () => {
    const payload = {
      month: selectedMonth.value,
    };

    const response = await makeRequest(
      getOperatorPaymentReport.bind(null, payload)
    );

    if (!response?.data) {
      return addMessage("Unable to load data", "error");
    }

    const currencyOptions = [
      ...new Set(response.data.map((d) => d.currency)),
    ].map((co) => ({ label: co, value: co }));

    setSelectedCurrency(() => currencyOptions[0]);
    setCurrencyOptions(() => currencyOptions);

    sortDataByTypeAndDate(response.data);

    setData(() => response.data);
  };

  useEffect(() => {
    if (selectedMonth) {
      loadData();
    }
  }, [selectedMonth]);

  const exportToExcel = async () => {
    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();

    const groupedData = data.reduce((acc, item) => {
      const currency = item.currency || "Unknown";
      if (!acc[currency]) acc[currency] = [];
      acc[currency].push(item);
      return acc;
    }, {});

    Object.entries(groupedData).forEach(([currency, rows]) => {
      const sheet = workbook.addWorksheet(currency);

      sheet.defaultRowHeight = 20;
      sheet.addRow(HEADERS_EXCEL);

      const headerRow = sheet.getRow(1);
      headerRow.font = { bold: true, size: 12 };
      headerRow.alignment = { vertical: "middle", horizontal: "center" };
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "6dbf9e" },
      };
      headerRow.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      rows.forEach((d, i) => {
        const isRefund = d.payment.type === REFUNDED;

        const date = !isRefund
          ? formatToDateTamplate(d.payment.status_complated_date)
          : formatToDateTamplate(d.payment.status_refunded_date || "N/A");

        const price = !isRefund ? d.payment.price : d.payment.price * -1;

        const exchangeRate = !isRefund
          ? d.payment.payment_details.exchange_rate
          : d.payment.payment_details.refunded_exchange_rate || "N/A";

        const convertedAmount = !isRefund
          ? d.payment.payment_details.converted_amount / 100
          : d.payment.payment_details.refunded_converted_amount / 100 || 0;

        const fee = !isRefund ? d.payment.payment_details.fee / 100 || 0 : 0;

        sheet.addRow([
          i + 1,
          d.contact.email,
          date,
          price.toFixed(2).replace(".", ","),
          d.currency,
          exchangeRate,
          convertedAmount.toFixed(2).replace(".", ","),
          d.payment.payment_details.converted_currency,
          d.payment.type,
          fee.toFixed(2).replace(".", ","),
        ]);
      });

      const summary = rows.reduce(
        (acc, next) => {
          const isRefund = next.payment.type === REFUNDED;
          const price = isRefund ? next.payment.price * -1 : next.payment.price;
          const convertedAmount = isRefund
            ? next.payment.payment_details.refunded_converted_amount / 100
            : next.payment.payment_details.converted_amount / 100;
          const fee = isRefund
            ? 0
            : (next.payment.payment_details.fee || 0) / 100;

          if (next.payment.type === "COMPLETED") {
            acc.COMPLETED.count++;
            acc.COMPLETED.totalAmount += price;
            acc.COMPLETED.convertedAmount += convertedAmount;
            acc.FEE += fee;
          } else if (next.payment.type === "REFUNDED") {
            acc.REFUNDED.count++;
            acc.REFUNDED.totalAmount += price;
            acc.REFUNDED.convertedAmount += convertedAmount;
          }

          acc.currency = next?.currency?.toUpperCase();
          return acc;
        },
        {
          COMPLETED: { count: 0, totalAmount: 0, convertedAmount: 0 },
          REFUNDED: { count: 0, totalAmount: 0, convertedAmount: 0 },
          FEE: 0,
          CURRENCY: "EUR",
        }
      );

      sheet.addRow([]);
      const summaryRow1 = sheet.addRow(["", "Summary"]);
      const summaryRowIndex = summaryRow1.number;
      sheet.mergeCells(`B${summaryRowIndex}:E${summaryRowIndex}`);

      summaryRow1.getCell(2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      summaryRow1.getCell(2).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const summaryRow2 = sheet.addRow([
        "",
        "",
        "Liczba operacji",
        `Amount (${summary.CURRENCY})`,
        "Converted Amount / PLN	",
      ]);

      const summaryRow3 = sheet.addRow([
        "",
        "COMPLETED",
        summary.COMPLETED.count,
        summary.COMPLETED.totalAmount.toFixed(2).replace(".", ","),
        summary.COMPLETED.convertedAmount.toFixed(2).replace(".", ","),
      ]);

      const summaryRow4 = sheet.addRow([
        "",
        "REFUNDED",
        summary.REFUNDED.count,
        summary.REFUNDED.totalAmount.toFixed(2).replace(".", ","),
        summary.REFUNDED.convertedAmount.toFixed(2).replace(".", ","),
      ]);

      const summaryRow5 = sheet.addRow([
        "",
        "FEE",
        "",
        "",
        summary.FEE.toFixed(2).replace(".", ","),
      ]);

      // Ustaw obramowanie dla tabelki podsumowującej
      [summaryRow2, summaryRow3, summaryRow4, summaryRow5].forEach((row) => {
        row.eachCell((cell, i) => {
          console.log(i);
          if (i > 1) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      });

      // Ustaw szerokość kolumn i wyrównanie
      sheet.columns.forEach((col) => {
        col.width = 15;
      });

      sheet.eachRow((row) => {
        row.alignment = { vertical: "middle", horizontal: "center" };
      });
    });

    // Generowanie pliku Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `PAYMENT_REPORT_${moment(selectedMonth.value)
      .format("MMMM/YYYY")
      .toUpperCase()}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Container>
      {hasUnfilledRequest(getOperatorPaymentReport) && <Loading />}
      <HeadingWrapper>
        <SelectInput
          name="Month"
          color={Colors.darkBlue}
          options={MONTHS_OPTIONS()}
          selected={selectedMonth}
          setSelected={setSelectedMonth}
          width={100}
        />
        <SelectInput
          name="Currency"
          color={Colors.darkBlue}
          options={currencyOptions}
          selected={selectedCurrency}
          setSelected={setSelectedCurrency}
          width={100}
        />
        <div onClick={() => exportToExcel()}>
          <i
            className="animation-scale fa-solid fa-file-excel"
            style={{
              fontSize: `26px`,
              cursor: "pointer",
              color: Colors.darkBlue,
            }}
          />
        </div>
      </HeadingWrapper>
      <TableWrapper>
        {!!data?.length && (
          <Table
            stickyHeader
            className="styled-table"
            headersArray={HEADERS}
            raws={tableData}
          />
        )}
      </TableWrapper>
    </Container>
  );
};

export default OperatorPaymentReport;
