import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 40px;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 30px;
	margin: 20px;
	justify-content: center;
`;
