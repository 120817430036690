import React from "react";
import PopUp from "../../../../../common/components/PopUp";
import styled from "styled-components";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  h3 {
    color: red;
    text-align: center;
  }
`;

const NoAswer = ({ setClose }) => {
  const { translation } = useTranslationContext();

  return (
    <PopUp setShow={setClose}>
      <Wrapper>
        <h3>{translation.no_answer}</h3>
      </Wrapper>
    </PopUp>
  );
};

export default NoAswer;
