import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 20px;
  gap: 10px;
  color: ${Colors.darkBlue};
`;
