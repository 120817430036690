import React from "react";
import styled from "styled-components";
import { formatDate } from "../../../../common/functions/dateFormater";

const Wrapper = styled.div`
  width: 1000px;
`;

const MarketPromotionTable = ({
  promotion,
  region,
  setPromotion,
  setAddPromotion,
}) => {
  return (
    <Wrapper>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th colSpan={5}>{`Promitions List (newest -> oldest)`}</th>
          </tr>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Promotion Label</th>
            <th colSpan={2}>Promotion</th>
            <th rowSpan={2}>Actions</th>
          </tr>
          <tr>
            <th>Start</th>
            <th>End</th>
          </tr>
        </thead>
        <tbody className="queue">
          {promotion[0] ? (
            promotion.map((reg, i) => (
              <tr>
                <td>{i + 1}.</td>
                <td>
                  {region
                    .filter((regio) => reg.regions.includes(regio._id))
                    .map((r) => r.label)
                    .join(", ")}
                </td>
                <td style={{ minWidth: "150px" }}>
                  {formatDate(reg.from, "YYYY/MM/DD HH:mm")}
                </td>
                <td style={{ minWidth: "150px" }}>
                  {formatDate(reg.to, "YYYY/MM/DD HH:mm")}
                </td>
                <td>
                  <i
                    className="fa fa-edit animation-scale"
                    onClick={() => {
                      setAddPromotion(() =>
                        region.filter((regio) =>
                          reg.regions.includes(regio._id)
                        )
                      );
                      setPromotion(reg);
                    }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>There is no data</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default MarketPromotionTable;
