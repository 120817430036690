import { PageWrapper } from "@/common/styles/Wrappers";
import { useFormik } from "formik";
import React, { useState } from "react";
import { PERIOD_OF_TIME_OPTIONS } from "./constants";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getCPLPaymentProductReport } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import ProductCPLTable from "./components/productCPLTable/ProductCPLTable";
import { formatBeforeSubmit } from "./helpers/formatBeforeSubmit";
import ProductCPLSearchBar from "./components/productCPLSearchBar/ProductCPLSearchBar";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const PaymentProductCPLReport = () => {
  const [cplData, setCplData] = useState();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleSubmit = async (values) => {
    if (!values) {
      return;
    }

    const response = await makeRequest(
      getCPLPaymentProductReport.bind(null, values)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setCplData(() => response.data);
  };

  const formik = useFormik({
    initialValues: {
      products: [],
      time_period: PERIOD_OF_TIME_OPTIONS[0],
      period_from:
        PERIOD_OF_TIME_OPTIONS[0].period_from[
          PERIOD_OF_TIME_OPTIONS[0].default_back
        ],
      period_to: PERIOD_OF_TIME_OPTIONS[0].period[0],
    },
    onSubmit: (values) => handleSubmit(formatBeforeSubmit(values, addMessage)),
  });

  return (
    <PageWrapper>
      {hasUnfilledRequest(getCPLPaymentProductReport) && <Loading />}
      <ProductCPLSearchBar
        formik={formik}
        setCplData={setCplData}
        cplData={cplData}
      />
      {cplData && <ProductCPLTable values={formik.values} cplData={cplData} />}
    </PageWrapper>
  );
};

export default PaymentProductCPLReport;
