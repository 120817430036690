import React from "react";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { getFileById } from "@/API/repositories/storedDocument";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findUserMarket } from "@/common/functions/findUserMarket";
import { getGamificationBonus } from "@/pages/admin/ccPayment/components/paymentData/components/paymentSummarizeTable/helpers/getGamificationBonus";
import userManager from "@/API/userManager";
import Table from "@/common/components/Table";
import { getRaws } from "./helpers/getRaws";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const EffectivePaymentSummarize = ({ summarizeData }) => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const currentUser = userManager.getUser();

  const {
    commonData: { markets, queues, users },
  } = useCommonDataContext();

  const handleDownloadFile = async (storedDataId) => {
    const response = await makeRequest(
      getFileById.bind(null, storedDataId, true)
    );

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const userMarket = findUserMarket(markets, queues, currentUser.id);
  summarizeData.forEach((monthData) => {
    const gamificationBonus = monthData.consultantResults?._gamification_results
      ? getGamificationBonus({
          consultantResults: monthData.consultantResults,
          paymentData: monthData.commonResult,
          userMarket,
          markets,
          queues,
          users,
        })
      : 0;

    monthData.gamificationBonus = gamificationBonus;
  });

  const HEADERS = [
    translation.month,
    translation.hours,
    translation.hours_payment,
    translation.effective_salary,
    translation.payment,
    translation.bonus,
    translation.gamification_bonus,
    translation.equalization,
    translation.payout_slip,
  ];

  return (
    <>
      {hasUnfilledRequest(getFileById) && <Loading />}
      <Table
        className="styled-table"
        headers={HEADERS}
        raws={getRaws(summarizeData, handleDownloadFile)}
      />
    </>
  );
};

export default EffectivePaymentSummarize;
