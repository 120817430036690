import React, { useEffect, useState } from "react";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import { formatToDateTamplate } from "../../../common/functions/dateFormater";
import { useFormik } from "formik";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import { getConsultantManagerRecordedCalls } from "../../../API/repositories/calling";
import Loading from "../../../common/components/Loading";
import { getTranscriptionById } from "../../../API/repositories/transcription";
import MediaPlayer from "../../../common/components/MediaPlayer";
import SelectInput from "../../../common/components/SelectInput";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import {
  Wrapper,
  SearchBar,
  InfoWrapper,
  ButtonWrapper,
} from "./headCcManagerScoringCalls.styled";
import CurrentWeekScoreTable from "./components/CurrentWeekScoreTable";
import ScoringCreate from "@/pages/consultant/consultantManagerScoringListening/components/ScoringCreate";
import ImporvementTicket from "@/pages/admin/listeningScores/components/ImporvementTicket";
import { createCallingImprovementTicket } from "@/API/repositories/tickets";
import Transcriptions from "@/common/components/transcriptions/Transcriptions";
import { getRecordingById } from "@/API/repositories/storedDocument";
import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";

const HeadCcManagerScoringCalls = () => {
  const [currentScore, setCurrentScore] = useState();
  const [ticket, setTicket] = useState();
  const [showEditCurrent, setShowEditCurrent] = useState();
  const [data, setData] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [recording, setRecording] = useState(null);
  const [transcriptions, setTranscriptions] = useState();
  const [team, setTeam] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  const handleGetRecording = async (e, element) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getRecordingById.bind(null, element.stored_data)
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(() => url);
      // setCurrentScore(() => ({ ...element, url }));
    }
  };

  const handleGetTranscription = async (element) => {
    const response = await makeRequest(
      getTranscriptionById.bind(null, element.stored_data)
    );

    if (response?.data) {
      setTranscriptions(() => response.data);
    }
  };

  const handleGeToRate = async (e, element) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getRecordingById.bind(null, element.stored_data)
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setCurrentScore(() => ({ ...element, url }));
    }
  };

  const handleSearch = async (values) => {
    values.fromDate = moment(values.fromDate).startOf("day").toISOString();
    values.toDate = moment(values.toDate).endOf("day").toISOString();

    const payload = {
      fromDate: moment(values.fromDate).startOf("day").toISOString(),
      toDate: moment(values.toDate).endOf("day").toISOString(),
      consultants: selectedConsultants.map((c) => c.value),
    };

    if (values.number) {
      payload.number = values.number;
    }

    const response = await makeRequest(
      getConsultantManagerRecordedCalls.bind(null, payload)
    );

    if (response.data) {
      const { table, data } = response.data;

      setData({
        table,
        generated: data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ),
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: formatToDateTamplate(new Date()),
      toDate: formatToDateTamplate(new Date()),
    },
    onSubmit: (values) => handleSearch(values),
  });

  const laodData = async () => {
    const validatedUsers = users.filter(
      (d) => CONSULTANT_ROLES.includes(d.role) && d.active
    );

    const options = validatedUsers.map((u) => ({
      value: u._id,
      label: u.username,
    }));

    setTeam(() => options);
    setSelectedConsultants(options);
  };

  useEffect(() => {
    laodData();
  }, [users]);

  return (
    <>
      {hasUnfilledRequest(
        getConsultantManagerRecordedCalls,
        getRecordingById,
        createCallingImprovementTicket,
        getTranscriptionById
      ) && <Loading />}
      <Wrapper>
        <SearchBar>
          <InfoWrapper>
            <Input
              type="date"
              name={"From"}
              id="fromDate"
              value={formik.values.fromDate}
              onChange={formik.handleChange}
              width={40}
              inputWidth={130}
              color={Colors.darkBlue}
            />
            <Input
              type="date"
              name={"To"}
              id="toDate"
              value={formik.values.toDate}
              onChange={formik.handleChange}
              width={20}
              inputWidth={130}
              color={Colors.darkBlue}
            />
            <Input
              name={"Number"}
              id="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              width={60}
              inputWidth={260}
              color={Colors.darkBlue}
              placeholder="Type without country code"
            />
          </InfoWrapper>
          <SelectInput
            name={"Consultants"}
            id="consultants"
            selected={selectedConsultants}
            setSelected={setSelectedConsultants}
            options={team}
            width={100}
            multiple={true}
            selectWidth={775}
            color={Colors.darkBlue}
            showLabel={false}
          />
          <ButtonWrapper>
            <ActionButton
              defaultText={"Search"}
              onClick={formik.handleSubmit}
            />
          </ButtonWrapper>
        </SearchBar>
        {data && data?.generated[0] && (
          <CurrentWeekScoreTable
            handleGetTranscription={handleGetTranscription}
            data={data.generated}
            handleGetRecording={handleGetRecording}
            handleGeToRate={handleGeToRate}
            setShowTicket={setTicket}
          />
        )}
      </Wrapper>
      {recording && (
        <MediaPlayer
          noDownload={true}
          recording={recording}
          setClose={setRecording}
        />
      )}
      {transcriptions && (
        <Transcriptions
          transcriptions={transcriptions}
          setClose={setTranscriptions}
        />
      )}
      {currentScore && (
        <ScoringCreate
          setCurrentScore={setCurrentScore}
          currentScore={currentScore}
          handleLoadData={formik.handleSubmit}
          setToScore={setData}
          showEditCurrent={showEditCurrent}
          toScore={data}
        />
      )}
      {ticket && (
        <ImporvementTicket
          showTicketTalkingImprovement={ticket}
          setShowTicketTalkingImprovement={setTicket}
        />
      )}
    </>
  );
};

export default HeadCcManagerScoringCalls;
