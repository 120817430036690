export const getCSVData = (toCsv, consultants) => {
	const result = [];

	toCsv.map((consultantRow) => {
		consultantRow.wppk
			.sort((a, b) => parseInt(a.dequeueCount) - parseInt(b.dequeueCount))
			.map((row) => {
				const temp = [];

				temp.push(
					consultants.find(
						(consultant) => consultant.value === consultantRow.index
					).label
				);
				temp.push(row.dequeueCount);
				temp.push(row.downloaded);
				temp.push(row.costs);
				temp.push(row.deliverd);
				temp.push(row.deliveredSum);
				temp.push(row.wppk);
				temp.push(row.grade);

				result.push(temp);
			});

		const temp = [];

		temp.push(
			consultants.find(
				(consultant) => consultant.value === consultantRow.index
			).label
		);
		temp.push("Total");
		temp.push(consultantRow.wppkSum.downloadedSum);
		temp.push(consultantRow.wppkSum.costsSum);
		temp.push(consultantRow.wppkSum.deliverdSum);
		temp.push(consultantRow.wppkSum.deliveredValueSum);
		temp.push(consultantRow.wppkSum.wppk);
		temp.push(consultantRow.wppkSum.grade);

		result.push(temp);
	});

	return result;
};