import React, { useState } from "react";
import { Colors } from "@/common/colors/colors";
import {
  ConversatgionGroupWrapper,
  ConversationGroupTitleWrapper,
  ActionIcon,
  NoDataWrapper,
  Hover,
  CloseIconWrapper,
} from "./ConversationGroup.styled";
import ConversationUsers from "./components/conversationUsers/ConversationUsers";
import TicketChangeTitle from "./components/ticketChangeTitle/TicketChangeTitle";
import ConversationActions from "./components/conversationActions/ConversationActions";
import { updateTicketPriority } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Icon from "@/common/components/Icon";
import SelectInput from "@/common/components/SelectInput";
import { PRIORITIES_OPTIONS } from "@/common/constants/questionTicket";
import ConversationDwUsers from "../conversationDwUsers/ConversationDwUsers";
import ContactView from "@/pages/consultant/calling/ticket/ContactView/ContactView";

const ConversationGroup = ({
  selectedTicket,
  handleCloseTicketView,
  loadData,
  setShowlisteningObjections,
  setRecording,
  isCallingTicket,
  handleEndCall,
  setIsTicketViewOpen,
  departments,
}) => {
  const [isContactCallOpen, setIsContactCallOpen] = useState();
  const [isPriorityUpdating, setIsPriorityUpdating] = useState();

  const { makeRequest } = useRequestsContext();

  const handleUpdatePriority = async (value) => {
    setIsPriorityUpdating(() => true);
    const response = await makeRequest(
      updateTicketPriority.bind(null, selectedTicket?._id, value)
    );

    if (response?.data) {
      await loadData();
    }

    setIsPriorityUpdating(() => false);
  };

  return (
    <>
      <ConversatgionGroupWrapper>
        <CloseIconWrapper>
          <Hover
            onClick={() => setIsTicketViewOpen(false)}
            label="Close tickets"
          >
            <ActionIcon className="fa-solid fa-x" color={Colors.newGray} />
          </Hover>
        </CloseIconWrapper>
        {selectedTicket ? (
          <div>
            <ConversationGroupTitleWrapper>
              <ConversationUsers
                departments={departments}
                selectedTicket={selectedTicket}
              />
              <TicketChangeTitle
                departments={departments}
                selectedTicket={selectedTicket}
                loadData={loadData}
              />
              <ConversationActions
                selectedTicket={selectedTicket}
                isCallingTicket={isCallingTicket}
                handleCloseTicketView={handleCloseTicketView}
                setRecording={setRecording}
                loadData={loadData}
                setShowlisteningObjections={setShowlisteningObjections}
                setIsContactCallOpen={setIsContactCallOpen}
              />
            </ConversationGroupTitleWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ConversationDwUsers
                departments={departments}
                selectedTicket={selectedTicket}
                loadData={loadData}
              />
              {selectedTicket?.type === "question" ? (
                isPriorityUpdating ? (
                  <div
                    style={{
                      paddingRight: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                      flexGrow: 1,
                      margin: "17px 0",
                    }}
                  >
                    <Icon size={24} name="fa fa-spinner fa-spin" />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexGrow: 1,
                    }}
                  >
                    <SelectInput
                      selectWidth={120}
                      width={40}
                      fontSize={14}
                      selectFontSize={12}
                      options={PRIORITIES_OPTIONS}
                      name="Priority"
                      strong={false}
                      selected={PRIORITIES_OPTIONS.find(
                        (o) => o.value === !!selectedTicket?.prioritized
                      )}
                      setSelected={(option) =>
                        handleUpdatePriority(option.value)
                      }
                    />
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <NoDataWrapper>No data</NoDataWrapper>
        )}
      </ConversatgionGroupWrapper>
      {isContactCallOpen && (
        <ContactView
          contact={selectedTicket.contact}
          setContactView={setIsContactCallOpen}
          handleEndCall={handleEndCall}
        />
      )}
    </>
  );
};

export default ConversationGroup;
