import moment from "moment";
import React from "react";
import styled from "styled-components";
import { NavigateLink } from "@/common/components/links/NavigateLink";
import Icon from "@/common/components/Icon";
import { Colors } from "@/common/colors/colors";
import { formatPhoneNumber } from "@/common/functions/formatPhoneNumber";

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  gap: 10px;
`;

const THead = styled.thead`
  th {
    background-color: #a34494;
  }
`;

const TBody = styled.tbody`
  td {
    background-color: #ffefd0;
  }
`;

export const TalkingNow = ({ data = [] }) => {
  return (
    <table
      className="styled-table"
      style={{ minWidth: "650px", maxWidth: "650px" }}
    >
      <THead>
        <tr>
          <th colSpan={6}>Talking Users</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>Username</th>
          <th>Number</th>
          <th>Answered at</th>
          <th>Direction</th>
          <th>Actions</th>
        </tr>
      </THead>
      <TBody className="queue">
        {data.length > 0 ? (
          data
            .sort(
              (a, b) =>
                new Date(b.talking_at).getTime() -
                new Date(a.talking_at).getTime()
            )
            .map((element, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{element.username}</td>
                <td>{formatPhoneNumber(element.phone_number)}</td>
                <td>{moment(element.talking_at).format("DD/MM HH:mm:ss")}</td>
                <td
                  style={{
                    color:
                      element.type === "inbound" ? Colors.red : Colors.green,
                  }}
                >
                  {element.type}
                </td>
                <td>
                  <IconsWrapper>
                    <NavigateLink
                      target="_blank"
                      to={`/settings/user/list?mode=show&userId=${element.userId}`}
                    >
                      <Icon
                        name="fa fa-user animation-scale"
                      />
                    </NavigateLink>
                    <NavigateLink
                      target="_blank"
                      to={`/dashboard/contact/${element.contact}`}
                    >
                      <Icon
                        name="fa fa-address-book animation-scale"
                      />
                    </NavigateLink>
                    <NavigateLink
                      target="_blank"
                      to={`/dashboard/order/${element.order}`}
                    >
                      <Icon
                        name="fa fa-address-card animation-scale"
                      />
                    </NavigateLink>
                  </IconsWrapper>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={6}>There is no users</td>
          </tr>
        )}
      </TBody>
    </table>
  );
};
