import React from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapper = styled.div`
  padding: 20px 20px 60px 20px;
  border-radius: 10px;
  background-color: white;
  max-width: 1200px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: right;
  color: ${Colors.darkBlue};
  font-weight: bold;
  margin: 0 0 20px 0;
`;

const StyledBorderItem = styled.div`
  padding: 5px;
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 10px;
`;

const GeneralStatistics = ({ data }) => {
  const { translation } = useTranslationContext();

  const calcByStatus = (dataToCalc, status) => {
    return dataToCalc.filter((a) => a.status === status).length;
  };
  const calcMails = (dataToCalc) => {
    return dataToCalc.length;
  };

  return (
    <Wrapper>
      <Flex>
        <StyledBorderItem>{translation.general}</StyledBorderItem>
      </Flex>
      <table style={{ minWidth: "1000px" }} className="styled-table">
        <thead>
          <tr>
            <th>{translation.confirmed} </th>
            <th>{translation.shifts}</th>
            <th>{translation.resigned}</th>
            <th>{translation.mails}</th>
          </tr>
        </thead>
        <tbody className="queue">
          <tr>
            <td>
              {calcByStatus(
                [
                  ...data.getTodayOutboundContacts,
                  ...data.getTodayInboundContacts,
                ],
                "confirmed"
              )}
            </td>
            <td>
              {calcByStatus(
                [
                  ...data.getTodayOutboundContacts,
                  ...data.getTodayInboundContacts,
                ],
                "shift"
              )}
            </td>
            <td>
              {calcByStatus(
                [
                  ...data.getTodayOutboundContacts,
                  ...data.getTodayInboundContacts,
                ],
                "resigned"
              )}
            </td>
            <td>{calcMails(data.findTodayMails)}</td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};

export default GeneralStatistics;
