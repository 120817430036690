import { createInvoiceTicket } from "@/API/repositories/tickets";
import ActionButton from "@/common/components/buttons/ActionButton";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { Colors } from "chart.js";
import React, { useRef } from "react";

const OldWayCreate = ({ orderId, setShow }) => {
  const id = orderId;

  const companyRef = useRef();
  const vatNumberRef = useRef();
  const otherNumberRef = useRef();
  const companyStreetAndNumberRef = useRef();
  const companyPostalCodeRef = useRef();
  const companyCityRef = useRef();

  const { makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();
  const { addMessage } = useMessageQueueContext();

  const handleTicketInvoiceCreate = async (e) => {
    e && e.preventDefault();
    const payload = {};
    if (!vatNumberRef.current.value && !otherNumberRef.current.value) {
      return addMessage("Add all data", "error");
    }
    payload.company_name = companyRef.current.value;
    payload.company_street_and_number = companyStreetAndNumberRef.current.value;
    payload.company_postal_code = companyPostalCodeRef.current.value;
    payload.company_company = companyCityRef.current.value;
    payload.company_city = companyCityRef.current.value;
    payload.vat_number = vatNumberRef.current.value;
    payload.other_number = otherNumberRef.current.value;
    const response = await makeRequest(
      createInvoiceTicket.bind(null, id, payload)
    );
    if (response.data) {
      setShow(() => false);
      addMessage("Success", "success");
    } else {
      addMessage("Error", "error");
    }
  };
  return (
    <PopUp setShow={setShow}>
      <h4 style={{ color: Colors.darkBlue, marginBottom: "30px" }}>
        Invoice ticket:
      </h4>
      <form>
        <Input
          inputRef={companyRef}
          width={170}
          name={translation.company_name}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={companyStreetAndNumberRef}
          width={170}
          name={translation.company_street_and_number}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={companyPostalCodeRef}
          width={170}
          name={translation.company_postal_code}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={companyCityRef}
          width={170}
          name={translation.company_city}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={vatNumberRef}
          width={170}
          name={translation.vat_id}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={otherNumberRef}
          width={170}
          name={translation.company_id}
          color={Colors.darkBlue}
        />
        <ActionButton
          defaultText="Create Ticket"
          style={{ float: "right", margin: "20px 0px 0px -20px" }}
          onClick={(e) => handleTicketInvoiceCreate(e)}
        />
      </form>
    </PopUp>
  );
};

export default OldWayCreate;
