import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;

export const getUserNameAndSurnameById = (id) =>
  errorHandler(async () => {
    const data = await api.get(`/user/short-info/${id}`);
    return { data: data.data };
  });

export const createUsersByCSV = (users) =>
  errorHandler(async () => {
    const data = await api.post(`/user/create-by-csv`, users);
    return { data: data.data };
  });

export const findConsultantInfo = () =>
  errorHandler(async () => {
    const data = await api.get(`/user/consultant/get-consultant-info-profile`);
    return { data: data.data };
  });

export const getConsultantInfo = () =>
  errorHandler(async () => {
    const data = await api.get(`/user/consultant/get-consultant-info`);
    return { data: data.data };
  });

export const createUser = (user) =>
  errorHandler(async () => {
    const data = await api.post("/user", user);
    return { data: data.data };
  });

export const getUsers = () =>
  errorHandler(async () => {
    const data = await api.get("/user");
    return { data: data.data };
  });

export const updateUser = (id, user) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/${id}`, user);
    return { data: data.data };
  });

export const addPersonalDocuments = (id, document) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/add-personal-document/${id}`, document);

    return { data: data.data }
  });

export const updateToDate = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/personal-document/update-to-date`, payload);

    return { data: data.data };
  })

export const updatePreferredQueue = (userId, queueId) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/${userId}/preferred-queue/${queueId}`);

    return { data: data.data };
  });

export const getOneUser = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`user/${_id}`);
    return { data: data.data };
  });

export const chnageUserSettings = (id, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/settings/${id}`, {
      password: payload.password,
      language: payload.language,
    });
    return { data: data.data };
  });

export const login = (email, password) =>
  errorHandler(async () => {
    const data = await api.post("/user/login", {
      email: email,
      password: password,
    });
    tokenManager.setToken(data.data.token);
    tokenManager.setRefreshToken(data.data.refreshToken);
    return { data: data.data.token };
  });

export const restartPassword = (email) =>
  errorHandler(async () => {
    const data = await api.post(`/user/restart-password/${email}`);
    return { data: data.data };
  });
