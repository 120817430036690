import React, { useRef } from "react";
import PopUp from "../../../../common/components/PopUp";
import Input from "../../../../common/components/Input";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const Flex = styled.div`
  display: flex;
`;
const Title = styled.h3`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;
const AddFreeDay = ({ setFreeDays, freeDay, setFreeDay }) => {
  const nameRef = useRef();
  const dateRef = useRef();

  const handleAddFreeDay = (e) => {
    e.preventDefault();

    const payload = {};
    payload.id = freeDay.id;
    payload.date = dateRef.current.value;
    payload.name = nameRef.current.value;

    if (freeDay.id) {
      setFreeDays((prev) => [
        ...prev.filter((p) => p.id !== payload.id),
        payload,
      ]);
    } else {
      setFreeDays((prev) => [
        ...prev.filter((p) => p.id !== payload.id),
        { ...payload, id: prev.length + 1 },
      ]);
    }
    setFreeDay(() => null);
  };

  return (
    <PopUp setShow={setFreeDay}>
      <Title>Free day:</Title>
      <form onSubmit={(e) => handleAddFreeDay(e)}>
        <Flex>
          <Input
            inputRef={nameRef}
            name="Name"
            value={freeDay.name}
            required={true}
            disabled={false}
            width={40}
            inputWidth={200}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={dateRef}
            type="date"
            name="Date"
            value={freeDay.date}
            required={true}
            disabled={false}
            width={30}
            inputWidth={150}
            color={Colors.darkBlue}
          />
        </Flex>
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: "-30px" }} text={"Save"} />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default AddFreeDay;
