export const navigateToUser = (userId) => {
  window.open("https://crm.eou-edu.eu/settings/user/list?mode=show&userId=" + userId, {
    traget: "_blank",
  });
};

export const navigateToOrder = (orderId) => {
  window.open("https://crm.eou-edu.eu/dashboard/order/" + orderId, {
    traget: "_blank",
  });
};

export const navigateToContact = (contactId) => {
  window.open("https://crm.eou-edu.eu/dashboard/contact/" + contactId, {
    traget: "_blank",
  });
};
