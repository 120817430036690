import { ScoreWrapper, TestReultWrapper } from '@/pages/consultant/consultantTests/ConsultantTests.styled'
import React from 'react'
import { getColorForResult } from '../../../../helpers/getColorForResult'
import { getTitleOfPercentPoints } from '../../../../helpers/getTitleOfPercentPoints'
import ActionButton from '@/common/components/buttons/ActionButton'

const TestResult = ({
	userAnswers,
	setShow,
}) => {
	return (
		<TestReultWrapper
			resultColor={getColorForResult(
				(userAnswers.reduce(
					(prev, next) => prev + (next.correct ? 1 : 0),
					0
				) /
					userAnswers.length) *
					100
			)}
		>
			<ScoreWrapper>
				{userAnswers.reduce(
					(prev, next) => prev + (next.correct ? 1 : 0),
					0
				)}{" "}
				/ {userAnswers.length}
			</ScoreWrapper>
			{getTitleOfPercentPoints(
				(userAnswers.reduce(
					(prev, next) => prev + (next.correct ? 1 : 0),
					0
				) /
					userAnswers.length) *
					100
			)}
			<div style={{ margin: "20px 0 -20px 0" }}>
				<ActionButton
					defaultText="Okay"
					onClick={() => setShow(() => null)}
				/>
			</div>
		</TestReultWrapper>
	)
}

export default TestResult
