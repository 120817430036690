import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getMarketStatistcs,
  getRealTimeData,
} from "../../../API/repositories/voip";
import { Colors } from "../../../common/colors/colors";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Circle from "../../../common/components/Circle";
import Users from "./components/Users";
import MarketStats from "./components/MarketStats";
import { RealtimeContext } from "./context/RealtimeContext";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const TopDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  margin: 0 1px;
`;

const REALTIME_ENUM = {
  USER_VIEW: "user_view",
  MARKET_VIEW: "market_view",
};

const REALTIME_CONFIG = [
  {
    view: <MarketStats />,
    label: "Market Stats",
    value: REALTIME_ENUM.MARKET_VIEW,
  },
  {
    view: <Users />,
    label: "Users",
    value: REALTIME_ENUM.USER_VIEW,
  },
];

const SwitchElement = styled.div`
  padding: 10px;
  background-color: #d7eef6;
  border-radius: 15px 15px 0 0px;
  min-width: 150px;
  text-align: center;
  color: ${Colors.darkBlue};

  &:hover {
    cursor: pointer;
  }
`;

const Realtimedashboard = () => {
  const [selectedView, setSelectedView] = useState(REALTIME_CONFIG[0]);
  const [data, setData] = useState({});
  const [marketStatistics, setMarketStatistics] = useState([]);
  const [marketStatisticsInbound, setMarketStatisticsInbound] = useState([]);
  const [workingData, setWorkingData] = useState([]);
  const [incomingCalls, setIncomingCalls] = useState([]);

  const {
    commonData: { queues },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const getData = async () => {
    const response = await makeRequest(getRealTimeData);

    if (response.data) {
      const result = {};
      result.login = response.data.realtime.filter(
        (u) => u?.status === "login"
      );
      result.calling = response.data.realtime.filter(
        (u) => u?.status === "calling"
      );
      result.break = response.data.realtime.filter(
        (u) => u?.status === "break"
      );
      result.talking = response.data.realtime.filter(
        (u) => u?.status === "talking"
      );
      result.wraptime = response.data.realtime.filter(
        (u) => u?.status === "wraptime"
      );
      setData(() => result);

      setWorkingData(() => response.data.working || []);
      setIncomingCalls(() => response.data.incoming || []);
    }

    const marketStatisticsResponse = await makeRequest(getMarketStatistcs);

    if (marketStatisticsResponse.data) {
      setMarketStatistics(() =>
        calcMarketStats(marketStatisticsResponse.data.outbound)
      );
      setMarketStatisticsInbound(() =>
        calcMarketStats(marketStatisticsResponse.data.inbound)
      );
    }
  };

  const findQueueByNumber = (number) => {
    return (
      queues.find((q) => q.outbound_numbers.includes(number))?.name || "-----"
    );
  };

  const calcMarketStats = (data) => {
    const result = {};

    data.forEach((d) => {
      const element = {};

      element.phone_number = d._id;
      element.count = 0;
      element.not_picked_up = 0;

      const resultMap = new Map();

      d.orders.forEach((order) => {
        element.count += 1;
        if (!order.consultant[0]) {
          element.not_picked_up += 1;

          return;
        }

        if (resultMap.has(order.consultant[0].username)) {
          resultMap.get(order.consultant[0].username).push(order);
          return;
        }

        resultMap.set(order.consultant[0].username, [order]);
      });
      if (resultMap.size > 0) {
        element.data = Array.from(resultMap).map(([name, value]) => ({
          name,
          value,
        }));
      }
      const country = findQueueByNumber(element.phone_number);

      console.log(country);

      if (result[country]) {
        return result[country].push(element);
      }
      result[country] = [element];
    });

    return result;
  };

  useEffect(() => {
    if (queues && queues.length > 0) getData();
  }, [queues]);

  const getStyles = (value, index) => {
    if (value === selectedView.value) {
      return {
        backgroundColor: Colors.darkBlue,
        color: "white",
      };
    }
  };

  return (
    <Wrapper>
      <TopDisplay>
        <div></div>
        <h3>Realtime dashboard </h3>
        <Circle getData={getData} />
      </TopDisplay>
      <SwitchWrapper>
        {REALTIME_CONFIG.map((d, i) => (
          <SwitchElement
            key={d.value}
            style={getStyles(d.value, i)}
            onClick={() => setSelectedView(d)}
          >
            {d.label}
          </SwitchElement>
        ))}
      </SwitchWrapper>
      <RealtimeContext.Provider
        value={{
          marketStatistics,
          marketStatisticsInbound,
          data,
          incomingCalls,
          workingData,
        }}
      >
        {selectedView.view}
      </RealtimeContext.Provider>
    </Wrapper>
  );
};

export default Realtimedashboard;
