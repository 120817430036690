import React, { useRef, useState } from "react";
import { SearchWrapper } from "../ccGamification.styles";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import { getGamificationPointsByQuery } from "@/API/repositories/gamificationPoints";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import SelectInput from "@/common/components/SelectInput";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const SearchGamificationBar = ({ setData }) => {
  const {
    options: { marketsOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const [selectedMarkets, setSelectedMarkets] = useState();
  const defDateFrom = formatToDateTamplate(moment().startOf("month"));
  const defDateTo = formatToDateTamplate(moment().endOf("months"));
  const dateFromRef = useRef();
  const dateToRef = useRef();

  const handleSearch = async () => {
    if (!selectedMarkets) {
      return addMessage("Please select market", "error");
    }
    setData(() => []);

    const payload = {};
    payload.market = selectedMarkets.value;
    payload.from = dateFromRef.current.value;
    payload.to = dateToRef.current.value;

    const response = await makeRequest(
      getGamificationPointsByQuery.bind(null, payload)
    );

    if (response.data && response.data[0]) {
      setData(() => response.data);
    } else {
      addMessage("There is no results", "error");
    }
  };

  return (
    <SearchWrapper>
      <Input
        inputRef={dateFromRef}
        name="from"
        type="date"
        width={30}
        inputWidth={140}
        value={defDateFrom}
        color={Colors.darkBlue}
      />
      <Input
        inputRef={dateToRef}
        name="to"
        type="date"
        width={20}
        inputWidth={140}
        value={defDateTo}
        color={Colors.darkBlue}
      />
      <SelectInput
        name="market"
        options={marketsOptions}
        width={60}
        selectWidth={120}
        selected={selectedMarkets}
        setSelected={setSelectedMarkets}
        color={Colors.darkBlue}
      />
      <ActionButton defaultText={"Search"} onClick={() => handleSearch()} />
    </SearchWrapper>
  );
};

export default SearchGamificationBar;
