import { formatMonthName } from "@/common/functions/dateFormater";
import { getClaimReportExcel } from "./getClaimReportExcel";
import { groupOrdersByMarket } from "./groupOrdersByMarket";
import { getFailedTransfersReportExcel } from "./getFailedTransfersReportExcel";

export async function generateFiles(data, month) {
  const groupedByMarket = groupOrdersByMarket(data);

  let excelBuffers = [];
  for (const [marketName, claim] of Object.entries(groupedByMarket)) {
    const buffer = await getClaimReportExcel(marketName, claim, month);
    const name = `EOU_${formatMonthName(
      month
    )}_zwroty_${marketName.toLowerCase()}.xlsx`;

    excelBuffers.push({ name, data: buffer });
  }

  const claimsWithSomeFailedTransfer = [...data].filter((item) =>
    item.transfers.some((t) => t.failed_at)
  );

  if (claimsWithSomeFailedTransfer.length) {
    const buffer = await getFailedTransfersReportExcel(
      claimsWithSomeFailedTransfer,
      month
    );
    const name = `EOU_${formatMonthName(month)}_nieudane_transakcje.xlsx`;

    excelBuffers.push({ data: buffer, name });
  }

  return excelBuffers;
}
