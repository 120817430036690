import ActionButton from "@/common/components/buttons/ActionButton";
import { formatToExactTimeDate } from "@/common/functions/dateFormater";
import { NavigateLink } from "@/common/components/links/NavigateLink";
import { CellActionsWrapper } from "../MailSearch.styled";
import { Colors } from "@/common/colors/colors";

export const getRaws = (data, handleCopy) => (data.map((element, i) => (
	<tr key={i}>
		<td>{i + 1}</td>
		<td>{element.sender ? element._user.username : "System"}</td>
		<td>{element.customer}</td>
		<td>{element.mailType}</td>
		<td>{element.open_countrer || 0}</td>
		<td>{element.clicked_countrer || 0}</td>
		<td>{element.spam_report_countrer || 0}</td>
		<td>
			{element.created_at
				? formatToExactTimeDate(element.created_at)
				: "-------"}
		</td>
		<td>
			{element.updatedAt
				? formatToExactTimeDate(element.updatedAt)
				: "-------"}
		</td>
		<td>
			{element.processed_at
				? formatToExactTimeDate(element.processed_at)
				: "-------"}
		</td>
		<td>
			{element.delivered_at
				? formatToExactTimeDate(element.delivered_at)
				: "-------"}
		</td>
		<td>
			{element.dropped_at
				? formatToExactTimeDate(element.dropped_at)
				: "-------"}
		</td>
		<td>
			{element.bounced_at
				? formatToExactTimeDate(element.bounced_at)
				: "-------"}
		</td>
		<td>{element.bounced_type || "-------"}</td>
		<td>
			{element.first_open_at
				? formatToExactTimeDate(element.first_open_at)
				: "-------"}
		</td>
		<td>
			{element.first_click_at
				? formatToExactTimeDate(element.first_click_at)
				: "-------"}
		</td>
		<td>
			{element.fisrt_spam_report_at
				? formatToExactTimeDate(element.fisrt_spam_report_at)
				: "-------"}
		</td>
		<td>
			<CellActionsWrapper>
				{element.order && (
					<NavigateLink
						color={Colors.purple}
						to={`/dashboard/order/${element.order}`} 
						text="Order" 
					/>
				)}
				{element.call_center_candidate && (
					<NavigateLink
						color={Colors.purple}
						to={`/cc/recruitment?candidate=${element.call_center_candidate}`} 
						text="Candidate" 
					/>
				)}
				{element.sengird_id && (
					<ActionButton
						onClick={() => handleCopy(element.sengird_id.toString())}
						defaultText="Sendgrid"
					/>
				)}
				{element.template_id && (
					<ActionButton
						onClick={() => handleCopy(element.template_id.toString())}
						defaultText="Template"
					/>
				)}
			</CellActionsWrapper>
		</td>
	</tr>
)));
