import { OPERATION_ACTIONS } from "../components/orderFilter/constants/operations";

export const operationsReducer = (state, action) => {

  switch (action.type) {
    case OPERATION_ACTIONS.UPDATE:
    case OPERATION_ACTIONS.ADD:
      state[action.payload.label] = {
        operation: action.payload.operation,
        type: action.payload.type,
        field: action.payload.label,
      };
      return { ...state };
    case OPERATION_ACTIONS.INIT:
      return action.payload;
    case OPERATION_ACTIONS.RESET:
      return {};
    case OPERATION_ACTIONS.DELETE:
      delete state[action.payload.label];
      return { ...state };
    default:
      return { ...state };
  }
};