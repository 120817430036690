import Input from "@/common/components/Input";
import { BorderLine, ColorTitle, OptionWrapper } from "../../../../FilterOptons.styled";

export const FilterOptionFinderBool = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      type_field: "Bool",
      isTrue: value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        type="checkbox"
        value={filters[fieldData.label]?.isTrue}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    </OptionWrapper>
  );
};