import styled from "styled-components";

export const Wrapper = styled.div`
margin: 30px 30px;
max-width: 1200px;
font-size: 14px;
td {
	padding: 10px 0px;
	margin-bottom: 1px solid grey;
	width: fit-content;
	text-align: center;
}
th {
	padding: 5px 10px;
	width: fit-content;
	text-align: center;
}
`;