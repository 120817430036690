import React, { useMemo, useState } from 'react'
import PopUp from '../PopUp'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import SelectInput from '../SelectInput';
import userManager from '@/API/userManager';
import { Colors } from '@/common/colors/colors';
import { ButtonWrapper, InputsWrapper, Title } from './SetPreferredQueue.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { updatePreferredQueue } from '@/API/repositories/user';
import Loading from '../Loading';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import SubmitButton from '../buttons/SubmitButton';

const SetPreferredQueue = ({ setShow, onQueueChange }) => {
  const [selectedQueue, setSelectedQueue] = useState(null);

  const { commonData: { users }, options: { queuesOptions }, queryFunctions: { refetch } } = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const user = users.find(u => u._id === userManager.getUser()?.id);

  const userQueueOptions = useMemo(() => {
    const filtered = queuesOptions?.filter(queue => queue.value?.consultants?.includes(user?._id))?.sort((a, b) => {
      if (user?.preferred_queue === a.value?._id) return -1;
      if (user?.preferred_queue === b.value?._id) return 1;

      return (b.value?.call_weight || 0) - (a.value?.call_weight || 0);
    });

    setSelectedQueue(filtered?.[0]);
    return filtered;
  }, [queuesOptions, user])

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(updatePreferredQueue.bind(null, user._id, selectedQueue.value._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    onQueueChange?.(selectedQueue.label);

    refetch();
    setShow(false)
  }

  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(updatePreferredQueue) && <Loading />}
      <Title>Set preferred queue</Title>
      <form onSubmit={handleSubmit}>
        <InputsWrapper>
          <SelectInput
            selected={selectedQueue}
            setSelected={setSelectedQueue}
            width={60}
            color={Colors.darkBlue}
            name="Queue" options={userQueueOptions}
          />
        </InputsWrapper>
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: '-30px' }} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default SetPreferredQueue
