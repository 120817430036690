import { getValidClaims } from '@/API/repositories/validClaim';
import Loading from '@/common/components/Loading';
import { useRequestsContext } from '@/common/hooks/requestHook'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { Colors } from '@/common/colors/colors';
import ValidClaimForm from '@/common/components/validClaimForm/ValidClaimForm';
import { getClaimTags } from '@/API/repositories/claim-tag';
import { sortValidClaims } from './helpers/sortValidClaims';
import ClaimTable from './components/claimTable/ClaimTable';
import ClaimSearchBar from './components/claimSearchBar/ClaimSearchBar';
import AddFile from './components/addFile/AddFile';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { CLAIM_ROLE_STATUSES } from '@/common/constants/claims';
import userManager from '@/API/userManager';
import { validClaimsReducer } from './helpers/validClaimsReducer';
import { DEFAULT_REDUCER_STATE, VALID_CLAIMS_ACTIONS } from './constants/reducer';
import FilterSetter from './components/filterSetter/FilterSetter';
import { ValidClaimProvider } from '@/common/contexts/validClaimContext';

const ValidClaims = () => {
  const { options: { marketsOptions } } = useCommonDataContext();

  const [validClaims, setValidClaims] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState(marketsOptions);
  const [selectedStatus, setSelectedStatus] = useState(CLAIM_ROLE_STATUSES[userManager.getUser().role].TO_DO);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [addFile, setAddFile] = useState(null);
  const [state, dispatch] = useReducer(validClaimsReducer, DEFAULT_REDUCER_STATE);
  const [openAction, setOpenAction] = useState(null);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadTags = async () => {
    const response = await makeRequest(getClaimTags);

    if (!response?.data) {
      return addMessage("Error while loading tags", "error");
    }

    const mappedOptions = response.data
      .map(tag => ({ value: tag._id, label: <span style={{ color: Colors.darkGray2 }}>{tag.label}</span> }));

    setTagsOptions(() => mappedOptions);
    return mappedOptions;
  };

  const handleSearch = async () => {
    if (!selectedMarkets?.length || !selectedStatus?.value) {
      return addMessage("Please select markets and statuses", "error");
    }

    const payload = {
      markets: selectedMarkets.map(market => market.value),
      statuses: Object.values(selectedStatus.claim_statuses).map(status => status.value),
    }
    const response = await makeRequest(getValidClaims.bind(null, payload));

    if (!response?.data) {
      return addMessage("Error while loading valid claims", "error");
    }

    setValidClaims(() => sortValidClaims(response.data));
  }

  const visibleClaims = useMemo(() => {
    return validClaims?.filter(claim => {
      const marketFilter = state[VALID_CLAIMS_ACTIONS.SET_MARKETS]?.map(m => m.value);
      const paymentFilter = state[VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD]?.map(p => p.value);

      if (paymentFilter?.length) {
        const claimPaymentMethod = claim.payment_method === 'cash'
          ? 'cash'
          : claim.currency === 'eur'
            ? 'ing'
            : 'revolut';

        if (!paymentFilter.includes(claimPaymentMethod)) {
          return false;
        }
      }

      if (marketFilter?.length) {
        if (!marketFilter.includes(claim._order.market)) {
          return false;
        }
      }
      return true;
    });
  }, [validClaims, state]);

  const handleSelectStatus = (value) => {
    setValidClaims(() => []);
    setSelectedStatus(() => value);
  }

  useEffect(() => {
    loadTags();
    handleSearch();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getValidClaims) && <Loading />}
      <ClaimSearchBar
        selectedMarkets={selectedMarkets}
        setSelectedMarkets={setSelectedMarkets}
        selectedStatus={selectedStatus}
        setSelectedStatus={handleSelectStatus}
        handleSearch={handleSearch}
        filters={state}
        reset={() => dispatch({ type: VALID_CLAIMS_ACTIONS.RESET })}
      />
      {!!validClaims.length && (
        <ClaimTable
          filters={state}
          setOpenAction={setOpenAction}
          selectedStatus={selectedStatus}
          setAddFile={setAddFile}
          validClaims={visibleClaims}
          setSelectedClaim={setSelectedClaim}
        />
      )}
      {selectedClaim && (
        <ValidClaimProvider
          savedContact={selectedClaim._contact}
          savedTags={tagsOptions}
          claim={selectedClaim}
          order={selectedClaim._order}
        >
          <ValidClaimForm
            reload={handleSearch}
            setShow={setSelectedClaim}
          />
        </ValidClaimProvider>
      )}
      {addFile && (
        <AddFile
          claimId={addFile}
          addMessageToParent={addMessage}
          reload={handleSearch}
          setAddFile={setAddFile}
        />
      )}
      {openAction && (
        <FilterSetter
          openAction={openAction}
          setOpenAction={setOpenAction}
          filter={state}
          dispatchFilter={dispatch}
        />
      )}
    </PageWrapper>
  )
}

export default ValidClaims
