import { Colors } from "@/common/colors/colors";
import { IconsWrapper } from "@/pages/admin/test/styles/Test.styles";
import React from "react";

const PayoutSlipTable = ({
  data,
  handleDownloadFile,
  setAddPayoutSlip,
  filterRegex,
}) => {
  return (
    <table className="styled-table-dashboard">
      <thead>
        <tr>
          <th>No.</th>
          <th>Contract</th>
          <th>Username</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="queue">
        {data
          ?.sort((a, b) => a.contract_type?.localeCompare(b.contract_type))
          .filter((e) =>
            filterRegex
              ? e.contract_type
                ?.toLowerCase()
                ?.includes(filterRegex.toLowerCase())
              : true
          )
          .map((callinfo, i) => (
            <tr key={callinfo._id}>
              <td>{i + 1}.</td>
              <td>{callinfo.contract_type || "----"}</td>
              <td>{callinfo.consultant.username}</td>
              <td>
                <IconsWrapper>
                  <i
                    className="fa fa-file-upload animation-scale"
                    style={{ cursor: "pointer", color: Colors.darkBlue }}
                    onClick={() => setAddPayoutSlip(callinfo)}
                  />
                  {callinfo.payout_slip && (
                    <i
                      className="fa fa-file animation-scale"
                      style={{
                        cursor: "pointer",
                        color: Colors.red,
                      }}
                      onClick={() =>
                        handleDownloadFile(callinfo.payout_slip._id)
                      }
                    />
                  )}
                  {callinfo?.payout_slip?.first_download_at && (
                    <i
                      className="fa fa-check"
                      style={{
                        color: Colors.green,
                      }}
                    />
                  )}
                </IconsWrapper>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default PayoutSlipTable;
