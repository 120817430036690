import React, { useState } from "react";
import { sendMailByMailType } from "../../../../API/repositories/mail";
import Loading from "../../../../common/components/Loading";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const MoreInfo = ({
  product,
  email,
  sendMail,
  mailError,
  mailSuccess,
  order,
  changeColor = true,
}) => {
  const [canSendMail, setCanSendMail] = useState(true);
  const [color, setColor] = useState(Colors.orange);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSendMoreInfo = async (e) => {
    setCanSendMail(false);
    setTimeout(() => {
      setCanSendMail(true);
      setColor(() => Colors.orange);
    }, 10000);
    e.preventDefault();

    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "MORE_INFO_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      changeColor && setColor(() => Colors.green);
    } else {
      changeColor && setColor(() => Colors.red);
    }
  };

  return (
    <>
      {hasUnfilledRequest(sendMailByMailType) && <Loading />}
      <ActionButton
        color={color}
        onClick={(e) => handleSendMoreInfo(e)}
        disabled={!canSendMail}
      >
        <i className="fa fa-paper-plane" style={{ marginRight: "10px" }}></i>
        {color === Colors.green
          ? mailSuccess
          : color === Colors.red
          ? mailError
          : sendMail}{" "}
        "More Info"
      </ActionButton>
    </>
  );
};

export default MoreInfo;
