import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useFormik } from 'formik'
import React from 'react'
import { ButtonWrapper } from '../../RecruitmentPage.styled'
import { useRequestsContext } from '@/common/hooks/requestHook'
import Loading from '@/common/components/Loading'
import { createPage, updatePage } from '@/API/repositories/recruitmentPage'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import SubmitButton from '@/common/components/buttons/SubmitButton'

const RecruitmentPageForm = ({ setShow, selectedPage, reload = () => {} }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { options: { marketsOptions } } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const formik = useFormik({
    initialValues: {
      title: selectedPage?.title || '',
      page_url: selectedPage?.page_url || '',
      market: marketsOptions.find(o => o.value === selectedPage?.market),
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title,
      page_url: values.page_url,
      market: values.market.value,
    };

    const requestFn = selectedPage ? updatePage : createPage;

    const response = await makeRequest(requestFn.bind(null, payload, selectedPage?._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(createPage, updatePage) && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <SelectInput
          color={Colors.darkBlue}
          name="Market"
          required
          hideStar
          options={marketsOptions}
          width={120}
          setSelected={(value) => formik.setFieldValue('market', value)}
          selected={formik.values.market}
          value={formik.values.market}
        />
        <Input
          id={"page_url"}
          color={Colors.darkBlue}
          name="Page URL"
          required
          width={120}
          value={formik.values.page_url}
          onChange={formik.handleChange}
        />
        <Input
          id={"title"}
          onChange={formik.handleChange}
          name="Title"
          value={formik.values.title}
          required
          color={Colors.darkBlue}
          width={120}
        />
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: "-30px"}} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default RecruitmentPageForm
