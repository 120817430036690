import ActionButton from '@/common/components/buttons/ActionButton'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper } from './ClaimTag.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { getPages } from '@/API/repositories/recruitmentPage';
import ClaimTagForm from './components/claimTagForm/ClaimTagForm';
import ClaimTagTable from './components/claimTagTable/ClaimTagTable';
import { getClaimTags } from '@/API/repositories/claim-tag';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';

const ClaimTag = () => {
  const [show, setShow] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const [tags, setTags] = useState([]);

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadTags = async () => {
    setTags([]);
    const response = await makeRequest(getClaimTags);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setTags(response.data);
  };


  useEffect(() => {
    loadTags();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getPages) && <Loading />}
      <ContentWrapper>
        <ActionButton 
          defaultText="Add tag" 
          onClick={() => {
            setSelectedTag(null)
            setShow(true)
          }}  
        />
      </ContentWrapper>
      {show && <ClaimTagForm selectedTag={selectedTag} reload={loadTags} setShow={setShow} />}
      {!!tags?.length && (
        <ClaimTagTable setSelectedTag={setSelectedTag} setShow={setShow} tags={tags} />
      )}
    </PageWrapper>
  )
}

export default ClaimTag
