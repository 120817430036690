import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { ButtonWrapper } from "../../ServerRefresh.styled";
import { preventFn } from "@/common/functions/preventEvent";
import Loading from "@/common/components/Loading";
import {
  createServerConfig,
  updateServerConfig,
} from "@/API/query/serverConfig";
import { useMutation } from "react-query";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const InvoiceConfigForm = ({ setShow, loadData, edit }) => {
  const ipRef = useRef();
  const pathToRunRef = useRef();
  const nameRef = useRef();

  const { addMessage } = useMessageQueueContext();

  const handleCreateMutation = useMutation({
    mutationFn: (value) => createServerConfig(value),
    onSuccess: (data) => {
      loadData.mutate();
      setShow(() => false);
      addMessage("Success", "success");
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const handleUpdateMutation = useMutation({
    mutationFn: (value) => updateServerConfig(value),
    onSuccess: (data) => {
      loadData.mutate();
      addMessage("Success", "success");
      setShow(() => false);
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const handleCreateKey = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.pathToRun = pathToRunRef.current.value;
    payload.ip = ipRef.current.value;
    payload.name = nameRef.current.value;

    if (edit._id) {
      payload._id = edit._id;
      return handleUpdateMutation.mutate(payload);
    }

    return handleCreateMutation.mutate(payload);
  };

  return (
    <PopUp padding="10px 20px 20px" setShow={setShow}>
      {handleCreateMutation.isLoading && handleUpdateMutation.isLoading && (
        <Loading />
      )}
      <form onSubmit={(e) => handleCreateKey(e)}>
        <Input
          inputRef={nameRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Name"
          value={edit?.name}
        />
        <Input
          inputRef={ipRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Server IP"
          value={edit?.ip}
        />
        <Input
          inputRef={pathToRunRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Path to run"
          value={edit?.pathToRun}
        />
        <ButtonWrapper>
          <SubmitButton
            color={Colors.orange}
            text={`${edit ? "Update" : "Create"} server Config`}
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default InvoiceConfigForm;
