import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import { useFormik } from "formik";
import Input from "../../../../../common/components/Input";
import { formatPhoneNumber } from "../../../../../common/functions/formatPhoneNumber";
import SelectInput from "../../../../../common/components/SelectInput";
import { saveConsultantContact } from "../../../../../API/repositories/contact";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import Loading from "../../../../../common/components/Loading";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Card = styled.div`
  border-radius: 10px;
  padding: 10px 30px 30px 30px;
  min-width: 400px;
  background: ${Colors.lightlightBlue};
`;

const Title = styled.h2`
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const SaveWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 30px;
`;

export const PREFIXES = {
  cz: "+420 ",
  ro: "+40 ",
  sk: "+421 ",
  hu: "+36 ",
};

const ContactDisplay = ({
  currentContact,
  handleLoadContact,
}) => {
  const { COUNTRIES } = useCommonDataContext();
  const { translation } = useTranslationContext();
  
  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRIES.find((c) => c.value === currentContact.country)
  );
  const [isSucces, setIsSuccess] = useState(null);
  const [isFailed, setIsFailed] = useState(null);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSave = async (values) => {
    const isPhoneError = values.phone_number_for_courier 
      && !validatePhoneForCourier(values.phone_number_for_courier)

    if (isPhoneError) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    values.country = selectedCountry.value;

    const response = await makeRequest(
      saveConsultantContact.bind(null, currentContact._id, values)
    );

    if (response.data) {
      setIsSuccess(() => true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      await handleLoadContact();
    } else {
      setIsFailed(() => true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
    }
  };

  const formik = useFormik({
    initialValues: { ...currentContact },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <>
      {hasUnfilledRequest(saveConsultantContact) && <Loading />}
      <Card>
        <Title>
          <div>{translation.contact}</div>
          {PREFIXES[currentContact.country]}{" "}
          {formatPhoneNumber(currentContact.phone_number)}
        </Title>
        <form onSubmit={formik.handleSubmit}>
          <Input
            name={translation.full_name}
            id="full_name"
            value={formik.values.full_name}
            onChange={formik.handleChange}
            width={135}
            inputWidth={240}
            color={Colors.darkBlue}
          />
          <SelectInput
            name={translation.country}
            selected={selectedCountry}
            setSelected={setSelectedCountry}
            options={COUNTRIES}
            width={135}
            color={Colors.darkBlue}
            selectWidth={240}
          />
          <Input
            id="email"
            name={translation.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <Input
            id="postal_code"
            name={translation.postalCode}
            value={formik.values.postal_code}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <Input
            id="street"
            name={translation.street}
            value={formik.values.street}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <Input
            id="city"
            name={translation.city}
            value={formik.values.city}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <Input
            id="phone_number_for_courier"
            name={translation.phone_number_for_courier}
            value={formik.values.phone_number_for_courier}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <Input
            id="additional_info"
            name={translation.additionalInfo}
            value={formik.values.additional_info}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            width={135}
            inputWidth={240}
          />
          <SaveWrapper>
            <SubmitButton
              color={isSucces 
                ? Colors.green 
                : isFailed 
                  ? Colors.red 
                  : Colors.orange
              }
              style={{ cursor: "pointer" }}
              text={translation.save}
            />
          </SaveWrapper>
        </form>
      </Card>
    </>
  );
};

export default ContactDisplay;
