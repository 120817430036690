import React, { useRef, useState } from "react";
import PopUp from "../../../common/components/PopUp";
import {
  ButtonWrapper,
  QuestionWrapper,
  Title,
} from "./styles/Test.styles";
import Table from "../../../common/components/Table";
import TestAnswer from "./TestAnswer";
import TextArea from "../../../common/components/TextArea";
import { handleReturnCorrectIcon, handleReturnOptions } from "./common/shared";
import ActionButton from "@/common/components/buttons/ActionButton";

const TestQuestion = ({ setShow, question, setQuestions }) => {
  const headers = ["No", "Answer", "isCorrect", "Action"];
  const [answers, setAnswers] = useState(question.answers || []);
  const [answer, setAnswer] = useState();

  const questionNameRef = useRef();

  const handleRemoveAnswer = (ans) => {
    // handle usuwanie obrazka z bazy czy cos takiego
    setAnswers((prev) =>
      prev.filter((e) => e.id !== ans.id).map((e, i) => ({ ...e, id: i + 1 }))
    );
  };

  const handleAddQuestion = () => {
    const payload = {};

    payload.value = questionNameRef.current?.value;
    payload.answers = answers;

    setQuestions((prev) => [
      ...prev
        .filter((e) => e.id !== question.id)
        .map((e, i) => ({ ...e, id: i + 1 })),
      {
        ...payload,
        id: prev.length + 1,
      },
    ]);

    setShow(() => null);
  };

  return (
    <>
      <PopUp setShow={setShow}>
        <Title>Question:</Title>
        <QuestionWrapper>
          <TextArea
            label="Value"
            textAreaRef={questionNameRef}
            width={"600px"}
            placeholder="type your question..."
            required
            defaultValue={question.value}
          />
          <Table
            headers={headers}
            raws={answers.map((ans, i) => (
              <tr>
                <td>{i + 1}.</td>
                <td>{ans.value}</td>
                <td>{handleReturnCorrectIcon(ans.is_correct)}</td>
                <td>
                  {handleReturnOptions(ans, handleRemoveAnswer, setAnswer)}
                </td>
              </tr>
            ))}
            className={"styled-table"}
          />
          <ButtonWrapper style={{ justifyContent: "right" }}>
            <ActionButton defaultText="Add Answer" onClick={() => setAnswer({})} />
            <ActionButton
              onClick={() => handleAddQuestion()}
              disabled={
                answers.length === 0 ||
                answers.filter((a) => a.is_correct).length === 0
              }
              defaultText="Save"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      </PopUp>
      {answer && (
        <TestAnswer
          answer={answer}
          setShow={setAnswer}
          setAnswers={setAnswers}
        />
      )}
    </>
  );
};

export default TestQuestion;
