import React from "react";
import PopUp from "../../../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Text = styled.h2`
  display: flex;
  color: ${Colors.darkBlue};
  text-align: center;
  justify-content: center;
`;

const Calling = ({ fullName }) => {
  const { translation } = useTranslationContext();

  return (
    <PopUp canOffByClickingBackground={false}>
      <Text>
        {translation.calling} {fullName}
      </Text>
    </PopUp>
  );
};

export default Calling;
