import { generateExcelSheetBuffer } from "@/common/functions/getExcelBuffer";
import { getExcelData } from "./getExcelData";

export const generateFiles = async ({ value, name }, month) => {
	const excelData = getExcelData(value, month);
	const sheetName = `paymentkdr.${name}.xlsx`;

	const buffer = await generateExcelSheetBuffer(excelData, sheetName);

	return {
		data: buffer.toString("base64"),
		name: sheetName
	}
};