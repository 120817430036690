import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  width: fit-content;
  gap: 10px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  align-items: center;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 200px);
  width: fit-content;
`;

export const ThCellFlex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const HeadButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
