import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getGamificationPointsForUserByQuery } from "../../../API/repositories/gamificationPoints";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Loading from "../../../common/components/Loading";
import anime from "animejs";
import userManager from "../../../API/userManager";
import { 
  CurrentUserWraper,
  NumberWrapper, 
  ResultCircle, 
  ResultElement,
  ResultElementWrapper, 
  ResultWrapper, 
  UsernameWrapper, 
  Wrapper 
} from "./ConsultantGamification.styled";
import GamificationPoints from "./components/gamificationPoints/GamificationPoints";
import { getColorByPosition } from "./helpers/getColorsByPosition";

const ConsultantGamification = () => {
  const [data, setData] = useState();
  const [showUserStats, setShowUserStats] = useState();
  const today = moment();
  const animationRef = useRef();

  useEffect(() => {
    animationRef.current = anime({
      targets: ".el",
      translateY: 200,
      backgroundColor: ["#FFA07A"],
      zIndex: [1000, 1],
      size: [10, 1],
      direction: "reverse",
      easing: "easeOutQuad",
    });

    animationRef.current.play();
  }, []);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadGamificationPoints = async () => {
    const payload = {};

    payload.from_last = today
      .clone()
      .subtract("1", "months")
      .startOf("month")
      .toISOString();
    payload.to_last = today
      .clone()
      .subtract("1", "months")
      .endOf("month")
      .toISOString();
    payload.from_current = today.clone().startOf("month").toISOString();
    payload.to_current = today.clone().endOf("month").toISOString();

    const response = await makeRequest(
      getGamificationPointsForUserByQuery.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadGamificationPoints();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getGamificationPointsForUserByQuery) && <Loading />}
      <table className="styled-table">
        <thead>
          <tr>
            <th colSpan={3}>
              Last Month Result (
              {today
                .clone()
                .subtract("1", "months")
                .startOf("month")
                .format("MMMM DD")}
              {" --> "}
              {today
                .clone()
                .subtract("1", "months")
                .endOf("month")
                .format("MMMM DD")}
              )
            </th>
          </tr>
          <tr>
            <th>Position</th>
            <th>Score</th>
            <th>Bonus %</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data && data?.last_month ? (
            <tr>
              <td>{data?.last_month.position}.</td>
              <td>{data?.last_month.score}</td>
              <td>{data?.last_month.bonus}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={3}>Thre is no results yet!</td>
            </tr>
          )}
        </tbody>
      </table>
      {data && data.current_month && data.current_month[0] && (
        <ResultWrapper>
          {data.current_month.map((element) => (
            <>
              <ResultElementWrapper
                onClick={() =>
                  userManager.getUser().username === element.username &&
                  setShowUserStats((prev) => !prev)
                }
              >
                <ResultCircle color={getColorByPosition(element.position)}>
                  {element.position}.
                </ResultCircle>
                <ResultElement
                  key={element.username}
                  color={getColorByPosition(element.position)}
                  isCurrent={
                    userManager.getUser().username === element.username
                  }
                  className={`${
                    userManager.getUser().username === element.username
                      ? "el"
                      : ""
                  }`}
                >
                  {userManager.getUser().username === element.username && (
                    <CurrentUserWraper>You!</CurrentUserWraper>
                  )}
                  <UsernameWrapper>{element.username}</UsernameWrapper>
                  <NumberWrapper>
                    {Math.round(element.score * 100) / 100} points
                  </NumberWrapper>
                  <NumberWrapper>{element.bonus} %</NumberWrapper>
                </ResultElement>
              </ResultElementWrapper>
              {showUserStats &&
                userManager.getUser().username === element.username && (
                  <GamificationPoints points={element.data} />
                )}
            </>
          ))}
        </ResultWrapper>
      )}
    </Wrapper>
  );
};

export default ConsultantGamification;
