import React, { useState } from "react";
import EffectiveReportConsultant from "./reports/EffectiveReportConsultant";
import EffectiveReportMarket from "./reports/EffectiveReportMarket";
import { Wrapper } from "./EffectiveReport.styed";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";

const EffectiveReport = () => {
  const [weight, setWeights] = useState(true);
  const [forConfirmedDate, setForConfirmedDate] = useState(false);
  const [data, setData] = useState();

  const { options, queryValues } = useCommonDataContext();
  const { marketsOptions, usersOptions, productsOptions } =
    options;
  const { isLoading } = queryValues;

  return (
    <Wrapper>
      {!isLoading ? (
        <SearchBar
          setWeights={setWeights}
          setForConfirmedDate={setForConfirmedDate}
          setData={setData}
          weight={weight}
          forConfirmedDate={forConfirmedDate}
          data={data}
        />
      ) : (
        <Loading />
      )}
      {data && !forConfirmedDate && (
        <EffectiveReportConsultant
          weights={!weight}
          markets={marketsOptions}
          products={productsOptions}
          users={usersOptions}
          data={data}
        />
      )}
      {data && forConfirmedDate && (
        <EffectiveReportMarket
          weights={!weight}
          markets={marketsOptions}
          products={productsOptions}
          users={usersOptions}
          data={data}
        />
      )}
    </Wrapper>
  );
};

export default EffectiveReport;
