import { formatToDateTamplate } from "@/common/functions/dateFormater";
import moment from "moment";
import { getColor } from "./getColor";
import { IconWrapper } from "@/pages/admin/listeningScores/listeningScores.styles";

export const getRaws = (data, setEdit, handleDelete) => data.map((element, i) => (
  <tr
    key={element.created_at}
    style={{ backgroundColor: getColor(element.invoice.priority) }}
  >
    <td>{i + 1}.</td>
    <td>{element._contact.full_name}</td>
    <td>{element._contact.email}</td>
    <td>{element.invoice.comapny_name}</td>
    <td>{element._product?.name || "-----"}</td>
    <td>
      {element._order.shipping?.status_delivered_no_payment_at
        ? formatToDateTamplate(
            element._order.shipping.status_delivered_no_payment_at
          )
        : "-----"}
    </td>
    <td>
      {element._order.shipping?.status_delivered_at
        ? formatToDateTamplate(
            element._order.shipping.status_delivered_at
          )
        : "-----"}
    </td>
    <td>
      {element._order.shipping?.status_delivered_at
        ? moment(element._order.shipping.status_delivered_at)
            .add("30", "days")
            .format("YYYY-MM-DD")
        : element._order.shipping?.status_delivered_no_payment_at
        ? moment(element._order.shipping.status_delivered_at)
            .add("30", "days")
            .format("YYYY-MM-DD")
        : "-----"}
    </td>
    <td>{element.status}</td>
    <td>
      <IconWrapper>
        <i
          className="fa fa-edit animation-scale"
          style={{
            fontSize: "14px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => setEdit(() => element)}
        />
        <i
          className="fa fa-trash animation-scale"
          style={{
            fontSize: "14px",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => handleDelete(element)}
        />
      </IconWrapper>
    </td>
  </tr>
));