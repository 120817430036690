import React, { useRef, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import { getOrderSentReport } from "../../../API/repositories/reports";
import Loading from "../../../common/components/Loading";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SentOrdersSearchBar from "./components/sentOrdersSearchBar/SentOrdersSearchBar";
import SentOrdersTable from "./components/sentOrdersTable/SentOrdersTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const SentOrdersReport = () => {
  const dateFromRef = useRef();
  const dateToRef = useRef();

  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [data, setData] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSearch = async () => {
    const payload = Object();

    if (!selectedMarkets.length) {
      addMessage("Select markets", "error");

      return;
    }

    payload.from =
      dateFromRef.current?.value || moment().startOf("day").toISOString();
    payload.to =
      dateToRef.current?.value || moment().endOf("day").toISOString();
    payload.markets = selectedMarkets.map((data) => data.value) || [];

    payload.from = moment(payload.from).startOf("day").toISOString();
    payload.to = moment(payload.to).endOf("day").toISOString();

    const response = await makeRequest(getOrderSentReport.bind(null, payload));

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getOrderSentReport) && <Loading />}
      <SentOrdersSearchBar
        dateFromRef={dateFromRef}
        dateToRef={dateToRef}
        handleSearch={handleSearch}
        selectedMarkets={selectedMarkets}
        setSelectedMarkets={setSelectedMarkets}
      />
      {!!data?.length && <SentOrdersTable data={data} />}
    </WrapperWithScroll>
  );
};

export default SentOrdersReport;
