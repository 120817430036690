import React, { useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createCorrectionTicket,
} from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  Flex,
  H5,
} from "@/pages/consultant/contact/tickets/TicketsForCalling";
import { RedSspan } from "@/common/styles/RedSpan";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import Loading from "@/common/components/Loading";
import { filterActiveUserOptions } from "@/common/functions/filterActiveUserOptions";
import { filterConsultantRoles } from "./helpers/filterConsultantRoles";

const CreateCorrectionTicket = ({ setShow, setSuccess }) => {
  const { id } = useParams();
  const [selectedRevicer, setSelectedRevicer] = useState();
  const [revicerOptions, setRevicerOptions] = useState();
  const [error, setError] = useState();
  const messageRef = useRef();

  const {
    commonData: { users },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const sendTicketCorrectionCreate = async (e) => {
    e && e.preventDefault();

    if (!selectedRevicer || !messageRef.current?.value) {
      setError(() => true);
      setTimeout(() => {
        setError(() => false);
      }, 2000);
      return;
    }

    const payload = {};

    payload.reciver = selectedRevicer.value;
    payload.message = messageRef.current.value;

    const response = await makeRequest(
      createCorrectionTicket.bind(null, id, payload)
    );

    if (response.data) {
      setSuccess(() => true);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
      setShow(() => false);
    }
  };

  const handleLoadPosibleRecivers = async () => {
    setRevicerOptions(() =>
      filterActiveUserOptions(filterConsultantRoles(users))
    );
  };

  useLayoutEffect(() => {
    handleLoadPosibleRecivers();
  }, [users]);

  return (
    <>
      {!revicerOptions?.length && <Loading />}
      <PopUp setShow={setShow}>
        <div style={{ height: "500px" }}>
          <h4 style={{ color: Colors.darkBlue, marginBottom: "30px" }}>
            Correction Ticket:
          </h4>
          <form>
            <SelectInput
              width={100}
              color={Colors.darkBlue}
              name="Receiver"
              selected={selectedRevicer}
              setSelected={setSelectedRevicer}
              options={revicerOptions}
              required
            />
            <Flex>
              <H5>
                Message:<RedSspan>*</RedSspan>
              </H5>
              <textarea
                ref={messageRef}
                rows="10"
                cols="27"
                wrap
                style={{ marginLeft: "30px" }}
                placeholder="describe sytuation..."
              />
            </Flex>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ActionButton
                style={{ float: "right", margin: "20px 0px 0px -20px" }}
                defaultText={error ? "Please fill all fileds" : "Create Ticket"}
                onClick={(e) => sendTicketCorrectionCreate(e)}
              />
            </div>
          </form>
        </div>
      </PopUp>
    </>
  );
};

export default CreateCorrectionTicket;
