import React, { useRef, useState } from "react";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { SearchBarRaw } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import { getMetaAddsSpend } from "@/API/repositories/reports";
import { ButtonWrapper } from "./CostPerLead.styled";
import CostPerLeadTable from "./components/costPerLead/CostPerLeadTable";
import Loading from "@/common/components/Loading";
import CSV from "@/common/components/CSV";
import { HEADERS } from "./constants/headers";
import { generateData } from "./helpers/generateData";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import moment from "moment";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const CostPerLead = () => {
  const [accountsSpend, setAccountsSpend] = useState();
  const [selectedProducts, setSelectedProducts] = useState();

  const fromRef = useRef();
  const toRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();
  const {
    commonData: { products },
  } = useCommonDataContext();

  const handleSearch = async () => {
    if (!selectedProducts || !fromRef.current?.value || !toRef.current?.value)
      return addMessage("Select all data");

    setAccountsSpend(() => null);

    const payload = {};

    payload.products = selectedProducts.map(
      (productOption) => productOption.value
    );
    payload.from = moment(fromRef.current.value).format("YYYY-MM-DD");
    payload.to = moment(toRef.current.value).format("YYYY-MM-DD");

    const response = await makeRequest(getMetaAddsSpend.bind(null, payload));

    if (!!response?.data?.length) return setAccountsSpend(() => response.data);

    return addMessage("Not found");
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getMetaAddsSpend) && <Loading />}
      <SearchBarRaw>
        <div>
          <Input
            inputRef={fromRef}
            name="From"
            type="date"
            color={Colors.darkBlue}
            width={30}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="date"
            color={Colors.darkBlue}
            width={30}
          />
        </div>
        <div>
          <SelectInput
            name="Products"
            options={products
              .map((p) => ({ label: p.name, value: p._id, data: p }))
              .filter((p) => p.data.type !== "payment")}
            color={Colors.darkBlue}
            width={140}
            multiple
            selected={selectedProducts}
            setSelected={setSelectedProducts}
          />
        </div>
        <ButtonWrapper>
          {!!accountsSpend?.length && (
            <CSV
              filename="cpl-report.csv"
              header={HEADERS}
              data={generateData(accountsSpend)}
            />
          )}
          <ActionButton
            onClick={() => handleSearch()}
            defaultText="Search"
          />
        </ButtonWrapper>
      </SearchBarRaw>
      {!!accountsSpend?.length && (
        <CostPerLeadTable accountsSpend={accountsSpend} />
      )}
    </WrapperWithScroll>
  );
};

export default CostPerLead;
