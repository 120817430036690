import React, { useEffect, useState } from "react";
import SelectInput from "../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { addProductToOrder } from "../../../../API/repositories/order";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const ChooseProductView = ({
  product,
  currency,
  setChosenProductData,
  chosenProductData,
  orderId,
  contact,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(null);
  const { makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const hadnleAddProduct = async (e) => {
    e.preventDefault();

    const payload = [
      ...chosenProductData,
      {
        _id: chosenProductData.length + 1,
        name: selectedOption.label,
        value: selectedOption.value,
        price: selectedOptionPrice.value,
      },
    ];

    const response = await makeRequest(
      addProductToOrder.bind(null, orderId, payload)
    );
    if (response.data && selectedOption && selectedOptionPrice)
      setChosenProductData((prev) => payload);
  };

  const hadnleDelteProductItem = async (_id) => {
    let data = chosenProductData.filter((item) => item._id !== _id);

    const response = await makeRequest(
      addProductToOrder.bind(null, orderId, data)
    );

    if (response.data) {
      let id = 1;
      data = data.map((data) => {
        data._id = id;
        id++;
        return data;
      });
      setChosenProductData(data);
    }
  };

  useEffect(() => {}, [chosenProductData]);
  return (
    <div>
      <h4>
        <u>{translation.chooseOrderItem}:</u>
      </h4>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <label style={{ fontSize: "20px" }}>
          <strong style={{ marginRight: "20px" }}>{translation.name}:</strong>{" "}
          {product.name}
        </label>
        <SelectInput
          name={translation.variants}
          width={80}
          selected={selectedOption}
          setSelected={setSelectedOption}
          options={product.variants.map((varaint) => {
            return { label: varaint.name, value: varaint.short };
          })}
          selectWidth={200}
        />
        <SelectInput
          name={translation.price}
          width={40}
          selected={selectedOptionPrice}
          setSelected={setSelectedOptionPrice}
          options={product.prices.map((price) => {
            return { label: price.value, value: price.value };
          })}
          selectWidth={200}
        />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <ActionButton
            onClick={(e) => hadnleAddProduct(e)}
            disabled={
              contact.country === "cz" && undefined === contact.hasPromotion
            }
            defaultText={translation.add}
          />
        </div>
      </div>
      <h6 style={{ marginLeft: "80px", marginTop: "20px" }}>
        {translation.chosenOrderItems}:
      </h6>
      {chosenProductData.length > 0 ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ fontSize: "20px", textAlign: "center" }}>
                  {translation.variant}
                </th>
                <th style={{ fontSize: "20px", textAlign: "center" }}>
                  {translation.short}
                </th>
                <th style={{ fontSize: "20px", textAlign: "center" }}>
                  {translation.price}
                </th>
                <th style={{ fontSize: "20px", textAlign: "center" }}></th>
              </tr>
            </thead>
            <tbody>
              {chosenProductData.map((data) => (
                <tr style={{}}>
                  <td
                    style={{
                      width: "100px",
                      fontSize: "18px",
                      borderTop: "1px solid grey",
                      textAlign: "center",
                      padding: "11px 0px",
                    }}
                  >
                    {data.name}
                  </td>
                  <td
                    style={{
                      width: "200px",
                      fontSize: "18px",
                      borderTop: "1px solid grey",
                      textAlign: "center",
                    }}
                  >
                    {data.value}
                  </td>
                  <td
                    style={{
                      width: "200px",
                      fontSize: "18px",
                      borderTop: "1px solid grey",
                      textAlign: "center",
                    }}
                  >
                    {data.price} {currency}
                  </td>
                  <td
                    style={{
                      width: "80px",
                      fontSize: "18px",
                      borderTop: "1px solid grey",
                      textAlign: "center",
                    }}
                  >
                    <ActionButton
                      color={Colors.red}
                      onClick={() => hadnleDelteProductItem(data._id)}
                      style={{ background: "none", border: "none" }}
                    >
                      <i
                        class="fa fa-remove"
                        style={{ color: "red", fontSize: "20px" }}
                      ></i>
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{translation.nothingToSee}</div>
      )}
    </div>
  );
};

export default ChooseProductView;
