import { createCallCenterCandidate, updateCallCenterCandidate } from '@/API/repositories/callCenterCandidate';
import { Colors } from '@/common/colors/colors';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput';
import { CONSULTANT_MANAGER_ROLES } from '@/common/constants/consultantRoles';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { ButtonWrapper, FlexForm, SwitchesWrapper, Title } from '../../Recruitment.styled';
import Input from '@/common/components/Input';
import ToggleSwitch from '@/common/components/ToggleSwitch';
import RecruitmentChat from '@/common/components/recruitmentChat/RecruitmentChat';
import { CANDIDATE_INTERACTION_STATUSES, MONTH_HOURS, STATUSES_LABELS } from '@/common/constants/recruitmentStatuses';
import ActionButton from '@/common/components/buttons/ActionButton';
import { formatDate, formatToDateTamplate } from '@/common/functions/dateFormater';
import formatToGMT from '@/common/functions/formatToGMT';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import SubmitButton from '@/common/components/buttons/SubmitButton';
import { getBase64 } from '@/common/functions/getBase64';
import Icon from '@/common/components/Icon';
import { getFileById } from '@/API/repositories/storedDocument';
import AreYouSure from '@/common/components/areYouSure/AreYouSure';
import AddPersonalDocument from '@/common/components/addPersonalDocument/AddPersonalDocument';

const BUTTONS_ALLOWED_STATUSES = [CANDIDATE_INTERACTION_STATUSES.SECOND_STAGE_ACCEPT];

const CandidateForm = ({
  setShow,
  selectedCandidate,
  callCenterPositionsOptions,
  recruitmentPagesOptions,
  reload = () => { },
}) => {
  const [chatHeight, setChatHeight] = useState();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [showPersonalDocuments, setShowPersonalDocuments] = useState(false);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { options: { marketsOptions }, filterUsersByRoles } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const inputsRef = useRef();

  const formik = useFormik({
    initialValues: {
      market: marketsOptions.find(o => o.value === selectedCandidate?.market) || [],
      call_center_manager: filterUsersByRoles(CONSULTANT_MANAGER_ROLES).find(o => o.value === selectedCandidate?.call_center_manager) || [],
      recruitment_page: recruitmentPagesOptions.find(page => selectedCandidate?.recruitment_page === page.value) || "",
      call_center_position: callCenterPositionsOptions.find(position => selectedCandidate?.call_center_position === position.value) || "",
      first_name: selectedCandidate?.first_name || "",
      last_name: selectedCandidate?.last_name || "",
      email: selectedCandidate?.email || "",
      phone: selectedCandidate?.phone || "",
      status: selectedCandidate?.status || "new",
      has_headset: selectedCandidate?.has_headset,
      has_experience: selectedCandidate?.has_experience,
      month_hours: MONTH_HOURS.find(h => h.value === selectedCandidate.month_hours),
      possible_start_date: selectedCandidate?.possible_start_date && formatToDateTamplate(selectedCandidate.possible_start_date),
      stage_two_date: selectedCandidate?.stage_two_date && formatDate(selectedCandidate.stage_two_date, 'YYYY-MM-DD HH:mm'),
      recall_date: selectedCandidate?.recall_date && formatDate(selectedCandidate.recall_date, 'YYYY-MM-DD HH:mm'),
      headset_number: selectedCandidate?.headset_number,
      personal_documents: null,
      cv: null,
    },
    onSubmit: async (values) => handleSubmit(await foramtBeforeSubmit(values)),
  });

  const foramtBeforeSubmit = async (values) => {
    return {
      ...values,
      market: values.market.value,
      call_center_manager: values.call_center_manager.value,
      recruitment_page: values.recruitment_page.value,
      call_center_position: values.call_center_position.value,
      month_hours: values.month_hours?.value,
      possible_start_date: values.possible_start_date && formatToGMT(values.possible_start_date),
      stage_two_date: values.stage_two_date && formatToGMT(values.stage_two_date),
      recall_date: values.recall_date && formatToGMT(values.recall_date),
      cv: values.cv?.length && await getBase64(values.cv?.[0]),
      personal_documents: values.personal_documents,
    };
  }

  const handleSubmit = async (payload) => {
    const requestFn = selectedCandidate?._id ? updateCallCenterCandidate : createCallCenterCandidate;

    const response = await makeRequest(requestFn.bind(null, payload, selectedCandidate?._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  const handleToHire = async (payload) => {
    if (!payload) {
      return setShowPersonalDocuments(true);
    }

    formik.setFieldValue("status", CANDIDATE_INTERACTION_STATUSES.TO_HIRE);
    formik.setFieldValue("personal_documents", payload);
    formik.handleSubmit();
  }

  const handleGetStoredDocument = async () => {
    setIsFileLoading(() => true);

    const response = await makeRequest(
      getFileById.bind(null, selectedCandidate.cv)
    );

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
    setIsFileLoading(() => false);
  };

  const handleReject = async () => {
    formik.setFieldValue("status", CANDIDATE_INTERACTION_STATUSES.REJECT);
    formik.handleSubmit();
  }

  useEffect(() => {
    setChatHeight(inputsRef.current?.offsetHeight);
  }, [inputsRef.current?.offsetHeight]);

  return (
    <PopUp padding='10px 20px 15px' setShow={setShow}>
      {hasUnfilledRequest(createCallCenterCandidate, updateCallCenterCandidate) && <Loading />}
      <FlexForm onSubmit={formik.handleSubmit}>
        <div ref={inputsRef}>
          <Title>
            {STATUSES_LABELS.find(s => s.value === formik.values.status)?.label}
          </Title>
          <SelectInput
            color={Colors.darkBlue}
            name="Market"
            options={marketsOptions}
            width={200}
            required
            disabled={selectedCandidate?._id}
            value={formik.values.market}
            selected={formik.values.market}
            setSelected={(value) => {
              formik.setFieldValue('recruitment_page', "");
              formik.setFieldValue('call_center_position', "");
              formik.setFieldValue('market', value);
            }}
          />
          <SelectInput
            color={Colors.darkBlue}
            name="Call center manager"
            options={filterUsersByRoles(CONSULTANT_MANAGER_ROLES)}
            width={200}
            required
            value={formik.values.call_center_manager}
            selected={formik.values.call_center_manager}
            setSelected={(value) => formik.setFieldValue('call_center_manager', value)}
          />
          <SelectInput
            color={Colors.darkBlue}
            name="Recruitment page"
            disabled={!formik.values.market?.value || selectedCandidate?._id}
            options={recruitmentPagesOptions.filter(page => page.market === formik.values.market.value)}
            width={200}
            required
            value={formik.values.recruitment_page}
            selected={formik.values.recruitment_page}
            setSelected={(value) => formik.setFieldValue('recruitment_page', value)}
          />
          <SelectInput
            color={Colors.darkBlue}
            name="Call center position"
            disabled={!formik.values.market?.value}
            options={callCenterPositionsOptions.filter(position => position.market === formik.values.market.value)}
            width={200}
            required
            value={formik.values.call_center_position}
            selected={formik.values.call_center_position}
            setSelected={(value) => formik.setFieldValue('call_center_position', value)}
          />
          {selectedCandidate?.stage_two_date && (
            <SelectInput
              options={MONTH_HOURS}
              color={Colors.darkBlue}
              name="Month hours"
              value={formik.values.month_hours}
              setSelected={value => formik.setFieldValue('month_hours', value)}
              selected={formik.values.month_hours}
              required
              type='number'
              width={200}
            />
          )}
          <Input
            id={"first_name"}
            color={Colors.darkBlue}
            name="First name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
          />
          <Input
            id={"last_name"}
            color={Colors.darkBlue}
            name="Last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
          />
          <Input
            id={"email"}
            color={Colors.darkBlue}
            name="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
            type='email'
          />
          <Input
            id={"phone"}
            color={Colors.darkBlue}
            name="Phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            required
            requiredSign
            width={200}
          />
          {selectedCandidate?.stage_two_date && (
            <>
              <Input
                id={"stage_two_date"}
                color={Colors.darkBlue}
                name="Stage two date"
                value={formik.values.stage_two_date}
                onChange={formik.handleChange}
                required
                type='datetime-local'
                width={200}
                disabled
                requiredSign
              />
              <Input
                id={"headset_number"}
                color={Colors.darkBlue}
                name="Headset id"
                value={formik.values.headset_number}
                onChange={formik.handleChange}
                width={200}
              />
              <Input
                id={"possible_start_date"}
                color={Colors.darkBlue}
                name="Possible start date"
                value={formik.values.possible_start_date}
                onChange={formik.handleChange}
                required
                type='date'
                width={200}
                disabled
                requiredSign
              />
            </>
          )}
          {formik.values.recall_date && (
            <Input
              id={"recall_date"}
              color={Colors.darkBlue}
              name="Recall date"
              value={formik.values.recall_date}
              onChange={formik.handleChange}
              required
              type='datetime-local'
              width={200}
              disabled
              requiredSign
            />
          )}
          {selectedCandidate.cv
            ? (
              <p onClick={() => handleGetStoredDocument()} style={{ gap: "8px", display: "flex", alignItems: "center", cursor: "pointer", width: "fit-content", float: "right" }}>
                <span
                  style={{ color: Colors.darkGray2 }}
                >download CV</span>
                <Icon color={Colors.green} name={isFileLoading ? "fa fa-spinner fa-spin" : "fa fa-download"} />
              </p>
            )
            : (
              <Input
                name="CV"
                color={Colors.darkBlue}
                type='file'
                width={200}
                onChange={(e) => formik.setFieldValue('cv', e.target.files)}
              />
            )}
          {formik.values.stage_two_date && (
            <SwitchesWrapper>
              <ToggleSwitch
                name="has_headset"
                onChange={formik.handleChange}
                text="Has headset:"
                width={210}
                checked={formik.values.has_headset}
              />
              <ToggleSwitch
                name="has_experience"
                onChange={formik.handleChange}
                text="Has expericence:"
                width={210}
                checked={formik.values.has_experience}
              />
            </SwitchesWrapper>
          )}
          {!selectedCandidate?._id && (
            <ButtonWrapper>
              <SubmitButton text="Save" />
            </ButtonWrapper>
          )}
        </div>
        {selectedCandidate?._id && <div>
          <RecruitmentChat chatHeight={chatHeight} candidate={selectedCandidate} reload={reload} />
          <ButtonWrapper>
            {BUTTONS_ALLOWED_STATUSES.includes(selectedCandidate?.status) && (
              <>
                <ActionButton
                  onClick={() => handleToHire()}
                  color={Colors.green}
                  defaultText={"Accept"}
                />
              </>
            )}
            {selectedCandidate?.status !== CANDIDATE_INTERACTION_STATUSES.REJECT && (
              <ActionButton
                onClick={() => setShowAreYouSure(true)}
                defaultText={"Reject"}
                color={Colors.red}
              />
            )}
            <SubmitButton text="Save" />
          </ButtonWrapper>
        </div>
        }
        {showAreYouSure && <AreYouSure setShow={setShowAreYouSure} next={handleReject} />}
        {showPersonalDocuments &&
          <AddPersonalDocument
            submitFn={handleToHire}
            setShow={setShowPersonalDocuments}
          />}
      </FlexForm>
    </PopUp>
  )
}

export default CandidateForm
