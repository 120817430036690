import React, { useContext } from "react";
import { RealtimeContext } from "../context/RealtimeContext";
import { MarketsStatisticInbound, MarketsStatistics } from "./realtime";

const MarketStats = () => {
  const { marketStatistics, marketStatisticsInbound } =
    useContext(RealtimeContext);

  return (
    <>
      <MarketsStatistics data={marketStatistics} />
      <MarketsStatisticInbound data={marketStatisticsInbound} />
    </>
  );
};

export default MarketStats;
