import React, { useState, useLayoutEffect, useEffect } from "react";
import Bell from "./components/bell/Bell";
import TicketView from "./components/ticketView/TicketView";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getAmountOfNotSeenMessages } from "./helpers/getAmountOfNotSeenMessages";
import { getTicketsToDo } from "@/API/repositories/tickets";
import userManager from "@/API/userManager";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import Circle from "@/common/components/Circle";
import { CircleWrapper } from "./Tickets.styled";
import { sortTickets } from "./helpers/sortTickets";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const Tickets = () => {
  const [isTicketsViewOpen, setIsTicketsViewOpen] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState();
  const [filteredOldTickets, setFilteredOldTickets] = useState();
  const [tickets, setTickets] = useState();
  const [oldTickets, setOldTickets] = useState();
  const [ticketsHash, setTicketsHash] = useState();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();
  const { dashboardSettings, loadDashboardSettings } =
    useAdminDashboardContext();

  const currentUserId = userManager.getUser().id;

  const rerenderTickets = () => {
    setTickets((prev) => [...(prev || [])]);
    setOldTickets((prev) => [...(prev || [])]);
  };

  const loadData = async () => {
    const response = await makeRequest(getTicketsToDo.bind(null, ticketsHash));

    if (response.data?.hash) {
      const { hash } = response.data;

      if (hash === ticketsHash) {
        return;
      }

      setTicketsHash(hash);
    }

    if (response.data?.activeTickets) {
      const { activeTickets } = response.data;
      const sortedActiveTickets = sortTickets(activeTickets);

      setTickets(() => sortedActiveTickets);

      if (tickets && dashboardSettings.notifications) {
        const previous = getAmountOfNotSeenMessages(tickets, currentUserId);
        const current = getAmountOfNotSeenMessages(
          activeTickets,
          currentUserId
        );

        if (previous < current) {
          addMessage("You have some new messages", "error");
        }
      }
    }

    if (response.data?.outdatedTickets) {
      const { outdatedTickets } = response.data;

      const sortedOutdatedTickets = sortTickets(outdatedTickets);

      setOldTickets(() => sortedOutdatedTickets);
    }
  };

  useLayoutEffect(() => {
    if (
      !dashboardSettings &&
      !(window.location.href.split("/").at(-1) === "dashboard")
    ) {
      loadDashboardSettings();
    }
  }, []);

  useEffect(() => {
    if (dashboardSettings) {
      loadData();
    }
  }, [dashboardSettings?.old_tickets_limit]);

  return (
    <>
      <CircleWrapper>
        <Circle refreshTime={60} getData={loadData} />
      </CircleWrapper>
      <Bell setIsTicketsViewOpen={setIsTicketsViewOpen} tickets={tickets} />
      {isTicketsViewOpen && (
        <TicketView
          rerenderTickets={rerenderTickets}
          filteredTickets={filteredTickets}
          filteredOldTickets={filteredOldTickets}
          setFilteredTickets={setFilteredTickets}
          setFilteredOldTickets={setFilteredOldTickets}
          loadData={loadData}
          tickets={tickets}
          oldTickets={oldTickets}
          setIsTicketViewOpen={setIsTicketsViewOpen}
        />
      )}
    </>
  );
};

export default Tickets;
