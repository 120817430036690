import SubmitButton from "@/common/components/buttons/SubmitButton";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const { updateMailTemplate, createMailTemplate } = require("@/API/repositories/mailTemplate");
const { Colors } = require("@/common/colors/colors");
const { default: Input } = require("@/common/components/Input");
const { default: PopUp } = require("@/common/components/PopUp");
const { useRequestsContext } = require("@/common/hooks/requestHook");
const { Title, ButtonSaveWrapper } = require("@/common/styles/Mails");
const { useRef } = require("react");

const MailTemplateElement = ({ element, setElement, reload }) => {
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const templateSengridIdRef = useRef();
  const templateSengridNameRef = useRef();

  const handleSave = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload._id = element._id || null;
    payload.template_sengrid_id =
      templateSengridIdRef.current?.value || element.template_sengrid_id;
    payload.template_sengrid_name =
      templateSengridNameRef.current?.value || element.template_sengrid_name;

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailTemplate.bind(null, payload));
    } else {
      response = await makeRequest(createMailTemplate.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  return (
    <PopUp setShow={setElement}>
      <Title>Mail Template</Title>
      <form onSubmit={(e) => handleSave(e)}>
        <Input
          inputRef={templateSengridIdRef}
          width={230}
          inputWidth={400}
          name="Sengrid Template Id"
          color={Colors.darkBlue}
          value={element.template_sengrid_id}
          requiredSign
          required
        />
        <Input
          inputRef={templateSengridNameRef}
          width={230}
          inputWidth={400}
          name="Sengrid Template Name"
          color={Colors.darkBlue}
          value={element.template_sengrid_name}
          requiredSign
          required
        />
        <ButtonSaveWrapper>
          <SubmitButton text="Save" />
        </ButtonSaveWrapper>
      </form>
    </PopUp>
  );
};

export default MailTemplateElement;
