import React, { useMemo, useState } from "react";
import {
  BrowserIcon,
  BrowserIconWrapper,
  ButtonWrapper,
  InputsWrapper,
  ManualViewWrapper,
} from "./ManualCreation.styled";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import { Textarea } from "../../../addComment/AddComment.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createValidInvoiceTicket } from "@/API/repositories/tickets";
import { MANUAL_CHECK_CONFIG } from "../../constants/manualCheck";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ManualCreation = ({
  companyId,
  commentRef,
  handleManualCreation,
  orderId,
  countryName,
}) => {
  const [companyName, setCompanyName] = useState();
  const [street, setStreet] = useState();
  const [postalCode, setPostalCode] = useState();
  const [city, setCity] = useState();
  const [hasVisited, setHasVisited] = useState();

  const { makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const isAllowedToSaveManual = useMemo(() => {
    return companyId && street && postalCode && city && companyName;
  }, [companyId, street, postalCode, city, companyName]);

  const createValidInvoice = async () => {
    const payload = {};
    payload.company_name = companyName;
    payload.company_address = street + ", " + postalCode + ", " + city;
    payload.company_number = companyId.replace(/[\s-]/g, "");
    payload.additional_info = commentRef.current?.value;
    payload.city = city.trim();
    payload.street = street.trim();
    payload.postal_code = postalCode.trim();

    const response = await makeRequest(
      createValidInvoiceTicket.bind(null, orderId, payload)
    );

    return response?.data;
  };

  const handleCompassClick = async () => {
    await navigator.clipboard.writeText(companyId);
    const baseUrl = MANUAL_CHECK_CONFIG[countryName]?.link;
    const baseQuery = MANUAL_CHECK_CONFIG[countryName]?.query;
    let query = '';

    if (baseQuery) {
      query = baseQuery + companyId;
    }

    window.open(baseUrl + query, "_blank");

    setHasVisited(() => true);
  };

  return (
    <ManualViewWrapper>
      <BrowserIconWrapper>
        <BrowserIcon
          onClick={handleCompassClick}
          className="fa-regular fa-compass"
          hasVisited={hasVisited}
          notActive={!companyId?.length}
        />
      </BrowserIconWrapper>
      <InputsWrapper visibility={hasVisited ? "visible" : "hidden"}>
        <Input
          width={190}
          inputWidth={700}
          onChange={(e) => setCompanyName(e.target.value)}
          name={translation.company_name}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          width={190}
          inputWidth={700}
          onChange={(e) => setStreet(e.target.value)}
          name={translation.company_street_and_number}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          width={190}
          inputWidth={700}
          onChange={(e) => setPostalCode(e.target.value)}
          name={translation.company_postal_code}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          width={190}
          inputWidth={700}
          onChange={(e) => setCity(e.target.value)}
          name={translation.company_city}
          color={Colors.darkBlue}
          requiredSign
          required
        />
      </InputsWrapper>
      <InputsWrapper visibility={hasVisited ? "visible" : "hidden"}>
        <Textarea
          placeholder={translation.add_comment}
          rows={8}
          cols={105}
          ref={commentRef}
        />
      </InputsWrapper>
      <ButtonWrapper>
        <ActionButton
          style={{
            visibility: isAllowedToSaveManual ? "visible" : "hidden",
          }}
          color={Colors.red}
          defaultText={translation.create_invoice}
          onClick={() => handleManualCreation(createValidInvoice)}
        />
      </ButtonWrapper>
    </ManualViewWrapper>
  );
};

export default ManualCreation;
