import { Colors } from "@/common/colors/colors";
import { Circle, CircleNumber, Line } from "@/pages/consultant/consultantTests/ConsultantTests.styled";

export const MultilineProgresBarElement = ({ id, current, showFrom }) => {
  const color = id < current ? Colors.darkBlue : "grey";
  return (
    <>
      {id !== showFrom && <Line color={color} />}
      <Circle color={color}>
        <CircleNumber color={color}>{id + 1}</CircleNumber>
      </Circle>
    </>
  );
};