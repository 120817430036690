import React from 'react'
import { CLAIM_TYPES } from '@/common/constants/validClaims'
import { CheckBoxInputsWrapper, SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'
import { useValidClaim } from '@/common/hooks/useValidClaim'
import { getMaxClaimDate } from '@/common/functions/getMaxClaimDate'

const ClaimType = () => {
  const {
    claim,
    claimType,
    setClaimType
  } = useValidClaim();

  const maxClaimDate = getMaxClaimDate(claim);

  return (
    <SectionWrapper>
      <Subtitle>
        Claim type:
      </Subtitle>
      <CheckBoxInputsWrapper>
        <CheckBoxInput
          disabled={claim}
          checked={claimType === CLAIM_TYPES.REFUND}
          handleSelect={() => setClaimType(CLAIM_TYPES.REFUND)}
          text={`Refund (due ${maxClaimDate})`}
        />
        <CheckBoxInput
          disabled={claim}
          checked={claimType === CLAIM_TYPES.COMPLAINT}
          handleSelect={() => setClaimType(CLAIM_TYPES.COMPLAINT)}
          text="Complaint"
        />
      </CheckBoxInputsWrapper>
    </SectionWrapper>
  )
}

export default ClaimType
