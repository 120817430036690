import { getConsultantTakenContacts } from '@/API/repositories/reports'
import { Colors } from '@/common/colors/colors'
import ActionButton from '@/common/components/buttons/ActionButton'
import Input from '@/common/components/Input'
import Loading from '@/common/components/Loading'
import SelectInput from '@/common/components/SelectInput'
import { CONSULTANT_ROLES } from '@/common/constants/consultantRoles'
import { CALL_HISTORY_STATUSES } from '@/common/constants/statuses'
import { formatToDateTimeFormaterTemplate } from '@/common/functions/dateFormater'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { SearchBarRaw } from '@/common/styles/SearchBars'
import moment from 'moment'
import React, { useMemo, useRef, useState } from 'react'

const SearchBar = ({ setData }) => {
  const [selctedUsers, setSelectedUsers] = useState([]);
  const [status, setStatus] = useState([]);

  const fromRef = useRef();
  const toRef = useRef();
  const today = formatToDateTimeFormaterTemplate(moment());

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();
  const { filterUsersByRoles } = useCommonDataContext();

  const usersOptions = useMemo(() => 
    filterUsersByRoles(CONSULTANT_ROLES), [])

  const handleSearch = async (e) => {
    e && e.preventDefault();

    if (selctedUsers.length === 0) {
      addMessage("Select user", "error");

      return;
    }

    if (!status.length) {
      addMessage("Select statuses", "error");

      return;
    }

    const payload = {
      to: toRef?.current?.value || moment(today).toISOString(),
      from: fromRef?.current?.value || moment(today).toISOString(),
      consultant: selctedUsers.value,
      status: status.map((s) => s.value) || [],
    };

    payload.to = moment(payload.to).toISOString();
    payload.from = moment(payload.from).toISOString();

    const response = await makeRequest(
      getConsultantTakenContacts.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <SearchBarRaw>
       {hasUnfilledRequest(getConsultantTakenContacts) && <Loading />}
      <div>
        <SelectInput
          name="consultant"
          selected={selctedUsers}
          setSelected={setSelectedUsers}
          options={usersOptions}
          width={80}
          selectWidth={200}
          color={Colors.darkBlue}
          placeholder="Select user..."
        />
        <SelectInput
          name="status"
          selected={status}
          setSelected={setStatus}
          options={CALL_HISTORY_STATUSES}
          width={80}
          selectWidth={200}
          color={Colors.darkBlue}
          placeholder="Select status..."
          multiple={true}
        />
      </div>
      <div>
        <Input
          name="From"
          type="datetime-local"
          inputWidth={180}
          color={Colors.darkBlue}
          width={50}
          inputRef={fromRef}
          value={today}
        />
        <Input
          inputRef={toRef}
          name="To"
          type="datetime-local"
          width={50}
          inputWidth={180}
          color={Colors.darkBlue}
          value={today}
        />
      </div>
      <div>
        <ActionButton defaultText="Search" onClick={(e) => handleSearch(e)} />
      </div>
    </SearchBarRaw>
  )
}

export default SearchBar
