import EditItem from "@/common/components/EditItem";
import { getOrderItems } from "@/common/functions/getOrderItems";

export const getRaws = (dataToDisplay, handleShowOrderEdit) => dataToDisplay.orders.map((data) => (
	<tr>
		<td>
			{data.status}
		</td>
		<td>
			{data.shipping?.currency || data.currency}
		</td>
		<td>
			{data._product.name}
		</td>
		<td>
			{getOrderItems(data)
				?.map((item) => item.product_variant)
				?.join(", ") || "--------"}
		</td>
		<td>
			{getOrderItems(data)
				?.reduce((prev, next) => prev + next.price, 0)
				?.toFixed(2) || "------"}
		</td>
		<td>
			{data.shipping?.status === "delivered_no_payment"
				? "delivered"
				: data.shipping?.status || "------"}
		</td>
		<td>
			{!data.showEdit ? (
				<div
					onClick={(e) => handleShowOrderEdit(e, data, false)}
				>
					<EditItem />
				</div>
			) : (
				"Sent"
			)}
		</td>
	</tr>
))