import React, { useEffect, useState } from "react";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { addDwUsers } from "@/API/repositories/tickets";
import { Colors } from "@/common/colors/colors";
import { SuccessButton } from "../../../conversationGroup/ConversationGroup.styled";
import userManager from "@/API/userManager";
import { findSuperior } from "@/common/functions/findSuperior";
import { ConversatinDWInputWrapper } from "@/pages/admin/infobar/components/ticket/components/conversationGroup/ConversationGroup.styled";

const DwSelect = ({
  selectedTicket,
  loadData,
  setDwSavedUsers,
  departments,
}) => {
  const [dwItems, setDwItems] = useState([]);
  const [dwLoading, setDwLoading] = useState();
  const [isDwSuccess, setIsDwSuccess] = useState(true);
  const {
    commonData: { users },
    getActiveUsersOptions
  } = useCommonDataContext();
  const usersOptions = getActiveUsersOptions();

  const { makeRequest } = useRequestsContext();

  const userId = userManager.getUser().id;
  const superior = usersOptions.find(
    (option) => option.value === findSuperior(userId, users)?._id
  );

  const getReceiverOptions = () => {
    const reciverOptions = [];

    if (superior) {
      reciverOptions.push(superior);
    }

    if (departments) {
      reciverOptions.push(
        ...(departments?.map((d) => ({
          label: d.name,
          value: d._id,
        })) || [])
      );
    }

    return reciverOptions.filter(
      (o) =>
        !selectedTicket.dw?.includes(o.value) &&
        !selectedTicket.dw?.some((userId) => {
          const department = departments.find((d) => d._id === o.value);

          return (
            department?.users?.includes(userId) ||
            department?.head_of_department === userId
          );
        })
    );
  };

  const handleAddDw = async () => {
    setDwLoading(() => true);

    const ids = [];

    dwItems.forEach((item) => {
      const department = departments.find((d) => d._id === item.value);

      if (department) {
        ids.push(...(department.users || []), department.head_of_department);
      } else {
        ids.push(item.value);
      }
    });

    const response = await makeRequest(
      addDwUsers.bind(null, selectedTicket._id, ids)
    );

    if (response.data) {
      setDwItems(() => []);
      await loadData();
      setIsDwSuccess(() => true);
    }

    setDwLoading(() => false);
  };

  const handleChangeDwUsers = (updatedDwUsers) => {
    if (!updatedDwUsers?.length) {
      setIsDwSuccess(() => true);
    } else {
      setIsDwSuccess(() => false);
    }
    setDwItems(() => updatedDwUsers);
  };

  const handleSetDwUsers = () => {
    setDwSavedUsers(
      () =>
        usersOptions.filter((userOption) =>
          selectedTicket?.dw?.includes(userOption.value)
        ) || []
    );

    setIsDwSuccess(() => true);
    setDwItems(() => []);
  };

  useEffect(() => {
    handleSetDwUsers();
  }, [selectedTicket]);

  return (
    <ConversatinDWInputWrapper>
      <SelectInput
        multiple
        options={getReceiverOptions()}
        color="gray"
        width={20}
        selectWidth={500}
        selectFontSize={14}
        setSelected={handleChangeDwUsers}
        selected={dwItems}
        showLabel={false}
      />
      <SuccessButton
        onClick={() => handleAddDw()}
        backgroundColor={isDwSuccess ? Colors.green : ""}
        borderColor={isDwSuccess ? Colors.green : Colors.darkGray2}
        color={isDwSuccess ? "white" : Colors.darkGray2}
        className="animation-scale"
      >
        <i
          className={dwLoading ? "fa-solid fa-spinner fa-spin" : "fa fa-check "}
        />
      </SuccessButton>
    </ConversatinDWInputWrapper>
  );
};

export default DwSelect;
