export const CLAIM_TYPES = {
  REFUND: 'refund',
  COMPLAINT: 'complaint'
}

export const ClAIM_METHODS = {
  BANK_TRANSFER: {
    value: 'bank_transfer',
    label: "Bank transfer"
  },
  CASH: {
    value: 'cash',
    label: "Cash"
  }
}

export const PAYMENT_METHODS_OPTIONS = [
  {
    label: "Revolut",
    value: "revolut",
  },
  {
    label: "ING",
    value: "ing",
  },
  {
    label: "Cash",
    value: "cash",
  }
];
