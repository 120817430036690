import ActionButton from '@/common/components/buttons/ActionButton'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper } from './RecruitmentPage.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import RecruitmentPageForm from './components/recruitmentPageForm/RecruitmentPageForm';
import { getPages } from '@/API/repositories/recruitmentPage';
import RecruitmentPageTable from './components/recruitmentPageTable/RecruitmentPageTable';
import SelectInput from '@/common/components/SelectInput';
import { Colors } from '@/common/colors/colors';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';

const RecruitmentPage = () => {
  const [show, setShow] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [pages, setPages] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [visiblePages, setVisiblePages] = useState([]);

  const { options: { marketsOptions }} = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadPages = async () => {
    setPages([]);
    const response = await makeRequest(getPages);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setPages(response.data);
    setVisiblePages(response.data.filter(p => !selectedMarket?.value || p.market === selectedMarket?.value));
  };

  const handleFilterMarkets = (value) => {
    setSelectedMarket(value);
    setVisiblePages(() => pages.filter(p => !value?.value || p.market === value?.value));
  }

  useEffect(() => {
    loadPages();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getPages) && <Loading />}
      <ContentWrapper>
        <ActionButton 
          defaultText="Add page" 
          onClick={() => {
            setSelectedPage(null)
            setShow(true)
          }}  
        />
         <SelectInput
          name="Market" 
          selected={selectedMarket} 
          setSelected={handleFilterMarkets} 
          color={Colors.darkBlue} 
          width={70} 
          options={[{ label: "None", value: null }, ...marketsOptions]} 
        />
      </ContentWrapper>
      {show && <RecruitmentPageForm selectedPage={selectedPage} reload={loadPages} setShow={setShow} />}
      {!!pages?.length && (
        <RecruitmentPageTable setSelectedPage={setSelectedPage} setShow={setShow} pages={visiblePages} />
      )}
    </PageWrapper>
  )
}

export default RecruitmentPage
