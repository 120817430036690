import styled from 'styled-components';

export const Container = styled.div`
	max-width: 80vw;
`;

export const Flex = styled.div`
	display: flex;
`;

export const ButtonsWrapper = styled.div`
	margin-top: 10px;
	float: right;
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const StyledTextField = styled.span`
	padding: 5px;
	cursor: pointer;
	border-radius: 10px;
	border: 1px solid black;
	background: ${({ bgColor }) => bgColor};
`;

export const FieldsWrapper = styled.div`
	display: flex;
	gap: 20px;
`;
