import styled from "styled-components";

export const FormHeader = styled.h2`
  color: rgb(3, 80, 114);
  margin: -20px 0 30px 0;
  font-size: 25px;
  font-weight: bolder;
`;

export const SelectTypeContainer = styled.div`
  grid-area: type;
`;

export const SubjectInputContainer = styled.div`
  grid-area: subject;
`;

export const RecipientsListContainer = styled.div`
  width: 800px;
  margin: 15px 0 0 0;
  grid-area: recipients;
`;

export const CronContainer = styled.div`
  margin: 20px 50px;
  grid-area: cron;
  width: 700px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  grid-area: button;
`;

export const ActiveSwitchContainer = styled.div`
  grid-area: active;
  margin: 15px 0 0 0;
`;

export const CronSwitchContainer = styled.div`
  grid-area: cronswitch;
  margin: 15px 40px;
`;

export const DeleteButtonContainer = styled.div`
  grid-area: delete;
  padding-left: 50px;
`;

export const InfoText = styled.div`
  font-size: 13px;
  margin-top: 10px;
`;
