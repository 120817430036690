import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 20px;
	justify-content: flex-end;
	padding-right: 18px;
`;

export const SaveButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 10px 20px 30px;
`;
