import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import { formatDate } from "../../../common/functions/dateFormater";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  color: ${Colors.darkBlue};
  font-weight: bold;
  min-width: 1000px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 20px;
  margin: 0px 0px 20px 0px;
`;

const FlexWrapperElement = styled.div`
  cursor: pointer;
`;

const ScorllTable = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const ContactsTable = ({ data }) => {
  const { translation } = useTranslationContext();

  return (
    <ScorllTable>
      <table style={{ minWidth: "1000px" }} className="styled-table">
        <thead>
          <tr>
            <th>{translation.donwloaded_at}</th>
            <th>{translation.full_name}</th>
            <th>{translation.email}</th>
            <th>{translation.number}</th>
            <th>{translation.decision}</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((elemet, i) => (
              <tr key={i}>
                <td>{formatDate(elemet.created_at, "YYYY-MM-DD HH:mm")}</td>
                <td>{elemet.contact[0]?.full_name}</td>
                <td>{elemet.contact[0]?.email}</td>
                <td>{elemet.contact[0]?.phone_number}</td>
                <td>{elemet.status}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </ScorllTable>
  );
};

const MailsTable = ({ data }) => {
  const { translation } = useTranslationContext();

  return (
    <ScorllTable>
      <table style={{ minWidth: "1000px" }} className="styled-table">
        <thead>
          <tr>
            <th>{translation.sent_at}</th>
            <th>{translation.full_name}</th>
            <th>{translation.email}</th>
            <th>{translation.number}</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((elemet, i) => (
              <tr key={i}>
                <td>{formatDate(elemet.created_at, "YYYY-MM-DD HH:mm")}</td>
                <td>{elemet.contact[0]?.full_name}</td>
                <td>{elemet.contact[0]?.email}</td>
                <td>{elemet.contact[0]?.phone_number}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </ScorllTable>
  );
};

const DetailedStatistics = ({
  contactsData = [],
  mailsData = [],
}) => {
  const { translation } = useTranslationContext();
  const [shouldShowContacts, setShouldShowContacts] = useState(true);

  return (
    <Wrapper>
      <FlexWrapper>
        <FlexWrapperElement
          onClick={() => setShouldShowContacts(true)}
          className="animation-scale"
          style={{
            border: shouldShowContacts
              ? `1px dashed ${Colors.darkBlue}`
              : "none",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          {translation.todays_contacts}
        </FlexWrapperElement>
        <FlexWrapperElement
          onClick={() => setShouldShowContacts(false)}
          className="animation-scale"
          style={{
            border: !shouldShowContacts
              ? `1px dashed ${Colors.darkBlue}`
              : "none",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          {translation.todays_mails}
        </FlexWrapperElement>
      </FlexWrapper>
      {shouldShowContacts ? (
        <ContactsTable data={contactsData} />
      ) : (
        <MailsTable data={mailsData} />
      )}
    </Wrapper>
  );
};

export default DetailedStatistics;
