import styled from 'styled-components';

export const PaymentMethodWrapper = styled.div`
  margin-top: 16px;
  min-height: 80px;
`;

export const PaymentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const BankTransferWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: flex-end;
`;

export const BankItemsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

