import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 0px auto;
	min-width: 1150px;
	max-width: 50vw;
	display: flex;
	justify-content: center;
	font-size: 14px;
`;
