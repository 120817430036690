import styled from  'styled-components';

export const Wrapper = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;
width: 100%;
gap: 30px;
padding: 20px;
overflow-y: auto;
max-height: 100vh;
padding-bottom: 100px;
`;
