import React, { useEffect, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import {
  ButtonCreateWrapper,
  Wrapper,
} from "../../../common/styles/Mails";
import { MailTemplateElement } from "./components/mailTemplateElement/MailTemplateElement";
import { createMailTest, getMailTests, updateMailTest } from "@/API/repositories/mailTest";
import { getMailTemplates } from "@/API/repositories/mailTemplate";
import Loading from "@/common/components/Loading";
import Table from "@/common/components/Table";
import { HEADERS_ARRAY } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import ActionButton from "@/common/components/buttons/ActionButton";

const MailTest = () => {
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [element, setElement] = useState();

  const handleLoadData = async () => {
    const mailTemplateResponse = await makeRequest(getMailTemplates);

    if (mailTemplateResponse.data) {
      setMailTemplateOptions(() =>
        mailTemplateResponse.data.map((template) => ({
          label: template.template_sengrid_name,
          value: template._id,
        }))
      );
    }

    const response = await makeRequest(getMailTests);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getMailTests, createMailTest, updateMailTest) && (
        <Loading />
      )}
      <Wrapper>
        <ButtonCreateWrapper>
          <ActionButton
            defaultText="Create"
            onClick={() => setElement({})}
          />
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            reload={handleLoadData}
            templates={mailTemplateOptions}
          />
        )}
        {!!data?.length && !!mailTemplateOptions?.length && (
          <Table 
            className="styled-table" 
            headersArray={HEADERS_ARRAY} 
            raws={getRaws(data, setElement)} 
          />
        )}
      </Wrapper>
    </>
  );
};

export default MailTest;
