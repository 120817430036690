import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getOneProduct,
  updateProduct,
} from "../../../API/repositories/product";
import Card from "../../../common/components/Card";
import Form from "../../../common/components/Form";
import Input from "../../../common/components/Input";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { Colors } from "../../../common/colors/colors";
import Loading from "../../../common/components/Loading";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getKeysByType } from "@/API/repositories/key";
import { KEY_TYPES } from "@/common/constants/keyTypes";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import SelectInput from "@/common/components/SelectInput";
import { getCompanies } from "@/API/repositories/company";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import RichTextEditor from "@/common/components/richTextEditor/RichTextEditor";
import { getMarkdownFromQuill } from "@/common/functions/getMarkdownFromQuill";
import { setQuillContent } from "@/common/functions/setQuillContent";

const Wrapper = styled.div`
  display: flex;
  margin: 30px;
  justify-content: center;
`;

const WrapperBox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px;
  gap: 30px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 800px;
`;

const ItemsTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${Colors.darkBlue};
  width: 110px;
`;

const Item = styled.div`
  cursor: pointer;
  background-color: ${Colors.green};
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 10px;
  font-size: 15px;
`;

const Splitter = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.darkGray};
  margin: 18px 0;
`;

export const PRODUCT_TYPES = [
  {
    label: "Payment",
    value: "payment",
  },
  {
    label: "Shipping",
    value: "shipping",
  },
];

const ProductShow = () => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { id } = useParams();
  const [value, setValue] = React.useState("");
  const [variants, setVaraints] = useState([]);
  const [mailNames, setMailNames] = useState([]);
  const [prices, setPrices] = useState([]);
  const [keys, setKeys] = useState();
  const [selectedKey, setSelectedKey] = useState();
  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedType, setSelectedType] = useState();

  const nameRef = useRef();
  const varaintNameRef = useRef();
  const varaintShortRef = useRef();
  const shortRef = useRef();
  const priceRef = useRef();
  const mailNameRef = useRef();
  const mailShortRef = useRef();
  const platformLinkRef = useRef();
  const itemFakturowniaRef = useRef();

  const { addMessage } = useMessageQueueContext();
  const quillRef = useRef();

  const handleAddMailName = (e) => {
    e && e.preventDefault();
    const name = mailNameRef.current.value;
    const short = mailShortRef.current.value;
    if (!name || !short) return;

    const _id = mailNames?.length + 1;
    setMailNames((prev) => [...prev, { _id, name, short }]);

    mailNameRef.current.value = "";
    mailShortRef.current.value = "";
  };

  const handleDeleteMailName = (e, _id) => {
    e && e.preventDefault();
    let filteredMailNames = mailNames.filter((mail) => mail._id !== _id);

    _id = 1;
    filteredMailNames = filteredMailNames.map((variant) => {
      variant._id = _id;
      _id++;
      return variant;
    });

    setMailNames(() => filteredMailNames);
  };

  const handleAddVaraint = (e) => {
    e && e.preventDefault();
    const name = varaintNameRef.current.value;
    const short = varaintShortRef.current.value;
    if (!name || !short) return;

    const _id = variants.length + 1;
    setVaraints((prev) => [...prev, { _id: _id, name: name, short: short }]);

    varaintNameRef.current.value = "";
    varaintShortRef.current.value = "";
  };

  const handleDeleteVariant = (e, _id) => {
    e && e.preventDefault();
    let filtredVaraints = variants.filter((variant) => variant._id !== _id);

    _id = 1;
    filtredVaraints = filtredVaraints.map((variant) => {
      variant._id = _id;
      _id++;
      return variant;
    });
    setVaraints(() => filtredVaraints);
  };

  const handleAddPrice = (e) => {
    e && e.preventDefault();
    const value = priceRef.current.value;

    if (!value) return;

    const _id = prices.length + 1;

    setPrices((prev) => [...prev, { _id: _id, value: value }]);
    priceRef.current.value = "";
  };

  const handleDeletePrice = (e, _id) => {
    e && e.preventDefault();
    let filtredPrices = prices.filter((price) => price._id !== _id);

    _id = 1;
    filtredPrices = filtredPrices.map((price) => {
      price._id = _id;
      _id++;
      return price;
    });
    setPrices(() => filtredPrices);
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    const name = nameRef.current.value;
    const short = shortRef.current.value;

    const product = {};
    product.name = name;
    product.short = short;
    product.variants = variants;
    product.prices = prices;
    product.product_script = getMarkdownFromQuill(quillRef);
    product.mail_names = mailNames;
    product.sendgrid_key = selectedKey.value;
    product.platformLink = platformLinkRef?.current?.value;
    product.company = selectedCompany.value;
    product.type = selectedType.value;
    product.fakturownia_item_title = itemFakturowniaRef.current.value;

    const response = await makeRequest(updateProduct.bind(null, id, product));

    if (response.data) {
      addMessage("Saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const handleGetProduct = async () => {
    const getProductResponse = await makeRequest(getOneProduct.bind(null, id));
    if (getProductResponse.data) {
      nameRef.current.value = getProductResponse.data.name;
      shortRef.current.value = getProductResponse.data.short;
      platformLinkRef.current.value = getProductResponse.data.platformLink;

      itemFakturowniaRef.current.value =
        getProductResponse.data.fakturownia_item_title || "";
      setVaraints(() => getProductResponse.data.variants);
      setPrices(() => getProductResponse.data.prices);
      setQuillContent(quillRef, getProductResponse.data.product_script);
      setMailNames(() => getProductResponse.data.mail_names);
      setSelectedKey(() =>
        keys?.find((key) => key.value === getProductResponse.data.sendgrid_key)
      );
      setSelectedCompany(() =>
        companies?.find(
          (company) => company.value === getProductResponse.data.company
        )
      );
      setSelectedType(() =>
        PRODUCT_TYPES.find((p) => p.value === getProductResponse.data.type)
      );
    }
  };

  const loadData = async () => {
    const keysResponse = await makeRequest(
      getKeysByType.bind(null, KEY_TYPES.SENDGRID)
    );

    if (!keysResponse?.data) {
      return addMessage("Loading keys error", "error");
    }

    setKeys(() => handleMapToOptions(keysResponse.data, "label", "_id"));

    const companiesResponse = await makeRequest(getCompanies);

    if (!companiesResponse?.data) {
      return addMessage("Loading companies error", "error");
    }

    setCompanies(() =>
      handleMapToOptions(companiesResponse.data, "name", "_id")
    );
  };

  useEffect(() => {
    if (keys && companies) {
      handleGetProduct();
    }
  }, [id, keys, companies]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(
        updateProduct,
        getKeysByType,
        getOneProduct,
        getCompanies
      ) && <Loading />}
      <Card>
        <div style={{ margin: "20px" }}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <Input
                    inputRef={nameRef}
                    name="Name"
                    value=""
                    required={true}
                    disabled={false}
                    width={155}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={shortRef}
                    name="Short"
                    value=""
                    required={true}
                    disabled={false}
                    width={100}
                    color={Colors.darkBlue}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <SelectInput
                    name="Sendgrid key"
                    color={Colors.darkBlue}
                    width={155}
                    options={keys}
                    setSelected={setSelectedKey}
                    required
                    hideStar
                    selected={selectedKey}
                  />
                  <SelectInput
                    name="Company"
                    color={Colors.darkBlue}
                    selected={selectedCompany}
                    setSelected={setSelectedCompany}
                    options={companies}
                    width={100}
                    required
                    hideStar
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Input
                    name="Item fakturownia"
                    inputRef={itemFakturowniaRef}
                    value=""
                    required={true}
                    width={155}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={platformLinkRef}
                    name="Platfrom Link"
                    disabled={false}
                    width={100}
                    color={Colors.darkBlue}
                  />
                </div>
                <div>
                  <SelectInput
                    name="Type"
                    color={Colors.darkBlue}
                    selected={selectedType}
                    setSelected={setSelectedType}
                    options={PRODUCT_TYPES}
                    width={155}
                  />
                </div>
                <Splitter />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Input
                    inputRef={mailNameRef}
                    name="name"
                    value=""
                    disabled={false}
                    width={60}
                    inputWidth={140}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={mailShortRef}
                    name="short"
                    value=""
                    disabled={false}
                    width={60}
                    inputWidth={140}
                    color={Colors.darkBlue}
                  />
                  <ActionButton
                    onClick={(e) => handleAddMailName(e)}
                    text="Add mail name"
                  />
                </div>
                <WrapperBox>
                  <ItemsTitle>Mail names:</ItemsTitle>
                  <ItemsWrapper>
                    {!!mailNames?.length
                      ? mailNames.map((mailName) => (
                        <Item
                          onClick={(e) =>
                            handleDeleteMailName(e, mailName._id)
                          }
                        >
                          {mailName.name} {mailName.short}
                        </Item>
                      ))
                      : ""}
                  </ItemsWrapper>
                </WrapperBox>
                <Splitter />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Input
                    inputRef={varaintNameRef}
                    name="name"
                    value=""
                    disabled={false}
                    width={60}
                    inputWidth={140}
                    color={Colors.darkBlue}
                  />
                  <Input
                    inputRef={varaintShortRef}
                    name="short"
                    value=""
                    disabled={false}
                    width={60}
                    inputWidth={140}
                    color={Colors.darkBlue}
                  />
                  <ActionButton
                    onClick={(e) => handleAddVaraint(e)}
                    text="Add varaint"
                  />
                </div>
                <WrapperBox>
                  <ItemsTitle>Variants:</ItemsTitle>
                  <ItemsWrapper>
                    {variants.length > 0
                      ? variants.map((varaint) => (
                        <Item
                          onClick={(e) => handleDeleteVariant(e, varaint._id)}
                        >
                          {varaint.name} {varaint.short}
                        </Item>
                      ))
                      : ""}
                  </ItemsWrapper>
                </WrapperBox>
                <Splitter />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Input
                    color={Colors.darkBlue}
                    inputRef={priceRef}
                    name="price"
                    value=""
                    width={60}
                    inputWidth={140}
                    type="number"
                  />
                  <ActionButton
                    text="Add price"
                    onClick={(e) => handleAddPrice(e)}
                  />
                </div>
                <WrapperBox>
                  <ItemsTitle>Prices:</ItemsTitle>
                  {prices.length > 0
                    ? prices.map((price) => (
                      <div
                        onClick={(e) => handleDeletePrice(e, price._id)}
                        style={{
                          cursor: "pointer",
                          background: Colors.green,
                          padding: "5px",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "10px",
                        }}
                      >
                        <p style={{ fontSize: "15px" }}>{price.value}</p>
                      </div>
                    ))
                    : ""}
                </WrapperBox>
              </div>
            </div>
            <Splitter />
            <div style={{ width: "60vw" }}>
              <RichTextEditor ref={quillRef} />
            </div>
            <Splitter />
            <SubmitButton
              text="Save"
              type="submit"
              style={{ float: "right", margin: "20px", }}
            />
          </Form>
        </div>
      </Card>
    </Wrapper>
  );
};

export default ProductShow;
