import React, { useLayoutEffect, useState } from "react";
import ActionButton from "@/common/components/buttons/ActionButton";
import { PageWrapper } from "@/common/styles/Wrappers";
import { ContentWrapper } from "./Gamification.styled";
import GamificatinForm from "./components/gamificationForm/GamificatinForm";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  createGamificationConfig,
  updateGamificationConfig,
  getGamificationConfigs,
} from "@/API/repositories/gamificationConfig";
import GamificationConfigsTable from "./components/gamificationConfigsTable/GamificationConfigsTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const Gamification = () => {
  const [gamificationConfigs, setGamificationConfigs] = useState();
  const [isGamificationFormOpen, setIsGamificationFormOpen] = useState();
  const [selectedConfig, setSelectedConfig] = useState();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadConfig = async () => {
    const response = await makeRequest(getGamificationConfigs);

    if (response.data) setGamificationConfigs(() => response.data);
  };

  const handleSubmitConfig = async (payload) => {
    const response = selectedConfig
      ? await makeRequest(updateGamificationConfig.bind(null, payload))
      : await makeRequest(createGamificationConfig.bind(null, payload));

    if (!response.data) return addMessage("Something went wrong!", "error");

    addMessage("Success", "success");
    setIsGamificationFormOpen(() => false);
    setSelectedConfig(() => null);
    loadConfig();
  };

  useLayoutEffect(() => {
    loadConfig();
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content" }}
          onClick={() => setIsGamificationFormOpen(() => true)}
          defaultText="Add config"
        />
      </ContentWrapper>
      {!!gamificationConfigs?.length && (
        <GamificationConfigsTable
          gamificationConfigs={gamificationConfigs}
          setSelectedConfig={setSelectedConfig}
        />
      )}
      {(isGamificationFormOpen || selectedConfig) && (
        <GamificatinForm
          gamificationConfig={selectedConfig}
          setIsGamificationFormOpen={
            selectedConfig ? setSelectedConfig : setIsGamificationFormOpen
          }
          handleSubmitConfig={handleSubmitConfig}
        />
      )}
    </PageWrapper>
  );
};

export default Gamification;
