import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

const Recipient = styled.div`
  padding: 8px;
  border-radius: 7px;
  background-color: ${Colors.green};
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  width: fit-content;

  &:hover {
    opacity: 0.8;
  }
`;

const RecipientsList = ({ recipients, setRecipients }) => {
  const handleRemoveRecipient = (name) => {
    setRecipients((prev) => prev.filter((r) => r !== name));
  };

  return (
    <div style={{ margin: "0 0 20px 50px" }}>
      <p
        style={{
          color: "rgb(3, 80, 114)",
          margin: "0 0 10px 0",
          fontSize: "18px",
        }}
      >
        <strong>
          Recipients: <span style={{ color: Colors.red }}>*</span>
        </strong>
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 5,
        }}
      >
        {recipients.map((recipient, index) => (
          <>
            <Recipient
              onClick={() => handleRemoveRecipient(recipient)}
              key={index}
            >
              {recipient}
            </Recipient>
            {index !== recipients.length - 1 && (
              <div style={{ marginTop: 15 }}>,</div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default RecipientsList;
