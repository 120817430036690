import moment from "moment";

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const formatToDateTamplate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatMonthName = (monthName, format = "MM_YYYY") => {
  return moment(monthName, "MMMM YYYY").format(format);
};

export const formatToDateTimeFormaterTemplate = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm");
};

export const formatToExactTimeDate = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};
