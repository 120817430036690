import React, { useRef, useState } from 'react'
import PopUp from '../PopUp'
import SelectInput from '../SelectInput'
import Input from '../Input'
import { Colors } from '@/common/colors/colors'
import { PERSONAL_DOCUMENTS_OPTIONS } from '@/common/constants/personalDocuments'
import ActionButton from '../buttons/ActionButton'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import moment from 'moment'
import { ButtonWrapper } from './AddPersonalDocument.styled'

const AddPersonalDocument = ({ setShow, submitFn, edit }) => {
	const [selectedDoc, setSelectedDoc] = useState();
	const fromRef = useRef();
	const toRef = useRef();

	const { addMessage } = useMessageQueueContext();

	const handleSave = () => {
		if (!edit) {
			if (!selectedDoc || !fromRef.current?.value || !toRef.current?.value) {
				return addMessage("Fill all fields");
			}
		} else {
			if (!toRef.current?.value) {
				return addMessage("Add new 'to date'");
			}
		}

		const payload = [{
			name: selectedDoc?.value,
			from: moment(fromRef.current?.value).format('YYYY-MM-DD'),
			to: moment(toRef.current.value).format('YYYY-MM-DD')
		}]

		submitFn?.(payload);
		setShow(false)
	}

	return (
		<PopUp padding='10px 20px 10px' setShow={setShow}>
			{!edit && <>
				<SelectInput
					options={PERSONAL_DOCUMENTS_OPTIONS}
					color={Colors.darkBlue}
					setSelected={setSelectedDoc}
					name="Document"
					width={100} />
				<Input
					inputRef={fromRef}
					name="From"
					type='date'
					color={Colors.darkBlue}
					width={100} />
			</>}
			<Input
				inputRef={toRef}
				name="To"
				type='date'
				color={Colors.darkBlue}
				width={100}
			/>
			<ButtonWrapper>
				<ActionButton
					onClick={handleSave}
					defaultText="Save"
				/>
			</ButtonWrapper>
		</PopUp>
	)
}

export default AddPersonalDocument