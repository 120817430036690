import styled from "styled-components";

export const Flex = styled.div`
	display: flex;
	gap: 18px;
	max-width: 400px;
	align-items: start;
	flex-flow: wrap;
	height: fit-content;

	button {
		height: fit-content;
	}
`;
