import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { BorderLine, ColorTitle, Form, OptionWrapper, SaveSelectWrapper, SelectsWrapper } from "./FilterOptons.styled";
import { DIRECTION_OPTIONS } from "./constants/options";
import { getSelectCustomStyles } from "./helpers/getSelectCustomStyles";
import { FilterOptionFinder } from "./components/filterOptionFinder/FilterOptionFinder";
import { OPERATION_ACTIONS, OPERATIONS } from "./constants/operations";
import OperationsUpdater from "./components/operationsUpdater/OperationsUpdater";
import ActionButton from "@/common/components/buttons/ActionButton";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createFilter, updateFilter } from "@/API/repositories/filters";
import { getAllPosibleFiledsToFilter } from "@/API/repositories/order";
import PopUp from "@/common/components/PopUp";
import { AVAILABLE_ORDER_FILEDS } from "@/common/constants/orderFilterFields";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";

const FilterOptions = ({
  setShow,
  setSelectedOrderFileds,
  selectedOrderFileds,
  filters,
  dispatchFilters,
  selectedData,
  setSelectedData,
  _id,
  markets,
  products,
  consultants,
  sortBy,
  setSortBy,
  sortType,
  setSortType,
  selectedOperationFields,
  setSelectedOperationFields,
  dispatchOperations,
  operations,
  selectedGroupByFields,
  setSelectedGroupByFields
}) => {
  const [sharedFor, setSharedFor] = useState(
    consultants.filter((c) => _id?.value?.shared_for?.includes(c.value)) || []
  );
  const [saved, setSaved] = useState();
  const [data, setData] = useState();
  const [operationsData, setOperationsData] = useState();
  const nameRef = useRef();
  const publicRef = useRef();
  const [showCreate, setShowCreate] = useState();

  const { makeRequest } = useRequestsContext();

  const handleUpdateGroupBy = (payload) => {
    const operation = OPERATIONS.GROUP_BY;

    const isAdd = payload.length > selectedGroupByFields.length;

    if (isAdd) {
      const newField = payload.find((field) => !selectedGroupByFields.find((f) => f.label === field.label));

      if (newField) {
        setSelectedGroupByFields(payload);
        dispatchOperations({
          type: OPERATION_ACTIONS.ADD, payload: { label: newField.label, type: newField.value.type, operation }
        });
      }
    }

    if (!isAdd) {
      const fields = selectedGroupByFields.filter((field) => !payload.find((f) => f.label === field.label));

      if (fields?.length) {
        fields.forEach((field) => {
          dispatchOperations({ type: OPERATION_ACTIONS.DELETE, payload: field });
        });
      }
      setSelectedGroupByFields(payload);
    }
  }

  const handleSaveOrCreateFilter = async (e) => {
    e && e.preventDefault();
    const payload = {};

    payload.data = filters;
    payload.sort_by = sortBy;
    payload.sort_type = sortType;
    payload.fields = selectedOrderFileds;
    payload.shared_for = sharedFor.map((sf) => sf.value);
    payload.operations = operations;

    let response;

    if (_id) {
      response = await makeRequest(
        updateFilter.bind(null, _id.value._id, payload)
      );
    } else {
      payload.name = nameRef.current?.value;
      payload.public = publicRef.current?.checked;
      response = await makeRequest(createFilter.bind(null, payload));
    }

    if (response.data) {
      setSaved(() => "Saved");
      setTimeout(() => {
        setSaved(() => null);
      }, 3000);
      setShowCreate(() => false);
    }
  };

  const getData = async () => {
    const response = await makeRequest(getAllPosibleFiledsToFilter);

    if (response.data) {
      setData(() => [
        ...Object.entries(response.data.contactFields).map(([key, value]) => {
          return { label: "contact." + key, value: value };
        }),
        ...Object.entries(response.data.orderFields).map(([key, value]) => {
          return { label: "order." + key, value: value };
        }),
        ...Object.entries(response.data.validClaimFields).map(([key, value]) => {
          return { label: "valid_claim." + key, value: value };
        }),
      ]);

      setOperationsData(() => [
        { label: "order.price", value: { type: "Number" } },
        ...Object.entries(response.data.orderFields).map(([key, value]) => {
          return { label: "order." + key, value };
        }),
        ...Object.entries(response.data.contactFields).map(([key, value]) => {
          return { label: "contact." + key, value };
        }),
        ...Object.entries(response.data.validClaimFields).map(([key, value]) => {
          return { label: "valid_claim." + key, value };
        }),
      ])
    }
  };


  useEffect(() => {
    getData();
  }, []);

  const handleSelected = (e) => {
    dispatchFilters({ type: "validate", values: e });
    setSelectedData(e);
  };

  const handleSelectOperationFileds = (payload) => {
    const isAdd = payload.length > selectedOperationFields.length;

    if (isAdd) {
      const newField = payload.find((field) => !selectedOperationFields.find((f) => f.label === field.label));

      if (newField) {
        const operation = OPERATIONS.COUNT;
        dispatchOperations({ type: OPERATION_ACTIONS.ADD, payload: { label: newField.label, type: newField.value.type, operation } });
        setSelectedOperationFields(payload);
      }
    }

    if (!isAdd) {
      const fields = selectedOperationFields.filter((field) => !payload.find((f) => f.label === field.label));

      if (fields?.length) {
        fields.forEach((field) => {
          dispatchOperations({ type: OPERATION_ACTIONS.DELETE, payload: field });
        });
        setSelectedOperationFields(payload);
      }
    }
  }

  return (
    <PopUp setShow={setShow}>
      <div style={{ minHeight: "800px" }}>
        <OptionWrapper>
          <ColorTitle>Fileds:</ColorTitle>
          <BorderLine />
          <Select
            value={selectedOrderFileds}
            onChange={setSelectedOrderFileds}
            options={AVAILABLE_ORDER_FILEDS}
            isMulti={true}
            styles={getSelectCustomStyles(800)}
          />
        </OptionWrapper>
        <OptionWrapper>
          <SelectsWrapper
          >
            <SelectInput
              width={80}
              name="Sort by"
              color={Colors.darkBlue}
              options={AVAILABLE_ORDER_FILEDS}
              setSelected={setSortBy}
              selected={sortBy}
            />
            <SelectInput
              width={80}
              selectWidth={150}
              name="Direction"
              color={Colors.darkBlue}
              options={DIRECTION_OPTIONS}
              selected={sortType || DIRECTION_OPTIONS[0]}
              setSelected={setSortType}
            />
          </SelectsWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <ColorTitle>
            <h3>Filters:</h3>
          </ColorTitle>
          <BorderLine />
        </OptionWrapper>
        <SelectInput
          name="Field"
          color={Colors.darkBlue}
          width={30}
          selected={selectedData}
          setSelected={(e) => handleSelected(e)}
          options={data}
          multiple={true}
          selectWidth={600}
        />
        <FilterOptionFinder
          data={selectedData}
          dispatchFilters={dispatchFilters}
          filters={filters}
          markets={markets}
          products={products}
          consultants={consultants}
        />
        <BorderLine />
        <OptionWrapper>
          <ColorTitle>
            <h3>Operations:</h3>
          </ColorTitle>
          <BorderLine />
        </OptionWrapper>
        <SelectInput
          name="Group by"
          selected={selectedGroupByFields}
          color={Colors.darkBlue}
          multiple
          width={90}
          options={operationsData?.filter((field) => !Object.keys(operations)?.includes(field.label))}
          selectWidth={600}
          setSelected={handleUpdateGroupBy}
        />
        <SelectInput
          name="Field"
          color={Colors.darkBlue}
          width={90}
          selected={selectedOperationFields}
          setSelected={(payload) => handleSelectOperationFileds(payload)}
          options={operationsData?.filter((field) => !Object.keys(operations)?.includes(field.label))}
          multiple={true}
          selectWidth={600}
        />
        <OperationsUpdater
          operations={operations}
          selectedOperationFields={selectedOperationFields}
          dispatchOperations={dispatchOperations}
        />
      </div>
      <SaveSelectWrapper>
        <SelectInput
          name="Shared with"
          options={consultants}
          selected={sharedFor}
          setSelected={setSharedFor}
          multiple={true}
          selectWidth={550}
          width={110}
          color={Colors.darkBlue}
        />
        <ActionButton
          onClick={(e) => {
            if (_id) {
              handleSaveOrCreateFilter(e);
              return;
            }
            setShowCreate(() => true);
          }}
          defaultText={saved ? saved : "Create or Save"}
          color={saved ? Colors.green : Colors.orange}
        />
      </SaveSelectWrapper>
      {showCreate && (
        <PopUp padding="10px 25px" setShow={setShowCreate}>
          <Form
            onSubmit={(e) => handleSaveOrCreateFilter(e)}
          >
            <Input width={80} inputRef={nameRef} required name={"Name"} />
            <Input
              width={80}
              inputRef={publicRef}
              checked
              name={"Public"}
              type="checkbox"
            />

            <SubmitButton
              style={{ marginTop: "20px" }}
              text="Create"
            />
          </Form>
        </PopUp>
      )}
    </PopUp>
  );
};

export default FilterOptions;
