import React, { useState, useRef, useEffect } from "react";
import ConversationGroup from "../conversationGroup/ConversationGroup";
import TicketsList from "../ticketsList/TicketsList";
import Chat from "../chat/Chat";
import Communication from "../communication/Communication";
import { Container, TicketsWrapper, TicketsCloser } from "./TicketView.styled";
import userManager from "@/API/userManager";
import { getLastTicketNotification } from "../../helpers/getLastTicketNotification";
import { markTicketAsSeen } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { ACCEPTABLE_FILE_TYPES } from "@/common/constants/availableFileTypes";
import { useAdminDashboardContext } from "@/common/hooks/adminDashboardHook";
import { updateDocumentTicket } from "@/API/repositories/tickets";
import DocumentToCorrect from "./components/documentToCorrect/DocumentToCorrect";
import DocumentDecline from "./components/documentDecline/DocumentDecline";
import OldTickets from "./components/oldTickets/OldTickets";
import TicketsHeader from "../ticketsHeader/TicketsHeader";
import { detectTicketChanged } from "./helpers/detectTicketChanged";
import { getDepartments } from "@/API/repositories/department";
import Loading from "@/common/components/Loading";
import { findUserById } from "@/common/functions/findUserById";
import { sortTickets } from "../../helpers/sortTickets";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import CreateQuestionAdmin from "@/common/components/ticket/createQuestionAdmin/CreateQuestionAdmin";
import { ListeningObjections } from "@/pages/consultant/dashboard/panel/ticket/listeningObjections/ListeningObjections";

const TicketView = ({
  setIsTicketViewOpen,
  tickets = [],
  oldTickets,
  filteredOldTickets,
  loadData,
  setFilteredTickets,
  setFilteredOldTickets,
  filteredTickets = [],
  rerenderTickets,
}) => {
  const [departments, setDepartments] = useState();
  const [showCreateTicket, setShowCreateTicket] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [showlisteningObjections, setShowlisteningObjections] = useState(null);
  const [recording, setRecording] = useState(null);
  const [isToCorrectOpen, setIsToCorrectOpen] = useState(false);
  const [isToDeclineOpen, setIsToDeclineOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messageLoading, setMessageLoading] = useState(null);
  const [newTicketCreating, setNewTicketCreating] = useState(false);
  const [filterText, setFilterText] = useState(null);

  const documentMessageRef = useRef();
  const quillRef = useRef();
  const fileRef = useRef();
  const currentUserId = userManager.getUser().id;

  const {
    commonData: { users },
  } = useCommonDataContext();
  const { dashboardSettings } = useAdminDashboardContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleFileChanges = () => {
    quillRef.current?.setContents?.(null);

    if (fileRef.current?.files.length) {
      if (
        !ACCEPTABLE_FILE_TYPES.includes(
          fileRef.current?.files[0].name.split(".").at(-1)
        )
      ) {
        fileRef.current.value = "";
        setIsFileSelected(() => false);

        return addMessage("Wrong file type", "error");
      }

      return setIsFileSelected(() => true);
    }

    setIsFileSelected(() => false);
  };

  const makeOptimisticClosedTicket = (status = "closed", additionalMessage) => {
    if (additionalMessage) {
      selectedTicket[selectedTicket.type].message = [
        ...(selectedTicket[selectedTicket.type].message || []),
        {
          sender: currentUserId,
          original: additionalMessage,
          created_at: new Date(),
        },
      ];
    }

    return {
      ...selectedTicket,
      status: status,
      [selectedTicket.type]: {
        ...selectedTicket[selectedTicket.type],
        message: [
          ...(selectedTicket[selectedTicket.type].message || []),
          {
            type: "status_change",
            sender: currentUserId,
            original: `${findUserById(currentUserId, users)?.username
              } changed status from "${selectedTicket.status}" to "${status}"`,
            created_at: new Date(),
          },
        ],
      },
    };
  };

  const updateAllTicketsWithOptimistic = (optimisticTicket) => {
    setFilteredOldTickets((prev) =>
      sortTickets([optimisticTicket, ...(prev || [])])
    );
    setFilteredTickets((prev) =>
      prev.filter((t) => t._id !== selectedTicket?._id)
    );
  };

  const renderOptimisticTicketUi = (
    status = "closed",
    additionalMessage,
    shouldStayInList = false
  ) => {
    const optimisticTicket = makeOptimisticClosedTicket(
      status,
      additionalMessage
    );
    setSelectedTicket(() => optimisticTicket);

    if (!shouldStayInList) {
      updateAllTicketsWithOptimistic(optimisticTicket);
    }
  };

  const handleChangeDocumentTicketStatus = async (
    status,
    shouldStayInList = false
  ) => {
    setIsToCorrectOpen(() => false);
    setIsToDeclineOpen(() => false);

    renderOptimisticTicketUi(
      status,
      documentMessageRef?.current?.value,
      shouldStayInList
    );

    if (selectedTicket.status === status) {
      addMessage("Check status", "error");
      rerenderTickets();
    }

    const payload = {};

    payload.ticket_status = status;
    payload.message = documentMessageRef.current?.value;

    const statusUpdateResponse = await makeRequest(
      updateDocumentTicket.bind(null, selectedTicket._id, payload)
    );

    if (!statusUpdateResponse.data) {
      addMessage("Something went wrong", "error");
      rerenderTickets();
    }
  };

  const handleSelectTicket = async (ticket) => {
    setSelectedTicket(() => ticket);
    quillRef.current?.setContents?.(null);
    fileRef.current.value = "";
    handleFileChanges();

    const lastNotification = getLastTicketNotification(ticket);

    if (!lastNotification.seen?.includes(currentUserId)) {
      await makeRequest(markTicketAsSeen.bind(null, ticket._id));
      await loadData();
    }
  };

  const handleDataLoaded = () => {
    if (selectedTicket && (oldTickets || tickets)) {
      const updatedTicket = [...(oldTickets || []), ...(tickets || [])].find(
        (ticket) => ticket._id === selectedTicket._id
      );

      if (!updatedTicket) return setSelectedTicket(() => filteredTickets[0]);

      if (detectTicketChanged(selectedTicket, updatedTicket))
        return setSelectedTicket(() => updatedTicket);
    }
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return;
    }

    setDepartments(() => response.data);
  };

  useEffect(() => {
    handleDataLoaded();
  }, [oldTickets, tickets]);

  useEffect(() => {
    loadDepartments();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getDepartments) && <Loading />}
      <TicketsWrapper>
        <TicketsCloser onClick={() => setIsTicketViewOpen((prev) => !prev)} />
        <Container>
          <TicketsHeader
            setShowCreateTicket={setShowCreateTicket}
            setFilterText={setFilterText}
            departments={departments}
            setFilteredTickets={setFilteredTickets}
            setFilteredOldTickets={setFilteredOldTickets}
            tickets={tickets}
            oldTickets={oldTickets}
          />
          <ConversationGroup
            renderOptimisticTicketUi={renderOptimisticTicketUi}
            departments={departments}
            handleCloseTicketView={setIsTicketViewOpen}
            selectedTicket={selectedTicket}
            loadData={loadData}
            setShowlisteningObjections={setShowlisteningObjections}
            setRecording={setRecording}
            setIsToDeclineOpen={setIsToDeclineOpen}
            setIsToCorrectOpen={setIsToCorrectOpen}
            rerenderTickets={rerenderTickets}
          />
          <TicketsList
            departments={departments}
            filterText={filterText}
            newTicketCreating={newTicketCreating}
            handleSelectTicket={handleSelectTicket}
            tickets={filteredTickets}
            selectedTicket={selectedTicket}
          />
          <Chat
            selectedTicket={selectedTicket}
            messageLoading={messageLoading}
          />
          {dashboardSettings?.show_old_tickets && (
            <OldTickets
              oldTickets={filteredOldTickets}
              selectedTicket={selectedTicket}
              handleSelectTicket={handleSelectTicket}
              departments={departments}
            />
          )}
          <Communication
            messageLoading={messageLoading}
            setMessageLoading={setMessageLoading}
            loadData={loadData}
            selectedTicket={selectedTicket}
            quillRef={quillRef}
            fileRef={fileRef}
            isFileSelected={isFileSelected}
            handleFileChanges={handleFileChanges}
          />
        </Container>
      </TicketsWrapper>
      {showCreateTicket && (
        <CreateQuestionAdmin
          defaultDepartments={departments}
          setNewTicketCreating={setNewTicketCreating}
          loadData={loadData}
          setShowQuestionTicket={setShowCreateTicket}
        />
      )}
      {showlisteningObjections && recording && (
        <ListeningObjections
          ticket={showlisteningObjections}
          setShow={setShowlisteningObjections}
          recording={recording}
        />
      )}
      {isToCorrectOpen && (
        <DocumentToCorrect
          handleChangeDocumentTicketStatus={handleChangeDocumentTicketStatus}
          setIsToCorrectOpen={setIsToCorrectOpen}
          documentMessageRef={documentMessageRef}
        />
      )}
      {isToDeclineOpen && (
        <DocumentDecline
          handleChangeDocumentTicketStatus={handleChangeDocumentTicketStatus}
          setIsToDeclineOpen={setIsToDeclineOpen}
          documentMessageRef={documentMessageRef}
        />
      )}
    </>
  );
};

export default TicketView;
