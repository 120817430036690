import moment from "moment";
import React, { useRef, useState } from "react";
import { getUtmCampaignWPPK } from "@/API/repositories/reports";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import ActionButton from "@/common/components/buttons/ActionButton";
import UTMReportTable from "./components/utmReportTable/UTMReportTable";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import { getToday } from "@/common/functions/getToday";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const UTMReport = () => {
  const [data, setData] = useState();

  const dateFromRef = useRef();
  const dateToRef = useRef();
  const utmCampaignRef = useRef();

  const today = getToday();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSearch = async () => {
    const payload = Object();

    payload.from =
      dateFromRef.current?.value || moment().startOf("day").toISOString();
    payload.to =
      dateToRef.current?.value || moment().endOf("day").toISOString();
    payload.utm_campaign = utmCampaignRef.current?.value;
    payload.from = moment(payload.from).startOf("day").toISOString();
    payload.to = moment(payload.to).endOf("day").toISOString();

    const response = await makeRequest(getUtmCampaignWPPK.bind(null, payload));

    if (response.data && !Object.keys(response.data).length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getUtmCampaignWPPK) && <Loading />}
      <SearchBarCenter>
        <div>
          <Input
            name="Created date from"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="date"
            value={today}
            inputWidth={140}
          />
          <Input
            name="Created date to"
            width={140}
            color={Colors.darkBlue}
            inputRef={dateToRef}
            type="date"
            value={today}
            inputWidth={140}
          />
        </div>
        <Input
          name="Utm campain"
          width={140}
          color={Colors.darkBlue}
          inputRef={utmCampaignRef}
          inputWidth={140}
        />
        <ActionButton
          onClick={(e) => handleSearch(e)}
          defaultText="Search"
        />
      </SearchBarCenter>
      {data && <UTMReportTable data={data} />}
    </WrapperWithScroll>
  );
};

export default UTMReport;
