import React from "react";
import PopUp from "@/common/components/PopUp";
import { SubTitle, Textarea, SaveButtonWrapper } from "../../TicketView.styled";
import ActionButton from "@/common/components/buttons/ActionButton";

const DocumentDecline = ({
  setIsToDeclineOpen,
  documentMessageRef,
  handleChangeDocumentTicketStatus,
}) => {
  return (
    <PopUp padding="10px 20px" setShow={setIsToDeclineOpen}>
      <SubTitle>Add reason of decline:</SubTitle>
      <Textarea
        placeholder="decline..."
        showLabel={false}
        ref={documentMessageRef}
      />
      <SaveButtonWrapper>
        <ActionButton
          onClick={() => handleChangeDocumentTicketStatus("declined_pending")}
          defaultText="Save"
        />
      </SaveButtonWrapper>
    </PopUp>
  );
};

export default DocumentDecline;
