import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import { sumByField } from "@/common/functions/sumByField";
import { calcWorking } from "@/pages/admin/ccPaymentKDR/helpers/calcWorking";
import moment from "moment";

const getDataFroDay = (addDays, workingHours, firstDayOfTheMonth) => {
  return workingHours.find((hourElement) => {
    if (
      moment(hourElement.for_day).format("DD/MM/YYYY").toLocaleString() ===
      firstDayOfTheMonth
        .clone()
        .add(addDays, "day")
        .format("DD/MM/YYYY")
        .toLocaleString()
    ) {
      return true;
    }
    return false;
  });
};

export const getCSVBody = (data, markets, selectedMonth) => {
  const FREEE_DAYS =
    findMarketByContractType(markets, data._user)?.free_days?.map(
      (d) => d.date
    ) || [];

  const firstDayOfTheMonth = moment(selectedMonth.value.toDate()).startOf(
    "month"
  );

  const result = Array.from(Array(firstDayOfTheMonth.daysInMonth()).keys())
    .map(
      (number, i) =>
        firstDayOfTheMonth.clone().add(number, "day").format("dddd") !==
          "Sunday" &&
        !FREEE_DAYS.includes(
          firstDayOfTheMonth.clone().add(number, "day").format("YYYY-MM-DD")
        ) && [
          firstDayOfTheMonth.clone().add(number, "day").isoWeek(),
          firstDayOfTheMonth
            .clone()
            .add(number, "day")
            .format(" dddd DD/MM/YYYY"),
          getDataFroDay(number, data.data, firstDayOfTheMonth)
            ?.attendance_at_work || 0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)?.holiday_leave ||
            0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)?.special_leave ||
            0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)?.sick_leave || 0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)
            ?.break_during_the_day || 0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)
            ?.excused_unpaid_leave || 0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)
            ?.absence_without_excuse || 0,
          getDataFroDay(number, data.data, firstDayOfTheMonth)?.manager_hours ||
            0,
          (getDataFroDay(number, data.data, firstDayOfTheMonth)
            ?.attendance_at_work || 0) +
            (getDataFroDay(number, data.data, firstDayOfTheMonth)
              ?.holiday_leave || 0) +
            (getDataFroDay(number, data.data, firstDayOfTheMonth)
              ?.special_leave || 0) +
            (getDataFroDay(number, data.data, firstDayOfTheMonth)?.sick_leave ||
              0) +
            (getDataFroDay(number, data.data, firstDayOfTheMonth)
              ?.manager_hours || 0),
        ]
    )
    .filter((element) => element !== false);

  result.push([
    "",
    "Sum",
    sumByField(data.data, "attendance_at_work"),
    sumByField(data.data, "holiday_leave"),
    sumByField(data.data, "special_leave"),
    sumByField(data.data, "sick_leave"),
    sumByField(data.data, "break_during_the_day"),
    sumByField(data.data, "excused_unpaid_leave"),
    sumByField(data.data, "absence_without_excuse"),
    sumByField(data.data, "manager_hours"),
    calcWorking(data.data),
  ]);

  return result;
};
