export const HEADERS = [
	"status",
	"currency",
	"product",
	"variants",
	"value",
	"shipping status",
	"timeline",
	"actions",
];
