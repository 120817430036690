import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import { WORKING_HOURS } from "@/common/constants/CC";
import React, { useMemo, useState } from "react";
import { Title } from "../calendar.styles";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";
import { WorkingHoursWrapper } from "@/pages/admin/ccHours/CCHours.styled";
import styled from 'styled-components';
import Icon from "@/common/components/Icon";
import HourForm from "@/common/components/hourForm/HourForm";
import { getValidBonuses } from "@/common/functions/getValidBonuses";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const HoursContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Hour = styled.span`
  color: ${({ isSet }) => (isSet ? Colors.green : Colors.darkBlue)};
  font-weight: bold;
`;

const AddWorkedHours = ({
  workingHoursOneSave,
  hanldeOnChange,
  handleSaveConsultantHours,
  setAddWorkedHours,
  addWorkedHours,
  bonuses = [],
  handleRemoveConsultantHours
}) => {
  const [showHourFill, setShowHourFill] = useState();

  const { addMessage } = useMessageQueueContext();

  const availableBonuses = useMemo(() => {
    return getValidBonuses(addWorkedHours, bonuses, addMessage);
  }, [bonuses]);

  const isHourSet = (hour) => {
    return workingHoursOneSave[hour] && workingHoursOneSave[hour].sign && workingHoursOneSave[hour].bonusId;
  };

  const handleRemoveHour = (hour) => {
    handleRemoveConsultantHours(hour);
  }

  return (
    <PopUp setShow={setAddWorkedHours}>
      <Title>Hours for day: {addWorkedHours}</Title>
      <WorkingHoursWrapper>
        {WORKING_HOURS.map((hour, i) => (
          <HoursContainer key={i}>
            <Hour isSet={isHourSet(hour)}>{hour}</Hour>
            <Icon onClick={() => setShowHourFill(hour)} color={isHourSet(hour) ? Colors.green : Colors.darkBlue} name={isHourSet(hour) ? 'fa fa-check' : 'fa fa-edit'} />
            {isHourSet(hour) && <Icon color={Colors.red} name="fa fa-trash" onClick={() => handleRemoveHour(hour)} />}
          </HoursContainer>
        ))}
        {showHourFill && (
          <HourForm
            availableBonuses={availableBonuses}
            setShowHourFill={setShowHourFill}
            showHourFill={showHourFill}
            workingHoursOneSave={workingHoursOneSave}
            hanldeOnChange={hanldeOnChange}
          />
        )}
      </WorkingHoursWrapper>
      <ButtonWrapper style={{ marginBottom: "-20px" }}>
        <ActionButton
          defaultText="Save"
          onClick={(e) => handleSaveConsultantHours(e)}
        />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddWorkedHours;
