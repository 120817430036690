import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { getMailTemplates } from "../../../API/repositories/mailTemplate";
import {
  ButtonCreateWrapper,
  ButtonSaveWrapper,
  Title,
  SubTitle,
  Wrapper,
  TestingWrapper,
  WrapperCategory,
  LineWrapper,
} from "../../../common/styles/Mails";
import PopUp from "../../../common/components/PopUp";
import Loading from "../../../common/components/Loading";
import Input from "../../../common/components/Input";
import { Colors } from "../../../common/colors/colors";
import SelectInput from "../../../common/components/SelectInput";
import TextArea from "../../../common/components/TextArea";
import { getMarkets } from "../../../API/repositories/market";
import { getProducts } from "../../../API/repositories/product";
import userManager from "@/API/userManager";
import {
  createMailRecruitmentCategory,
  getCandidatesToMailByQuery,
  getMailRecruitmentCategories,
  testRecruitmentCategoryEmail,
  updateMailRecruitmentCategory
} from "@/API/repositories/mailRecruitmentCategory";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";
import { getPages } from "@/API/repositories/recruitmentPage";
import { getCallCenterPositions } from "@/API/repositories/callCenterPosition";
import { CALL_CENTER_POSITION_OPTIONS } from "../callCenterPosition/constants/callCenterPositions";
import CategoryRecruitmentFilter from "@/pages/admin/recruitmentMailCategory/components/categoryRecruitmentFilter/CategoryRecruitmentFilter";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getAllDynamicMailContent } from "@/API/repositories/dynamicMailContent";
import { getAllDynamicMailAttachments } from "@/API/repositories/dynamicMailAttachment";
import { getPdfsByIds } from "@/API/repositories/storedDocument";
import { getAllFiles } from "@/API/repositories/file";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const RESTRICTED_ROLES = ["SUPPORT"];

const MAIL_PAYLOAD = {
  stage_two_date: new Date().toISOString(),
  first_name: "John",
  last_name: "Doe",
  start_date: new Date().toISOString(),
  document: "document.pdf",
};

function reducer(state, action) {
  if (action.type === "add")
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === "Number"
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.days && { days: parseInt(action.days) }),
        ...(action.hours && { hours: parseInt(action.hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };
  if (action.type === "validate") {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === "remove") {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === "init") {
    return {
      ...action.data,
    };
  }
}

const SIZE_LIMIT_MB = 30;
const BYTEST_IN_MB = 1048576;

const MailTemplateElement = ({
  element,
  setElement,
  reload,
  templates,
}) => {
  const [filters, dispatchFilters] = useReducer(reducer, {});

  const [selected, setSelected] = useState([]);
  const [queryResult, setQueryResult] = useState();

  const { makeRequest } = useRequestsContext();
  const [selectedTemplates, setSelectedTemplates] = useState(
    templates?.find((t) => t.value === element.mail_template_id)
  );
  const [showInfo, setShowInfo] = useState(false);

  const [recruitmentPages, setRecruitmentPages] = useState();
  const [callCenterPositions, setCallCenterPositions] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedDynamicContent, setSelectedDynamicContent] = useState();
  const [dynamicMailContent, setDynamicMailContent] = useState();
  const [allAttachmentsOptions, setAllAttachmentsOptions] = useState();
  const [attachmentsOptions, setAttachmentsOptions] = useState();
  const [selectedAttachments, setSelectedAttachments] = useState();
  const [files, setFiles] = useState();

  const categoryNameRef = useRef();
  const senderNameRef = useRef();
  const senderEmailRef = useRef();
  const cronTimeRef = useRef();
  const activeRef = useRef();
  const shouldSendOnceRef = useRef();
  const shouldSendOnWeekendRef = useRef();
  const emailTestRef = useRef();
  const textAreaRef = useRef();
  const fileRef = useRef();

  const {
    filterUsersByRoles,
    options: { marketsOptions }
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const consultants = useMemo(() => filterUsersByRoles(CONSULTANT_ROLES), []);

  const handleFindOrdersByQuery = async () => {
    filters.should_send_mail_once = element.should_send_mail_once;
    filters.mailCategoryId = element._id;

    const response = await makeRequest(
      getCandidatesToMailByQuery.bind(null, filters)
    );

    if (response.data) {
      setQueryResult(() => response.data);
    }
  };

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (await exceedsSizeLimit()) {
      addMessage(`Attachments size exceeds ${SIZE_LIMIT_MB}MB`, "error");
      return;
    }

    if (cronTimeRef?.current?.value?.split(" ").length != 6) {
      addMessage("CRON TIME SHOULD HAVE 6 spaces check info", "error");
      return;
    }

    if (cronTimeRef?.current?.value) {
      const hasError = cronTimeRef?.current?.value
        ?.split(" ")
        .filter((element) => element.includes("/*"));

      if (hasError.length > 0) {
        addMessage("Not valid Cron, check info");
        return;
      }
    }

    if (selectedTemplates.length == 0) {
      addMessage("Please add mail template and market", "error");
      return;
    }
    const payload = {};

    payload._id = element._id || null;
    payload.name = categoryNameRef.current?.value || element.name;
    payload.mail_template_id = selectedTemplates.value;
    payload.sender_name = senderNameRef.current.value || element.sender_name;
    payload.sender_email = senderEmailRef.current.value || element.sender_email;
    payload.cron_time = cronTimeRef.current.value || element.cron_time;
    payload.active = activeRef.current?.checked || false;
    payload.filters = JSON.stringify(filters);
    payload.should_send_mail_once = shouldSendOnceRef.current?.checked || false;
    payload.should_send_on_weekend =
      shouldSendOnWeekendRef.current?.checked || false;
    payload.attachments = selectedAttachments?.map((attachment) => attachment.value);
    payload.market = selectedMarket.value;
    payload.dynamic_mail_content = selectedDynamicContent?.value;

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailRecruitmentCategory.bind(null, payload));
    } else {
      response = await makeRequest(createMailRecruitmentCategory.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  const handleLoadData = async () => {
    setSelectedMarket(() => marketsOptions.find(o => element.market === o.value));

    const dynamicMailContentResponse = await makeRequest(getAllDynamicMailContent);

    if (dynamicMailContentResponse?.data) {
      const dynamicMailContentOptions = dynamicMailContentResponse.data.map((content) => ({
        label: content.title,
        value: content._id,
      }));
      setDynamicMailContent(() =>
        dynamicMailContentOptions
      );
      setSelectedDynamicContent(() => dynamicMailContentOptions.find(o => element.dynamic_mail_content === o.value));
    }

    const recruitmentPagesResponse = await makeRequest(getPages);

    if (recruitmentPagesResponse?.data) {
      setRecruitmentPages(() =>
        recruitmentPagesResponse.data.map((page) => ({
          label: page.title,
          value: page._id,
          market: page.market
        }))
      );
    }

    const callCenterPositionsResponse = await makeRequest(getCallCenterPositions);

    if (callCenterPositionsResponse?.data) {
      setCallCenterPositions(() =>
        callCenterPositionsResponse.data.map((position) => ({
          label: CALL_CENTER_POSITION_OPTIONS.find(o => o.value === position.title)?.label,
          value: position._id,
          market: position.market
        }))
      );
    }

    const dynamicAttachmentsResponse = await makeRequest(getAllDynamicMailAttachments);

    if (dynamicAttachmentsResponse?.data) {
      const dynamicAttachmentsOptions = dynamicAttachmentsResponse.data.map((attachment) => ({
        label: attachment.title,
        value: attachment._id,
        market: attachment.market,
        file: attachment.file,
      }));
      setAllAttachmentsOptions(() => dynamicAttachmentsOptions);
      handleFilterAttachments(dynamicAttachmentsOptions);
    }

    const filesResponse = await makeRequest(getAllFiles);

    if (filesResponse?.data) {
      setFiles(() => filesResponse.data);
    }

    dispatchFilters({
      type: "init",
      data: JSON.parse(element.filters),
    });

    setSelected(() =>
      Object.entries(JSON.parse(element.filters)).map(([key, value]) => {
        return {
          label: key,
          value: { ...value, type: value.type_field },
        };
      })
    );
  };

  const storedDocumentsSize = useMemo(async () => {
    if (selectedAttachments && files) {
      const storedDocumentsIds = selectedAttachments?.map(a => files?.find(f => f._id === a.file)?.stored_document);

      if (!storedDocumentsIds?.length) {
        return 0;
      }

      const query = {};

      query.ids = storedDocumentsIds;

      const storedDocumentsResponse = await makeRequest(getPdfsByIds.bind(null, query));

      if (storedDocumentsResponse?.data) {
        return storedDocumentsResponse.data.reduce((acc, curr) => acc + new Blob([curr.data.data]).size, 0) / BYTEST_IN_MB;
      }
    }

    return 0;
  }, [selectedAttachments, files])

  const exceedsSizeLimit = async () => {
    const size = await storedDocumentsSize;
    return size >= SIZE_LIMIT_MB;
  }

  const handleTest = async () => {
    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);
    formData.append("category_id", element._id);
    formData.append("email", emailTestRef.current.value);
    formData.set("mail_payload", textAreaRef.current?.value || MAIL_PAYLOAD);

    const response = await makeRequest(testRecruitmentCategoryEmail.bind(null, formData));

    if (response.data) {
      addMessage("Sent", "success");
    } else {
      addMessage("Error", "error");
    }
  };

  const handleFilterAttachments = (attachmentsOptions) => {
    setSelectedAttachments(() => attachmentsOptions.filter(o => selectedMarket?.value === o.market && element.attachments?.includes(o.value)));
    setAttachmentsOptions(() => attachmentsOptions.filter(o => selectedMarket?.value === o.market));
  }

  useEffect(() => {
    if (selectedMarket && allAttachmentsOptions) {
      handleFilterAttachments(allAttachmentsOptions)
    }
  }, [selectedMarket, allAttachmentsOptions]);

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <PopUp setShow={setElement}>
        <WrapperCategory>
          <Title>
            Recruitment Mail Category{" "}
            <i
              className="fa fa-circle-info animation-scale"
              style={{ cursor: "pointer" }}
              onClick={() => setShowInfo(() => true)}
            />
          </Title>
          <form onSubmit={(e) => handleSave(e)}>
            <SubTitle>Settings: </SubTitle>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <Input
                  inputRef={categoryNameRef}
                  width={150}
                  inputWidth={350}
                  name="Name"
                  value={element.name}
                  color={Colors.darkBlue}
                  requiredSign
                  required
                />
                <Input
                  inputRef={senderNameRef}
                  width={150}
                  inputWidth={350}
                  value={element.sender_name}
                  name="Sender name"
                  color={Colors.darkBlue}
                  requiredSign
                  required
                />
                <Input
                  inputRef={senderEmailRef}
                  width={150}
                  inputWidth={350}
                  name="Sender email"
                  value={element.sender_email}
                  color={Colors.darkBlue}
                  type="email"
                  requiredSign
                  required
                />
                <Input
                  inputRef={activeRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  disabled={!element._id}
                  name="Active"
                  checked={element.active}
                  color={Colors.darkBlue}
                />
              </div>
              <div>
                <Input
                  link={"https://crontab.guru/"}
                  value={element.cron_time}
                  inputRef={cronTimeRef}
                  width={150}
                  inputWidth={150}
                  name="Cron time"
                  color={Colors.darkBlue}
                  requiredSign
                  required
                />
                <SelectInput
                  name={"Mail template"}
                  width={150}
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={templates}
                  selected={selectedTemplates}
                  setSelected={setSelectedTemplates}
                  required
                />
                <SelectInput
                  name={"Market"}
                  width={150}
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={marketsOptions}
                  selected={selectedMarket}
                  setSelected={setSelectedMarket}
                  required
                />
                <SelectInput
                  name={"Dynamic content"}
                  width={150}
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={[{ label: "None", value: null }, ...(dynamicMailContent || [])]}
                  selected={selectedDynamicContent}
                  setSelected={setSelectedDynamicContent}
                />
                <SelectInput
                  name="Attachments"
                  width={150}
                  multiple
                  selectWidth={350}
                  color={Colors.darkBlue}
                  options={attachmentsOptions}
                  selected={selectedAttachments}
                  setSelected={setSelectedAttachments}
                />
                <Input
                  inputRef={shouldSendOnceRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  name="Send mail once"
                  checked={
                    element.should_send_mail_once === undefined
                      ? true
                      : element.should_send_mail_once
                  }
                  color={Colors.darkBlue}
                />
                <Input
                  inputRef={shouldSendOnWeekendRef}
                  type="checkbox"
                  width={150}
                  inputWidth={350}
                  name="On weekend"
                  checked={element.should_send_on_weekend}
                  color={Colors.darkBlue}
                />
              </div>
            </div>
            {consultants?.[0] && marketsOptions[0] && (
              <CategoryRecruitmentFilter
                filters={filters}
                market={selectedMarket}
                dispatchFilters={dispatchFilters}
                selectedData={selected}
                setSelectedData={setSelected}
                markets={marketsOptions}
                consultants={consultants}
                recruitmentPages={recruitmentPages}
                callCenterPositions={callCenterPositions}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <ActionButton
                defaultText={"Check query result"}
                onClick={() => handleFindOrdersByQuery()}
                disabled={!element._id}
              />
            </div>
            <SubTitle>Testing: </SubTitle>
            <TestingWrapper>
              <TextArea
                textAreaRef={textAreaRef}
                label="Sending object"
                placeholder="object"
                defaultValue={JSON.stringify(MAIL_PAYLOAD, null, "\t")}
                fontSize={"12px"}
                width="500px"
                minHeight="210px"
              />
              <div>
                <Input
                  showLabel={false}
                  inputRef={emailTestRef}
                  inputWidth={350}
                  name="Mail"
                  type="email"
                  placeholder="Type email..."
                  color={Colors.darkBlue}
                />
                <ActionButton
                  defaultText={"Test"}
                  disabled={!element._id}
                  onClick={() => handleTest()}
                />
              </div>
            </TestingWrapper>
            <ButtonSaveWrapper>
              <SubmitButton text={"Save"} />
            </ButtonSaveWrapper>
          </form>
        </WrapperCategory>
      </PopUp>
      {queryResult && (
        <PopUp setShow={setQueryResult}>
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Full name</th>
                <th>Email</th>
                <th>Market</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {queryResult[0] ? (
                queryResult.map((result, i) => (
                  <tr key={result._id}>
                    <td>{i + 1}.</td>
                    <td>{result.first_name} {result.last_name}</td>
                    <td>{result.email}</td>
                    <td>{result.market?.name}</td>
                    <td>
                      <i
                        className="fa fa-address-book animation-scale"
                        style={{ color: Colors.darkBlue, cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `/cc/recruitment?candidate=${result._id}`,
                            "_blank"
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>There is no data</td>
                </tr>
              )}
            </tbody>
          </table>
        </PopUp>
      )}
      {showInfo && (
        <PopUp setShow={setShowInfo}>
          <Title>Wskazówki do CronTime:</Title>
          <LineWrapper>1. Musi byc 6 spacji nie mniej nie wiecej.</LineWrapper>
          <LineWrapper>
            2. Default to * * * * * * - wtedy beda sie wykonywac co sekunde
          </LineWrapper>
          <LineWrapper>
            3. 1(wsza) giwazdka to sekundy (1-60) , 2(ga) gwiazdka to
            minuty(1-60), 3(cia) to godziny (1-24), 4 to dni w miesiacu , 5 to
            miesiace, 6 to dni w tygodniu (1-7 gdzie 1 to poniedzialek)
          </LineWrapper>
          <LineWrapper>Przyklady:</LineWrapper>
          <LineWrapper>45 * * * * * - co minute w 45 sekundzie</LineWrapper>
          <LineWrapper>0 10 * * * * - co godzine w 10 minucie</LineWrapper>
          <LineWrapper>
            0 */30 9-17 * * * - co 30 minut w godzinach 9 - 17{" "}
          </LineWrapper>
          <LineWrapper>
            0 30 11 * * 1-5 - od poniedzialku do piatku o 11:30
          </LineWrapper>
          W razie czego to tu mozna sie czego dowiedziec{" "}
          <a
            href="https://docs.nestjs.com/techniques/task-scheduling"
            target="_blank"
            rel="noreferrer"
          >
            https://docs.nestjs.com/techniques/task-scheduling
          </a>
          . Klikajac na cron time mozna wejsc w testera.
        </PopUp>
      )}
    </>
  );
};

const RecruitmentMailCategory = () => {
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [element, setElement] = useState();
  const [filterRegex, setFilterRegex] = useState();

  const isRestricted = RESTRICTED_ROLES.includes(userManager.getUser().role);

  const handleLoadData = async () => {
    const mailTemplateResponse = await makeRequest(getMailTemplates);

    if (mailTemplateResponse.data) {
      setMailTemplateOptions(() =>
        mailTemplateResponse.data.map((template) => ({
          label: template.template_sengrid_name,
          value: template._id,
        }))
      );
    }

    const response = await makeRequest(getMailRecruitmentCategories);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  const getIconByBool = (bool) => {
    if (!!bool) {
      return (
        <i
          className="fa fa-check"
          style={{ color: "green", fontSize: "18px" }}
        />
      );
    }

    return (
      <i className="fa fa-remove" style={{ color: "red", fontSize: "18px" }} />
    );
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 3) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  return (
    <>
      {hasUnfilledRequest(
        createMailRecruitmentCategory,
        updateMailRecruitmentCategory,
        getMailRecruitmentCategories,
        testRecruitmentCategoryEmail,
        getProducts,
        getMarkets,
        getCandidatesToMailByQuery,
        getMailTemplates,
        getPdfsByIds,
        getAllFiles,
      ) && <Loading />}
      <Wrapper>
        <ButtonCreateWrapper>
          {!isRestricted && (
            <ActionButton
              defaultText={"Create"}
              onClick={() => setElement({})}
            />
          )}
          <Input
            width={100}
            inputWidth={200}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            reload={handleLoadData}
            templates={mailTemplateOptions}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={6}>Mail Categories</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Cron</th>
              <th>Active</th>
              <th>Send once</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] && mailTemplateOptions[0] ? (
              data
                .filter((e) =>
                  filterRegex ? e.name.includes(filterRegex) : true
                )
                .map((element, i) => (
                  <tr key={element._id}>
                    <td>{i + 1}.</td>
                    <td>{element.name}</td>
                    <td>{element.cron_time}</td>
                    <td>{getIconByBool(element.active)}</td>
                    <td>{getIconByBool(element.should_send_mail_once)}</td>
                    <td>
                      {!isRestricted && (
                        <i
                          className="fa fa-edit animation-scale"
                          style={{ cursor: "pointer" }}
                          onClick={() => setElement(element)}
                        />
                      )}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default RecruitmentMailCategory;
