import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getContactWithOrders,
} from "@/API/repositories/contact";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import ChangeLog from "@/common/components/ChangeLog";
import CallingInfo from "@/common/components/CallingInfo";
import { copyLinkForConsultant } from "./helpers/copyLinkForConsultant";
import ContactForm from "./components/contactForm/ContactForm";
import { ContactWrapper, ContentBody, InfoBlocksWrapper, OrdersWrapper } from "./Contact.styled";
import OrdersTable from "./components/ordersTable/OrdersTable";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const Contact = () => {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [orders, setOrders] = useState(null);
  const [copied, setCopied] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();

  const navigate = useNavigate();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { COUNTRIES } = useCommonDataContext();

  const getContactInfromation = async () => {
    setContact(() => null);

    const response = await makeRequest(getContactWithOrders.bind(null, id));
    if (response.data) {
      setSelectedCountry(() =>
        COUNTRIES.find((country) => country.value === response.data.country)
      );
      setContact(() => response.data);

      response.data.orders.forEach((order) => {
        let variants = "";

        order.order_items.forEach((item) => {
          if (variants.length > 0) {
            variants += ", " + item.product_variant;
          } else {
            variants = item.product_variant;
          }
        });

        order._variants = variants;
      });

      setOrders(() => response.data.orders);
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    getContactInfromation();
  }, [id]);

  return (
    <ContactWrapper>
      {hasUnfilledRequest(getContactWithOrders) && <Loading />}
      {contact && (
        <div style={{ margin: "0 auto" }}>
          <ActionButton
            color={copied ? Colors.green : Colors.blue}
            style={{ minWidth: "200px" }}
            onClick={() => copyLinkForConsultant(id, setCopied)}
            defaultText={copied ? "Copied" : "Link For consultant"}
          />
        </div>
      )}
      <ContentBody>
        {contact && (
					<ContactForm
						contact={contact}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						reloadFN={getContactInfromation}
					/>
        )}
        <OrdersWrapper>
          {orders && (
            <OrdersTable 
							reloadFn={getContactInfromation}
							contact={contact} 
							orders={orders} 
						/>
          )}
          <InfoBlocksWrapper>
            {contact?._changes?.length > 0 && (
              <ChangeLog changes={contact._changes} />
            )}
            {contact?._calling_information?.length > 0 && (
              <CallingInfo callings={contact._calling_information} />
            )}
          </InfoBlocksWrapper>
        </OrdersWrapper>
      </ContentBody>
    </ContactWrapper>
  );
};

export default Contact;
