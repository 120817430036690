export const HEADERS = [
	"nr ",
	"Status",
	" Created at",
	" ConfimredBy",
	"product",
	"variants",
	"value",
	"shipping status",
	"Actions",
];