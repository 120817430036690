export const HEADERS_DONE = [
  "No.",
  "Full name",
  "Email",
  "Company name",
  "Product",
  "Delivery at",
  "Date of payment",
  "Invoice number",
  "Actions",
];

export const HEADERS = [
  "No.",
  "Full name",
  "Email",
  "Company name",
  "Product",
  "Delivery at NO",
  "Delivery at",
  "Invoice to",
  "Invoice status",
  "Actions",
];
