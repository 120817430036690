import React, { useState } from "react";
import {
} from "../../../common/functions/naviagte";
import { PageWrapper } from "@/common/styles/Wrappers";
import SearchBar from './components/searchBar/SearchBar';
import ContactsRoadTable from "./components/contactsRoadTable/ContactsRoadTable";

const ContactsRoad = () => {
  const [data, setData] = useState();

  return (
    <PageWrapper>
      <SearchBar setData={setData} />
      {data && (
        <ContactsRoadTable data={data} />
      )}
    </PageWrapper>
  );
};

export default ContactsRoad;
