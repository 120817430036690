import React, { useEffect, useRef, useState } from "react";
import {
  createGeneralQuestionTicket,
} from "@/API/repositories/tickets";
import userManager from "@/API/userManager";
import { Colors } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  Flex,
  H5,
} from "@/pages/consultant/contact/tickets/TicketsForCalling";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { ACCEPTABLE_FILE_TYPES } from "@/common/constants/availableFileTypes";
import { getSearchedContactWithOrders } from "@/API/repositories/contact";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getFileExtension } from "@/common/functions/getFileExtension";
import { RedSspan } from "@/common/styles/RedSpan";
import {
  ButtonWrapper,
  CheckedDataWrapper,
  ContactsCloser,
  File,
  Form,
  Title,
} from "./CreateQuestionAdmin.styled";
import { getDepartments } from "@/API/repositories/department";
import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";
import RichTextEditor from "@/common/components/richTextEditor/RichTextEditor";
import { handleExtractDelta } from "@/common/functions/handleExtractDelta";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ContactSearch from "../../contactSearch/ContactSearch";

const CreateQuestionAdmin = ({
  setNewTicketCreating = () => { },
  contact,
  setShowQuestionTicket,
  order,
  loadData = () => { },
  defaultDepartments = [],
}) => {
  const currentUser = userManager.getUser();

  const [departments, setDepartments] = useState(defaultDepartments);
  const [selectedTitle, setSelectedTitle] = useState();
  const [selectedRevicer, setSelectedRevicer] = useState();
  const [errors, setErrors] = useState();
  const [contacts, setContacts] = useState([]);
  const [checkedContact, setCheckedContact] = useState(contact?._id);
  const [checkedOrder, setCheckedOrder] = useState(order?._id);
  const [selectedDw, setSelectedDw] = useState();
  const [userDepartment, setUserDepartment] = useState(
    defaultDepartments?.find((d) =>
      [d.head_of_department, ...(d.users || [])].includes(currentUser.id)
    )
  );

  const {
    options: { queuesOptions },
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const fileRef = useRef();
  const textareaRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { filterUsersByRoles } = useCommonDataContext();

  const quillRef = useRef();

  const handleSearch = async () => {
    if (!textareaRef.current?.value)
      return addMessage("Type something before search", "error");

    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, textareaRef.current.value)
    );

    if (!response.data?.length > 0) return addMessage("Not found anyone");

    setContacts(() => response.data);

    textareaRef.current.value = "";
  };

  const handleTicketQuestionCreate = async (e, force = false) => {
    e && e.preventDefault();

    if (
      getFileExtension(fileRef.current?.files[0]?.name)?.length &&
      !ACCEPTABLE_FILE_TYPES.includes(
        getFileExtension(fileRef.current?.files[0]?.name)
      )
    ) {
      fileRef.current.value = "";

      return addMessage("Wrong file type", "error");
    }

    if (!selectedRevicer || !selectedTitle || !quillRef.current.getText()?.trim()) {
      setErrors(true);
      setTimeout(() => {
        setErrors(false);
      }, 2000);

      return;
    }

    if (selectedTitle?.file_required && !fileRef.current?.files[0]) {
      addMessage("Add file", "error");

      return;
    }

    const shouldHaveOrder =
      selectedTitle?.order_required && !checkedOrder && !checkedContact;

    if (shouldHaveOrder) {
      return addMessage(
        "Cannot create ticket without order or contact",
        "error"
      );
    }

    await sendTicketQuestionCreate(e);
  };

  const sendTicketQuestionCreate = async (e) => {
    e && e.preventDefault();
    const consultantsDW = [];

    selectedDw?.forEach((queueOption) => {
      consultantsDW.push(...(queueOption.value.consultants || []));
    });

    const formData = new FormData();

    formData.append("reciver", selectedRevicer.value);
    formData.append("topic", selectedTitle.value);
    formData.append("quill_delta", JSON.stringify(handleExtractDelta(quillRef)));
    formData.append("file", fileRef.current.files[0]);
    consultantsDW.forEach((consultantId) => {
      formData.append("dw[]", consultantId);
    });

    if (checkedContact) {
      formData.append("contact", checkedContact);
    }

    if (checkedOrder) {
      formData.append("order", checkedOrder);
    }

    setNewTicketCreating(() => true);

    const response = await makeRequest(
      createGeneralQuestionTicket.bind(null, formData)
    );

    if (!response.data) {
      addMessage("Couldn't create a ticket", "error");
    }

    if (response.data) {
      addMessage("Success", "success");
      setShowQuestionTicket(() => false);
      await loadData();
    }

    setNewTicketCreating(() => false);
  };

  const resetCheckedData = () => {
    setCheckedContact(() => null);
    setCheckedOrder(() => null);
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDepartments(() => response.data);
    setUserDepartment(() =>
      response.data.find((d) =>
        [d.head_of_department, ...(d.users || [])].includes(currentUser.id)
      )
    );
  };

  const handleSelectReceiver = (receiver) => {
    setSelectedRevicer(() => receiver);
    setSelectedTitle(() => null);
  };

  const getReceiverOptions = () => [
    ...(departments
      ?.filter((d) => d._id !== userDepartment?._id)
      .map((d) => ({
        label: d.name,
        value: d._id,
      })) || []),
    ...(filterUsersByRoles(CONSULTANT_ROLES, true) || []),
  ];

  const getTopicsOptions = () =>
    departments?.find((d) => d._id === selectedRevicer?.value)?.topics ||
    userDepartment?.topics;

  useEffect(() => {
    if (!departments?.length) {
      loadDepartments();
    }
  }, []);

  return (
    <>
      {(hasUnfilledRequest(createGeneralQuestionTicket) ||
        !departments?.length) && <Loading />}
      <PopUp setShow={setShowQuestionTicket}>
        {!!contacts?.length && (
          <ContactsCloser onClick={() => setContacts(null)} />
        )}
        <Title>Question Ticket:</Title>
        {(checkedContact || checkedOrder) && (
          <CheckedDataWrapper onClick={() => resetCheckedData()}>
            Added one {checkedContact ? "contact" : "order"}
            <i className="fa fa-check" />
          </CheckedDataWrapper>
        )}
        <ContactSearch
          contacts={contacts}
          textareaRef={textareaRef}
          handleSearch={handleSearch}
          setCheckedContact={setCheckedContact}
          setCheckedOrder={setCheckedOrder}
          setContacts={setContacts}
          checkedContact={checkedContact}
          checkedOrder={checkedOrder}
        />
        <Form>
          <SelectInput
            width={100}
            color={Colors.darkBlue}
            name="Receiver"
            selected={selectedRevicer}
            setSelected={handleSelectReceiver}
            options={getReceiverOptions()}
            selectWidth={350}
            required
          />
          <SelectInput
            width={100}
            color={Colors.darkBlue}
            name="Topic"
            selected={selectedTitle}
            setSelected={setSelectedTitle}
            disabled={!selectedRevicer}
            options={getTopicsOptions()}
            selectWidth={350}
            required
          />
          <SelectInput
            width={100}
            color={Colors.darkBlue}
            name="Queue DW"
            multiple
            selected={selectedDw}
            setSelected={setSelectedDw}
            options={queuesOptions}
            selectWidth={350}
          />
          <div style={{ marginTop: "40px" }}>
            <RichTextEditor ref={quillRef} />
          </div>
          <Flex>
            <H5>
              File: {selectedTitle?.file_required && <RedSspan>*</RedSspan>}
            </H5>
            <File
              color={Colors.darkBlue}
              ref={fileRef}
              type="file"
              accept="application/pdf,image/jpg,image/png,image/jpeg"
            />
          </Flex>
          <ButtonWrapper>
            <ActionButton
              onClick={(e) => handleTicketQuestionCreate(e)}
              defaultText={errors ? "Please fill all fileds" : "Create Ticket"}
            />
          </ButtonWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default CreateQuestionAdmin;
