import { findUserById } from "@/common/functions/findUserById";

export const getCSVData = (dataToChange, users) => {
  const result = [];

  const calcSalary = (bigItems, smallItems, user) => {
    return (
      bigItems * user.delivered_commision +
      smallItems * user.additional_item_commision
    );
  };

  const calcSalaryForOne = (data, user) => {
    return (
      calcSalary(data.big_items_count, data.small_items_count, user) /
      data.delivered_count
    );
  };

  dataToChange.forEach((currentUser) => {
    const consultant = findUserById(currentUser.consultant, users);
    currentUser.data
      .sort((a, b) => a.price - b.price)
      .forEach((currentData) => {
        const temp = [];

        temp.push(
          `${consultant?.name}_${consultant?.surname}`
        );
        temp.push(`${currentData.price} ${currentUser.salary_currency}`);
        temp.push(
          calcSalaryForOne(currentData, currentUser.month_working_info)
            ? `${calcSalaryForOne(currentData, currentUser.month_working_info)} ${currentUser.salary_currency}`
              : `0 ${currentUser.salary_currency}`
        );
        temp.push(currentData.orders_count);
        temp.push(currentData.delivered_count);
        temp.push(
          `${calcSalary(
            currentData.big_items_count,
            currentData.small_items_count,
            currentUser.month_working_info
          )} ${currentUser.salary_currency}`
        );

        result.push(temp);
      });
  });

  return result;
};