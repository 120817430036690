import styled from "styled-components";
import { Colors } from "@/common/colors/colors";

export const Header = styled.h2`
  font-size: 20px;
  color: ${Colors.darkBlue};
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const SureButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 12px;
`;
