import React from "react";
import PopUp from "../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";

const Wrapper = styled.div`
  color: ${Colors.red};
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 30px;

  h3 {
    text-align: center;
  }
`;

const VoipError = ({ close }) => {
  return (
    <PopUp setShow={close}>
      <Wrapper>
        <h3>We are geting the voip Error. Please try to refresh the page!</h3>
        <ActionButton  defaultText="Okay" color={Colors.green} onClick={() => {}} />
      </Wrapper>
    </PopUp>
  );
};

export default VoipError;
