import React from 'react'
import { SearchBar } from './InvoicesSearchBar.styled'
import SelectInput from '@/common/components/SelectInput'
import { Colors } from '@/common/colors/colors'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { findQueuesByMarketId } from '@/common/functions/findQueuesByMarketId'
import { INVOICE_STATUSES_OPTIONS } from '../../constants/statusOptions'
import ActionButton from '@/common/components/buttons/ActionButton'

const InvoicesSearchBar = ({
  selectedMarket,
  setSelectedMarket,
  selectedProduct,
  setSelectedProduct,
  selectedStatus,
  setSelectedStatus,
  handleSearch,
}) => {
  const {
    options: { productsOptions, marketsOptions },
    commonData: { queues },
  } = useCommonDataContext();

  const handleChangeMarket = (marketOption) => {
    const filteredQueues = findQueuesByMarketId(marketOption.value, queues);

    setSelectedProduct(() =>
      productsOptions.find((productOption) =>
        filteredQueues.some((queue) => queue.product === productOption.value)
      )
    );

    setSelectedMarket(() => marketOption);
  };

  const filterProductsOptions = () => {
    if (!selectedMarket) {
      return [];
    }

    const filteredQueues = findQueuesByMarketId(selectedMarket.value, queues);

    return productsOptions.filter((productOption) =>
      filteredQueues.some((queue) => queue.product === productOption.value)
    );
  };

  return (
  <SearchBar>
      <h3>Invoices:</h3>
      <div>
        <SelectInput
          color={Colors.darkBlue}
          name="Market"
          width={140}
          inputWidth={140}
          setSelected={handleChangeMarket}
          options={marketsOptions}
          selected={selectedMarket}
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Product"
          width={140}
          inputWidth={140}
          selected={selectedProduct}
          setSelected={setSelectedProduct}
          options={filterProductsOptions()}
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Status"
          width={140}
          inputWidth={140}
          selected={selectedStatus}
          setSelected={setSelectedStatus}
          options={INVOICE_STATUSES_OPTIONS}
        />
      </div>
      <div>
        <ActionButton defaultText="Search" onClick={(e) => handleSearch(e)} />
      </div>
    </SearchBar>
  )
}

export default InvoicesSearchBar
