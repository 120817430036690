import { Colors } from "@/common/colors/colors";
import styled from "styled-components";

export const AddButton = styled.div`
	border: 2px solid ${Colors.darkBlue};
	border-radius: 8px;
	padding: 4px;
	cursor: pointer;
	width: 32px;
	height: 32px;
	color: ${Colors.darkBlue};
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
`;

export const AddButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const Container = styled.div`
	width: fit-content;
	min-width: 410px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const FlexCell = styled.div`
	display: flex;
	gap: 8px;
`;
