import { Colors } from '@/common/colors/colors';
import ActionButton from '@/common/components/buttons/ActionButton';
import React, { useState } from 'react'
import { checkOnTheWay, handleCheckAdditionalOrder, handleCheckAddOrder } from './helpers/orderCheckers';
import OrderCreate from '@/pages/admin/contact/components/orderCreate/OrderCreate';
import AdditionalOrderCreate from '@/common/components/additionalOrderCreate/AdditionalOrderCreate';
import Table from '@/common/components/Table';
import { HEADERS } from './constants/headers';
import { getRaws } from './helpers/getRaws';

const OrdersTable = ({ orders, contact, reloadFn }) => {
	const [showAdditionalOrder, setShowAdditionalOrder] = useState();
	const [show, setShowAdd] = useState(false);

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<h3 style={{ color: Colors.darkBlue }}>Orders</h3>
				{handleCheckAddOrder() && (
					<ActionButton
						text="Add"
						onClick={(e) => setShowAdd(() => true)}
					/>
				)}
				{handleCheckAdditionalOrder() && (
					<ActionButton
						defaultText="additional +"
						onClick={(e) => setShowAdditionalOrder(() => true)}
					/>
				)}
			</div>
			<Table
				className="styled-table"
				headers={HEADERS}
				raws={getRaws(orders)}
			/>
			{show && contact && (
				<OrderCreate
					reloadFn={reloadFn}
					setShow={setShowAdd}
					contact={contact}
				/>
			)}
			{showAdditionalOrder && (
				<AdditionalOrderCreate
					setShow={setShowAdditionalOrder}
					orderById={orders?.find((order) => checkOnTheWay(order))?._id}
					reloadFn={reloadFn}
				/>
			)}
		</>
	)
}

export default OrdersTable
