import Card from '@/common/components/Card';
import CSV from '@/common/components/CSV';
import Input from '@/common/components/Input';
import SelectInput from '@/common/components/SelectInput';
import { round } from '@/common/functions/round';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { CALL_TYPE_OPTIONS } from '../constants/callTypeOptions';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { getMarketWppkReportForConsultant, getMarketWppkReportForMarket } from '@/API/repositories/reports';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { ButtonsWrapper, Container, FieldsWrapper, Flex, StyledTextField } from './SearchBar.styled';
import { NO_WEIGHT_CONSULTANT, NO_WEIGHT_MARKET, WEIGHT_CONSULTANT, WEIGHT_MARKET } from './constants/headers';
import { Colors } from '@/common/colors/colors';
import ActionButton from '@/common/components/buttons/ActionButton';

const SearchBar = ({ 
	weight, 
	data, 
	setData, 
	forConfirmedDate, 
	setForConfirmedDate, 
	setWeights 
}) => {
	const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedCallTypes, setSelectedCallTypes] = useState(CALL_TYPE_OPTIONS);

	const { addMessage } = useMessageQueueContext();
	const { 
		commonData: { queues }, 
		options: { 
			usersOptions, 
			productsOptions,
			marketsOptions, 
			queuesOptions 
		}
	} = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const downloadStartDateRef = useRef();
  const downloadEndDateRef = useRef();
  const createdStartDateRef = useRef();
  const createdEndDateRef = useRef();

  const downloadStartDate = moment().format("YYYY-MM-DD");
  const createdStartDate = moment()
    .subtract("6", "months")
    .format("YYYY-MM-DD");

	const getCsvRowsConsultant = (data) => {
    const result = [];
    Object.entries(data)
      .sort((a, b) => {
        if (weight)
          return (
            parseInt(a[1]._id.split("-")[1]) - parseInt(b[1]._id.split("-")[1])
          );
        return 1;
      })
      .sort((a, b) => {
        if (weight)
          return a[1]._id.split("-")[0].localeCompare(b[1]._id.split("-")[0]);
        return a[1]._id.localeCompare(b[1]._id);
      })
      .map(([key, value]) => {
        const temp = [];

        key = value._id;

        temp.push(
          usersOptions.find(
            (a) => a.value === (!weight ? key.split("-")[0] : key)
          ).label
        );
        temp.push(marketsOptions.find((a) => a.value === value.market).label);
        temp.push(productsOptions.find((a) => a.value === value.product).label);
        !weight && temp.push(key.split("-")[1]);
        temp.push(round(value.cod_count));
        temp.push(Math.round(value.cod_sum));
        temp.push(round(value.cod_delivered_count));
        temp.push(round(value.cod_delivered_sum));
        temp.push(
          round(
            value.weight_all_sum > 0 ? value.cod_sum / value.weight_all_sum : 0
          )
        );
        temp.push(
          round(
            value.weight_all_sum > 0
              ? value.cod_delivered_sum / value.weight_all_sum
              : 0
          )
        );
        temp.push(round(value.dequeue_count));
        temp.push(round(value.shift));
        temp.push(round(value.confirmed));
        temp.push(round(value.no_response));
        temp.push(round(value.trash));
        temp.push(round(value.informed));
        temp.push(round(value.resigned));
        temp.push(round(value.weight_all_sum));

        result.push(temp);
      });

    return result;
  };

  const getCsvRowsMarket = (data) => {
    const result = [];

    Object.entries(data).map(([key, value]) => {
      const temp = [];

      key = value._id;

      if (!marketsOptions.find((a) => a.value === value.market)) return;

      temp.push(marketsOptions.find((a) => a.value === value.market)?.label);
      temp.push(productsOptions.find((a) => a.value === value.product)?.label);
      !weight && temp.push(key.split("-")[1]);
      temp.push(round(value.cod_count));
      temp.push(Math.round(value.cod_sum));
      temp.push(round(value.cod_delivered_count));
      temp.push(round(value.cod_delivered_sum));
      temp.push(round(value.cod_sum / value.weight_all_sum));
      temp.push(round(value.cod_delivered_sum / value.weight_all_sum));
      temp.push(value.inbounds);
      temp.push(value.outbounds);
      temp.push(value.contacts);
      temp.push(round(value.weight_all_sum));

      result.push(temp);
    });

    return result;
  };

	const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

	const handleGetReport = async () => {
    setData(() => null);
    const payload = Object();
    payload.from = downloadStartDateRef.current
      ? moment(downloadStartDateRef.current.value).startOf("day")
      : moment(downloadStartDate).startOf("day");
    payload.to = downloadEndDateRef.current
      ? moment(downloadEndDateRef.current.value).endOf("day")
      : moment(downloadStartDate).endOf("day");
    payload.fromContact = createdStartDateRef.current
      ? moment(createdStartDateRef.current.value).startOf("day")
      : moment(createdStartDate).startOf("day");
    payload.toContact = createdEndDateRef.current
      ? moment(createdEndDateRef.current.value).endOf("day")
      : moment(downloadStartDate).endOf("day");
    payload.weights = !weight;
    payload.type = selectedCallTypes.map((callType) => callType.value);

    if (productsOptions && forConfirmedDate) {
      payload.ids = selectedMarkets.map((data) => data.value);
      if (payload.type.length === 0) return;
      if (payload.ids.length === 0) {
        addMessage("Select markets", "error");

        return;
      }

      const response = await makeRequest(
        getMarketWppkReportForMarket.bind(null, payload)
      );

      if (response.data && !Object.keys(response.data).length) {
        addMessage("Not found");
        setData(() => null);

        return;
      }

      if (response.data) {
        setData(() =>
          Object.entries(response.data).map(([key, value]) => {
            value._id = key;
            return value;
          })
        );
      }
    } else {
      payload.ids = selectedConsultants.map((data) => data.value);
      if (payload.ids.length === 0) {
        addMessage("Select consultants", "error");

        return;
      }

      const response = await makeRequest(
        getMarketWppkReportForConsultant.bind(null, payload)
      );

      if (response.data && !Object.keys(response.data).length) {
        addMessage("Not found");
        setData(() => null);

        return;
      }

      if (response.data) {
        setData(() =>
          Object.entries(response.data).map(([key, value]) => {
            value._id = key;
            return value;
          })
        );
      }
    }
  };

	return (
		<Card>
			{hasUnfilledRequest(getMarketWppkReportForConsultant, getMarketWppkReportForMarket) && <Loading />}
			<Container>
				<Flex>
					<div>
						<Input
							inputRef={downloadStartDateRef}
							name="Download date from"
							type="date"
							width={200}
							value={downloadStartDate}
							inputWidth={140}
						/>
						<Input
							inputRef={downloadEndDateRef}
							name="Download date to"
							type="date"
							width={200}
							value={downloadStartDate}
							inputWidth={140}
						/>
						<Input
							inputRef={createdStartDateRef}
							name="Created date from"
							type="date"
							width={200}
							value={createdStartDate}
							inputWidth={140}
						/>
						<Input
							inputRef={createdEndDateRef}
							name="Created date to"
							type="date"
							width={200}
							value={downloadStartDate}
							inputWidth={140}
						/>
					</div>
					<div>
						<SelectInput
							multiple={true}
							name="Products"
							options={productsOptions}
							width={200}
							setSelected={setSelectedMarkets}
							selected={selectedMarkets}
						/>
						<SelectInput
							multiple={true}
							name="Consultants"
							options={usersOptions}
							width={200}
							setSelected={setSelectedConsultants}
							selected={selectedConsultants}
						/>
						<SelectInput
							multiple={true}
							name="Queue"
							options={queuesOptions}
							width={200}
							setSelected={setSelectedQueue}
							selected={selectedQueues}
						/>
						<SelectInput
							multiple={true}
							name="Call types"
							options={CALL_TYPE_OPTIONS}
							width={200}
							setSelected={setSelectedCallTypes}
							selected={selectedCallTypes}
						/>
					</div>
				</Flex>
				<div>Options:</div>
				<FieldsWrapper>
					<StyledTextField
						bgColor={weight ? Colors.green : "none"}
						className="animation-scale"
						onClick={() => {
							setData(() => null);
							setWeights(true);
						}}
					>
						Bez podzialu na wagi
					</StyledTextField>
					<StyledTextField
						bgColor={weight ? "none" : Colors.green}
						className="animation-scale"
						onClick={() => {
							setData(() => null);
							setWeights(false);
						}}
					>
						Z podzialem na wagi
					</StyledTextField>
					<StyledTextField
						bgColor={forConfirmedDate ? Colors.green : "none"}
						className="animation-scale"
						onClick={() => {
							setData(() => null);
							setForConfirmedDate(true);
						}}
					>
						Po Dacie utworzenia (market)
					</StyledTextField>
					<StyledTextField
						bgColor={forConfirmedDate ? "none" : Colors.green}
						className="animation-scale"
						onClick={() => {
							setData(() => null);
							setForConfirmedDate(false);
						}}
					>
						Po Dacie pobrania (konsultanci)
					</StyledTextField>
				</FieldsWrapper>
			</Container>
			<ButtonsWrapper>
				{data && !forConfirmedDate && (
					<CSV
						filename={"effective-consultant.csv"}
						header={
							!weight
								? NO_WEIGHT_CONSULTANT
								: WEIGHT_CONSULTANT
						}
						data={getCsvRowsConsultant(data)}
					/>
				)}
				{data && forConfirmedDate && (
					<CSV
						filename={"effective-market.csv"}
						header={
							!weight
								? NO_WEIGHT_MARKET
								: WEIGHT_MARKET
						}
						data={getCsvRowsMarket(data)}
					/>
				)}
				<ActionButton
					onClick={(e) => handleGetReport(e)}
					defaultText="Search"					
				/>
			</ButtonsWrapper>
		</Card>
	)
}

export default SearchBar
