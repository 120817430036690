export const HEADERS = {
  PL: [
    "No.",
    "Imię i Nazwisko",
    "Etat",
    "Okres rozliczeniowy od",
    "Okres rozliczeniowy do",
    "Full-time /miesiąc",
    "Ilość godzin przepracowanych",
    "Urlop",
    "Urlop okolicznościowy",
    "L4 - zwolnie lekarskie",
    "Urlop bezpłatny",
    "Nieusprawiedliwone",
  ],
  EN: [
    "No.",
    "Full name",
    "Etat",
    "Billing period from",
    "Billing period to",
    "Full-time/month",
    "Number of hours worked",
    "Holiday",
    "Occasional holidays",
    "Sick leave",
    "Excused unpaid leave",
    "Unexcused absence",
  ],
};

export const getHeadersByMarket = (marketShort) => {
  switch (marketShort) {
    case "sk":
    case "cz":
    case "lt":
      return HEADERS.PL;
    default:
      return HEADERS.EN;
  }
}

export const HEADERS_UOP_CZ = (month) => [
  { startRow: 1, endRow: 1, startCol: 1, endCol: 9, name: month },
  { startRow: 2, endRow: 3, startCol: 1, endCol: 1, name: "Dátum", width: 20 },
  {
    startRow: 2,
    endRow: 3,
    startCol: 2,
    endCol: 2,
    name: "Priezvisko, meno, titul",
    width: 25,
  },
  {
    startRow: 2,
    endRow: 2,
    startCol: 3,
    endCol: 3,
    name: "PRÍCHOD",
    width: 15,
  },
  { startRow: 2, endRow: 2, startCol: 4, endCol: 4, name: "ODCHOD", width: 15 },
  {
    startRow: 2,
    endRow: 2,
    startCol: 5,
    endCol: 7,
    name: "Prerušenie pracovného času/ Přestávka",
  },
  {
    startRow: 2,
    endRow: 3,
    startCol: 8,
    endCol: 8,
    name: "neodpracovaný čas v hod",
    width: 25,
  },
  {
    startRow: 2,
    endRow: 3,
    startCol: 9,
    endCol: 9,
    name: "odpracovaný čas - hod",
    width: 22,
  },
  {
    startRow: 3,
    endRow: 3,
    startCol: 3,
    endCol: 3,
    name: "hod./min.",
    width: 15,
  },
  {
    startRow: 3,
    endRow: 3,
    startCol: 4,
    endCol: 4,
    name: "hod./min.",
    width: 15,
  },
  {
    startRow: 3,
    endRow: 3,
    startCol: 5,
    endCol: 5,
    name: "odchod/h/m ",
    width: 15,
  },
  {
    startRow: 3,
    endRow: 3,
    startCol: 6,
    endCol: 6,
    name: "príchod/h/m",
    width: 15,
  },
  { startRow: 3, endRow: 3, startCol: 7, endCol: 7, name: "dôvod", width: 20 },
];
