import React, { useRef, useState } from "react";
import PopUp from "../../../common/components/PopUp";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import {
  ButtonWrapper,
  Form,
  SelectUserWrapepr,
  Title,
} from "./styles/Test.styles";
import Input from "../../../common/components/Input";
import Table from "../../../common/components/Table";
import { Colors } from "../../../common/colors/colors";
import SelectInput from "../../../common/components/SelectInput";
import TestQuestion from "./TestQuestion";
import { handleReturnOptions } from "./common/shared";
import TextArea from "../../../common/components/TextArea";
import { createTest, updateTest } from "../../../API/repositories/test";
import Loading from "../../../common/components/Loading";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const TestEdit = ({
  setShow,
  current,
  usersOptions,
  queuesOptions,
  reload,
}) => {
  const headers = ["no", "question", "answers", "action"];
  const [selectedUsers, setSelectedUsers] = useState(
    usersOptions.filter((uo) => current.consultants?.includes(uo.value._id)) ||
    []
  );
  const [selectedQueues, setSelectedQueues] = useState();

  const [question, setQuestion] = useState();
  const [questions, setQuestions] = useState(current.questions || []);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const nameRef = useRef();
  const activeRef = useRef();

  const handleSave = async (e) => {
    e.preventDefault();
    const payload = {};
    payload.name = nameRef.current?.value || current.name;
    payload.active = activeRef.current?.checked || false;
    payload.questions = questions;
    payload.consultants = selectedUsers.map((su) => su.value._id);

    let response;

    if (!current._id) {
      response = await makeRequest(createTest.bind(null, payload));
    } else {
      response = await makeRequest(updateTest.bind(null, current._id, payload));
    }

    if (response.data) {
      addMessage("saved!", "success");
      reload();
      setShow(() => null);
    } else {
      addMessage("error", "error");
    }
  };

  const handleSelectedQueues = (queue) => {
    let selectedUsersIds = [];
    queue.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedUsers(() =>
      usersOptions.filter((c) => selectedUsersIds.includes(c.value._id))
    );
    setSelectedQueues(() => queue);
  };

  const handleRemoveQuestion = (question) => {
    setQuestions((prev) =>
      prev
        .filter((e) => e.id !== question.id)
        .map((e, i) => ({ ...e, id: i + 1 }))
    );
  };

  return (
    <>
      {hasUnfilledRequest(createTest, updateTest) && <Loading />}
      <PopUp setShow={setShow}>
        <div></div>
        <Title>Test:</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <SelectUserWrapepr>
            <TextArea
              textAreaRef={nameRef}
              width={400}
              label="Name"
              color={Colors.darkBlue}
              defaultValue={current.name}
              required
              placeholder="type test name..."
            />
            <Input
              inputRef={activeRef}
              width={80}
              inputWidth={20}
              checked={current.active}
              name="Active"
              type="checkbox"
              color={Colors.darkBlue}
            />
          </SelectUserWrapepr>
          <SelectUserWrapepr>
            <SelectInput
              showLabel={false}
              selected={selectedUsers}
              setSelected={setSelectedUsers}
              options={usersOptions}
              width={80}
              selectWidth={700}
              color={Colors.darkBlue}
              multiple={true}
              placeholder="Select user..."
            />
            <SelectInput
              showLabel={false}
              selected={selectedQueues}
              setSelected={handleSelectedQueues}
              options={queuesOptions}
              width={80}
              selectWidth={180}
              color={Colors.darkBlue}
              multiple={true}
              placeholder="Select market..."
            />
          </SelectUserWrapepr>
          <Table
            headers={headers}
            raws={questions.map((q, i) => (
              <tr>
                <td>{i + 1}.</td>
                <td>{q.value}</td>
                <td>{q.answers?.length || 0}</td>
                <td>
                  {handleReturnOptions(q, handleRemoveQuestion, setQuestion)}
                </td>
              </tr>
            ))}
            className={"styled-table"}
          />
          <ButtonWrapper
            style={{ margin: "20px 0 -20px 0", justifyContent: "right" }}
          >
            <ActionButton
              onClick={() => setQuestion(() => ({}))}
              defaultText="Add Question"
            />
            <SubmitButton
              style={{ marginBottom: "-30px" }}
              disabled={questions.length === 0}
              text="Save"
            />
          </ButtonWrapper>
        </Form>
      </PopUp>
      {question && (
        <TestQuestion
          setShow={setQuestion}
          question={question}
          setQuestions={setQuestions}
        />
      )}
    </>
  );
};

export default TestEdit;
