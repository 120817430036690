import React, { useDeferredValue, useEffect, useState } from "react";
import EditItem from "../../../../../common/components/EditItem";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { Wrapper } from "./FilterTable.styled";

const FilterTable = ({ rows, columns, howManyToShow = 50 }) => {
  const [page, setPage] = useState(0);
  const [currentRows, setCurrentRows] = useState([]);
  const rowsToDisplay = useDeferredValue(currentRows);
  const [sortInfo, setSortInfo] = useState({});

  const changePage = (e, number) => {
    e.preventDefault();
    setPage((prev) => prev + number);
  };

  const handleSort = (e, index) => {
    e.preventDefault();
    const decision = {};

    if (sortInfo.isAscending === true && index === sortInfo.index) {
      rows = rows.sort((a, b) => (!(a[index] < b[index]) ? 1 : -1));
      decision.isAscending = false;
    } else {
      rows = rows.sort((a, b) => (a[index] < b[index] ? 1 : -1));
      decision.isAscending = true;
    }

    decision.index = index;
    setSortInfo(() => decision);
    setCurrentRows(
      rows.slice(page * howManyToShow, (page + 1) * howManyToShow)
    );
  };

  useEffect(() => { }, [rows]);

  useEffect(() => {
    setCurrentRows(
      rows.slice(page * howManyToShow, (page + 1) * howManyToShow)
    );
  }, [page]);

  return (
    <Wrapper>
      {rows ? (
        <div>
          <table className="styled-table">
            <thead>
              <tr>
                {columns
                  .map((element) => element.label)
                  .map((column, i) => {
                    return (
                      <th
                        onClick={(e) => handleSort(e, i)}
                        style={{ cursor: "pointer" }}
                      >
                        {column}
                      </th>
                    );
                  })}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="queue">
              {rowsToDisplay.map((row, i) => {
                return (
                  <tr key={i}>
                    {row.data.map((row) => (
                      <td>{row ? row : "brak"}</td>
                    ))}
                    <td style={{ textAlign: "center" }}>
                      <EditItem
                        link={`/dashboard/contact/${row._id}`}
                        width={60}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <h5>page: {page + 1}</h5>
              <h5>elements: {rows.length}</h5>
            </div>
            <div>
              <ActionButton
                color={Colors.darkBlue}
                style={{ margin: "10px", display: "inline" }}
                onClick={(e) => changePage(e, -1)}
                disabled={page <= 0}
                defaultText="Previous Page"
              />
              <ActionButton
                color={Colors.darkBlue}
                style={{ display: "inline" }}
                onClick={(e) => changePage(e, 1)}
                disabled={page > rows.length / howManyToShow - 2}
                defaultText="Next Page"
              />
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </Wrapper>
  );
};

export default FilterTable;
