import moment from "moment";
import React, {
  useContext,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { markOrdertAsConsultantDecision } from "../../../../API/repositories/order";
import userManager from "../../../../API/userManager";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import ConfirmationView from "../order/ConfirmationView";

import Confirm from "./Confirm";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  height: fit-content;
  display: grid;
`;

const StatusButton = styled.span`
  width: fit-content;
  font-size: 12px;
  padding: 5px;
  border-radius: 10px;
  height: fit-content;
`;

const SHIFT_STATUSES = [
  { label: "No Product Info", staus: "NO_PRODUCT_INFO" },
  { label: "Wrong time", staus: "BAD_TIME" },
  { label: "other", staus: "OTHER" },
];

const NO_RESPONSE_STATUSES = [
  { label: "rejected_call", staus: "REJECTED_CALL" },
  { label: "voicmail", staus: "VOICMAIL" },
  { label: "no answer", staus: "NO_ANSWER" },
  { label: "VOIP error", staus: "VOIP_ERROR" },
  { label: "other", staus: "OTHER" },
];

const Buttons = ({
  product,
  contact,
  chosenProductData,
  data,
}) => {
  const minDate = moment().format("YYYY-MM-DDTHH:mm");
  const maxDate = moment().add(14, "days").format("YYYY-MM-DDTHH:mm");
  const defaultShiftDate = moment().add(1, "days").format("YYYY-MM-DDTHH:mm");

  const dateRef = useRef();

  const [shiftReason, setshiftReason] = useState("BAD_TIME");
  const [noResponseReason, setNoResponseReason] = useState("REJECTED_CALL");
  const [isConfirm, setIsConfirm] = useState(false);

  const [confirmDecision, setConfirmDecision] = useState(false);
  const [text, setText] = useState();
  const [decision, setDecision] = useState();

  const [isConfirmNotAllowed, setIsConfirmNotAllowed] = useState(false);
  const [isShift, setIsShift] = useState(false);
  const [isNoResponse, setIsNoResponse] = useState(false);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [shiftDate, setShiftDate] = useState();

  const { translation } = useTranslationContext();

  const {
    setShowCallingDescription,
    setCallingClientCredentials,
    setCurrentContactOutbound,
  } = useContext(CallCRMApiContextVonage);

  const handleConfirmOrder = (e) => {
    e.preventDefault();

    if (
      chosenProductData.length === 0 ||
      contact.postal_code === undefined ||
      contact.city === undefined ||
      contact.street === undefined
    ) {
      setIsConfirmNotAllowed((prev) => !prev);
    } else {
      const price = chosenProductData.reduce(
        (prev, next) => prev + next.price,
        0
      );

      if (
        contact.country === "cz" &&
        !contact.hasPromotion &&
        price < 1900 * 5
      ) {
        setIsConfirmNotAllowed((prev) => !prev);
      } else {
        setIsConfirm((prev) => !prev);
      }
    }
  };

  const handleClick = async (e, type) => {
    e.preventDefault();

    if (["SHIFT", "NO_RESPONSE"].includes(type)) {
      setIsShift(() => false);
      setIsNoResponse(() => false);
    }

    setConfirmDecision(true);
    setText(type);
    setDecision(type);
  };

  const handleDecision = async (type, additionalInformation) => {
    const responseDecision = await makeRequest(
      markOrdertAsConsultantDecision.bind(
        this,
        userManager.getUser().id,
        data._id,
        {
          type: type,
          additionalInformation: additionalInformation,
        }
      )
    );

    if (responseDecision.data && type !== "CONFIRM") {
      setCallingClientCredentials(() => null);
      setCurrentContactOutbound(() => null);
      setShowCallingDescription(() => true);
    }
  };

  const onDeceline = () => {
    setConfirmDecision(false);
  };

  const onConfirm = async () => {
    switch (decision) {
      case "SHIFT":
        handleDecision("SHIFT", {
          shiftReason: shiftReason,
          shiftDate: shiftDate
            ? new Date(shiftDate).toISOString()
            : new Date(defaultShiftDate).toISOString(),
        });
        break;
      case "RESIGN":
        handleDecision("RESIGN", {});
        break;
      case "TRASH":
        handleDecision("TRASH", {});
        break;
      case "INFORMED":
        handleDecision("INFORMED", {});
        break;
      case "NO_RESPONSE":
        handleDecision("NO_RESPONSE", { noResponseReason: noResponseReason });
        break;
      default:
    }
  };

  return (
    <>
      <Wrapper>
        {hasUnfilledRequest(markOrdertAsConsultantDecision) && <Loading />}
        <ButtonWrapper>
          <Flex>
            <ActionButton
              style={{ fontSize: "18px", padding: "8px" }}
              onClick={(e) => setIsShift((prev) => !prev)}
              defaultText={translation.shift}
            />
            <ActionButton
              style={{ fontSize: "18px", padding: "8px" }}
              onClick={(e) => handleClick(e, "INFORMED")}
              defaultText={translation.informed}
            />
            <ActionButton
              style={{ fontSize: "18px", padding: "8px" }}
              onClick={(e) => handleClick(e, "RESIGN")}
              color={Colors.red}
              defaultText={translation.resign}
            />
            <ActionButton
              style={{ fontSize: "18px", padding: "8px" }}
              onClick={(e) => {
                setNoResponseReason(() => "VOICMAIL");
                handleClick(e, "NO_RESPONSE");
              }}
              color={Colors.red}
              defaultText={translation.noResponse}
            />
            <ActionButton
              style={{ fontSize: "18px", padding: "8px" }}
              onClick={(e) => handleConfirmOrder(e)}
              disabled={!contact.postal_code}
              color={Colors.green}
              defaultText={translation.confirm}
            />
          </Flex>
          {confirmDecision ? (
            <Confirm
              text={text}
              onDeceline={onDeceline}
              onConfirm={onConfirm}
            />
          ) : (
            ""
          )}
        </ButtonWrapper>
      </Wrapper>
      {isConfirm && (
        <PopUp setShow={setIsConfirm}>
          <ConfirmationView
            setCallingClientCredentials={setCallingClientCredentials}
            setCurrentContactOutbound={setCurrentContactOutbound}
            setShowCallingDescription={setShowCallingDescription}
            product={product}
            shippingData={contact}
            chosenProductData={chosenProductData}
            orderId={data._id}
          />
        </PopUp>
      )}
      {isConfirmNotAllowed && (
        <PopUp setShow={setIsConfirmNotAllowed}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <h4>Please fill all required fields</h4>
            <ActionButton
              color={Colors.green}
              defaultText="OK"
              onClick={() => setIsConfirmNotAllowed((prev) => !prev)}
            />
          </div>
        </PopUp>
      )}
      {isShift && (
        <PopUp setShow={setIsShift}>
          <h3>Decision: SHIFT</h3>
          <Flex
            style={{ margin: "20px 20px 0px 20px", flexDirection: "column" }}
          >
            <Flex style={{ margin: "20px 20px 20px 20px", gap: "40px" }}>
              {SHIFT_STATUSES.map((status) => (
                <StatusButton
                  style={{ cursor: "pointer" }}
                  className={
                    shiftReason === status.staus
                      ? "check animation-scale"
                      : "notCheck animation-scale"
                  }
                  key={status.staus}
                  onClick={() => setshiftReason(() => status.staus)}
                >
                  {status.label}
                </StatusButton>
              ))}
            </Flex>
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                ref={dateRef}
                type="datetime-local"
                min={minDate}
                max={maxDate}
                defaultValue={defaultShiftDate}
              />
              <ActionButton
                onClick={(e) => {
                  setShiftDate(() => dateRef.current.value);
                  handleClick(e, "SHIFT");
                }}
                defaultText="Make Shift"
              />
            </div>
          </Flex>
        </PopUp>
      )}
      {isNoResponse && (
        <PopUp setShow={setIsNoResponse}>
          <h3>Decision: NO RESPONSE</h3>
          <Flex
            style={{ margin: "20px 20px 0px 20px", flexDirection: "column" }}
          >
            <Flex style={{ margin: "20px 20px 20px 20px", gap: "40px" }}>
              {NO_RESPONSE_STATUSES.map((status) => (
                <StatusButton
                  className={
                    noResponseReason === status.staus
                      ? "check animation-scale"
                      : "notCheck animation-scale"
                  }
                  key={status.staus}
                  onClick={() => setNoResponseReason(() => status.staus)}
                  style={{ cursor: "pointer" }}
                >
                  {status.label}
                </StatusButton>
              ))}
            </Flex>
            <ActionButton
              defaultText="Save"
              onClick={(e) => handleClick(e, "NO_RESPONSE")}
            />
          </Flex>
        </PopUp>
      )}
    </>
  );
};

export default Buttons;
