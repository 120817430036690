export const NO_WEIGHT_CONSULTANT = [
	"Consultant",
	"Market",
	"Product",
	"Download no",
	"COD number",
	"COD sum",
	"COD delivered number",
	"COD delivered sum",
	"WPPK",
	"WPPK delivered",
	"Number of downloaded orders",
	"shifts",
	"confirmed",
	"no_response",
	"informed",
	"trash",
	"resigned",
	"Weight sum",
]

export const WEIGHT_CONSULTANT = [
	"Consultant",
	"Market",
	"Product",
	"COD number",
	"COD sum",
	"COD delivered number",
	"COD delivered sum",
	"WPPK",
	"WPPK delivered",
	"Number of downloaded orders",
	"shifts",
	"confirmed",
	"no_response",
	"informed",
	"trash",
	"resigned",
	"Weight sum",
]

export const NO_WEIGHT_MARKET = [
	"Market",
	"Product",
	"Download no",
	"COD number",
	"COD sum",
	"COD delivered number",
	"COD delivered sum",
	"WPPK",
	"WPPK delivered",
	"Inbounds",
	"Outbounds",
	"Contacts",
	"Weight sum",
]

export const WEIGHT_MARKET = [
	"Market",
	"Product",
	"COD number",
	"COD sum",
	"COD delivered number",
	"COD delivered sum",
	"WPPK",
	"WPPK delivered",
	"Inbounds",
	"Outbounds",
	"Contacts",
	"Weight sum",
]
