import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Api from "../../../../API/api";

import { AuthContext } from "../../../../common/contexts/authContext";
import "./loginForm.style.css";
import UserManager from "../../../../API/userManager";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import { login, restartPassword } from "../../../../API/repositories/user";
import Loading from "../../../../common/components/Loading";
import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const { tokenManager } = Api;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: white;
  background-color: #025072;
  font-size: 25px;
  width: 40px;
  justify-content: center;
  text-align: center;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
`;

const ForgotPasswordWrapper = styled.div`
  color: gray;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-left: 100px;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${Colors.darkBlue};
  }
`;

const Title = styled.h2`
  color: ${Colors.darkBlue};
`;

const RestartFrom = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const CONSULTANT_ROLES = ["CALL_CENTER_MANAGER", "CALL_CENTER_OPERATOR"];

const LoginForm = () => {
  const navigate = useNavigate();
  let emailRef = useRef();
  let passwordRef = useRef();
  let emailRestartRef = useRef();

  const [passwordRestart, showPasswordRestart] = useState();

  const { setAuthUser } = useContext(AuthContext);

  const { addMessage } = useMessageQueueContext();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { translate } = useTranslationContext();

  const submitHandler = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value.replace(" ", "");
    const password = passwordRef.current.value.replace(" ", "");

    const response = await makeRequest(login.bind(null, email, password));

    if (response.data) {
      if (response.data) {
        const payload = tokenManager.parseJwt(response.data);
        setAuthUser(payload);
        setImmediate(() => translate());
        addMessage("Login successful", "success", 2000);

        UserManager.setUser(payload);

        setTimeout(() => {
          CONSULTANT_ROLES.find((e) => payload.role.includes(e))
            ? navigate("/consultant/dashboard")
            : navigate("/dashboard");
        }, 500);
      }
    }

    if (!response.data) {
      addMessage("Login failed", "error", 500);
    }
  };

  const handleRestartPassword = async (e) => {
    e && e.preventDefault();

    const email = emailRestartRef.current.value;

    const response = await makeRequest(restartPassword.bind(null, email));

    if (response.data) {
      addMessage("Password restarted", "success");
      showPasswordRestart(false);
    } else {
      addMessage("There is no email like that", "error");
    }
  };

  return (
    <>
      <form className="form" onSubmit={(e) => submitHandler(e)}>
        {hasUnfilledRequest(login, restartPassword) && <Loading />}
        <Flex>
          <input
            ref={emailRef}
            className="input"
            type="email"
            placeholder="Enter email"
            required
          />
          <IconWrapper>
            <i className="bi bi-person-fill"></i>
          </IconWrapper>
        </Flex>
        <Flex>
          <input
            ref={passwordRef}
            className="input"
            type="password"
            placeholder="Enter password"
            required
          />
          <IconWrapper>
            <i className="bi bi-key-fill "></i>
          </IconWrapper>
        </Flex>
        <SubmitButton
          buttonType="secondary"
          style={{ marginTop: "20px", fontSize: "14px", width: "300px" }}
          color={Colors.darkBlue}
          text="LOGIN"
        />
        <ForgotPasswordWrapper onClick={() => showPasswordRestart(true)}>
          forgot password?
        </ForgotPasswordWrapper>
      </form>
      {passwordRestart && (
        <PopUp setShow={showPasswordRestart}>
          <RestartFrom onSubmit={handleRestartPassword}>
            <Title>Restart password</Title>
            <Input
              inputRef={emailRestartRef}
              color={Colors.darkBlue}
              name="Email"
              type="email"
              required
              inputWidth={200}
              width={50}
            />
            <SubmitButton
              color={Colors.darkBlue}
              style={{ marginTop: "20px", fontSize: "14px", width: "280px" }}
              text="Restart"
              buttonType="secondary"
            />
          </RestartFrom>
        </PopUp>
      )}
    </>
  );
};

export default LoginForm;
