import React from "react";
import styled from "styled-components";
import ChooseProductView from "./ChoseProductView";

const Wrapper = styled.div`
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
`;

const Order = ({
  addMessage,
  product,
  order,
  chosenProductData,
  setChosenProductData,
  contact,
}) => {
  return (
    <Wrapper>
      <ChooseProductView
        contact={contact}
        product={product}
        setChosenProductData={setChosenProductData}
        chosenProductData={chosenProductData}
        currency={order.currency}
        orderId={order._id}
        items={order.order_items}
      />
    </Wrapper>
  );
};

export default Order;
