import { PageWrapper } from "@/common/styles/Wrappers";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getMailingReports } from "@/API/repositories/mailing-report";
import { useEffect, useState, useMemo } from "react";
import Loading from "@/common/components/Loading";
import Table from "@/common/components/Table";
import { HEADERS } from "./constants/headers";
import MailingReportTableItem from "./components/mailingReportTableItem/MailingReportTableItem";
import MailingReportForm from "./components/mailingReportForm/MailingReportForm";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const MailingReports = () => {
  const [mailingReports, setMailingReports] = useState([]);
  const [selectedMailingReport, setSelectedMailingReport] = useState();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadMailingReports = async () => {
    const response = await makeRequest(getMailingReports);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setMailingReports(response.data);
  };

  useEffect(() => {
    loadMailingReports();
  }, []);

  const rows = useMemo(
    () =>
      mailingReports.map((mailingReport, index) => (
        <MailingReportTableItem
          key={mailingReport._id}
          report={mailingReport}
          index={index}
          onEdit={() => setSelectedMailingReport(mailingReport)}
        />
      )),
    [mailingReports]
  );

  return (
    <PageWrapper>
      {hasUnfilledRequest(getMailingReports) && <Loading />}

      <div style={{ width: "100%" }}>
        <ActionButton
          defaultText="Add mailing report"
          onClick={() => setSelectedMailingReport(null)}
        />
      </div>

      <Table className="styled-table" headers={HEADERS} raws={rows}></Table>

      {selectedMailingReport !== undefined && (
        <MailingReportForm
          onClose={() => setSelectedMailingReport(undefined)}
          selectedReport={selectedMailingReport}
          loadData={loadMailingReports}
        />
      )}
    </PageWrapper>
  );
};

export default MailingReports;
