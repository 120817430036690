export const HEADERS = [
  "Attendance",
  "Holiday",
  "Special leave",
  "Sick leave",
  "Break during the day",
  "Excused unpaid leave",
  "Absence",
  "Manager hours",
  "Hours a day",
];
