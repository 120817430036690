import React from "react";
import { FilterWrapper, SearchBar } from "./CCPaymentKDRSearchBar.styled";
import SelectInput from "@/common/components/SelectInput";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import { COMPANIES_OPTIONS } from "@/common/constants/Accounting";

const CCPyamentKDRSearchBar = ({
  setSelectedMonth,
  selectedMonth,
  hanldeSearch,
  consultantsFilterOption,
  setSelectedConsultant,
  selectedConsultant,
  setSelectedCompany,
  selectedCompany,
}) => {
  return (
    <SearchBar>
      <FilterWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SelectInput
            name="Comapny"
            options={COMPANIES_OPTIONS}
            color={Colors.darkBlue}
            width={80}
            selectWidth={120}
            setSelected={setSelectedCompany}
            selected={selectedCompany}
          />
          <SelectInput
            name="Month"
            options={LAST_YEAR_MONTHS}
            color={Colors.darkBlue}
            width={50}
            setSelected={setSelectedMonth}
            selected={selectedMonth}
          />
          <div>
            <ActionButton
              defaultText="Search"
              onClick={(e) => hanldeSearch(e)}
            />
          </div>
        </div>
        <div>
          <SelectInput
            name="Consultants"
            options={consultantsFilterOption}
            color={Colors.darkBlue}
            showLabel={false}
            selectWidth={1000}
            setSelected={setSelectedConsultant}
            selected={selectedConsultant}
            multiple={true}
          />
        </div>
      </FilterWrapper>
    </SearchBar>
  );
};

export default CCPyamentKDRSearchBar;
