import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getInboundContact } from "../../../../../API/repositories/queue";
import { Colors } from "../../../../../common/colors/colors";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Box = styled.div`
  width: 600px;
  height: 100px;
  border: 1px solid #035072;
  color: ${Colors.darkBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 40px 0px 20px;
`;

const Grid = styled.div`
  display: grid;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${Colors.darkBlue};
`;

const HasNotCurrentInboundWrapper = styled.div`
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 20px;
  color: ${Colors.green};
  border: 2px solid ${Colors.green};
  cursor: pointer;
  font-weight: bold;

  :hover {
    background-color: ${Colors.green};
    color: white;
  }
`;

const HasCurrentInboundWrapper = styled.div`
  width: fit-content;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  padding: 0px 20px;
  color: ${Colors.red};
  border: 2px solid ${Colors.red};
  cursor: pointer;

  :hover {
    background-color: ${Colors.red};
    color: white;
  }
`;

const Inbound = ({
  importText,
  downloadedContactText,
  placeHolderText,
  currentContact,
  setCurrentContact,
  inboundCallText,
}) => {
  const search = useRef();
  const { makeRequest } = useRequestsContext();
  const navigate = useNavigate();

  const handleKeyUp = (event) => {
    if (event.key === "Enter") handleSearch();
  };

  const handleGetCurrentInboundCall = async (e) => {
    e.preventDefault();

    setCurrentContact(() => null);
    navigate(`/consultant/contact/inbound/${currentContact[0].contact}`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const response = await makeRequest(
      getInboundContact.bind(null, search.current.value)
    );

    if (response.data) {
      navigate(`/consultant/contact/inbound/${response.data}`);
    } else {
    }
  };

  return (
    <>
      {currentContact && currentContact[0]?.type === "inbound" ? (
        <HasCurrentInboundWrapper
          className="animation-scale"
          onClick={(e) => handleGetCurrentInboundCall(e)}
        >
          <i className="bi bi-phone " style={{ fontSize: "32px" }} />
          <span
            style={{
              maxWidth: "200px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {inboundCallText}
          </span>
        </HasCurrentInboundWrapper>
      ) : (
        <HasNotCurrentInboundWrapper>
          <i className="bi bi-phone " style={{ fontSize: "30px" }} />
          <Grid>
            <span style={{ maxWidth: "100px" }}>
              {currentContact && currentContact[0]?.type === "inbound"
                ? importText
                : downloadedContactText}
            </span>
          </Grid>
          <form onSubmit={(e) => handleSearch(e)}>
            <input
              ref={search}
              className="offInputStyle"
              type="number"
              style={{
                border: "none",
                borderRadius: "10px",
                width: "300px",
                padding: "5px 5px 5px 15px",
                border: `1px solid ${Colors.green}`,
              }}
              onKeyUp={() => handleKeyUp}
              placeholder={placeHolderText}
              required
              disabled={
                currentContact && currentContact[0]?.type === "outbound"
              }
            />
            <SubmitButton
              style={{
                background: "none",
                border: "none",
                marginLeft: "-70px",
                color: Colors.green,
              }}
              disabled={
                currentContact && currentContact[0]?.type === "outbound"
              }
            >
              <i
                className="fa fa-search animation-scale"
                style={{ marginLeft: "30px" }}
              />
            </SubmitButton>
          </form>
        </HasNotCurrentInboundWrapper>
      )}
    </>
  );
};

export default Inbound;
