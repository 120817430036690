import React, { useState } from "react";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  addMessageToTicket,
  updateTicketStatus,
  uploadFileToQuestionTicket,
} from "@/API/repositories/tickets";
import {
  CommunicationWrapper,
  AttachIcon,
  FileInput,
  FileInputWrapper,
  FileInputNameWrapper,
  SpinnerWrapper,
  RichTextEditorWrapper,
} from "./Communication.styled";
import { OLD_STATUSES } from "../conversationGroup/constants/statuses";
import SelectInput from "@/common/components/SelectInput";
import { QUESTION_TICKET_STATUS_OPTIONS } from "@/common/constants/questionTicket";
import { Colors } from "@/common/colors/colors";
import Icon from "@/common/components/Icon";
import userManager from "@/API/userManager";
import { CUSTOM_SELECT_THEME } from "@/common/constants/customSelectTheme";
import { getMessagePlaceHolder } from "@/common/functions/getMessagePlaceHolder";
import RichTextEditor from "@/common/components/richTextEditor/RichTextEditor";
import { handleExtractDelta } from "@/common/functions/handleExtractDelta";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Communication = ({
  loadData,
  selectedTicket,
  quillRef,
  fileRef,
  handleFileChanges,
  isFileSelected,
  setMessageLoading,
  messageLoading,
}) => {
  const [statusUpdatingTicket, setStatusUpdatingTicket] = useState();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const isDisableCommunication =
    !selectedTicket ||
    OLD_STATUSES.includes(selectedTicket?.status) ||
    (selectedTicket?.status === "new" &&
      selectedTicket?.created_by !== userManager.getUser().id);

  const sendMessage = async (e) => {
    e && e.preventDefault();

    if (fileRef.current?.files[0]) {
      return await sendFile();
    }

    if (quillRef.current.getText()?.trim()) {
      await sendTextMessage();
    }
  };

  const sendTextMessage = async (e) => {
    e && e.preventDefault();

    if (messageLoading === selectedTicket)
      return addMessage("Wait until previous message will be sended", "error");
    setMessageLoading(() => selectedTicket);

    const payload = {};
    payload.quill_delta = handleExtractDelta(quillRef);
    quillRef.current?.setContents?.(null);

    const response = await makeRequest(
      addMessageToTicket.bind(null, selectedTicket._id, payload)
    );

    if (response.data) {
      await loadData();
    }

    setMessageLoading(() => null);
  };

  const sendFile = async () => {
    if (messageLoading === selectedTicket)
      return addMessage("Wait until previous message will be sended", "error");
    setMessageLoading(() => selectedTicket);

    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);

    fileRef.current.value = "";
    handleFileChanges();

    const response = await makeRequest(
      uploadFileToQuestionTicket.bind(null, formData, selectedTicket._id)
    );

    if (response.data) {
      await loadData();
    }

    setMessageLoading(() => false);
  };

  const changeTicketStatus = async (status) => {
    setStatusUpdatingTicket(() => selectedTicket);

    await sendMessage();

    const payload = {};

    payload.status = status.value;

    const response = await makeRequest(
      updateTicketStatus.bind(null, selectedTicket?._id, payload)
    );

    if (response?.data) {
      await loadData();
    }

    setStatusUpdatingTicket(() => false);
  };

  const isStatusDisabled =
    !selectedTicket ||
    statusUpdatingTicket ||
    selectedTicket?.status === "solved" ||
    (selectedTicket?.status !== "pending" &&
      selectedTicket?.created_by === userManager.getUser().id &&
      selectedTicket?.[selectedTicket?.type]?.message?.length < 2);

  return (
    <CommunicationWrapper onSubmit={(e) => sendMessage(e)}>
      <AttachIcon
        htmlFor="ticket_file"
        className="fa-solid fa-paperclip animation-scale"
      />
      <FileInputWrapper display={!isFileSelected ? "none" : "block"}>
        <FileInputNameWrapper>
          <label htmlFor="ticket_file" className="fa fa-file" />
          <FileInput
            onChange={() => handleFileChanges()}
            hidden={!isFileSelected ? "hidden" : false}
            type="file"
            accept="application/pdf,image/jpg,image/png,image/jpeg"
            id="ticket_file"
            disabled={isDisableCommunication}
            ref={fileRef}
          />
        </FileInputNameWrapper>
      </FileInputWrapper>
      <RichTextEditorWrapper>
        {!isFileSelected && (
          <RichTextEditor 
            ref={quillRef} 
            readOnly={isDisableCommunication} 
            placeholder={getMessagePlaceHolder(selectedTicket, isDisableCommunication)} 
          />
        )}
      </RichTextEditorWrapper>
      <SubmitButton
        style={{ border: "none", background: "none" }}
        disabled={isDisableCommunication}
      >
        <Icon
          size={20}
          name="fa fa-paper-plane animation-scale"
        />
     </SubmitButton>

      {selectedTicket?.type === "question" &&
        (statusUpdatingTicket ? (
          <SpinnerWrapper>
            <Icon size={28} name="fa fa-spinner fa-spin" />
          </SpinnerWrapper>
        ) : (
          <SelectInput
            bgColor={isStatusDisabled ? "transparent" : Colors.purple}
            showLabel={false}
            selectWidth={150}
            options={QUESTION_TICKET_STATUS_OPTIONS}
            setSelected={(value) => changeTicketStatus(value)}
            disabled={isStatusDisabled}
            selected={QUESTION_TICKET_STATUS_OPTIONS.find(
              (o) => o.value === selectedTicket?.status
            )}
            customThemeColors={CUSTOM_SELECT_THEME}
            color={"#fff"}
            rotation="rotateX(180deg)"
            reversed
            showSquare={true}
            placeholderColor="#fff"
            textColor={isStatusDisabled ? Colors.newDarkGray : "#fff"}
          />
        ))}
    </CommunicationWrapper>
  );
};

export default Communication;
