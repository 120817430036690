import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { getColor } from "./getColor";

export const getRawsDone = (data, setEdit) => data.map((element, i) => (
  <tr
    key={element.created_at}
    style={{ backgroundColor: getColor(element.invoice.priority) }}
  >
    <td>{i + 1}.</td>
    <td>{element._contact.full_name}</td>
    <td>{element._contact.email}</td>
    <td>{element.invoice.comapny_name}</td>
    <td>{element._product?.name || "-----"}</td>
    <td>
      {element._order.shipping?.status_delivered_at
        ? formatToDateTamplate(
            element._order.shipping.status_delivered_at
          )
        : "-----"}
    </td>
    <td>{formatToDateTamplate(element.invoice.sold_date)}</td>
    <td>{element.invoice.invoice_number}</td>
    <td>
      <i
        className="fa fa-edit animation-scale"
        style={{
          fontSize: "14px",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() => setEdit(() => element)}
      />
    </td>
  </tr>
));