import Table from '@/common/components/Table'
import React from 'react'
import { HEADERS } from './constants/headers'
import { getRaws } from './helpers/getRaws'

const DailyMarketTable = ({
  data
}) => {
  console.log(data);
  return (
    <Table className="styled-table" headers={HEADERS} raws={getRaws(data)} />
  )
}

export default DailyMarketTable
