import { updateClaimCommentForAccounting } from "@/API/repositories/validClaim";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import React, { useRef, useState } from "react";
import styled from "styled-components";

export const Subtitle = styled.h3`
  font-size: 14px;
  color: ${Colors.darkBlue};
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Textarea = styled.textarea`
  width: 360px;
  height: 104px;
  padding: 10px;
  border: 1px solid ${Colors.darkGray2};
  border-radius: 6px;
  resize: none;
  font-size: 12px;
`;

const CommentForAccounting = ({ claim }) => {
  const [disabled, setDisabled] = useState(false);
  const commentForAccoutningRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSave = async () => {
    setDisabled(() => true);

    const paylod = {
      _id: claim._id,
      comment_for_accounting: commentForAccoutningRef.current.value,
    };

    const response = await makeRequest(
      updateClaimCommentForAccounting.bind(null, paylod)
    );

    if (!response?.data) {
      setDisabled(() => false);
      return addMessage("Error ", "error");
    }

    return addMessage("Saved", "success");
  };

  return (
    <>
      {hasUnfilledRequest(updateClaimCommentForAccounting) && <Loading />}
      <div>
        <Subtitle>
          <div>Comment for accounting</div>
          <ActionButton
            style={{
              padding: "6px 4px",
              width: "60px",
            }}
            disabled={disabled}
            color={Colors.purple}
            buttonType="secondary"
            text="Save"
            background={Colors.darkBlue}
            onClick={handleSave}
          />
        </Subtitle>
        <Textarea
          onChange={() => setDisabled(() => false)}
          defaultValue={claim.comment_for_accounting}
          ref={commentForAccoutningRef}
          placeholder="Enter comment for accounting"
        />
      </div>
    </>
  );
};

export default CommentForAccounting;
