import React from "react";
import {
  SumUpInfo,
  SumUpItem,
  SumUpItemElementImage,
  SumUpItemElementImageWrapper,
  SumUpItemElementInfo,
  SumUpItemElements,
  SumUpItemElementsWrapper,
} from "../../SumUp.styled";
import FilledStar from "@/images/icons/gamification-filled-star.svg";
import { formatDate } from "@/common/functions/dateFormater";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";
import { getStars } from "@/common/functions/getStars";
import { getUserGoal } from "@/common/functions/getUserGoal";
import { SubTitle } from "@/pages/consultant/dashboard/panel/gamification/Gamification.styled";
import { findUserById } from "@/common/functions/findUserById";

const OrdersSumUp = ({ sumUpData }) => {
  const {
    commonData: { users, markets, queues },
  } = useCommonDataContext();
  const currentUserId = userManager.getUser().id;
  const user = findUserById(currentUserId, users);

  return (
    <SumUpItem>
      <SubTitle>
        {sumUpData.length === 1
          ? "Last month"
          : `${sumUpData.length} last months`}{" "}
        - Orders
      </SubTitle>
      <SumUpItemElementsWrapper>
        {sumUpData.map((sumUpMonth) => (
          <SumUpItemElements>
            <SumUpItemElementInfo>
              {formatDate(sumUpMonth.month, "MMMM")}
            </SumUpItemElementInfo>
            <SumUpItemElementImageWrapper size="72px">
              <SumUpItemElementImage src={FilledStar} alt="star" />
              <SumUpInfo color="white">
                {getStars({
                  user,
                  gamificationUserStats: sumUpMonth,
                  markets,
                  queues,
                })}
              </SumUpInfo>
            </SumUpItemElementImageWrapper>
            <SumUpItemElementInfo>
              <b>{sumUpMonth.total_amount}</b> /{" "}
              {Math.ceil(
                getUserGoal({
                  gamificationUserStats: sumUpMonth,
                  user,
                  markets,
                  queues,
                })
              )}
            </SumUpItemElementInfo>
          </SumUpItemElements>
        ))}
      </SumUpItemElementsWrapper>
    </SumUpItem>
  );
};

export default OrdersSumUp;
