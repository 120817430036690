export const COLORS_ARRAY = [
  "#FF66B2", // Jasny róż
  "#99FF99", // Jasna limonka
  "#CC99FF", // Jasny fiolet
  "#FF99FF", // Jasny fuksja
  "#99FFFF", // Jasny turkus
  "#FFCC99", // Jasny pomarańcz
  "#99CCFF", // Jasny niebieski
  "#FFCC99", // Jasny brąz
  "#FF3333", // Jasny czerwony
  "#99FF99", // Jasna zieleń
  "#CC66FF", // Jasny purpurowy
  "#66CCFF", // Jasny błękit
  "#FFDD55", // Jasny żółty
  "#FFCC99", // Jasny pomarańcz
  "#9966FF", // Jasny fiolet
  "#66FFFF", // Jasny turkus
  "#FF3333", // Jasny czerwony
  "#99FF99", // Jasna zieleń
  "#CCFF66", // Jasny żółtozielony
  "#66CCFF", // Jasny błękit
  "#F0BD5A", // Jasny błękit
  "#8CD4C8", // Jasny błękit
  "#BDB8DB", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
  "#66CCFF", // Jasny błękit
];
