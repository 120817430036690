import React from "react";
import {
  OldTicketsTitle,
  OldTicketsTitleWrapper,
} from "../../TicketView.styled";
import OldTicketsList from "../../../oldTicketsList/OldTicketsList";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const OldTickets = ({
  oldTickets,
  selectedTicket,
  handleSelectTicket,
}) => {
  const { translation } = useTranslationContext();

  return (
    <>
      <OldTicketsTitleWrapper>
        <OldTicketsTitle>{translation["old_tickets"]}</OldTicketsTitle>
      </OldTicketsTitleWrapper>
      <OldTicketsList
        oldTickets={oldTickets}
        selectedTicket={selectedTicket}
        handleSelectTicket={handleSelectTicket}
      />
    </>
  );
};

export default OldTickets;
