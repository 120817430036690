import React, { useMemo, useRef, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { getOrderLostPackagesReport } from "../../../API/repositories/reports";
import Loading from "../../../common/components/Loading";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SentOrdersSearchBar from "./components/sentOrdersSearchBar/SentOrdersSearchBar";
import SentOrdersTable from "./components/sentOrdersTable/SentOrdersTable";
import { generateRaws, generateRawsCSV } from "./helpers/generateRaws";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const LostPackagesReport = () => {
  const minusDateRef = useRef();
  const [selectedType, setSelectedType] = useState();

  const [data, setData] = useState();

  const results = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateRaws(data, minusDateRef.current.value);
  }, [data]);

  const csvResults = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateRawsCSV(data, minusDateRef.current.value);
  }, [data]);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSearch = async () => {
    const payload = Object();

    if (!selectedType) {
      addMessage("Please pick all required fileds", "error");

      return;
    }

    payload.type = selectedType.value;

    const response = await makeRequest(
      getOrderLostPackagesReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getOrderLostPackagesReport) && <Loading />}
      <SentOrdersSearchBar
        minusDateRef={minusDateRef}
        handleSearch={handleSearch}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        results={csvResults}
      />
      {!!data?.length && <SentOrdersTable data={results} />}
    </WrapperWithScroll>
  );
};

export default LostPackagesReport;
