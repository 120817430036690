import React, { useEffect, useRef, useState } from "react";
import PopUp from "../../../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import Input from "../../../../../common/components/Input";
import MoreInfo from "../../moreInfo/MoreInfo";
import ItemsPicker from "./ItemsPicker";
import AddVariant from "./AddVariant";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import {
  addProductToOrder,
  updateOrderByConsultant,
} from "../../../../../API/repositories/order";
import Loading from "../../../../../common/components/Loading";
import { formatToDateTamplate } from "../../../../../common/functions/dateFormater";
import { getProductsWithCouriers } from "@/API/repositories/lms";
import SelectInput from "@/common/components/SelectInput";
import SupportMail from "@/common/components/supportMail/SupportMail";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const DisplayFlex = styled.div`
  margin-top: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: ${Colors.darkBlue};
  }

  button {
    margin: 0 30px;
  }
`;

const OrderShow = ({
  order,
  setOrder,
  contact,
  handleLoadContact,
}) => {
  const [addVariant, setAddVariant] = useState();
  const [selectedCourier, setSelectedCourier] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const suggestedDeliveryDateRef = useRef();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();
  const { translation } = useTranslationContext();

  const hadnleDelteProductItem = async (e, number) => {
    const valdated = order.order_items
      .filter((e, i) => i !== number)
      .map((e, i) => ({
        product: e.product,
        value: e.product_variant,
        price: e.price,
      }));

    const response = await makeRequest(
      addProductToOrder.bind(null, order._id, valdated)
    );

    if (!response?.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    await handleLoadContact();
  };

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (productWithCourier) => order._product.short === productWithCourier.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(
      options.find((option) => option.value === order?.shipping?.curier_lms_id) ||
        options[0]
    );
  };

  const handleChangeShippingDate = async (e) => {
    e.preventDefault();

    order.shipping.suggested_delivery_date =
      suggestedDeliveryDateRef.current.value;
    order.items = order.order_items?.map((item) => ({
      product: item.product,
      price: item.price,
      value: item.product_variant,
    }));
    order.shipping.full_name = contact.full_name;
    order.shipping.email = contact.email;
    order.shipping.phone_number = contact.phone_number;
    order.shipping.street = contact.street;
    order.shipping.postal_code = contact.postal_code;
    order.shipping.city = contact.city;
    order.shipping.country = contact.country;
    order.shipping.curier_lms_id = selectedCourier?.value;

    const response = await makeRequest(
      updateOrderByConsultant.bind(null, order._id, order)
    );

    if (!response.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    await handleLoadContact();
  };

  const handleChangeCourier = async (e) => {
    e.preventDefault();

    if (!order.shipping) {
      return;
    }

    order.shipping.full_name = contact.full_name;
    order.shipping.email = contact.email;
    order.shipping.phone_number = contact.phone_number;
    order.shipping.street = contact.street;
    order.shipping.postal_code = contact.postal_code;
    order.shipping.city = contact.city;
    order.shipping.country = contact.country;
    order.items = order.order_items?.map((item) => ({
      product: item.product,
      price: item.price,
      value: item.product_variant,
    }));
    order.shipping.curier_lms_id = selectedCourier?.value;

    const response = await makeRequest(
      updateOrderByConsultant.bind(null, order._id, order)
    );

    if (!response.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    };

    await handleLoadContact();
  };

  useEffect(() => {
    loadCouriers();
  }, []);

  useEffect(() => {
    handleSetCouriersData();
  }, [productsWithCouriers]);

  return (
    <>
      {hasUnfilledRequest(
        addProductToOrder,
        getProductsWithCouriers,
        updateOrderByConsultant
      ) && <Loading />}
      <PopUp setShow={setOrder}>
        <DisplayFlex>
          <h2>{translation.order}:</h2>
          <MoreInfo
            product={order?._product?.short}
            email={contact.email}
            sendMail={translation.sendMail}
            order={order}
            mailError={translation.mailError}
            mailSuccess={translation.mailSuccess}
          />
          <SupportMail order={order} />
        </DisplayFlex>
        <DisplayFlex style={{ marginTop: "20px" }}>
          <div>
            <ItemsPicker
              market={order._market}
              product={order._product}
              chosenVariants={order.order_items}
              setAddVariant={setAddVariant}
              hadnleDelteProductItem={hadnleDelteProductItem}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0 0",
              }}
            >
              <SelectInput
                width={80}
                selectWidth={230}
                name={translation.curier}
                color={Colors.darkBlue}
                options={courierOptions}
                setSelected={setSelectedCourier}
                selected={selectedCourier}
              />
              <ActionButton
                onClick={(e) => handleChangeCourier(e)}
                disabled={
                  order.shipping?.waybill_number || order.status !== "confirmed"
                }
                defaultText={translation.changeCurier}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0 0",
              }}
            >
              <Input
                name={translation.suggested_delivery}
                color={Colors.darkBlue}
                inputRef={suggestedDeliveryDateRef}
                value={
                  order.shipping?.suggested_delivery_date &&
                  formatToDateTamplate(order.shipping.suggested_delivery_date)
                }
                width={180}
                type="date"
                inputWidth={130}
              />
              <ActionButton
                onClick={(e) => handleChangeShippingDate(e)}
                disabled={order.status !== "confirmed"}
                defaultText={translation.changeDate}
              />
            </div>
          </div>
        </DisplayFlex>
      </PopUp>
      {addVariant && (
        <AddVariant
          product={order._product}
          setAddVariant={setAddVariant}
          order={order}
          handleLoadContact={handleLoadContact}
        />
      )}
    </>
  );
};

export default OrderShow;
