import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

export const InfoWrapper = styled.div`
  max-width: 800px;
  border: 1px dashed red;
  padding: 20px;
  border-radius: 10px;
  color: red;
  text-decoration: underline;
`;

export const SpecialInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
