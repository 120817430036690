import React from 'react'
import { PointTableWrapper, PointWrapper } from '../../ConsultantGamification.styled';
import { formatToDateTamplate } from '@/common/functions/dateFormater';

const GamificationPoints = ({ points }) => {
	return (
    <PointTableWrapper>
      {points?.map((d) => (
        <PointWrapper>
          <i style={{ fontSize: "12px" }}>
            {formatToDateTamplate(d.created_at)}
          </i>
          <i>For: {d.points_for_type}</i>
          <b>Grade: {d.grade}</b>
          <b>Points: {d.point_number}</b>
        </PointWrapper>
      ))}
    </PointTableWrapper>
  );
}

export default GamificationPoints
