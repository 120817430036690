import moment from "moment";
import React, { useEffect, useState } from "react";
import { getTeamResult } from "../../../API/repositories/reports";
import { Colors } from "../../../common/colors/colors";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { TableWrapper, Wrapper } from "./ConsultantTeamLastResult.styled";

const ConsultantTeamLastResult = () => {
  const [results, setResult] = useState(null);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const from = moment().subtract("14", "days").format("DD/MM/YYYY");
  const to = moment().format("DD/MM/YYYY");

  const getTeamRsults = async () => {
    const response = await makeRequest(getTeamResult.bind(null));

    if (response.data) {
      setResult(response.data);
    }
  };

  useEffect(() => {
    getTeamRsults();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getTeamResult) && <Loading />}
      {results &&
        results.map((consultantData) => (
          <TableWrapper>
            <h5
              style={{
                textAlign: "center",
                marginBottom: "10px",
                color: Colors.darkBlue,
              }}
            >
              {consultantData.username} ( {from} - {to})
            </h5>
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Confirmed</th>
                  <th> Delivered</th>
                  <th>Confirmed more than 1 item</th>
                  <th>Delivered more than 1 item</th>
                </tr>
              </thead>
              <tbody className="queue">
                {consultantData.result.map((result, i) => (
                  <tr key={i + "_" + consultantData.username}>
                    <td>{result.index}</td>
                    <td>{result.confirmed}</td>
                    <td>{result.delivered}</td>

                    <td>{result.confirmedMoreOneProduct}</td>
                    <td>{result.deliveredMoreOneProduct}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        ))}
    </Wrapper>
  );
};

export default ConsultantTeamLastResult;
