import userManager from '@/API/userManager';
import { Colors } from '@/common/colors/colors';
import SubmitButton from '@/common/components/buttons/SubmitButton';
import Input from '@/common/components/Input';
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput';
import { HOURS_POSIBLES } from '@/common/constants/CC';
import { checkIsManager } from '@/common/functions/checkIsManager';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import React, { useState } from 'react'
import styled from 'styled-components'

const BigHour = styled.p`
  color: ${Colors.darkBlue};
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 10px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 80px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HourForm = ({
  setShowHourFill,
  showHourFill,
  workingHoursOneSave,
  hanldeOnChange,
  availableBonuses = [],
  addWorkedHours
}) => {
  const { options: { productsOptions }, commonData: { users } } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();
  const currentUserId = userManager.getUser().id;
  const availableOptions = productsOptions.filter(o => availableBonuses?.some(b => b.product === o.value));

  const selectDefaultProduct = () => {
    const bonus =  availableBonuses?.find(b => b._id === workingHoursOneSave[showHourFill]?.bonusId) || availableBonuses?.[0];
    const product = availableOptions?.find(o => o.value === bonus?.product);
  
    return product;
  }

  const [activity, setActivity] = useState(workingHoursOneSave[showHourFill]?.sign);
  const [selectedProduct, setSelectedProduct] = useState(selectDefaultProduct);

  const handleSave = (e) => {
    e && e.preventDefault();
    const bonus = availableBonuses?.find(b => b.product === selectedProduct.value);

    if (!bonus) {
      return;
    }

    if (activity === "m") {
      const isManager = checkIsManager(currentUserId, users, addWorkedHours);

      if (!isManager) {
        return addMessage("You can't add manager hours", "error");
      }
    }
  
    hanldeOnChange(showHourFill, activity, bonus._id);
    setShowHourFill("");
  }

  const controlWorkingHours = (sign) => {
    if (HOURS_POSIBLES.includes(sign)) {
      setActivity(sign);
      return;
    }

    setActivity("");
  }

  return (
    <PopUp padding="10px 20px 15px" setShow={setShowHourFill}>
    <BigHour>{showHourFill}</BigHour>
    <form onSubmit={handleSave}>
      <InputsWrapper>
        <SelectInput
          name="Product"
          color={Colors.darkBlue}
          width={80}
          required
          hideStar
          selected={selectedProduct}
          options={availableOptions}
          setSelected={setSelectedProduct}
        />
        <Input
          controlled
          color={Colors.darkBlue}
          width={80}
          name="Activity"
          value={activity}
          onChange={(e) => controlWorkingHours(e.target.value)}
          required
        />
      </InputsWrapper>
      <ButtonWrapper>
        <SubmitButton text="Save" />
      </ButtonWrapper>
    </form>
  </PopUp>
  )
}

export default HourForm
