import React from "react";
import {
  CalendarElementWrapper,
  FreeDayNameWrapper,
  TopContentCalendarElementWrapper,
} from "../calendar.styles";
import { Colors } from "@/common/colors/colors";
import Icon from "@/common/components/Icon";
import moment from "moment";
import { IconWrapper } from "@/pages/consultant/consultantManagerScoringListening/consultantManagerScoring.styles";
import { calculateCalendatElementsBaner } from "@/common/functions/calculateCalendatElementsBaner";
import ActionButton from "@/common/components/buttons/ActionButton";

const CalendarElement = ({
  data,
  day,
  freeDay,
  setWorkingHoursOneSave,
  setAddWorkedHours,
  handleDownloadFile,
  setAddWorkingHoursNew
}) => {
  const today = new Date().getDate();
  const date = new Date(day).getDate();

  const getColorForRow = () => {
    if (date <= today - 4) {
      return Colors.lightGreen;
    }

    if (date <= today) {
      return Colors.yellow;
    }

    return "";
  };

  const canEdit = () => {
    return date > today - 4 && date <= today;
  };

  const getIconForDoc = (status) => {
    switch (status) {
      case "open":
        return (
          <i
            style={{ fontSize: "20px", color: Colors.orange }}
            className="fa fa-sharp fa-clock-rotate-left"
          />
        );
      case "accepted":
      case "accepted_pending":
        return (
          <i
            style={{ fontSize: "20px", color: Colors.green }}
            className="fa-solid fa-check"
          />
        );
      case "to_correct":
        return (
          <i
            style={{ fontSize: "20px", color: Colors.red }}
            className="fa-solid fa-circle-xmark"
          />
        );
      default:
        return "----";
    }
  };

  return (
    <CalendarElementWrapper
      style={{
        background: freeDay ? "lightgray" : getColorForRow(),
      }}
    >
      <TopContentCalendarElementWrapper>
        <h3>{moment(day).format("DD")}</h3>
        <IconWrapper>
          {canEdit() && (
            <ActionButton
              color="transparent"
              disabled={freeDay}
              style={{ border: "none", padding: "0" }}
              onClick={() => {
                setWorkingHoursOneSave(() => {
                  const result = {};

                  data?.hours?.forEach((hour) => {
                    result[hour.hour_time] = {
                      sign: hour.sign,
                      bonusId: hour.bonus,
                    }
                  });
                  return result;
                });
                setAddWorkedHours(day.format("YYYY-MM-DD"));
              }}
            >
              <Icon name={"fa fa-edit"} color={Colors.red} />
            </ActionButton>
          )}
          {data?._ticket && (
            <>
              <ActionButton
                color="transparent"
                style={{ border: "none", padding: "0" }}
                onClick={() =>
                  handleDownloadFile(data?._ticket?._stored_document?._id)
                }
              >
                {getIconForDoc(data?._ticket.status)}
              </ActionButton>
            </>
          )}
          {canEdit() &&
            <ActionButton
              color="transparent"
              disabled={freeDay}
              style={{ border: "none", padding: "0" }}
              onClick={() => setAddWorkingHoursNew(() => day.format("YYYY-MM-DD"))}
            >
              <Icon name={"fa fa-plus"} color={Colors.green} />
            </ActionButton>
          }
        </IconWrapper>
      </TopContentCalendarElementWrapper>
      {freeDay && (
        <FreeDayNameWrapper>
          <p>{freeDay?.name}</p>
        </FreeDayNameWrapper>
      )}
      {data && calculateCalendatElementsBaner(data)}
    </CalendarElementWrapper>
  );
};

export default CalendarElement;
