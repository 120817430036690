import { generateExcelSheetBuffer } from "@/common/functions/getExcelBuffer";
import { getExcelBodyForHoursReport } from "./getExcelBodyForHoursRepor";
import { formatDate } from "./dateFormater";

export const generateHoursReportFiles = async ({
	data,
	selectedConsultant,
	consultants,
	workingFromDate,
	workingToDate,
	filterRegex,
	selectedMonth,
	headers
}) => {
	const excelData = getExcelBodyForHoursReport(
		data,
		selectedConsultant,
		consultants,
		workingFromDate,
		workingToDate,
		filterRegex,
		headers
	);

	const sheetName = `Hours report ${formatDate(selectedMonth.label, 'MMMM YYYY')}.xlsx`;

	const buffer = await generateExcelSheetBuffer(excelData, sheetName);

	const excel = {
		data: buffer.toString('base64'),
		name: sheetName,
	};

	return [excel];
}
