import React from "react";
import PopUp from "@/common/components/PopUp";
import { SubTitle, Textarea, SaveButtonWrapper } from "../../TicketView.styled";
import ActionButton from "@/common/components/buttons/ActionButton";

const DocumentToCorrect = ({
  setIsToCorrectOpen,
  handleChangeDocumentTicketStatus,
  documentMessageRef,
}) => {
  return (
    <PopUp padding="10px 20px" setShow={setIsToCorrectOpen}>
      <SubTitle>Add what to correct:</SubTitle>
      <Textarea
        showLabel={false}
        placeholder="correct..."
        ref={documentMessageRef}
      />
      <SaveButtonWrapper>
        <ActionButton
          onClick={() => handleChangeDocumentTicketStatus("to_correct", true)}
          defaultText="Save"
        />
      </SaveButtonWrapper>
    </PopUp>
  );
};

export default DocumentToCorrect;
