import React from 'react'
import { sumByField } from '@/common/functions/sumByField'
import { CheckBoxInputsWrapper, SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'
import { selectOrderItems } from './helpers/selectOrderItems'
import { useValidClaim } from '@/common/hooks/useValidClaim'
import { genItemsIds } from '@/common/components/validClaimForm/helpers/getItemsIds'

const OrderItems = () => {
  const {
    claim,
    order,
    selectedOrderItems,
    setSelectedOrderItems
  } = useValidClaim();

  const currency = claim?.currency || order.shipping?.currency || order.currency;
  const orderItems = claim?.order_items || genItemsIds(order);

  return (
    <SectionWrapper>
      <Subtitle>
        Items (In Total: {sumByField(selectedOrderItems, 'price')} {currency}):
      </Subtitle>
      <CheckBoxInputsWrapper>
        {orderItems.map((item, index) => (
          <CheckBoxInput
            disabled={claim}
            checked={selectedOrderItems.some(i => i.id === item.id)}
            key={index}
            text={`${item.product_variant} (${item.price} ${currency})`}
            handleSelect={() => setSelectedOrderItems((prev) => selectOrderItems(item, prev))}
          />
        ))}
      </CheckBoxInputsWrapper>
    </SectionWrapper>
  )
}

export default OrderItems
