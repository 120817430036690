import { getPaymentStatus, triggerStatus } from "@/API/repositories/cms";
import Icon from "@/common/components/Icon";
import Loading from "@/common/components/Loading";
import Table from "@/common/components/Table";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import PaymentStatus from "./paymentStatus/PaymentStatus";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const formatDate = (date) => {
  if (!date) {
    return "-----";
  }

  return moment(date).format("DD/MM/YYYY HH:mm:ss");
};
const OrderPayment = ({ data, setOrder, orderId }) => {
  const [paymentStatus, setPaymentStatus] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const checkPaymentStatus = async (paymentId) => {
    const response = await makeRequest(getPaymentStatus.bind(null, paymentId));

    if (!response?.data) {
      return addMessage("Failed to get payment status", "error");
    }

    setPaymentStatus(response.data);
  }

  const triggerStatusRefresh = async (paymentId) => {
    const response = await makeRequest(triggerStatus.bind(null, orderId, paymentId));

    if (!response?.data) {
      return addMessage("Failed to refresh status", "error");
    }

    addMessage("Status refreshed", "success");

    setOrder((prev) => (
      {
        ...prev,
        payment: prev.payment.map((p) => {
          if (p.paymentId === paymentId) {
            return {
              ...p,
              status: response.data.status,
              status_pending_date: response.data.status_pending_date,
              status_canceled_date: response.data.status_canceled_date,
              status_waiting_for_confirmation_date: response.data.status_waiting_for_confirmation_date,
              status_complated_date: response.data.status_complated_date
            }
          }

          return p;
        }),
      }
    ))
  }
  
  return (
    <Wrapper>
      {hasUnfilledRequest(getPaymentStatus) && <Loading />}
      <Table
        headers={[
          "No.",
          "Created at",
          "Status",
          "Pending at",
          "Canceled at",
          "Waiting for confirmation",
          "Paid at",
          "Actions"
        ]}
        raws={data.map((d, i) => (
          <tr>
            <td>{i + 1}.</td>
            <td>{formatDate(d.created_at)}</td>
            <td>{d.status}</td>
            <td>{formatDate(d.status_pending_date)}</td>
            <td>{formatDate(d.status_canceled_date)}</td>
            <td>{formatDate(d.status_waiting_for_confirmation_date)}</td>
            <td>{formatDate(d.status_complated_date)}</td>
            <td>
              {d.payment_operator !== 'STRIPE' && d.status !== 'REFUNDED' && (
                <ActionsWrapper>
                  <Icon name="fa fa-eye" onClick={() => checkPaymentStatus(d.paymentId)} />
                  <Icon name="fa fa-refresh" onClick={() => triggerStatusRefresh(d.paymentId)} />
                </ActionsWrapper>
              )}
            </td>
          </tr>
        ))}
        stickyHeader={true}
        className="styled-table-dashboard"
      />
      {paymentStatus && (
        <PaymentStatus setShow={setPaymentStatus} status={paymentStatus} />
      )}
    </Wrapper>
  );
};

export default OrderPayment;
