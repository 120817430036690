import React, { useEffect, useRef, useState } from 'react'
import Loading from '../Loading';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { sendMailByMailType } from '@/API/repositories/mail';
import { ButtonWrapper, CustomIcon } from './SupportMail.styled';
import PopUp from '../PopUp';
import SelectInput from '../SelectInput';
import { getDepartments } from '@/API/repositories/department';
import { Colors } from '@/common/colors/colors';
import { createGeneralQuestionTicket } from '@/API/repositories/tickets';
import RichTextEditor from '../richTextEditor/RichTextEditor';
import { handleExtractDelta } from '@/common/functions/handleExtractDelta';
import userManager from '@/API/userManager';
import { useTranslationContext } from '@/common/hooks/useTranslationContext';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import ActionButton from '../buttons/ActionButton';
import SubmitButton from '../buttons/SubmitButton';

const SupportMail = ({ order }) => {
  const [color, setColor] = useState(Colors.orange);
  const [canSendMail, setCanSendMail] = useState(true);
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [supportDepartment, setSupportDepartment] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState();

  const { addMessage } = useMessageQueueContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const quillRef = useRef();

  const handleSubmitSupport = async (e) => {
    e && e.preventDefault();

    if (!quillRef.current.getText()?.trim()) {
      return addMessage("Please fill the message", "error");
    }
    
    setShowCreateQuestion(false);

    const formData = new FormData();

    const username = userManager.getUser().username;
    const mailMessage = `${username} sent "SUPPORT" mail`;

    formData.append("topic", selectedTopic.value);
    formData.append("quill_delta", JSON.stringify(handleExtractDelta(quillRef)));
    formData.append("order", order._id);
    formData.append("contact", order.contact);
    formData.append("reciver", supportDepartment?._id);
    formData.append("mail_message", mailMessage);

    const ticketResponse = await makeRequest(createGeneralQuestionTicket.bind(null, formData));

    if (!ticketResponse?.data) {
      setColor(() => Colors.red);
      setTimeout(() => {
        setColor(() => Colors.orange);
      }, 3000)
      return addMessage("Couldn't create a ticket", "error");
    }
    
    const mailResponse = await makeRequest(
      sendMailByMailType.bind(
        null,
        "SUPPORT_" + order._market.short.toUpperCase(),
        order._id
      )
    );
    setCanSendMail(false);
    setTimeout(() => {
      setCanSendMail(true);
    }, 10000)

    if (!mailResponse?.data) {
      setColor(() => Colors.red);
      setTimeout(() => {
        setColor(() => Colors.orange);
      }, 3000)
      return addMessage("Unable to send mail", "error");
    }

    setColor(() => Colors.green);
    setTimeout(() => {
      setColor(() => Colors.orange);
    }, 3000)
    addMessage("Success", "success")
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setSupportDepartment(() => response.data.find((d) => d.name === "Support"));
   
  };

  const loadBaseData = () => {
    loadDepartments();
  }

  useEffect(() => {
    loadBaseData();
  }, []);

  return (
    <div>
      {hasUnfilledRequest(sendMailByMailType, getDepartments, createGeneralQuestionTicket) && <Loading />}
      <ActionButton
        color={color}
        onClick={() => setShowCreateQuestion(true)}
        disabled={!canSendMail}
        defaultText={color === Colors.green
          ? translation.mailSuccess
          : color === Colors.red
            ? translation.mailError
            : translation.sendSupportMail}
      >
        <CustomIcon color={"#000"} className="fa fa-envelope" />
      </ActionButton>
      {showCreateQuestion && (<PopUp setShow={setShowCreateQuestion}>
       <form onSubmit={handleSubmitSupport}>
        <SelectInput
          name="Topic"
          color={Colors.darkBlue}
          width={40}
          selectWidth={520}
          options={supportDepartment?.topics}
          selected={selectedTopic}
          setSelected={setSelectedTopic}
          required
          hideStar
        />
        <div style={{
          maxWidth: '600px'
        }}>
          <RichTextEditor ref={quillRef} />
        </div>
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: '-30px'}} text="Send " />
        </ButtonWrapper>
       </form>
      </PopUp>)}
    </div>
  )
}

export default SupportMail
