import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import StatisticsGradeTable from "@/common/components/statisticsGradeTable/StatisticsGradeTable";
import React, { useEffect, useState } from "react";
import { DataContainer, Wrapper } from "./GradeReport.styled";
import Loading from "@/common/components/Loading";
import SearchBar from './components/searchBar/SearchBar';

const GradeReport = () => {
  const [data, setData] = useState();

  const [consultants, setConsultants] = useState(null);

  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  const handleSetConsultants = () => {
    setConsultants(
      users
        .filter((user) => CONSULTANT_ROLES.includes(user.role))
        .map((consultant) => {
          return {
            label: consultant.name + " " + consultant.surname,
            value: consultant._id,
          };
        })
    );
  };

  useEffect(() => {
    handleSetConsultants();
  }, [users]);

  return (
    <Wrapper>
      {consultants ? (
        <SearchBar
          data={data}
          consultants={consultants}
          setData={setData}
        />
      ) : (
        <Loading />
      )}
      {data && (
        <DataContainer
        >
          {data.map((data) => (
            <StatisticsGradeTable
              csv={true}
              grade={data}
              dontShowDescription={true}
              name={() =>
                consultants.find(
                  (consultant) => consultant.value === data.index
                ).label
              }
            />
          ))}{" "}
        </DataContainer>
      )}
    </Wrapper>
  );
};

export default GradeReport;
