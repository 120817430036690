import { saveConsultantContact } from '@/API/repositories/contact'
import { createSuspicionTicket } from '@/API/repositories/tickets'
import { Colors } from '@/common/colors/colors'
import ActionButton from '@/common/components/buttons/ActionButton'
import Card from '@/common/components/Card'
import Input from '@/common/components/Input'
import SelectInput from '@/common/components/SelectInput'
import { DISABLED_TOOLTIP } from '@/common/constants/suspicion'
import { validatePhoneForCourier } from '@/common/functions/validatePhoneForCourier'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonWrapper, InputsWrapper } from './ContactForm.styled'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'

const ContactForm = ({
	dataToDisplay,
	country,
	setCountry,
	reload
}) => {
  const { id } = useParams();

	const nameRef = useRef();
  const emailRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const phoneForCourierRef = useRef();

	const { addMessage } = useMessageQueueContext();
	const { makeRequest } = useRequestsContext();
	const { COUNTRIES } = useCommonDataContext();

	const handleSave = async () => {

		const isPhoneError = phoneForCourierRef.current.value
      && !validatePhoneForCourier(phoneForCourierRef.current.value)

    if (isPhoneError) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    const payload = {};
    payload.full_name = nameRef.current
      ? nameRef.current.value
      : dataToDisplay.full_name;
    payload.email = emailRef.current
      ? emailRef.current.value
      : dataToDisplay.email;
    payload.country = country.value;
    payload.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : dataToDisplay.postal_code;
    payload.city = cityRef.current ? cityRef.current.value : dataToDisplay.city;
    payload.street = streetRef.current
      ? streetRef.current.value
      : dataToDisplay.street;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;

    const response = await makeRequest(
      saveConsultantContact.bind(null, id, payload)
    );

    await reload();

    if (response.data) {
    } else {
      console.error(response.error);
    }
  };

	const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, dataToDisplay._id)
    );

    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    await reload();

    return addMessage("Success", "success");
  };

	return (
		<Card>
			<InputsWrapper>
				<h3 style={{ color: Colors.darkBlue }}>Contact:</h3>
				<div style={{ display: "flex" }}>
					<div>
						<Input
							inputRef={nameRef}
							name="Full Name"
							value={dataToDisplay.full_name}
							width={140}
						/>
						<Input
							inputRef={emailRef}
							name="Email"
							value={dataToDisplay.email}
							width={140}
						/>
						<SelectInput
							options={COUNTRIES}
							name="Country"
							selected={country}
							setSelected={setCountry}
							width={140}
						/>
						<Input
							name="Phone for courier"
							value={dataToDisplay.phone_number_for_courier}
							width={140}
							inputRef={phoneForCourierRef}
						/>
						<Input
							name="Phone Number"
							disabled={true}
							value={dataToDisplay.phone_number}
							width={140}
						/>
					</div>
					<div>
						<Input
							inputRef={postalCodeRef}
							name="Postal Code"
							value={dataToDisplay.postal_code}
							width={140}
						/>
						<Input
							inputRef={cityRef}
							name="City"
							value={dataToDisplay.city}
							width={140}
						/>
						<Input
							inputRef={streetRef}
							name="Street"
							value={dataToDisplay.street}
							width={140}
						/>
					</div>
				</div>
				<ButtonWrapper>
					<ActionButton
						tooltip={
							dataToDisplay.shouldDisableSuspicion
								? DISABLED_TOOLTIP
								: null
						}
						disabled={dataToDisplay.shouldDisableSuspicion}
						defaultText="Suspect"
						color={Colors.red}
						onClick={handleCreateSuspicionTicket}
					/>
					<ActionButton defaultText="Save" onClick={handleSave} />
				</ButtonWrapper>
			</InputsWrapper>
		</Card>
	)
}

export default ContactForm
