import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { findMarketById } from "@/common/functions/findMarketById";
import { findProductById } from "@/common/functions/findProductById";
import { DEFAULT_LIMIT } from "../constants/searchParams";
import { RESIGN_OPTIONS } from "@/common/constants/resignOptions";

export const getCSVBody = ({
	data, markets, products, page, cleanTagOptions
}) => {
	const result = [];

	data.forEach((resign, index) => {
		const temp = [];

		temp.push((page - 1) * DEFAULT_LIMIT + index + 1);
		temp.push(findMarketById(resign.market, markets)?.name);
		temp.push(findProductById(resign.product, products)?.name);
		temp.push(resign.contact.full_name);
		temp.push(resign.contact.email);
		temp.push(resign.confirmation_date ? formatToDateTamplate(resign.confirmation_date) : "----");
		temp.push(formatToDateTamplate(resign.resignation_date));
		temp.push(RESIGN_OPTIONS.find(o => o.value === resign.resign_comment)?.label)
		temp.push(resign?.manual_resign_comment)
		temp.push(resign?.resign_tags?.length ? resign.resign_tags
			.map(tagId => cleanTagOptions.find(t => t.value === tagId)?.label).join(', ')
			: '----')

		result.push(temp);
	})

	return result;
};
