import React, { useEffect, useRef, useState } from "react";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { SMS_TEMPLATE_TYPES } from "@/common/constants/CC";
import {
  findSMSByFilter,
  getNumbersFromCloudTalk,
} from "@/API/repositories/calling";
import moment from "moment";
import Loading from "@/common/components/Loading";
import SmsSearchTable from "./components/smsSearchTable/SmsSearchTable";
import { Flex, SearchBar, Wrapper } from "./SMSSearch.styled";
import ActionButton from "@/common/components/buttons/ActionButton";

const SMSSearch = () => {
  const [data, setData] = useState();
  const [selectedCallTypes, setSelectedCallTypes] = useState();
  const [selectedCCNumbers, setSelectedCCNumbers] = useState();
  const [CCNumberOptions, setCCNumberOptions] = useState();

  const numberRef = useRef();
  const startedAtFromRef = useRef();
  const startedAtToRef = useRef();

  const handleCallingSearch = async (e) => {
    e.preventDefault();

    const payload = Object();
    payload.numbers =
      selectedCCNumbers?.map((number) => number.label).length > 0
        ? selectedCCNumbers?.map((number) => number.label)
        : null;
    payload.type =
      selectedCallTypes?.map((consultant) => consultant.value).length > 0
        ? selectedCallTypes?.map((consultant) => consultant.value)
        : null;
    payload.startedFrom =
      startedAtFromRef.current?.value || moment().startOf("day");
    payload.startedTo = startedAtToRef.current?.value || moment().endOf("day");
    payload.consultantNumber = numberRef.current?.value || null;

    const responseData = await makeRequest(findSMSByFilter.bind(null, payload));

    if (responseData.data) {
      setData(() => responseData.data);
    }
  };
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleFindData = async () => {
    const numberResponse = await makeRequest(getNumbersFromCloudTalk);

    if (numberResponse.data) {
      setCCNumberOptions(() => numberResponse.data);
    }
  };

  useEffect(() => {
    handleFindData();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(findSMSByFilter) && <Loading />}
      <SearchBar>
        <h3>SMS Search:</h3>
        <div>
          <Flex>
            <div>
              <SelectInput
                name="CC Number"
                width={120}
                multiple={true}
                selected={selectedCCNumbers}
                options={CCNumberOptions}
                setSelected={setSelectedCCNumbers}
              />
              <SelectInput
                name="Type"
                width={120}
                multiple={true}
                selected={selectedCallTypes}
                options={SMS_TEMPLATE_TYPES}
                setSelected={setSelectedCallTypes}
              />
            </div>
            <div>
              <Input
                inputRef={numberRef}
                name="Number"
                width={160}
                placeholder="without prefix"
              />
              <Input
                inputRef={startedAtFromRef}
                type="date"
                name="Sent At (from)"
                width={160}
              />
              <Input
                inputRef={startedAtToRef}
                type="date"
                name="Sent At (to)"
                width={160}
              />
            </div>
          </Flex>
          <ActionButton
            onClick={(e) => handleCallingSearch(e)}
            style={{ float: "right", marginRight: "10px" }}
            defaultText="Search"
          />
        </div>
      </SearchBar>
      {data && <SmsSearchTable data={data} />}
    </Wrapper>
  );
};

export default SMSSearch;
