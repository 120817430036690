import React, { useEffect, useState } from "react";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Wrapper } from "./FilterTable.styled";


const FilterTable = ({ rows, columns, howManyToShow = 50 }) => {
  const [page, setPage] = useState(1);
  const [currentRows, setCurrentRows] = useState([]);
  const [sortInfo, setSortInfo] = useState({});

  const changePage = (e, number) => {
    e.preventDefault();
    setPage((prev) => prev + number);
  };

  const handleSort = (e, index) => {
    e.preventDefault();
    const decision = {};

    const filed = columns[index];

    decision.isAscending = !sortInfo.isAscending;

    if (filed.value.type === "string") {
      rows = rows.sort((a, b) => {
        const current = a.data[index] ? a.data[index] : "";
        const next = b.data[index] ? b.data[index] : "";

        return sortInfo.isAscending
          ? current?.replace(" ", "").localeCompare(next?.replace(" ", ""))
          : next?.replace(" ", "").localeCompare(current?.replace(" ", ""));
      });
    }

    if (filed.value.type === "number") {
      rows = rows.sort((a, b) => {
        const current = a.data[index] ? a.data[index] : 0;
        const next = b.data[index] ? b.data[index] : 0;

        return sortInfo.isAscending ? current - next : next - current;
      });
    }
    if (filed.value.type === "date") {
      rows = rows.sort((a, b) => {
        const from = a.data[index] ? new Date(a.data[index])?.getTime() : 0;
        const to = b.data[index] ? new Date(b.data[index])?.getTime() : 0;

        return sortInfo.isAscending ? from - to : to - from;
      });
    }

    decision.index = index;
    setSortInfo(() => decision);
    setCurrentRows(
      rows.slice((page - 1) * howManyToShow, page * howManyToShow)
    );
  };

  const handleSetPage = (e) => {
    e.preventDefault();
    let toSetPage = parseInt(e.target.value);
    if (toSetPage < 1) {
      toSetPage = 1;
    } else if (toSetPage > Math.ceil(rows.length / howManyToShow)) {
      toSetPage = Math.ceil(rows.length / howManyToShow);
    }

    setPage(() => toSetPage);
  };

  useEffect(() => { }, [rows]);

  useEffect(() => {
    setCurrentRows(
      rows.slice((page - 1) * howManyToShow, page * howManyToShow)
    );
  }, [page]);

  return (
    <Wrapper>
      {rows ? (
        <div className="styled-table-2 tableWrap">
          <div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "20px", margin: "20px 0" }}
            >
              <h5>
                <strong>Total:</strong> {rows.length}
              </h5>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                {columns
                  .map((element) => element.label)
                  .map((column, i) => {
                    return (
                      <th
                        key={column}
                        onClick={(e) => handleSort(e, i)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {column}
                        {sortInfo.index === i ? (
                          sortInfo.isAscending ? (
                            <i
                              className="fa fa-sort-up"
                              style={{ fontSize: "20px", marginLeft: "5px" }}
                            />
                          ) : (
                            <i
                              className="fa fa-sort-down"
                              style={{ fontSize: "20px", marginLeft: "5px" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    );
                  })}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, i) => {
                return (
                  <tr key={i}>
                    {row.data.map((element, i) => (
                      <td key={Math.random() + "-" + element + "-" + i}>
                        {element ? element : "brak"}
                      </td>
                    ))}
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window
                            .open(`/dashboard/order/${row._id}`, "_blank")
                            .focus();
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: "30px" }}
          >
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <h5>
                <strong>Total:</strong> {rows.length}
              </h5>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <ActionButton
                onClick={(e) => changePage(e, -1)}
                defaultText="<"
                disabled={page <= 1}
                style={{ padding: '8px 16px' }}
              />
              <input
                style={{ width: "60px", height: "40px" }}
                type="number"
                value={page}
                onChange={(e) => handleSetPage(e)}
              />
              <ActionButton
                onClick={(e) => changePage(e, 1)}
                defaultText=">"
                disabled={page > Math.floor(rows.length / howManyToShow)}
                style={{ padding: '8px 16px' }}
              />
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </Wrapper>
  );
};

export default FilterTable;
