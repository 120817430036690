import React, { useState } from "react";
import {
  DataWrapper,
  Title,
  TitleIcon,
  GamifiedStatisticsItemWrapper,
  GamifiedStatisticsWrapper,
  HoverRegulation,
} from "../../Gamification.styled";
import Orders from "./components/orders/Orders";
import CallReview from "./components/callReview/CallReview";
import PackageDelivery from "./components/packageDelivery/PackageDelivery";
import { TABS } from "../../constants/tabs";
import SumUp from "./components/sumUp/SumUp";
import { SumUpOpener } from "./User.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const User = ({
  userData,
  selectedTab,
  sumUpData,
  gamificationConfig,
}) => {
  const { average_listening_score } = userData;
  const [isSumUpOpen, setIsSumUpOpen] = useState(false);

  const { translation } = useTranslationContext();

  return (
    <>
      <DataWrapper
        visibilityHidden={selectedTab !== TABS.user}
        selectedTop={false}
      >
        <Title>
          <span>Master of Sales</span>
          <HoverRegulation
            regulation={gamificationConfig.gamification_regulation}
          >
            <TitleIcon className="fa fa-circle-info" />
          </HoverRegulation>
        </Title>
        <SumUpOpener onClick={() => setIsSumUpOpen(!isSumUpOpen)}>
          {translation.sum_up}
          <i
            className={isSumUpOpen ? "fa fa-chevron-up" : "fa fa-chevron-down"}
          />
        </SumUpOpener>
        <GamifiedStatisticsWrapper>
          <GamifiedStatisticsItemWrapper
            width={!!average_listening_score ? "40%" : "60%"}
          >
            <Orders
              regulation={gamificationConfig.orders_regulation}
              userData={userData}
            />
          </GamifiedStatisticsItemWrapper>
          {!!average_listening_score && (
            <GamifiedStatisticsItemWrapper width="25%">
              <CallReview
                regulation={gamificationConfig.listening_regulation}
                userData={userData}
              />
            </GamifiedStatisticsItemWrapper>
          )}
          <GamifiedStatisticsItemWrapper
            width={!!average_listening_score ? "35%" : "40%"}
          >
            <PackageDelivery
              regulation={gamificationConfig.delivery_regulation}
              userData={userData}
            />
          </GamifiedStatisticsItemWrapper>
        </GamifiedStatisticsWrapper>
      </DataWrapper>
      <SumUp
        regulation={gamificationConfig.sum_up_regulation}
        isSumUpOpen={isSumUpOpen}
        sumUpData={sumUpData}
      />
    </>
  );
};

export default User;
