import { deleteInvoiceById, searchInvoices } from "@/API/repositories/tickets";
import { findQueuesByMarketId } from "@/common/functions/findQueuesByMarketId";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useState, useEffect } from "react";
import InvoiceEdit from "@/pages/admin/invoices/components/invoiceEdit/InvoiceEdit";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { PRIORITY_SORT } from "@/common/constants/priorities";
import Loading from "@/common/components/Loading";
import InvoicesSearchBar from "./components/invoicesSearchBar/InvoicesSearchBar";
import { Wrapper } from "../claimNotEnded/claimNotEnded.styles";
import { INVOICE_STATUSES_OPTIONS } from "./constants/statusOptions";
import InvoicesTable from "./components/invoicesTable/InvoicesTable";

const Invoices = () => {
  const [edit, setEdit] = useState();
  const [data, setData] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedStatus, setSelectedStatus] = useState(
    INVOICE_STATUSES_OPTIONS[0]
  );

  const {
    options: { productsOptions, marketsOptions },
    commonData: { queues },
  } = useCommonDataContext();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleChangeMarket = (marketOption) => {
    const filteredQueues = findQueuesByMarketId(marketOption.value, queues);

    setSelectedProduct(() =>
      productsOptions.find((productOption) =>
        filteredQueues.some((queue) => queue.product === productOption.value)
      )
    );

    setSelectedMarket(() => marketOption);
  };

  const handleSearch = async (e) => {
    e?.preventDefault();

    if (!selectedProduct || !selectedStatus)
      return addMessage("Select all required data");

    const payload = {};
    payload.product = selectedProduct.value;
    payload.status = selectedStatus.value;

    const response = await makeRequest(searchInvoices.bind(null, payload));

    if (response.data) {
      if (edit) {
        setEdit(() => response.data.find((res) => res._id === edit._id));
      }

      const visibleData = response.data
        .sort(
          (elementA, elementB) =>
            new Date(elementA._order.shipping?.status_delivered_at).getTime() -
            new Date(elementB._order.shipping?.status_delivered_at).getTime()
        )
        .sort(
          (a, b) =>
            PRIORITY_SORT[a.invoice.priority] -
            PRIORITY_SORT[b.invoice.priority]
        );

      setData(() => visibleData);
    }
  };

  const handleDeleteInvoice = async (invoice) => {
    const response = await makeRequest(
      deleteInvoiceById.bind(null, invoice._id)
    );

    if (response.data) {
      addMessage("Deleted", "success");
      handleSearch();
    } else {
      addMessage("Error", "error");
    }
  };

  useEffect(() => {
    if (!!marketsOptions?.length) {
      handleChangeMarket(marketsOptions[0]);
    }
  }, [marketsOptions]);

  return (
    <Wrapper>
      {hasUnfilledRequest(searchInvoices) && <Loading />}
      <InvoicesSearchBar
        selectedMarket={selectedMarket}
        setSelectedMarket={setSelectedMarket}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleSearch={handleSearch}
      />
      <InvoicesTable
        data={data}
        selectedStatus={selectedStatus}
        setEdit={setEdit}
        handleDelete={handleDeleteInvoice}
      />
      {edit && (
        <InvoiceEdit
          setShow={setEdit}
          data={edit}
          handleSearch={handleSearch}
        />
      )}
    </Wrapper>
  );
};

export default Invoices;
