import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';


export const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
`;

export const CardWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

export const OrdersWrapper = styled.div`
	width: 1000px;
`;

export const Title = styled.h3`
	margin-bottom: 30px;
	color: ${Colors.darkBlue};
`;

export const TableWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
