import React from "react";
import styled from "styled-components";
import InfoBar from "./pages/consultant/infobar/InfoBar";
import Navbar from "./pages/consultant/dashboard/common/navbar/Navbar";

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 100vw;
`;

const ConsultantDashboardView = ({ children }) => {
  return (
    <Wrapper>
      <Navbar />
      <div style={{ width: "calc(100vw - 60px)" }}>
        <InfoBar />
        {children}
      </div>
    </Wrapper>
  );
};

export default ConsultantDashboardView;
