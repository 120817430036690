import React, { useContext, useState } from "react";
import styled from "styled-components";
import PopUp from "../../../common/components/PopUp";
import Buttons from "../calling-vonage/buttons/Buttons";
import Comments from "../calling-vonage/comments/Comments";
import ConsultantScript from "../calling-vonage/consultantScript/ConsultantScript";
import MoreInfo from "../calling/moreInfo/MoreInfo";
import Order from "../calling-vonage/order/Order";
import Orders from "../calling-vonage/orders/Orders";
import TicketsForCalling from "../calling-vonage/tickets/TicketsForCalling";
import ContactOutbound from "./outbound/ContactOutbound";
import ReCallInfo from "./ReCallInfo";
import ReCalling from "./ReCalling";
import WrapTimeCounter from "./timer/WrapTimeCounter";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import SupportMail from "@/common/components/supportMail/SupportMail";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MAX_WRAP_SECONDS = 60 * 4;

const OutboundView = ({
  handleEndCall,
  isTalking = true,
  setCountWrapTime,
  countWrapTime,
  handleWrappTimeClear,
}) => {
  const {
    currentContactOutbound,
    callOneceAgain,
    callingClientOnceAgain,
    setCallingClientOnceAgain,
    showResultReCall,
    setShowResultReCall,
  } = useContext(CallCRMApiContextVonage);

  const formatOrderVariantsToDisplay = () =>
    currentContactOutbound?.order_items?.map((orderItem, i) => ({
      _id: i + 1,
      name: currentContactOutbound._product.variants.find(
        (variant) => variant.short === orderItem.product_variant
      )?.name,
      price: orderItem.price,
      value: currentContactOutbound._product.variants.find(
        (variant) => variant.short === orderItem.product_variant
      )?.short,
    })) || [];

  const [chosenProductData, setChosenProductData] = useState(
    formatOrderVariantsToDisplay()
  );
  const [shouldDisableSuspicion, setShouldDisableSuspicion] = useState(
    currentContactOutbound?.shouldDisableSuspicion
  );
  const [showScript, setShowScript] = useState();
  const [isCallAgainDisabled, setIsCallAgainDisable] = useState(false);

  const [contact, setContact] = useState(
    currentContactOutbound._contact || null
  );
  const [comments, setComments] = useState(
    currentContactOutbound._comments || []
  );
  const [market, setMarket] = useState(currentContactOutbound._market || null);
  const { translation } = useTranslationContext();

  return (
    <>
      <PopUp closeIcon={false} canOffByClickingBackground={false}>
        <Wrapper>
          <div
            style={{
              padding: "30px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <MoreInfo
              product={currentContactOutbound._product.short}
              email={contact.email}
              sendMail={translation.sendMail}
              order={currentContactOutbound}
              mailError={translation.mailError}
              mailSuccess={translation.mailSuccess}
            />
            <SupportMail order={currentContactOutbound} />
            <TicketsForCalling
              market={market}
              orderId={currentContactOutbound._id.toString()}
            />
            <ActionButton
              onClick={() => setShowScript(() => true)}
              defaultText="Script"
            />
            {isTalking && (
              <ActionButton
                color={Colors.red}
                onClick={(e) => {
                  setIsCallAgainDisable(() => true);
                  setTimeout(() => {
                    setIsCallAgainDisable(() => false);
                  }, 8000);
                  handleEndCall();
                }}
                defaultText="End Call"
              />
            )}
            {!isTalking && (
              <ActionButton
                color={Colors.red}
                onClick={(e) => {
                  setCountWrapTime(() => false);
                  setIsCallAgainDisable(() => true);
                  setTimeout(() => {
                    setIsCallAgainDisable(() => false);
                  }, 8000);
                  callOneceAgain();
                }}
                disabled={isCallAgainDisabled}
                defaultText="Call Again"
              />
            )}
            {countWrapTime && (
              <WrapTimeCounter
                initTime={MAX_WRAP_SECONDS}
                onTimeEnd={handleWrappTimeClear}
              />
            )}
          </div>
          <ContactOutbound
            shouldDisableSuspicion={shouldDisableSuspicion}
            setShouldDisableSuspicion={setShouldDisableSuspicion}
            market={market}
            contact={contact}
            setContact={setContact}
          />
          <Order
            chosenProductData={chosenProductData}
            setChosenProductData={setChosenProductData}
            product={currentContactOutbound._product}
            contact={contact}
            order={currentContactOutbound}
          />
          <Buttons
            product={currentContactOutbound._product}
            contact={contact}
            chosenProductData={chosenProductData}
            data={currentContactOutbound}
          />
          <Orders
            orders={currentContactOutbound._orders || []}
            currentOrder={currentContactOutbound}
          />
          <Comments
            comments={comments}
            contactId={currentContactOutbound._contact._id}
            setComments={setComments}
          />
        </Wrapper>
      </PopUp>
      {showScript && (
        <ConsultantScript
          setShowScript={setShowScript}
          phoneScript={currentContactOutbound._product.product_script}
        />
      )}
      {callingClientOnceAgain && (
        <ReCalling close={setCallingClientOnceAgain} />
      )}
      {showResultReCall && (
        <ReCallInfo
          showResultReCall={showResultReCall}
          setShowResultReCall={setShowResultReCall}
        />
      )}
    </>
  );
};

export default OutboundView;
