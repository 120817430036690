import React, { useEffect } from "react";
import { useAdminDashboardContext } from "../../../common/hooks/adminDashboardHook";
import Circle from "../../../common/components/Circle";
import SettingsForm from "@/pages/admin/dashboard/components/settings/SettingsForm";
import {
  CircleWrapper,
  SettingsIcon,
  SpaceBetween,
  Wrapper,
} from "./dashboard.styles";
import { findDashboardView } from "./helpers/findDashboardView";

const Dashboard = () => {
  const {
    loadData,
    dashboardSettings,
    setShowDashboardSettings,
    showDashboardSettings,
  } = useAdminDashboardContext();

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <SpaceBetween>
        <Wrapper>
          {dashboardSettings?.visible_views.map(({ name }, i) =>
            findDashboardView(name) ? (
              <div key={i}>{findDashboardView(name)[name]}</div>
            ) : null
          )}
          <SettingsIcon
            onClick={() => setShowDashboardSettings(true)}
            className="animation-scale fa-solid fa-gear"
          />
        </Wrapper>
        {dashboardSettings && (
          <CircleWrapper>
            <Circle
              refreshTime={dashboardSettings?.refresh_time_seconds}
              getData={loadData}
            />
          </CircleWrapper>
        )}
      </SpaceBetween>
      {showDashboardSettings && <SettingsForm />}
    </>
  );
};

export default Dashboard;
