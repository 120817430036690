import CSV from "@/common/components/CSV";
import { getCSVDataForShortInfo } from "./getCSVDataForShortInfo";
import { HeaderTitleWrapper } from "../ShortInfoDataTable.styled";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelData } from "./getExcelData";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import moment from "moment";

export const getHeadersArray = (data, handleSend, reportConfirmed) => [
  [
    <th colSpan={6}>
      <HeaderTitleWrapper>
        <div>Contract type: {data.name} </div>
        <CSV
          color={"white"}
          header={[
            "TYP",
            "IMIĘ I NAZWISKO",
            "OKRES",
            "ROZLICZENIA",
            "ILOSC PRZEPRACOWANYCH GODZIN",
            "BONUS BRUTTO",
          ]}
          data={getCSVDataForShortInfo(data.value)}
          filename={`paymentkdr.${data.name}.csv`}
        />
        <ExportExcel
          excelData={getExcelData(data.value)}
          fileName={`paymentkdr.${data.name}`}
          color="white"
        />
        <div>
          <ActionButton
            onClick={() => handleSend()}
            color={Colors.darkBlue}
            style={{
              color: "#fff",
              border: "1px solid #fff",
            }}
            defaultText={
              reportConfirmed
                ? `Last created at: ${moment(
                    reportConfirmed.processed_at || reportConfirmed.created_at
                  ).format("DD/MM/2024 HH:mm")}`
                : "Send excel in mail"
            }
          />
        </div>
      </HeaderTitleWrapper>
    </th>,
  ],
  [
    <th>TYP</th>,
    <th>IMIĘ I NAZWISKO</th>,
    <th colSpan={2}>OKRES ROZLICZENIA</th>,
    <th>ILOSC PRZEPRACOWANYCH GODZIN</th>,
    <th>BONUS BRUTTO</th>,
  ],
];
