import moment from "moment";
import React, { useRef, useState } from "react";
import {
  addMessageToTicket,
  getTicketsByFilter,
  uploadFileToQuestionTicket,
} from "../../../API/repositories/tickets";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { Wrapper } from "./Tickets.styled";
import TicketsSearchBar from "./components/ticketsSearchBar/TicketsSearchBar";
import TicketsSearchTable from "./components/ticketsSearchTable/TicketsSearchTable";
import TicketCommunication from "./components/ticketCommunication/TicketCommunication";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const Tickets = () => {
  const [selectedConsultantsSenders, setSelectedConsultantsSenders] =
    useState();
  const [selectedConsultantsRecivers, setSelectedConsultantsRecivers] =
    useState();

  const [selectedTicketStatuses, setSelectedTicketStatuses] = useState();

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState();
  const [messageSucces, setMessageSuccess] = useState();
  const [messages, setMessages] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const {
    addMessage,
  } = useMessageQueueContext();

  const textareaRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const fileRef = useRef();

  const sendFile = async () => {
    const formData = new FormData();

    formData.append("file", fileRef.current?.files[0]);
    fileRef.current.value = "";

    const response = await makeRequest(
      uploadFileToQuestionTicket.bind(null, formData, messages._id)
    );

    if (response.data) {
      addMessage("Success", "success");
      await hadnleGetConsultantTickets();
    }
  };

  const handleAddCommentToTicket = async (e) => {
    e && e?.preventDefault();

    const payload = {};
    payload.message = textareaRef.current.value;

    const response = await makeRequest(
      addMessageToTicket.bind(null, messages._id, payload)
    );

    if (response.data) {
      setMessageSuccess(() => true);
      setTimeout(() => {
        setMessageSuccess(false);
      }, [3000]);
      await hadnleGetConsultantTickets();
      textareaRef.current.value = "";
    }
  };

  const hadnleGetConsultantTickets = async (e) => {
    e && e.preventDefault();
    setDataToDisplay(() => null);

    const payload = {};

    payload.types = selectedTypes.map((type) => type.value);

    payload.statuses = selectedTicketStatuses?.map((status) => status.value);
    payload.senders = selectedConsultantsSenders?.map((sender) => sender.value);
    payload.recivers = selectedConsultantsRecivers?.map(
      (reciver) => reciver.value
    );

    payload.from = moment(fromRef.current?.value).startOf("day");
    payload.to = moment(toRef.current?.value).endOf("day");

    const response = await makeRequest(getTicketsByFilter.bind(null, payload));

    if (response.data && response.data.length > 0) {
      if (messages) {
        setMessages(() => response.data.find((m) => m._id === messages._id));
      }
      setDataToDisplay(() =>
        response.data.sort((a, b) => a.type.localeCompare(b.type))
      );
    }
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(getTicketsByFilter, uploadFileToQuestionTicket) && (
        <Loading />
      )}
      <TicketsSearchBar
        hadnleGetConsultantTickets={hadnleGetConsultantTickets}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        fromRef={fromRef}
        toRef={toRef}
        setSelectedTicketStatuses={setSelectedTicketStatuses}
        selectedTicketStatuses={selectedTicketStatuses}
        setSelectedConsultantsSenders={setSelectedConsultantsSenders}
        selectedConsultantsSenders={selectedConsultantsSenders}
        setSelectedConsultantsRecivers={setSelectedConsultantsRecivers}
        selectedConsultantsRecivers={selectedConsultantsRecivers}
      />
      {dataToDisplay && (
        <TicketsSearchTable
          setMessages={setMessages}
          dataToDisplay={dataToDisplay}
        />
      )}
      {messages && (
        <TicketCommunication
          setMessages={setMessages}
          messages={messages}
          fileRef={fileRef}
          sendFile={sendFile}
          handleAddCommentToTicket={handleAddCommentToTicket}
          textareaRef={textareaRef}
          messageSucces={messageSucces}
        />
      )}
    </Wrapper>
  );
};

export default Tickets;
