import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import React, { useRef, useState } from "react";
import { ButtonWrapper } from "../../../../ValidInvoices.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import {
  InvoiceEditableData,
  Textarea,
  TextareaLabel,
  TextareaWrapper,
} from "./ValidInvoiceEdit.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  acceptValidInvoice,
  generateValidInoicePreview,
  updateValidInvoiceTicket,
} from "@/API/repositories/tickets";
import InvoicePreview from "../../../../common/components/invoicePreview/InvoicePreview";
import { Icon, OrderNavigateWrapper } from "../../ValidInvoicesTable.styled";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
const pako = require("pako");

const ADDRESS_LINE_LENGTH = 60;

const ValidInvoiceEdit = ({
  setSelectedTicket,
  selectedTicket,
  handleSearch,
  isRestricted,
}) => {
  const [updateColor, setUpdateColor] = useState(Colors.orange);
  const [generateColor, setGenerateColor] = useState(Colors.orange);
  const [pdfSource, setPdfSource] = useState();
  const [showWarning, setShowWarning] = useState();

  const issueDateRef = useRef();
  const companyNameRef = useRef();
  const fullNameRef = useRef();
  const cityRef = useRef();
  const postalCodeRef = useRef();
  const streetRef = useRef();
  const dynamicQuantityRef = useRef();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleUpdateValidInvoice = async () => {
    const payload = {};

    payload.street = streetRef.current.value;
    payload.postal_code = postalCodeRef.current.value;
    payload.city = cityRef.current.value;
    payload.company_name = companyNameRef.current?.value;
    payload.issue_date = issueDateRef.current.value;
    payload.dynamic_quantity = dynamicQuantityRef.current.checked;
    payload.ticketId = selectedTicket._id;
    payload.full_name = fullNameRef.current?.value;

    const response = await makeRequest(
      updateValidInvoiceTicket.bind(null, payload)
    );

    if (!response?.data) {
      setUpdateColor(() => Colors.red);
      setTimeout(() => {
        setUpdateColor(() => Colors.orange);
      }, 3000);

      return addMessage("Couldn't update", "error");
    }

    await handleSearch();
    setUpdateColor(() => Colors.green);
    setTimeout(() => {
      setUpdateColor(() => Colors.orange);
    }, 3000);
    addMessage("Success", "success");
  };

  const handleGenerateValidInvoicePreview = async () => {
    const payload = {};

    if (companyNameRef.current?.value?.length > ADDRESS_LINE_LENGTH) {
      return addMessage("Too long company name", "error");
    }

    if (streetRef.current.value?.length > ADDRESS_LINE_LENGTH) {
      return addMessage("Too long street name", "error");
    }

    if (
      cityRef.current?.value.length >
      ADDRESS_LINE_LENGTH - postalCodeRef.current?.value.length
    ) {
      return addMessage("Too long city name", "error");
    }

    payload.street = streetRef.current.value;
    payload.postal_code = postalCodeRef.current.value;
    payload.city = cityRef.current.value;
    payload.company_name = companyNameRef.current?.value;
    payload.full_name = fullNameRef.current?.value;
    payload.issue_date = issueDateRef.current.value;
    payload.dynamic_quantity = dynamicQuantityRef.current.checked;
    payload.ticketId = selectedTicket._id;
    payload.sold_date =
      selectedTicket._order.shipping?.status_delivered_at ||
      selectedTicket._order.shipping?.status_delivered_no_payment_at ||
      selectedTicket._order.payment?.find((p) => p.status_complated_date)
        ?.status_complated_date;

    const response = await makeRequest(
      generateValidInoicePreview.bind(null, payload)
    );

    if (!response?.data?.data) {
      setGenerateColor(() => Colors.red);
      setTimeout(() => {
        setGenerateColor(() => Colors.orange);
      }, 3000);

      return addMessage("Couldn't generate", "error");
    }

    const uint8Array = new Uint8Array(response.data.data);

    const decompressed = pako.inflate(uint8Array);

    const blob = new Blob([decompressed], {
      type: "application/pdf",
    });

    const url = URL.createObjectURL(blob);

    setPdfSource(() => url);

    await handleSearch();
    setGenerateColor(() => Colors.green);
    setTimeout(() => {
      setGenerateColor(() => Colors.orange);
    }, 3000);
    addMessage("Success", "success");
  };

  const handleAcceptValidInoice = async () => {
    setShowWarning(() => false);

    const response = await makeRequest(
      acceptValidInvoice.bind(null, selectedTicket._id)
    );

    if (!response.data) {
      return addMessage("Something went wrong", "error");
    }

    await handleSearch();
    addMessage("Success", "success");
    setPdfSource(() => null);
    setSelectedTicket(() => null);
  };

  return (
    <PopUp padding="4px 20px 14px 20px" setShow={setSelectedTicket}>
      <OrderNavigateWrapper>
        <Icon
          onClick={() =>
            window.open(`/dashboard/order/${selectedTicket.order}`, "_blank")
          }
          className="fa fa-truck"
        />
      </OrderNavigateWrapper>
      <InvoiceEditableData>
        <Input
          type="date"
          name="Issue date"
          color={Colors.darkBlue}
          inputRef={issueDateRef}
          width={180}
          inputWidth={500}
          value={formatToDateTamplate(selectedTicket.valid_invoice.issue_date)}
        />
        {selectedTicket.valid_invoice.company_name && (
          <Input
            value={selectedTicket.valid_invoice.company_name}
            color={Colors.darkBlue}
            width={180}
            inputWidth={500}
            inputRef={companyNameRef}
            name="Company name"
          />
        )}
        {selectedTicket.valid_invoice.full_name && (
          <Input
            value={selectedTicket.valid_invoice.full_name}
            color={Colors.darkBlue}
            width={180}
            inputWidth={500}
            inputRef={fullNameRef}
            name="Customer name"
          />
        )}
        {selectedTicket.valid_invoice.company_address && (
          <Input
            value={selectedTicket.valid_invoice.company_address}
            color={Colors.darkBlue}
            inputWidth={500}
            width={180}
            inputRef={streetRef}
            name="Address"
            disabled
          />
        )}
        <Input
          value={selectedTicket.valid_invoice.street}
          color={Colors.darkBlue}
          inputWidth={500}
          width={180}
          inputRef={streetRef}
          name="Street and number"
        />
        <Input
          value={selectedTicket.valid_invoice.postal_code}
          color={Colors.darkBlue}
          inputWidth={500}
          width={180}
          inputRef={postalCodeRef}
          name="Postal code"
        />{" "}
        <Input
          value={selectedTicket.valid_invoice.city}
          color={Colors.darkBlue}
          inputWidth={500}
          width={180}
          inputRef={cityRef}
          name="City"
        />
        {!selectedTicket.valid_invoice.full_name && (
          <Input
            value={
              selectedTicket.valid_invoice.vat_number ||
              selectedTicket.valid_invoice.company_number
            }
            color={Colors.darkBlue}
            inputWidth={500}
            width={180}
            name={
              selectedTicket.valid_invoice.vat_number
                ? "Vat number"
                : "Company num"
            }
            disabled
          />
        )}
        <Input
          inputRef={dynamicQuantityRef}
          checked={selectedTicket.valid_invoice.dynamic_quantity}
          id="quantity"
          type="checkbox"
          height={20}
          inputWidth={20}
          width={180}
          color={Colors.darkBlue}
          name="Dynamic quantity"
        />
        <TextareaWrapper>
          {!!selectedTicket.valid_invoice.message[0]?.original?.length && (
            <>
              <TextareaLabel>Comment: </TextareaLabel>
              <Textarea
                disabled
                cols={55}
                value={selectedTicket.valid_invoice.message[0].original}
              />
            </>
          )}
        </TextareaWrapper>
      </InvoiceEditableData>
      <ButtonWrapper>
        <ActionButton
          color={updateColor}
          defaultText="Save"
          onClick={() => handleUpdateValidInvoice()}
        />
        <ActionButton
          color={generateColor}
          defaultText="Generate preview"
          onClick={() => handleGenerateValidInvoicePreview()}
          disabled={isRestricted}
        />
      </ButtonWrapper>
      {pdfSource && (
        <InvoicePreview
          showWarning={showWarning}
          setShowWarning={setShowWarning}
          pdfSource={pdfSource}
          setPdfSource={setPdfSource}
          handleAccept={handleAcceptValidInoice}
        />
      )}
    </PopUp>
  );
};

export default ValidInvoiceEdit;
