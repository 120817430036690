import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 30px;
`;

export const Flex = styled.div`
	display: flex;
`;

export const DataContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 30px;
	justify-content: center;
	flex-wrap: wrap;
`;

export const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 20px;
`;
