import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  findTranslation,
  updateTranslation,
} from "../../../API/repositories/translations";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import Table from "../../../common/components/Table";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px;
  gap: 50px;
`;

const Dictionary = () => {
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [translations, setTranslations] = useState(null);

  const [data, setData] = useState();
  const translationRef = useRef();
  const labelRef = useRef();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleShowTranslations = (e, trasnlations) => {
    e.preventDefault();
    setTranslations(() => trasnlations);
  };

  const handleSetEdit = (e, trasnlation) => {
    e.preventDefault();
    setEditData(() => trasnlation);
    setEdit(() => true);
  };

  const handleGetTranslation = async () => {
    const text = translationRef.current?.value;
    const response = await makeRequest(findTranslation.bind(null, text));

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleLabelUpdate = async (e) => {
    e.preventDefault();
    const id = editData._id;
    const label = labelRef.current?.value;

    const response = await makeRequest(updateTranslation.bind(null, id, label));

    if (response.data) {
      setData(() => null);
      setEdit(() => false);
      addMessage("Saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const headers = ["No", "Label", "Value", "Lang", "Actions"];
  const raws = useMemo(
    () =>
      data?.map((item, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{item.label}</td>
          <td>{item.value}</td>
          <td>{item.lang}</td>
          <td>
            <i
              className="fas fa-edit"
              style={{
                color: "black",
                cursor: "pointer",
              }}
              onClick={(e) => handleShowTranslations(e, item.translations)}
            ></i>
          </td>
        </tr>
      )),
    [data]
  );

  const translationHeaders = ["No", "Label", "Value", "Lang", "Actions"];
  const translationRaws = useMemo(() => {
    if (translations) {
      return translations.map((translation, i) => (
        <tr>
          <td>{i + 1}</td>
          <td>{translation.label}</td>
          <td>{translation.value}</td>
          <td>{translation.lang}</td>
          <td>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "14px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={(e) => handleSetEdit(e, translation)}
            ></i>
          </td>
        </tr>
      ));
    }
    return null;
  }, [translations]);

  return (
    <Wrapper>
      {hasUnfilledRequest(findTranslation) && <Loading />}
      <div style={{ display: "flex", gap: "30px" }}>
        <input
          ref={translationRef}
          style={{ width: "600px" }}
          placeholder="type translation"
        />
        <ActionButton
          onClick={(e) => handleGetTranslation(e)}
          defaultText="Search"
        />
      </div>

      {data?.length > 0 && (
        <Table raws={raws} headers={headers} className="styled-table" />
      )}

      {translations?.length && (
        <PopUp setShow={setTranslations}>
          <Table
            style={{ width: "100%", marginTop: 20 }}
            raws={translationRaws}
            headers={translationHeaders}
            className="styled-table"
          />
        </PopUp>
      )}

      {edit && (
        <PopUp padding="10px 25px" setShow={setEdit}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <div style={{ display: "flex", gap: "50px" }}>
              <h4>Old Label:</h4> <h4>{editData.label}</h4>
            </div>
            <div style={{ display: "flex", gap: "50px" }}>
              <h4>New label:</h4> <input ref={labelRef} />
            </div>
            <ActionButton
              onClick={(e) => handleLabelUpdate(e)}
              defaultText="Save"
            />
          </div>
        </PopUp>
      )}
    </Wrapper>
  );
};

export default Dictionary;
