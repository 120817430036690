export const HEADERS_ARRAY = [
	[
		<th colSpan={4}>Mail Tests</th>
	],
	[
		<th>No.</th>,
		<th>Name</th>,
		<th>Templates</th>,
		<th>Actions</th>,
	]
];
