import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { ARITHMETIC_OPTIONS } from "../../../../constants/options";
import { BorderLine, ColorTitle, OptionWrapper } from "../../../../FilterOptons.styled";

export const FilterOptionFinderNumber = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      value: value,
      type_field: "Number",
      arithmetic: filters[fieldData.label]?.arithmetic,
    });
  };

  const handleOnChangeArithmetic = (value) => {
    return dispatchFilters({
      type: "add",
      key: fieldData.label,
      arithmetic: value,
      value: filters[fieldData.label]?.value,
      type_field: "Number",
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={ARITHMETIC_OPTIONS}
        showLabel={false}
        selected={ARITHMETIC_OPTIONS.find(
          (e) => e.value === filters[fieldData.label]?.arithmetic?.value
        )}
        setSelected={(e) => handleOnChangeArithmetic(e)}
      />
      <Input
        showLabel={false}
        type="number"
        value={filters[fieldData.label]?.value}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </OptionWrapper>
  );
};