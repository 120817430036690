import PopUp from "@/common/components/PopUp";
import React from "react";
import {
  FileUploadWrapper,
  Icon,
  MessagesBox,
  TitlePopUp,
  UserWrapper,
} from "../../Tickets.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import moment from "moment";
import {
  Form,
  MessageDate,
  MessageOriginal,
  MessageWrapper,
  Textarea,
} from "./TicketCommunication.styled";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { Colors } from "@/common/colors/colors";

const TicketCommunication = ({
  setMessages,
  messages,
  fileRef,
  sendFile,
  handleAddCommentToTicket,
  textareaRef,
  messageSucces,
}) => {
  const {
    options: { usersOptions },
  } = useCommonDataContext();

  return (
    <PopUp setShow={setMessages}>
      <TitlePopUp>Conversation with:</TitlePopUp>
      <MessagesBox>
        {messages[messages.type].message
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          .map((message) => (
            <div>
              <MessageWrapper>
                <UserWrapper>
                  <td>
                    {
                      usersOptions?.find(
                        (user) => message.sender === user.value
                      )?.label
                    }
                  </td>
                </UserWrapper>
                <MessageOriginal>{message.original}</MessageOriginal>
              </MessageWrapper>
              <MessageDate>
                {moment(message.created_at).format("YYYY-MM-DD HH:MM")}
              </MessageDate>
            </div>
          ))}
      </MessagesBox>
      <FileUploadWrapper>
        <input
          ref={fileRef}
          type="file"
          accept="application/pdf,image/jpg,image/png,image/jpeg"
        />
        <Icon onClick={() => sendFile()} className="fa fa-file-export" />
      </FileUploadWrapper>
      <Form onSubmit={(e) => handleAddCommentToTicket(e)}>
        <Textarea ref={textareaRef} required placeholder="type message..." />
        <SubmitButton
          color={messageSucces ? Colors.green : Colors.orange}
        >
          <span>{messageSucces ? "Sent" : "Send"}</span>
          <i className="fa fa-send" />
        </SubmitButton>
      </Form>
    </PopUp>
  );
};

export default TicketCommunication;
