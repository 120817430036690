export const TYPE_OPTIONS = [
  {
    label: "Inbound",
    value: "incoming",
  },
  {
    label: "Outbound",
    value: "outgoing",
  },
];
