import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

const PREFIX = 'resign-tag';

export const createResignTag = (resignTag) =>
	errorHandler(async () => {
		const data = await api.post(`/${PREFIX}/create`, resignTag);

		return { data: data.data };
	});

export const updateResignTag = (resignTag, id) =>
	errorHandler(async () => {
		const data = await api.patch(`/${PREFIX}/update/${id}`, resignTag);

		return { data: data.data };
	});

export const getResignTags = () =>
	errorHandler(async () => {
		const data = await api.get(`/${PREFIX}/get`);

		return { data: data.data };
	});
