import styled from "styled-components";

export const Form = styled.form`
display: flex;
flex-direction: column;
min-height: 300px;
justify-content: space-between;
align-items: center;
`;

