import React from "react";
import PopUp from "../PopUp";
import ActionButton from "../buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { makeSuspicionDecision } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "../Loading";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const MakeDecision = ({ setShow, ticket, reloadFn }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const { addMessage } = useMessageQueueContext();

  const handleDecision = async (isOkay) => {
    const payload = {};

    payload.isOkay = isOkay;
    payload.ticketId = ticket._id;

    const response = await makeRequest(
      makeSuspicionDecision.bind(null, payload)
    );

    if (!response?.data) {
      return addMessage("Something went wrong!", "error");
    }

    reloadFn();
    setShow(false);
  };

  return (
    <PopUp padding="10px 20px 18px" setShow={setShow}>
      {hasUnfilledRequest(makeSuspicionDecision) && <Loading />}
      <div style={{ display: "flex", gap: "40px", justifyContent: "center" }}>
        <ActionButton
          color={Colors.green}
          defaultText="There was no problem"
          onClick={() => handleDecision(true)}
        />
        <ActionButton
          color={Colors.red}
          defaultText="Was problem on consultant's side"
          onClick={() => handleDecision(false)}
        />
      </div>
    </PopUp>
  );
};

export default MakeDecision;
