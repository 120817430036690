import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { unplugContact } from "../../../../API/repositories/queue";
import Loading from "../../../../common/components/Loading";
import PopUp from "../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../common/hooks/requestHook";
import ActionButton from "@/common/components/buttons/ActionButton";

const EndContact = () => {
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const hadnleHoldingContact = async (e) => {
    e.preventDefault();

    const response = await makeRequest(unplugContact.bind(null, id));

    if (response.data) {
      navigate("/consultant/dashboard");
    } else {
      console.log(response.error);
    }
  };

  return (
    <PopUp canOffByClickingBackground={false}>
      {hasUnfilledRequest(unplugContact) && <Loading />}
      <h3>Nastąpiło przetrzymanie</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          height: "80px",
        }}
      >
        <ActionButton
          defaultText="Wroć do menu"
          onClick={(e) => hadnleHoldingContact(e)}
        />
      </div>
    </PopUp>
  );
};

export default EndContact;
