import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";

const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 20px;
  padding: 0 10px;
  h3 {
    color: ${Colors.darkBlue};
  }
`;

const CallingTopBar = ({
  handleCall,
  setIsCalling,
  handleEndCall,
  isTalkingTicket,
}) => {
  return (
    <Wrapper>
      {isTalkingTicket ? (
        <i
          class="fa-solid fa-phone-slash animation-scale"
          style={{ cursor: "pointer", fontSize: "28px", color: Colors.red }}
          onClick={(e) => handleEndCall(e)}
        />
      ) : (
        <i
          className="fa-solid fa-phone animation-scale"
          style={{ cursor: "pointer", fontSize: "28px", color: Colors.green }}
          onClick={(e) => {
            setIsCalling(() => true);
            handleCall(e);
          }}
        />
      )}
    </Wrapper>
  );
};

export default CallingTopBar;
