import React, { useEffect, useRef, useState } from "react";
import PopUp from "../PopUp";
import SelectInput from "../SelectInput";
import { Colors } from "@/common/colors/colors";
import { ButtonWrapper, Form } from "./Resign.styled";
import { RESIGN_OPTIONS } from "@/common/constants/resignOptions";
import Input from "../Input";
import SubmitButton from "../buttons/SubmitButton";
import { getResignTags } from "@/API/repositories/resign-tag";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "../Loading";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ResignTagForm from "@/pages/admin/resignTag/components/resignTagForm/ResignTagForm";

const ADD_TAG_OPTION = "add_tag";

const Resign = ({ setShow, handleResign }) => {
  const [selectedReason, setSelectedReason] = useState();
  const [tagsOptions, setTagsOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const commentRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadTags = async () => {
    let oldIds;

    if (tagsOptions.length) {
      oldIds = tagsOptions.map((tag) => tag.value);
    }

    const response = await makeRequest(getResignTags);

    if (!response?.data) {
      return addMessage("Error while loading tags", "error");
    }

    const mappedOptions = response.data.map((tag) => ({
      value: tag._id,
      label: <span style={{ color: Colors.darkGray2 }}>{tag.label}</span>,
    }));

    if (oldIds && oldIds.length !== response.data.length) {
      setSelectedTags(
        mappedOptions.filter(
          (option) =>
            !oldIds.includes(option.value) ||
            selectedTags?.some((tag) => tag.value === option.value)
        )
      );
    }

    setTagsOptions(() => mappedOptions);
    return mappedOptions;
  };

  const handleSelectTag = (options) => {
    if (options.some((option) => option.value === ADD_TAG_OPTION)) {
      return setShowAddTag(true);
    }

    setSelectedTags(options);
  };

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <PopUp padding="12px 20px" setShow={setShow}>
      {hasUnfilledRequest(getResignTags) && <Loading />}
      <Form
        onSubmit={(e) =>
          handleResign(
            e,
            selectedReason.value,
            commentRef.current.value,
            selectedTags.map((tag) => tag.value)
          )
        }
      >
        <SelectInput
          selected={selectedTags}
          multiple
          setSelected={handleSelectTag}
          options={[
            ...tagsOptions,
            {
              label: <span style={{ color: Colors.purple }}>+ Add tag</span>,
              value: ADD_TAG_OPTION,
            },
          ]}
          placeholder="add tags"
          color={Colors.darkBlue}
          width={150}
          name="Tags"
          placeholderColor={Colors.darkGray2}
        />
        <SelectInput
          setSelected={setSelectedReason}
          selected={selectedReason}
          name="Resign reason"
          color={Colors.darkBlue}
          width="150"
          options={RESIGN_OPTIONS}
          required
        />
        <Input
          color={Colors.darkBlue}
          inputRef={commentRef}
          width={150}
          name="Comment"
        />
        <ButtonWrapper>
          <SubmitButton text="Resign" />
        </ButtonWrapper>
      </Form>
      {showAddTag && (
        <ResignTagForm setShow={setShowAddTag} reload={loadTags} />
      )}
    </PopUp>
  );
};

export default Resign;
