import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const Header = styled.h2`
  font-size: 20px;
  color: ${Colors.darkBlue};
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  color: ${Colors.darkBlue};
  font-weight: bold;
  margin-bottom: 8px;
`;

export const SectionText = styled.p`
  font-size: 12px;
  color: ${Colors.darkBlue};
  margin-bottom: 8px;
  font-weight: 500;
`;

export const CheckBoxInputsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 18px;
`;

export const FormSidesWrapper = styled.div`
  display: flex;
  gap: 80px;
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'center'};
`;

export const InfoItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const InfoItemLabel = styled.p`
  color: ${Colors.darkBlue};
  font-size: 12px;
  font-weight: bold;
  width: 75px;
`;

export const InfoItemValue = styled.p`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 12px;
  color: ${Colors.darkBlue};
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const CopyItem = styled.span`
  cursor: pointer;
  text-shadow: ${({ isCopied }) => isCopied ? `2px 2px 8px ${Colors.darkBlue}` : 'none'};
`;
