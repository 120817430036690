import React, { useState } from "react";
import SearchBarResignReport from "./components/searchBarResignReport/SearchBarResignReport";
import ResignReportTable from "./components/ResignReportTable/ResignReportTable";

const SupportResignReport = () => {
  const [resigns, setResigns] = useState();

  return (
    <div>
      <SearchBarResignReport setResigns={setResigns} />
      {resigns && <ResignReportTable resigns={resigns} />}
    </div>
  );
};

export default SupportResignReport;
