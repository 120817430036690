import PopUp from '@/common/components/PopUp'
import Table from '@/common/components/Table';
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import React, { useState } from 'react'
import ActionButton from '@/common/components/buttons/ActionButton';
import { ButtonsWrapper, TableWrapper } from './UserManagerForm.styled';
import { Colors } from '@/common/colors/colors';
import AddNewManager from './components/addNewManager.js/AddNewManager';
import { HEADERS } from './constants/headers';
import { getRaws } from './helpers/getRaws';
import { getValidatedManagers } from './helpers/getValidatedManagers';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';

const UserManagerForm = ({ 
  setShow, 
  userManagers = [], 
  managers = [], 
  setUserManagers, 
}) => {
  const [showAddNew, setShowAddNew] = useState();
  const [edit, setEdit] = useState();
  const [managersHistory, setManagersHistory] = useState(userManagers?.map((m, i) => ({ ...m, _id: i + 1 })) || []);

  const { commonData: { users } } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const handleAddNew = (newManager) => {
    setManagersHistory((prev) => {
      const res = [...prev, newManager];

      return getValidatedManagers(res, addMessage, setShow);
    })
  }

  const handleEdit = (editedManager) => {
    setManagersHistory((prev) => {
      const newManagers = prev?.map(manager => {
        if (manager._id === editedManager._id) {
          return editedManager;
        }
        return manager;
      })

      return getValidatedManagers(newManagers, addMessage, setShow);
    })
  }

  const raws = getRaws(managersHistory, setEdit, users);

  const handleSave = () => {
    setUserManagers(managersHistory);
    setShow(false);
  }

  return (
    <PopUp padding='10px 20px 24px' setShow={setShow}>
      <TableWrapper>
        {!!raws?.length && <Table raws={raws} headers={HEADERS} className="styled-table" />}
      </TableWrapper>
      <ButtonsWrapper>
        <ActionButton onClick={() => setShowAddNew(true)} defaultText={"Add new"} />
        <ActionButton color={Colors.red} onClick={() => handleSave()} defaultText={"Save"} />
      </ButtonsWrapper>
      {showAddNew && <AddNewManager userManagers={managersHistory} handeSubmit={handleAddNew} managers={managers} setShow={setShowAddNew} />}
      {edit && <AddNewManager userManagers={managersHistory} handeSubmit={handleEdit} manager={edit} setShow={setEdit} managers={managers} />}
    </PopUp>
  )
}

export default UserManagerForm
