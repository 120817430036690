import { findConsultantInfo } from "@/API/repositories/user";
import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import UserProile from "./images/user-profile.svg";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";
import { findSuperior } from "@/common/functions/findSuperior";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 50px;
`;

export const ImageWrapper = styled.div`
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

export const ImageInput = styled.input`
  display: none;
`;

const Element = styled.div`
  min-width: 500px;
  display: flex;
  gap: 10px;
  color: ${Colors.darkBlue};
`;

const Text = styled.div`
  min-width: 200px;
  text-align: right;
`;

const Title = styled.h4`
  min-width: 400px;
  margin: 20px 0;
  font-style: italic;
  color: ${Colors.darkBlue};
`;

const Subtitle = styled.p`
  margin: 40px;
  max-width: 400px;
  text-align: center;
  font-style: italic;
`;

const ConsultantProfile = () => {
  const { translation } = useTranslationContext();
  const { commonData: { queues, users } } = useCommonDataContext();

  const [user, setUser] = useState({});
  const [bonuses, setBonuses] = useState([]);

  const userId = userManager.getUser().id;

  const superior = useMemo(() => {
    return findSuperior(userId, users)
  }, [userId, users])

  const currentBonus = useMemo(() => {
    const userProduct = queues.find((queue) => queue.consultants?.includes(userId))?.product;

    const productBonuses = bonuses.filter((bonus) => bonus.product_id === userProduct?.id);
  
    const now = new Date();

    const perfectMatchedCurrentBonus = productBonuses.find((bonus) => new Date(bonus.valid_from) <= now && new Date(bonus.valid_to) >= now);

    if (!perfectMatchedCurrentBonus) {
      const latest = 
        productBonuses.filter(bonus => new Date(bonus.valid_from) <= now).sort((a, b) => new Date(b.valid_from) - new Date(a.valid_from))[0];

      if (latest) {
        return latest;
      }

      return {
        hourly_rate: 0,
        manager_hourly_rate: 0,
        manger_percent: 0,
        additional_commision: 0,
        delivered_commision: 0,
      }
    }

    return perfectMatchedCurrentBonus
  }, [bonuses, queues])

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const getUserInformation = async () => {
    const response = await makeRequest(findConsultantInfo.bind(null));

    if (response?.data?.user) {
      setUser(response.data.user);
    }

    if (response?.data?.bonuses) {
      setBonuses(response.data.bonuses);
    }
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  return (
    <>
      {hasUnfilledRequest(findConsultantInfo) && <Loading />}
      <Wrapper className={`fade__in__animation `}>
        <ImageWrapper>
          <label htmlFor="file-input" style={{ width: "150px" }}>
            <img
              alt=""
              src={user?.user_profile?.base64 || UserProile}
              style={{ width: "100%", height: "auto" }}
            />
          </label>
        </ImageWrapper>
        <Title>{translation["your_informations"]}:</Title>
        <Element>
          <Text>{translation["name"]}:</Text> <b> {user.name} </b>
        </Element>
        <Element>
          <Text>{translation["surname"]}:</Text> <b>{user.surname} </b>
        </Element>
        <Element>
          <Text>{translation["email"]}:</Text> <b>{user.email} </b>
        </Element>
        <Title>{translation["your_settings"]}:</Title>
        <Element>
          <Text>{translation["delivered_commision"]}:</Text>{" "}
          <b>
            {currentBonus?.delivered_commision} {user.salary_currency}
          </b>
        </Element>
        <Element>
          <Text>{translation["additional_item_commision"]}:</Text>{" "}
          <b>
            {currentBonus?.additional_commision} {user.salary_currency}
          </b>
        </Element>
        <Element>
          <Text>{translation["hourly_rate"]}:</Text>{" "}
          <b>
            {" "}
            {currentBonus?.hourly_rate} {user.salary_currency}
          </b>
        </Element>
        {superior && (
          <Element>
            <Text>{translation["team_leader"]}:</Text>{" "}
            <b>
              {superior.name} {superior.surname}
            </b>
          </Element>
        )}
        {!!currentBonus?.manager_hourly_rate && (
          <Element>
            <Text>{translation["hourly_manager_rate"]}:</Text>{" "}
            <b>
              {" "}
              {currentBonus?.manager_hourly_rate} {user.salary_currency}
            </b>
          </Element>
        )}
        {!!currentBonus?.manger_percent && (
          <Element>
            <Text>{translation["manager_percent"]}:</Text>{" "}
            <b>{currentBonus?.manger_percent} %</b>
          </Element>
        )}
        <Subtitle>{translation["rest_profile_info"]}</Subtitle>
      </Wrapper>
    </>
  );
};

export default ConsultantProfile;
