import React, { useEffect, useState } from "react";
import MailTemplateElement from "./components/mailTemplateElement/MailTemplateElement";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createMailTemplate, getMailTemplates, updateMailTemplate } from "@/API/repositories/mailTemplate";
import Loading from "@/common/components/Loading";
import { ButtonCreateWrapper, Wrapper } from "@/common/styles/Mails";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";

const MailTemplate = () => {
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [element, setElement] = useState();
  const [filter, setFilter] = useState();

  const handleLoadData = async () => {
    const response = await makeRequest(getMailTemplates);

    if (response.data) {
      setData(() => response.data);
    }
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 3) {
      return setFilter(() => e.target.value);
    }

    return setFilter(() => null);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(
        getMailTemplates,
        updateMailTemplate,
        createMailTemplate
      ) && <Loading />}
      <Wrapper>
        <ButtonCreateWrapper>
          <ActionButton
            onClick={() => setElement({})}
            defaultText="Create"
          />
          <Input
            width={100}
            inputWidth={200}
            onChange={(e) => handleChangeRegex(e)}
            name="Includer"
            color={Colors.darkBlue}
          />
        </ButtonCreateWrapper>
        {element && (
          <MailTemplateElement
            element={element}
            setElement={setElement}
            reload={handleLoadData}
          />
        )}
        <table className="styled-table">
          <thead>
            <tr>
              <th colSpan={3}>Mail Templates</th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Template Sengrid Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data[0] ? (
              data
                .filter((e) =>
                  filter ? e.template_sengrid_name.includes(filter) : true
                )
                .map((element, i) => (
                  <tr key={element._id}>
                    <td>{i + 1}.</td>
                    <td>{element.template_sengrid_name}</td>
                    <td>
                      <i
                        className="fa fa-edit animation-scale"
                        style={{ cursor: "pointer" }}
                        onClick={() => setElement(element)}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={3}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

export default MailTemplate;
