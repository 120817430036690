import { CircleWrapper } from "@/pages/consultant/consultantTests/ConsultantTests.styled";
import { MultilineProgresBarElement } from "./components/multilineProgresBarElement/MultilineProgresBarElement";

export const MultilineProgresBar = ({ current, max }) => {
  const showFrom = Math.min(current - 1, Math.max(max - 8, 0));
  const showTo = Math.min(current + 7, max);

  return (
    <CircleWrapper>
      {[...Array(max).keys()].map(
        (e, i) =>
          i >= showFrom &&
          i < showTo && (
            <MultilineProgresBarElement
              current={current}
              id={i}
              showFrom={showFrom}
              max={max}
            />
          )
      )}
    </CircleWrapper>
  );
};
