import styled from "styled-components";

export const Wrapper = styled.div`
margin: 30px;
display: flex;
align-items: center;
flex-direction: column;
gap: 30px;
`;

export const InputsWrapper = styled.div`
  max-width: 80vw;
  display: flex;
  justify-content: center;
`;

export const ActionButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  gap: 20px;
  align-items: center;
`;

export const TableWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;
