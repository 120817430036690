import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useFormik } from 'formik'
import React from 'react'
import { ButtonWrapper } from '../../CallCenterPosition.styled'
import { useRequestsContext } from '@/common/hooks/requestHook'
import Loading from '@/common/components/Loading'
import { createCallCenterPosition, updateCallCenterPosition } from '@/API/repositories/callCenterPosition'
import { CALL_CENTER_POSITION_OPTIONS } from '../../constants/callCenterPositions'
import { CURRENCY } from '@/common/constants/currency'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import SubmitButton from '@/common/components/buttons/SubmitButton'

const CallCenterPositionForm = ({ setShow, selectedPosition, reload = () => {} }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { options: { marketsOptions } } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const formik = useFormik({
    initialValues: {
      title: CALL_CENTER_POSITION_OPTIONS.find(o => o.value === selectedPosition?.title) || CALL_CENTER_POSITION_OPTIONS[0],
      market: marketsOptions.find(o => o.value === selectedPosition?.market),
      salary_currency: CURRENCY.find(o => o.value === selectedPosition?.salary_currency),
      hourly_rate: selectedPosition?.hourly_rate || 0, 
      delivered_commision: selectedPosition?.delivered_commision || 0,
      additional_commision: selectedPosition?.additional_commision || 0,
      manager_percent: selectedPosition?.manager_percent || 0,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title.value,
      market: values.market.value,
      salary_currency: values.salary_currency.value,
      hourly_rate: values.hourly_rate,
      delivered_commision: values.delivered_commision,
      additional_commision: values.additional_commision, 
      manager_percent: values.manager_percent,
    };

    const requestFn = selectedPosition ? updateCallCenterPosition : createCallCenterPosition;

    const response = await makeRequest(requestFn.bind(null, payload, selectedPosition?._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  return (
    <PopUp setShow={setShow}>
      {hasUnfilledRequest(createCallCenterPosition, updateCallCenterPosition) && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <SelectInput
          color={Colors.darkBlue}
          name="Market"
          required
          hideStar
          options={marketsOptions}
          width={200}
          setSelected={(value) => formik.setFieldValue('market', value)}
          selected={formik.values.market}
          value={formik.values.market}
        />
        <SelectInput
          setSelected={(value) => formik.setFieldValue('title', value)}
          options={CALL_CENTER_POSITION_OPTIONS}
          name="Title"
          value={formik.values.title}
          selected={formik.values.title}
          required
          hideStar
          color={Colors.darkBlue}
          width={200}
        />
         <SelectInput
          value={formik.values.salary_currency}
          name="Currency"
          required
          hideStar
          color={Colors.darkBlue}
          width={200}
          options={CURRENCY}
          setSelected={(value) => formik.setFieldValue('salary_currency', value)}
          selected={formik.values.salary_currency}
        />
        <Input 
          id={"hourly_rate"}
          onChange={formik.handleChange}
          value={formik.values.hourly_rate}
          type="number"
          name="Hourly rate"
          required
          color={Colors.darkBlue}
          width={200}
        />
        <Input
          id={"delivered_commision"}
          onChange={formik.handleChange}
          value={formik.values.delivered_commision}
          type="number"
          name="Delivered commision"
          required
          color={Colors.darkBlue}
          width={200}
        />
        <Input
          id={"additional_commision"}
          onChange={formik.handleChange}
          value={formik.values.additional_commision}
          type="number"
          name="Additional commision"
          required
          color={Colors.darkBlue}
          width={200}
        />
        <Input
          id={"manager_percent"}
          onChange={formik.handleChange}
          value={formik.values.manager_percent}
          type="number"
          name="Manager percentage"
          required
          color={Colors.darkBlue}
          width={200}
        />
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: "-30px" }} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default CallCenterPositionForm
