import React from 'react'
import ClaimType from './components/claimType/ClaimType'
import OrderItems from './components/orderItems/OrderItems'
import Reasoning from './components/reasoning/Reasoning'
import { ClaimSideWrapper } from './ClaimSide.styled'
import ClaimPayment from './components/claimPayment/ClaimPayment'
import ClaimsStatus from './components/claimStatus/ClaimsStatus'

const ClaimSide = ({
  loadTags,
  handleValidClaimUpdate,
}) => {
  return (
    <ClaimSideWrapper>
      <ClaimType />
      <OrderItems />
      <Reasoning loadTags={loadTags} />
      <ClaimPayment />
      <ClaimsStatus
        handleValidClaimUpdate={handleValidClaimUpdate}
      />
    </ClaimSideWrapper>
  )
}

export default ClaimSide
