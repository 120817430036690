import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../common/colors/colors";
import InvoiceTicket from "@/common/components/invoiceTicket/InvoiceTicket";
import ActionButton from "@/common/components/buttons/ActionButton";
import CreateNewTicket from "../../dashboard/panel/ticket/createNewTicket/CreateNewTicket";

export const H5 = styled.strong`
  color: ${Colors.darkBlue};
  width: 110px;
  text-align: right;
  font-size: 18px;
  margin-top: 10px;
`;

export const Flex = styled.div`
  display: flex;
`;

const TicketsForCalling = ({ orderId, market }) => {
  const [showQuestionTicket, setShowQuestionTicket] = useState();
  const [showInvoiceTicket, setShowInvoiceTicket] = useState();
  const [success, setSuccess] = useState();

  return (
    <>
      <ActionButton
        color={success === "question" ? Colors.green : Colors.orange}
        onClick={() => setShowQuestionTicket(true)}
      >
        <i class="fa fa-question" style={{ marginRight: "10px" }}></i>
        {success === "question" ? "Success" : "Question"}
      </ActionButton>
      <ActionButton
        color={success === "invoice" ? Colors.green : Colors.orange}
        onClick={() => setShowInvoiceTicket(true)}
      >
        <i class="fa fa-file-invoice" style={{ marginRight: "10px" }}></i>
        {success === "invoice" ? "Success" : "Invoice"}
      </ActionButton>
      {showInvoiceTicket && (
        <InvoiceTicket
          market={market}
          setShowInvoiceTicket={setShowInvoiceTicket}
          orderId={orderId}
        />
      )}
      {showQuestionTicket && (
        <CreateNewTicket
          orderId={orderId}
          setShowQuestionTicket={setShowQuestionTicket}
        />
      )}
    </>
  );
};

export default TicketsForCalling;
