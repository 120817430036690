export const VALID_CLAIMS_ACTIONS = {
	RESET: "RESET",
	SET_MARKETS: "SET_MARKETS",
	SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
};

export const DEFAULT_REDUCER_STATE = {
	[VALID_CLAIMS_ACTIONS.SET_MARKETS]: [],
	[VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD]: null,
};
