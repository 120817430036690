import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
  InputsWrapper,
} from "./DynamicMailContentForm.styled";
import { ButtonWrapper } from "../../DynamicMailContent.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createDynamicMailContent, updateDynamicMailContent } from "@/API/repositories/dynamicMailContent";
import HtmlEditor from "@/common/components/htmlEditor/HtmlEditor";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const DynamicMailContentForm = ({
  setShow,
  selectedDynamicContent,
  reload,
}) => {
  const [shouldBlockSubmit, setShouldBlockSubmit] = useState();

  const { makeRequest } = useRequestsContext();
  const { addMessage }  = useMessageQueueContext();

  const handleSubmit = async () => {
    const req = selectedDynamicContent ? updateDynamicMailContent : createDynamicMailContent;
    const response = await makeRequest(
      req.bind(null, formik.values, selectedDynamicContent?._id)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }
  
    setShow(false);
    reload();
  };

  const formik = useFormik({
    initialValues: {
      title: selectedDynamicContent?.title || "",
      subject: selectedDynamicContent?.subject || "",
      html_content: selectedDynamicContent?.html_content || "",
    },
    onSubmit: () => handleSubmit(),
  });

  return (
    <PopUp padding="10px 20px 15px" setShow={setShow}>
      <form onSubmit={formik.handleSubmit}>
        <InputsWrapper>
          <Input
            id="title"
            name="Title"
            color={Colors.darkBlue}
            value={formik.values.title}
            onChange={formik.handleChange}
            required
            width={70}
            inputWidth={790}
          />
          <Input
            id="subject"
            name="Subject"
            color={Colors.darkBlue}
            value={formik.values.subject}
            onChange={formik.handleChange}
            required
            width={70}
            inputWidth={790}
          />
          <HtmlEditor 
            setFieldValue={formik.setFieldValue} 
            field={"html_content"} 
            setShouldBlockSubmit={setShouldBlockSubmit}
            defaultValue={selectedDynamicContent?.html_content} 
          />
        </InputsWrapper>
        <ButtonWrapper>
          <SubmitButton disabled={shouldBlockSubmit} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default DynamicMailContentForm;
