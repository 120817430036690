import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Colors } from "../../../../../common/colors/colors";
import { round } from "../../../../../common/functions/round";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import moment from "moment";
import { getLastGrades } from "../../../../../API/repositories/gamificationPoints";
import Skeleton from "react-loading-skeleton";
import CustomSkeleton from "../../../../../common/components/CustomSkeleton";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  width: 400px;
  height: 370px;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  h3 {
    font-size: 16px;
    color: ${Colors.darkBlue};
  }
`;

const MonthSwapWrapper = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 10px;
    color: lightgrey;
    font-style: italic;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const GradeChart = () => {
  const [data, setData] = useState();

  const { makeRequest } = useRequestsContext();
  const { translation: translations} = useTranslationContext();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            color: Colors.darkBlue,
          },
          color: Colors.darkBlue,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
    },
  };

  const handleLoadData = async () => {
    const payload = {};
    payload.from = moment().subtract("7", "days").startOf("day").toISOString();

    const response = await makeRequest(getLastGrades.bind(null, payload));
    if (response.data) {
      setData(() => ({
        labels: response.data.map((d) => moment(d.created_at).format("dddd")),
        datasets: [
          {
            label: "Daily grade",
            data: response.data.map((d) => d.overall.grade),
            borderColor: Colors.darkBlue,
            backgroundColor: Colors.darkBlue,
          },
        ],
      }));
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <Wrapper>
      <TopWrapper>
        <NameWrapper>
          <i
            className="fa fa-poll"
            style={{ color: Colors.darkBlue, fontSize: "30px" }}
          />
          <h3>
            {translations["Avg"]}:{" "}
            <b>
              {(data &&
                round(
                  data.datasets[0].data.reduce((prev, next) => prev + next, 0) /
                    data.datasets[0].data.length
                )) ||
                0}
            </b>{" "}
          </h3>
        </NameWrapper>
        <MonthSwapWrapper>
          <h3>{translations["last 7 days grade"]}</h3>
        </MonthSwapWrapper>
      </TopWrapper>
      <ChartWrapper>
        {data ? <Line options={options} data={data} /> : <CustomSkeleton />}
      </ChartWrapper>
    </Wrapper>
  );
};

export default GradeChart;
