import React from "react";
import { Title } from "../../InvoiceTicket.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { INVOICE_CREATION_TYPES } from "../../contstants/invoices";
import { CreateByButtonsWrapper } from "./InvoiceNotSet.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const InvoiceNotSet = ({ setInvoiceCreateBy }) => {
  const { translation } = useTranslationContext();
  return (
    <>
      <Title>Invoice ticket:</Title>
      <CreateByButtonsWrapper>
        <ActionButton
          onClick={() => setInvoiceCreateBy(INVOICE_CREATION_TYPES.VAT_ID)}
          defaultText={translation.create_by_vat}
        />
        <ActionButton
          onClick={() => setInvoiceCreateBy(INVOICE_CREATION_TYPES.COMPANY_ID)}
          defaultText={translation.create_by_different_id}
        />
      </CreateByButtonsWrapper>
    </>
  );
};

export default InvoiceNotSet;
