import React, { createContext, useRef, useState } from "react";
import { useRequestsContext } from "../../hooks/requestHook";
import {
  getDashboardSettings,
  createDashboardSettings,
  updateDashboardSettings,
} from "../../../API/repositories/dashboardSettings";
import { DASHBOARD_VIEW_SETTINGS } from "../../constants/dashboardSettings";
import userManager from "../../../API/userManager";
import { useAdminDasbhoardRequests } from "./requests/adminDashboardRequests";

export const AdminDashboardContext = createContext();

export const AdminDashboardProvider = ({ children }) => {
  const [dashboardSettings, setDashboardSettings] = useState(null);
  const [showDashboardSettings, setShowDashboardSettings] = useState(false);
  const [data, setData] = useState({});

  const { makeRequest } = useRequestsContext();

  const settingsRef = useRef({
    value: {
      visible_views: [],
      refresh_time_seconds: 0,
      show_old_tickets: false,
      old_tickets_limit: 0,
      notifications: false,
    },
  });

  const {
    loadBoundInfo,
    loadArchives,
    loadMailsHistory,
    getLoggedInUsers,
    loadEffectivityReport,
    loadConsultantHours,
    loadSentOrders,
    loadVoipStatistics,
    loadClaims,
    loadInvoices,
    loadQueueLeads,
    loadCallingStatistics,
    loadActiveTickets,
  } = useAdminDasbhoardRequests();

  const loadDashboardSettings = async () => {
    const response = await makeRequest(getDashboardSettings);

    if (response.data) {
      const dashboardSettingsValues = {
        visible_views: response.data.visible_views.filter((view) =>
          userManager.checkAccess(
            DASHBOARD_VIEW_SETTINGS.find(
              (settings) => settings.value === view.name
            )?.access
          )
        ),
        refresh_time_seconds: response.data.refresh_time_seconds,
        old_tickets_limit: response.data.old_tickets_limit,
        show_old_tickets: response.data.show_old_tickets,
        notifications: response.data.notifications,
      };

      setDashboardSettings(() => dashboardSettingsValues);

      settingsRef.current.value = JSON.parse(
        JSON.stringify(dashboardSettingsValues)
      );
    } else if (!response.data) {
      const payload = {};

      payload.visible_views = DASHBOARD_VIEW_SETTINGS.filter((view) =>
        userManager.checkAccess(view.access)
      ).map((view) => ({ name: view.value }));
      payload.refresh_time_seconds = 60;
      payload.old_tickets_limit = 30;
      payload.show_old_tickets = true;
      payload.notifications = true;

      const createdSettingsResponse = await makeRequest(
        createDashboardSettings.bind(null, payload)
      );

      if (createdSettingsResponse.data) {
        const dashboardSettingsValues = {
          visible_views: createdSettingsResponse.data.visible_views,
          refresh_time_seconds:
            createdSettingsResponse.data.refresh_time_seconds,
          old_tickets_limit: createdSettingsResponse.data.old_tickets_limit,
          show_old_tickets: createdSettingsResponse.data.show_old_tickets,
        };

        setDashboardSettings(() => dashboardSettingsValues);
        settingsRef.current.value = JSON.parse(
          JSON.stringify(dashboardSettingsValues)
        );
      }
    }
  };

  const handleUpdateDashboardSettings = async (e, newValues) => {
    e && e.preventDefault();

    const payload = {};

    payload.visible_views = newValues.visible_views;
    payload.refresh_time_seconds = newValues.refresh_time_seconds;
    payload.old_tickets_limit = newValues.old_tickets_limit;
    payload.show_old_tickets = newValues.show_old_tickets;
    payload.notifications = newValues.notifications;

    const response = await makeRequest(
      updateDashboardSettings.bind(null, payload)
    );

    if (response.data) {
      await loadDashboardSettings();

      setShowDashboardSettings(() => false);
    }

    const newViewsNames = newValues.visible_views.map((view) => view.name);

    const currentViewsNames = dashboardSettings.visible_views.map(
      (view) => view.name
    );

    const viewsToLoad = newViewsNames.filter(
      (view) => !currentViewsNames.includes(view)
    );

    const settingsToUpdate = {
      visible_views: newValues.visible_views.filter((view) =>
        viewsToLoad.includes(view.name)
      ),
    };

    const promises = [];

    viewsToLoad.forEach((view) => {
      const reqFn = settingsToLoad[view];

      if (reqFn) {
        promises.push(
          reqFn(settingsToUpdate, setData).then((data) => {
            return setData((prev) => ({ ...prev, [`${view}`]: data }));
          })
        );
      }
    });

    await Promise.all(promises);
  };

  const handleDashboardSettingsPropertiesUpdate = async (
    viewName,
    property,
    value
  ) => {
    const payload = { ...dashboardSettings };
    payload.visible_views.find((visibleView) => visibleView.name === viewName)[
      property
    ] = value;

    const response = await makeRequest(
      updateDashboardSettings.bind(null, payload)
    );

    if (response.data) {
      setShowDashboardSettings(() => false);
      await loadDashboardSettings();
    }
  };

  const settingsToLoad = {
    bound_info: loadBoundInfo,
    mail_history: loadMailsHistory,
    logged_in_users: getLoggedInUsers,
    effectivity_report: loadEffectivityReport,
    consultant_hours: loadConsultantHours,
    archives: loadArchives,
    sent_orders: loadSentOrders,
    invoices: loadInvoices,
    claims: loadClaims,
    voip_statistics: loadVoipStatistics,
    queue_leads: loadQueueLeads,
    calling_statistics: loadCallingStatistics,
    active_tickets: loadActiveTickets,
  };

  const loadData = async () => {
    const promises = [];
    let currentDashboardSettings = dashboardSettings;

    if (!currentDashboardSettings) {
      await loadDashboardSettings();

      const response = await makeRequest(getDashboardSettings);
      currentDashboardSettings = response?.data;
    }

    currentDashboardSettings?.visible_views.forEach((view) => {
      const reqFn = settingsToLoad[view.name];

      if (reqFn) {
        promises.push(
          reqFn(currentDashboardSettings, setData).then((data) =>
            setData((prev) => ({ ...prev, [`${view.name}`]: data }))
          )
        );
      }
    });

    await Promise.all(promises);
  };

  return (
    <AdminDashboardContext.Provider
      value={{
        data,
        setData,
        setDashboardSettings,
        dashboardSettings,
        showDashboardSettings,
        settingsRef,
        setShowDashboardSettings,
        loadData,
        loadDashboardSettings,
        handleUpdateDashboardSettings,
        handleDashboardSettingsPropertiesUpdate,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
};
