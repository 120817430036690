import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { ButtonWrapper, Title } from "../listeningScores.styles";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createCallingImprovementTicket } from "@/API/repositories/tickets";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const ImporvementTicket = ({
  showTicketTalkingImprovement,
  setShowTicketTalkingImprovement,
}) => {
  const textareaRef = useRef();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleCreateTicketTalkingImprovement = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.score_id = showTicketTalkingImprovement._id;
    payload.message = textareaRef.current.value;

    const response = await makeRequest(
      createCallingImprovementTicket.bind(null, payload)
    );

    if (response.data) {
      addMessage("Sent", "success");
      setShowTicketTalkingImprovement(() => null);
    } else {
      addMessage("Error", "error");
    }
  };

  return (
    <PopUp setShow={setShowTicketTalkingImprovement}>
      <Title>Create Improvement Ticket</Title>
      <form onSubmit={(e) => handleCreateTicketTalkingImprovement(e)}>
        <textarea
          ref={textareaRef}
          style={{
            width: "500px",
            height: "300px",
            resize: "none",
            fontSize: "16px",
            margin: "40px 0px",
          }}
          required
          placeholder="type message (english)"
        />
        <ButtonWrapper>
          <SubmitButton text="Send" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default ImporvementTicket;
