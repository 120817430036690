import React from "react";
import Input from "../../../../common/components/Input";
import SelectInput from "../../../../common/components/SelectInput";
import { useFormik } from "formik";
import { Colors } from "../../../../common/colors/colors";
import styled from "styled-components";
import { PREFIXES } from "../ticket/ContactView/ContactDisplay";
import { formatPhoneNumber } from "../../../../common/functions/formatPhoneNumber";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import { useRequestsContext } from "@/common/hooks/requestHook";
import ActionButton from "@/common/components/buttons/ActionButton";
import { DISABLED_TOOLTIP } from "@/common/constants/suspicion";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Card = styled.div`
  border-radius: 10px;
  padding: 10px 30px 30px 30px;
  min-width: 400px;
  background: ${Colors.lightlightBlue};
`;

const Title = styled.h2`
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const ContactInboundForm = ({
  contact,
  handleSave,
  handleCheckPromotion,
  country,
  shouldDisableSuspicion,
  setShouldDisableSuspicion,
}) => {
  const formik = useFormik({
    initialValues: {
      additionalInfo: contact.additiona_info,
      country: country,
      full_name: contact.full_name,
      email: contact.email,
      postal_code: contact.postal_code,
      city: contact.city,
      street: contact.street,
      additional_info: contact.additional_info,
      phone_number_for_courier: contact.phone_number_for_courier,
    },
    onSubmit: (values) => handleSave(values),
  });
  const { addMessage } = useMessageQueueContext();
  const { makeRequest } = useRequestsContext();
  const { COUNTRIES } = useCommonDataContext();
  const { translation } = useTranslationContext();

  const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, contact._id)
    );
    setShouldDisableSuspicion(true);

    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    addMessage("Success", "success");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Title>
          <div>Contact</div>
          {PREFIXES[contact.country]} {formatPhoneNumber(contact.phone_number)}
        </Title>
        <Input
          name={translation.fullName}
          id="full_name"
          value={formik.values.full_name}
          color={Colors.darkBlue}
          width={140}
          onChange={formik.handleChange}
        />
        <Input
          id="email"
          name={translation.email}
          value={formik.values.email}
          width={140}
          color={Colors.darkBlue}
          onChange={formik.handleChange}
        />
        <SelectInput
          id="country"
          options={COUNTRIES}
          name={translation.country}
          selected={formik.values.country}
          setSelected={(value) => formik.setFieldValue("country", value)}
          width={140}
          color={Colors.darkBlue}
          onChange={formik.handleChange}
        />
        <Input
          id="postal_code"
          name={translation.countryCode}
          value={formik.values.postal_code}
          width={140}
          color={Colors.darkBlue}
          onChange={formik.handleChange}
        />
        <Input
          id="city"
          name={translation.city}
          value={formik.values.city}
          color={Colors.darkBlue}
          width={140}
          onChange={formik.handleChange}
        />
        <Input
          id="street"
          name={translation.street}
          value={formik.values.street}
          width={140}
          color={Colors.darkBlue}
          onChange={formik.handleChange}
        />
        <Input
          id="phone_number_for_courier"
          name={translation.phone_number_for_courier}
          value={formik.values.phone_number_for_courier}
          onChange={formik.handleChange}
          color={Colors.darkBlue}
          width={135}
          inputWidth={240}
        />
        <Input
          id="additional_info"
          name={translation.additionalInfo}
          value={formik.values.additional_info}
          width={140}
          onChange={formik.handleChange}
          color={Colors.darkBlue}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            gap: "30px",
            margin: "20px 0 -10px 0",
          }}
        >
          {country?.value === "cz" && (
            <div>
              <ActionButton
                color={Colors.blue}
                onClick={(e) => handleCheckPromotion(e)}
                disabled={!contact.postal_code}
                defaultText={translation.checkProm}
              />
            </div>
          )}
          <div>
            <ActionButton
              tooltip={shouldDisableSuspicion ? DISABLED_TOOLTIP : null}
              onClick={() => handleCreateSuspicionTicket()}
              defaultText={translation.suspect}
              color={Colors.red}
              disabled={shouldDisableSuspicion}
            />
          </div>
          <div>
            <SubmitButton text={translation.save} />
          </div>
        </div>
      </Card>
    </form>
  );
};

export default ContactInboundForm;
