import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  gap: 20px;
  overflow-y: auto;
  max-height: 100vh;
`;

export const TableWrapper = styled.div`
  max-height: 80%;
  overflow: scroll;
`;

export const GradeCell = styled.td`
	padding: 0 10px;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	color: ${(props) => props.color};
`;
