import React, { useState, useRef, useMemo } from "react";
import Card from "@/common/components/Card";
import { useRequestsContext } from "@/common/hooks/requestHook";
import SelectInput from "@/common/components/SelectInput";
import Loading from "@/common/components/Loading";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import { getInvoicesReport } from "@/API/repositories/reports";
import { formatDate } from "@/common/functions/dateFormater";
import CSV from "@/common/components/CSV";
import { COMPANY_NAMES } from "@/common/constants/companies";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import InvoicesReportTable from "./components/invoicesReportTable/InvoicesReportTable";
import { getMarketById } from "./helpers/getMarketById";
import { getProductById } from "./helpers/getProductById";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Wrapper, Flex, RightFlex } from "./InvoicesReport.styled";
import { getHeaders } from "./helpers/getHeaders";
import { getCSVData } from "./helpers/getCSVData";
import ExportExcel from "@/common/components/ExportExcel";
import { getExcelData } from "./helpers/getExcelData";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

function InvoicesReport() {
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProducts, setSelectedProducts] = useState();
  const [orders, setOrders] = useState();

  const {
    commonData: { markets, queues },
    options: { productsOptions, marketsOptions },
  } = useCommonDataContext();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const correctionNumberFilterRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();

  const handleSearch = async () => {
    if (!selectedMarket?.value) {
      return addMessage("Select market", "error");
    }

    if (!selectedProducts?.length) {
      return addMessage("Select products", "error");
    }

    if (!fromDateRef.current?.value || !toDateRef.current?.value) {
      return addMessage("Select all dates", "error");
    }

    const payload = {};

    payload.market = selectedMarket.value;
    payload.products = selectedProducts.map((product) => product.value);
    payload.fromDate = new Date(fromDateRef.current.value);
    payload.toDate = new Date(toDateRef.current.value);

    const response = await makeRequest(getInvoicesReport.bind(null, payload));

    if (!response.data) {
      return addMessage("Something went wrong", "error");
    }

    if (response.data) {
      setOrders(() => response.data);
    }
  };

  const handleSelectMarket = (market) => {
    setSelectedProducts(() => {
      const newProducts = [];

      queues.forEach((queue) => {
        if (queue.market === market.value) {
          newProducts.push(getProductById(queue.product, productsOptions));
        }
      });

      return newProducts;
    });

    setSelectedMarket(() => market);
  };

  const headers = useMemo(() => {
    if (!!orders?.length) {
      return getHeaders(orders);
    }
  }, [orders]);

  const csvData = useMemo(() => {
    if (!!orders?.length) {
      return getCSVData(
        orders,
        markets,
        correctionNumberFilterRef.current?.checked
      );
    }
  }, [orders]);

  const excelData = useMemo(() => {
    if (!!orders?.length) {
      const netto = `Kwota (netto ${orders[0].currency})`;
      const brutto = `Kwota (brutto ${orders[0].currency})`;

      return getExcelData(
        orders,
        markets,
        correctionNumberFilterRef.current?.checked,
        netto,
        brutto
      );
    }
  }, [orders]);

  return (
    <Wrapper>
      {hasUnfilledRequest(getInvoicesReport) && <Loading />}
      <Card>
        <SelectInput
          name="Market"
          placeholder="Select market..."
          selectWidth={450}
          width={120}
          options={marketsOptions}
          setSelected={handleSelectMarket}
          color={Colors.darkBlue}
        />
        <SelectInput
          name="Products"
          placeholder="Select products..."
          selectWidth={450}
          width={120}
          multiple
          options={productsOptions}
          selected={selectedProducts}
          setSelected={setSelectedProducts}
          color={Colors.darkBlue}
        />
        <Flex>
          <Input
            inputWidth={140}
            width={120}
            inputRef={fromDateRef}
            name="From"
            type="date"
            color={Colors.darkBlue}
          />
          <Input
            inputWidth={140}
            inputRef={toDateRef}
            width={120}
            name="To"
            type="date"
            color={Colors.darkBlue}
          />
        </Flex>
        <RightFlex>
          <Input
            inputWidth={20}
            inputRef={correctionNumberFilterRef}
            width={235}
            name="Invoice correction number"
            type="checkbox"
            id="correction_checkbox"
            color={Colors.darkBlue}
          />
        </RightFlex>
        <RightFlex>
          {!!orders?.length && (
            <ExportExcel
              excelData={excelData}
              fileName={`${COMPANY_NAMES[selectedMarket?.value]
                ?.split(" ")
                .join("_")}_${getMarketById(
                selectedMarket.value,
                markets
              ).short.toUpperCase()}_Faktury_${formatDate(
                fromDateRef.current?.value,
                "DD.MM.YYYY"
              )}-${formatDate(toDateRef.current?.value, "DD.MM.YYYY")}.csv`}
            />
          )}
          {!!orders?.length && (
            <CSV
              header={headers}
              data={csvData}
              filename={`${COMPANY_NAMES[selectedMarket?.value]
                ?.split(" ")
                .join("_")}_${getMarketById(
                selectedMarket.value,
                markets
              ).short.toUpperCase()}_Faktury_${formatDate(
                fromDateRef.current?.value,
                "DD.MM.YYYY"
              )}-${formatDate(toDateRef.current?.value, "DD.MM.YYYY")}.csv`}
            />
          )}
          <ActionButton
            onClick={() => handleSearch()}
            defaultText="Search"
          />
        </RightFlex>
      </Card>
      {orders && orders[0] && (
        <InvoicesReportTable
          correctionNumber={correctionNumberFilterRef.current?.checked}
          orders={orders}
        />
      )}
    </Wrapper>
  );
}

export default InvoicesReport;
