export const getRaws = (data, setElement) => data.map((element, i) => (
	<tr key={element._id}>
		<td>{i + 1}.</td>
		<td>{element.data[0].name}</td>
		<td>
			{element.data
				?.map(
					(template) =>
						template.mail_template_ids.template_sengrid_name
				)
				.join(", ") || "-----"}
		</td>
		<td>
			<i
				className="fa fa-edit animation-scale"
				style={{ cursor: "pointer" }}
				onClick={() => setElement(element)}
			/>
		</td>
	</tr>
));
