import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  createOrderWithShipping,
  markOrdertAsConsultantDecision,
  updateOrderByConsultant,
} from "../../../../../API/repositories/order";
import Input from "../../../../../common/components/Input";
import PopUp from "../../../../../common/components/PopUp";
import SelectInput from "../../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import moment from "moment";
import Loading from "../../../../../common/components/Loading";
import MoreInfo from "../../../contact/moreInfo/MoreInfo";
import {
  markInBoundCallAsConfirmed,
  markInboundCallAsInformed,
  markInboundCallAsResigned,
  markInBoundCallAsShiftOrder,
  markOnlinePaymentAsConfirmed,
} from "../../../../../API/repositories/queue";
import TicketsForCalling from "../../../contact/tickets/TicketsForCalling";
import { Colors } from "../../../../../common/colors/colors";
import userManager from "@/API/userManager";
import { checkIfCommentAdded } from "@/common/functions/checkIfCommentAdded";
import { getProductsWithCouriers } from "@/API/repositories/lms";
import ConfirmPayment from "../../../contact/ConfirmPayment";
import { MIN_SHIFT_OFFSET } from "@/common/constants/minShiftOffset";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SupportMail from "@/common/components/supportMail/SupportMail";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { sendMailByMailType } from "@/API/repositories/mail";

const OrderEditInbound = ({
  order = { shipping: {} },
  setShow,
  contact,
  country,
  market,
  create = true,
  comments = [],
}) => {
  const [product, setProduct] = useState(order?._product)
  const [showPaymentConfirm, setShowPaymentConfirm] = useState();
  const defaultShiftDate = moment().add(1, "days").format("YYYY-MM-DDTHH:mm");
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [showExtraShiftConfirm, setShowExtraShiftConfirm] = useState(false);
  const [shiftDate, setShiftDate] = useState(defaultShiftDate);
  const [chosenVariants, setChosenVariants] = useState(
    order.order_items
      ? order.order_items.map((order, i) => {
        return {
          _id: i + 1,
          name: product?.variants.find(
            (variant) => variant.short === order.product_variant
          )?.name,
          value: order.product_variant,
          price: order.price,
        };
      })
      : []
  );
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionPrice, setSelectedOptionPrice] = useState();
  const [itemsError, setItemsError] = useState();
  const [selectedCourier, setSelectedCourier] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const suggestedDeliveryDateRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();

  const [showConfirm, setShowConfirm] = useState();
  const [type, setType] = useState();
  const currentUserId = userManager.getUser().id;

  const wasCommentAdded = useMemo(
    () => checkIfCommentAdded(currentUserId, comments),
    [currentUserId, comments]
  );
  const { addMessage } = useMessageQueueContext();
  const {
    commonData: { queues, products },
    options: { productsOptions },
    COUNTRIES
  } = useCommonDataContext();
  const { translation } = useTranslationContext();


  const availableProductsOptions = useMemo(() => {
    const currentUserId = userManager.getUser().id;

    const userQueues = queues.filter((queue) => queue.consultants.includes(currentUserId));

    const availableProducts = Array.from(new Set(userQueues.map((queue) => queue.product)));
    return productsOptions.filter((p) => availableProducts.includes(p.value));
  }, [queues, productsOptions])

  const handleMarkAsConsultantDecision = async (e) => {
    e.preventDefault();
    switch (type) {
      case "resign":
        handleResign(e);
        break;
      case "confirm":
        handleCofnirm(e);
        break;
      case "shift":
        handleShift(e);
        break;
      case "informed":
        handleInformed(e);
        break;
      default:
        break;
    }
  };

  const handleInformed = async () => {
    const response = await makeRequest(
      markInboundCallAsInformed.bind(null, order._id)
    );

    if (!response?.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    setShowConfirm(() => false);
    setShow(() => false);
  };

  const handleResign = async (e) => {
    const response = await makeRequest(
      markInboundCallAsResigned.bind(null, order._id)
    );

    if (!response?.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    setShowConfirm(() => false);
    setShow(() => false);
  };

  const handleCofnirm = async (e) => {
    if (chosenVariants.length === 0) {
      setItemsError(() => "item");
      return;
    }

    if (contact.country === "cz" && !contact.hasPromotion) {
      const price = chosenVariants.reduce((prev, next) => prev + next.price, 0);

      if (price < 1960 * 5) {
        setItemsError(() => "bad price to promotion");
        return;
      }
    }

    await handleSaveOrder(e);
    const response = await makeRequest(
      markInBoundCallAsConfirmed.bind(null, order._id)
    );

    if (!response?.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    setShowConfirm(() => false);
    setShow(() => false);
  };

  const handleShift = async (e) => {
    if (MIN_SHIFT_OFFSET > new Date(shiftDate).getTime() - new Date().getTime()) {
      return addMessage("Shift date should be at least 10 minutes from now", "error");
    }
    const response = await makeRequest(
      markInBoundCallAsShiftOrder.bind(
        null,
        order._id,
        new Date(shiftDate).toISOString(),
      )
    );

    if (!response?.data) {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }

    setShowConfirm(() => false);
    setShow(() => false);
  };

  const handleSaveOrder = async (e) => {
    e.preventDefault();
    let response;
    order.currency = market.currency;
    order.market = market._id;
    order.product = product._id;
    order.items = chosenVariants;

    const shipping = Object();

    shipping.full_name = nameRef.current
      ? nameRef.current.value
      : contact.full_name;

    shipping.email = emailRef.current ? emailRef.current.value : contact.email;
    shipping.phone_number = phoneRef.current
      ? phoneRef.current.value
      : contact.phone_number;
    shipping.suggested_delivery_date = suggestedDeliveryDateRef.current
      ? moment(suggestedDeliveryDateRef.current.value)
      : moment().add(2, "days");
    shipping.street = streetRef.current
      ? streetRef.current.value
      : contact.street;
    shipping.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : contact.postal_code;
    shipping.city = cityRef.current ? cityRef.current.value : contact.city;
    shipping.country = selectedCountry.value;
    shipping.curier_lms_id = selectedCourier?.value;

    order.shipping = shipping;

    if (create) {
      if (chosenVariants.length > 0) {
        response = await makeRequest(
          createOrderWithShipping.bind(null, contact._id, order)
        );
        setShow(() => null);
      }
    } else {
      response = await makeRequest(
        updateOrderByConsultant.bind(null, order._id, order)
      );
    }

    if (response?.data) {
      setShow(() => false);
    } else {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }
  };

  const hadnleAddProduct = (e) => {
    e.preventDefault();

    if (selectedOption && selectedOptionPrice)
      setChosenVariants((prev) => [
        ...chosenVariants,
        {
          _id: chosenVariants.length + 1,
          name: selectedOption.label,
          value: selectedOption.value,
          price: selectedOptionPrice.value,
        },
      ]);
  };

  const hadnleDelteProductItem = (e, _id) => {
    e.preventDefault();
    let data = chosenVariants.filter((item) => item._id !== _id);
    let id = 1;
    data = data.map((data) => {
      data._id = id;
      id++;
      return data;
    });
    setChosenVariants(() => data);
  };

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (productWithCourier) => product?.short === productWithCourier.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(
      options.find((option) => option.value === order?.shipping?.curier_lms_id) ||
      options[0]
    );
  };

  useEffect(() => {
    loadCouriers();
  }, [product]);

  useEffect(() => {
    handleSetCouriersData();
  }, [productsWithCouriers]);

  const selectProduct = (product) => {
    setProduct(products.find((p) => p._id === product.value));
  }

  const handleOnlinePayment = async (category) => {
    const results = await Promise.all([
      sendCategoryMail(category),
      handlePaymentConfirm()
    ])

    const someWrong = results.some((result) => !result);

    if (someWrong) {
      return;
    }

    addMessage("Successfully confirmed payment", "success");
    setShow(() => false);
  }

  const handlePaymentConfirm = async () => {
    const response = await makeRequest(
      markOnlinePaymentAsConfirmed.bind(null, order._id)
    )

    if (!response?.data)
      addMessage("Couldn't confirm payment", "error");


    return response?.data;
  }

  const sendCategoryMail = async (category) => {
    const mailResponse = await makeRequest(
      sendMailByMailType.bind(
        null,
        category,
        order._id
      )
    );

    if (!mailResponse?.data)
      addMessage("Couldn't send mail", "error");

    return mailResponse?.data;
  }

  const isTestAvailable =
    product?.short === 'er'
    && order?.shipping?.status === 'return'
    && order?.order_items?.length === 1
    && !order.payment?.length;

  return (
    <>
      <PopUp setShow={setShow} top={30}>
        {hasUnfilledRequest(
          createOrderWithShipping,
          getProductsWithCouriers,
          updateOrderByConsultant,
          markOrdertAsConsultantDecision,
          sendMailByMailType,
          markOnlinePaymentAsConfirmed
        ) && <Loading />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ marginBottom: "20px" }}>{translation.order}</h2>
          <div style={{ display: "flex", gap: "20px" }}>
            {isTestAvailable && (
              <>
                <ActionButton
                  defaultText="Buy online"
                  onClick={() => handleOnlinePayment("TEST_ONLINE_PAYMENT_RO")}
                />
                <ActionButton
                  defaultText="No answer"
                  onClick={() => handleOnlinePayment("NO_ANSWER_TEST_ONLINE_PAYMENT_RO")}
                />
              </>
            )}
            {!create && (
              <TicketsForCalling
                market={market}
                orderId={order._id.toString()}
              />
            )}
            <MoreInfo
              changeColor
              product={product?.short}
              email={contact.email}
              sendMail={translation.send}
              order={order}
              mailError={translation.mailError}
              mailSuccess={translation.mailSuccess}
            />
            <SupportMail order={order} />
          </div>{" "}
        </div>
        <form onSubmit={(e) => handleSaveOrder(e)}>
          <div style={{ display: "flex" }}>
            <div>
              <h4 style={{ margin: "20px" }}>
                <u>{translation.contactInfo}:</u>
              </h4>
              <Input
                inputRef={nameRef}
                name={translation.fullName}
                value={contact.full_name}
                required={true}
                width={100}
              />
              <Input
                name={translation.email}
                type="email"
                inputRef={emailRef}
                value={contact.email}
                required={true}
                width={100}
              />
              <Input
                name={translation.phone}
                inputRef={phoneRef}
                value={contact.phone_number}
                disabled
                width={100}
              />
              <Input
                name={translation.status}
                value={order.status}
                disabled
                width={100}
              />
              {!create && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <div style={{ display: "flex", gap: "20px" }}>
                    <ActionButton
                      color={Colors.orange}
                      onClick={(e) => {
                        setType("informed");
                        setShowConfirm(true);
                      }}
                      defaultText={translation.informed}
                    />
                    <ActionButton
                      disabled={!wasCommentAdded}
                      defaultText={translation.resign}
                      onClick={(e) => {
                        setType("resign");
                        setShowConfirm(true);
                      }}
                    />
                    <ActionButton
                      disabled={!wasCommentAdded}
                      onClick={(e) => {
                        setType("shift");
                        setShowConfirm(true);
                      }}
                      defaultText={translation.shift}
                    />
                    <ActionButton
                      defaultText={translation.confirm}
                      disabled={!wasCommentAdded}
                      onClick={() => {
                        if (order?.payment?.length > 0) {
                          setShowPaymentConfirm(true);
                          return;
                        }

                        setType("confirm");
                        setShowConfirm(true);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <h4 style={{ margin: "20px" }}>
                <u>{translation.shippingInfo}:</u>
              </h4>
              <Input
                inputRef={suggestedDeliveryDateRef}
                type="date"
                name={translation.suggested_delivery_date}
                value={
                  order.shipping?.suggested_delivery_date
                    ? moment(order.shipping.suggested_delivery_date).format(
                      "YYYY-MM-DD"
                    )
                    : moment().add(2, "days").format("YYYY-MM-DD")
                }
                required={true}
                width={250}
              />
              <Input
                inputRef={streetRef}
                name={translation.street}
                value={contact.street}
                required={true}
                width={250}
              />
              <Input
                name={translation.postalCode}
                inputRef={postalCodeRef}
                value={contact.postal_code}
                required={true}
                width={250}
              />
              <Input
                name={translation.city}
                inputRef={cityRef}
                value={contact.city}
                required={true}
                width={250}
              />
              <SelectInput
                name={translation.country}
                selected={selectedCountry}
                setSelected={setSelectedCountry}
                width={250}
                options={COUNTRIES}
              />
              <Input
                name={translation.countryCode}
                value={
                  selectedCountry.value ? selectedCountry.value : country.value
                }
                width={250}
                disabled={true}
              />
              <SelectInput
                name={translation.courier}
                selected={selectedCourier}
                setSelected={setSelectedCourier}
                options={courierOptions}
                width={250}
              />
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px" }}>
              <u>{translation.items}:</u>
            </h4>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: product ? "center" : "flex-start",
                gap: "20px",
                alignItems: "center",
                marginTop: "20px",
                flexWrap: product ? "nowrap" : "wrap",
              }}
            >
              {product ? <label
                style={{
                  fontSize: "20px",
                  display: "flex",
                  padding: "0px 30px",
                }}
              >
                <strong style={{ marginRight: "20px" }}>
                  {translation.name}:
                </strong>{" "}
                {product.name}
              </label>
                : <div style={{ width: "100%", display: 'flex' }}>
                  <SelectInput
                    name="Product"
                    width={80}
                    setSelected={selectProduct}
                    options={availableProductsOptions}
                  />
                </div>}
              <SelectInput
                name={translation.variant}
                width={80}
                selected={selectedOption}
                setSelected={setSelectedOption}
                options={product?.variants.map((varaint) => {
                  return { label: varaint.name, value: varaint.short };
                })}
              />
              <SelectInput
                name={translation.price}
                width={40}
                selected={selectedOptionPrice}
                setSelected={setSelectedOptionPrice}
                options={product?.prices.map((price) => {
                  return { label: price.value, value: price.value };
                })}
              />
              <div style={{ display: "flex", justifyContent: "right" }}>
                <ActionButton
                  onClick={(e) => hadnleAddProduct(e)}
                  defaultText={translation.add}
                />
              </div>
            </div>
            {chosenVariants.length > 0 && (
              <div
                style={{
                  marginTop: "30px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th style={{ fontSize: "20px", textAlign: "left" }}>
                        {translation.variant}
                      </th>
                      <th style={{ fontSize: "20px", textAlign: "center" }}>
                        {translation.short}
                      </th>
                      <th style={{ fontSize: "20px", textAlign: "center" }}>
                        {translation.price}
                      </th>
                      <th
                        style={{ fontSize: "20px", textAlign: "center" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {chosenVariants.map((data) => (
                      <tr style={{}}>
                        <td
                          style={{
                            width: "100px",
                            fontSize: "18px",
                            borderTop: "1px solid grey",
                            textAlign: "left",
                            padding: "11px 0px",
                          }}
                        >
                          {data.name}
                        </td>
                        <td
                          style={{
                            width: "200px",
                            fontSize: "18px",
                            borderTop: "1px solid grey",
                            textAlign: "center",
                          }}
                        >
                          {data.value}
                        </td>
                        <td
                          style={{
                            width: "200px",
                            fontSize: "18px",
                            borderTop: "1px solid grey",
                            textAlign: "center",
                          }}
                        >
                          {data.price} {market.currency}
                        </td>
                        <td
                          style={{
                            width: "80px",
                            fontSize: "18px",
                            borderTop: "1px solid grey",
                            textAlign: "center",
                          }}
                        >
                          <ActionButton
                            onClick={(e) => hadnleDelteProductItem(e, data._id)}
                            style={{ background: "none", border: "none" }}
                          >
                            <i
                              class="fa fa-remove"
                              style={{ color: "red", fontSize: "20px" }}
                            />
                          </ActionButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <SubmitButton
            style={{ float: "right" }}
            text={create ? translation.create : translation.save}
          />
        </form>
      </PopUp >
      {showConfirm && (
        <PopUp setShow={setShowConfirm}>
          <h5>Do you want to mark order as {type}?</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "50px",
              marginTop: "20px",
            }}
          >
            {type === "shift" && (
              <input
                onChange={(e) => setShiftDate(e.target.value)}
                type="datetime-local"
                defaultValue={shiftDate}
              />
            )}
            <ActionButton
              onClick={() => setShowConfirm(() => false)}
              defaultText="no"
            />
            <ActionButton
              onClick={(e) =>
                type === "shift"
                  ? setShowExtraShiftConfirm(true)
                  : handleMarkAsConsultantDecision(e)
              }
              color={Colors.green}
              defaultText="yes"
            />
          </div>
        </PopUp>
      )
      }
      {
        showExtraShiftConfirm && (
          <PopUp setShow={setShowExtraShiftConfirm}>
            <p style={{ fontSize: "32px" }}>
              NOW YOU ARE SELECTING: <br />
              <span
                style={{
                  fontSize: "36px",
                  color: Colors.red,
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {moment(shiftDate).format("YYYY-MM-DD HH:mm")}
              </span>
            </p>
            <h5>Are you sure you want to shift order?</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                marginTop: "20px",
              }}
            >
              <ActionButton
                onClick={() => setShowExtraShiftConfirm(() => false)}
                defaultText="no"
              />
              <ActionButton
                onClick={(e) => handleMarkAsConsultantDecision(e)}
                color={Colors.green}
                defaultText="yes"
              />
            </div>
          </PopUp>
        )
      }
      {
        itemsError && (
          <PopUp setShow={setItemsError}>
            <h3
              style={{
                maxWidth: "400px",
                color: Colors.red,
                textAlign: "center",
              }}
            >
              {itemsError === "item"
                ? "You cannot confirm order if there is no items!!!"
                : "If there is no promotion the starting price should be 5x higher"}
            </h3>
          </PopUp>
        )
      }
      {
        showPaymentConfirm && (
          <ConfirmPayment
            setClose={setShowPaymentConfirm}
            data={order}
          />
        )
      }
    </>
  );
};

export default OrderEditInbound;
