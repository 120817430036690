import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import React, { useRef } from "react";
import { ButtonWrapper } from "../../InvoiceConfig.styled";
import { preventFn } from "@/common/functions/preventEvent";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import {
  createInvoiceConfig,
  updateInvoiceConfig,
} from "@/API/repositories/invoiceConfig";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const InvoiceConfigForm = ({
  setShow,
  loadData,
  selectedInvoiceConfig,
}) => {
  const buyerNoteRef = useRef();
  const correctionReasonRef = useRef();
  const descriptionRef = useRef();
  const nameRef = useRef();
  const taxExemptRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleCreateKey = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.buyer_note = buyerNoteRef.current.value;
    payload.correction_reason = correctionReasonRef.current.value;
    payload.description = descriptionRef.current.value;
    payload._id = selectedInvoiceConfig?._id;
    payload.name = nameRef.current.value;
    payload.exempt_tax_kind = taxExemptRef.current.value;

    const response = selectedInvoiceConfig
      ? await makeRequest(updateInvoiceConfig.bind(null, payload))
      : await makeRequest(createInvoiceConfig.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setShow(() => false);
    addMessage("Success", "success");
    loadData();
  };

  return (
    <PopUp padding="10px 20px 20px" setShow={setShow}>
      {hasUnfilledRequest(updateInvoiceConfig, createInvoiceConfig) && (
        <Loading />
      )}
      <form onSubmit={(e) => handleCreateKey(e)}>
        <Input
          inputRef={nameRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Name"
          value={selectedInvoiceConfig?.name}
          placeholder="e.g CZ_INVOICE_CONFIG"
        />
        <Input
          inputRef={buyerNoteRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Buyer note"
          value={selectedInvoiceConfig?.buyer_note}
          placeholder="e.g IČO"
        />
        <Input
          inputRef={correctionReasonRef}
          required
          color={Colors.darkBlue}
          width={170}
          inputWidth={450}
          name="Correction reason"
          placeholder="e.g Order cancelation / Zrušení objednávky"
          value={selectedInvoiceConfig?.correction_reason}
        />
        <Input
          inputRef={descriptionRef}
          required
          name="Description"
          width={170}
          inputWidth={450}
          placeholder="e.g Reverse charge / Prenos daňovej povinnosti"
          color={Colors.darkBlue}
          value={selectedInvoiceConfig?.description}
        />
        <Input
          inputRef={taxExemptRef}
          name="Tax exempt"
          width={170}
          inputWidth={450}
          required
          color={Colors.darkBlue}
          value={selectedInvoiceConfig?.exempt_tax_kind}
        />
        <ButtonWrapper>
          <SubmitButton
            color={Colors.orange}
            text={`${
              selectedInvoiceConfig ? "Update" : "Create"
            } invoice config`}
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default InvoiceConfigForm;
