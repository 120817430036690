import styled from 'styled-components';

export const ContactWrapper = styled.div`
	padding: 20px;
	gap: 20px;
	flex-direction: column;
	display: flex;
`;

export const InfoBlocksWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 20px;
	justify-content: right;
`;

export const OrdersWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 850px;
`;

export const ContentBody = styled.div`
	display: flex;
	justify-content: center;
	gap: 30px;
	flex-wrap: wrap;
`;
