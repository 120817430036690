import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import SelectInput from '@/common/components/SelectInput'
import ToggleSwitch from '@/common/components/ToggleSwitch'
import React, { useRef, useState } from 'react'
import { ButtonWrapper } from '../../MailCategory.styled'
import SubmitButton from '@/common/components/buttons/SubmitButton'

const SEND_AFTER = [
  { label: "MINUTES", value: "minutes" },
  { label: "SECONDS", value: "seconds" },
  { label: "HOURS", value: "hours" },
  { label: "DAYS", value: "days" },
];

const GroupConfig = ({ 
  setShow, 
  config, 
  templatesOptions,
  setGroupConfigs,
  groupConfigs
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(
    templatesOptions.find(o => o.value === config.mail_template)
  );
  const [selectedSendType, setSelectedSendType] = useState(
    SEND_AFTER.find(s => s.value === config.send_after_type) || SEND_AFTER[0]);
  const [isControllGroup, setIsControllGroup] = useState(config.is_controll_group);

  const groupNameRef = useRef({ value: config?.name });
  const senderNameRef = useRef({ value: config?.sender_name });
  const emailRef = useRef({ value: config?.sender_email });
  const weight = useRef({ value: config?.weight });
  const subjectRef = useRef({ value: config?.subject });
  const sendAfterRef = useRef({ value: config?.send_after });

  const handleSave = (e) => {
    e && e.preventDefault();
  
    const group = {
      is_controll_group: isControllGroup,
      sender_name: senderNameRef.current?.value,
      sender_email: emailRef.current?.value,
      weight: Number(weight.current?.value),
      mail_template: selectedTemplate?.value,
      name: groupNameRef.current?.value,
      subject: subjectRef.current?.value,
      id: config?.id || Math.max(...groupConfigs.map(group => group.id), 0) + 1,
      send_after: Number(sendAfterRef.current?.value),
      send_after_type: selectedSendType.value,
    };


    if (config?.id) {
      setGroupConfigs(groupConfigs.map(g => {
        if (g.id === group.id) {
          return group;
        }
        return g;
      }));

      return setShow(false);
    }

    setGroupConfigs([...groupConfigs, group]);
    setShow(false);
  }

  return (
    <PopUp padding='10px 20px 20px' setShow={setShow}>
      <form onSubmit={handleSave}>
        <ToggleSwitch
          checked={isControllGroup}
          width={160}
          text="Is controll group"
          onChange={() => setIsControllGroup(!isControllGroup)}
        />
        <Input
          name="Group name"
          inputRef={groupNameRef}
          width={150}
          inputWidth={400}
          color={Colors.darkBlue}
          required
          requiredSign
          value={groupNameRef.current?.value}
        />
        <SelectInput
          name="Mail template"
          width={150}
          selectWidth={400}
          color={Colors.darkBlue}
          options={templatesOptions}
          setSelected={setSelectedTemplate}
          selected={selectedTemplate}
          required={!isControllGroup}
          disabled={isControllGroup}
        />
        <Input
          name="Sender name"
          inputRef={senderNameRef}
          width={150}
          inputWidth={400}
          color={Colors.darkBlue}
          required={!isControllGroup}
          requiredSign={!isControllGroup}
          disabled={isControllGroup}
          value={senderNameRef.current?.value}
        />
        <Input
          name="Sender email"
          type="email"
          inputRef={emailRef}
          width={150}
          inputWidth={400}
          color={Colors.darkBlue}
          required={!isControllGroup}
          requiredSign={!isControllGroup}
          disabled={isControllGroup}
          value={emailRef.current?.value}
        />
        <Input
          type="number"
          name="Weight"
          inputRef={weight}
          value={weight.current?.value}
          width={150}
          inputWidth={400}
          color={Colors.darkBlue}
          required
          requiredSign
        />
        <Input
          name="Subject"
          inputRef={subjectRef}
          width={150}
          inputWidth={400}
          color={Colors.darkBlue}
          value={subjectRef.current?.value}
          disabled={isControllGroup}
        />
        <div style={{ display: "flex", marginBottom: "90px" }}>
          <Input
            name="Send After"
            type="number"
            color={Colors.darkBlue}
            width={115}
            inputWidth={90}
            required={true}
            requiredSign={!isControllGroup}
            inputRef={sendAfterRef}
            value={sendAfterRef.current?.value}
            disabled={isControllGroup}
          />
          <SelectInput
            color={Colors.darkBlue}
            name={"Send After Type"}
            width={158}
            selectWidth={140}
            required={true}
            hideStar={isControllGroup}
            options={SEND_AFTER}
            selected={selectedSendType}
            setSelected={setSelectedSendType}
            disabled={isControllGroup}
          />
        </div>
        <ButtonWrapper>
          <SubmitButton
            text="Save"
          />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default GroupConfig
