import EditItem from "@/common/components/EditItem";
import Icon from "@/common/components/Icon";
import { addMailConditionalStyles } from "@/common/functions/addMailConditionalStyles";
import { addShippingConditionalStyles } from "@/common/functions/addShippingConditionalStyles";
import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";
import { getOrderInvoiceColor } from "@/common/functions/getOrderInvoiceColor";
import { getOrderItems } from "@/common/functions/getOrderItems";
import { TableItemHover } from "@/common/styles/Hover";
import { isCzechWithoutPromotion } from "./orderCheckers";

export const getRaws = ({
	dataToDisplay,
	setMailsTimeline,
	setShippingTimeline,
	handleShowOrderEdit,
	checkExtraCondition
}) => dataToDisplay.orders.map((data) => (
	<tr key={data?._id}>
		<td>
			{data?.status}
		</td>
		<td>
			{" "}
			{data?.shipping?.currency || data?.currency}
		</td>
		<td>
			{data?._product?.name}
		</td>
		<td>
			{getOrderItems(data).length
				? getOrderItems(data)
						.map((item) => item.product_variant)
						.join(", ")
				: "--------"}
		</td>
		<td>
			{getOrderItems(data).length
				? getOrderItems(data)
						.reduce((prev, next) => prev + next.price, 0)
						?.toFixed(2)
				: "------"}
		</td>
		<td>
			{data?.shipping?.status === "delivered_no_payment"
				? "delivered"
				: data.shipping?.status || "------"}
		</td>
		<td>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					gap: "10px",
				}}
			>
				<TableItemHover label="Invoice status by color">
					<Icon
						color={getOrderInvoiceColor(data?._invoice)}
						cursor="default"
						name="fa fa-file-invoice"
					/>
				</TableItemHover>
				<TableItemHover label="Mail history">
					<Icon
						onClick={() => setMailsTimeline(data?._mails)}
						name="fa fa-envelope"
						{...addMailConditionalStyles(data)}
					/>
				</TableItemHover>
				<TableItemHover label="Shipping history">
					<Icon
						onClick={() =>
							setShippingTimeline(data?.shipping)
						}
						name="fa fa-truck"
						{...addShippingConditionalStyles(data)}
					/>
				</TableItemHover>
			</div>
		</td>
		<td>
			<div>
				{(checkIsOrderEditable(data) || checkExtraCondition(data)) &&
					!isCzechWithoutPromotion() ? (
					<div
						onClick={(e) =>
							handleShowOrderEdit(e, data, false)
						}
					>
						<EditItem width={30} />
					</div>
				) : data?.payment?.length > 0 ? (
					<div
						onClick={(e) =>
							handleShowOrderEdit(e, data, false)
						}
					>
						<EditItem width={30} />
					</div>
				) : (
					"------"
				)}
			</div>
		</td>
	</tr>
))