import { SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React, { useState } from 'react'
import { DocumentItem, DocumentsListWrapper, StyledText, StyledTextImportant } from './Documents.styled'
import Icon from '@/common/components/Icon';
import { getFileById } from '@/API/repositories/storedDocument';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { removeTimestamp } from './helpers/removeTimestamp';
import { useValidClaim } from '@/common/hooks/useValidClaim';

const Documents = () => {
  const [loadingFiles, setLoadingFiles] = useState([]);

  const { makeRequest } = useRequestsContext();
  const { claim } = useValidClaim();

  const downloadFile = async (storedDataId) => {
    if (loadingFiles.includes(storedDataId)) {
      return;
    }

    setLoadingFiles((prev) => [...prev, storedDataId]);

    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", removeTimestamp(response.data.name));
      document.body.appendChild(link);
      link.click();
    }

    setLoadingFiles((prev) => prev.filter((el) => el !== storedDataId));
  };

  return (
    <SectionWrapper>
      <Subtitle>
        Documents:
      </Subtitle>
      {claim?._stored_documents?.length
        ? (
          <DocumentsListWrapper>
            {claim._stored_documents.map((storedDocument, i) => (
              <DocumentItem onClick={() => downloadFile(storedDocument._id)} key={storedDocument._id}>
                <StyledText>
                  {i + 1}. {removeTimestamp(storedDocument.id_in_bucket)}
                </StyledText>
                <Icon size={14} name={loadingFiles.includes(storedDocument._id) ? "fa fa-spinner fa-spin" : "fa fa-download"} />
              </DocumentItem>
            ))}
          </DocumentsListWrapper>
        )
        : (
          <StyledTextImportant>
            No documents yet
          </StyledTextImportant>
        )}
    </SectionWrapper>
  )
}

export default Documents
