import React, { useEffect, useMemo, useState } from "react";
import {
  getContactWithOrders,
  saveConsultantContact,
} from "../../../API/repositories/contact";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import ContactForm from "./components/contactForm/ContactForm";
import { getProductByUserQueue } from "@/API/repositories/product";
import userManager from "@/API/userManager";
import { useParams } from "react-router-dom";
import { CardWrapper, OrdersWrapper, TableWrapper, Title, Wrapper } from "./ConsultantContact.styled";
import ContactTable from "./components/contactTable/ContactTable";
import OrderEdit from "./components/orderEdit/OrderEdit";

const ConsultantContact = () => {
  const { id } = useParams();

  const [dataToDisplay, setDataToDisplay] = useState(null);
  const [country, setCountry] = useState();
  const [create, setCreate] = useState(false);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState({});

  const { commonData: { markets } } = useCommonDataContext();
  const { COUNTRIES } = useCommonDataContext();

  const market = useMemo(() => {
    return markets.find(m => m.short === dataToDisplay?.country)
  }, [dataToDisplay])

  const handleShowOrderEdit = (e, data, create) => {
    e.preventDefault();
    setCreate(() => create);
    setOrder(() => data);
    setShow(() => true);
  };

  const handleGetContactWithOrders = async () => {
    const response = await makeRequest(getContactWithOrders.bind(null, id));
    const responseProduct = await makeRequest(
      getProductByUserQueue.bind(null, userManager.getUser().id)
    );

    if (response.data && responseProduct.data) {
      setCountry(
        COUNTRIES.find((country) => country.value === response.data.country)
      );

      response.data.orders.forEach((order) => {
        order.showEdit = false;

        let varaints = "";

        order.order_items?.forEach((item, i) => {
          if (i === 0) {
            varaints += item.product_variant;
          } else {
            varaints += " ," + item.product_variant;
          }
        });

        order.varaintsName = varaints !== "" ? varaints : "-------";

        if (order.shipping) {
          if (order.shipping.status && order.shipping.status !== "to_send") {
            order.showEdit = true;
          }
        }
      });

      setDataToDisplay(() => response.data);
    } else {
      setDataToDisplay(() => null);
    }
  };

  useEffect(() => {
    handleGetContactWithOrders();
  }, [show]);

  return (
    <Wrapper>
      {hasUnfilledRequest(
        saveConsultantContact,
        createSuspicionTicket,
        getContactWithOrders
      ) && <Loading />}
      {dataToDisplay && (
        <CardWrapper>
          <ContactForm
            reload={handleGetContactWithOrders}
            dataToDisplay={dataToDisplay}
            setDataToDisplay={setDataToDisplay}
            country={country}
            setCountry={setCountry}
            show={show}
          />
          <OrdersWrapper>
            <Title>
              Orders:
            </Title>
            <TableWrapper>
              <ContactTable
                dataToDisplay={dataToDisplay}
                handleShowOrderEdit={handleShowOrderEdit}
              />
            </TableWrapper>
          </OrdersWrapper>
        </CardWrapper>
      )}
      {show && (
        <OrderEdit
          order={order}
          setShow={setShow}
          setOrder={setOrder}
          contact={dataToDisplay}
          country={country}
          market={market}
          create={create}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantContact;
