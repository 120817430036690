import { getGamificationStatsByMarket } from "@/API/repositories/gamificationStats";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  AllDataContainer,
  NoDataWrapper,
  Title,
  UpdatedWrapper,
  Wrapper,
} from "./Gamification.styled";
import Tabs from "./components/tabs/Tabs";
import User from "./components/user/User";
import Market from "./components/market/Market";
import { TABS } from "./constants/tabs";
import userManager from "@/API/userManager";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { compareDates } from "@/common/functions/compareDates";
import { getGamificationConfigForUser } from "@/API/repositories/gamificationConfig";
import { findUserMarket } from "@/common/functions/findUserMarket";
import moment from "moment";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Gamification = () => {
  const [userData, setUserData] = useState();
  const [sumUpData, setSumUpData] = useState();
  const [selectedTab, setSelectedTab] = useState(TABS.user);
  const [marketGamification, setMarketGamification] = useState();
  const [gamificationConfig, setGamificationConfig] = useState();

  const { translation } = useTranslationContext();

  const currentUserId = userManager.getUser().id;
  const {
    commonData: { markets, queues },
  } = useCommonDataContext();

  const userMarket = findUserMarket(markets, queues, currentUserId);

  const { makeRequest } = useRequestsContext();

  const loadConfig = async () => {
    const response = await makeRequest(getGamificationConfigForUser);

    if (response.data) {
      setGamificationConfig(() => response.data);
    }
  };

  const loadMarketsData = async () => {
    const response = await makeRequest(
      getGamificationStatsByMarket.bind(null, userMarket._id)
    );

    if (response.data) {
      setMarketGamification(() => response.data.usersStats);
      setUserData(() =>
        response.data.usersStats.find(
          (stats) => stats?.consultant === currentUserId
        )
      );
      setSumUpData(() =>
        response.data.sumUpStats.sort((monthStatsA, monthStatsB) =>
          compareDates(
            new Date(monthStatsA?.month),
            new Date(monthStatsB?.month)
          )
        )
      );
    }
  };

  useLayoutEffect(() => {
    loadConfig();
  }, []);

  useEffect(() => {
    if (userMarket) {
      loadMarketsData();
    }
  }, [userMarket]);

  return gamificationConfig && userData && !!marketGamification?.length ? (
    <Wrapper>
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {
        <>
          <AllDataContainer
            flexDirection={`${
              selectedTab === TABS.user ? "row" : "row-reverse"
            }`}
          >
            <User
              gamificationConfig={gamificationConfig}
              sumUpData={sumUpData}
              selectedTab={selectedTab}
              userData={userData}
            />
            <Market
              selectedTab={selectedTab}
              marketGamification={marketGamification}
            />
          </AllDataContainer>
          <UpdatedWrapper>
            {translation.updated}:{" "}
            {moment(userData.updatedAt).format("DD.MM HH:mm")}
          </UpdatedWrapper>
        </>
      }
    </Wrapper>
  ) : (
    <Wrapper>
      <NoDataWrapper>
        <Title>No data</Title>
      </NoDataWrapper>
    </Wrapper>
  );
};

export default Gamification;
