import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import React, { useMemo, useState } from "react";
import { Title } from "../calendar.styles";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";
import { Colors } from "@/common/colors/colors";
import { createDateForBackend } from "@/common/functions/createDateForBackend";
import moment from "moment";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import ActionButton from "@/common/components/buttons/ActionButton";
import Icon from "@/common/components/Icon";
import SelectInput from "@/common/components/SelectInput";
import { HOURS_OPTIONS } from "../consts/hoursOptionsConst";
import * as MomentRange from 'moment-range';
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { WORKING_OPTIONS } from "@/common/constants/workingOptions";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";
const momentRange = MomentRange.extendMoment(moment);

const NewAddWorkedHours = ({
  bonuses,
  selectedMonth,
  handleNewSaveWorkedHours,
  setAddWorkingHoursNew,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [selectedType, setSelectedType] = useState();
  const minDate = formatToDateTamplate(
    moment(selectedMonth).startOf("month").toDate()
  );
  const maxDate = formatToDateTamplate(
    moment(selectedMonth).endOf("month").toDate()
  );
  const [addType, setAddType] = useState();
  const [types, setTypes] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const { addMessage } = useMessageQueueContext();
  const { options: { productsOptions }} = useCommonDataContext();

  const availableBonuses = useMemo(() => {
    const bonusesProductMap = new Map();

    if (!dateFrom || !dateTo) return; 

    const daysRange = Array.from(momentRange.range(dateFrom, dateTo).by("day"));

    daysRange.forEach((day) => {
      const date = new Date(day.toDate());

      bonuses.forEach((bonus) => {
        const validFrom = new Date(bonus.valid_from);
        const validTo = new Date(bonus.valid_to);

        if (validFrom <= date) {
          const currentBonus = bonusesProductMap.get(`${bonus.product}_${day.format('YYYY-MM-DD')}`);

          const currentBonusValidTo = new Date(currentBonus?.valid_to);

          if (currentBonus && (currentBonusValidTo >= validTo)) {
            return;
          }

          bonusesProductMap.set(`${bonus.product}_${day.format('YYYY-MM-DD')}`, bonus);
        }
      });
    });


    Array.from(bonusesProductMap.entries()).forEach(([key, value]) => {
      const productKeyParts = key.split('_');

      const canContinue = Array.from(momentRange.range(dateFrom, dateTo).by("day")).every((day) => {
        const productBonus = bonusesProductMap.get(`${productKeyParts[0]}_${day.format('YYYY-MM-DD')}`);
        return productBonus?._id === value?._id;
      });

      if (!canContinue) {
        bonusesProductMap.delete(key);
      }
    });

    const values = Array.from(new Set(bonusesProductMap.values()));

    if (!values?.length) {
      addMessage("Check bonuses of this consultant", "error")
      return;
    }

    return values;

  }, [bonuses, dateFrom, dateTo]);

  const handleAddType = (e) => {
    e && e.preventDefault();

    const payload = {
      id: types.length + 1,
      from: selectedFrom.label,
      to: selectedTo.label,
      fromValue: selectedFrom.value,
      toValue: selectedTo.value,
      type: selectedType.value,
      bonusId: availableBonuses.find(b => b.product === selectedProduct.value)?._id,
    };

    setTypes((prev) => [...prev, payload]);
    setSelectedFrom(null);
    setSelectedTo(null);
    setAddType(null);
    setSelectedType(null);
    setSelectedProduct(null);
  };

  const handleDeleteHourType = (id) => {
    setTypes((prev) =>
      prev
        .filter((typeId) => typeId.id !== id)
        .map((type, i) => ({ ...type, id: i + 1 }))
    );
  };

  const getProductLabelByBonusId = (bonusId) => {
    const option = productsOptions.find(o => {
      const bonus = availableBonuses.find(b => b._id === bonusId);

      return o.value === bonus?.product;
    });

    if (!option) {
      addMessage("Product not found, check bonuses and try again", "error");
      setDateFrom(null);
      setDateTo(null);
      setTypes([]);
      setSelectedFrom(null);
      setSelectedTo(null);
    }

    return option?.label;
  }

  const handleSave = (e) => {
    e && e.preventDefault();

    if (types.length === 0) return;

    const payload = {};

    const resultTypes = {};

    types.forEach((type) => {
      const amount = type.toValue - type.fromValue;

      [...Array(amount).keys()].forEach((i) => {
        const time = 510 + (i + type.fromValue) * 30;
        const seconds =
          (time % 60).toString().length === 1 ? (time % 60) + "0" : time % 60;

        const resultHour = `${Math.floor(time / 60)}:${seconds}`;

        resultTypes[resultHour] = {
          sign: type.type,
          bonusId: type.bonusId,
        }
      });
    });

    payload.from = createDateForBackend(dateFrom);
    payload.to = createDateForBackend(dateTo);
    payload.type = Object.entries(resultTypes).map(([hour_time, hourData]) => ({
      hour_time,
      sign: hourData.sign,
      bonusId: hourData.bonusId,
    }));

    handleNewSaveWorkedHours(payload);
  };

  return (
    <>
      <PopUp setShow={setAddWorkingHoursNew}>
        <Title>Add Hours</Title>
        <form
          onSubmit={(e) => handleSave(e)}
          style={{ display: "grid", placeItems: "center" }}
        >
          <Input
            onChange={(e) => setDateFrom(e.target.value)}
            value={dateFrom}
            controlled
            width={50}
            name="From"
            required={true}
            color={Colors.darkBlue}
            inputWidth={140}
            type="date"
            min={minDate}
            max={maxDate}
          />
          <Input
            controlled
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
            width={50}
            name="To"
            required={true}
            color={Colors.darkBlue}
            inputWidth={140}
            type="date"
            min={minDate}
            max={maxDate}
          />
          <table
            className="styled-table-dashboard"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>From</th>
                <th>To</th>
                <th>Type</th>
                <th>Product</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="queue">
              {types.map((type) => (
                <tr key={type.id}>
                  <td>{type.id}</td>
                  <td>{type.from}</td>
                  <td>{type.to}</td>
                  <td>{type.type}</td>
                  <td>{getProductLabelByBonusId(type?.bonusId)}</td>
                  <td>
                    <Icon
                      name="fa fa-trash"
                      color={Colors.red}
                      onClick={() => handleDeleteHourType(type.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ButtonWrapper style={{ marginTop: "40px", gap: "20px" }}>
            <ActionButton
              disabled={!dateFrom || !dateTo}
              defaultText={<Icon name={"fa fa-plus"} />}
              style={{ marginBottom: "-30px", width: "30px" }}
              onClick={() => {
                setSelectedProduct(null);
                setAddType(true);
              }}
            />
            <SubmitButton 
              style={{ marginBottom: "-30px" }} 
              text={"Save"} 
              width={100} 
            />
          </ButtonWrapper>
        </form>
      </PopUp>
      {addType && (
        <PopUp setShow={setAddType}>
          <Title>Add type</Title>
          <form
            onSubmit={(e) => handleAddType(e)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "600px",
              height: "300px",
            }}
          >
            <SelectInput
              name="Product"
              required
              color={Colors.darkBlue}
              width={90}
              options={productsOptions?.filter((option) => availableBonuses?.some(b => b.product === option.value))}
              setSelected={setSelectedProduct}
              selected={selectedProduct}
            />
            <SelectInput
              name={"Type"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={WORKING_OPTIONS}
              setSelected={setSelectedType}
              selected={selectedType}
            />
            <SelectInput
              name={"From"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={HOURS_OPTIONS}
              setSelected={setSelectedFrom}
              selected={selectedFrom}
            />
            <SelectInput
              name={"To"}
              required={true}
              color={Colors.darkBlue}
              width={90}
              options={HOURS_OPTIONS}
              setSelected={setSelectedTo}
              selected={selectedTo}
            />
            <ButtonWrapper>
              <SubmitButton style={{ marginBottom: "-30px" }} text={"Save"} />
            </ButtonWrapper>
          </form>
        </PopUp>
      )}
    </>
  );
};

export default NewAddWorkedHours;
