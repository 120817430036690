import Input from '@/common/components/Input'
import React, { useRef, useState } from 'react'
import { ButtonsWrapper, Flex } from '../../GradeReport.styled'
import SelectInput from '@/common/components/SelectInput'
import CSV from '@/common/components/CSV'
import moment from 'moment'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { getGradeReport } from '@/API/repositories/reports'
import Loading from '@/common/components/Loading'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { getCSVData } from './helpers/getCSVData'
import { HEADERS } from './constants/headers'
import Card from '@/common/components/Card'
import ActionButton from '@/common/components/buttons/ActionButton'

const SearchBar = ({
	data,
	consultants,
	setData,
}) => {
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);

	const downloadStartDateRef = useRef();
  const downloadEndDateRef = useRef();
  const createdStartDateRef = useRef();
  const createdEndDateRef = useRef();

	const { addMessage } = useMessageQueueContext();

  const downloadStartDate = moment()
    .subtract("35", "days")
    .format("YYYY-MM-DD");
  const downloadEndDate = moment().subtract("7", "days").format("YYYY-MM-DD");

  const createdStartDate = moment()
    .subtract("6", "months")
    .format("YYYY-MM-DD");
  const createdEndDate = moment().format("YYYY-MM-DD");

	const { commonData: { queues }, options: { queuesOptions } } = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const handleGetReport = async () => {
    setData(() => null);
    const payload = Object();

    if (!selectedConsultants.length) {
      addMessage("Select consultants", "error");

      return;
    }

    payload.from = downloadStartDateRef.current
      ? moment(downloadStartDateRef.current.value).startOf("day")
      : moment(downloadStartDate).startOf("day");
    payload.to = downloadEndDateRef.current
      ? moment(downloadEndDateRef.current.value).endOf("day")
      : moment(downloadEndDate).endOf("day");
    payload.fromContact = createdStartDateRef.current
      ? moment(createdStartDateRef.current.value).startOf("day")
      : moment(createdStartDate).startOf("day");
    payload.toContact = createdEndDateRef.current
      ? moment(createdEndDateRef.current.value).endOf("day")
      : moment(createdEndDate).endOf("day");
    payload.weights = true;
    payload.ids = selectedConsultants.map((data) => data.value);

    const response = await makeRequest(getGradeReport.bind(null, payload));

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      consultants.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

	return (
		<Card>
			{hasUnfilledRequest(getGradeReport) && <Loading />}
			<div style={{ maxWidth: "80vw" }}>
				<div style={{ display: "flex" }}>
					<div>
						<Input
							inputRef={downloadStartDateRef}
							name="Download date from"
							type="date"
							width={240}
							value={downloadStartDate}
							inputWidth={220}
						/>
						<Input
							inputRef={downloadEndDateRef}
							name="Download date to"
							type="date"
							width={240}
							value={downloadEndDate}
							inputWidth={220}
						/>
					</div>
					<div>
						<Input
							inputRef={createdStartDateRef}
							name="Created date from"
							type="date"
							width={240}
							value={createdStartDate}
							inputWidth={220}
						/>
						<Input
							inputRef={createdEndDateRef}
							name="Created date to"
							type="date"
							width={240}
							value={createdEndDate}
							inputWidth={220}
						/>
					</div>
				</div>

				<Flex>
					<SelectInput
						multiple={true}
						name="Consultants"
						options={consultants}
						width={220}
						setSelected={setSelectedConsultants}
						selected={selectedConsultants}
						selectWidth={240}
					/>{" "}
					<SelectInput
						multiple={true}
						name="Consultants from queue"
						options={queuesOptions}
						width={220}
						selectWidth={240}
						setSelected={setSelectedQueue}
						selected={selectedQueues}
					/>{" "}
				</Flex>
			</div>
			<ButtonsWrapper>
				{data && (
					<CSV
						filename={"report-grade-all.csv"}
						data={getCSVData(data, consultants)}
						header={HEADERS}
					/>
				)}
				<ActionButton
					onClick={(e) => handleGetReport(e)}
					defaultText="Search"
				/>
			</ButtonsWrapper>
		</Card>
	)
}

export default SearchBar
