import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCommonDataContext } from "../../hooks/commonDataContext";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import Table from "../Table";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { Wrapper } from "./ConsultantHoursTable.styled";

const ConsultantHoursTable = ({ data, month }) => {
  const {
    commonData: { markets },
  } = useCommonDataContext();
  const [workingHours, setWorkingHours] = useState(data.data);
  const freeDays =
    findMarketByContractType(markets, data._user)?.free_days || [];

  const firstDayOfTheMonth = moment(month.toDate()).startOf("month");

  const workingDays = Array.from(Array(firstDayOfTheMonth.daysInMonth()).keys())
    .map(
      (number, i) =>
        !["Sunday", "Saturday"].includes(
          firstDayOfTheMonth.clone().add(number, "day").format("dddd")
        ) &&
        !freeDays
          .map((d) => d.date)
          .includes(
            firstDayOfTheMonth.clone().add(number, "day").format("YYYY-MM-DD")
          )
    )
    .filter((result) => result !== false).length;

  useEffect(() => {
    setWorkingHours(() => data.data);
  }, [data]);

  return (
    <Wrapper>
      <Table
        className="styled-table tableWrap"
        headers={HEADERS}
        raws={getRaws(workingHours, data._month_working_info || {}, workingDays)}
      />
    </Wrapper>
  );
};

export default ConsultantHoursTable;
