import PopUp from "@/common/components/PopUp";
import React from "react";
import { FlexRight, MarginBottomContainter } from "../claimEditHrView.styles";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";

const AddCorrectionComponent = ({
  setIsAddCorrectionOpen,
  data,
  correctionNumberRef,
  handleUpdatingInvoiceCorrectionNumber,
}) => {
  return (
    <PopUp setShow={setIsAddCorrectionOpen}>
      <MarginBottomContainter>
        <h3 style={{ color: Colors.darkBlue }}>Add correction number</h3>
      </MarginBottomContainter>
      <Input
        inputRef={correctionNumberRef}
        showLabel={false}
        color={Colors.darkBlue}
        width={180}
        value={data._ticket.invoice.correction_number}
      />
      <FlexRight>
        <ActionButton
          onClick={() => handleUpdatingInvoiceCorrectionNumber()}
          defaultText="Save"
        />
      </FlexRight>
    </PopUp>
  );
};

export default AddCorrectionComponent;
