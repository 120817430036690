export const getTitleOfPercentPoints = (percent) => {
  if (percent < 10) {
    return getTitleOfPercentPoints(10);
  }

  if (percent < 20) {
    return getTitleOfPercentPoints(20);
  }

  if (percent < 30) {
    return getTitleOfPercentPoints(30);
  }

  if (percent < 40) {
    return getTitleOfPercentPoints(40);
  }

  if (percent < 50) {
    return getTitleOfPercentPoints(50);
  }

  if (percent < 60) {
    return getTitleOfPercentPoints(60);
  }

  if (percent < 70) {
    return getTitleOfPercentPoints(70);
  }

  if (percent < 80) {
    return getTitleOfPercentPoints(80);
  }
  
  if (percent < 90) {
    return getTitleOfPercentPoints(90);
  }

  return getTitleOfPercentPoints(100);
}