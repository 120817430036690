import Table from '@/common/components/Table'
import React, { useMemo } from 'react'
import { HEADERS } from '../../../pages/admin/ccHours/components/consultantMonth/constants/headers'
import { getRaws } from './helpers/getRaws'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'

const HoursTable = ({
  data,
  filterRegex,
  selectedConsultant,
  consultants,
  setShowConsultantMonth,
  selectedMonth,
  accepted,
  selectedCompany
}) => {
  const { commonData: { markets } } = useCommonDataContext();

  const raws = useMemo(() => getRaws({
    data,
    filterRegex,
    selectedConsultant,
    consultants,
    setShowConsultantMonth,
    selectedMonth,
    accepted,
    selectedCompany,
    markets
  }), [
    data,
    filterRegex,
    selectedConsultant,
    consultants,
    setShowConsultantMonth,
    selectedMonth,
    accepted,
    selectedCompany,
    markets
  ])

  return (
    !!raws.length && (
      <Table
        className="styled-table-2 tableWrap"
        headers={HEADERS}
        raws={raws}
      />
    )
  )
}

export default HoursTable
