import React, { useState } from "react";
import { getEffectivePaymentReport } from "../../../API/repositories/reports";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import EffectivePaymentTable from "../../consultant/salary/EffectivePaymentTable";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { TableWrapper, Wrapper } from "./PaymentReport.styled";
import SearchBar from "./components/searchBar/SearchBar";

const PaymentReport = () => {
  const [data, setData] = useState();

  const { queryValues: { isLoading } } = useCommonDataContext();
  const { hasUnfilledRequest } = useRequestsContext();

  return (
    <Wrapper>
      {hasUnfilledRequest(getEffectivePaymentReport) && <Loading />}
      {!isLoading ? (
        <SearchBar data={data} setData={setData} />
      ) : (
        <Loading />
      )}
      {data && (
        <TableWrapper>
          {data.map((data) => (
            <EffectivePaymentTable data={data} csv={true} />
          ))}
        </TableWrapper>
      )}
    </Wrapper>
  );
};

export default PaymentReport;
