import moment from "moment";
import React, { useRef, useState } from "react";
import {
  markInvoiceStatusAsDone,
  markInvoiceStatusAsIssued,
  markInvoiceStatusAsToCorrect,
  markInvoiceStatusCorrected,
  updateInvoiceTicket,
  addMessageToTicket,
  markTicketAsOverdue,
} from "../../../../../API/repositories/tickets";
import { Colors } from "../../../../../common/colors/colors";
import Input from "../../../../../common/components/Input";
import PopUp from "../../../../../common/components/PopUp";
import SelectInput from "../../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { PRIORITIES } from "../../../../../common/constants/priorities";
import { useCommonDataContext } from "../../../../../common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import { DataStrong, Flex, H5, MessagesBox, UserWrapper } from "./InvoiceEdit.styled";

const vatAmountOptions = [
  {
    label: "ZW",
    value: "zw",
  },
  {
    label: "0%",
    value: "0%",
  },
];

const MANAGER = "637e2e7155ea52a5bf393a3f";

const InvoiceEdit = ({ setShow, data, handleSearch }) => {
  const { makeRequest } = useRequestsContext();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const issueDateRef = useRef();
  const vatAmountRef = useRef();
  const invoiceNumberRef = useRef();
  const correctionNumberRef = useRef();
  const companyRef = useRef();
  const postalCodeRef = useRef();
  const companyStreetAndNumberRef = useRef();
  const cityRef = useRef();
  const vatIdRef = useRef();
  const companyIdRef = useRef();
  const [messageSucces, setMessageSuccess] = useState();
  const [selectedVatAmount, setSelectedVatAmmount] = useState(
    vatAmountOptions.find((o) => o.value === data.invoice.vat_amount)
  );
  const [selectedPriority, setSelectedPriority] = useState(
    PRIORITIES.find((priority) => priority.value === data.invoice.priority) || {
      label: "none",
      value: "none",
    }
  );
  const { addMessage } = useMessageQueueContext();
  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  const textareaRef = useRef();

  const handleAddCommentToTicket = async (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.message = textareaRef.current.value;

    const response = await makeRequest(
      addMessageToTicket.bind(null, data._id, payload)
    );

    if (response.data) {
      // add message wyslano
      setMessageSuccess(() => true);
      await handleSearch();
      setTimeout(() => {
        setMessageSuccess(false);
      }, [3000]);
      textareaRef.current.value = "";
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {};

    payload.issue_date = issueDateRef.current?.value
      ? moment(issueDateRef.current?.value).toISOString()
      : null;
    payload.vat_amount = selectedVatAmount?.value || data.vat_amount;
    payload.invoice_number =
      invoiceNumberRef.current?.value || data.invoice_number;
    payload.correction_number =
      correctionNumberRef.current?.value || data.invoice.correction_number;
    payload.company_name = companyRef.current?.value || data.comapny_name;
    payload.priority = selectedPriority.value;
    payload.company_street_and_number =
      companyStreetAndNumberRef.current?.value ||
      data.company_street_and_number;
    payload.company_postal_code =
      postalCodeRef.current?.value || data.company_postal_code;
    payload.company_city = cityRef.current?.value || data.company_city;
    payload.vat_number = vatIdRef.current?.value || data.vat_number;
    payload.other_number = companyIdRef.current?.value || data.other_number;
    payload.price = data._order.order_items.reduce(
      (prev, next) => prev + next.price,
      0
    );
    payload.sold_date =
      data.sold_date ||
      data._order.shipping?.status_delivered_at ||
      data._order.payment?.find(p => p.status_complated_date)?.status_complated_date;

    const response = await makeRequest(
      updateInvoiceTicket.bind(null, data._id, payload)
    );

    if (response.data && typeof response.data === "string") {
      const errorMessages = response.data.split(":");

      addMessage(
        errorMessages[0].split(" ").slice(1, -1).join(" ") +
        ": {" +
        errorMessages.at(-1),
        "error"
      );

      return;
    }

    if (response.data) {
      await handleSearch();
      setSuccess(() => "save");
      setTimeout(() => {
        setSuccess(() => null);
      }, 2000);
    } else {
      setError(() => "save");
      setTimeout(() => {
        setError(() => null);
      }, 2000);
    }
  };

  const handleMadeClaim = async (e) => {
    e.preventDefault();
  };

  const markInvoiceToCorrect = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(
      markInvoiceStatusAsToCorrect.bind(null, data._id)
    );

    if (response.data) {
      await handleSearch();
      setShow(() => null);
    }
  };
  const markInvoiceAsCorrected = async (e) => {
    e && e.preventDefault();

    await handleSave(e);

    const response = await makeRequest(
      markInvoiceStatusCorrected.bind(null, data._id)
    );

    if (response.data) {
      await handleSearch();
      setShow(() => null);
    }
  };
  const markInvoiceAsIssued = async (e) => {
    e && e.preventDefault();

    await handleSave(e);

    if (
      !data.invoice.issue_date ||
      !data.invoice.vat_amount ||
      !data.invoice.invoice_number
    ) {
      return;
    }

    const response = await makeRequest(
      markInvoiceStatusAsIssued.bind(null, data._id)
    );

    if (response.data) {
      await handleSearch();
      setShow(() => null);
    }
  };
  const markInvoiceAsDone = async (e) => {
    e && e.preventDefault();

    const response = await makeRequest(
      markInvoiceStatusAsDone.bind(null, data._id)
    );

    if (response.data) {
      await handleSearch();
      setShow(() => null);
    }
  };

  const handleMarkTicketAsOverdue = async () => {
    const payload = {};

    payload.overdue_follower = MANAGER;

    const response = await makeRequest(
      markTicketAsOverdue.bind(null, data._id, payload)
    );

    if (response.data) {
      setSuccess("overdue");
      setTimeout(() => {
        setSuccess(() => null);
      }, 2000);
    }
  };

  return (
    <PopUp setShow={setShow}>
      <div style={{ margin: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <h3 style={{ color: Colors.darkBlue }}>Invoice:</h3>
          <i
            style={{
              color: Colors.darkBlue,
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "30px",
            }}
            className="fa fa-address-card animation-scale"
            onClick={() =>
              window.open(
                "https://crm.europa-edu.eu/dashboard/order/" + data.order,
                {
                  traget: "_blank",
                }
              )
            }
          ></i>
        </div>
        <form onSubmit={handleMadeClaim}>
          <Flex>
            <div>
              <Flex>
                <Input
                  inputRef={issueDateRef}
                  color={Colors.darkBlue}
                  width={210}
                  value={
                    data.invoice.issue_date &&
                    moment(data.invoice.issue_date).format("YYYY-MM-DD")
                  }
                  name="Issue date"
                  type="date"
                />
              </Flex>

              <Flex>
                <SelectInput
                  inputRef={vatAmountRef}
                  color={Colors.darkBlue}
                  width={210}
                  options={vatAmountOptions}
                  selected={selectedVatAmount}
                  setSelected={setSelectedVatAmmount}
                  name="VAT amount "
                />
              </Flex>

              <Flex>
                <Input
                  inputRef={invoiceNumberRef}
                  color={Colors.darkBlue}
                  width={210}
                  value={data.invoice.invoice_number}
                  name="Invoice number"
                />
              </Flex>

              <Flex>
                <Input
                  inputRef={correctionNumberRef}
                  color={Colors.darkBlue}
                  width={210}
                  value={data.invoice.correction_number}
                  name="Correction number"
                />
              </Flex>

              <Flex>
                <H5>Mail:</H5>
                <DataStrong>{data._contact.email}</DataStrong>
              </Flex>

              <Flex>
                <H5>Consultant:</H5>
                <DataStrong>{data._order._user.username}</DataStrong>
              </Flex>

              <Flex>
                <H5>Product:</H5>
                <DataStrong>{data._product.name}</DataStrong>
              </Flex>
            </div>
            <div>
              <Input
                inputRef={companyRef}
                width={210}
                color={Colors.darkBlue}
                name="Company name"
                value={data.invoice.comapny_name}
              />

              <Input
                inputRef={companyStreetAndNumberRef}
                width={210}
                color={Colors.darkBlue}
                name="Company street and number"
                value={data.invoice.company_street_and_number}
              />

              <Input
                inputRef={postalCodeRef}
                width={210}
                color={Colors.darkBlue}
                name="Company postal code"
                value={data.invoice.company_postal_code}
              />

              <Input
                inputRef={cityRef}
                width={210}
                color={Colors.darkBlue}
                name="Company city"
                value={data.invoice.company_city}
              />

              <Input
                inputRef={vatIdRef}
                width={210}
                color={Colors.darkBlue}
                name="VAT ID"
                value={data.invoice.vat_number}
              />

              <Input
                inputRef={companyIdRef}
                width={210}
                color={Colors.darkBlue}
                name="Company ID "
                value={data.invoice.other_number}
              />

              <SelectInput
                width={210}
                color={Colors.darkBlue}
                name="Priority"
                selected={selectedPriority}
                setSelected={setSelectedPriority}
                options={PRIORITIES}
              />

              <Flex>
                <H5>Price:</H5>
                <DataStrong>
                  {data._order.order_items.reduce(
                    (prev, next) => prev + next.price,
                    0
                  )}{" "}
                  {data._order.currency}
                </DataStrong>
              </Flex>

              <Flex>
                <H5>Sold date:</H5>
                <DataStrong>
                  {data._order.shipping?.status_delivered_at
                    ? moment(data._order.shipping.status_delivered_at).format(
                      "YYYY-MM-DD"
                    )
                    : "------"}
                </DataStrong>
              </Flex>
            </div>
          </Flex>
        </form>
      </div>
      <div style={{ margin: "-40px 80px" }}>
        <div>
          <ActionButton
            onClick={() => handleMarkTicketAsOverdue()}
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginBottom: "10px",
            }}
            color={success === "overdue" ? Colors.green : Colors.orange}
          >
            Overdue
            <i className="fa fa-exclamation" />
          </ActionButton>

          <MessagesBox>
            {data.invoice.message
              .filter((message) => message.type !== "status_change")
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message) => (
                <div key={message.created_at}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      display: "flex",
                    }}
                  >
                    <UserWrapper>
                      {
                        users.find((user) => message.sender === user._id)
                          ?.username
                      }
                    </UserWrapper>
                    <p style={{ textAlign: "right", width: "100%" }}>
                      {message.original}
                    </p>
                  </div>
                  <p style={{ fontSize: "10px", textAlign: "right" }}>
                    {moment(message.created_at).format("YYYY-MM-DD HH:MM")}
                  </p>
                </div>
              ))}
          </MessagesBox>
          <form
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              alignItems: "center",
            }}
            onSubmit={(e) => handleAddCommentToTicket(e)}
          >
            <textarea
              ref={textareaRef}
              style={{
                width: "350px",
                resize: "none",
                fontSize: "12px",
              }}
              required
              placeholder="type message..."
            />
            <ActionButton
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              color={messageSucces ? Colors.green : Colors.orange}
            >
              <span>{messageSucces ? "Sent" : "Send"}</span>
              <i className="fa fa-send" />
            </ActionButton>
          </form>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "right", gap: "20px" }}>
        <ActionButton
          color={success === "save" ? Colors.green : Colors.orange}
          onClick={(e) => handleSave(e)}
          text="Save"
        />
        <ActionButton
          onClick={(e) => markInvoiceToCorrect(e)}
          disabled={data.status === "to_correct"}
          text="To correct"
        />
        <ActionButton
          onClick={(e) => markInvoiceAsCorrected(e)}
          disabled={data.status !== "to_correct"}
          defaultText="Corrected"
        />
        <ActionButton
          color={Colors.red}
          onClick={(e) => markInvoiceAsIssued(e)}
          defaultText="Issued"
        />
        <ActionButton
          onClick={(e) => markInvoiceAsDone(e)}
          disabled={data.status !== "issued"}
          defaultText="Done"
        />
      </div>
    </PopUp>
  );
};

export default InvoiceEdit;
