import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getConsultantBlackboardInfo } from "../../../../../API/repositories/blackboardInfo";
import userManager from "../../../../../API/userManager";
import { Colors } from "../../../../../common/colors/colors";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";

const Wrapper = styled.div`
  width: fit-content;
  height: 500px;
  width: 600px;
  gap: 20px;
  border-radius: 20px;
  color: ${(props) => props.color};
  padding: 10px 0 0 0;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
`;

const TopInfoWrapper = styled.h4`
  text-align: center;
  font-style: italic;
`;

const Blackboard = ({ type = "main" }) => {
  const [data, setData] = useState();
  const { makeRequest } = useRequestsContext();

  const handleLoadBlackboardInfo = async () => {
    const response = await makeRequest(getConsultantBlackboardInfo);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadBlackboardInfo();
  }, []);

  return (
    <Wrapper color={Colors.darkBlue}>
      <TopInfoWrapper>Blackboard</TopInfoWrapper>
      {data &&
        data
          .filter((d) => {
            const dType = d.dashboard || "main";
            return dType === type;
          })
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((info) => (
            <div
              style={{
                borderBottom: `1px dashed lightgrey`,
                width: "100%",
                padding: "10px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  padding: "0 10px",
                }}
              >
                <h5
                  style={{
                    minWidth: `${info.title.length * 3 + 20}px`,
                    color: Colors.darkBlue,
                  }}
                >
                  {info.title}
                </h5>
                <div
                  style={{
                    borderLeft: `1px dashed lightgrey`,
                    paddingLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      color: info.color,
                      fontSize: "22px",
                      fontStyle: "italic",
                    }}
                  >
                    {info.translations.find(
                      (translation) =>
                        translation.lang === userManager.getUser().lang
                    )?.text || info.message}
                  </div>
                </div>
              </div>
            </div>
          ))}
    </Wrapper>
  );
};

export default Blackboard;
