import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDrawnContact } from "../../../API/repositories/queue";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Buttons from "../contact/buttons/Buttons";
import Comments from "../contact/comments/Comments";
import ConsultantScript from "../contact/consultantScript/ConsultantScript";
import EndContact from "../contact/endContact/EndContact";
import MoreInfo from "../contact/moreInfo/MoreInfo";
import Order from "../contact/order/Order";
import Orders from "../contact/orders/Orders";
import PrimaryInfo from "../contact/primaryInfo/PrimaryInfo";
import TicketsForCalling from "../contact/tickets/TicketsForCalling";
import { Timer } from "../contact/timer/Timer";
import Ping from "@/common/components/Ping";
import SupportMail from "@/common/components/supportMail/SupportMail";
import { OUTBOUND_NOT_ALLOWED_STATUSES } from "./constants/outboundNotAllowedStatuses";
import {
  ActionButtonsWrapper,
  ContactWrapper,
  Container,
  ContentBodyWrapper,
  Flex,
  OrderWrapper,
  Wrapper
}
  from "./ConsultantContactOutbound.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const ConsultantContactOutbound = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [time, setTime] = useState(null);
  const [contact, setContact] = useState(null);
  const [comments, setComments] = useState([]);
  const [market, setMarket] = useState(null);

  const [shouldOffContact, setShouldOffContact] = useState(false);
  const { addMessage } = useMessageQueueContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();

  const [chosenProductData, setChosenProductData] = useState([]);

  const { translation } = useTranslationContext();

  const handleGetDawnContact = async () => {
    const response = await makeRequest(getDrawnContact.bind(null, id));
    if (response.data) {
      if (
        !response.data._call_queue_stack &&
        OUTBOUND_NOT_ALLOWED_STATUSES.includes(response.data.status)
      )
        navigate("/consultant/dashboard");
      else {
        const now = moment();
        const to = moment(response.data._call_history.created_at);
        const minutes =
          parseInt(response.data._queue?.max_keep_time) -
          now.diff(to, "seconds") / 60;
        setTime(() => (minutes > 0 ? minutes : 0));
        setMarket(() => response.data._market);
        setContact(() => response.data._contact);
        setChosenProductData(() =>
          response.data.order_items
            ? response.data.order_items.map((data, index) => {
              return {
                _id: index,
                name: response.data._product.variants.find(
                  (variant) => variant.short === data.product_variant
                ).name,
                value: data.product_variant,
                price: data.price,
              };
            })
            : []
        );

        setData(() => response.data);

        setComments(() => response.data._comments);
      }
    } else {
      addMessage(response.error, "error");
    }
  };

  useLayoutEffect(() => {
    handleGetDawnContact();
  }, []);

  return (
    <Container>
      <Ping display={false} />
      {!hasUnfilledRequest(getDrawnContact) && data != null ? (
        <Wrapper>
          <Timer
            setShouldOffContact={setShouldOffContact}
            time={time}
            reamainingTime={translation.reamainingTime}
            minutes={translation.minutes}
            tSeconds={translation.seconds}
          />
          <Flex>
            <ContactWrapper>
              <PrimaryInfo
                market={market}
                contact={contact}
                shouldDisableSuspicion={data.shouldDisableSuspicion}
                setContact={setContact}
                handleGetDawnContact={handleGetDawnContact}
                dequeuedCount={
                  data._call_queue_stack
                    ? data._call_queue_stack.dequeued_count
                    : data.contact_attempts_queue_count
                }
              />
              <Buttons
                comments={comments}
                product={data._product}
                contact={contact}
                chosenProductData={chosenProductData}
                data={data}
                handleGetDawnContact={handleGetDawnContact}
              />
              <Comments
                handleGetDawnContact={handleGetDawnContact}
                comments={comments}
                contactId={data._contact._id}
                setComments={setComments}
              />
            </ContactWrapper>
            <OrderWrapper>
              <Order
                chosenProductData={chosenProductData}
                setChosenProductData={setChosenProductData}
                product={data._product}
                contact={contact}
                order={data}
              />
              <ConsultantScript phoneScript={data._product.product_script} />
              <ContentBodyWrapper>
                <div>
                  <Orders
                    orders={data._orders || []}
                  />
                </div>
                <ActionButtonsWrapper>
                  <MoreInfo
                    sendMail={translation.sendMail}
                    order={data}
                    mailError={translation.mailError}
                    mailSuccess={translation.mailSuccess}
                  />
                  <SupportMail order={data} />
                  <TicketsForCalling
                    market={market}
                    orderId={data._id.toString()}
                  />
                </ActionButtonsWrapper>
              </ContentBodyWrapper>
            </OrderWrapper>
          </Flex>
        </Wrapper>
      ) : (
        <Loading />
      )}
      {shouldOffContact && <EndContact />}
    </Container>
  );
};

export default ConsultantContactOutbound;
