import React, { useState } from "react";
import {
  AddTodoButton,
  AddTodoButtonWrapper,
  CustomSkeletonWrapper,
  TodoItemsWrapper,
  TodoListCloser,
  TodoListContainer,
  TodoListWrapper,
  TodoStatusWrapper,
} from "./TodoList.styled";
import Icon from "@/common/components/Icon";
import TodoForm from "@/common/components/todoForm/TodoForm";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getAllTodos, updateTodoStatus } from "@/API/repositories/todo";
import CustomSkeleton from "@/common/components/CustomSkeleton";
import { Colors } from "@/common/colors/colors";
import TodoStatus from "./components/todoStatus/TodoStatus";
import { TODO_STATUSES } from "@/common/constants/todoStatuses";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const TodoList = ({
  setIsOpen,
  todos,
  setTodos,
  loadData,
  notRead,
}) => {
  const [isTodoFormOpen, setIsTodoFormOpen] = useState();
  const [hiddenStatuses, setHiddenStatuses] = useState(
    Object.keys(TODO_STATUSES).map((s) => TODO_STATUSES[s].value)
  );
  const [loadingTodoId, setLoadingTodoId] = useState();
  const [selectedTodo, setSelectedTodo] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleHiddenStatusesChange = (status) => {
    if (hiddenStatuses.includes(status)) {
      return setHiddenStatuses((prev) => prev.filter((s) => s !== status));
    }

    return setHiddenStatuses((prev) => [...prev, status]);
  };

  const handleEditTodo = (todo) => {
    setSelectedTodo(() => todo);
    setIsTodoFormOpen(() => true);
  };

  const updateStatus = async (todoId, status) => {
    setLoadingTodoId(() => todoId);
    const response = await makeRequest(
      updateTodoStatus.bind(null, todoId, status)
    );

    if (!response?.data) {
      setLoadingTodoId(() => null);
      return addMessage("Something went wrong", "error");
    }

    await loadData();

    setLoadingTodoId(() => null);
    return addMessage("Success", "success");
  };

  return (
    <TodoListContainer>
      <TodoListCloser onClick={() => setIsOpen(false)} />
      <TodoListWrapper>
        {!todos && hasUnfilledRequest(getAllTodos) && (
          <CustomSkeletonWrapper>
            <CustomSkeleton
              width="100%"
              height="4%"
              count={25}
              highlightColor={Colors.darkGray2}
              baseColor={Colors.darkGray}
            />
          </CustomSkeletonWrapper>
        )}
        <TodoItemsWrapper>
          {todos && (
            <>
              <TodoStatusWrapper>
                {Object.keys(TODO_STATUSES)
                  .filter((status) =>
                    todos.find(
                      (group) => group._id === TODO_STATUSES[status].value
                    )
                  )
                  .map((status) => (
                    <TodoStatus
                      notRead={notRead}
                      loadData={loadData}
                      key={TODO_STATUSES[status].value}
                      updateStatus={updateStatus}
                      loadingTodoId={loadingTodoId}
                      status={status}
                      todos={todos}
                      hiddenStatuses={hiddenStatuses}
                      handleHiddenStatusesChange={handleHiddenStatusesChange}
                      handleEditTodo={handleEditTodo}
                    />
                  ))}
              </TodoStatusWrapper>
            </>
          )}
        </TodoItemsWrapper>
        <AddTodoButtonWrapper>
          <AddTodoButton onClick={() => setIsTodoFormOpen((prev) => !prev)}>
            <Icon name="fa fa-plus" color="#fff" size={28} />
          </AddTodoButton>
        </AddTodoButtonWrapper>
      </TodoListWrapper>
      {isTodoFormOpen && (
        <TodoForm
          selectedTodo={selectedTodo}
          setSelectedTodo={setSelectedTodo}
          loadData={loadData}
          setTodos={setTodos}
          setIsOpen={setIsTodoFormOpen}
        />
      )}
    </TodoListContainer>
  );
};

export default TodoList;
