import translationManager from "@/API/translationManager";
import React, { createContext, useEffect, useState } from "react";
import { TO_TRANSLATE } from "../constants/toTranslate";
import userManager from "@/API/userManager";

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translation, setTranslation] = useState(TO_TRANSLATE);

  const translate = async () => {
    const user = userManager.getUser();

    if (!user) {
      return;
    }

    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => (translations));
  };

  useEffect(() => {
    translate();
  }, []);

  return (
    <TranslationContext.Provider value={{ translation, translate }}>
      {children}
    </TranslationContext.Provider>
  )
}
