import React, { useEffect, useState } from "react";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getConsultantDailyContactsHistory } from "../../../../../API/repositories/reports";
import CustomSkeleton from "../../../../../common/components/CustomSkeleton";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapper = styled.div`
  width: 400px;
  height: 370px;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  h3 {
    font-size: 16px;
    color: ${Colors.darkBlue};
  }
`;

const MonthSwapWrapper = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 10px;
    color: lightgrey;
    font-style: italic;
  }
`;

const ChartWrapper = styled.div`
  width: 300px;
`;

const DATA_RANGE = [
  {
    value: "today",
    label: "today",
  },
  {
    value: "last_3_days",
    label: "last 3 days",
  },
  {
    value: "last_7_days",
    label: "last 7 days",
  },
];

ChartJS.register(ArcElement, Tooltip, Legend);

const CallingStatisticsChart = () => {
  const [currentRange, setCurrentRange] = useState(DATA_RANGE[0]);
  const [data, setData] = useState();

  const { translation: translations } = useTranslationContext();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            color: Colors.darkBlue,
          },
          color: Colors.darkBlue,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
    },
    cutout: 100,
    percentageInnerCutout: 40,
  };

  const { makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    const response = await makeRequest(getConsultantDailyContactsHistory);

    if (response.data) {
      setData((prev) => ({
        labels: ["Shift", "Confirm", "Resign"],
        downloaded: response.data.downloaded,
        datasets: [
          {
            label: "Daily grade",
            data: [
              response.data.shift,
              response.data.confirmed,
              response.data.resigned,
            ],
            backgroundColor: [Colors.yellow, Colors.green, Colors.red],
            borderColor: [Colors.yellow, Colors.green, Colors.red],
          },
        ],
      }));
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <Wrapper>
      <TopWrapper>
        <NameWrapper>
          <i
            className="fa fa-poll"
            style={{ color: Colors.darkBlue, fontSize: "30px" }}
          />
          <h3>
            {translations["Downloaded"]}: {data?.downloaded}
          </h3>
        </NameWrapper>
        <MonthSwapWrapper>
          <h3>{translations[currentRange.label]}</h3>
        </MonthSwapWrapper>
      </TopWrapper>
      {data ? (
        <ChartWrapper>
          <Doughnut options={options} data={data} />{" "}
        </ChartWrapper>
      ) : (
        <div style={{ marginBottom: "30px" }}>
          <CustomSkeleton />
        </div>
      )}
    </Wrapper>
  );
};

export default CallingStatisticsChart;
