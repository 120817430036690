import React, { useState } from "react";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import SearchBar from './components/searchBar/SearchBar';
import DailyMarketTable from "./components/table/DailyMarketTable";

const DailyMarket = () => {
  const [data, setData] = useState();

  return (
    <WrapperWithScroll>
      <SearchBar setData={setData} />
      {data && (
        <DailyMarketTable data={data} />
      )}
    </WrapperWithScroll>
  );
};

export default DailyMarket;
