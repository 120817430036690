import React from "react";
import CardView from "@/pages/login/components/Card/cardView";
import LoginForm from "@/pages/login/components/LoginForm/loginForm";
import Welcometext from "@/pages/login/components/WelcomeText/welcometext";
import { LoginWrapper, Wrapper } from "./Login.styled";

const LoginPage = () => {
  return (
    <Wrapper>
      <CardView />
      <LoginWrapper>
        <Welcometext />
        <LoginForm />
      </LoginWrapper>
    </Wrapper>
  );
};

export default LoginPage;
