import React, { useState } from "react";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getTrackingLink } from "@/common/functions/getTrackingLink";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import CreateQuestionAdmin from "@/common/components/ticket/createQuestionAdmin/CreateQuestionAdmin";
import { Flex } from "./OrderButtons.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { sendMailByMailType } from "@/API/repositories/mail";
import { confirmOrder, resendOrder } from "@/API/repositories/order";
import Loading from "@/common/components/Loading";
import SendAnyMail from "./components/sendAnyMail/SendAnyMail";

const OrderButtons = ({ order, handleGetOrderWithContact }) => {
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [showSendAnyMail, setShowSendAnyMail] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSendMailMoreInfo = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "MORE_INFO_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendMailOrderConfirmation = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "ORDER_CONFIRMATION_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendInvoiceMail = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(null, "INVOICE_" + order._market.short, order._id)
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  // const handleSendIBLActivationCode = async () => {
  //   const response = await makeRequest(
  //     sendMailByMailType.bind(
  //       null,
  //       "IBL_ACTIVATION_CODE_" + order._market.short,
  //       order._id
  //     )
  //   );

  //   if (response.data) {
  //     await handleGetOrderWithContact();
  //     addMessage("Mail sent", "success");
  //   } else {
  //     addMessage("Unable to send", "error");
  //   }
  // };

  const handleSendActivationCode = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "ACTIVATION_CODE_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendCorrectionInvoiceMail = async () => {
    const response = await makeRequest(
      sendMailByMailType.bind(
        null,
        "CORRECTION_INVOICE_" + order._market.short,
        order._id
      )
    );

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleSendAnyMailTemplate = async (type) => {
    const response = await makeRequest(
      sendMailByMailType.bind(null, type + order._market.short, order._id)
    );

    setShowSendAnyMail(() => null);

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Mail sent", "success");
    } else {
      addMessage("Unable to send", "error");
    }
  };

  const handleReSend = async () => {
    const response = await makeRequest(resendOrder.bind(null, order._id));

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Done", "success");
    } else {
      addMessage("Unable to resend", "error");
    }
  };

  const handleConfirm = async () => {
    const response = await makeRequest(confirmOrder.bind(null, order._id));

    if (response.data) {
      await handleGetOrderWithContact();
      addMessage("Done", "success");
    } else {
      addMessage("Unable to resend", "error");
    }
  };

  return (
    <>
      {hasUnfilledRequest(confirmOrder, resendOrder) && <Loading />}
      <Flex>
        <ActionButton
          onClick={() => handleSendMailMoreInfo()}
          defaultText="Send More Info Mail"
        />
        <ActionButton
          onClick={() => handleSendMailOrderConfirmation()}
          defaultText="Send Confirmation Mail"
        />
        <ActionButton
          onClick={() => handleSendInvoiceMail()}
          defaultText="Send Invoice Mail"
          disabled={!order.sales_invoice}
        />
        <ActionButton
          onClick={() => handleSendActivationCode()}
          defaultText="Send Activation code"
        />
        <ActionButton
          onClick={() => handleSendCorrectionInvoiceMail()}
          defaultText="Send correction invoice"
          disabled={!order.correction_invoice}
        />
        <ActionButton
          disabled={!order._contact?.country || !order.shipping?.waybill_number}
          onClick={() => {
            window.open(
              getTrackingLink(order._contact?.country, order.shipping),
              {
                traget: "_blank",
              }
            );
          }}
          defaultText="Check Post"
        />
        <ActionButton
          disabled={!order?.shipping?.is_order_sent}
          onClick={() => handleReSend()}
          defaultText="ReSend"
        />
        <ActionButton
          disabled={order.status === "confirmed"}
          onClick={() => handleConfirm()}
          defaultText="Confirm"
        />
        <ActionButton
          onClick={() => setShowCreateQuestion(true)}
          defaultText="Ask a question"
        />
        <ActionButton
          onClick={() => setShowSendAnyMail(order)}
          defaultText="Send any Mail"
        />
      </Flex>
      {showCreateQuestion && (
        <CreateQuestionAdmin
          order={order}
          setShowQuestionTicket={setShowCreateQuestion}
        />
      )}
      {showSendAnyMail && (
        <SendAnyMail
          setShowSendAnyMail={setShowSendAnyMail}
          handleSendAnyMailTemplate={handleSendAnyMailTemplate}
          showSendAnyMail={showSendAnyMail}
        />
      )}
    </>
  );
};

export default OrderButtons;
