import { createTestResult } from "@/API/repositories/testResult";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useState } from "react";
import { 
	TestWrapper, 
	Title 
} from "../../../../ConsultantTests.styled";
import userManager from "@/API/userManager";
import NoResult from "./components/noResult/NoResult";
import TestResult from "./components/testResult/TestResult";

export const ResolveTest = ({ test, setShow }) => {
  const [showResult, setShowResult] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [userQuestion, setUserQuestion] = useState(1);
  const [canNext, setCanNext] = useState(false);
  const { makeRequest } = useRequestsContext();

  const handleSave = async () => {
    const payload = {};

    payload.test_id = test._id;
    payload.answers = userAnswers;

    const response = await makeRequest(createTestResult.bind(null, payload));

    if (response.data) {
    }
    setShowResult(() => true);
  };

  return (
    <PopUp setShow={setShow}>
      <TestWrapper>
        <Title>
          {test.translations.find(
            (t) => userManager.getUser().lang === t.lang.toLowerCase()
          )?.value || test.name}
        </Title>
        {!showResult && (
          <NoResult
            test={test}
            userQuestion={userQuestion}
            setUserQuestion={setUserQuestion}
            userAnswers={userAnswers}
            setUserAnswers={setUserAnswers}
            setCanNext={setCanNext}
            canNext={canNext}
            handleSave={handleSave}
          />
        )}
        {showResult && (
          <TestResult
            userAnswers={userAnswers}
            setShow={setShow}
          />
        )}
      </TestWrapper>
    </PopUp>
  );
};