import React, { useMemo } from "react";
import { useAdminDashboardContext } from "../../../../../common/hooks/adminDashboardHook";
import Table from "../../../../../common/components/Table";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { getInoicesByStatusesReport } from "../../../../../API/repositories/reports";
import { groupByMarketAndStautsField } from "../../../../../common/functions/groupByMarketAndStatusField";

const Invoices = () => {
  const { data } = useAdminDashboardContext();
  const { invoices } = data;
  const { hasUnfilledRequest } = useRequestsContext();

  const headersArray = [
    [<th colSpan={4}>Invoices</th>],
    [<th>Market</th>, <th>Open</th>, <th>Issued</th>, <th>To correct</th>],
  ];

  const countByStatus = (items, status) => {
    return items.reduce(
      (prev, next) => prev + (next.status === status ? 1 : 0),
      0
    );
  };

  const raws = useMemo(() => {
    if (invoices) {
      return groupByMarketAndStautsField(invoices)
        .sort((invoiceA, invoiceB) => invoiceA[0].localeCompare(invoiceB[0]))
        .map((invoice) => (
          <tr key={invoice[0]}>
            <td>{invoice[0]}</td>
            <td>{countByStatus(invoice[1], "open") || 0}</td>
            <td>{countByStatus(invoice[1], "issued") || 0}</td>
            <td>{countByStatus(invoice[1], "to_correct") || 0}</td>
          </tr>
        ));
    }
  }, [invoices]);

  const shouldShowLoading = useMemo(
    () => !invoices && hasUnfilledRequest(getInoicesByStatusesReport),
    [invoices, hasUnfilledRequest]
  );

  return (
    <div>
      <Table
        stickyHeader={true}
        className="styled-table-dashboard"
        headersArray={headersArray}
        raws={raws}
        loading={shouldShowLoading}
      />
    </div>
  );
};

export default Invoices;
