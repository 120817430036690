import React, { useState, useRef, useMemo } from "react";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import moment from "moment";
import { formatToDateTimeFormaterTemplate } from "@/common/functions/dateFormater";
import { CONSULTANT_ROLES } from "@/pages/login/components/LoginForm/loginForm";
import { CALLING_DIRECTION, ORDER_STATUSES } from "@/common/constants/statuses";
import { CALL_HISTORY_STATUSES } from "@/common/constants/statuses";
import { CALL_HISTORY_FIELDS } from "@/common/constants/callHistoryFilterFields";
import { getCallHitoryStatisticts } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import CSV from "@/common/components/CSV";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import {
  Flex,
  FlexRightItem,
  GapFlex,
  SelectsFlex,
  SearchBar,
} from "./CallHistoryCsv.styled";
import CallHistoryCSVTable from "./components/CallHistoryCSVTable";
import { getLabel } from "./helpers/getLabel";
import { getHeaders } from "./helpers/getHeaders";
import { PageWrapper } from "@/common/styles/Wrappers";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const TIME_DIFFERENCE = 20 * 60 * 1000;

const CallHistoryCsv = () => {
  const {
    filterUsersByRoles,
    options: { queuesOptions, usersOptions },
  } = useCommonDataContext();

  const consultantsOptions = useMemo(
    () => filterUsersByRoles(CONSULTANT_ROLES),
    [usersOptions]
  );

  const [direction, setDirection] = useState(CALLING_DIRECTION);

  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedCallHistoryStatuses, setSelectedCallHistoryStatuses] =
    useState(CALL_HISTORY_STATUSES);
  const [selectedOrderStatuses, setSelectedOrderStatuses] =
    useState(ORDER_STATUSES);
  const [selectedCallHistoryFields, setSelectedCallHistoryFields] =
    useState(CALL_HISTORY_FIELDS);
  const [callHistoryData, setCallHistoryData] = useState([]);

  const minDequeCountRef = useRef();
  const maxDequeCountRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const today = formatToDateTimeFormaterTemplate(moment());

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSelectQueues = (queues) => {
    let selectedConsultantsIds = [];

    queues.forEach((data) => {
      selectedConsultantsIds = [
        ...selectedConsultantsIds,
        ...data.value.consultants,
      ];
    });

    setSelectedConsultants(() =>
      consultantsOptions.filter((consultant) =>
        selectedConsultantsIds.includes(consultant.value)
      )
    );

    setSelectedQueues(() => queues);
  };

  const handleSetFilterFields = (fields) => {
    const filteredFields = CALL_HISTORY_FIELDS.filter((callHistoryField) =>
      fields.find((field) => field.value === callHistoryField.value)
    );

    setSelectedCallHistoryFields(() => filteredFields);
  };

  const handleSearch = async () => {
    if (selectedConsultants && !selectedConsultants.length)
      return addMessage("Select consultant", "error");

    if (selectedQueues && !selectedQueues.length)
      return addMessage("Select queue", "error");

    const payload = {};
    payload.consultants = selectedConsultants.map(
      (consultant) => consultant.value
    );
    payload.direction = direction.map((d) => d.value);
    payload.queues = selectedQueues.map((queue) => queue.value._id);
    payload.from = moment(fromRef.current.value).startOf("day").toISOString();
    payload.to = moment(toRef.current.value).endOf("day").toISOString();
    payload.min_dequeue_count = minDequeCountRef.current.value;
    payload.max_dequeue_count = maxDequeCountRef.current.value;
    payload.calling_statuses = selectedCallHistoryStatuses.map(
      (status) => status.value
    );
    payload.order_statuses = selectedOrderStatuses.map((order) => order.value);

    const response = await makeRequest(
      getCallHitoryStatisticts.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
    }

    if (response.data) {
      const mapedResult = handleMapResult(response.data);

      setCallHistoryData(() => mapedResult);
    } else {
      setCallHistoryData(() => []);
    }
  };

  const matchCallInfoToCallHistory = (callInfo, callHistory) => {
    return (
      Math.abs(
        new Date(callInfo.started_at || callInfo.created_at).getTime() -
        new Date(callHistory.created_at).getTime()
      ) <= TIME_DIFFERENCE && callInfo.consultant === callHistory.consultant_id
    );
  };

  const handleMapResult = (result) =>
    result.map((callHistory) => {
      const callingInformation = callHistory.calling_information.filter(
        (callInfo) => matchCallInfoToCallHistory(callInfo, callHistory)
      );

      callHistory.waiting_time = callingInformation.reduce(
        (acc, callInfo) =>
          acc + parseInt(callInfo.waiting_time || callInfo.duration),
        0
      );

      callHistory.talking_time = callingInformation.reduce(
        (acc, callInfo) => acc + parseInt(callInfo.talking_time),
        0
      );

      const allHisotry = [
        ...callHistory.all_call_history,
        ...callHistory.all_inbound_history,
      ].sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });

      let previousCallHisotry = "-------";

      allHisotry.forEach((c, i) => {
        if (c._id === callHistory._id) {
          if (i > 0) {
            previousCallHisotry = allHisotry[i - 1].status;
          }
        }
      });

      callHistory.previousCallHisotry = previousCallHisotry;

      return callHistory;
    });

  const createCallHistoryDataToCSV = (callHistoryData) =>
    callHistoryData.map((callHistory, i) =>
      selectedCallHistoryFields.map((field) =>
        getLabel(callHistory[field.value.label], field.value.type, i)
      )
    );

  return (
    <PageWrapper>
      {hasUnfilledRequest(getCallHitoryStatisticts) && <Loading />}
      <SearchBar>
        <div>
          <SelectInput
            showLabel={false}
            selected={selectedCallHistoryFields}
            setSelected={handleSetFilterFields}
            options={CALL_HISTORY_FIELDS}
            selectWidth={950}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select filter fields..."
          />
        </div>
        <SelectsFlex>
          <SelectInput
            showLabel={false}
            selected={selectedConsultants}
            setSelected={setSelectedConsultants}
            options={consultantsOptions}
            selectWidth={600}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select consultant..."
          />
          <SelectInput
            showLabel={false}
            selected={selectedQueues}
            setSelected={handleSelectQueues}
            options={queuesOptions}
            selectWidth={300}
            color={Colors.darkBlue}
            multiple={true}
            placeholder="Select queue..."
          />
        </SelectsFlex>
        <Flex>
          <Input
            name="From"
            type="datetime-local"
            inputWidth={180}
            color={Colors.darkBlue}
            width={50}
            inputRef={fromRef}
            value={today}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="datetime-local"
            width={50}
            inputWidth={180}
            color={Colors.darkBlue}
            value={today}
          />
          <SelectInput
            options={CALLING_DIRECTION}
            name="Directions"
            setSelected={setDirection}
            selected={direction}
            width={98}
            selectWidth={200}
            color={Colors.darkBlue}
            multiple={true}
          />
        </Flex>
        <Flex>
          <Input
            inputRef={minDequeCountRef}
            name="min deque count"
            type="number"
            width={150}
            inputWidth={100}
            color={Colors.darkBlue}
            value={0}
          />
          <Input
            inputRef={maxDequeCountRef}
            name="max deque count"
            type="number"
            width={160}
            inputWidth={100}
            color={Colors.darkBlue}
            value={12}
          />
        </Flex>
        <Flex>
          <SelectInput
            options={CALL_HISTORY_STATUSES}
            name="Call status"
            setSelected={setSelectedCallHistoryStatuses}
            selected={selectedCallHistoryStatuses}
            type="number"
            width={98}
            inputWidth={300}
            color={Colors.darkBlue}
            multiple={true}
          />
          <SelectInput
            options={ORDER_STATUSES}
            name="Order status"
            setSelected={setSelectedOrderStatuses}
            selected={selectedOrderStatuses}
            type="number"
            width={120}
            inputWidth={300}
            color={Colors.darkBlue}
            value={0}
            multiple={true}
          />
        </Flex>
        <FlexRightItem>
          <GapFlex>
            {!!callHistoryData?.length && (
              <CSV
                filename={"callhistory.csv"}
                header={getHeaders(selectedCallHistoryFields)}
                data={createCallHistoryDataToCSV(callHistoryData)}
              />
            )}
            <ActionButton
              onClick={(e) => handleSearch(e)}
              defaultText="Search"
            />
          </GapFlex>
        </FlexRightItem>
      </SearchBar>
      <CallHistoryCSVTable
        callHistoryData={callHistoryData}
        selectedCallHistoryFields={selectedCallHistoryFields}
      />
    </PageWrapper>
  );
};

export default CallHistoryCsv;
