import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import React, { useEffect, useRef, useState } from "react";
import { TEMPLATES_OPTIONS } from "../../constants";
import { ButtonWrapper, InputsWrapper, Title } from "./TemplateForm.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  createInvoiceTemplate,
  updateInvoiceTemplate,
} from "@/API/repositories/invoiceTemplate";
import { preventFn } from "@/common/functions/preventEvent";
import { getBase64 } from "@/common/functions/getBase64";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const TemplateForm = ({ setShow, template, loadData }) => {
  const [selectedMarket, setSelectedMarket] = useState();
  // const [selectedProduct, setSelectedProduct] = useState();
  const [selectedTemplateType, setSelectedTemplateType] = useState();
  const [selectedProducts, setSelectedProducts] = useState();

  const fileRef = useRef();
  const filenameRef = useRef();

  const {
    options: { marketsOptions, productsOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSubmit = async (e) => {
    e && preventFn(e);

    const payload = {};
    const file = fileRef.current?.files[0];

    const base64 = file ? await getBase64(file) : template?.base64;

    if (!base64) {
      return addMessage("Bad file", "error");
    }

    payload.base64 = base64;
    payload.market = selectedMarket.value;
    // payload.product = selectedProduct?.value;
    payload.template_type = selectedTemplateType.value;
    payload.filename = filenameRef.current.value;
    payload.products = selectedProducts.map((product) => product.value);

    const response = template
      ? await handleUpdateTemplate(payload)
      : await handleCreateTemplate(payload);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(() => false);
    loadData();
  };

  const handleCreateTemplate = async (payload) => {
    return await makeRequest(createInvoiceTemplate.bind(null, payload));
  };

  const handleUpdateTemplate = async (payload) => {
    return await makeRequest(
      updateInvoiceTemplate.bind(null, template._id, payload)
    );
  };

  const handleSetData = () => {
    if (template && marketsOptions?.length && productsOptions?.length) {
      setSelectedMarket(() =>
        marketsOptions.find((option) => option.value === template.market)
      );
      setSelectedProducts(() =>
        productsOptions.filter((option) => template.products?.includes(option.value))
      );
      setSelectedTemplateType(() =>
        TEMPLATES_OPTIONS.find(
          (option) => option.value === template.template_type
        )
      );
    }
  };

  useEffect(() => {
    handleSetData();
  }, [marketsOptions, productsOptions]);

  return (
    <PopUp setShow={setShow}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Title>{template ? "Edit" : "Create"} template</Title>
        <InputsWrapper>
          <Input
            width={180}
            name="Filename"
            color={Colors.darkBlue}
            value={template?.filename}
            inputRef={filenameRef}
            pattern=".+\.pdf$"
            placeholder="File name must end with .pdf"
            required
          />
          <SelectInput
            width={180}
            color={Colors.darkBlue}
            name="Template type"
            options={TEMPLATES_OPTIONS}
            setSelected={setSelectedTemplateType}
            selected={selectedTemplateType}
            required
            hideStar
          />
          <SelectInput
            width={180}
            color={Colors.darkBlue}
            name="Market"
            options={marketsOptions}
            setSelected={setSelectedMarket}
            selected={selectedMarket}
            required
            hideStar
          />{" "}
          <SelectInput
            width={180}
            color={Colors.darkBlue}
            name="Products"
            options={productsOptions}
            setSelected={setSelectedProducts}
            selected={selectedProducts}
            required
            hideStar
            multiple
          />
          <Input
            width={180}
            inputRef={fileRef}
            color={Colors.darkBlue}
            name="Invoice template"
            type="file"
            accept="application/pdf"
            required={!template}
          />
        </InputsWrapper>
        <ButtonWrapper>
          <SubmitButton style={{ marginBottom: "-30px"}} text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default TemplateForm;
