import React, { useLayoutEffect, useState } from "react";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import TestEdit from "./TestEdit";
import Table from "../../../common/components/Table";
import { ButtonWrapper, Wrapper } from "./styles/Test.styles";
import { getUsers } from "../../../API/repositories/user";
import { getQueues } from "../../../API/repositories/queue";
import Loading from "../../../common/components/Loading";
import { getAllTests } from "../../../API/repositories/test";
import { handleReturnCorrectIcon } from "./common/shared";
import { Colors } from "../../../common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";

const TestList = () => {
  const [data, setData] = useState([]);
  const column = ["no", "name", "active", "actions"];
  const [current, setCurrent] = useState(null);
  const [usersOptions, setUsersOptions] = useState();
  const [queuesOptions, setQueuesOptions] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    const responseTests = await makeRequest(getAllTests);
    const responseUsers = await makeRequest(getUsers);
    const responseQueues = await makeRequest(getQueues);

    if (responseTests.data) {
      setData(() => responseTests.data);
    }

    if (responseUsers.data) {
      setQueuesOptions(() =>
        responseQueues.data.map((queue) => ({
          label: queue.name,
          value: queue,
        }))
      );
    }

    if (responseQueues.data) {
      setUsersOptions(() =>
        responseUsers.data.map((user) => ({
          label: user.username,
          value: user,
        }))
      );
    }
  };

  useLayoutEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getUsers, getUsers) && <Loading />}
      <Wrapper>
        <ButtonWrapper>
          <ActionButton onClick={() => setCurrent({})} defaultText="Create Test" />
        </ButtonWrapper>
        <Table
          headers={column}
          raws={data.map((d, i) => (
            <tr>
              <td>{i + 1}.</td>
              <td>{d.name}</td>
              <td>{handleReturnCorrectIcon(d.active)}</td>
              <td>
                <i
                  className="fa fa-edit animation-scale"
                  style={{ cursor: "pointer", color: Colors.orange }}
                  onClick={() => setCurrent(d)}
                />
              </td>
            </tr>
          ))}
          className={"styled-table"}
        />
      </Wrapper>
      {current && (
        <TestEdit
          setShow={setCurrent}
          current={current}
          usersOptions={usersOptions}
          queuesOptions={queuesOptions}
          reload={handleLoadData}
        />
      )}
    </>
  );
};

export default TestList;
