import React, { useEffect, useMemo, useRef, useState } from "react";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { CALLING_STATUSES } from "@/common/constants/CC";
import {
  findCallsByFilter,
  getNumbersFromCloudTalk,
} from "@/API/repositories/calling";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { Flex, SearchBar, Wrapper } from "./CallingSearch.styled";
import { Colors } from "@/common/colors/colors";
import { CONSULTANT_ROLES } from "@/pages/login/components/LoginForm/loginForm";
import CallingSearchTable from "./components/CallingSearchTable";
import ActionButton from "@/common/components/buttons/ActionButton";

const CallingSearch = () => {
  const [data, setData] = useState();
  const [selectedConsultants, setSelectedConsultants] = useState();
  const [selectedCallTypes, setSelectedCallTypes] = useState();
  const [selectedCCNumbers, setSelectedCCNumbers] = useState();
  const [CCNumberOptions, setCCNumberOptions] = useState();

  const {
    filterUsersByRoles,
    options: { usersOptions: dataUserOptions },
  } = useCommonDataContext();

  const usersOptions = useMemo(
    () => filterUsersByRoles(CONSULTANT_ROLES),
    [dataUserOptions]
  );

  const numberRef = useRef();
  const startedAtFromRef = useRef();
  const startedAtToRef = useRef();

  const navigate = useNavigate();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleCallingSearch = async () => {
    const payload = Object();
    payload.numbers =
      selectedCCNumbers?.map((number) => number.label).length > 0
        ? selectedCCNumbers?.map((number) => number.label)
        : null;
    payload.consultants =
      selectedConsultants?.map((consultant) => consultant.value).length > 0
        ? selectedConsultants?.map((consultant) => consultant.value)
        : null;
    payload.type =
      selectedCallTypes?.map((consultant) => consultant.value).length > 0
        ? selectedCallTypes?.map((consultant) => consultant.value)
        : null;
    payload.startedFrom =
      startedAtFromRef.current?.value || moment().startOf("day");
    payload.startedTo = startedAtToRef.current?.value || moment().endOf("day");
    payload.consultantNumber = numberRef.current?.value || null;

    const responseData = await makeRequest(
      findCallsByFilter.bind(null, payload)
    );

    if (responseData.data) {
      setData(() => responseData.data);
    }
  };

  const handleFindData = async () => {
    const numberResponse = await makeRequest(getNumbersFromCloudTalk);
    if (numberResponse.data) {
      setCCNumberOptions(() => numberResponse.data);
    }
  };

  useEffect(() => {
    handleFindData();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(findCallsByFilter) && <Loading />}
      <SearchBar>
        <h3>Calls Search:</h3>
        <div>
          <Flex>
            <div>
              <SelectInput
                name="CC Number"
                width={120}
                multiple={true}
                selected={selectedCCNumbers}
                options={CCNumberOptions}
                setSelected={setSelectedCCNumbers}
                color={Colors.darkBlue}
              />
              <SelectInput
                name="Consultant"
                width={120}
                multiple={true}
                selected={selectedConsultants}
                options={usersOptions}
                setSelected={setSelectedConsultants}
                color={Colors.darkBlue}
              />
              <SelectInput
                name="Type"
                width={120}
                multiple={true}
                selected={selectedCallTypes}
                options={CALLING_STATUSES}
                setSelected={setSelectedCallTypes}
                color={Colors.darkBlue}
              />
            </div>
            <div>
              <Input
                inputRef={numberRef}
                name="Number"
                width={160}
                placeholder="start with + and prefix"
                color={Colors.darkBlue}
              />
              <Input
                inputRef={startedAtFromRef}
                type="date"
                name="Started At (from)"
                width={160}
                color={Colors.darkBlue}
              />
              <Input
                inputRef={startedAtToRef}
                type="date"
                name="Started At (to)"
                width={160}
                color={Colors.darkBlue}
              />
            </div>
          </Flex>
          <ActionButton
            defaultText="Search"
            onClick={handleCallingSearch}
            style={{ float: "right", marginRight: "10px" }}
          />
        </div>
      </SearchBar>
      <CallingSearchTable data={data} />
    </Wrapper>
  );
};

export default CallingSearch;
