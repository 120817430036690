import { formatToDateTamplate } from "@/common/functions/dateFormater";

export const getRefundDate = (ticket) => {
	const isRefund = ticket._valid_claim
		|| ticket._order.claim
		|| ticket._order.payment?.some(p => p.status_refunded_date);

	if (!isRefund) {
		return "----";
	}

	if (ticket._valid_claim) {
		if (!ticket._valid_claim.transfers?.length) {
			return "----";
		}

		return formatToDateTamplate(ticket._valid_claim.transfers.at(-1).initiated_at);
	}

	if (ticket._order.claim) {
		if (!ticket._order.claim.paid_date) {
			return '----';
		}

		return formatToDateTamplate(ticket._order.claim.paid_date);
	}

	const refundDate = ticket._order.payment.find(p => p.status_refunded_date).status_refunded_date;

	if (!refundDate) {
		return '----';
	}

	return formatToDateTamplate(refundDate);
}