import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

const PREFIX = "mailing-report";

export const getMailingReports = () =>
  errorHandler(async () => {
    const data = await api.get(`/${PREFIX}/`);

    return { data: data.data };
  });

export const updateMailingReport = (mailingReport, id) =>
  errorHandler(async () => {
    const data = await api.patch(`/${PREFIX}/${id}`, mailingReport);

    return { data: data.data };
  });

export const createMailingReport = (mailingReport) =>
  errorHandler(async () => {
    const data = await api.post(`/${PREFIX}`, mailingReport);

    return { data: data.data };
  });

export const getMailingReportById = (id) =>
  errorHandler(async () => {
    const data = await api.post(`/${PREFIX}/${id}`);

    return { data: data.data };
  });

export const getMailingReportsByType = (type) =>
  errorHandler(async () => {
    const data = await api.get(`/${PREFIX}?type=${type}`);

    return { data: data.data };
  });

export const deleteMailingReport = (id) =>
  errorHandler(async () => {
    const data = await api.delete(`/${PREFIX}/${id}`);

    return { data: data.data };
  });

export const triggerDocs = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/${PREFIX}/trigger-docs`, payload);

    return { data: data.data };
  });

export const getDocsJobs = () =>
  errorHandler(async () => {
    const data = await api.get(`/${PREFIX}/docs-jobs`);

    return { data: data.data };
  });
