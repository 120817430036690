import React, { useRef } from "react";
import PopUp from "../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
  margin-top: 100px;
`;

const ListeningGeneratorAddTable = ({
  setShow,
  setTableElemnets,
  setToSave,
  addTable,
}) => {
  const valueRef = useRef();
  const labelRef = useRef();
  const orderNumberRef = useRef();

  const handleAddTable = async (e) => {
    e.preventDefault();
    const payload = {};

    payload.value = valueRef.current.value;
    payload.label = labelRef.current.value;
    payload.orderNumber = orderNumberRef.current.value;

    setTableElemnets((prev) => [
      ...prev.filter((d) => d.value !== payload.value),
      payload,
    ]);
    setToSave(() => true);
    setShow(() => null);
  };

  return (
    <PopUp setShow={setShow}>
      <Title>Table element</Title>
      <form onSubmit={handleAddTable}>
        <Input
          width={140}
          selectWidth={220}
          inputRef={valueRef}
          name="Value"
          required
          color={Colors.darkBlue}
          value={addTable.value}
        />
        <Input
          width={140}
          selectWidth={220}
          inputRef={labelRef}
          name="Label"
          required
          color={Colors.darkBlue}
          value={addTable.label}
        />
        <Input
          type="number"
          width={140}
          selectWidth={220}
          inputRef={orderNumberRef}
          name="Order number"
          required
          color={Colors.darkBlue}
          value={addTable.orderNumber}
        />
        <ButtonWrapper>
          <SubmitButton text="Add element" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default ListeningGeneratorAddTable;
