import { markAsRodoDecision, updateContact } from '@/API/repositories/contact'
import { Colors } from '@/common/colors/colors'
import ActionButton from '@/common/components/buttons/ActionButton'
import Card from '@/common/components/Card'
import Input from '@/common/components/Input'
import Loading from '@/common/components/Loading'
import SelectInput from '@/common/components/SelectInput'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import CreateCorrectionTicket from '@/common/components/ticket/createCorrectionTicket/CreateCorrectionTicket'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import MarkAsRedo from './components/markAsRedo/MarkAsRedo'
import { ButtonsWrapper, SaveButtonWrapper } from './ContactForm.styled'
import { validatePhoneForCourier } from '@/common/functions/validatePhoneForCourier'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import SubmitButton from '@/common/components/buttons/SubmitButton'
import CreateQuestionAdmin from '@/common/components/ticket/createQuestionAdmin/CreateQuestionAdmin'

const ContactForm = ({ contact, selectedCountry, setSelectedCountry, reloadFN }) => {
  const { id } = useParams();

  const [showNewTicket, setShowNewTicket] = useState();
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [success, setSuccess] = useState();
  const [markAsRodo, setMarkAsRodo] = useState(false);


  const fullNameRef = useRef();
  const orginalPhoneNUmberRef = useRef();
  const phoneNumberRef = useRef();
  const phoneForCourierRef = useRef();
  const emailRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const aidditionalInformationRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();
  const { COUNTRIES } = useCommonDataContext();

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {};

    const isPhoneError = phoneForCourierRef.current.value
      && !validatePhoneForCourier(phoneForCourierRef.current.value)

    if (isPhoneError) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    payload.full_name = fullNameRef.current
      ? fullNameRef.current.value
      : contact.last_name;
    payload.phone_number = phoneNumberRef.current
      ? phoneNumberRef.current.value
      : contact.phone_number;
    payload.phone_number_for_courier = phoneForCourierRef.current.value;
    payload.country = selectedCountry.value;
    payload.email = emailRef.current ? emailRef.current.value : contact.email;
    payload.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : contact.postal_code;
    payload.street = streetRef.current
      ? streetRef.current.value
      : contact.street;
    payload.city = cityRef.current ? cityRef.current.value : contact.city;
    payload.additional_info = aidditionalInformationRef.current
      ? aidditionalInformationRef.current.value
      : contact.additional_info;

    const response = await makeRequest(updateContact.bind(null, id, payload));

    if (response.data) {
      setSelectedCountry(() =>
        COUNTRIES.find((country) => country.value === response.data.country)
      );
      await reloadFN();

      addMessage("saved", "success");
    } else {
      addMessage("Couldn't save", "error");
    }
  };

  const handleMakeRodo = async (e) => {
    e.preventDefault();

    const response = await makeRequest(markAsRodoDecision.bind(null, id));

    if (response.data) {
      setMarkAsRodo(null);
      reloadFN();
    }
  };

  return (
    <>
      {hasUnfilledRequest(updateContact, markAsRodoDecision) && <Loading />}
      <form onSubmit={(e) => handleSave(e)}>
        <Card>
          <h3 style={{ margin: "10px 10px 20px 10px" }}>
            <u>Contact:</u>
          </h3>
          <div>
            <h5 style={{ textAlign: "center" }}>
              Created at:
              <h5 style={{ color: Colors.darkBlue }}>
                {moment(contact.created_at).format("YYYY/MM/DD HH:mm")}
              </h5>
            </h5>
          </div>
          <Input
            inputRef={fullNameRef}
            name="Full name"
            value={contact.full_name}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={orginalPhoneNUmberRef}
            name="Orginal phone"
            value={contact.orginal_phone_number}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={phoneNumberRef}
            name="Phone"
            value={contact.phone_number}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={phoneForCourierRef}
            name="Phone for courier"
            value={contact.phone_number_for_courier}
            width={135}
            inputWidth={180}
          />
          <SelectInput
            name="Country"
            selected={selectedCountry}
            setSelected={setSelectedCountry}
            options={COUNTRIES}
            width={135}
            selectWidth={180}
          />
          <Input
            inputRef={emailRef}
            name="E-mail"
            value={contact.email}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={postalCodeRef}
            name="Postal code"
            value={contact.postal_code}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={streetRef}
            name="Street"
            value={contact.street}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={cityRef}
            name="City"
            value={contact.city}
            width={135}
            inputWidth={180}
          />
          <Input
            inputRef={aidditionalInformationRef}
            name="Additional info"
            value={contact.additional_info}
            width={135}
            inputWidth={180}
          />
          <ButtonsWrapper>
            <ActionButton
              onClick={() => setShowCreateQuestion(true)}
              defaultText="Ask a question"
            />
            <ActionButton
              onClick={() => setShowNewTicket(true)}
              defaultText={success ? "Success" : "To correct"}
            />
            <ActionButton
              color={Colors.red}
              defaultText="Clear client"
              onClick={() => setMarkAsRodo(true)}
            />
          </ButtonsWrapper>
          <SaveButtonWrapper>
            <SubmitButton style={{ marginBottom: "-30px", width: "90px" }} text="Save" />
          </SaveButtonWrapper>
        </Card>
      </form>
      {showNewTicket && (
        <CreateCorrectionTicket
          setShow={setShowNewTicket}
          setSuccess={setSuccess}
        />
      )}
      {showCreateQuestion && (
        <CreateQuestionAdmin
          contact={contact}
          setShowQuestionTicket={setShowCreateQuestion}
        />
      )}
      {markAsRodo && (
        <MarkAsRedo
          setMarkAsRodo={setMarkAsRodo}
          handleMakeRodo={handleMakeRodo}
        />
      )}
    </>
  )
}

export default ContactForm
