import { getConsultantOrders } from '@/API/repositories/order'
import userManager from '@/API/userManager'
import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import Loading from '@/common/components/Loading'
import SearchWrapper from '@/common/components/newDesign/SearchWrapper'
import SelectInput from '@/common/components/SelectInput'
import { ALLOWED_CONSULTANT_ORDER_STATUSES } from '@/common/constants/statuses'
import { getToday } from '@/common/functions/getToday'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useTranslationContext } from '@/common/hooks/useTranslationContext'
import moment from 'moment'
import React, { useRef, useState } from 'react'

const SearchBar = ({
	setDataToDisplay,
}) => {
  const [selected, setSelected] = useState([]);

	const { translation } = useTranslationContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const fromRef = useRef();
  const toRef = useRef();

  const today = getToday();

	const hadnleGetConsultantOrders = async (e) => {
    e.preventDefault();
    setDataToDisplay(() => null);

    const payload = Object();

    payload.statuses = selected.map((data) => data.value);
    if (payload.statuses.length === 0) return;

    payload.from = fromRef.current
      ? moment(fromRef.current.value).startOf("day")
      : null;
    payload.to = toRef.current
      ? moment(toRef.current.value).endOf("day")
      : null;

    const response = await makeRequest(
      getConsultantOrders.bind(null, userManager.getUser().id, payload)
    );

    if (response.data && response.data.length > 0) {
      setDataToDisplay(() =>
        response.data.sort((a, b) => a.status.localeCompare(b.status))
      );
    }
  };

	return (
		<div
		style={{
			display: "flex",
			justifyContent: "center",
			marginBottom: "20px",
		}}
	>
		{hasUnfilledRequest(getConsultantOrders) && <Loading />}
		<SearchWrapper onSubmit={hadnleGetConsultantOrders}>
			<SelectInput
				options={ALLOWED_CONSULTANT_ORDER_STATUSES}
				selected={selected}
				setSelected={setSelected}
				multiple={true}
				name={translation["status"]}
				width={80}
				selectWidth={200}
				required
				color={Colors.darkBlue}
			/>
			<Input
				inputRef={fromRef}
				width={100}
				name={translation["start"]}
				type="date"
				inputWidth={150}
				value={today}
				color={Colors.darkBlue}
				required
				requiredSign
			/>
			<Input
				inputRef={toRef}
				width={90}
				name={translation["end"]}
				type="date"
				inputWidth={150}
				value={today}
				color={Colors.darkBlue}
				required
				requiredSign
			/>
		</SearchWrapper>
	</div>
	)
}

export default SearchBar
