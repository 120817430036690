import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { getProductsWithCouriers } from "@/API/repositories/lms";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import userManager from "@/API/userManager";
import SupportMail from "@/common/components/supportMail/SupportMail";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { createOrderWithShipping, updateOrderByConsultant } from "@/API/repositories/order";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import Loading from "@/common/components/Loading";
import MoreInfo from "@/pages/consultant/contact/moreInfo/MoreInfo";
import { useRequestsContext } from "@/common/hooks/requestHook";
import SelectInput from "@/common/components/SelectInput";

const OrderEdit = ({
  order = { shipping: {} },
  setShow,
  contact,
  country,
  market,
  create = true,
}) => {
  const [product, setProduct] = useState(order?._product);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [chosenVariants, setChosenVariants] = useState(
    order.order_items
      ? order.order_items.map((order, i) => {
        return {
          _id: i + 1,
          name: product?.variants.find(
            (variant) => variant.short === order.product_variant
          )?.name,
          value: order.product_variant,
          price: order.price,
        };
      })
      : []
  );
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionPrice, setSelectedOptionPrice] = useState();
  const [selectedCourier, setSelectedCourier] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const suggestedDeliveryDateRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();

  const {
    commonData: { queues, products },
    options: { productsOptions },
    COUNTRIES,
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const availableProductsOptions = useMemo(() => {
    const currentUserId = userManager.getUser().id;
    const userQueues = queues.filter((queue) => queue.consultants.includes(currentUserId));
    const availableProducts = Array.from(new Set(userQueues.map((queue) => queue.product)));
    return productsOptions.filter((p) => availableProducts.includes(p.value));
  }, [queues, productsOptions])

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (productWithCourier) => product?.short === productWithCourier.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(
      options.find((option) => option.value === order?.shipping?.curier_lms_id) ||
      options[0]
    );
  };

  const handleSaveOrder = async (e) => {
    e.preventDefault();
    let response;
    order.currency = market.currency;
    order.market = market._id;
    order.product = product?._id;
    order.items = chosenVariants;

    const shipping = Object();

    shipping.full_name = nameRef.current
      ? nameRef.current.value
      : contact.full_name;

    shipping.email = emailRef.current ? emailRef.current.value : contact.email;
    shipping.phone_number = phoneRef.current
      ? phoneRef.current.value
      : contact.phone_number;
    shipping.suggested_delivery_date = suggestedDeliveryDateRef.current
      ? moment(suggestedDeliveryDateRef.current.value)
      : moment().add(2, "days");
    shipping.street = streetRef.current
      ? streetRef.current.value
      : contact.street;
    shipping.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : contact.postal_code;
    shipping.city = cityRef.current ? cityRef.current.value : contact.city;
    shipping.country = selectedCountry.value;
    shipping.curier_lms_id = selectedCourier?.value;

    order.shipping = shipping;

    if (create) {
      response = await makeRequest(
        createOrderWithShipping.bind(null, contact._id, order)
      );
    } else {
      response = await makeRequest(
        updateOrderByConsultant.bind(null, order._id, order)
      );
    }

    if (response?.data) {
      setShow(() => false);
    } else {
      return addMessage("You are not allowed to update this order, please, quickly contact CC Manager", "error");
    }
  };

  const hadnleAddProduct = (e) => {
    e.preventDefault();

    if (selectedOption && selectedOptionPrice)
      setChosenVariants((prev) => [
        ...chosenVariants,
        {
          _id: chosenVariants.length + 1,
          name: selectedOption.label,
          value: selectedOption.value,
          price: selectedOptionPrice.value,
        },
      ]);
  };

  const hadnleDelteProductItem = (_id) => {
    let data = chosenVariants.filter((item) => item._id !== _id);
    let id = 1;
    data = data.map((data) => {
      data._id = id;
      id++;
      return data;
    });
    setChosenVariants(data);
  };

  const selectProduct = (product) => {
    setProduct(products.find((p) => p._id === product.value));
  }

  useEffect(() => {
    loadCouriers();
  }, [product]);

  useEffect(() => {
    handleSetCouriersData();
  }, [productsWithCouriers]);

  return (
    <PopUp setShow={setShow}>
      {(hasUnfilledRequest(createOrderWithShipping) ||
        hasUnfilledRequest(updateOrderByConsultant)) && <Loading />}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginBottom: "20px" }}>Order</h2>{" "}
        <div style={{ display: 'flex', flexDirection: 'column', gap: "10px" }}>
          <MoreInfo
            changeColor
            product={product?.short}
            email={contact.email}
            sendMail={"Send"}
            order={order}
          />
          <SupportMail order={order} />
        </div>
      </div>
      <form onSubmit={(e) => handleSaveOrder(e)}>
        <div style={{ display: "flex" }}>
          <div>
            <h4 style={{ margin: "20px" }}>
              <u>Contact Info:</u>
            </h4>
            <Input
              inputRef={nameRef}
              name="Full name"
              value={contact.full_name}
              required={true}
              width={100}
            />
            <Input
              name="Email"
              type="email"
              inputRef={emailRef}
              value={contact.email}
              required={true}
              width={100}
            />
            <Input
              name="Phone"
              inputRef={phoneRef}
              value={contact.phone_number}
              disabled
              width={100}
            />
          </div>
          <div>
            <h4 style={{ margin: "20px" }}>
              <u>Shipping Info:</u>
            </h4>
            <Input
              inputRef={suggestedDeliveryDateRef}
              type="date"
              name="Suggested delivery date"
              value={
                order.shipping?.suggested_delivery_date
                  ? moment(order.shipping.suggested_delivery_date).format(
                    "YYYY-MM-DD"
                  )
                  : moment().add(2, "days").format("YYYY-MM-DD")
              }
              required={true}
              width={250}
            />
            <Input
              inputRef={streetRef}
              name="Street"
              value={contact.street}
              required={true}
              width={250}
            />
            <Input
              name="Postal code"
              inputRef={postalCodeRef}
              value={contact.postal_code}
              required={true}
              width={250}
            />
            <Input
              name="City"
              inputRef={cityRef}
              value={contact.city}
              required={true}
              width={250}
            />
            <SelectInput
              name="Country"
              selected={selectedCountry}
              setSelected={setSelectedCountry}
              width={250}
              options={COUNTRIES}
            />
            <Input
              name="Country code"
              value={country.value ? country.value : ""}
              width={250}
              disabled={true}
            />
            <SelectInput
              selected={selectedCourier}
              options={courierOptions}
              width={250}
              name="Courier"
              setSelected={setSelectedCourier}
              disabled={order?.shipping?.waybill_number}
            />
          </div>
        </div>
        <div>
          <h4 style={{ margin: "20px" }}>
            <u>Items:</u>
          </h4>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: product ? "center" : "flex-start",
              gap: "20px",
              alignItems: "center",
              marginTop: "20px",
              flexWrap: product ? "nowrap" : "wrap",
            }}
          >
            {product ? <label
              style={{
                fontSize: "20px",
                display: "flex",
                padding: "0px 30px",
              }}
            >
              <strong style={{ marginRight: "20px" }}>
                Name:
              </strong>{" "}
              {product.name}
            </label>
              : <div style={{ width: "100%", display: 'flex' }}>
                <SelectInput
                  name="Product"
                  width={80}
                  setSelected={selectProduct}
                  options={availableProductsOptions}
                />
              </div>}
            <SelectInput
              name={"Variants"}
              width={80}
              selected={selectedOption}
              setSelected={setSelectedOption}
              options={product?.variants.map((varaint) => {
                return { label: varaint.name, value: varaint.short };
              })}
              selectWidth={140}
            />
            <SelectInput
              name={"Price"}
              width={40}
              selected={selectedOptionPrice}
              setSelected={setSelectedOptionPrice}
              options={product?.prices.map((price) => {
                return { label: price.value, value: price.value };
              })}
              selectWidth={140}
            />
            <div style={{ display: "flex", justifyContent: "right" }}>
              <ActionButton
                onClick={(e) => hadnleAddProduct(e)}
                text="Add"
              />
            </div>
          </div>

          {chosenVariants.length > 0 && (
            <div
              style={{
                marginTop: "30px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ fontSize: "20px", textAlign: "left" }}>
                      Variant
                    </th>
                    <th style={{ fontSize: "20px", textAlign: "center" }}>
                      Short
                    </th>
                    <th style={{ fontSize: "20px", textAlign: "center" }}>
                      Price
                    </th>
                    <th style={{ fontSize: "20px", textAlign: "center" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {chosenVariants.map((data) => (
                    <tr style={{}}>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "left",
                          padding: "11px 0px",
                        }}
                      >
                        {data.name}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                        }}
                      >
                        {data.value}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                        }}
                      >
                        {data.price} {market.currency}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                        }}
                      >
                        <ActionButton
                          onClick={() => hadnleDelteProductItem(data._id)}
                          style={{ background: "none", border: "none" }}
                        >
                          <i
                            class="fa fa-remove"
                            style={{ color: "red", fontSize: "20px" }}
                          ></i>
                        </ActionButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <SubmitButton
          style={{ float: "right" }}
          text={create ? "Create" : "Save"}
        />
      </form>
    </PopUp>
  );
};

export default OrderEdit;
