import React, { useMemo } from 'react'
import { ElementsContainer, Flex, Info, PaginationElementsWrapper } from './Pagination.styled';
import Input from '@/common/components/Input';
import { Colors } from '@/common/colors/colors';
import { getTotalPages } from '../../helpers/getTotalPages';
import { DEFAULT_LIMIT } from '../../constants/searchParams';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import ActionButton from '@/common/components/buttons/ActionButton';

const Pagination = ({
	page,
	count,
	setSearchParams,
	handleInputPageChange,
	inputPage,
}) => {
	const { addMessage } = useMessageQueueContext();

	const totalPages = useMemo(() => getTotalPages(count, DEFAULT_LIMIT), [count, DEFAULT_LIMIT]);

	const goToPage = () => {
		if (!inputPage) {
			return addMessage("Please enter a page number", "error");
		}

		setSearchParams({
			page: inputPage
		})
	}

	return (
		<PaginationElementsWrapper>
			<Flex>
				<Info>
					{page} of {totalPages}
				</Info>
				<ElementsContainer>
					<Input 
						inputFontSize='14px'
						onChange={handleInputPageChange} 
						value={inputPage} type='number' 
						min={1} 
						max={totalPages}
						controlled={true}
						showLabel={false}
						inputWidth={70}
						margin='0'
						height={34}
						placeholder='Page'
					/>
					<ActionButton color={Colors.purple} onClick={() => goToPage()} defaultText="Go"/>
				</ElementsContainer>
			</Flex>
			<ElementsContainer>
				<ActionButton 
					style={{ padding: "0 16px" }}
					disabled={page === 1} 
					color={Colors.purple} 
					onClick={() => {setSearchParams({
						page: --page
					})}} 
					defaultText="<" 
				/>
				<ActionButton 
				style={{ padding: "0 16px" }}
					disabled={page === totalPages} 
					color={Colors.purple} 
					onClick={() => setSearchParams({
						page: ++page
					})} 
					defaultText=">"
				/>
			</ElementsContainer>
		</PaginationElementsWrapper>
	)
}

export default Pagination
