import { getValidInvoicesDashboard } from '@/API/repositories/reports';
import Table from '@/common/components/Table';
import { useRequestsContext } from '@/common/hooks/requestHook';
import React, { useEffect, useMemo, useState } from 'react'

const HEADERS = ['Market', 'To accept', 'Overdue'];

const ValidInvoicesDashboard = () => {
	const [raws, setRaws] = useState([]);

	const { makeRequest, hasUnfilledRequest } = useRequestsContext();

	const shouldShowLoading = useMemo(
		() => !raws?.length && hasUnfilledRequest(getValidInvoicesDashboard),
		[raws, hasUnfilledRequest]
	);

	const loadData = async () => {
		const response = await makeRequest(getValidInvoicesDashboard);

		if (!response?.data) {
			return;
		}

		const currentRaws = response.data.map((invoice) => (
			<tr key={invoice.marketName}>
				<td>{invoice.marketName}</td>
				<td>{invoice.total}</td>
				<td>{invoice.overdue}</td>
			</tr>
		))

		setRaws(() => currentRaws);
	}


	useEffect(() => {
		loadData();
	}, []);

	return (
		<Table loading={shouldShowLoading} headers={HEADERS} raws={raws} className="styled-table-dashboard" />
	)
}

export default ValidInvoicesDashboard