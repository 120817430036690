import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { findMarketById } from "@/common/functions/findMarketById";
import { findProductById } from "@/common/functions/findProductById";
import { DEFAULT_LIMIT } from "../constants/searchParams";
import { TableCell } from "../ResignFeedbackReport.styled";
import { RESIGN_OPTIONS } from "@/common/constants/resignOptions";

export const getRaws = ({
	data, markets, products, page, cleanTagOptions
}) => data.map((resign, index) => (
	<tr key={resign._id}>
		<TableCell>{(page - 1) * DEFAULT_LIMIT + index + 1}</TableCell>
		<TableCell>{findMarketById(resign.market, markets)?.name}</TableCell>
		<TableCell>{findProductById(resign.product, products)?.name}</TableCell>
		<TableCell>{resign.contact.full_name}</TableCell>
		<TableCell>{resign.contact.email}</TableCell>
		<TableCell>{resign.confirmation_date ? formatToDateTamplate(resign.confirmation_date) : "----"}</TableCell>
		<TableCell>{formatToDateTamplate(resign.resignation_date)}</TableCell>
		<TableCell>{RESIGN_OPTIONS.find(o => o.value === resign.resign_comment)?.label}</TableCell>
		<TableCell>{resign?.manual_resign_comment}</TableCell>
		<TableCell>
			{resign?.resign_tags?.length ? resign.resign_tags
				.map(tagId => cleanTagOptions.find(t => t.value === tagId)?.label).join(', ')
				: '----'}
		</TableCell>
	</tr>
));
