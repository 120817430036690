import { createMailTest, updateMailTest } from "@/API/repositories/mailTest";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { ButtonSaveWrapper, Title } from "@/common/styles/Mails";
import { useRef, useState } from "react";
import { filterTemplates } from "../helpers/filterTemplates";
import SubmitButton from "@/common/components/buttons/SubmitButton";

export const MailTemplateElement = ({
  element,
  setElement,
  reload,
  templates,
}) => {
  const { makeRequest } = useRequestsContext();
	const { addMessage } = useMessageQueueContext();

  const [selectedTemplates, setSelectedTemplates] = useState(
    filterTemplates.bind(null, templates, element)
  );

  const templateNameRef = useRef();

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (selectedTemplates.length == 0) {
      addMessage("Please add mail template", "error");
      return;
    }
    const payload = {};

    payload._id = element._id || null;
    payload.name = templateNameRef.current?.value || element.name;
    payload.mail_template_ids = selectedTemplates.map(
      (template) => template.value
    );

    let response;

    if (payload._id) {
      response = await makeRequest(updateMailTest.bind(null, payload));
    } else {
      response = await makeRequest(createMailTest.bind(null, payload));
    }

    if (response.data) {
      addMessage("Saved", "success");
      await reload();
      setElement(() => null);
    }
  };

  return (
    <PopUp setShow={setElement}>
      <Title>Mail Test</Title>
      <form onSubmit={(e) => handleSave(e)}>
        <Input
          inputRef={templateNameRef}
          width={230}
          inputWidth={400}
          name="Name"
          color={Colors.darkBlue}
          value={element?.data && element?.data[0]?.name}
          requiredSign
          required
        />
        <SelectInput
          name={"Mail templates"}
          width={230}
          selectWidth={400}
          color={Colors.darkBlue}
          options={templates}
          selected={selectedTemplates}
          setSelected={setSelectedTemplates}
          multiple
        />
        <ButtonSaveWrapper>
          <SubmitButton text="Save" />
        </ButtonSaveWrapper>
      </form>
    </PopUp>
  );
};