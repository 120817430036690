import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { SearchBarCenter } from "@/common/styles/SearchBars";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import SelectInput from "@/common/components/SelectInput";
import userManager from "@/API/userManager";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { Wrapper } from "./ConsultantTeamCallHistory.styled";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import { formatToDateTimeFormaterTemplate } from "@/common/functions/dateFormater";
import { getConsultantsCallHistoryReport } from "@/API/query/reports";
import Table from "@/common/components/Table";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const HEADERS = [
  "No.",
  "Consultant",
  "Phone number",
  "Start",
  "End",
  "Status",
  "Type",
];

const ConsultantTeamCallHistory = () => {
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [data, setData] = useState();
  const startDateInput = formatToDateTimeFormaterTemplate(moment());
  const { translation } = useTranslationContext();

  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const dateFromRef = useRef();
  const dateToref = useRef();

  const currentUserId = userManager.getUser().id;

  const consultants = useMemo(() => {
    return handleMapToOptions(
      users?.filter((user) =>
        user.managers?.some((m) => m.manager === currentUserId)
      ) || [],
      "username",
      "_id"
    );
  }, [users, currentUserId]);

  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedConsultant.length) {
      addMessage("Select consultants", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.consultants = selectedConsultant.map(
      (consultant) => consultant.value
    );

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getConsultantsCallHistoryReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      const mapedData = response.data.map((d) => (
        <tr>
          {d.map((di) => (
            <td>{di}</td>
          ))}
        </tr>
      ));
      setData(() => mapedData);
    }
  };

  return (
    <Wrapper>
      {hasUnfilledRequest(getConsultantsCallHistoryReport) && <Loading />}
      <SearchBarCenter>
        <div>
          <Input
            name={translation[`Download from`]}
            width={140}
            color={Colors.darkBlue}
            inputRef={dateFromRef}
            type="datetime-local"
            inputWidth={180}
            value={startDateInput}
          />
          <Input
            name={translation[`Download to`]}
            width={140}
            color={Colors.darkBlue}
            inputRef={dateToref}
            type="datetime-local"
            inputWidth={180}
            value={startDateInput}
          />
        </div>
        <SelectInput
          name={translation[`Download Consultants`]}
          options={consultants}
          color={Colors.darkBlue}
          width={100}
          setSelected={setSelectedConsultant}
          selected={selectedConsultant}
          multiple={true}
        />
        <ActionButton defaultText="Search" onClick={(e) => hanldeSearch(e)} />
        <ActionButton
          defaultText={translation[`Add All`]}
          onClick={() => setSelectedConsultant(consultants)}
        />
      </SearchBarCenter>
      {data && (
        <Table
          style={{ margin: "0 auto" }}
          className="styled-table"
          raws={data}
          stickyHeader={true}
          headers={HEADERS.map((h) => translation[h])}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantTeamCallHistory;
