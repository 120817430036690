import { getSearchedContactWithOrders } from "@/API/repositories/contact";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import ContactSearch from "@/common/components/contactSearch/ContactSearch";
import { useRequestsContext } from "@/common/hooks/requestHook";
import React, { useRef, useState } from "react";
import ActionButton from "@/common/components/buttons/ActionButton";
import {
  ButtonWrapper,
  CheckedDataWrapper,
  ContactsCloser,
  ContactsWrapper,
  Title,
} from "./AddContact.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { addTicketAttachments } from "@/API/repositories/tickets";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const AddContact = ({
  setIsAddContactOpen,
  selectedTicket,
  loadData,
  setIsAttachmentLoading,
}) => {
  const [checkedContact, setCheckedContact] = useState();
  const [checkedOrder, setCheckedOrder] = useState();
  const [contacts, setContacts] = useState();
  const [storedContacts, setStoredContacts] = useState();

  const textareaRef = useRef();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const {
    commonData: { products },
  } = useCommonDataContext();

  const handleSearch = async () => {
    if (!textareaRef.current?.value)
      return addMessage("Type something before search", "error");

    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, textareaRef.current.value)
    );

    if (!response.data?.length > 0) return addMessage("Not found anyone");

    setContacts(() => response.data);
    setStoredContacts(() => response.data);

    textareaRef.current.value = "";
  };

  const resetCheckedData = () => {
    setCheckedContact(() => null);
    setCheckedOrder(() => null);
  };

  const handleAddAttachments = async () => {
    setIsAttachmentLoading(() => true);
    const payload = {};

    payload.order = checkedOrder;
    payload.contact = checkedContact;

    const response = await makeRequest(
      addTicketAttachments.bind(null, selectedTicket._id, payload)
    );

    if (response?.data) {
      setIsAddContactOpen(() => false);
      await loadData();
    }
    setIsAttachmentLoading(() => false);
  };

  const getOrderDataById = (orderId) => {
    const contact = storedContacts?.find((contact) =>
      contact.orders.some((order) => order._id === orderId)
    );

    const order = contact.orders.find((order) => order._id === orderId);

    const stringToDisplay = `${contact.full_name}, ${
      products.find((product) => product._id === order.product)?.name
    } ${order.shipping?.waybill_number || ""}`;

    return stringToDisplay;
  };

  return (
    <PopUp padding="10px 20px 18px" setShow={setIsAddContactOpen}>
      {!!contacts?.length && (
        <ContactsCloser onClick={() => setContacts(null)} />
      )}
      {hasUnfilledRequest(
        getSearchedContactWithOrders,
        addTicketAttachments
      ) && <Loading />}
      <ContactsWrapper>
        <Title>Add contact or order</Title>
        {(checkedContact || checkedOrder) && (
          <CheckedDataWrapper onClick={() => resetCheckedData()}>
            {checkedContact
              ? storedContacts?.find(
                  (contact) => contact._id === checkedContact
                )?.full_name
              : getOrderDataById(checkedOrder)}
            <i className="fa fa-check" />
          </CheckedDataWrapper>
        )}
        <ContactSearch
          handleSearch={handleSearch}
          contacts={contacts}
          setContacts={setContacts}
          checkedContact={checkedContact}
          checkedOrder={checkedOrder}
          setCheckedContact={setCheckedContact}
          setCheckedOrder={setCheckedOrder}
          textareaRef={textareaRef}
        />
      </ContactsWrapper>
      <ButtonWrapper>
        <ActionButton onClick={handleAddAttachments} defaultText="Save" />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddContact;
