import React, { useEffect, useState } from "react";
import { getAllFaqs } from "../../../API/repositories/faq";
import { Colors } from "../../../common/colors/colors";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import MDEditor from "@uiw/react-md-editor";
import {
  FAQInfo,
  FAQQuestion,
  FAQSection,
  FAQType,
  FAQWrapper,
  SubtitleWrapper,
  TitleWrapper,
} from "./consultantFaq.styles";
import { groupFaqs } from "./helper/groupFaqs";
import { getTextByUserLangAndType } from "./helper/getTextByUserLangAndType";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ConsultantFaq = () => {
  const [showDescription, setShowDescription] = useState();
  const [data, setData] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  
  const { translation: translations} = useTranslationContext();

  const loadData = async () => {
    const response = await makeRequest(getAllFaqs);

    if (response.data) {
      setData(groupFaqs(response.data));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {hasUnfilledRequest(getAllFaqs) && <Loading />}
      <FAQWrapper>
        <TitleWrapper className="fade__in__animation">
          {translations["facts_questions"]}
        </TitleWrapper>
        <SubtitleWrapper className="fade__in__animation">
          {translations["facts_description"]}
        </SubtitleWrapper>
        {data &&
          data.map((e) => (
            <FAQSection>
              <FAQType className={`fade__in__animation`}>
                {translations["category"]}: {e.name.toUpperCase()}
              </FAQType>
              {e.value.map((question, i) => (
                <FAQInfo
                  className={`fade__in__animation ${
                    showDescription?._id === question._id ? "open" : ""
                  }`}
                  style={{ animationDelay: `${i * 100}ms` }}
                >
                  <FAQQuestion>
                    <i
                      className={`fa ${
                        showDescription?._id !== question._id
                          ? "fa-plus"
                          : "fa-minus"
                      } animation-scale`}
                      style={{ cursor: "pointer", color: Colors.darkBlue }}
                      onClick={() =>
                        setShowDescription((prev) => {
                          return prev?._id === question?._id ? null : question;
                        })
                      }
                    ></i>
                    {getTextByUserLangAndType(
                      question,
                      "question",
                      "original_question"
                    )}
                  </FAQQuestion>
                  {showDescription && showDescription?._id === question._id && (
                    <MDEditor.Markdown
                      source={getTextByUserLangAndType(
                        question,
                        "answer",
                        "original_answer"
                      )}
                      style={{
                        backgroundColor: Colors.lightGray,
                        color: Colors.green,
                      }}
                    />
                  )}
                </FAQInfo>
              ))}
            </FAQSection>
          ))}
      </FAQWrapper>
    </>
  );
};

export default ConsultantFaq;
