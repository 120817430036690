import { Colors } from "@/common/colors/colors";

export const getColor = (priority) => {
  switch (priority) {
    case "low_priority":
      return Colors.yellow;
    case "high_priority":
      return Colors.red;
    default:
      return "";
  }
};
