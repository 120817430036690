import Input from '@/common/components/Input'
import React, { useState } from 'react'
import { BankItemsWrapper, BankTransferWrapper, ButtonContent } from '../../ClaimPayment.styled'
import SelectInput from '@/common/components/SelectInput'
import { ACCOUNTS_CONVERSION, CONVERSION_OPTIONS } from '@/common/components/validClaimForm/constants/accountsConversion'
import { Colors } from '@/common/colors/colors'
import Icon from '@/common/components/Icon'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { convertBankDataToIBAN } from '@/API/repositories/validClaim'
import Loading from '@/common/components/Loading'
import { validateIBAN } from '@/common/functions/validateIban'
import { checkDisabled } from '../../helpers/checkDisabled'
import { findCountry } from '../../helpers/findCountry'
import { sendMailByMailType } from '@/API/repositories/mail'
import ActionButton from '@/common/components/buttons/ActionButton'
import { useValidClaim } from '@/common/hooks/useValidClaim'

const BankTransfer = () => {
	const [isAsked, setIsAsked] = useState(false);

	const { commonData: { markets } } = useCommonDataContext();
	const { makeRequest, hasUnfilledRequest } = useRequestsContext();
	const { addMessage } = useMessageQueueContext();
	const {
		claim,
		ibanRef,
		ibanCountry,
		setIbanCountry,
		accountsDataState,
		dispatchAccountsData,
		claimStatus,
		contact,
		order
	} = useValidClaim();

	const disabled = checkDisabled(claimStatus);

	const sendCategoryMail = async () => {
		const mailResponse = await makeRequest(
			sendMailByMailType.bind(
				null,
				"BANK_ACCOUNT_" + contact.country.toUpperCase(),
				order._id
			)
		);

		if (!mailResponse?.data) {
			return addMessage("Couldn't send mail", "error");
		}

		setIsAsked(true);
		setTimeout(() => {
			setIsAsked(false);
		}, 3000);
		addMessage("Mail sent", "success");
	}

	const countryOptions = markets.map((market) => ({
		value: market._id,
		label: market.short.toUpperCase()
	}));

	const validateBeforeConversion = () => {
		const accountConversion = ACCOUNTS_CONVERSION[ibanCountry?.label.toUpperCase()];

		if (!accountConversion) {
			return;
		}

		let error = false;

		Object.values(accountConversion).forEach((item) => {
			if (error) {
				return;
			}

			if (!accountsDataState[item.name] && item.required) {
				error = true;
				addMessage(`${CONVERSION_OPTIONS.find(o => o.value === item.name)?.label} is required`, "error");
				return;
			}

			if (item.pattern && !(item.pattern.test(accountsDataState[item.name]))) {
				error = true;
				addMessage(`${CONVERSION_OPTIONS.find(o => o.value === item.name)?.label} is not valid`, "error");
			}
		});

		return !error;
	}

	const convert = async () => {
		if (!validateBeforeConversion()) {
			return;
		}

		const response = await makeRequest(convertBankDataToIBAN
			.bind(null, { ...accountsDataState, country: ibanCountry?.label }));

		if (!response?.data) {
			return addMessage("Couldn't convert bank data, close window now and try again in 10 minutes", "error");
		}

		const iban = response.data.trim();

		if (iban.slice(0, 2).toUpperCase() !== ibanCountry?.label) {
			return addMessage("IBAN country code doesn't match the selected country", "error");
		}

		const isValidIban = validateIBAN(iban);

		if (!isValidIban) {
			return addMessage("Received IBAN is not valid", "error");
		}

		ibanRef.current.value = iban;
	}

	const handleSelectCountry = (country) => {
		setIbanCountry(country);

		const accountConversion = ACCOUNTS_CONVERSION[country.label.toUpperCase()];

		if (!accountConversion || country.label === ibanCountry?.label) {
			return;
		}


		Object.values(accountConversion).forEach((item) => {
			dispatchAccountsData({ type: item.name, payload: "" });
		});
	}

	return (
		<>
			<BankTransferWrapper>
				{hasUnfilledRequest(convertBankDataToIBAN, sendMailByMailType) && <Loading />}
				<BankItemsWrapper>
					<SelectInput
						disabled={disabled}
						labelUp
						selectFontSize={12}
						fontSize={12}
						name={"Country"}
						selectWidth={80}
						width={80}
						color={Colors.darkBlue}
						dropdownIndicatorPadding='0'
						margin='0'
						minHeight='20px'
						upGap='4px'
						textAlign='left'
						options={countryOptions}
						setSelected={handleSelectCountry}
						selected={findCountry(countryOptions, ibanCountry?.value)}
					/>
					{ACCOUNTS_CONVERSION[ibanCountry?.label.toUpperCase()] &&
						<>
							{Object.entries(ACCOUNTS_CONVERSION[ibanCountry?.label.toUpperCase()])?.map(([key, item]) => (
								<Input
									placeholderColor={Colors.darkGray}
									inputName={item.name}
									disabled={disabled}
									value={accountsDataState[item.name]}
									controlled={true}
									onChange={(e) => dispatchAccountsData({ type: item.name, payload: e.target.value })}
									name={key}
									color={Colors.darkBlue}
									width="fit-content"
									direction='column'
									noColon
									fontSize='12px'
									inputFontSize='12px'
									gap='4px'
									alignItems="flex-start"
									margin='0'
									placeholder={disabled ? "----" : item.placeholder}
									inputWidth={item.placeholder.length * 9 + 12}
								/>))}
							<ActionButton
								buttonType='secondary'
								disabled={disabled}
								onClick={() => convert()}
								color={Colors.purple}
							>
								<ButtonContent>
									Convert <Icon color='#fff' name="fa fa-arrow-right" />
								</ButtonContent>
							</ActionButton>
						</>
					}
				</BankItemsWrapper>
				<Input
					placeholderColor={Colors.darkGray}
					disabled={disabled}
					inputRef={ibanRef}
					name="IBAN"
					color={Colors.darkBlue}
					direction='column'
					noColon
					fontSize='12px'
					inputFontSize='12px'
					gap='4px'
					width={20}
					alignItems="flex-start"
					margin='0'
					inputWidth={230}
					value={ibanRef.current?.value || claim?.iban}
				/>
			</BankTransferWrapper>
			{!disabled && (
				<ActionButton
					buttonType='secondary'
					disabled={isAsked || disabled}
					onClick={() => sendCategoryMail()}
					style={{ alignSelf: "flex-end" }}
					color={Colors.purple}
				>
					<ButtonContent>
						Ask Bank Number <Icon color='#fff' name="fa fa-envelope" />
					</ButtonContent>
				</ActionButton>
			)}
		</>
	)
}

export default BankTransfer
