import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";
import { checkOnTheWay } from "@/common/functions/checkOnTheWay";

export const handleCheckAddOrder = (data) =>
	!data?.orders?.some((order) => checkIsOrderEditable(order)) &&
	!data?.orders?.some((order) => checkOnTheWay(order));

export const handleCheckAdditionalOrder = (data) =>
	!data?.orders?.some((order) => checkIsOrderEditable(order)) &&
data?.orders?.some((order) => checkOnTheWay(order));


export const isCzechWithoutPromotion = (data) =>
	data?.country === "cz" && !data?.hasPromotion;