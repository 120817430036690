import moment from "moment";
import React, { useEffect, useState } from "react";
import { getTeamCurScore } from "../../../API/repositories/reports";
import { getUserNameAndSurnameById } from "../../../API/repositories/user";
import { Colors } from "../../../common/colors/colors";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { getGradeColor } from "./helpers/getGradeColor";
import {
  GradeCell,
  TableWrapper,
  Wrapper,
} from "./ConsultantTeamCurScore.styled";

const ConsultantTeamCurScore = () => {
  const [results, setResult] = useState(null);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const from = moment().subtract("28", "days").format("DD/MM/YYYY");
  const to = moment().subtract("7", "days").format("DD/MM/YYYY");

  const fun = async (orderID) => {
    const response = await getUserNameAndSurnameById(orderID);

    return response.data;
  };

  const getTeamRsults = async () => {
    const response = await makeRequest(getTeamCurScore.bind(null));
    await fun(response.data[0].index);

    if (response.data) {
      const validatedData = await Promise.all(
        response.data.map(async (data) => {
          const user = await fun(data.index);
          return {
            ...data,
            index: user,
          };
        })
      );

      setResult(() => validatedData);
    }
  };

  useEffect(() => {
    getTeamRsults();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getTeamCurScore) && <Loading />}
      {results && (
        <TableWrapper>
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: Colors.darkBlue,
            }}
          >
            {from} - {to}
          </h3>
          <table className="styled-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Consultant</th>
                <th> Downloaded</th>
                <th>Cost</th>
                <th>Delivered</th>
                <th>Orders value</th>
                <th>CUR</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody className="queue">
              {results.map((result, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{result.index}</td>
                  <td>{result.downloadedSum}</td>
                  <td>{result.costsSum}</td>

                  <td>{result.deliverdSum}</td>
                  <td>{result.deliveredValueSum}</td>
                  <td>{result.wppk}</td>
                  <GradeCell color={getGradeColor(result.grade)}>
                    {result.grade}
                  </GradeCell>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}
    </Wrapper>
  );
};

export default ConsultantTeamCurScore;
