import { useRequestsContext } from "@/common/hooks/requestHook";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./InvoiceTemplates.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import TemplateForm from "./components/templateForm/TemplateForm";
import Loading from "@/common/components/Loading";
import {
  createInvoiceTemplate,
  getAllInvoiceTemplates,
  updateInvoiceTemplate,
} from "@/API/repositories/invoiceTemplate";
import TemplateTable from "./components/templateTable/TemplateTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const InvoiceTemplates = () => {
  const [templates, setTemplates] = useState();
  const [isTemplateFormOpen, setIsTemplateFormOpen] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadData = async () => {
    const response = await makeRequest(getAllInvoiceTemplates);

    if (!response?.data) {
      return addMessage("No data");
    }

    setTemplates(() => response.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        createInvoiceTemplate,
        updateInvoiceTemplate,
        getAllInvoiceTemplates
      ) && <Loading />}
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content", width: "fit-content" }}
          onClick={() => setIsTemplateFormOpen(() => true)}
          defaultText="Add invoice template"
        />
        {!!templates?.length && (
          <TemplateTable
            loadData={loadData}
            templates={templates}
          />
        )}
        {isTemplateFormOpen && (
          <TemplateForm
            loadData={loadData}
            setShow={setIsTemplateFormOpen}
          />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default InvoiceTemplates;
