import React, { useState } from "react";
import OrdersTable from "./components/ordersTable/OrdersTable";
import SearchBar from "./components/searchBar/SearchBar";
import { TableWrapper, Wrapper } from "./ConsultantOrders.styled";

const ConsultantOrders = () => {
  const [dataToDisplay, setDataToDisplay] = useState();

  return (
    <Wrapper>
      <SearchBar setDataToDisplay={setDataToDisplay} />
      <TableWrapper>
        {dataToDisplay && <OrdersTable data={dataToDisplay} />}
      </TableWrapper>
    </Wrapper>
  );
};

export default ConsultantOrders;
