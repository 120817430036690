import { Colors } from '@/common/colors/colors'
import { StyledButton, StyledSecondaryButton } from '@/common/styles/InteractionElements'
import React from 'react'

const getButtonType = (buttonType) => {
	switch (buttonType) {
		case "secondary":
			return StyledSecondaryButton;
		default:
			return StyledButton;
	}
}

const SubmitButton = ({
	text,
	color = Colors.red,
	tooltip,
	children,
	buttonType = "primary",
	...rest
}) => {
	const ButtonType = getButtonType(buttonType);

	return (
		<ButtonType
			type="submit"
			color={color}
			tooltip={tooltip}
			{...rest}
		>
			{children}
			{text}
		</ButtonType>
	)
}

export default SubmitButton
