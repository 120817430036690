import React, { useRef, useState } from "react";
import { Colors } from "../../../../../common/colors/colors";
import Card from "../../../../../common/components/Card";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { getRequestsForUser } from "../../../../../API/repositories/crmRequestsHistory";
import moment from "moment";
import Input from "../../../../../common/components/Input";
import Loading from "../../../../../common/components/Loading";
import { formatDate } from "../../../../../common/functions/dateFormater";
import PopUp from "../../../../../common/components/PopUp";
import {
  BoldSubTitle,
  DataWrapper,
  DateContainer,
  DateWrapper,
  Flex,
  FlexRight,
  Point,
  SettingsIcon,
  SpaceBetween,
  SubTitle,
  Title,
} from "./UserRequestsLog.styled";
import ActionButton from "@/common/components/buttons/ActionButton";

const ONE_HOUR = 1000 * 60 * 60;

export default function UserRequestsLog({ userId }) {
  const [userRequests, setUserRequests] = useState(null);
  const [showDatesChanging, setShowDatesChanging] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getTime() - ONE_HOUR)
  );
  const [toDate, setToDate] = useState(new Date());

  const fromDateRef = useRef();
  const toDateRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadRequestHistory = async () => {
    const payload = {};

    payload.userId = userId;
    payload.dateFrom =
      fromDateRef.current?.value || new Date(new Date().getTime() - ONE_HOUR);
    payload.dateTo = toDateRef.current?.value || new Date();

    const response = await makeRequest(getRequestsForUser.bind(null, payload));

    if (response.data) {
      setUserRequests(() =>
        response.data.sort(
          (reqA, reqB) =>
            new Date(reqB.created_at).getTime() -
            new Date(reqA.created_at).getTime()
        )
      );
    }
  };

  const handleSave = async () => {
    setFromDate(fromDateRef.current?.value);
    setToDate(toDateRef.current?.value);
    setShowDatesChanging(() => false);

    await loadRequestHistory();
  };

  return (
    <>
      {hasUnfilledRequest(getRequestsForUser) && <Loading />}
      <Card>
        <SpaceBetween>
          <Title>
            <u>Requests log:</u>
          </Title>
          <SettingsIcon
            onClick={() => setShowDatesChanging(true)}
            className="animation-scale fa fa-gear"
          />
        </SpaceBetween>
        <FlexRight>
          <ActionButton
            onClick={() => loadRequestHistory()}
            defaultText="Find Request History"
          />
        </FlexRight>
        {userRequests && userRequests.length > 0 ? (
          userRequests.map((userRequest) => (
            <Flex key={userRequest._id}>
              <DateContainer>
                <Point />
                {moment(userRequest.created_at).format("HH:mm:ss")}
              </DateContainer>
              <DataWrapper>
                <DateWrapper>
                  {moment(userRequest.created_at).format("YYYY-MM-DD")}
                  {userRequest.request_method ? (
                    <BoldSubTitle color={Colors.green}>API</BoldSubTitle>
                  ) : (
                    <BoldSubTitle color={Colors.red}>Web sockets</BoldSubTitle>
                  )}
                </DateWrapper>
                <SubTitle>Access name: {userRequest.access_name}</SubTitle>
                <SubTitle>IP: {userRequest.ip}</SubTitle>
                <SubTitle>URL: {userRequest.url}</SubTitle>
                <SubTitle>User agent: {userRequest.user_agent}</SubTitle>
                {userRequest.request_method && (
                  <SubTitle>Method: {userRequest.request_method}</SubTitle>
                )}
                {userRequest.request_body &&
                  userRequest.request_body !== "{}" && (
                    <SubTitle>Body: {userRequest.request_body}</SubTitle>
                  )}
                <SubTitle>
                  Status:{" "}
                  {userRequest.approve ? (
                    <span style={{ color: Colors.green }}>Approved</span>
                  ) : (
                    <span style={{ color: Colors.red }}>Rejected</span>
                  )}
                </SubTitle>
              </DataWrapper>
            </Flex>
          ))
        ) : (
          <SubTitle>No requests found</SubTitle>
        )}
      </Card>
      {showDatesChanging && (
        <PopUp setShow={setShowDatesChanging}>
          <Input
            inputRef={fromDateRef}
            value={formatDate(fromDate, "YYYY-MM-DDTHH:mm")}
            color={Colors.darkBlue}
            width={80}
            name="From"
            type="datetime-local"
          />
          <Input
            inputRef={toDateRef}
            value={formatDate(toDate, "YYYY-MM-DDTHH:mm")}
            color={Colors.darkBlue}
            width={80}
            name="To"
            type="datetime-local"
          />
          <FlexRight>
            <ActionButton defaultText="Save" onClick={() => handleSave()} />
          </FlexRight>
        </PopUp>
      )}
    </>
  );
}
