import moment from "moment";
import React, { useRef, useState } from "react";
import { getContactWithFilters } from "../../../API/repositories/contact";
import Card from "../../../common/components/Card";
import CSV from "../../../common/components/CSV";
import Input from "../../../common/components/Input";
import Loading from "../../../common/components/Loading";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import FilterTable from "./components/filterTable/FilterTable";
import { Colors } from "@/common/colors/colors";
import { formatToDateTimeFormaterTemplate } from "@/common/functions/dateFormater";
import ActionButton from "@/common/components/buttons/ActionButton";
import { handleDisplayContacts } from "./helpers/handleDisplayContacts";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { AVAILABLE_CONTACT_FILEDS } from "./constants/avaiableContactFileds";
import {
  ButtonWrapper,
  SearchBarView,
  TableWrapper,
  Wrapper,
} from "./contactFilter.styles";

const ContactsFilterTable = () => {
  const {
    commonData: { markets },
  } = useCommonDataContext();

  const from = formatToDateTimeFormaterTemplate(
    moment().subtract(1, "day").toDate()
  );
  const to = formatToDateTimeFormaterTemplate();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [contactToDisplay, setContactToDisplay] = useState(null);
  const [selectedContactCountry, setSelectedContactCountry] = useState([]);
  const [selectedContactFileds, setSelectedContactFileds] = useState(
    AVAILABLE_CONTACT_FILEDS
  );

  const createdFromRef = useRef();
  const createdToRef = useRef();

  const handleFindContactWithFilters = async (e) => {
    e && e.preventDefault();
    setContactToDisplay(() => null);

    if (!selectedContactCountry[0]) {
      return;
    }

    const payload = {};

    payload.from =
      (createdFromRef.current &&
        moment(createdFromRef.current.value).toISOString()) ||
      from;
    payload.to =
      (createdToRef.current &&
        moment(createdToRef.current.value).toISOString()) ||
      to;
    payload.countries = selectedContactCountry?.map((data) => data.value);

    const contactResponse = await makeRequest(
      getContactWithFilters.bind(null, payload)
    );

    if (contactResponse.data) {
      setContactToDisplay(() =>
        handleDisplayContacts(contactResponse.data, selectedContactFileds)
      );
    }
  };

  return (
    <Wrapper>
      <Card>
        <SearchBarView>
          <SelectInput
            setSelected={setSelectedContactFileds}
            selected={selectedContactFileds}
            multiple={true}
            options={AVAILABLE_CONTACT_FILEDS}
            width={200}
            selectWidth={400}
            showLabel={false}
          />
          <div>
            <Input
              inputRef={createdFromRef}
              name="From"
              type="datetime-local"
              color={Colors.darkBlue}
              value={from}
              inputWidth={180}
              width={80}
            />
            <Input
              inputRef={createdToRef}
              name="To"
              type="datetime-local"
              color={Colors.darkBlue}
              value={to}
              inputWidth={180}
              width={80}
            />
            <SelectInput
              setSelected={setSelectedContactCountry}
              selected={selectedContactCountry}
              multiple={true}
              options={markets.map((m) => ({ label: m.name, value: m.short }))}
              name="Country"
              color={Colors.darkBlue}
              selectWidth={180}
              width={80}
            />
          </div>
        </SearchBarView>
        <ButtonWrapper>
          {contactToDisplay && (
            <CSV
              filename={"contacts.csv"}
              header={selectedContactFileds.map((item) => item["label"])}
              data={contactToDisplay.map((data) => data.data)}
            />
          )}
          <ActionButton
            defaultText={"Search"}
            onClick={handleFindContactWithFilters}
          />
        </ButtonWrapper>
      </Card>
      {contactToDisplay && (
        <TableWrapper>
          <FilterTable
            rows={contactToDisplay}
            columns={selectedContactFileds}
          />{" "}
        </TableWrapper>
      )}
      {hasUnfilledRequest(getContactWithFilters) && <Loading />}
    </Wrapper>
  );
};

export default ContactsFilterTable;
