import { Colors } from "@/common/colors/colors";

export const getColorForResult = (percent) => {
  if (percent < 40) {
    return Colors.red;
  }
  if (percent < 80) {
    return Colors.yellow;
  }

  return Colors.green;
};