import {
  addNewTranslation,
  getTranslationByLang,
} from "./repositories/translations";

class TransaltionManager {
  async getLanguage() {
    const response = await getTranslationByLang();

    if (response.data) {
      localStorage.removeItem("translations");
      const stringLang = JSON.stringify(response.data);
      localStorage.setItem("translations", stringLang);
    }
  }

  async getTranslation(word) {
    let translations = JSON.parse(localStorage.getItem("translations")) || [];

    let translatedWord = translations.find(
      (translation) => translation.value === word
    );
    if (translatedWord) return translatedWord.label;

    await this.getLanguage();

    translations = JSON.parse(localStorage.getItem("translations")) || [];

    translatedWord = translations.find(
      (translation) => translation.value === word
    );
    if (translatedWord) return translatedWord.label;

    await addNewTranslation(word);

    return word;
  }
}

export default new TransaltionManager();
