import React, { useRef, useState } from "react";
import styled from "styled-components";
import Input from "../../../../../common/components/Input";
import SelectInput from "../../../../../common/components/SelectInput";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../common/components/Loading";
import { createContact } from "../../../../../API/repositories/contact";
import { Colors } from "@/common/colors/colors";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Wrapper = styled.div`
  margin: 0px 20px 20px 20px;
`;

const CreateForm = () => {
  const fullNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const phoneNumberForCourierRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const [country, setCountry] = useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();
  const { addMessage } = useMessageQueueContext();
  const { COUNTRIES } = useCommonDataContext();

  const hadnleSaveConsultantContact = async (e) => {
    e.preventDefault();

    if (country) {
      const payload = Object();

      const isPhoneError = phoneNumberForCourierRef?.current?.value
        && !validatePhoneForCourier(phoneNumberForCourierRef.current.value)

      if (isPhoneError) {
        return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
      }

      payload.full_name = fullNameRef.current.value;
      payload.orginal_phone_number = phoneNumberRef.current.value;
      payload.phone_number_for_courier = phoneNumberForCourierRef.current.value;
      payload.email = emailRef.current.value;
      payload.country = country.value;
      payload.city = cityRef.current.value;
      payload.postal_code = postalCodeRef.current.value;
      payload.street = streetRef.current.value;

      const response = await makeRequest(createContact.bind(null, payload));
      if (response.data) {
        navigate(`/dashboard/contact/${response.data}`);
      } else {
        console.error(response.error);
      }
    } else {
    }
  };

  return (
    <Wrapper>
      <h3 style={{ marginBottom: "30px", color: Colors.darkBlue }}>
        Create Contact
      </h3>
      <form onSubmit={(e) => hadnleSaveConsultantContact(e)}>
        <Input
          inputRef={fullNameRef}
          name="Full Name"
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={emailRef}
          type="email"
          name="E-mail"
          required={true}
          color={Colors.darkBlue}
          width={230}
        />
        <Input
          inputRef={phoneNumberRef}
          name="Phone number"
          required={true}
          color={Colors.darkBlue}
          width={230}
        />
        <Input
          inputRef={phoneNumberForCourierRef}
          name="Phone number for courier"
          color={Colors.darkBlue}
          width={230}
        />
        <SelectInput
          name="Country"
          options={COUNTRIES}
          width={230}
          setSelected={setCountry}
          color={Colors.darkBlue}
          selected={country}
        />
        <Input
          inputRef={cityRef}
          name="City"
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={streetRef}
          name="Street"
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={postalCodeRef}
          name="Postal Code"
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <SubmitButton
          style={{ float: "right", margin: "10px", width: "100px" }}
          text="Save"
        />
      </form>
      {hasUnfilledRequest(createContact) && <Loading />}
    </Wrapper>
  );
};

export default CreateForm;
