import SubmitButton from "@/common/components/buttons/SubmitButton";
import React from "react";

const Buttons = ({ setPromotion, setAddRegion, onSave }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        gap: "10px",
        padding: "30px",
        width: "100%",
      }}
    >
      <div>
        <SubmitButton
          text="Save"
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};

export default Buttons;
