export const HEADERS = [
	"No.",
	"Market",
	"Product",
	"Client name",
	"Client mail",
	"Confirmed date",
	"Resign date",
	"Reason",
	"Comment",
	"Tag",
];
