import { findUserMarket } from "@/common/functions/findUserMarket";
import { getGamificationBonus } from "../../ccPayment/components/paymentData/components/paymentSummarizeTable/helpers/getGamificationBonus";
import { calcTotalSalary } from "./calcTotalSalary";
import { calculateHoursPayment } from "./calculateHoursPayment";
import { round } from "@/common/functions/round";
import { caclManagerEffectivePayment } from "./calcManagerEffectivePayment";
import { calcWorking } from "./calcWorking";

const marketFromContract = (contract) => contract?.split("_")[0].toLowerCase();

export const generateMainData = (
  { data, markets, queues, users },
  selectedMarkets
) => {
  const selectedMarketsIds = selectedMarkets?.map((market) => market.value);

  let result = [];

  for (let element of data.result) {
    const user = users.find((e) => e._id === element._id);
    let userMarket = findUserMarket(markets, queues, element._id);

    // getting market from contract type if user is not in any queue
    if (!userMarket) {
      userMarket = markets.find(
        (m) =>
          m.short ===
          marketFromContract(element._month_working_info.contract_type)
      );
    }

    if (
      selectedMarkets &&
      !selectedMarketsIds.includes(userMarket?._id) &&
      !!userMarket
    ) {
      continue;
    }

    const fields = {};
    const preResult = element.data.find((e) => e.is_final === false);

    fields._id = element._id;
    fields.market = userMarket;
    fields.contract_type = element._month_working_info.contract_type;
    fields.name = user.name;
    fields.surname = user.surname;
    fields.from = element._month_working_info.working_from;
    fields.to = element._month_working_info.working_to;
    fields.currency = preResult.salary_currency;

    const attendance = preResult._consultant_hours.reduce(
      (prev, next) => prev + (next.attendance_at_work || 0),
      0
    );

    const managerHours = preResult._consultant_hours.reduce(
      (prev, next) => prev + (next.manager_hours || 0),
      0
    );

    fields.additional_bonus = element._month_working_info.block_bonus
      ? 0
      : element._month_working_info.additional_bonus || 0;
    fields.consultantHours = attendance;
    fields.managerHours = managerHours;
    fields.hoursSum = fields.consultantHours + fields.managerHours;
    fields.gamification_bonus = element._month_working_info.block_bonus
      ? 0
      : element?._gamification_results
      ? getGamificationBonus({
          consultantResults: element,
          paymentData: data.result,
          userMarket,
          markets,
          queues,
          users,
        }) || 0
      : 0;

    fields.pre_consultant = element._month_working_info.block_bonus
      ? 0
      : Math.max(
          calcTotalSalary(preResult.data, element._month_working_info) -
            calculateHoursPayment({
              userId: element._id,
              hours: fields.consultantHours,
              rate: element._month_working_info.hourly_rate,
              users,
            }),
          0
        );

    fields.final_consultant = 0;
    fields.diffrence_consultant = 0;

    fields.pre_manager = element._month_working_info.block_bonus
      ? 0
      : caclManagerEffectivePayment(element, false);

    const lastMonth = data.previousResult.find(
      (result) => result._id === element._id
    );

    if (lastMonth) {
      const preLastMonth = lastMonth.data.find((e) => e.is_final === false);
      const finalLastMonth = lastMonth.data.find((e) => e.is_final === true);
      const hours = calcWorking(preLastMonth._consultant_hours);

      const consultantHourMoney = calculateHoursPayment({
        userId: lastMonth._id,
        hours,
        rate: lastMonth._month_working_info.hourly_rate,
        users,
      });

      const preLatMonthBonus = preLastMonth
        ? calcTotalSalary(preLastMonth.data, lastMonth._month_working_info)
        : 0;

      const finalLastMonthBonus = finalLastMonth
        ? calcTotalSalary(finalLastMonth.data, lastMonth._month_working_info)
        : 0;

      fields.final_consultant = element._month_working_info.block_bonus
        ? 0
        : round(
            finalLastMonthBonus - consultantHourMoney > 0
              ? finalLastMonthBonus -
                  Math.max(preLatMonthBonus, consultantHourMoney)
              : 0
          );

      const preLastMonthManagerBonus = lastMonth.data
        ? caclManagerEffectivePayment(lastMonth, false)
        : 0;

      const finalLastMonthManagerBonus = lastMonth.data
        ? caclManagerEffectivePayment(lastMonth, true)
        : 0;

      fields.final_manager = Math.max(
        element._month_working_info.block_bonus
          ? 0
          : finalLastMonthManagerBonus - preLastMonthManagerBonus,
        0
      );
    }

    const keys = Object.keys(fields);

    keys.forEach((key) => {
      if (typeof fields[key] === typeof 0) {
        fields[key] = Math.max(fields[key], 0);
      }
    });

    fields.sum =
      Math.round(
        ((fields.pre_consultant || 0) +
          (fields.diffrence_consultant || 0) +
          (fields.pre_manager || 0) +
          (fields.diffrence_manager || 0) +
          (fields.additional_bonus || 0) +
          (fields.final_manager || 0) +
          (fields.gamification_bonus || 0) +
          (fields.final_consultant || 0)) *
          100
      ) / 100;

    const hourlyRate = element._month_working_info.hourly_rate;
    const managerHourlyRate = element._month_working_info.manager_hourly_rate;

    fields.totalSum =
      Math.round(
        (fields.sum +
          hourlyRate * fields.hoursSum +
          managerHourlyRate * fields.managerHours) *
          100
      ) / 100;

    result.push(fields);
  }

  return result;
};
