import React, { useContext } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";
import ActionButton from "@/common/components/buttons/ActionButton";

const Wrapper = styled.div`
  position: absolute;
  background: rgba(2, 80, 114, 0.78);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const Card = styled.div`
  background: white;
  width: 500px;
  height: fit-content;
  color: black;
  border-radius: 15px;
  padding: 20px;
`;

const CallingTo = styled.h2`
  color: ${Colors.darkBlue};
  display: flex;
`;

const ElementWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const LoadingAnimation = styled.div`
  margin-top: 2px;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px 1px;
    background: ${Colors.darkBlue};
    animation: wave 1.2s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
`;

const SearchingContact = () => {
  const {
    callingClientCredentials,
    hasPosibilityToCallNext,
    setHasPosibilityToCallNext,
    sendWantBreakAfterNextContact,
  } = useContext(CallCRMApiContextVonage);

  return (
    <Wrapper>
      <Card>
        <CallingTo>
          Calling to: {callingClientCredentials?.contact.full_name}
          <LoadingAnimation>
            <span></span>
            <span></span>
            <span></span>
          </LoadingAnimation>
        </CallingTo>
        <ElementWrapper>
          Order status: {callingClientCredentials?.status}
        </ElementWrapper>
        <ActionButton
          color={Colors.red}
          disabled={!!hasPosibilityToCallNext}
          onClick={() => {
            sendWantBreakAfterNextContact();
            setHasPosibilityToCallNext(() => true);
          }}
          defaultText="Dont call next"
        />
        {/* {(callingClientCredentials.contact?.postal_code ||
          callingClientCredentials.contact?.street ||
          callingClientCredentials.contact?.street) && (
          <TextInfoWrapper>
            Adress: {callingClientCredentials?.contact.street}{" "}
            {callingClientCredentials?.contact.postal_code}{" "}
            {callingClientCredentials?.contact.city}
          </TextInfoWrapper>
        )}
        <TextInfoWrapper>Order items:</TextInfoWrapper>
        <TextInfoWrapper>Last orders:</TextInfoWrapper>
        <TextInfoWrapper>
          Mail 'More info' was send at: 'tu data' and 'status at'
        </TextInfoWrapper> */}
      </Card>
    </Wrapper>
  );
};

export default SearchingContact;
