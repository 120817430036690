import React, { useEffect, useState } from "react";
import { getConsultantTests } from "../../../API/repositories/test";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Table from "../../../common/components/Table";
import { 
  Wrapper
} from "./ConsultantTests.styled";
import { ResolveTest } from "./components/resolveTest/components/resolveTest/ResolveTest";

const ConsultantTests = () => {
  const headers = ["no", "name", "actions"];
  const { makeRequest } = useRequestsContext();
  const [data, setData] = useState([]);
  const [test, setTest] = useState();

  const handleLoadData = async () => {
    const response = await makeRequest(getConsultantTests);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [test]);

  return (
    <Wrapper>
      <Table
        headers={headers}
        raws={data.map((d, i) => (
          <tr>
            <td>{i + 1}.</td>
            <td>{d.name}</td>
            <td>
              <i
                className="fa fa-graduation-cap fa-fade"
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => setTest(() => d)}
              />
            </td>
          </tr>
        ))}
        className={"styled-table"}
      />
      {test && <ResolveTest
       test={test} setShow={setTest} />}
    </Wrapper>
  );
};

export default ConsultantTests;
