import React, { useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  getCompanyDataByNumber,
  getCompanyDataByVat,
} from "@/API/repositories/invoices";
import Loading from "@/common/components/Loading";
import { createValidInvoiceTicket } from "@/API/repositories/tickets";
import { preventFn } from "@/common/functions/preventEvent";
import InvoiceNotSet from "./components/invoiceNotSet/InvoiceNotSet";
import { INVOICE_CREATION_TYPES } from "./contstants/invoices";
import InvoiceByVat from "./components/invoiceByVat/InvoiceByVat";
import InvioceByNumber from "./components/invoiceByNumber/InvioceByNumber";
import OldWayCreate from "./components/oldWayCreate/OldWayCreate";
import PhysicalPerson from "./components/physicalPerson/PhysicalPerson";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import AreYouSure from "./components/areYouSure/AreYouSure";

const InvoiceTicket = ({
  setShowInvoiceTicket,
  orderId,
  market,
  handleSearch = () => { },
}) => {
  const [invoiceCreateBy, setInvoiceCreateBy] = useState(
    INVOICE_CREATION_TYPES.VAT_ID
  );
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const companyNameRef = useRef();
  const vatRef = useRef();
  const companyIdRef = useRef();
  const addressRef = useRef();
  const commentRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleCreateValidInvoiceTicket = async (e, force) => {
    e && preventFn(e);

    const payload = {};

    payload.company_name = companyNameRef.current.value;
    payload.company_address = addressRef.current.value;
    payload.vat_number = vatRef.current?.value;
    payload.company_number = companyIdRef.current?.value;
    payload.additional_info = commentRef.current?.value;
    payload.city = cityRef.current.value.trim();
    payload.street = streetRef.current.value.trim();
    payload.postal_code = postalCodeRef.current.value.trim();


    const isValidAddress = validateAddress();

    const shouldShowPopup = !force && !isValidAddress && payload.vat_number;

    if (shouldShowPopup) {
      return setShowAreYouSure(() => true);
    }

    if (!isValidAddress) {
      payload.additional_info +=
        " Address data was not validated and has to be checked";
    }

    const response = await makeRequest(
      createValidInvoiceTicket.bind(null, orderId, payload)
    );

    if (!response.data) {
      return addMessage("Couldn't create invoice ticket", "error");
    }

    setShowInvoiceTicket(() => false);
    addMessage("Succesfully created invoice ticket", "success");
    handleSearch();
  };

  const handleManualCreation = async (fn) => {
    const success = await fn();

    if (!success) {
      return addMessage("Couldn't create invoice ticket", "error");
    }

    setShowInvoiceTicket(() => false);
    addMessage("Succesfully created invoice ticket", "success");
    handleSearch();
  };

  const removeSpaces = (str) => str?.replaceAll(" ", "");
  const trimmedAddress = removeSpaces(addressRef.current?.value);

  const validateAddress = () => trimmedAddress?.includes(removeSpaces(cityRef.current?.value)) &&
    trimmedAddress?.includes(removeSpaces(streetRef.current?.value)) &&
    trimmedAddress?.includes(removeSpaces(postalCodeRef.current?.value));

  const getYourAddressParts = () =>
    [removeSpaces(streetRef.current?.value), removeSpaces(postalCodeRef.current?.value), removeSpaces(cityRef.current?.value)];

  return (
    <PopUp padding="10px 20px 32px" setShow={setShowInvoiceTicket}>
      {hasUnfilledRequest(
        getCompanyDataByVat,
        getCompanyDataByNumber,
        createValidInvoiceTicket
      ) && <Loading />}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.NOT_SET && (
        <InvoiceNotSet
          setInvoiceCreateBy={setInvoiceCreateBy}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.VAT_ID && (
        <InvoiceByVat
          vatRef={vatRef}
          addressRef={addressRef}
          companyNameRef={companyNameRef}
          handleCreateValidInvoiceTicket={handleCreateValidInvoiceTicket}
          setInvoiceCreateBy={setInvoiceCreateBy}
          commentRef={commentRef}
          streetRef={streetRef}
          cityRef={cityRef}
          postalCodeRef={postalCodeRef}
          orderMarket={market}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.COMPANY_ID && (
        <InvioceByNumber
          handleManualCreation={handleManualCreation}
          companyIdRef={companyIdRef}
          addressRef={addressRef}
          companyNameRef={companyNameRef}
          handleCreateValidInvoiceTicket={handleCreateValidInvoiceTicket}
          setInvoiceCreateBy={setInvoiceCreateBy}
          commentRef={commentRef}
          streetRef={streetRef}
          cityRef={cityRef}
          postalCodeRef={postalCodeRef}
          orderMarket={market}
          orderId={orderId}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.PHYSICAL_PERSON && (
        <PhysicalPerson
          orderId={orderId}
          setShowInvoiceTicket={setShowInvoiceTicket}
          handleSearch={handleSearch}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.OLD_WAY && (
        <OldWayCreate
          orderId={orderId}
          setShow={setShowInvoiceTicket}
        />
      )}
      {showAreYouSure && (
        <AreYouSure
          proceed={handleCreateValidInvoiceTicket}
          setShow={setShowAreYouSure}
          originalAddress={trimmedAddress}
          yourAddressParts={getYourAddressParts()}
        />
      )}
    </PopUp>
  );
};

export default InvoiceTicket;
