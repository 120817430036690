import Input from "@/common/components/Input";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import React, { useRef } from "react";
import { Flex } from "../../../CCHours.styled";
import { acceptConsultantMonthHours } from "@/API/repositories/consultantHours";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";
import { formatToDateTamplate } from "@/common/functions/dateFormater";

const MonthInfoForm = ({
  selectedMonth,
  showConsultantMonth,
  setShowConsultantMonth,
  consultantsFull,
  hanldeSearch,
}) => {
  const { makeRequest } = useRequestsContext();

  const workingFromDate = moment().startOf("month").format("YYYY-MM-DD");
  const workingToDate = moment().endOf("month").format("YYYY-MM-DD");

  const workingFromRef = useRef();
  const workingToRef = useRef();
  const deliveredCommisionRef = useRef();
  const additionalItemCommisionRef = useRef();
  const hourlyRateRef = useRef();
  const contractTypeRef = useRef();
  const managerPercentRef = useRef();
  const managerHourlyRateRef = useRef();
  const additionalBonusRef = useRef();
  const contractHoursRef = useRef();
  const monthHoursRef = useRef();

  const handleAcceptHoursFromEdit = async (e) => {
    e && e.preventDefault();
    const consultant = consultantsFull.find(
      (c) => c._id === showConsultantMonth._id
    );

    const payload = {};
    payload.delivered_commision =
      deliveredCommisionRef.current?.value ||
      showConsultantMonth._month_working_info?.delivered_commision ||
      consultant.delivered_commision;
    payload.hourly_rate =
      hourlyRateRef.current?.value ||
      showConsultantMonth._month_working_info?.hourly_rate ||
      consultant.hourly_rate;
    payload.contract_type =
      contractTypeRef.current?.value ||
      showConsultantMonth._month_working_info?.contract_type ||
      consultant.contract_type;
    payload.manager_percent =
      managerPercentRef.current?.value ||
      showConsultantMonth._month_working_info?.manager_percent ||
      consultant.manager_percent;
    payload.manager_hourly_rate =
      managerHourlyRateRef.current?.value ||
      showConsultantMonth._month_working_info?.manager_hourly_rate ||
      consultant.manager_hourly_rate;
    payload.additional_item_commision =
      additionalItemCommisionRef.current?.value ||
      showConsultantMonth._month_working_info?.additional_item_commision ||
      consultant.additional_item_commision;

    payload.working_from =
      workingFromRef.current?.value ||
      showConsultantMonth._month_working_info?.working_from ||
      workingFromDate;
    payload.working_to =
      workingToRef.current?.value ||
      showConsultantMonth._month_working_info?.working_to ||
      workingToDate;

    payload.working_from = moment(payload.working_from).toISOString();

    payload.working_to = moment(payload.working_to).endOf("day").toISOString();

    payload.toConfirm = showConsultantMonth.data.map((element) => element._id);
    payload.consultant = consultant._id;
    payload.month = selectedMonth.value;

    payload.contract_hours = contractHoursRef.current?.value || 0;
    payload.month_hours = monthHoursRef.current?.value || 0;

    payload.additional_bonus =
      additionalBonusRef.current?.value ||
      showConsultantMonth._month_working_info?.additional_bonus ||
      0;

    setShowConsultantMonth(() => null);

    const response = await makeRequest(
      acceptConsultantMonthHours.bind(null, payload)
    );

    if (response.data) {
      await hanldeSearch(e);
    }
  };

  return (
    <>
      <div>
        <Flex>
          <Input
            width={160}
            inputWidth={130}
            inputRef={contractHoursRef}
            name="Day hours"
            type="number"
            value={
              showConsultantMonth._month_working_info?.contract_hours ||
              consultantsFull.find((c) => c._id === showConsultantMonth._id)
                .contract_hours
            }
            color={Colors.darkBlue}
          />
          <Input
            width={160}
            inputWidth={130}
            inputRef={monthHoursRef}
            name="Month hours"
            type="number"
            value={
              showConsultantMonth._month_working_info?.month_hours ||
              consultantsFull.find((c) => c._id === showConsultantMonth._id)
                .month_hours
            }
            color={Colors.darkBlue}
          />
          <Input
            width={160}
            inputWidth={130}
            inputRef={contractTypeRef}
            name="Contract type"
            value={
              showConsultantMonth._month_working_info?.contract_type ||
              consultantsFull.find((c) => c._id === showConsultantMonth._id)
                .contract_type
            }
            color={Colors.darkBlue}
          />
          <Input
            width={160}
            inputWidth={130}
            inputRef={additionalBonusRef}
            name="Additinal bonus"
            type="number"
            value={
              showConsultantMonth._month_working_info?.additional_bonus || 0
            }
            color={Colors.darkBlue}
          />
          <Input
            width={160}
            inputWidth={130}
            inputRef={workingFromRef}
            name="Working from"
            type="date"
            value={
              (showConsultantMonth._month_working_info?.working_from &&
                moment(
                  showConsultantMonth._month_working_info?.working_from
                ).format("YYYY-MM-DD")) ||
              workingFromDate
            }
            color={Colors.darkBlue}
          />
          <Input
            width={160}
            inputWidth={130}
            inputRef={workingToRef}
            name="Working to"
            type="date"
            value={
              (showConsultantMonth._month_working_info?.working_to &&
                moment(showConsultantMonth._month_working_info?.working_to)
                  .utcOffset(0)
                  .format("YYYY-MM-DD")) ||
              workingToDate
            }
            color={Colors.darkBlue}
          />
        </Flex>
        <ButtonWrapper>
          <ActionButton
            defaultText={"Save && Accept hours"}
            onClick={(e) => handleAcceptHoursFromEdit(e)}
          />
        </ButtonWrapper>
      </div>
    </>
  );
};

export default MonthInfoForm;
