import ActionButton from "@/common/components/buttons/ActionButton";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { CenteredTable, ContentWrapper } from "./MetaAccountsList.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  createMetaAccount,
  getMetaAccounts,
  updateMetaAccount,
} from "@/API/repositories/meta";
import MetaAccountsTable from "./components/metaAccountsTable/MetaAccountsTable";
import MetaAccountForm from "./components/metaAccountForm/MetaAccountForm";
import Loading from "@/common/components/Loading";

const MetaAccountsList = () => {
  const [accounts, setAccounts] = useState();
  const [isCreateOpen, setIsCreateOpen] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getMetaAccounts);

    if (response.data) setAccounts(() => response.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getMetaAccounts,
        updateMetaAccount,
        createMetaAccount
      ) && <Loading />}
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content" }}
          onClick={() => setIsCreateOpen(() => true)}
          defaultText="Create"
        />
        {!!accounts?.length && (
          <CenteredTable>
            <MetaAccountsTable
              loadData={loadData}
              accounts={accounts}
            />
          </CenteredTable>
        )}
      </ContentWrapper>
      {isCreateOpen && (
        <MetaAccountForm
          loadData={loadData}
          setShow={setIsCreateOpen}
        />
      )}
    </PageWrapper>
  );
};

export default MetaAccountsList;
