import React, { useEffect, useState } from "react";
import { ButtonWrapper, SearchBar } from "../claimNotEnded.styles";
import { CLAIM_STATUS_OPTIONS_NOT_ENDED_CLAIMS } from "@/common/constants/Accounting";
import SelectInput from "@/common/components/SelectInput";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findDefaultMarketFromOptions } from "@/common/functions/findDefaultMarketFromOptions";
import { findPossibleProductFieldByMarket } from "@/common/functions/findPossibleProductFieldByMarket";
import { filterEntitiesByIds } from "@/common/functions/filterEntitiesByIds";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { filterProdluctIdsByCompanyId } from "@/common/functions/filterProductIdsByCompanyId";
import { getCompanies } from "@/API/repositories/company";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const SearchBarNotEndedClaims = ({
  selectedProduct,
  setSelectedProduct,
  selectedStatus,
  setSelectedStatus,
  handleSearch,
}) => {
  const [selectedMarket, setSelectedMarket] = useState();
  const [companies, setCompanies] = useState();
  const [companiesOptions, setCompaniesOptions] = useState();
  const [productsOptions, setProductsOptions] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const {
    commonData: { products },
    options: { marketsOptions, queuesOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setCompanies(() => response.data);
  };

  const handleSetMarket = (market) => {
    const currentMarket =
      market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

    setSelectedMarket(currentMarket);
    handleMarketChange(currentMarket);
  };

  const handleMarketChange = (market) => {
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (market && companies) {
      const possibleCompanies = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products,
        "company"
      );

      const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

      const filteredCompanies = filterEntitiesByIds(
        companies,
        uniqueCompaniesIds
      );

      const newCompaniesOptions = handleMapToOptions(
        filteredCompanies,
        "name",
        "_id"
      );

      setCompaniesOptions(newCompaniesOptions);
      const company = newCompaniesOptions[0];

      handleSetCompany(company, market);
    }
  };

  const handleSetCompany = (company, market = selectedMarket) => {
    setSelectedCompany(company);
    handleCompanyChange(company, market);
  };

  const handleCompanyChange = (company, market) => {
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (company) {
      const possibleProductsIds = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products
      );

      const productIdsFilteredByCompanies = filterProdluctIdsByCompanyId(
        products,
        possibleProductsIds,
        company.value
      );

      const filteredProducts = filterEntitiesByIds(
        products,
        productIdsFilteredByCompanies
      );

      const productsOptions = handleMapToOptions(
        filteredProducts,
        "name",
        "_id"
      );

      setProductsOptions(() => productsOptions);
      setSelectedProduct(() => productsOptions[0]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    handleSetMarket();
  }, [queuesOptions, marketsOptions, products, companies]);

  return (
    <SearchBar>
      {!marketsOptions?.length && <Loading />}
      <div style={{ display: "flex" }}>
        <SelectInput
          color={Colors.darkBlue}
          options={marketsOptions}
          selected={selectedMarket}
          setSelected={handleSetMarket}
          name="Market"
          width={80}
          required
          hideStar
        />
        <SelectInput
          width={80}
          name="Company"
          options={companiesOptions}
          setSelected={handleSetCompany}
          selected={selectedCompany}
          color={Colors.darkBlue}
          disabled={!companiesOptions}
          required
          hideStar
        />
      </div>
      <div style={{ display: "flex" }}>
        <SelectInput
          name="Product"
          width={80}
          color={Colors.darkBlue}
          selected={selectedProduct}
          setSelected={setSelectedProduct}
          options={productsOptions}
          disabled={!productsOptions}
          required
          hideStar
        />
        <SelectInput
          color={Colors.darkBlue}
          name="Status"
          width={80}
          selected={selectedStatus}
          setSelected={setSelectedStatus}
          options={CLAIM_STATUS_OPTIONS_NOT_ENDED_CLAIMS}
        />
      </div>
      <ButtonWrapper>
        <ActionButton
          defaultText={"Search"}
          onClick={(e) => handleSearch(e)}
        />
      </ButtonWrapper>
    </SearchBar>
  );
};

export default SearchBarNotEndedClaims;
