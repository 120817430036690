import { Colors } from '@/common/colors/colors'
import PopUp from '@/common/components/PopUp'
import React from 'react'
import { AddressCompareWrapper, AddressPart, ButtonsWrapper, InfoTitle, Title } from './AreYouSure.styled'
import ActionButton from '@/common/components/buttons/ActionButton'

const AreYouSure = ({ setShow, proceed, originalAddress, yourAddressParts }) => {
	return (
		<PopUp padding='10px 24px' setShow={setShow} >
			<Title>Address data may be not valid. Are you sure that you want to create invoice?</Title>
			<AddressCompareWrapper>
				<p>
					<InfoTitle>Original address:</InfoTitle> {originalAddress}
				</p>
				<p>
					<InfoTitle>Your address: </InfoTitle>
					{yourAddressParts.map((part) =>
						<AddressPart
							underline={!originalAddress.includes(part)}
							fontWeight={originalAddress.includes(part) ? "" : "bold"}
							color={originalAddress.includes(part) ? "" : Colors.red}
							key={part}
						>
							{part}
						</AddressPart>)
					}
				</p>
			</AddressCompareWrapper>
			<ButtonsWrapper>
				<ActionButton
					onClick={() => setShow(false)}
					color={Colors.red}
					defaultText="No"
				/>
				<ActionButton
					onClick={(e) => proceed(e, true)}
					color={Colors.green}
					defaultText="Yes"
				/>
			</ButtonsWrapper>
		</PopUp>
	)
}

export default AreYouSure
