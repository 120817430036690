import { formatToDateTamplate } from "@/common/functions/dateFormater";

const REFUNDED = "REFUNDED";

export const getOperatorPaymentRows = (data) => {
  const result = [];
  const summary = {
    COMPLETED: { count: 0, totalAmount: 0, convertedAmount: 0 },
    REFUNDED: { count: 0, totalAmount: 0, convertedAmount: 0 },
    FEE: 0,
  };

  data.forEach((d) => {
    const paymentType = d.payment.type;
    const isRefund = paymentType === "REFUNDED";
    const price = d.payment.price;
    const convertedAmount = isRefund
      ? d.payment.payment_details.refunded_converted_amount / 100
      : d.payment.payment_details.converted_amount / 100;
    const fee = isRefund ? 0 : (d.payment.payment_details.fee || 0) / 100;

    if (paymentType === "COMPLETED") {
      summary.COMPLETED.count++;
      summary.COMPLETED.totalAmount += price;
      summary.COMPLETED.convertedAmount += convertedAmount;
      summary.FEE += fee;
    } else if (paymentType === "REFUNDED") {
      summary.REFUNDED.count++;
      summary.REFUNDED.totalAmount += price;
      summary.REFUNDED.convertedAmount += convertedAmount;
    }
  });

  data.forEach((d, i) => {
    const isRefund = d.payment.type === REFUNDED ? true : false;

    const date = !isRefund
      ? formatToDateTamplate(d.payment.status_complated_date)
      : formatToDateTamplate(d.payment.status_refunded_date);

    const price = !isRefund ? d.payment.price : d.payment.price * -1;

    const exchangeRate = !isRefund
      ? d.payment.payment_details.exchange_rate
      : d.payment.payment_details.refunded_exchange_rate;

    const convertedAmount = !isRefund
      ? d.payment.payment_details.converted_amount / 100
      : d.payment.payment_details.refunded_converted_amount / 100;

    const fee = !isRefund ? d.payment.payment_details.fee / 100 || 0 : 0;

    const currentRow = (
      <tr>
        <td>{i + 1}.</td>
        <td>{d.contact.email}</td>
        <td>{date}</td>
        <td>{price}</td>
        <td>{d.currency}</td>
        <td>{exchangeRate}</td>
        <td>{convertedAmount}</td>
        <td>{d.payment.payment_details.converted_currency}</td>
        <td>{d.payment.type}</td>
        <td>{fee}</td>
      </tr>
    );

    result.push(currentRow);
  });

  result.push(
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  result.push(
    <tr>
      <td></td>
      <td colSpan={4}>Sumarize</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  result.push(
    <tr>
      <td></td>
      <td></td>
      <td>Liczba operacji</td>
      <td>Amount ({data[0]?.currency?.toUpperCase()})</td>
      <td>Converted Amount / PLN</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  result.push(
    <tr>
      <td></td>
      <td>COMPLETED</td>
      <td>{summary.COMPLETED.count}</td>
      <td>{summary.COMPLETED.totalAmount.toFixed(2)}</td>
      <td>{summary.COMPLETED.convertedAmount.toFixed(2)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  result.push(
    <tr>
      <td></td>
      <td>REFUNDED</td>
      <td>{summary.REFUNDED.count}</td>
      <td>{summary.REFUNDED.totalAmount.toFixed(2)}</td>
      <td>{summary.REFUNDED.convertedAmount.toFixed(2)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  result.push(
    <tr>
      <td></td>
      <td>FEE</td>
      <td>-----</td>
      <td>-----</td>
      <td>{summary.FEE.toFixed(2)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  return result;
};
