import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import { useFormik } from 'formik'
import React from 'react'
import { ButtonWrapper } from '../../ClaimTag.styled'
import { useRequestsContext } from '@/common/hooks/requestHook'
import Loading from '@/common/components/Loading'
import { createClaimTag, updateClaimTag } from '@/API/repositories/claim-tag'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import SubmitButton from '@/common/components/buttons/SubmitButton'

const ClaimTagForm = ({ setShow, selectedTag, reload = () => {} }) => {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const formik = useFormik({
    initialValues: {
      label: selectedTag?.label || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    let label = values.label;

    if (!label.startsWith("#")) {
      label = `#${label}`;
    }

    const payload = {
      label,
    };

    const requestFn = selectedTag ? updateClaimTag : createClaimTag;

    const response = await makeRequest(requestFn.bind(null, payload, selectedTag?._id));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    addMessage("Success", "success");
    setShow(false);
    reload();
  };

  return (
    <PopUp padding='10px 20px' setShow={setShow}>
      {hasUnfilledRequest(createClaimTag, updateClaimTag) && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <Input
          id={"label"}
          onChange={formik.handleChange}
          name="Label"
          value={formik.values.label}
          required
          color={Colors.darkBlue}
          width={80}
        />
        <ButtonWrapper>
          <SubmitButton text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  )
}

export default ClaimTagForm
