import React from "react";
import { AutoInputsWrapper, InputWrapper } from "../../../InvoiceTicket.styled";
import AdditionalErrorMessage from "../additionalErrorMessage/AdditionalErrorMessage";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import { Textarea } from "../../../components/addComment/AddComment.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const AutoFilledInputs = ({
  additionalErrorMessage,
  companyNameRef,
  addressRef,
  commentRef,
  cityRef,
  postalCodeRef,
  streetRef,
}) => {
  const { translation } = useTranslationContext();

  return (
    <AutoInputsWrapper>
      {additionalErrorMessage && (
        <AdditionalErrorMessage
          additionalErrorMessage={additionalErrorMessage}
        />
      )}
      <InputWrapper
        visibility={companyNameRef.current?.value ? "visible" : "hidden"}
      >
        <Input
          inputRef={companyNameRef}
          width={190}
          inputWidth={700}
          name={translation.company_name}
          color={Colors.darkBlue}
          requiredSign
          required
          disabled
        />
      </InputWrapper>
      <InputWrapper
        visibility={addressRef.current?.value ? "visible" : "hidden"}
      >
        <Input
          inputRef={addressRef}
          width={190}
          inputWidth={700}
          name={translation.address}
          color={Colors.darkBlue}
          requiredSign
          required
          disabled
        />
        <Input
          inputRef={streetRef}
          width={190}
          inputWidth={700}
          name={translation.company_street_and_number}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={postalCodeRef}
          width={190}
          inputWidth={700}
          name={translation.company_postal_code}
          color={Colors.darkBlue}
          requiredSign
          required
        />
        <Input
          inputRef={cityRef}
          width={190}
          inputWidth={700}
          name={translation.company_city}
          color={Colors.darkBlue}
          requiredSign
          required
        />
      </InputWrapper>
      <Textarea
        visibility={
          addressRef.current?.value && companyNameRef.current?.value
            ? "visible"
            : "hidden"
        }
        placeholder={translation.add_comment}
        rows={8}
        cols={105}
        ref={commentRef}
      />
    </AutoInputsWrapper>
  );
};

export default AutoFilledInputs;
