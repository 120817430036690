import Input from "@/common/components/Input";
import Loading from "@/common/components/Loading";
import SelectInput from "@/common/components/SelectInput";
import { SearchBarRaw } from "@/common/styles/SearchBars";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import React, { useMemo, useRef, useState } from "react";
import { ButtonWrapper } from "./OrderLangStatsReport.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getLanguageMonthStats, getLangugeStats } from "@/API/repositories/reports";
import CSV from "@/common/components/CSV";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import moment from "moment";
import OrderLangStatsTable from "./components/orderLangStatsTable/OrderLangStatsTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const TIME_PERIODS = [
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
];

const OrderLangStatsReport = () => {
  const [selectedProducts, setSelectedProducts] = useState();
  const [langStats, setLangStats] = useState();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(TIME_PERIODS[0]);
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [selectedLanguages, setSelectedLanguages] = useState();
  const [visibleLangStats, setVisibleLangStats] = useState(false);

  const fromRef = useRef();
  const toRef = useRef();

  const {
    commonData: { products },
    options: { productsOptions },
  } = useCommonDataContext();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const languageOptions = useMemo(() => {
    const languages = new Set();

    products?.forEach((product) => {
      product.variants?.forEach((variant) => {
        const lang = variant?.short.split('-')[1];
        languages.add(lang);
      });
    });

    return Array.from(languages).map((lang) => ({ value: lang, label: lang }));
  }, [products])

  const handleSearch = async () => {
    setLangStats(null);
    setVisibleLangStats(null);

    const payload = {};

    if (selectedTimePeriod.value === "day") {
      const from = fromRef.current?.value;
      const to = toRef.current?.value;
  
      if (!selectedProducts?.length || !from || !to) {
        return addMessage("Select all data", "error");
      }

      payload.from = from;
      payload.to = to;
    }

    if (selectedTimePeriod.value === "month") {
      if (!selectedProducts?.length || !selectedFrom || !selectedTo) {
        return addMessage("Select all data", "error");
      }

      payload.from = moment(selectedFrom.value).format('YYYY-MM-DD');
      payload.to = moment(selectedTo.value).format('YYYY-MM-DD');
    }

    const queryFn = selectedTimePeriod.value === "day" ? getLangugeStats : getLanguageMonthStats;

    payload.products = selectedProducts.map((c) => c.value);

    const response = await makeRequest(queryFn.bind(null, payload));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    if (!response?.data?.length) {
      return addMessage("Not found");
    }

    setLangStats(() => response.data);
    handleSelectLanguage(selectedLanguages, response.data);

    return;
  };

  const handleSelectLanguage = (value, data = langStats) => {
    setSelectedLanguages(value);
    setVisibleLangStats(data?.filter((lang, i) => {
      if (!i) return true;

      return value.some((v) => lang[0] === v.value);
    }));
  }

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getLangugeStats, getLanguageMonthStats) && <Loading />}
      <SearchBarRaw>
        <div>
          <div>
            <SelectInput
              name="Time period"
              color={Colors.darkBlue}
              width={110}
              selectWidth={170}
              options={TIME_PERIODS}
              selected={selectedTimePeriod}
              setSelected={setSelectedTimePeriod}
            />
         </div>
         {selectedTimePeriod?.value === "day" && (
           <>
            <Input
              inputRef={fromRef}
              name="From"
              type="date"
              color={Colors.darkBlue}
              width={110}
              inputWidth={170}
            />
            <Input
              inputRef={toRef}
              name="To"
              type="date"
              color={Colors.darkBlue}
              width={110}
              inputWidth={170}
            />
           </>
         )}
         {selectedTimePeriod?.value === "month" && (
           <>
            <SelectInput
              name="From"
              color={Colors.darkBlue}
              width={110}
              options={LAST_YEAR_MONTHS}
              selectWidth={170}
              setSelected={setSelectedFrom}
              selected={selectedFrom}
            />
            <SelectInput
              name="To"
              color={Colors.darkBlue}
              width={110}
              selectWidth={170}
              options={LAST_YEAR_MONTHS}
              setSelected={setSelectedTo}
              selected={selectedTo}
            />
           </>
         )}
        </div>
        <div>
          <SelectInput
            name="Products"
            options={productsOptions}
            color={Colors.darkBlue}
            width={80}
            setSelected={setSelectedProducts}
            selected={selectedProducts}
            multiple
            selectWidth={300}
          />
          <SelectInput
            name="Language"
            options={languageOptions}
            color={Colors.darkBlue}
            width={80}
            selectWidth={300}
            multiple
            selected={selectedLanguages}
            setSelected={handleSelectLanguage}
          />
        </div>
        <ButtonWrapper>
          <ActionButton
            onClick={() => handleSearch()}
            defaultText="Search"
          />
          <ActionButton
            defaultText="Select all products"
            onClick={() => setSelectedProducts(productsOptions)}
          />
            <ActionButton
            defaultText="Select all languages"
            onClick={() => handleSelectLanguage(languageOptions)}
          />
          {visibleLangStats && (
            <CSV
              filename={`lang_stats.csv`}
              header={visibleLangStats[0]}
              data={visibleLangStats.slice(1)}
            />
          )}
        </ButtonWrapper>
      </SearchBarRaw>
      {visibleLangStats && <OrderLangStatsTable langStats={visibleLangStats} />}
    </WrapperWithScroll>
  );
};

export default OrderLangStatsReport;
