import dashboard from "../../images/icons/dashboard.png";
import report from "../../images/icons/reports.png";
import TeamLeaderPanel from "../components/TeamLeaderPanel";

const adminNavigationConfig = [
  {
    name: "Dashboard",
    link: "/dashboard",
    path: dashboard,
    access: "DASHBOARD",
  },
  {
    name: "Contacts",
    link: "/dashboard/contacts",
    icon: "fa fa-address-book",
    access: "CONTACT",
  },
  {
    name: "Orders",
    link: "/dashboard/orders",
    icon: "fa fa-address-card",
    access: "ORDER",
  },
  {
    name: "Tickets",
    link: "/dashboard/tickets",
    icon: "fa fa-clipboard-list",
    access: "TICKETS",
  },

  {
    name: "Reports",
    icon: "fa fa-chart-simple",
    access: "REPORTS",
    list: [
      {
        name: "Sent Orders",
        link: "/report/sent-orders",
        icon: "fa fa-paper-plane",
        access: "REPORTS_SENT_ORDERS",
      },
      {
        name: "UTM",
        link: "/report/utm",
        icon: "fa fa-bullhorn",
        access: "REPORTS_UTM",
      },
      {
        name: "Not pick up calls",
        link: "/report/not-pick-up-calls",
        icon: "fa fa-phone",
        access: "REPORTS_NOT_PICK_UP",
      },
      {
        name: "Daily",
        link: "/report/daily",
        icon: "fa fa-users",
        access: "REPORTS_DAILY",
      },
      {
        name: "Daily Market",
        link: "/report/daily-market",
        icon: "fa fa-magnifying-glass-chart",
        access: "REPORTS_DAILY_MARKET",
      },
      {
        name: "Effectivity",
        link: "/report/effective",
        icon: "fa fa-chart-simple",
        access: "REPORTS_EFFECTIVE",
      },
      {
        name: "Payment",
        link: "/report/payment",
        icon: "fa fa-money",
        access: "REPORTS_EFFECTIVE_SALARY",
      },
      {
        name: "Grade",
        link: "/report/grade",
        icon: "fa fa-chart-simple",
        access: "REPORTS_GRADE",
      },

      {
        name: "Contacts road",
        link: "/report/contacts-road",
        icon: "fa fa-road",
        access: "REPORTS_CONTACTS_ROAD",
      },
      {
        name: "Call history",
        link: "/report/consultant",
        icon: "fa fa-user fa-solid",
        access: "REPORTS_CALLING_HISTORY",
      },
      {
        name: "Cost per lead (Netto)",
        link: "/report/cost-per-lead",
        icon: "fa fa-money",
        access: "REPORTS_CPL",
      },

      {
        name: "Lang Pair Claim",
        link: "/report/lang-pair-claim",
        icon: "fa fa-road",
        access: "REPORTS_LANG_PAIR",
      },
      {
        name: "Lost packages",
        link: "/report/lost-packages",
        icon: "fa fa-road",
        access: "REPORTS_LOST_PACAKAGE",
      },
      {
        name: "Suspicions",
        link: "/report/suspicion",
        icon: "fa fa-circle-exclamation",
        access: "REPORTS_SUSPICIONS",
      },
      {
        name: "Product CPL(Netto)",
        link: "/report/product-cpl",
        icon: "fa fa-money",
        access: "REPORTS_PRODUCT_CPL",
      },
      {
        name: "Payment Product CPL(Netto)",
        link: "/report/payment-product-cpl",
        icon: "fa fa-money",
        access: "REPORTS_PRODUCT_CPL",
      },
      {
        name: "Resign",
        link: "/report/resign",
        icon: "fa fa-ban",
        access: "REPORTS_RESIGN",
      },
      {
        name: "Resign new",
        link: "/report/resign-new",
        icon: "fa fa-ban",
        access: "REPORTS_RESIGN",
      },
      {
        name: "Order lang stats",
        link: "/report/order-langs-stats",
        icon: "fa fa-language",
        access: "REPORTS_ORDER_LANGS_STATS",
      },
    ],
  },

  {
    name: "Mail",
    icon: "fas fa-envelope",
    access: "MAIL",
    list: [
      {
        name: "Search",
        link: "/mail/search",
        icon: "fa fa-envelope",
        access: "MAIL_SEARCH",
      },
      {
        name: "Template",
        link: "/mail/template",
        icon: "far fa-file",
        access: "MAIL_TEMPLATE_VIEW",
      },
      {
        name: "Test",
        link: "/mail/test",
        icon: "far fa-file",
        access: "MAIL_TEST_VIEW",
      },
      {
        name: "Category",
        link: "/mail/category",
        icon: "fa fa-list",
        access: "MAIL_CATEGORY_VIEW",
      },
      {
        name: "Recruitment category",
        link: "/mail/recruitment-mail",
        icon: "fa fa-list",
        access: "MAIL_RECRUITMENT_CATEGORY",
      },
    ],
  },
  {
    name: "Accounting",
    path: report,
    access: "ACCOUNTING",
    list: [
      {
        name: "Claim Search",
        link: "/hr/claim/search",
        icon: "fa fa-file-circle-exclamation",
        access: "ACCOUNTING_CLAIMS_SEARCH",
      },
      {
        name: "Claims",
        link: "/hr/claim/not_ended_claims",
        icon: "fa fa-file-circle-exclamation",
        access: "ACCOUNTING_CLAIMS",
      },
      {
        name: "Claims Report",
        link: "/hr/claims-report",
        icon: "fa fa-file-circle-exclamation",
        access: "ACCOUNTING_CLAIMS_REPORT",
      },
      {
        name: "Invoices",
        link: "/hr/invoices",
        icon: "fa fa-file-invoice",
        access: "ACCOUNTING_INVOICES",
      },
      {
        name: "Invoices Report",
        link: "/hr/invoices-report",
        icon: "fa fa-file-invoice-dollar",
        access: "ACCOUNTING_INVOICES_REPORT",
      },
      {
        name: "Valid invoices",
        link: "/valid-invoices",
        icon: "fa fa-file-invoice",
        access: "VALID_INVOICES_GET",
      },
      {
        name: "Valid Invoices Report",
        link: "/valid-invoices-report",
        icon: "fa fa-file-invoice-dollar",
        access: "ACCOUNTING_INVOICES",
      },
      {
        name: "Valid Claims",
        link: "/valid-claims",
        icon: "fa fa-receipt",
        access: "VALID_CLAIMS_GET",
      },
      {
        name: "Valid claims feedback",
        link: "/report/valid-claims-feedback-report",
        icon: "fa fa-comment",
        access: "REPORTS_VALID_CLAIMS_FEEDBACK",
      },
      {
        name: "Valid Claims Report",
        link: "/report/valid-claims-report",
        icon: "fa fa-file-invoice",
        access: "REPORTS_VALID_CLAIMS",
      },
      {
        name: "Payment Report",
        link: "/report/operator-payment-report",
        icon: "fa fa-file-invoice",
        access: "OPERATOR_PAYMENT_REPORT",
      },
    ],
  },
  {
    name: "Voip",
    icon: "fa fa-phone",
    access: "VOIP",
    list: [
      {
        name: "SMS Search",
        link: "/voip/sms/search",
        icon: "fa fa-sms",
        access: "VOIP_SMS_SEARCH",
      },
      {
        name: "SMS Create",
        link: "/voip/sms/create",
        icon: "fa fa-sms",
        access: "VOIP_SMS_CREATE",
      },
      {
        name: "Grading",
        link: "/voip/listening-marks",
        icon: "fa fa-ear-listen",
        access: "VOIP_MARKS",
      },
      {
        name: "Scoring listening",
        link: "/voip/listening-scoring",
        icon: "fa fa-headphones",
        access: "VOIP_SCORING",
      },
      {
        name: "Calling Search",
        link: "/voip/calling/search",
        icon: "fa fa-phone",
        access: "VOIP_CALLING_SEARCH",
      },
      {
        name: "Realtime",
        link: "/voip/realtime-dashboard",
        icon: "fa-solid fa-home",
        access: "VOIP_REALTIME",
      },
      {
        name: "Working",
        link: "/voip/working-time",
        icon: "fa-solid fa-clock",
        access: "VOIP_WORKING_TIME",
      },
      {
        name: "Statistics",
        link: "/voip/statistics",
        icon: "fa-solid fa-chart-bar",
        access: "VOIP_STATISTICS",
      },
      {
        name: "Listening",
        link: "/voip/listening",
        icon: "fa-solid fa-ear-listen",
        access: "VOIP_LISTENING",
      },
      {
        name: "Scoring",
        link: "/voip/listening-scores",
        icon: "fa-solid fa-star",
        access: "VOIP_SCORING",
      },
      {
        name: "Archive",
        link: "/voip/archive",
        icon: "fa-solid fa-box-archive",
        access: "VOIP_ARCHIVE",
      },
    ],
  },
  {
    name: "CC",
    icon: "fa fa-headphones",
    access: "CC",
    list: [
      {
        name: "Hours",
        link: "/cc/hours",
        icon: "fa fa-clock-o",
        access: "CC_HOURS",
      },
      {
        name: "Hours report",
        link: "/cc/hours_report",
        icon: "fa fa-hourglass-end",
        access: "CC_HOURS_REPORT",
      },
      {
        name: "Payment",
        link: "/cc/payment",
        icon: "fa fa-money",
        access: "CC_PAYMENT",
      },
      {
        name: "Payment KDR",
        link: "/cc/payment_kdr",
        icon: "fa fa-money",
        access: "CC_PAYMENT_KDR",
      },
      {
        name: "Payment short",
        link: "/cc/payment-short",
        icon: "fa fa-money",
        access: "CC_PAYMENT",
      },
      {
        name: "Gamification",
        link: "/cc/gamification",
        icon: "fa fa-play",
        access: "CC_GAMIFICATION",
      },
      {
        name: "Gamification new",
        link: "/cc/gamification/new",
        icon: "fa fa-star",
        access: "CC_GAMIFICATION",
      },
      {
        name: "Payout slip",
        link: "/cc/payout-slip",
        icon: "fa-solid fa-cash-register",
        access: "CC_PAYMENT",
      },
      {
        name: "Speech to text",
        link: "/cc/speech-to-text",
        icon: "fa fa-comment",
        access: "CC_SPEECH_TO_TEXT",
      },
      {
        name: "Recruitment",
        link: "/cc/recruitment",
        icon: "fa fa-users",
        access: "CC_RECRUITMENT",
      },
    ],
  },
  {
    name: "CRM settings",
    icon: "fa fa-gear",
    access: "CRM_SETTINGS",
    list: [
      {
        name: "Markets",
        link: "/settings/market/list",
        icon: "fa fa-location-dot",
        access: "CRM_SETTINGS_MARKETS",
      },
      {
        name: "Users",
        link: "/settings/user/list",
        icon: "fa fa-location-dot",
        access: "CRM_SETTINGS_USERS",
      },
      {
        name: "Queues",
        link: "/settings/queue/list",
        icon: "fa fa-chess-queen",
        access: "CRM_SETTINGS_QUEUES",
      },
      {
        name: "Products",
        link: "/settings/product/list",
        icon: "fa fa-product-hunt",
        access: "CRM_SETTINGS_PRODUCTS",
      },
      {
        name: "Dictionary",
        link: "/settings/dictionary",
        icon: "fa fa-spell-check",
        access: "CRM_SETTINGS_DICTIONARY",
      },
      {
        name: "Blackboard",
        link: "/settings/blackboard/list",
        icon: "fa-solid fa-chalkboard",
        access: "CRM_SETTINGS_BLACKBOARD",
      },
      {
        name: "FAQ",
        link: "/settings/faq/list",
        icon: "fa-solid fa-question",
        access: "CRM_SETTINGS_FAQ",
      },
      {
        name: "Drafts",
        link: "/settings/drafts/list",
        icon: "fa-solid fa-file",
        access: "CRM_SETTINGS_DRAFTS",
      },
      {
        name: "LI Gen",
        link: "/settings/listening-generator",
        icon: "fa-solid fa-ear-listen",
        access: "CRM_SETTINGS_LISTENING_GENERATOR",
      },
      {
        name: "Meta",
        link: "/settings/meta",
        icon: "fa-brands fa-meta",
        access: "CRM_SETTINGS_META",
      },
      {
        name: "Language",
        link: "/settings/languages",
        icon: "fa fa-globe",
        access: "CRM_SETTINGS_LANGUAGE",
      },
      {
        name: "Tests",
        link: "/settings/test/list",
        icon: "fa fa-question-circle",
        access: "CRM_SETTINGS_TEST_GET_ALL",
      },
      {
        name: "Gamification",
        link: "/settings/gamification",
        icon: "fa fa-star",
        access: "CC_GAMIFICATION",
      },
      {
        name: "Invoice Templates",
        link: "/settings/invoice-templates",
        icon: "fa fa-pen-ruler",
        access: "CRM_SETTINGS_INVOICE_TEMPLATE",
      },
      {
        name: "Keys",
        link: "/settings/keys",
        icon: "fa fa-key",
        access: "CRM_SETTINGS_KEYS",
      },
      {
        name: "Companies",
        link: "/settings/companies",
        icon: "fa fa-building",
        access: "CRM_SETTINGS_COMPANY",
      },
      {
        name: "Invoices config",
        link: "/settings/invoice-config",
        icon: "fa fa-font",
        access: "CRM_SETTINGS_INVOICE_CONFIG",
      },
      {
        name: "Server Refresh",
        link: "/settings/server-refresh",
        icon: "fa fa-server",
        access: "CRM_SETTINGS_SERVER_REFRESH",
      },
      {
        name: "Department",
        link: "/settings/department",
        icon: "fa fa-building",
        access: "CRM_SETTINGS_DEPARTMENT",
      },
      {
        name: "Bonus",
        link: "/settings/bonus",
        icon: "fa fa-gift",
        access: "CRM_SETTINGS_BONUS",
      },
      {
        name: "Recruitment page",
        link: "/settings/recruitment-page",
        icon: "fa fa-users",
        access: "CRM_SETTINGS_RECRUITMENT",
      },
      {
        name: "Call center position",
        link: "/settings/call-center-position",
        icon: "fa fa-tag",
        access: "CRM_SETTINGS_POSITION",
      },
      {
        name: "Dynamic mail content",
        link: "/settings/dynamic-mail-content",
        icon: "fa fa-envelope-open",
        access: "CRM_SETTINGS_DYNAMIC_MAIL_CONTENT",
      },
      {
        name: "Dynamic mail attachment",
        link: "/settings/dynamic-mail-attachment",
        icon: "fa fa-envelope-open",
        access: "CRM_SETTINGS_DYNAMIC_MAIL_ATTACHMENT",
      },
      {
        name: "Files",
        link: "/settings/files",
        icon: "fa fa-file",
        access: "CRM_SETTINGS_FILES",
      },
      {
        name: "Promotion",
        link: "/settings/promotion",
        icon: "fa fa-funnel-dollar",
        access: "CRM_SETTINGS_PROMOTION",
      },
      {
        name: "Claim tags",
        link: "/settings/claim-tags",
        icon: "fa fa-tag",
        access: "CRM_SETTINGS_CLAIM_TAGS",
      },
      {
        name: "Mailing Reports",
        link: "/settings/mailing-reports",
        icon: "fa fa-envelope",
        access: "CRM_SETTINGS_MAILING_REPORTS",
      },
      {
        name: "Resign tags",
        link: "/settings/resign-tags",
        icon: "fa fa-tag",
        access: "CRM_SETTINGS_RESIGN_TAGS",
      },
    ],
  },
];

const consultantNavigationConfig = [
  {
    name: "Dashboard",
    link: "/consultant/dashboard",
    icon: "bi bi-grid-3x3-gap",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "My_statistics",
    link: "/consultant/statistics",
    icon: "fa fa-bar-chart",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "My_orders",
    link: "/consultant/orders",
    icon: "	fa fa-address-book",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "My_salary",
    link: "/consultant/salary",
    icon: "	fa fa-dollar",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "Hours",
    link: "/consultant/hours",
    icon: "fa fa-clock-o",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "Tickets",
    link: "/consultant/tickets",
    icon: "fa fa-tasks",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "Listening",
    link: "/consultant/listening",
    icon: "fa fa-ear-listen",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "FAQ",
    link: "/consultant/faq",
    icon: "fa fa-question",
    access: "CONSULTANT_COMMON",
  },
  {
    name: "Drafts",
    link: "/consultant/drafts",
    icon: "fa fa-file",
    access: "CONSULTANT_COMMON",
  },
  // {
  //   name: "Tests",
  //   link: "/consultant/tests",
  //   icon: "fa fa-check",
  //   access: "CONSULTANT_COMMON",
  // },
  // {
  //   name: "Gamification",
  //   link: "/consultant/gamification",
  //   icon: "fas fa-gamepad",
  //   access: "CONSULTANT_COMMON",
  // },
  {
    name: "Your Team",
    icon: "fa fa-users",
    access: "CONSULTANT_MANAGER",
    element: (setShow) => <TeamLeaderPanel setShow={setShow} />,
  },
];

export { adminNavigationConfig, consultantNavigationConfig };
