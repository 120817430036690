import React, { useEffect, useState } from "react";
import { DefaultInputsWrapper, Form, Title } from "../../InvoiceTicket.styled";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import { findMarketById } from "../../helpers/findMarketById";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getCompanyDataByVat } from "@/API/repositories/invoices";
import { INVOICE_CREATION_TYPES } from "../../contstants/invoices";
import { resetWithTimeout } from "../../helpers/resetWithTimeout";
import AutoFilledInputs from "../../common/components/autoFilledInputs/AutoFilledInputs";
import InvoiceButtons from "../../common/components/invoiceButtons/InvoiceButtons";
import { findMarketFromOptions } from "@/pages/admin/sentOrderReport/components/sentOrdersTable/helpers/findMarketFromOptions";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const InvoiceByVat = ({
  vatRef,
  addressRef,
  companyNameRef,
  handleCreateValidInvoiceTicket,
  setInvoiceCreateBy,
  commentRef,
  cityRef,
  postalCodeRef,
  streetRef,
  orderMarket,
}) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [canChangeCreationType, setCanChangeCreationType] = useState();
  const [checkColor, setCheckColor] = useState();
  const [additionalErrorMessage, setAdditionalErrorMessage] = useState();
  const { translation } = useTranslationContext();
  const { addMessage } = useMessageQueueContext();

  const {
    commonData: { markets },
    options: { marketsOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleCountrySelect = (country) => {
    const vat = vatRef.current?.value.replace(/\s/g, "") || "";

    vatRef.current.value = vat;

    const vatValue = parseInt(vat) ? vat : vat.slice(2);

    const vatWithPrefix =
      findMarketById(country.value, markets)?.short.toUpperCase() + vatValue;

    vatRef.current.value = vatWithPrefix;

    setSelectedCountry(() => country);
  };

  const handleFillDataByVat = async () => {
    addressRef.current.value = null;
    companyNameRef.current.value = null;
    setCanChangeCreationType(() => false);
    setAdditionalErrorMessage(() => null);

    const vat = vatRef.current?.value.replace(/\s/g, "");

    if (!vatRef.current?.value) {
      return addMessage("Add vat", "error");
    }

    if (!selectedCountry?.value) {
      return addMessage("Add market", "error");
    }

    vatRef.current.value = vat;

    const marketPrefix = vatRef.current.value.slice(0, 2);
    const number = vatRef.current.value.slice(2);

    if (parseInt(marketPrefix)) {
      return addMessage("Add country prefix", "error");
    }

    if (marketPrefix.toUpperCase() !== marketPrefix) {
      return addMessage("Country prefix must be uppercase", "error");
    }

    if (!parseInt(number)) {
      return addMessage("Add proper vat number", "error");
    }

    const payload = {};

    payload.vat = vat;
    payload.marketId = selectedCountry.value;

    const response = await makeRequest(getCompanyDataByVat.bind(null, payload));

    if (!response.data || response.data.vies[0].valid[0] === "false") {
      setCheckColor(() => Colors.red);
      setAdditionalErrorMessage(() => "NOT VALID VAT ID");
      resetWithTimeout(setCheckColor, Colors.orange);
      resetWithTimeout(setAdditionalErrorMessage);
      setCanChangeCreationType(() => true);
      return addMessage("There is no active company with such vat id", "error");
    }

    companyNameRef.current.value = response.data.vies[0].traderName[0];
    addressRef.current.value =
      response.data.vies[0].traderAddress[0].replaceAll("\n", " ");

    setCheckColor(() => Colors.green);
    resetWithTimeout(setCheckColor, Colors.orange);

    return addMessage("Everything filled in correctly", "success");
  };

  useEffect(() => {
    if (!!marketsOptions?.length) {
      setSelectedCountry(() =>
        findMarketFromOptions(orderMarket._id, marketsOptions)
      );
    }
  }, [marketsOptions]);

  return (
    <>
      <Title>Invoice ticket:</Title>
      <Form onSubmit={(e) => handleCreateValidInvoiceTicket(e)}>
        <DefaultInputsWrapper>
          <Input
            disabled={
              companyNameRef.current?.value && addressRef.current?.value
            }
            inputRef={vatRef}
            width={200}
            inputWidth={200}
            required
            requiredSign
            name={translation.vat_id}
            color={Colors.darkBlue}
            value={findMarketById(
              selectedCountry?.value,
              markets
            )?.short.toUpperCase()}
          />
          <SelectInput
            disabled={
              companyNameRef.current?.value && addressRef.current?.value
            }
            setSelected={handleCountrySelect}
            selected={selectedCountry}
            width={200}
            selectWidth={200}
            required
            requiredSign
            name={translation.company_country}
            color={Colors.darkBlue}
            options={marketsOptions}
          />
        </DefaultInputsWrapper>
        <AutoFilledInputs
          additionalErrorMessage={additionalErrorMessage}
          companyNameRef={companyNameRef}
          addressRef={addressRef}
          commentRef={commentRef}
          streetRef={streetRef}
          cityRef={cityRef}
          postalCodeRef={postalCodeRef}
        />
        <InvoiceButtons
          addressRef={addressRef}
          companyNameRef={companyNameRef}
          canChangeCreationType={canChangeCreationType}
          setInvoiceCreateBy={setInvoiceCreateBy}
          checkColor={checkColor}
          handleCheck={handleFillDataByVat}
          differentCreationType={INVOICE_CREATION_TYPES.COMPANY_ID}
        />
      </Form>
    </>
  );
};

export default InvoiceByVat;
