import { Colors } from "@/common/colors/colors";

export const getColorByPosition = (postion) => {
  if (postion === 1) {
    return Colors.green;
  }
  if (postion === 2) {
    return Colors.yellow;
  }
  if (postion === 3) {
    return Colors.violet;
  }

  return Colors.darkBlue;
};
