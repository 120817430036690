import ActionButton from '@/common/components/buttons/ActionButton'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper } from './CallCenterPosition.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { getCallCenterPositions } from '@/API/repositories/callCenterPosition';
import CallCenterPositionForm from './components/callCenterPositionForm/CallCenterPositionForm';
import CallCenterPositionTable from './components/callCenterPositionTable/CallCenterPositionTable';
import SelectInput from '@/common/components/SelectInput';
import { Colors } from '@/common/colors/colors';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';

const CallCenterPosition = () => {
  const [show, setShow] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();
  const [positions, setPositions] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [visiblePositions, setVisiblePositions] = useState([]);

  const { options: { marketsOptions }} = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadPages = async () => {
    setPositions([]);
    setVisiblePositions([]);
    const response = await makeRequest(getCallCenterPositions);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setPositions(response.data);
    setVisiblePositions(response.data.filter(p => !selectedMarket?.value || p.market === selectedMarket?.value));
  };

  const handleFilterMarkets = (value) => {
    setSelectedMarket(value);
    setVisiblePositions(() => positions.filter(p => !value?.value || p.market === value?.value));
  }

  useEffect(() => {
    loadPages();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getCallCenterPositions) && <Loading />}
      <ContentWrapper>
        <ActionButton 
          defaultText="Add position" 
          onClick={() => {
            setSelectedPosition(null)
            setShow(true)
          }}  
        />
        <SelectInput 
          name="Market" 
          selected={selectedMarket} 
          setSelected={handleFilterMarkets} 
          color={Colors.darkBlue} 
          width={70} 
          options={[{ label: "None", value: null }, ...marketsOptions]} 
        />
      </ContentWrapper>
      {show && 
        <CallCenterPositionForm 
          selectedPosition={selectedPosition} 
          reload={loadPages} 
          setShow={setShow} 
        />}
      {!!positions?.length && (
        <CallCenterPositionTable 
          setSelectedPosition={setSelectedPosition} 
          setShow={setShow} 
          positions={visiblePositions} 
        />
      )}
    </PageWrapper>
  )
}

export default CallCenterPosition
