import Table from '@/common/components/Table'
import React from 'react'
import { HEADERS } from './headers/headers'
import { getRaws } from './helpers/getRaws'

const ContactTable = ({ dataToDisplay, handleShowOrderEdit }) => {
	return (
		<Table 
			className="styled-table" 
			headers={HEADERS}
			raws={getRaws(dataToDisplay, handleShowOrderEdit)} 
		/>
	)
}

export default ContactTable
