import { ACCEPTED_HEADERS, HEADERS } from "../constants/headers";
import { getAcceptedRaws } from "./getAcceptedRaws";
import { getRaws } from "./getRaws";

export const getTableConfig = ({
  tickets,
  setSelectedTicket,
  handleDownloadFile,
  markets,
  isRestricted = false,
}) => ({
  open: {
    headers: HEADERS,
    raws: getRaws(tickets, setSelectedTicket, markets),
  },
  done: {
    headers: ACCEPTED_HEADERS,
    raws: getAcceptedRaws(tickets, handleDownloadFile, markets, isRestricted),
  },
});
