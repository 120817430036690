import { getConsultantsDailyReport } from '@/API/repositories/reports'
import { Colors } from '@/common/colors/colors'
import ActionButton from '@/common/components/buttons/ActionButton'
import Input from '@/common/components/Input'
import Loading from '@/common/components/Loading'
import SelectInput from '@/common/components/SelectInput'
import { CONSULTANT_ROLES } from '@/common/constants/consultantRoles'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { SearchBarCenter } from '@/common/styles/SearchBars'
import moment from 'moment'
import React, { useLayoutEffect, useRef, useState } from 'react'

const SearchBar = ({ setData }) => {
	const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [consultantsFilterOption, setConsultantFilterOption] = useState();

	const { addMessage } = useMessageQueueContext();
	const { hasUnfilledRequest, makeRequest } = useRequestsContext();
	const { commonData, filterUsersByRoles } = useCommonDataContext();
  const { users } = commonData;

  const dateFromRef = useRef();
  const dateToref = useRef();

	const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedConsultant.length) {
      addMessage("Select consultants", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.consultants = selectedConsultant.map(
      (consultant) => consultant.value
    );

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getConsultantsDailyReport.bind(null, payload)
    );

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (
      response.data &&
      response.data.length &&
      !response.data.some((element) => {
        return element._call_history.length || element._inbound_history.length;
      })
    ) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

	const handleGetConsultants = async () => {
    setConsultantFilterOption(() => filterUsersByRoles(CONSULTANT_ROLES));
  };

  useLayoutEffect(() => {
    handleGetConsultants();
  }, [users]);

	return (
		<SearchBarCenter>
			{hasUnfilledRequest(getConsultantsDailyReport) && <Loading />}
			<div>
				<Input
					name="Download from"
					width={140}
					color={Colors.darkBlue}
					inputRef={dateFromRef}
					type="date"
					inputWidth={140}
				/>
				<Input
					name="Download to"
					width={140}
					color={Colors.darkBlue}
					inputRef={dateToref}
					type="date"
					inputWidth={140}
				/>
			</div>
			<SelectInput
				name="Consultants"
				options={consultantsFilterOption}
				color={Colors.darkBlue}
				width={100}
				setSelected={setSelectedConsultant}
				selected={selectedConsultant}
				multiple={true}
			/>
			<ActionButton 
				defaultText="Search" 
				onClick={(e) => hanldeSearch(e)} 
			/>
			<ActionButton
				defaultText="Add All"
				onClick={(e) => setSelectedConsultant(consultantsFilterOption)}
			/>
		</SearchBarCenter>
	)
}

export default SearchBar
