import React, { useRef, useState } from "react";
import PopUp from "../../../common/components/PopUp";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import SelectInput from "../../../common/components/SelectInput";
import {
  CALL_HISTORY_STATUSES,
} from "../../../common/constants/statuses";
import { formatToDateTamplate } from "../../../common/functions/dateFormater";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
  margin-top: 40px;
  gap: 20px;
`;

const Form = styled.form``;

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ListeningGeneratorAddRule = ({
  setAddRole,
  addRole,
  setRules,
  users,
  allUsers,
  setToSave,
}) => {
  const [addStatus, setAddStatus] = useState();
  const [selectedUser, setSelectedUser] = useState(
    allUsers.find((user) => user.value === addRole?.consultant) || null
  );
  const [selectedStatus, setSelectedStatus] = useState();
  const calledFromRef = useRef();
  const calledToRef = useRef();
  const minusDaysRef = useRef();
  const limitRef = useRef();
  const minSecRef = useRef();

  const [statuses, setStatuses] = useState(addRole?.statuses || []);

  const { addMessage } = useMessageQueueContext();

  const handleAddRule = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      addMessage("Add consultant", "error");
      return;
    }
    if (!statuses?.length) {
      addMessage("Add status", "error");
      return;
    }

    const calledFrom = calledFromRef?.current?.value || addRole.from;
    const calledTo = calledToRef?.current?.value || addRole.to;
    const minusDays = minusDaysRef?.current?.value || addRole.minusDays;

    if (!minusDays && !calledTo && !calledFrom) {
      addMessage("One date should be set");
      return;
    }

    const payload = {};

    payload.consultant = selectedUser.value;
    payload.statuses = statuses;
    payload.minusDays = minusDays || null;
    payload.calledFrom = calledFrom || null;
    payload.calledTo = calledTo || null;

    setRules((prev) => [
      ...prev.filter((p) => p.consultant !== payload.consultant),
      payload,
    ]);
    setAddRole(() => null);
    setToSave(() => true);

    e.preventDefault();
  };

  const handleAddStatus = (e) => {
    e.preventDefault();

    const payload = {};

    payload.status = selectedStatus.value;
    payload.min_seconds = minSecRef.current.value;
    payload.limit = limitRef.current.value;

    setStatuses((prev) => [
      ...prev.filter((p) => p.status !== selectedStatus.value),
      payload,
    ]);
    setSelectedStatus(() => null);
    setAddStatus(() => null);
  };

  const handleDelete = (status) => {
    setStatuses((prev) => [...prev.filter((s) => s.status !== status)]);
  };

  const handleClear = (e) => {
    setStatuses(() => null);
    setAddRole(e);
  };

  return (
    <>
      <PopUp setShow={handleClear}>
        <Title>Rule</Title>
        <Form onSubmit={handleAddRule}>
          <SelectInput
            width={120}
            selectWidth={220}
            name="Consultant"
            options={users}
            selected={selectedUser}
            setSelected={setSelectedUser}
            color={Colors.darkBlue}
          />
          <Input
            inputRef={calledFromRef}
            type="date"
            name="Called from"
            color={Colors.darkBlue}
            width={120}
            inputWidth={220}
            value={
              addRole.calledFrom && formatToDateTamplate(addRole.calledFrom)
            }
          />
          <Input
            inputRef={calledToRef}
            type="date"
            name="Called to"
            color={Colors.darkBlue}
            width={120}
            inputWidth={220}
            value={addRole.calledTo && formatToDateTamplate(addRole.calledTo)}
          />
          <Input
            inputRef={minusDaysRef}
            name="Minus days"
            color={Colors.darkBlue}
            width={120}
            inputWidth={220}
            value={addRole.minusDays}
            type="number"
            placeholder="type number..."
          />
          <TableWrapper>
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Min sec</th>
                  <th>Limit</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="queue">
                {statuses[0] ? (
                  statuses.map((element) => (
                    <tr key={element.status}>
                      <td>{element.status}</td>
                      <td>{element.min_seconds}</td>
                      <td>{element.limit}</td>
                      <td>
                        <i
                          className="fa fa-remove animation-scale"
                          style={{ cursor: "pointer", color: Colors.red }}
                          onClick={() => handleDelete(element.status)}
                        ></i>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>There is no data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableWrapper>
          <ButtonWrapper>
            <ActionButton
              onClick={() => setAddStatus({})}
              defaultText="Add status"
            />
            <SubmitButton text="Save role" />
          </ButtonWrapper>
        </Form>
      </PopUp>
      {addStatus && (
        <PopUp setShow={setAddStatus}>
          <Title>Add status:</Title>
          <form onSubmit={(e) => handleAddStatus(e)}>
            <SelectInput
              width={120}
              selectWidth={220}
              name="Statuses"
              options={CALL_HISTORY_STATUSES}
              selected={selectedStatus}
              setSelected={setSelectedStatus}
              color={Colors.darkBlue}
            />
            <Input
              inputRef={minSecRef}
              type="number"
              placeholder="type seconds..."
              name="Min seconds"
              color={Colors.darkBlue}
              width={120}
              inputWidth={220}
              required
            />
            <Input
              inputRef={limitRef}
              type="number"
              placeholder="type number..."
              name="Limit"
              color={Colors.darkBlue}
              width={120}
              inputWidth={220}
              required
            />
            <ButtonWrapper>
              <SubmitButton text="Save" />
            </ButtonWrapper>
          </form>
        </PopUp>
      )}
    </>
  );
};

export default ListeningGeneratorAddRule;
