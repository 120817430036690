export const MONEY_TRANSFER = {
  'CZ': "Refundacia - jazykovy kurz",
  "SK": "Refundacia - jazykovy kurz",
  "RO": "Rambursare - curs de limba",
  "HU": "visszatérítés - nyelvtanfolyam",
  "HR": "naknada troškova - tečaj jezika",
  "LT": "kalbos kursai",
  "LV": "valodu kursi - atmaksa"
}

export const ING_CURRENCIES = ['eur'];
