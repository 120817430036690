import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../../common/colors/colors";
import EditItem from "../../../../../common/components/EditItem";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const Wrapepr = styled.div`
  overflow-y: auto;
  max-height: 60vh;
  display: flex;
  justify-content: center;
`;

const OrdersTable = ({ data }) => {
  const { translation } = useTranslationContext();

  return (
    <Wrapepr>
      <table className="styled-table">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>{translation["status"]}</th>
            <th style={{ textAlign: "center" }}>{translation["phone"]}</th>
            <th style={{ textAlign: "center" }}>{translation["email"]}</th>
            <th style={{ textAlign: "center" }}>{translation["fullName"]}</th>
            <th style={{ textAlign: "center" }}>{translation["street"]}</th>
            <th style={{ textAlign: "center" }}>{translation["city"]}</th>
            <th style={{ textAlign: "center" }}>{translation["postalCode"]}</th>
            <th style={{ textAlign: "center" }}>{translation["value"]}</th>
            <th style={{ textAlign: "center" }}>{translation["actions"]}</th>
          </tr>
        </thead>
        <tbody className="queue">
          {data.map((filtred) => (
            <tr style={{ borderTop: `1px solid lightgrey` }}>
              <td
                style={{
                  fontWeight: "bold",
                  width: "200px",
                  textAlign: "center",
                  color:
                    filtred.status === "confirmed"
                      ? Colors.green
                      : filtred.status === "resigned"
                        ? Colors.red
                        : Colors.yellow,
                }}
              >
                {filtred.status ? filtred.status : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {filtred._contact.phone_number
                  ? filtred._contact.phone_number
                  : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {filtred._contact.email ? filtred._contact.email : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {filtred._contact.full_name
                  ? filtred._contact.full_name
                  : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {" "}
                {filtred._contact.street ? filtred._contact.street : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {" "}
                {filtred._contact.city ? filtred._contact.city : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {" "}
                {filtred._contact.postal_code
                  ? filtred._contact.postal_code
                  : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                {" "}
                {filtred._order?.order_items?.length > 0
                  ? filtred._order?.order_items.reduce(
                    (last, { price }) => last + price,
                    0
                  )
                  : "-------"}
              </td>
              <td style={{ width: "220px", textAlign: "center" }}>
                <EditItem
                  width={60}
                  link={`/consultant/contact/${filtred._contact._id}`}
                />{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapepr>
  );
};

export default OrdersTable;
