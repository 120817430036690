import { formatDate } from "@/common/functions/dateFormater";
import moment from "moment";
import { ADD_DAYS } from "../constants";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { checkDisabled } from "./checkDisabled";

const findPayment = (payments) => {
  if (!payments?.length) {
    return;
  }

  const completed = payments.find((payment) => payment.status === "COMPLETED");

  if (!completed) {
    return payments.at(-1);
  }

  return completed
}

export const getRaws = (orders, handleShowSearchedOrders, handleSendRefund, setInvoice) =>
  orders.map((order) => {
    const payment = findPayment(order.payment);
    return (
      <tr key={order._id}>
        <td>{payment?.paymentId}</td>
        <td>{payment?.status}</td>
        <td>{order.status}</td>
        <td>
          {order.order_items.map((item) => item.product_variant).join(", ")}
        </td>
        <td>
          {payment?.status_complated_date
            ? formatDate(
              payment?.status_complated_date,
              "YYYY-MM-DD HH:mm"
            )
            : "-----"}
        </td>
        <td>
          {payment?.status_complated_date
            ? formatDate(
              moment(payment?.status_complated_date)
                .add(ADD_DAYS, "days")
                .toDate(),
              "YYYY-MM-DD HH:mm"
            )
            : "-----"}
        </td>
        <td>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
              margin: "10px",
              minWidth: "300px",
            }}
          >
            <ActionButton
              style={{ fontSize: "12px" }}
              color={Colors.blue}
              onClick={(e) => handleShowSearchedOrders(e, order._id)}
              defaultText="Show Order"
            />

            <ActionButton
              disabled={checkDisabled(payment)}
              style={{ fontSize: "12px" }}
              onClick={(e) => handleSendRefund(order._id)}
              defaultText="Refund"
              color={Colors.red}
            />
            <ActionButton
              style={{ fontSize: "12px" }}
              onClick={(e) => {
                setInvoice(order);
              }}
              disabled={order._invoices.length > 0}
              defaultText="Invoice"
            />
          </div>
        </td>
      </tr>
    )
  });