export const getHeaders = (from, to, translation) => [
  [
    <th colspan="6">
      <h5>
        Pre Manager result
        <br /> {from && to ? `${from} - ${to}` : ""}
      </h5>
    </th>,
  ],
  [
    <th>{translation["consultant"]}</th>,
    <th>{translation["price"]}</th>,
    <th>{translation["sal_for_one"]}</th>,
    <th> {translation["text3"]}</th>,
    <th> {translation["text4"]}</th>,
    <th> {translation["text5"]} </th>,
  ],
];
