import Table from '@/common/components/Table'
import React, { useMemo } from 'react'
import { HEADERS, HEADERS_DONE } from './constants/headers'
import { getRaws } from './helpers/getRaws';
import { getRawsDone } from './helpers/getRawsDone';

const InvoicesTable = ({ data, selectedStatus, setEdit, handleDelete }) => {
  const raws = useMemo(() => {
    if (!!data?.length) {
     return getRaws(data, setEdit, handleDelete);
    }
  }, [data]);

  const rawsDone = useMemo(() => {
    if (data && data[0]) {
      return getRawsDone(data, setEdit);
    }
  }, [data]);

  return (
    <div>
      {!!data?.length && selectedStatus.value !== "done" && (
        <Table
          headers={HEADERS}
          raws={raws}
          className="styled-table styled-table-2"
        />
      )}
      {!!data?.length && selectedStatus.value === "done" && (
        <Table
          style={{ overflow: "scroll", minWidth: "50vh", maxWidth: "75vw" }}
          headers={HEADERS_DONE}
          raws={rawsDone}
          className="styled-table styled-table-2"
        />
      )}
    </div>
  )
}

export default InvoicesTable
