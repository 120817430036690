import React from "react";
import CSV from "../../../common/components/CSV";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findUserById } from "@/common/functions/findUserById";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const EffectivePaymentTable = ({ data, from, to, csv = false }) => {
  const { commonData: { users }} = useCommonDataContext();
  const { translation } = useTranslationContext();

  const calcTotalSalary = (orders) => {
    return orders.reduce(
      (current, next) =>
        current + calcSalary(next.big_items_count, next.small_items_count),
      0
    );
  };

  const calcSalary = (bigItems, smallItems) => {
    return (
      bigItems * data.month_working_info?.delivered_commision +
      smallItems * data.month_working_info?.additional_item_commision
    );
  };

  const calcSalaryForOne = (element) => {
    return (
      calcSalary(element.big_items_count, element.small_items_count) /
      element.delivered_count
    );
  };

  const consultant = findUserById(data.consultant, users)

  const getConsultantCSV = () => {
    const result = [];

    data.data
      .sort((a, b) => a._id - b._id)
      .forEach((currentData) => {
        const temp = [];

        temp.push(`${consultant?.name}_${consultant?.surname}`);
        temp.push(currentData.price + " " + consultant?.salary_currency);
        temp.push(
          calcSalaryForOne(currentData)
            ? calcSalaryForOne(currentData)
            : 0 + " " + data.salary_currency
        );
        temp.push(currentData.orders_count);
        temp.push(currentData.delivered_count);
        temp.push(
          calcSalary(
            currentData.big_items_count,
            currentData.small_items_count
          ) +
            " " +
            data.salary_currency
        );

        result.push(temp);
      });

    return result;
  };

  return (
    <div>
      <table className="styled-table">
        <thead>
          <tr>
            <th colspan="5">
              <h5
                style={{
                  display: "flex",
                  textAlign: "center",
                  margin: "0px 0px 10px 0px",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                {from
                  ? ` ${from} - ${to} `
                  : `${consultant?.name} ${consultant?.surname}`}{" "}
                <div>
                  {csv && (
                    <CSV
                      color="white"
                      filename={`payment-report-${
                        consultant?.name + "_" + consultant?.surname
                      }.csv`}
                      header={[
                        "Consultant",
                        "Price",
                        "Salaty for on",
                        "Confirmed orders",
                        "Delivered orders",
                        "Effective salary",
                      ]}
                      data={getConsultantCSV()}
                    />
                  )}
                </div>
              </h5>
            </th>
          </tr>
          <tr>
            <th>{translation["price"]}</th>
            <th>{translation["sal_for_one"]}</th>
            <th> {translation["text3"]}</th>
            <th> {translation["text4"]}</th>
            <th> {translation["text5"]} </th>
          </tr>
        </thead>
        <tbody className="queue">
          {data.data
            .sort((a, b) => a.price - b.price)
            .map((element) => (
              <tr style={{ borderTop: "1px solid grey" }}>
                <td style={{ width: "60px", textAlign: "left" }}>
                  {element.price} {data.salary_currency}
                </td>
                <td style={{ width: "150px", textAlign: "center" }}>
                  {calcSalaryForOne(element) ? calcSalaryForOne(element) : 0}{" "}
                  {data.salary_currency}
                </td>
                <td style={{ width: "150px", textAlign: "center" }}>
                  {element.orders_count}
                </td>
                <td style={{ width: "150px", textAlign: "center" }}>
                  {element.delivered_count}
                </td>
                <td style={{ width: "200px", textAlign: "center" }}>
                  {calcSalary(
                    element.big_items_count,
                    element.small_items_count
                  )}{" "}
                  {data.salary_currency}
                </td>
              </tr>
            ))}
          <tr style={{ borderTop: "1px solid grey", background: "lightgrey" }}>
            <td style={{ width: "60px", textAlign: "left" }}>
              {translation["text6"]}
            </td>
            <td style={{ width: "150px", textAlign: "center" }}> </td>
            <td style={{ width: "150px", textAlign: "center" }}></td>
            <td style={{ width: "150px", textAlign: "center" }}></td>
            <td
              style={{
                width: "200px",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              {calcTotalSalary(data.data)} {data.salary_currency.toUpperCase()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EffectivePaymentTable;
