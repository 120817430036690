import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import userManager from "../../../API/userManager";
import Ping from "../../../common/components/Ping";
import SearchContact from "../../../common/components/SearchContact";
import { Colors } from "../../../common/colors/colors";
import Loguout from "./Loguout";
import Tickets from "./ticket/Tickets";
import TodoView from "./todoView/TodoView";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import Icon from "@/common/components/Icon";
import SetPreferredQueue from "@/common/components/setPreferredQueue/SetPreferredQueue";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { NavigateLink } from "@/common/components/links/NavigateLink";

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 30px;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
`;

const FullNameWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const UserName = styled.p`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.darkBlue};
`;

const InfoBar = () => {
  const [showSetPreferredQueue, setShowSetPreferredQueue] = useState(false);

  const { commonData: { queues }} = useCommonDataContext();
  const { translation } = useTranslationContext();

  const userRole = userManager.getUser().role;

  const hasMultipleQueues = queues?.filter(queue => queue.consultants?.includes(userManager.getUser().id))?.length > 1;

  return (
    <Wrapper>
      <div>
        <Title>
          {translation.sayHiToUser} {userManager.getUser()?.name}!
        </Title>
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          <b style={{ color: Colors.darkBlue }}>{moment().format("dddd")}</b>
          {moment().format(", MMMM YYYY")}
        </span>
      </div>
      <SearchContact />
      <FullNameWrapper>
        <NavigateLink
          to="/consultant/profile"
        >
          <UserName>
            {userManager.getUser()?.username}
          </UserName>
        </NavigateLink>
        <Ping />
        <NavigateLink 
          to="/consultant/profile"
        >
          <i
            className="fa fa-user-circle"
            style={{
              color: Colors.darkBlue,
              fontSize: "16px",
              cursor: "pointer",
            }}
          />
        </NavigateLink>
        {hasMultipleQueues && <Icon onClick={() => setShowSetPreferredQueue(true)} name="fa fa-arrow-right-arrow-left" />}
        <Tickets />
        {CONSULTANT_MANAGER_ROLES.includes(userRole) && <TodoView />}
        {showSetPreferredQueue && (<SetPreferredQueue setShow={setShowSetPreferredQueue} />)}
        <Loguout />
      </FullNameWrapper>
    </Wrapper>
  );
};

export default InfoBar;
