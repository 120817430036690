export const getGradeColor = (grade) => {
	return grade > 2
		? grade > 4
			? grade > 6
				? grade > 8
					? "#469E2B"
					: "#93DB41"
				: "#EEF743"
			: "#F7AF43"
		: "#FC593C";
};