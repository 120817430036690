import React, { useState } from "react";
import {
  ConversatgionGroupWrapper,
  ConversationGroupTitleWrapper,
  NoDataWrapper,
  PriorityAndDwWrapper,
} from "./ConversationGroup.styled";
import ConversationUsers from "./components/conversationUsers/ConversationUsers";
import TicketChangeTitle from "./components/ticketChangeTitle/TicketChangeTitle";
import ConversationActoins from "./components/conversationActions/ConversationActoins";
import ConversationDwUsers from "./components/conversationDwUsers/ConversationDwUsers";
import SelectInput from "@/common/components/SelectInput";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Icon from "@/common/components/Icon";
import { updateTicketPriority } from "@/API/repositories/tickets";
import { PRIORITIES_OPTIONS } from "@/common/constants/questionTicket";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { getUserById } from "../../helpers/getUserById";
import { copyLinkToClipboard } from "@/common/functions/copyLinkToClipboard";
import EmailClipboard from "./components/emailClipboard/EmailClipboard";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const ConversationGroup = ({
  selectedTicket,
  handleCloseTicketView,
  loadData,
  setShowlisteningObjections,
  setRecording,
  setIsToDeclineOpen,
  setIsToCorrectOpen,
  departments,
  rerenderTickets,
  renderOptimisticTicketUi,
}) => {
  const [isPriorityUpdating, setIsPriorityUpdating] = useState();
  const { makeRequest } = useRequestsContext();
  const { commonData: { users } } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();
  const documentTo = getUserById(selectedTicket?.reciver || selectedTicket?.created_by, users)

  const handleUpdatePriority = async (value) => {
    setIsPriorityUpdating(() => true);
    const response = await makeRequest(
      updateTicketPriority.bind(null, selectedTicket?._id, value)
    );

    if (response?.data) {
      await loadData();
    }

    setIsPriorityUpdating(() => false);
  };

  const handleClickCopyEmail = () => {
    copyLinkToClipboard(selectedTicket._contact.email);
    addMessage("Copied", "success", 1000);
  };

  return (
    <ConversatgionGroupWrapper>
      {selectedTicket ? (
        <div>
          <ConversationGroupTitleWrapper>
            <ConversationUsers
              selectedTicket={selectedTicket}
              departments={departments}
              loadData={loadData}
            />
            <TicketChangeTitle
              departments={departments}
              selectedTicket={selectedTicket}
              loadData={loadData}
            />
            <div>
              <ConversationActoins
                addMessage={addMessage}
                renderOptimisticTicketUi={renderOptimisticTicketUi}
                selectedTicket={selectedTicket}
                handleCloseTicketView={handleCloseTicketView}
                loadData={loadData}
                setIsToCorrectOpen={setIsToCorrectOpen}
                setRecording={setRecording}
                setShowlisteningObjections={setShowlisteningObjections}
                setIsToDeclineOpen={setIsToDeclineOpen}
                rerenderTickets={rerenderTickets}
              />
              {selectedTicket?.contact && (
                <EmailClipboard
                  email={selectedTicket._contact.email}
                  onClick={handleClickCopyEmail}
                />
              )}
            </div>
          </ConversationGroupTitleWrapper>
          <PriorityAndDwWrapper>
            <ConversationDwUsers
              selectedTicket={selectedTicket}
              loadData={loadData}
            />
            {selectedTicket?.type === "question" ? (
              isPriorityUpdating ? (
                <div
                  style={{
                    paddingRight: "50px",
                  }}
                >
                  <Icon size={24} name="fa fa-spinner fa-spin" />
                </div>
              ) : (
                <SelectInput
                  selectWidth={100}
                  width={40}
                  fontSize={14}
                  selectFontSize={12}
                  options={PRIORITIES_OPTIONS}
                  name="Priority"
                  strong={false}
                  selected={PRIORITIES_OPTIONS.find(
                    (o) => o.value === !!selectedTicket?.prioritized
                  )}
                  setSelected={(option) => handleUpdatePriority(option.value)}
                />
              )
            ) : selectedTicket?.type === "document" && documentTo ? (
              <div>
                {documentTo?.username} has {documentTo?.vacation_days || 0} vacation days
              </div>
            ) : null}
          </PriorityAndDwWrapper>
        </div>
      ) : (
        <NoDataWrapper>No data</NoDataWrapper>
      )}
    </ConversatgionGroupWrapper>
  );
};

export default ConversationGroup;
