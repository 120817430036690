import React, { useEffect, useReducer, useState } from "react";
import { getOrderWithFilter } from "../../../API/repositories/order";
import Card from "../../../common/components/Card";
import CSV from "../../../common/components/CSV";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { getFiltersForUser } from "../../../API/repositories/filters";
import { AVAILABLE_ORDER_FILEDS } from "../../../common/constants/orderFilterFields";
import { Colors } from "../../../common/colors/colors";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import SelectInput from "@/common/components/SelectInput";
import { getCustomStyles } from "./helpers/getCustomStyles";
import { ButtonsWrapper, SearchBarWrapper, Wrapper } from "./OrderFilterTable.styled";
import { reducer } from "./helpers/reducer";
import { getOrdersToDisplay } from "./helpers/getOrdersToDisplay";
import { sort } from "./helpers/sort";
import { operationsReducer } from "./helpers/operationsReducer";
import Table from "@/common/components/Table";
import { generateTableRaws } from "./helpers/generateTableRaws";
import { OPERATION_ACTIONS, OPERATIONS } from "./components/orderFilter/constants/operations";
import FilterOptions from "./components/orderFilter/FilterOptions";
import FilterTable from "./components/filterTable/FilterTable";

const OrderFilterTable = () => {
  const [filters, dispatchFilters] = useReducer(reducer, {
    "order.created_at": {
      arithmetic_from: "$gte",
      days: -7,
      type_field: "Date",
    },
  });

  const [operations, dispatchOperations] = useReducer(operationsReducer, {});

  const [selected, setSelected] = useState([
    {
      label: "order.created_at",
      value: {
        type: "Date",
      },
    },
  ]);

  const [selectedOperationFields, setSelectedOperationFields] = useState([]);
  const [selectedGroupByFields, setSelectedGroupByFields] = useState([]);

  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState();
  const [selectedFilter, setSelectedFilter] = useState();
  const [selectedFilterOption, setSelectedFilterOption] = useState();
  const [filterData, setFilterData] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOrderFileds, setSelectedOrderFileds] = useState(
    AVAILABLE_ORDER_FILEDS
  );
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [orders, setOrders] = useState(null);
  const [orderstToDisplay, setOrdersToDisplay] = useState(null);
  const [afterOperationsOrders, setAfterOperationsOrders] = useState(null);
  const [afterOperationsHeaders, setAfterOperationsHeaders] = useState(null);


  const { options } = useCommonDataContext();
  const { marketsOptions, usersOptions, productsOptions } = options;

  const handleGetPosibleFilters = async () => {
    const response = await makeRequest(getFiltersForUser.bind(null));

    if (response.data) {
      const toDisplay = [];

      response.data.forEach((data) => {
        data.filters.forEach((filter) => {
          toDisplay.push({ label: filter.name, value: filter });
        });
      });

      const filters = [];
      response.data.forEach((data) => {
        data.filters.forEach((filter) => {
          filters.push(filter);
        });
      });

      setFilterData(() => filters);
      setSelectedFilterOption(() => toDisplay);
    }
  };

  const handleDisplayOrders = () => {
    return getOrdersToDisplay(orders, selectedOrderFileds);
  };

  useEffect(() => {
    if (orders) {
      const data = handleDisplayOrders();
      setOrdersToDisplay(() => data);
    }
  }, [selectedOrderFileds, orders]);

  useEffect(() => {
    async function initalData() {
      await handleGetPosibleFilters();
    }
    initalData();
  }, [showFilters]);

  const handleFindOrderWithFilters = async (e) => {
    e && e.preventDefault();
    setOrders(() => null);
    setOrdersToDisplay(() => null);
    setAfterOperationsHeaders(() => null);
    setAfterOperationsOrders(() => null);

    const payload = {
      filters,
      operations
    }

    const response = await makeRequest(
      getOrderWithFilter.bind(null, payload)
    );

    if (response.data?.mainTable) {
      const { mainTable, afterOperationsTable } = response.data

      const sorted = sortBy
        ? sort(
          mainTable,
          sortBy.value.type,
          sortBy.value.label,
          sortType?.value
        )
        : mainTable;

      setOrders(() => sorted);

      const hasAfterOperations = afterOperationsTable && Object.keys(afterOperationsTable).length > 0;

      if (hasAfterOperations) {
        setAfterOperationsHeaders(afterOperationsTable.headers);
        setAfterOperationsOrders(generateTableRaws(afterOperationsTable?.flattened));
      }
    }
  };

  const handleSelectFiter = (selected) => {
    const selectedFilter = filterData.find(
      (filtr) => filtr._id === selected.value._id
    );

    dispatchFilters({
      type: "init",
      data: JSON.parse(selectedFilter.data),
    });

    if (selectedFilter.operations) {
      dispatchOperations({ type: OPERATION_ACTIONS.INIT, payload: JSON.parse(selectedFilter?.operations) });
      setSelectedOperationFields(() => Object.keys(JSON.parse(selectedFilter?.operations))?.filter(key => JSON.parse(selectedFilter?.operations)[key].operation !== OPERATIONS.GROUP_BY)
        .map(key => ({ label: key, value: { type: JSON.parse(selectedFilter?.operations)[key].type } }))
      );
      setSelectedGroupByFields(() => Object.keys(JSON.parse(selectedFilter?.operations))?.filter(key => JSON.parse(selectedFilter?.operations)[key].operation === OPERATIONS.GROUP_BY)
        .map(key => ({ label: key, value: { type: JSON.parse(selectedFilter?.operations)[key].type } })))
    } else {
      dispatchOperations({ type: OPERATION_ACTIONS.RESET })
      setSelectedOperationFields([])
    }


    setSelected(() =>
      Object.entries(JSON.parse(selectedFilter.data)).map(([key, value]) => {
        return {
          label: key,
          value: { ...value, type: value.type_field },
        };
      })
    );

    selectedFilter.fields &&
      setSelectedOrderFileds(() => JSON.parse(selectedFilter.fields));
    selectedFilter.sort_type &&
      setSortType(() => JSON.parse(selectedFilter.sort_type));
    selectedFilter.sort_by &&
      setSortBy(() => JSON.parse(selectedFilter.sort_by));

    setSelectedFilter(() => selected);
  };

  return (
    <Wrapper>
      <Card>
        <SearchBarWrapper>
          <SelectInput
            value={selectedFilter}
            setSelected={(selected) => handleSelectFiter(selected)}
            options={selectedFilterOption}
            styles={getCustomStyles(300)}
            name={"Filters"}
            color={Colors.darkBlue}
            selectWidth={250}
            width={60}
          />
          <ButtonsWrapper>
            <ActionButton
              defaultText={"Options"}
              onClick={() => {
                setShowFilters((prev) => !prev);
                setOrdersToDisplay(() => null);
              }}
            />
            <ActionButton
              defaultText={"Search"}
              onClick={(e) => handleFindOrderWithFilters(e)}
            />
            {orderstToDisplay && (
              <CSV
                filename={"orders.csv"}
                header={selectedOrderFileds.map((item) => item["label"])}
                data={orderstToDisplay.map((data) => data.data)}
              />
            )}
          </ButtonsWrapper>
        </SearchBarWrapper>
      </Card>
      {showFilters && (
        <FilterOptions
          selectedGroupByFields={selectedGroupByFields}
          setSelectedGroupByFields={setSelectedGroupByFields}
          dispatchOperations={dispatchOperations}
          selectedOperationFields={selectedOperationFields}
          setSelectedOperationFields={setSelectedOperationFields}
          setShow={setShowFilters}
          setSelectedOrderFileds={setSelectedOrderFileds}
          selectedOrderFileds={selectedOrderFileds}
          filters={filters}
          dispatchFilters={dispatchFilters}
          _id={selectedFilter}
          selectedData={selected}
          setSelectedData={setSelected}
          markets={marketsOptions}
          products={productsOptions}
          consultants={usersOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortType={sortType}
          setSortType={setSortType}
          operations={operations}
        />
      )}
      {orderstToDisplay && (!afterOperationsHeaders || !afterOperationsOrders) && (
        <FilterTable rows={orderstToDisplay} columns={selectedOrderFileds} />
      )}
      {afterOperationsOrders && afterOperationsHeaders && (
        <Table className="styled-table" headers={afterOperationsHeaders} raws={afterOperationsOrders} />
      )}
      {hasUnfilledRequest(getOrderWithFilter) && <Loading />}
    </Wrapper>
  );
};

export default OrderFilterTable;
