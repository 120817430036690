import { calcWorkingHours } from "@/common/functions/calcWorkingHours";
import moment from "moment";

export const getCSVBodyForHoursReport = (
  data,
  selectedConsultant,
  consultants,
  workingFromDate,
  workingToDate,
  filterRegex
) => {
  return data
    .sort((a, b) => a._id.localeCompare(b._id))
    .filter((e) =>
      filterRegex
        ? (e?._month_working_info?.contract_type || e?._user?.contract_type)
          ?.toLowerCase()
          .includes(filterRegex.toLowerCase())
        : true
    )
    .filter((c) => selectedConsultant.map((c1) => c1.value).includes(c._id))
    .map((consultantData, i) => [
      i + 1 + ".",
      consultants.find((c) => c.value === consultantData._id)?.label ||
      "------",
      consultantData._month_working_info?.month_hours || "------",
      (consultantData._month_working_info?.working_from &&
        moment(consultantData._month_working_info?.working_from).format(
          "YYYY-MM-DD"
        )) ||
      workingFromDate,
      (consultantData._month_working_info?.working_to &&
        moment(consultantData._month_working_info?.working_to).format(
          "YYYY-MM-DD"
        )) ||
      workingToDate,
      calcWorkingHours(consultantData.data),
      consultantData.data.reduce(
        (prev, next) => prev + next.attendance_at_work + next.manager_hours,
        0
      ) || 0,
      consultantData.data.reduce(
        (prev, next) => prev + next.holiday_leave,
        0
      ) || 0,
      consultantData.data.reduce(
        (prev, next) => prev + (next.special_leave || 0),
        0
      ) || 0,
      consultantData.data.reduce((prev, next) => prev + next.sick_leave, 0) ||
      0,
      consultantData.data.reduce(
        (prev, next) => prev + next.excused_unpaid_leave,
        0
      ) || 0,
      consultantData.data.reduce(
        (prev, next) => prev + next.absence_without_excuse,
        0
      ) || 0,
    ]);
};
