import Icon from "@/common/components/Icon";
import { VALID_CLAIMS_ACTIONS } from "./reducer";
import { FlexCell } from "../components/claimTable/ClaimTable.styled";
import { Colors } from "@/common/colors/colors";

export const getPaidHeadersArray = (setOpenAction, filters) => (
  [[
    <th>
      No.
    </th>,
    <th>
      <FlexCell>
        Market
        <Icon
          color={filters?.[VALID_CLAIMS_ACTIONS.SET_MARKETS]?.length ? Colors.orange : "#fff"}
          name="fa fa-filter"
          onClick={() => setOpenAction(VALID_CLAIMS_ACTIONS.SET_MARKETS)}
        />
      </FlexCell>
    </th>,
    <th>
      Full name
    </th>,
    <th>
      Mail
    </th>,
    <th>
      Delivered at
    </th>,
    <th>
      Refund date
    </th>,
    <th>
      <FlexCell>
        Payment method
        <Icon
          color={filters?.[VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD]?.length ? Colors.orange : "#fff"}
          name="fa fa-filter"
          onClick={() => setOpenAction(VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD)}
        />
      </FlexCell>
    </th>,
    <th>
      Claim status
    </th>,
    <th>
      Actions
    </th>,
    <th>
      Załączniki
    </th>
  ]]
);

export const getUnpaidHeadersArray = (setOpenAction, filters) => ([[
  <th>
    No.
  </th>,
  <th>
    <FlexCell>
      Market
      <Icon
        color={filters?.[VALID_CLAIMS_ACTIONS.SET_MARKETS]?.length ? Colors.orange : "#fff"}
        name="fa fa-filter"
        onClick={() => setOpenAction(VALID_CLAIMS_ACTIONS.SET_MARKETS)}
      />
    </FlexCell>
  </th>,
  <th>
    Full name
  </th>,
  <th>
    Mail
  </th>,
  <th>
    Delivered at
  </th>,
  <th>
    Created at
  </th>,
  <th>
    Max payment date
  </th>,
  <th>
    Amount
  </th>,
  <th>
    <FlexCell>
      Payment method
      <Icon
        color={filters?.[VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD]?.length ? Colors.orange : "#fff"}
        name="fa fa-filter"
        onClick={() => setOpenAction(VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD)}
      />
    </FlexCell>
  </th>,
  <th>
    Claim status
  </th>,
  <th>
    Actions
  </th>
]]);

export const PAID_HEADERS = [
  "No.",
  "Market",
  "Full name",
  "Mail",
  "Delivered at",
  "Refund date",
  "Payment method",
  "Claim status",
  "Actions",
  "Załączniki"
];

export const UNPAID_HEADERS = [
  "No.",
  "Market",
  "Full name",
  "Mail",
  "Delivered at",
  "Created at",
  "Max payment date",
  "Amount",
  "Payment method",
  "Claim status",
  "Actions"
];
