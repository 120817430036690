import React from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import PopUp from "../../../common/components/PopUp";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import ActionButton from "@/common/components/buttons/ActionButton";

const H3 = styled.h3`
  color: ${Colors.darkBlue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  margin-bottom: -30px;
`;

const Conecting = ({ isConnected, isPlivoConnected }) => {
  const { translation } = useTranslationContext();

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <PopUp canOffByClickingBackground={false}>
      <Wrapper>
        {!isConnected && <H3>{translation.connectingToServer}</H3>}
        {!isPlivoConnected && <H3>{translation.connectingToCalling}</H3>}
        <ActionButton defaultText="Refresh" onClick={() => handleRefresh()} />
      </Wrapper>
    </PopUp>
  );
};

export default Conecting;
