import styled from "styled-components";

export const ElementWrapper = styled.div`
	width: 110px;
	text-align: right;
	padding-right: 10px;
	font-weight: bold;
	font-style: italic;
`;

export const RestInfoWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: left;
`;

export const Flex = styled.div`
	display: flex;
`;

export const InfoWrapper = styled.div`
	display: flex;
	padding: 10px;
	flex-direction: column;
`;

export const NavigationWrapper = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 10px;
`;
