import React, { useEffect, useMemo, useState } from "react";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import ActionButton from "@/common/components/buttons/ActionButton";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import moment from "moment";
import { addHoursForDay } from "@/API/repositories/consultantHours";
import { useRequestsContext } from "@/common/hooks/requestHook";
import AddDayHours from "./components/AddDayHours";
import Loading from "@/common/components/Loading";
import {
  ButtonWrapper,
  InputsWrapper,
  TitleWrapper,
} from "@/pages/admin/ccHours/CCHours.styled";
import { getBonusesByUserId } from "@/API/repositories/bonus";
import { HOURS_POSIBLES } from "@/common/constants/CC";
import userManager from "@/API/userManager";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const AddHoursForAny = ({
  setIsAddHoursForAnyOpen,
  monthName,
  hanldeSearch,
}) => {
  const [bonuses, setBonuses] = useState([]);
  const [isDayHoursOpen, setIsDayHoursOpen] = useState();
  const [selectedConsultantToAddHours, setSelectedConsultantToAddHours] =
    useState();
  const [date, setDate] = useState(null);
  const [workingHoursOnSave, setWorkingHoursOnSave] = useState({});

  const { commonData: { users } } = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const currentUserId = userManager.getUser().id;

  const consultants = useMemo(() => {
    return handleMapToOptions
    (
      users?.filter(user => user.managers?.some(m => m.manager === currentUserId)) || [],
      'username',
      '_id'
    );
  }, [users, currentUserId]);

  const handleHoursChange = (hour, sign, bonusId) => {
    if (HOURS_POSIBLES.includes(sign)) {
      setWorkingHoursOnSave((prev) => {
        const next = { ...prev };

        next[hour] = { sign, bonusId };

        return next;
      });
    }
  };

  const handleRemoveConsultantHours = async (hour) => {
    setWorkingHoursOnSave((prev) => {
      delete prev[hour];
      return { ...prev };
    });
  }

  const loadBonuses = async () => {
    setBonuses([]);
    const response = await makeRequest(getBonusesByUserId.bind(null, selectedConsultantToAddHours.value));

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setBonuses(() => response.data);
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.consultant = selectedConsultantToAddHours.value;
    payload.hours = workingHoursOnSave;
    payload.for_day = new Date(formatToDateTamplate(moment(date)));

    const response = await makeRequest(addHoursForDay.bind(null, payload));

    if (response.data) {
      hanldeSearch();
      setIsDayHoursOpen(() => false);
      setIsAddHoursForAnyOpen(() => false);
      return addMessage("Success", "success");
    }

    addMessage("Something went wrong", "error");
  };

  useEffect(() => {
    if (selectedConsultantToAddHours) {
      loadBonuses();
    }
  }, [selectedConsultantToAddHours])

  return (
    <PopUp setShow={setIsAddHoursForAnyOpen}>
      {hasUnfilledRequest(addHoursForDay) && <Loading />}
      <TitleWrapper>Select user and day</TitleWrapper>
      <InputsWrapper>
        <SelectInput
          setSelected={setSelectedConsultantToAddHours}
          showLabel={false}
          options={[...consultants]}
          selected={selectedConsultantToAddHours}
        />
        <Input
          min={formatToDateTamplate(monthName.startOf("month"))}
          max={formatToDateTamplate(monthName.endOf("month"))}
          showLabel={false}
          type="date"
          value={formatToDateTamplate(moment(date))}
          onChange={(e) => setDate(() => e.target.value)}
        />
      </InputsWrapper>
      <ButtonWrapper>
        <ActionButton
          onClick={() => setIsDayHoursOpen(true)}
          defaultText="Open hours"
          disabled={!selectedConsultantToAddHours || !date}
        />
      </ButtonWrapper>
      {isDayHoursOpen && (
        <AddDayHours
          handleRemoveConsultantHours={handleRemoveConsultantHours}
          bonuses={bonuses}
          workingHoursOnSave={workingHoursOnSave}
          setIsDayHoursOpen={setIsDayHoursOpen}
          date={date}
          handleHoursChange={handleHoursChange}
          handleSubmit={handleSubmit}
        />
      )}
    </PopUp>
  );
};

export default AddHoursForAny;
