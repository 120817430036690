import { Colors } from '@/common/colors/colors';
import PopUp from '@/common/components/PopUp';
import React, { useMemo } from 'react'
import { BorderLine, ColorTitle, OptionWrapper } from './MailOptionPopUp.styled';
import SelectInput from '@/common/components/SelectInput';
import Input from '@/common/components/Input';
import { MAIL_TEMPLATE_BOUNCE_TYPES } from '@/common/constants/mails';
import { ACTIONS } from '../../constants/fiter.constants';
import { CONSULTANT_ROLES } from '@/common/constants/consultantRoles';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';

const MailOptionPopUp = ({
  templateOptions,
  setShow,
  dispatchFilters,
  filters,
}) => {
  const {
    mailTemplate,
    customer,
    consultant,
    openCountFrom,
    openCountTo,
    clickCountFrom,
    clickCountTo,
    spamReportsFrom,
    spamReportsTo,
    createdDateFrom,
    createdDateTo,
    processedDateFrom,
    processedDateTo,
    deliveredDateFrom,
    deliveredDateTo,
    droppedDateFrom,
    droppedDateTo,
    bouncedDateFrom,
    bouncedDateTo,
    bouncedType,
    firstOpenDateFrom,
    firstOpenDateTo,
    firstClickDateFrom,
    firstClickDateTo,
    firstSpamReportDateFrom,
    firstSpamReportDateTo,
  } = filters; 
  const { filterUsersByRoles } = useCommonDataContext();

  const consultants = useMemo(() => {
    return filterUsersByRoles(CONSULTANT_ROLES)
  }, [])

  return (
    <PopUp setShow={setShow}>
      <h3 style={{ color: Colors.darkBlue, marginBottom: "40px" }}>
        Mail Filter
      </h3>
      <ColorTitle>Type</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <SelectInput
          name="Mail Template"
          options={templateOptions}
          selected={mailTemplate}
          setSelected={(value) => dispatchFilters({ type: ACTIONS.SET_MAIL_TEMPLATE, payload: value })}
          multiple={true}
          selectWidth={500}
        />
      </OptionWrapper>
      <ColorTitle>Customer</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          name="Email"
          value={customer}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_CUSTOMER, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Sender</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <SelectInput
          name="Consultants"
          multiple={true}
          options={consultants}
          selected={consultant}
          setSelected={(value) => dispatchFilters({ type: ACTIONS.SET_CONSULTANT, payload: value })}
        />
      </OptionWrapper>
      <ColorTitle>Open Times</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={openCountFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_OPEN_COUNT_FROM, payload: e.target.value })}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={openCountTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_OPEN_COUNT_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Click Times</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={clickCountFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_CLICK_COUNT_FROM, payload: e.target.value })}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={clickCountTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_CLICK_COUNT_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Spam Report Times</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="number"
          name="Number from"
          width={140}
          value={spamReportsFrom}
          onChange={(e) => dispatchFilters({type: ACTIONS.SET_SPAM_REPORTS_FROM, payload: e.target.value })}
        />
        <Input
          type="number"
          name="Number to"
          width={140}
          value={spamReportsTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_SPAM_REPORTS_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Created Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={createdDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_CREATED_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={createdDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_CREATED_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Processed Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={processedDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_PROCESSED_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={processedDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_PROCESSED_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>

      <ColorTitle>Delivered Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={deliveredDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_DELIVERED_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={deliveredDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_DELIVERED_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Dropped Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={droppedDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_DROPPED_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={droppedDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_DROPPED_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Bounced Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={bouncedDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_BOUNCED_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={bouncedDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_BOUNCED_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>Bounced Type</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <SelectInput
          name="Types"
          width={140}
          options={MAIL_TEMPLATE_BOUNCE_TYPES}
          selected={bouncedType}
          setSelected={(value) => dispatchFilters({ type: ACTIONS.SET_BOUNCED_TYPE, payload: value })}
        />
      </OptionWrapper>
      <ColorTitle>First Open Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstOpenDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_OPEN_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstOpenDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_OPEN_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>First click Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstClickDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_CLICK_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstClickDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_CLICK_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
      <ColorTitle>First Spam Report Date</ColorTitle>
      <BorderLine></BorderLine>
      <OptionWrapper>
        <Input
          type="date"
          name="Date From"
          width={140}
          value={firstSpamReportDateFrom}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_SPAM_REPORT_DATE_FROM, payload: e.target.value })}
        />
        <Input
          type="date"
          name="Date To"
          width={140}
          value={firstSpamReportDateTo}
          onChange={(e) => dispatchFilters({ type: ACTIONS.SET_FIRST_SPAM_REPORT_DATE_TO, payload: e.target.value })}
        />
      </OptionWrapper>
    </PopUp>
  );
};

export default MailOptionPopUp
