import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./InvoiceConfig.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { getInvoiceConfigs } from "@/API/repositories/invoiceConfig";
import InvoiceConfigForm from "./components/invoiceConfigForm/InvoiceConfigForm";
import InvoiceConfigTable from "./components/invoiceConfigTable/InvoiceConfigTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const InvoiceConfig = () => {
  const [invoiceConfigs, setInvoiceConfigs] = useState();
  const [isInvoiceConfigFormOpen, setIsInvoiceConfigFormOpen] = useState();
  const [selectedInvoiceConfig, setSelectedInvoiceConfig] = useState();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getInvoiceConfigs);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setInvoiceConfigs(() => response.data);
  };

  const handleSetInvoiceConfigFormInEditMode = (key) => {
    setSelectedInvoiceConfig(() => key);
    setIsInvoiceConfigFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsInvoiceConfigFormOpen(() => false);
    setSelectedInvoiceConfig(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getInvoiceConfigs) && <Loading />}
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content" }}
          defaultText="Add invoice config"
          onClick={() => setIsInvoiceConfigFormOpen(true)}
        />
      </ContentWrapper>
      {isInvoiceConfigFormOpen && (
        <InvoiceConfigForm
          setShow={handleCloseForm}
          loadData={loadData}
          selectedInvoiceConfig={selectedInvoiceConfig}
        />
      )}
      {!!invoiceConfigs?.length && (
        <InvoiceConfigTable
          setInvoiceConfig={handleSetInvoiceConfigFormInEditMode}
          invoiceConfigs={invoiceConfigs}
        />
      )}
    </PageWrapper>
  );
};

export default InvoiceConfig;
