import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';


export const Flex = styled.div`
  display: flex;
`;

export const H5 = styled.strong`
  color: ${Colors.darkBlue};
  width: 220px;
  text-align: right;
  font-size: 18px;
  margin-top: 10px;
`;

export const DataStrong = styled.h5`
  color: ${Colors.darkBlue};
  width: 200px;
  margin-left: 30px;
  margin-top: 10px;
`;

export const MessagesBox = styled.div`
  padding: 20px 10px;
  width: 460px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${Colors.darkBlue};
`;

export const UserWrapper = styled.p`
  font-size: 14px;
  margin-right: 10px;
  width: 120px;
  text-align: left;
`;
