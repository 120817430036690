import { compareDates } from "@/common/functions/compareDates";
import { copyLinkToClipboard } from "@/common/functions/copyLinkToClipboard";
import { navigateToContact, navigateToOrder, navigateToUser } from "@/common/functions/naviagte";
import moment from "moment";
import { IconWrapper } from "../ContactsRoadTable.styled";

export const getRaws = (data) => data
  .sort(
    (a, b) =>
      compareDates(new Date(b.created_at), new Date(a.created_at))
  )
  .map((element, i) => (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>
        {moment(element.created_at).format("DD/MM/YYYY HH:mm:ss")}
      </td>
      <td>
        {(element.updated_at &&
          moment(element.updated_at).format(
            "DD/MM/YYYY HH:mm:ss"
          )) ||
          "-----"}
      </td>
      <td>{element.consultant.username}</td>
      <td>{element.status || "------"}</td>
      <td>{element.contact.email || "------"}</td>
      <td>{element.contact.phone_number || "------"}</td>
      <td>{element.contact.full_name || "------"}</td>
      <td>
        <IconWrapper>
          <i
            className="fa fa-user animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => navigateToUser(element.consultant._id)}
          />
          <i
            className="fa fa-address-book animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => navigateToOrder(element.order._id)}
          />
          <i
            className="fa fa-book animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => navigateToContact(element.contact._id)}
          />
          <i
            className="fa fa-copy animation-scale"
            style={{ cursor: "pointer" }}
            onClick={() => copyLinkToClipboard(element._id)}
          />
        </IconWrapper>
      </td>
    </tr>
  ))