import PopUp from '@/common/components/PopUp'
import React from 'react'
import { Header } from '../../ValidClaimForm.styled'
import { Colors } from '@/common/colors/colors'
import { SureButtonsWrapper } from './AreYouSure.styled'
import ActionButton from '@/common/components/buttons/ActionButton'

const AreYouSure = ({ setShow, submit }) => {
	return (
		<PopUp setShow={setShow}>
			<Header>
				Are you sure you want to cancel this claim?
			</Header>
			<SureButtonsWrapper>
				<ActionButton
					buttonType='secondary'
					color={Colors.green}
					onClick={() => submit(true)}
					defaultText="Yes"
				/>
				<ActionButton
					buttonType='secondary'
					color={Colors.red}
					defaultText="No"
					onClick={() => setShow(false)}
					background={Colors.red}
				/>
			</SureButtonsWrapper>
		</PopUp>
	)
}

export default AreYouSure
