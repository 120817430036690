import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const BorderLine = styled.div`
  width: 100%;
  background-color: ${Colors.lightlightBlue};
  height: 1px;
`;

export const ColorTitle = styled.h4`
  color: ${Colors.darkBlue};
`;

export const OptionWrapper = styled.div`
  margin: 20px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
