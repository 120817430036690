import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const FormWrapper = styled.div`
	width: 1000px;
	display: grid;
	gap: 40px;
`;

export const Title = styled.h3`
	color: ${Colors.darkBlue}
`;

export const Flex = styled.div`
	display: flex;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: right;
	margin-top: -40px;
	gap: 30px;
`;
