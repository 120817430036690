import PopUp from "@/common/components/PopUp";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";

const DeleteReportPopUp = ({ onClose, onDelete }) => {
  return (
    <PopUp setShow={onClose}>
      <form>
        <p>Are you sure you want to delete this mailing report?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 10px -20px 0",
          }}
        >
          <ActionButton
            onClick={onDelete}
            color={Colors.orange}
            defaultText={`Delete`}
          />
        </div>
      </form>
    </PopUp>
  );
};

export default DeleteReportPopUp;
