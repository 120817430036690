import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import React, { useRef, useState } from "react";
import { Icon, OrderNavigateWrapper } from "../../ValidInvoicesTable.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper } from "../../../../ValidInvoices.styled";
import { EditableWrapper } from "./CorrectionInvoice.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  acceptCorrectionInvoice,
  generateCorrectionInvoicePreview,
  updateCorrectionInvoice,
} from "@/API/repositories/tickets";
import InvoicePreview from "@/pages/admin/validInvoices/common/components/invoicePreview/InvoicePreview";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
const pako = require("pako");

const CorrectionInvoiceEdit = ({
  setSelectedTicket,
  selectedTicket,
  handleSearch,
  isRestricted,
}) => {
  const [updateColor, setUpdateColor] = useState(Colors.orange);
  const [generateColor, setGenerateColor] = useState(Colors.orange);
  const [pdfSource, setPdfSource] = useState();
  const [showWarning, setShowWarning] = useState();

  const issueDateRef = useRef();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleUpdateCorrectionInvoice = async () => {
    const payload = {};

    payload.issue_date = issueDateRef.current?.value;

    const response = await makeRequest(
      updateCorrectionInvoice.bind(null, selectedTicket?._id, payload)
    );

    if (!response?.data) {
      setUpdateColor(() => Colors.red);
      setTimeout(() => {
        setUpdateColor(() => Colors.orange);
      }, 3000);

      return addMessage("Couldn't update", "error");
    }

    await handleSearch();
    setUpdateColor(() => Colors.green);
    setTimeout(() => {
      setUpdateColor(() => Colors.orange);
    }, 3000);
    addMessage("Success", "success");
  };

  const handleAcceptCorrectionInoice = async () => {
    setShowWarning(() => false);

    const response = await makeRequest(
      acceptCorrectionInvoice.bind(null, selectedTicket._id)
    );

    if (!response.data) {
      return addMessage("Something went wrong", "error");
    }

    await handleSearch();
    addMessage("Success", "success");
    setPdfSource(() => null);
    setSelectedTicket(() => null);
  };

  const handleGenerateCorrectionInvoicePreview = async () => {
    const payload = {};

    payload.issue_date = issueDateRef.current?.value;
    payload.ticketId = selectedTicket?._id;

    const response = await makeRequest(
      generateCorrectionInvoicePreview.bind(null, payload)
    );

    if (!response?.data?.data) {
      setGenerateColor(() => Colors.red);
      setTimeout(() => {
        setGenerateColor(() => Colors.orange);
      }, 3000);

      return addMessage("Couldn't generate", "error");
    }

    const uint8Array = new Uint8Array(response.data.data);

    const decompressed = pako.inflate(uint8Array);

    const blob = new Blob([decompressed], {
      type: "application/pdf",
    });

    const url = URL.createObjectURL(blob);

    setPdfSource(() => url);

    await handleSearch();
    setGenerateColor(() => Colors.green);
    setTimeout(() => {
      setGenerateColor(() => Colors.orange);
    }, 3000);
    addMessage("Success", "success");
  };

  return (
    <PopUp padding="10px 20px 14px" setShow={setSelectedTicket}>
      <OrderNavigateWrapper>
        <Icon
          onClick={() =>
            window.open(`/dashboard/order/${selectedTicket.order}`, "_blank")
          }
          className="fa fa-truck"
        />
      </OrderNavigateWrapper>
      <EditableWrapper>
        <Input
          type="date"
          name="Issue date"
          color={Colors.darkBlue}
          inputRef={issueDateRef}
          width={140}
          inputWidth={300}
          value={formatToDateTamplate(selectedTicket.valid_invoice.issue_date)}
        />
      </EditableWrapper>
      <ButtonWrapper>
        <ActionButton
          color={updateColor}
          defaultText="Save"
          onClick={() => handleUpdateCorrectionInvoice()}
        />
        <ActionButton
          color={generateColor}
          defaultText="Generate preview"
          onClick={() => handleGenerateCorrectionInvoicePreview()}
          disabled={isRestricted}
        />
      </ButtonWrapper>
      {pdfSource && (
        <InvoicePreview
          showWarning={showWarning}
          setShowWarning={setShowWarning}
          pdfSource={pdfSource}
          setPdfSource={setPdfSource}
          handleAccept={() => handleAcceptCorrectionInoice()}
        />
      )}
    </PopUp>
  );
};

export default CorrectionInvoiceEdit;
