import React from "react";
import { ButtonsWrapper, Hover, Icon } from "../../../InvoiceTicket.styled";
import { goToMaps } from "../../../helpers/goToMaps";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import { INVOICE_CREATION_TYPES } from "../../../contstants/invoices";
import { searchInGoogle } from "../../../helpers/searchInGoogle";
import userManager from "@/API/userManager";
import { CONSULTANT_ROLES } from "@/common/constants/consultantRoles";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const InvoiceButtons = ({
  addressRef,
  companyNameRef,
  canChangeCreationType,
  setInvoiceCreateBy,
  checkColor,
  handleCheck,
  differentCreationType,
}) => {
  const { translation } = useTranslationContext();

  const user = userManager.getUser();
  const isNotConsultant = !CONSULTANT_ROLES.includes(user.role?.toUpperCase());

  return (
    <ButtonsWrapper>
      {isNotConsultant && (
        <ActionButton
          color={Colors.darkPurple}
          defaultText="Physical person"
          onClick={() =>
            setInvoiceCreateBy(() => INVOICE_CREATION_TYPES.PHYSICAL_PERSON)
          }
        />
      )}
      {companyNameRef.current?.value && (
        <Hover label={translation.find_in_google}>
          <Icon
            className="fa fa-google animation-scale"
            onClick={() => searchInGoogle(companyNameRef.current?.value)}
          />
        </Hover>
      )}
      {addressRef.current?.value && (
        <Hover label={translation.maps}>
          <Icon
            className="fa fa-map animation-scale"
            onClick={() => goToMaps(addressRef.current.value)}
          />
        </Hover>
      )}
      {addressRef.current?.value && companyNameRef.current?.value && (
        <SubmitButton
          text={translation.create_invoice}
        />
      )}
      {canChangeCreationType && (
        <ActionButton
          color={Colors.red}
          defaultText={
            differentCreationType === INVOICE_CREATION_TYPES.VAT_ID
              ? translation.vat_id_check
              : translation.different_id_check
          }
          onClick={() => setInvoiceCreateBy(() => differentCreationType)}
        />
      )}
      <ActionButton
        disabled={companyNameRef.current?.value && addressRef.current?.value}
        color={checkColor}
        defaultText={translation.check_company}
        onClick={() => handleCheck()}
      />
    </ButtonsWrapper>
  );
};

export default InvoiceButtons;
