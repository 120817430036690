import PopUp from '@/common/components/PopUp';
import SelectInput from '@/common/components/SelectInput';
import React from 'react'
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { VALID_CLAIMS_ACTIONS } from '../../constants/reducer';
import { PAYMENT_METHODS_OPTIONS } from '@/common/constants/validClaims';
import { FilterSetterWrapper } from './FilterSetter.styled';

const FilterSetter = ({
	openAction,
	setOpenAction,
	filter,
	dispatchFilter,
}) => {
	const { options: { marketsOptions } } = useCommonDataContext();

	const getActionOptions = () => {
		switch (openAction) {
			case VALID_CLAIMS_ACTIONS.SET_MARKETS:
				return marketsOptions;
			case VALID_CLAIMS_ACTIONS.SET_PAYMENT_METHOD:
				return PAYMENT_METHODS_OPTIONS;
			default: return [];
		}
	}
	return (
		<PopUp
			minWidth='300px'
			padding='10px 20px'
			setShow={setOpenAction}
		>
			<SelectInput
				multiple
				selectWidth={500}
				showLabel={false}
				options={getActionOptions()}
				selected={filter[openAction]}
				setSelected={(value) => dispatchFilter({ type: openAction, payload: value })}
			/>
			<FilterSetterWrapper />
		</PopUp>
	)
}

export default FilterSetter
