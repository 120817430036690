import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  overflow-y: scroll;
  max-height: 95vh;
  gap: 50px;
`;

export const ResultElement = styled.div`
  padding: 15px 20px;
  width: 600px;
  border: 2px solid ${(props) => (props.isCurrent ? Colors.red : props.color)};
  border-radius: 10px;
  background-color: ${(props) => props.color};
  display: flex;
  gap: 20px;
  justify-content: space-between;
  cursor: ${(props) => (props.isCurrent ? "pointer" : "")};
`;

export const ResultCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ResultElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const UsernameWrapper = styled.h3`
  color: white;
  font-style: italic;
  font-size: 22px;
  width: 220px;
`;

export const NumberWrapper = styled.h3`
  color: white;
  font-size: 22px;
  align-self: flex-end;
`;

export const CurrentUserWraper = styled.h3`
  font-size: 30px;
  color: red;
  position: absolute;
  right: -40px;
  top: -30px;
  transform: rotate(45deg);
`;

export const PointTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 60px;
`;

export const PointWrapper = styled.div`
  width: 500px;
  border-left: 1px solid grey;
  padding-left: 10px;
  display: flex;
  gap: 10px;
`;
