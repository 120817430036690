import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import { MANAGER_ROLE } from "@/pages/admin/user/components/userForm/constants";
import { deleteFileById } from "@/API/repositories/storedDocument";
import userManager from "@/API/userManager";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getUserCurrentContact } from "@/API/repositories/queue";
import Loading from "@/common/components/Loading";
import { ButtonsContainer, MiddleContainer, TopViewContainer, Wrapper } from "./ConsultantDashboard.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import Blackboard from "../dashboard/panel/blackboard-info/Blackboard";
import Gamification from "../dashboard/panel/gamification/Gamification";
import ManagerStatsTable from "../dashboard/panel/manager-stats-table/ManagerStatsTable";
import StartCalling from "../dashboard/panel/start-calling/StartCalling";
import Contact from "../dashboard/panel/contact/Contact";
import Inbound from "../dashboard/panel/inbound/Inbound";
import Create from "../dashboard/panel/create/Create";
import CallingStatisticsChart from "../dashboard/panel/callingStats/CallingStatisticsChart";
import GradeChart from "../dashboard/panel/GradeChart/GradeChart";
import ConfirmedToHoursChart from "../dashboard/panel/confirmedToHoursChart/ConfirmedToHoursChart";
import ConsultantQueueActions from "../dashboard/panel/queueActons/ConsultantQueueActions";

const ConsultantDashboard = () => {
  const [currentContact, setCurrentContact] = useState(null);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const userRole = userManager.getUser().role;

  const payload = Object();
  payload.from = moment().subtract(35, "days").startOf("day").toDate();
  payload.to = moment().subtract(7, "days").startOf("day").toDate();
  payload.weights = true;
  payload.ids = [userManager.getUser().id];

  const handleGetUserCurrentContact = async () => {
    const response = await makeRequest(
      getUserCurrentContact.bind(null, userManager.getUser().id)
    );
    if (response.data) {
      setCurrentContact(() => response.data);
    }
  };

  useLayoutEffect(() => {
    handleGetUserCurrentContact();
  }, []);

  return (
    <Wrapper>
      {(hasUnfilledRequest(getUserCurrentContact) ||
        hasUnfilledRequest(deleteFileById)) && <Loading />}
      <Gamification />
      <ButtonsContainer>
        {userManager?.getUser()?.calling_system && (
          <StartCalling />
        )}
        <Contact
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          textCurrent={translation["CurrentContact"]}
          textNew={translation["ImportNewContact"]}
        />
        <Inbound
          inboundCallText={translation["inboundCallText"]}
          downloadedContactText={translation["downloaded_contact_text"]}
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          importText={translation["ImportInboundContact"]}
          placeHolderText={translation["PlaceholderInbound"]}
        />
        <Create text={translation["CreateContact"]} />
      </ButtonsContainer>
      <TopViewContainer>
        <CallingStatisticsChart />
        <GradeChart />
        <ConfirmedToHoursChart />
      </TopViewContainer>
      <MiddleContainer>
        <Blackboard />
        <ConsultantQueueActions />
        {MANAGER_ROLE === userRole && (
          <ManagerStatsTable />
        )}
      </MiddleContainer>
    </Wrapper>
  );
};

export default ConsultantDashboard;
