import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import React from "react";
import { acceptConsultantMonthHours } from "@/API/repositories/consultantHours";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";

const MonthInfoForm = ({
  selectedMonth,
  showConsultantMonth,
  setShowConsultantMonth,
  consultantsFull,
  hanldeSearch,
}) => {
  const { makeRequest } = useRequestsContext();

  const workingFromDate = moment(selectedMonth.value).startOf("month").toISOString();
  const workingToDate = moment(selectedMonth.value).endOf("month").toISOString();

  const handleAcceptHoursFromEdit = async (e) => {
    e && e.preventDefault();
    const consultant = consultantsFull.find(
      (c) => c._id === showConsultantMonth._id
    );

    const monthWorkingInfo = showConsultantMonth._month_working_info;
    const fullConsultant = consultantsFull.find(
      (c) => c._id === showConsultantMonth._id
    );

    const payload = {};
    payload.contract_type = monthWorkingInfo?.contract_type || fullConsultant.contract_type;
    payload.working_from = (monthWorkingInfo?.working_from && moment(monthWorkingInfo.working_from).toISOString()) 
    || workingFromDate;
    payload.working_to = (monthWorkingInfo?.working_to && moment(monthWorkingInfo.working_to).toISOString())
    || workingToDate;
    payload.toConfirm = showConsultantMonth.data.map((element) => element._id);
    payload.consultant = consultant._id;
    payload.month = selectedMonth.value;
    payload.contract_hours = monthWorkingInfo?.contract_hours || fullConsultant.contract_hours || 0;
    payload.month_hours = monthWorkingInfo?.month_hours || fullConsultant.month_hours || 0;
    payload.additional_bonus = monthWorkingInfo?.additional_bonus || 0;

    setShowConsultantMonth(() => null);

    const response = await makeRequest(
      acceptConsultantMonthHours.bind(null, payload)
    );

    if (response.data) {
      await hanldeSearch(e);
    }
  };

  return (
    <>
      <div>
        <ButtonWrapper>
          <ActionButton
            defaultText={"Save && Accept hours"}
            onClick={(e) => handleAcceptHoursFromEdit(e)}
          />
        </ButtonWrapper>
      </div>
    </>
  );
};

export default MonthInfoForm;
