import React, { useEffect } from "react";
import Input from "../../../../common/components/Input";
import PopUp from "../../../../common/components/PopUp";
import SelectInput from "../../../../common/components/SelectInput";
import { formatToDateTimeFormaterTemplate } from "../../../../common/functions/dateFormater";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const AddPromotion = ({
  setPromotion,
  region,
  addPromotion,
  setAddPromotion,
  dateFromRegionRef,
  dateToRegionRef,
  handleAddPromotion,
  promotion,
}) => {
  return (
    <PopUp setShow={setPromotion}>
      <form
        onSubmit={(e) => handleAddPromotion(e)}
        style={{
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SelectInput
          name="Regions"
          width={80}
          options={region}
          selected={addPromotion}
          setSelected={setAddPromotion}
          multiple={true}
        />
        <Input
          inputRef={dateFromRegionRef}
          name="From"
          type="datetime-local"
          width={80}
          inputWidth={180}
          value={
            promotion?.from && formatToDateTimeFormaterTemplate(promotion.from)
          }
          required
        ></Input>
        <Input
          inputRef={dateToRegionRef}
          name="To"
          type="datetime-local"
          width={80}
          value={
            promotion?.to && formatToDateTimeFormaterTemplate(promotion.to)
          }
          inputWidth={180}
          required
        ></Input>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "-20px",
          }}
        >
          <SubmitButton text="Save" />
        </div>
      </form>
    </PopUp>
  );
};

export default AddPromotion;
