import { createContext, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import MessageQueue from "./components/MessageQueue";

export const NewMessageQueueContext = createContext();

export const NewMessageQueueProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const ref = useRef();
  ref.current = messages;

  function addMessage(caption, type = "info", timeout = 3000) {
    const id = uuid();
    
    setMessages(prev => [
      ...prev.slice(-1),
      {
        id,
        type,
        caption,
        timeout: setTimeout(() => {
          removeMessage(id);
        }, timeout),
      },
    ]);
  }

  function removeMessage(id) {
    setMessages(ref.current.filter((msg) => msg.id !== id));
  }

  return (
    <NewMessageQueueContext.Provider value={{
      addMessage,
    }}>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {children}
    </NewMessageQueueContext.Provider>
  );
}