import React, { useEffect, useState } from "react";
import { Colors } from "../../../common/colors/colors";
import { formatToDateTamplate } from "../../../common/functions/dateFormater";
import { useFormik } from "formik";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import moment from "moment";
import { getConsultantRecordedCalls } from "../../../API/repositories/calling";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import MediaPlayer from "../../../common/components/MediaPlayer";
import { consultantScoreResult } from "../../../API/repositories/listeningScoring";
import { average } from "../../../common/functions/average";
import { round } from "../../../common/functions/round";
import { createObjectionListeningTicket } from "../../../API/repositories/tickets";
import { GOOD_CALLING_THRESHOLD } from "@/common/constants/companies";
import {
  ButtonWrapperCenter,
  ScoreStatsWrapper,
  Text,
  Title,
  Wrapper,
} from "./consultantListeningCalls.styles";
import SearchBar from "./components/SearchBar";
import {
  TitleWrapper,
} from "../consultantFaq/consultantFaq.styles";
import { getRecordingById } from "@/API/repositories/storedDocument";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const ShowGrades = ({ grades, setShowGrades }) => {
  const [createTicket, setCreateTicket] = useState();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleCreateListeningTicket = async () => {
    const response = await makeRequest(
      createObjectionListeningTicket.bind(null, createTicket._id)
    );

    if (response.data) {
      addMessage("Ticket created", "success");
      setCreateTicket(() => null);
    } else {
      addMessage("Error", "error");
    }
  };

  return (
    <>
      <PopUp setShow={setShowGrades}>
        <Title>Your Scores:</Title>
        <table className="styled-table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Number</th>
              <th>Grade</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {grades.map((grade, i) => (
              <tr key={grade._id + i}>
                <td>{i + 1}.</td>
                <td>{moment(grade.created_at).format("DD/MM/YYYY HH:mm")}</td>
                <td>
                  {grade._calling_information.type === "outgoing"
                    ? grade._calling_information.to
                    : grade._calling_information.from}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    color: `${
                      round(grade.score) < GOOD_CALLING_THRESHOLD
                        ? "red"
                        : "green"
                    }`,
                  }}
                >
                  {round(grade.score)}%
                </td>
                <td>{grade._calling_information.call_history.status}</td>
                <td>
                  <i
                    className="fa-solid fa-question animation-scale"
                    style={{ cursor: "pointer" }}
                    onClick={() => setCreateTicket(grade)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PopUp>
      {createTicket && (
        <PopUp setShow={setCreateTicket}>
          <Text>
            Would you like to know a more detailed reason for the ratings? (This
            will create a ticket)
          </Text>
          <ButtonWrapperCenter>
            <ActionButton
              color={Colors.red}
              onClick={() => setCreateTicket(null)}
              defaultText="No"
            />
            <ActionButton
              color={Colors.green}
              onClick={() => handleCreateListeningTicket()}
              defaultText="Yes"
            />
          </ButtonWrapperCenter>
        </PopUp>
      )}
    </>
  );
};

const ConsultantListeningCalls = () => {
  const [data, setData] = useState([]);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [recording, setRecording] = useState(null);
  const [listeningScore, setListeningScore] = useState(null);
  const [listeningScoreAll, setListeningScoreAll] = useState(null);
  const [showGrades, setShowGrades] = useState(null);

  const { translation: translations} = useTranslationContext();

  const handleLoadData = async () => {
    const response = await makeRequest(consultantScoreResult);

    if (response.data) {
      const result = response.data.reduce((prev, next) => {
        const date = next.created_at;

        const yearWeek = `${moment(date)
          .startOf("week")
          .format("MM/DD/YYYY")}-${moment(date)
          .endOf("week")
          .format("MM/DD/YYYY")}`;
        if (!prev[yearWeek]) {
          prev[yearWeek] = [];
        }
        prev[yearWeek].push(next);
        return prev;
      }, {});

      setListeningScore(() => result);
      setListeningScoreAll(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  const handleGetRecording = async (e, element) => {
    e && e.preventDefault();

    const response = await makeRequest(
      getRecordingById.bind(null, element.stored_data)
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(() => url);
    }
  };

  const handleSearch = async (values) => {
    values.fromDate = moment(values.fromDate).startOf("day").toISOString();
    values.toDate = moment(values.toDate).endOf("day").toISOString();

    const payload = {
      fromDate: moment(values.fromDate).startOf("day").toISOString(),
      toDate: moment(values.toDate).endOf("day").toISOString(),
    };

    if (values.number) {
      payload.number = values.number;
    }

    const response = await makeRequest(
      getConsultantRecordedCalls.bind(null, payload)
    );

    if (response.data) {
      setData(() =>
        response.data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: formatToDateTamplate(new Date()),
      toDate: formatToDateTamplate(new Date()),
    },
    onSubmit: (values) => handleSearch(values),
  });

  return (
    <>
      {hasUnfilledRequest(
        getConsultantRecordedCalls,
        createObjectionListeningTicket,
        getRecordingById
      ) && <Loading />}
      <Wrapper>
        <TitleWrapper
          className="fade__in__animation"
          style={{ marginBottom: "-20px" }}
        >
          {translations["title"]}
        </TitleWrapper>
        <ScoreStatsWrapper className="fade__in__animation">
          <table className="styled-table">
            <thead>
              <tr>
                <th colSpan={6}>Scoring Table</th>
              </tr>
              <tr>
                <th>No.</th>
                <th>Week</th>
                <th>Min</th>
                <th>Max</th>
                <th>Average</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="queue">
              {listeningScore && listeningScoreAll ? (
                <>
                  {Object.entries(listeningScore)
                    .sort(
                      (a, b) =>
                        new Date(b[0].split("-")[0]) -
                        new Date(a[0].split("-")[0])
                    )
                    .map(([key, value], i) => (
                      <tr key={key}>
                        <td>{i + 1}</td>
                        <td>{key}</td>
                        <td>
                          {round(
                            Math.min.apply(
                              Math,
                              value.map((v) => v.score) || [0]
                            )
                          )}{" "}
                          %
                        </td>
                        <td>
                          {round(
                            Math.max.apply(
                              Math,
                              value.map((v) => v.score)
                            )
                          )}{" "}
                          %
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            color: `${
                              average(value.map((v) => v.score)) <
                              GOOD_CALLING_THRESHOLD
                                ? "red"
                                : "green"
                            }`,
                          }}
                        >
                          {average(value.map((v) => v.score))} %
                        </td>
                        <td>
                          <i
                            className="fa fa-eye animation-scale"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowGrades(value)}
                          />
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td>Sum</td>
                    <td>------</td>
                    <td>
                      {listeningScoreAll.length > 0
                        ? round(
                            Math.min.apply(
                              Math,
                              listeningScoreAll.map((v) => v.score)
                            )
                          )
                        : 0}{" "}
                      %
                    </td>
                    <td>
                      {listeningScoreAll.length > 0
                        ? round(
                            Math.max.apply(
                              Math,
                              listeningScoreAll.map((v) => v.score)
                            )
                          )
                        : 0}{" "}
                      %
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        color: `${
                          (listeningScoreAll.length > 0
                            ? average(listeningScoreAll.map((v) => v.score))
                            : 0) < GOOD_CALLING_THRESHOLD
                            ? "red"
                            : "green"
                        }`,
                      }}
                    >
                      {listeningScoreAll.length > 0
                        ? average(listeningScoreAll.map((v) => v.score))
                        : 0}{" "}
                      %
                    </td>
                    <td>------</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={6}>There is no data</td>
                </tr>
              )}
            </tbody>
          </table>
        </ScoreStatsWrapper>
        <SearchBar formik={formik} />
        {data[0] && (
          <table className="styled-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Consultant</th>
                <th>Calling start at</th>
                <th>Number</th>
                <th>Client Full name</th>
                <th>Recording</th>
              </tr>
            </thead>
            <tbody className="queue">
              {data
                ?.sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((elemnet, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{elemnet?.consultant?.username}</td>
                    <td>
                      {moment(elemnet.created_at).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      {elemnet.type === "incoming" ? elemnet.from : elemnet.to}
                    </td>
                    <td>{elemnet?.contact?.full_name || "------"}</td>
                    <td>
                      <i
                        className="fa fa-ear-listen animation-scale"
                        style={{ cursor: "pointer", color: Colors.red }}
                        onClick={(e) => handleGetRecording(e, elemnet)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Wrapper>
      {showGrades && (
        <ShowGrades
          grades={showGrades}
          setShowGrades={setShowGrades}
        />
      )}
      {recording && (
        <MediaPlayer
          noDownload={true}
          recording={recording}
          setClose={setRecording}
        />
      )}
    </>
  );
};

export default ConsultantListeningCalls;
