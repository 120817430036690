import PopUp from "@/common/components/PopUp";
import React from "react";
import {
  MarginBottomContainter,
  RightFlex,
  Title,
} from "../../ClaimsReport.styled";
import Input from "@/common/components/Input";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import { checkInvoiceCorrection } from "../../helpers/checkInvoiceCorrection";

const InvoiceCorrectionNumber = ({
  setOrderWithInvoice,
  handleUpdatingInvoiceCorrectionNumber,
  correctionNumberRef,
  orderWithInvoice,
}) => {
  return (
    <PopUp setShow={setOrderWithInvoice}>
      <MarginBottomContainter>
        <Title>Add correction number</Title>
      </MarginBottomContainter>
      <Input
        inputRef={correctionNumberRef}
        showLabel={false}
        color={Colors.darkBlue}
        width={180}
        value={
          checkInvoiceCorrection(orderWithInvoice)?.invoice.correction_number
        }
      />
      <RightFlex>
        <ActionButton
          defaultText="Save"
          onClick={() => handleUpdatingInvoiceCorrectionNumber()}
        />
      </RightFlex>
    </PopUp>
  );
};

export default InvoiceCorrectionNumber;
