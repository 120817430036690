import React, { useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import { ActionButtonsWrapper, InputsWrapper } from '../../PaymentReport.styled'
import Input from '@/common/components/Input'
import SelectInput from '@/common/components/SelectInput'
import CSV from '@/common/components/CSV'
import moment from 'moment'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { getEffectivePaymentReport } from '@/API/repositories/reports'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { getCSVData } from '../helpers/getCSVData'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import ActionButton from '@/common/components/buttons/ActionButton'

const SearchBar = ({ setData, data }) => {
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);

  const { 
    commonData: { queues, users },
    options: { queuesOptions, usersOptions} 
  } = useCommonDataContext();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const startDateRef = useRef();
  const endDateRef = useRef();

  const startDate = moment().subtract("1", "months").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  const handleGetReport = async () => {
    const payload = {};

    if (!selectedConsultants.length) {
      addMessage("Select consultants", "error");
      setData(() => null);

      return;
    }

    payload.from = startDateRef.current
      ? moment(startDateRef.current.value).startOf("day").toISOString()
      : moment(startDate).startOf("day").toISOString();
    payload.to = endDateRef.current
      ? moment(endDateRef.current.value).endOf("day").toISOString()
      : moment(endDate).endOf("day").toISOString();
    payload.ids = selectedConsultants.map((data) => data.value);

    const response = await makeRequest(
      getEffectivePaymentReport.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  const setSelectedQueue = (selectedQueues) => {
    let queueConsultants = [];

    selectedQueues.forEach((selectedQueue) => {
      const foundQueue = queues.find(
        (queue) => queue._id === selectedQueue.value._id
      );
      queueConsultants = [...queueConsultants, ...foundQueue.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((consultant) =>
        queueConsultants.includes(consultant.value)
      )
    );

    setSelectedQueues(selectedQueues);
  };

  return (
    <Card>
      <InputsWrapper>
        <div>
          <Input
            inputRef={startDateRef}
            name="confirmation date from"
            type="date"
            width={250}
            value={startDate}
            inputWidth={140}
          />
          <Input
            inputRef={endDateRef}
            name="confirmation date to"
            type="date"
            width={250}
            value={endDate}
            inputWidth={140}
          />
        </div>
        <div>
          <SelectInput
            multiple={true}
            name="Consultants"
            options={usersOptions}
            width={250}
            setSelected={setSelectedConsultants}
            selected={selectedConsultants}
          />
          <SelectInput
            multiple={true}
            name="Consultants from queue"
            options={queuesOptions}
            width={250}
            setSelected={setSelectedQueue}
            selected={selectedQueues}
          />{" "}
        </div>
      </InputsWrapper>
      <ActionButtonsWrapper>
        {data && (
          <CSV
            filename={"payment-report-all.csv"}
            header={[
              "Consultant",
              "Price",
              "Salary for one",
              "Confirmed orders",
              "Delivered orders",
              "Effective salary",
            ]}
            data={getCSVData(data, users)}
          />
        )}
        <ActionButton
          defaultText="Search"
          onClick={(e) => handleGetReport(e)}
        />
      </ActionButtonsWrapper>
    </Card>
  )
}

export default SearchBar
