import React from 'react'
import { MultilineProgresBar } from '../../../multilineProgresBar/MultilineProgresBar';
import { 
	AnswerName, 
	AnswersWrapper, 
	QuestionName,
	ButtonWrapper
} from '@/pages/consultant/consultantTests/ConsultantTests.styled';
import userManager from '@/API/userManager';
import ActionButton from '@/common/components/buttons/ActionButton';
import { Colors } from '@/common/colors/colors';

const NoResult = ({
	test,
	userQuestion,
	setUserQuestion,
	userAnswers,
	setUserAnswers,
	setCanNext,
	canNext,
	handleSave
}) => {
	return (
		<>
			<MultilineProgresBar
				max={test.questions.length}
				current={userQuestion}
			/>
			{test.questions.map(
				(question, i) =>
					i + 1 === userQuestion && (
						<div>
							<QuestionName>
								{question.translations.find(
									(t) =>
										userManager.getUser().lang === t.lang.toLowerCase()
								)?.value || question.value}
							</QuestionName>
							<AnswersWrapper>
								{question.answers.map((answer) => (
									<AnswerName
										selected={userAnswers.some(
											(ua) =>
												ua.question.id === question.id &&
												ua.answerId === answer.id
										)}
										className="animation-scale"
										onClick={() => {
											setCanNext(() => true);
											setUserAnswers((prev) => [
												...prev.filter(
													(p) => p.question.id !== question.id
												),
												{
													answerId: answer.id,
													correct: answer.is_correct,
													question: question,
												},
											]);
										}}
									>
										{answer.translations.find(
											(t) =>
												userManager.getUser().lang ===
												t.lang.toLowerCase()
										)?.value || answer.value}
									</AnswerName>
								))}
							</AnswersWrapper>
						</div>
					)
			)}
			<ButtonWrapper>
				<ActionButton
					onClick={() => setUserQuestion((prev) => prev - 1)}
					disabled={userQuestion <= 1}
					defaultText="Prev"
				/>
				{userQuestion === test.questions.length ? (
					<ActionButton
						color={Colors.green}
						onClick={() => handleSave()}
						defaultText="End Test"
					/>
				) : (
					<ActionButton
						onClick={() => {
							setUserQuestion((prev) => prev + 1);
							setCanNext(() => false);
						}}
						disabled={!canNext}
						defaultText="Next"
					/>
				)}
			</ButtonWrapper>
		</>
	)
}

export default NoResult
