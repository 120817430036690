import { StyledLink } from "@/common/styles/InteractionElements";

export const NavigateLink = ({ 
	to, 
	text, 
	color = "transparent", 
	tooltip,
	children,
	scale = 1,
	...rest
}) => {
	return (
		<StyledLink
			scale={scale}
			color={color} 
			to={to}
			tooltip={tooltip}
			{...rest}
		>
			{text}
			{children}
		</StyledLink>
	)
};
