import { Colors } from '@/common/colors/colors'
import Input from '@/common/components/Input'
import SelectInput from '@/common/components/SelectInput'
import { formatDate } from '@/common/functions/dateFormater'
import { SearchBarCenter } from '@/common/styles/SearchBars'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { ButtonsWrapper } from '../../DailyMarket.styled'
import { TYPE_OPTIONS } from './constants/typeOptions'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { getMarketsDailyReport } from '@/API/repositories/reports'
import Loading from '@/common/components/Loading'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import ActionButton from '@/common/components/buttons/ActionButton'

const SearchBar = ({ setData }) => {
  const [callingType, setCallingType] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const dateFromRef = useRef();
  const dateToref = useRef();

  const { addMessage } = useMessageQueueContext();
  const { options: { marketsOptions }} = useCommonDataContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();


  const hanldeSearch = async (e) => {
    e && e.preventDefault();

    if (!selectedMarkets.length) {
      addMessage("Select markets", "error");

      return;
    }

    if (!callingType.length) {
      addMessage("Select calling types", "error");

      return;
    }

    const today = moment();

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToref.current?.value || today;
    payload.markets = selectedMarkets.map((market) => market.value);
    payload.types = callingType.map((type) => type.value);

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(
      getMarketsDailyReport.bind(null, payload)
    );

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <SearchBarCenter>
      {hasUnfilledRequest(getMarketsDailyReport) && <Loading />}
      <div>
        <Input
          name="Download from"
          width={140}
          color={Colors.darkBlue}
          inputRef={dateFromRef}
          type="date"
          inputWidth={140}
          value={formatDate(moment())}
        />
        <Input
          name="Download to"
          width={140}
          color={Colors.darkBlue}
          inputRef={dateToref}
          type="date"
          inputWidth={140}
          value={formatDate(moment())}
        />
      </div>
      <div>
        <SelectInput
          name="Markets"
          options={marketsOptions}
          color={Colors.darkBlue}
          width={100}
          setSelected={setSelectedMarkets}
          selected={selectedMarkets}
          multiple={true}
          selectWidth={320}
        />
        <SelectInput
          name="Type"
          options={TYPE_OPTIONS}
          color={Colors.darkBlue}
          width={100}
          setSelected={setCallingType}
          selected={callingType}
          multiple={true}
          selectWidth={320}
        />
      </div>
      <ButtonsWrapper>
        <ActionButton 
          defaultText="Search" 
          onClick={(e) => hanldeSearch(e)} 
        />
        <ActionButton
          defaultText="Add All"
          onClick={(e) => setSelectedMarkets(marketsOptions)}
        />
      </ButtonsWrapper>
    </SearchBarCenter>
  )
}

export default SearchBar
