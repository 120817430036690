import React, { useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/SelectInput";
import Input from "@/common/components/Input";
import { PDF_TYPES } from "@/common/constants/pdfTypes";
import moment from "moment";
import { renameFile } from "./helpers/renameFile";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { compareVacationDaysToSelectedDays } from "./helpers/compareVacatoiinDaysToSelectedDays";
import {
  AddDocumentWrapper,
  SpaceBetween,
  UploadFileWrapper,
} from "@/pages/admin/ccHours/CCHours.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { getFileExtension } from "@/common/functions/getFileExtension";
import Loading from "@/common/components/Loading";
import { getOneUser, updateUser } from "@/API/repositories/user";
import { saveConsultantPdfToAws } from "@/API/repositories/storedDocument";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const AddDocument = ({
  setIsAddDocumentOpen,
  hanldeSearch,
  showConsultantMonth,
}) => {
  const [selectedPdfType, setSelectedPdfType] = useState();

  const fromDateRef = useRef();
  const toDateRef = useRef();
  const commentRef = useRef();
  const fileRef = useRef();

  const {
    commonData: { markets, users },
  } = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleUploadFile = async () => {
    if (!selectedPdfType) return addMessage("Select pdf type", "error");

    if (!fromDateRef.current?.value || !toDateRef.current?.value)
      return addMessage("Add all dates", "error");

    if (
      new Date(fromDateRef.current.value).getTime() >
      new Date(toDateRef.current.value).getTime()
    )
      return addMessage("Selected dates are wrong");

    const currentUserResponse = await makeRequest(
      getOneUser.bind(null, showConsultantMonth._id)
    );

    if (!currentUserResponse)
      return addMessage("Something went wrong", "error");

    const currentUser = currentUserResponse.data;

    const compareFunctionPayload = {};

    compareFunctionPayload.fromRef = fromDateRef;
    compareFunctionPayload.toRef = toDateRef;
    compareFunctionPayload.userId = showConsultantMonth._id;
    compareFunctionPayload.markets = markets;
    compareFunctionPayload.users = users;

    const daysAmount = compareVacationDaysToSelectedDays(
      compareFunctionPayload
    );

    if (
      currentUser.vacation_days - daysAmount < 0 &&
      selectedPdfType.value === "paid_vacation"
    )
      return addMessage("User doesn't have enough vacation days", "error");

    if (!commentRef.current?.value) return addMessage("Add comment", "error");

    if (!fileRef.current?.files[0]) return addMessage("Add file", "error");

    if (getFileExtension(fileRef.current?.files[0].name) !== "pdf")
      return addMessage("File must be in pdf format", "error");

    const username = showConsultantMonth._user.username;
    const name = username.split(".")[0];
    const surname = username.split(".")[1];

    const formData = new FormData();
    const file = renameFile(
      fileRef.current?.files[0],
      `${name}_${surname}_${fromDateRef.current.value}_${toDateRef.current.value}_${selectedPdfType.value}.pdf`
    );
    formData.append("file", file);

    const payload = {};

    payload.from = moment.utc(fromDateRef.current.value).format();
    payload.to = moment.utc(toDateRef.current.value).format();
    payload.comment = commentRef.current.value;
    payload.document_type = selectedPdfType.value;
    payload.days_amount = daysAmount;
    payload.ticket_status = "accepted";
    payload.consultantId = showConsultantMonth._id;

    const response = await makeRequest(
      saveConsultantPdfToAws.bind(null, payload, formData)
    );

    if (response.data !== true) {
      const message = response.data || "Something went wrong!";
      return addMessage(message, "error");
    }

    if (selectedPdfType.value === "paid_vacation") {
      currentUser.vacation_days = currentUser.vacation_days - daysAmount;
      await makeRequest(updateUser.bind(null, currentUser._id, currentUser));
    }

    addMessage("Saved", "success");
    hanldeSearch(null, true);
    setIsAddDocumentOpen(() => false);
    setSelectedPdfType(() => null);
  };

  return (
    <>
      {hasUnfilledRequest(saveConsultantPdfToAws) && <Loading />}
      <PopUp setShow={setIsAddDocumentOpen}>
        <AddDocumentWrapper>
          <SelectInput
            options={PDF_TYPES}
            showLabel={false}
            placeholder="Add file type"
            selected={selectedPdfType}
            setSelected={setSelectedPdfType}
          />
          <SpaceBetween>
            <Input type="date" name="From" inputRef={fromDateRef} width="50" />
            <Input type="date" name="To" inputRef={toDateRef} width="30" />
          </SpaceBetween>
          <Input
            showLabel={false}
            placeholder="Leave your comment"
            inputRef={commentRef}
          />
          <UploadFileWrapper>
            <input ref={fileRef} type="file" accept="application/pdf" />

            <ActionButton
              onClick={(e) => handleUploadFile(e)}
              defaultText="Upload"
            />
          </UploadFileWrapper>
        </AddDocumentWrapper>
      </PopUp>
    </>
  );
};

export default AddDocument;
