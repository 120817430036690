import Table from "@/common/components/Table";
import React from "react";
import { getRaws } from "./helpers/getRaws";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ManagerSummarize = ({ summarizeData }) => {
  const { translation } = useTranslationContext();

  const headersArray = [
    [<th colSpan={6}>{translation.manager_payment_summarize}</th>],
    [
      <th>{translation.month}</th>,
      <th>{translation.Hours}</th>,
      <th>{translation.hours_payment}</th>,
      <th>{translation.effective_payment}</th>,
      <th>{translation.payment}</th>,
      <th>{translation.equalization}</th>,
    ],
  ];

  return (
    <Table
      className="styled-table"
      headersArray={headersArray}
      raws={getRaws(summarizeData)}
    />
  );
};

export default ManagerSummarize;
