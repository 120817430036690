import React from "react";
import { Colors } from "../../colors/colors";
import { StyledButton, StyledSecondaryButton } from "@/common/styles/InteractionElements";

const getButtonType = (buttonType) => {
  switch (buttonType) {
    case "secondary":
      return StyledSecondaryButton;
    default:
      return StyledButton;
  }
}

const ActionButton = ({
  text = "",
  defaultText,
  color = Colors.orange,
  tooltip = null,
  children,
  buttonType = "primary",
  ...rest
}) => {
  const ButtonType = getButtonType(buttonType);

  return (
    <ButtonType
      type="button"
      tooltip={tooltip}
      color={color}
      {...rest}
    >
      {children}
      {text.toLowerCase().replaceAll("add", "")}
      {text.toLowerCase().includes("add") && <i className="fa fa-plus" />}
      {defaultText}
    </ButtonType>
  );
};

export default ActionButton;
