import {
  createDepartment,
  getDepartments,
  updateDepartment,
} from "@/API/repositories/department";
import Loading from "@/common/components/Loading";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { PageWrapper } from "@/common/styles/Wrappers";
import React, { useEffect, useState } from "react";
import { ContentWrapper } from "./Department.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import DepartmentTable from "./components/departmentTable/DepartmentTable";
import DepartmentForm from "./components/departmentForm/DepartmentForm";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const Department = () => {
  const [departments, setDepartments] = useState();
  const [isDepartmentFormOpen, setIsDepartmentFormOpen] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setDepartments(() => response.data);
  };

  const handleSetDepartmentEdit = (dep) => {
    setSelectedDepartment(() => dep);
    setIsDepartmentFormOpen(() => true);
  };

  const handleCloseForm = () => {
    setIsDepartmentFormOpen(() => false);
    setSelectedDepartment(() => null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getDepartments,
        createDepartment,
        updateDepartment
      ) && <Loading />}
      <ContentWrapper>
        <ActionButton
          style={{ height: "fit-content" }}
          defaultText="Add department"
          onClick={() => setIsDepartmentFormOpen(true)}
        />
      </ContentWrapper>
      {isDepartmentFormOpen && (
        <DepartmentForm
          selectedDepartment={selectedDepartment}
          setShow={handleCloseForm}
          reload={loadData}
        />
      )}
      {!!departments?.length && (
        <DepartmentTable
          handleSetDepartmentEdit={handleSetDepartmentEdit}
          departments={departments}
        />
      )}
    </PageWrapper>
  );
};

export default Department;
