import Input from '@/common/components/Input'
import PopUp from '@/common/components/PopUp'
import React, { useState } from 'react'
import { ButtonWrapper, CopyItem, InfoItem, InfoItemLabel, InfoItemValue } from '../../../ValidClaimForm.styled'
import ActionButton from '@/common/components/buttons/ActionButton'
import { Colors } from '@/common/colors/colors'
import Icon from '@/common/components/Icon'
import { sumByField } from '@/common/functions/sumByField'
import moment from 'moment'
import { ClAIM_METHODS } from '@/common/constants/validClaims'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { InputsWrapper } from './ShowPayDate.styled'
import { ING_CURRENCIES, MONEY_TRANSFER } from './constants/moneyTransfer'
import { useValidClaim } from '@/common/hooks/useValidClaim'

const ShowPayDate = ({ setShow, handleSubmit }) => {
  const [copied, setCopied] = useState();

  const { addMessage } = useMessageQueueContext();
  const {
    payDateRef,
    claim,
    contact,
    claimMethod,
  } = useValidClaim();

  const copy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(value);
    setTimeout(() => setCopied(null), 200);
    addMessage("Copied", "success", 500)
  }

  const isING = ING_CURRENCIES.includes(claim?.currency)

  return (
    <PopUp
      minWidth='360px'
      crossHeight='20px'
      padding='8px 20px 10px'
      setShow={setShow}
    >
      <InputsWrapper>
        {!isING && ClAIM_METHODS.BANK_TRANSFER.value === claimMethod && (
          <Input
            fontSize='12px'
            inputFontSize='12px'
            type="datetime-local"
            inputRef={payDateRef}
            color={Colors.darkBlue}
            name="Pay date"
            width={60}
            margin='0'
            inputWidth={160}
            min={moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm')}
            value={payDateRef?.current?.value || moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm')}
          />
        )}
        {isING && ClAIM_METHODS.BANK_TRANSFER.value === claimMethod && (
          <>
            <InfoItem>
              <InfoItemLabel>
                IBAN:
              </InfoItemLabel>
              <InfoItemValue
                cursor="pointer"
                onClick={() => copy(claim.iban)}
              >
                <CopyItem isCopied={copied === claim.iban}>
                  {claim.iban}
                </CopyItem>
                <Icon name="fa fa-copy" />
              </InfoItemValue>
            </InfoItem>
            <InfoItem>
              <InfoItemLabel>
                Full name:
              </InfoItemLabel>
              <InfoItemValue
                cursor="pointer"
                onClick={() => copy(claim._contact.full_name)}
              >
                <CopyItem isCopied={copied === claim._contact.full_name}>
                  {claim._contact.full_name}
                </CopyItem>
                <Icon name="fa fa-copy" />
              </InfoItemValue>
            </InfoItem>
            <InfoItem>
              <InfoItemLabel>
                Amount:
              </InfoItemLabel>
              <InfoItemValue
                cursor="pointer"
                onClick={() => copy(`${sumByField(claim.order_items, 'price')}`)}
              >
                <CopyItem isCopied={copied === `${sumByField(claim.order_items, 'price')}`}>
                  {sumByField(claim.order_items, 'price')} {claim.currency}
                </CopyItem>
                <Icon name="fa fa-copy" />
              </InfoItemValue>
            </InfoItem>
            <InfoItem>
              <InfoItemLabel>
                Title:
              </InfoItemLabel>
              <InfoItemValue
                cursor="pointer"
                onClick={() => copy(MONEY_TRANSFER[contact.country.toUpperCase()])}
              >
                <CopyItem isCopied={copied === MONEY_TRANSFER[contact.country.toUpperCase()]}>
                  {MONEY_TRANSFER[contact.country.toUpperCase()]}
                </CopyItem>
                <Icon name="fa fa-copy" />
              </InfoItemValue>
            </InfoItem>
          </>)}
      </InputsWrapper>
      <ButtonWrapper justify="flex-end">
        <ActionButton
          onClick={() => handleSubmit(true)}
          defaultText="Save"
        />
      </ButtonWrapper>
    </PopUp>
  )
}

export default ShowPayDate
