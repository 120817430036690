import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./common/contexts/authContext";
import "bootstrap-icons/font/bootstrap-icons.css";
import { RequestsProvider } from "./common/contexts/requestsContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { CommonDataProvider } from "./common/contexts/commonDataContext";
import { NewMessageQueueProvider } from "./common/contexts/messageQueueContext/messageQueueContext";
import { TranslationProvider } from "./common/contexts/translationContext";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <RequestsProvider>
        <AuthProvider>
          <CommonDataProvider>
            <NewMessageQueueProvider>
              <TranslationProvider>
                <App />
              </TranslationProvider>
            </NewMessageQueueProvider>
          </CommonDataProvider>
        </AuthProvider>
      </RequestsProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
