import React, { useRef, useState } from "react";
import styled from "styled-components";
import Loading from "../../../../common/components/Loading";

import { useRequestsContext } from "../../../../common/hooks/requestHook";
import slovakFlag from "../../../../images/flags/slowacki_o.png";
import romanianFlag from "../../../../images/flags/rumunski_o.png";
import czechFlag from "../../../../images/flags/czeski_o.png";
import testFlag from "../../../../images/flags/angielski.png";
import hungarianFlag from "@/images/languages-flags/HU.jpg";
import lithuanianFlag from "@/images/flags/litewski_o.png";
import croatianFlag from "@/images/flags/chorwacja_o.png";
import Input from "../../../../common/components/Input";
import SelectInput from "../../../../common/components/SelectInput";
import PopUp from "../../../../common/components/PopUp";
import { Colors } from "../../../../common/colors/colors";
import {
  markContactAsPostalCodePromotion,
  updateContactFromOutbound,
} from "../../../../API/repositories/contact";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import ActionButton from "@/common/components/buttons/ActionButton";
import { DISABLED_TOOLTIP } from "@/common/constants/suspicion";
import { validatePhoneForCourier } from "@/common/functions/validatePhoneForCourier";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const Wrapper = styled.div`
  height: fit-content;
  border-radius: 20px;
  padding: 20px;
  background-color: ${Colors.lightlightBlue};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
`;

const ContactOutbound = ({
  contact,
  setContact,
  market,
  shouldDisableSuspicion,
  setShouldDisableSuspicion,
}) => {
  const { COUNTRIES } = useCommonDataContext();
  const { translation } = useTranslationContext();
  
  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRIES.find((country) => country.value === contact.country)
  );
  const [isSave, setIsSave] = useState(true);
  const [promotionResult, setPromotionResult] = useState();
  const unSaveSet = new Set();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const fullNameRef = useRef();
  const emailRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const additionalInfoRef = useRef();
  const phoneForCourier = useRef();

  const handlerSaveBasicInfo = async (e) => {
    e.preventDefault();

    const isPhoneError = phoneForCourier.current.value 
      && !validatePhoneForCourier(phoneForCourier.current.value)

    if (isPhoneError) {
      return addMessage("Phone for courier must consist of digits, spaces and hyphens", "error");
    }

    const payload = {};

    payload.full_name = fullNameRef.current.value
      ? fullNameRef.current.value
      : contact.full_name;
    payload.email = emailRef.current.value
      ? emailRef.current.value
      : contact.email;
    payload.country = selectedCountry.value;
    payload.postal_code = postalCodeRef.current.value
      ? postalCodeRef.current.value
      : contact.postal_code;
    payload.city = cityRef.current.value ? cityRef.current.value : contact.city;
    payload.street = streetRef.current.value
      ? streetRef.current.value
      : contact.street;
    payload.additionalInfo = additionalInfoRef.current.value
      ? additionalInfoRef.current.value
      : contact.additional_info;
    payload.phone_number_for_courier = phoneForCourier.current.value;

    const response = await makeRequest(
      updateContactFromOutbound.bind(null, contact._id, payload)
    );

    if (response.data) {
      unSaveSet.clear();
      setIsSave(() => true);
      setContact(() => response.data);
    }
  };

  const handleCheckPromotion = async () => {
    let hasPromotion = {
      region: null,
      message: translation.promNotFound,
      isGood: false,
    };

    try {
      market.regions.forEach((m) => {
        const postalCode = parseInt(contact.postal_code.substring(0, 3));

        const foundCode = m.codes.find(
          (c) => c.from <= postalCode && c.to >= postalCode
        );

        if (foundCode) {
          hasPromotion.region = m;
          hasPromotion.message =
            translation.promNotAvaiableTitle +
            " " +
            m.label +
            " " +
            translation.promNotAvaiable;
        }
      });

      market.promotion.forEach((prom) => {
        const isPromoOK = prom.regions.includes(hasPromotion.region._id) &&
          new Date(prom.from) - new Date(contact.created_at) <= 0 &&
          new Date(prom.to) - new Date(contact.created_at) >= 0;
      
        if (isPromoOK) {
          hasPromotion.isGood = true;
          hasPromotion.message =
            translation.promAvaialbleTitle +
            " " +
            hasPromotion.region.label.toUpperCase() +
            " " +
            translation.promAvaiable;
        }
      });
    } catch (e) {
      console.log(e.message);
    }

    const response = await makeRequest(
      markContactAsPostalCodePromotion.bind(
        null,
        contact._id,
        hasPromotion.isGood
      )
    );

    setContact(() => response.data);
    setPromotionResult(() => hasPromotion);
  };

  const findFalg = (country) => {
    switch (country) {
      case "ro":
        return romanianFlag;
      case "sk":
        return slovakFlag;
      case "cz":
        return czechFlag;
      case "hu":
        return hungarianFlag;
      case "lt":
        return lithuanianFlag;
      case "hr":
        return croatianFlag;
      default:
        return testFlag;
    }
  };

  const handleUnSave = () => {
    setIsSave(() => false);
  };

  const handleCreateSuspicionTicket = async () => {
    const response = await makeRequest(
      createSuspicionTicket.bind(null, contact._id)
    );
    setShouldDisableSuspicion(true);

    if (!response?.data) {
      return addMessage("Cannot submit", "error");
    }
    addMessage("Success", "success");
  };

  return (
    <Wrapper>
      <Flex>
        <div style={{ width: "100px", height: "80px", overflow: "hidden" }}>
          <img
            src={findFalg(contact.country)}
            alt={contact.country}
            style={{ width: "100%", height: "auto" }}
          ></img>
        </div>
        <h1 style={{ fontSize: "35px" }}>
          {contact.country === "cz"
            ? "+420 "
            : contact.country === "hu"
            ? "+36 "
            : contact.country === "ro"
            ? "+40 "
            : contact.country === "sk"
            ? "+421 "
            : contact.country === "hr"
            ? "+385"
            : contact.country === "lt"
            ? "+370"
            : "+44 "}
          {contact.phone_number
            .match(/.{1,3}/g)
            .map((substring) => substring + " ")}
        </h1>
      </Flex>
      <form onSubmit={(e) => handlerSaveBasicInfo(e)}>
        <Flex>
          <div>
            <Input
              onChange={handleUnSave}
              inputRef={fullNameRef}
              name={translation.fullName}
              width={160}
              inputWidth={280}
              value={contact.full_name ? contact.full_name : ""}
            />
            <Input
              onChange={handleUnSave}
              inputRef={emailRef}
              name={translation.email}
              width={160}
              inputWidth={280}
              value={contact.email ? contact.email : ""}
            />
            <SelectInput
              width={160}
              name={translation.country}
              selected={selectedCountry}
              setSelected={(value) => {
                setSelectedCountry(value);
                setIsSave(false);
              }}
              options={COUNTRIES}
              selectWidth={280}
            />
            <Input
              onChange={handleUnSave}
              inputRef={additionalInfoRef}
              name={translation.additionalInfo}
              width={160}
              inputWidth={280}
              value={contact.additional_info ? contact.additional_info : ""}
            />
          </div>
          <div>
            <Input
              onChange={handleUnSave}
              inputRef={postalCodeRef}
              name={translation.postalCode}
              width={160}
              inputWidth={280}
              value={contact.postal_code ? contact.postal_code : ""}
              requiredSign
            />
            <Input
              onChange={handleUnSave}
              inputRef={cityRef}
              name={translation.city}
              width={160}
              inputWidth={280}
              value={contact.city ? contact.city : ""}
              requiredSign
            />
            <Input
              onChange={handleUnSave}
              inputRef={streetRef}
              name={translation.street}
              width={160}
              inputWidth={280}
              value={contact.street ? contact.street : ""}
              requiredSign
            />
            <Input
              onChange={handleUnSave}
              inputRef={phoneForCourier}
              name={translation.phone_number_for_courier}
              width={160}
              inputWidth={280}
              value={contact.phone_number_for_courier}
            />
          </div>
        </Flex>
        <ButtonWrapper>
          {contact.country === "cz" && (
            <div>
              <ActionButton
                color={Colors.blue}
                onClick={(e) => handleCheckPromotion(e)}
                disabled={!contact.postal_code}
                defaultText={translation.checkProm}
              />
            </div>
          )}
          <div>
            <ActionButton
              tooltip={shouldDisableSuspicion ? DISABLED_TOOLTIP : null}
              onClick={() => handleCreateSuspicionTicket()}
              defaultText={translation.suspect}
              color={Colors.red}
              disabled={shouldDisableSuspicion}
            />
          </div>
          <div>
            <SubmitButton
              color={!isSave ? Colors.red : Colors.orange}
              type="submit"
              style={{
                margin: "10px 0px",
                width: "100px",
                fontSize: "19px",
              }}
              text={translation.save}
            />
          </div>
        </ButtonWrapper>
      </form>
      {promotionResult && (
        <PopUp setShow={setPromotionResult}>
          <h3
            style={{
              color: promotionResult.isGood ? Colors.green : Colors.red,
              maxWidth: "400px",
              textAlign: "center",
            }}
          >
            {promotionResult.message}
          </h3>
        </PopUp>
      )}
      {hasUnfilledRequest(updateContactFromOutbound) && <Loading />}
    </Wrapper>
  );
};

export default ContactOutbound;
