import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 90vh;
`;

export const CardWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

export const AddOrdersWrapper = styled.div`
  display: flex;
  margin: 20px;
  justify-content: flex-end;
  gap: 20px;
`;


export const ContactTableWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const PromotionResultTitle = styled.h3`
	color: ${({ isGood }) => (isGood ? Colors.green : Colors.red)};
	max-width: 400px;
	text-align: center;
`;

