import { sumByField } from "@/common/functions/sumByField";

export const getRaws = (data, monthWorkingData, workingDays) => {
  return [
    <tr>
      <td>
        <strong>{sumByField(data, "attendance_at_work")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "holiday_leave")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "special_leave")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "sick_leave")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "break_during_the_day")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "excused_unpaid_leave")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "absence_without_excuse")}h</strong>
      </td>
      <td>
        <strong>{sumByField(data, "manager_hours")}h</strong>
      </td>
      <td>
        <strong>
          {data?.reduce(
            (prev, next) =>
              prev +
              ((next.attendance_at_work || 0) +
                (next.holiday_leave || 0) +
                (next.sick_leave || 0) +
                (next.manager_hours || 0) +
                (next.special_leave || 0)) +
              (next.excused_unpaid_leave || 0),
            0
          )}
          h / {(monthWorkingData?.contract_hours || 8) * workingDays}h
        </strong>
      </td>
    </tr>,
  ];
};
