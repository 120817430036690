import { getValidClaimsReport } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import Table from "@/common/components/Table";
import { useRequestsContext } from "@/common/hooks/requestHook";
import moment from "moment";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { getHeadersArray } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import {
  Container,
  HeadButtonsContainer,
  HeadingWrapper,
  TableWrapper,
} from "./ValidClaimsReport.styled";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import Icon from "@/common/components/Icon";
import { generateZip } from "./helpers/generateZip";
import { filterReducer } from "./helpers/reducer";
import { DEFAULT_FILTER, FILTER_ACTIONS } from "./constants/filter";
import ShowAction from "./components/showAction/ShowAction";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import SendAccountingPopUp from "./components/sendAccountingPopUp/SendAccountingPopUp";
import { generateFiles } from "./helpers/generateFiles";

const ValidClaimsReport = () => {
  const [filters, dispatch] = useReducer(filterReducer, DEFAULT_FILTER);
  const [action, setAction] = useState(null);
  const [data, setData] = useState();
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[1]);
  const [isSendAccountingPopUp, setIsSendAccountingPopUp] = useState(false);

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadData = async () => {
    const payload = {
      month: moment(selectedMonth.value, "MMMM YYYY").format("YYYY-MM"),
    };

    const response = await makeRequest(
      getValidClaimsReport.bind(null, payload)
    );

    if (!response?.data) {
      return addMessage("Unable to load data", "error");
    }

    setData(response.data);
  };

  const handleSelectMonth = (month) => {
    if (month.value !== selectedMonth.value) {
      setData(null);
    }

    setSelectedMonth(month);
  };

  const visibleData = useMemo(
    () =>
      data?.filter((item) => {
        const availabelMarkets = filters[FILTER_ACTIONS.SET_MARKETS]?.map(
          ({ value }) => value
        );
        if (
          availabelMarkets.length &&
          !availabelMarkets.includes(item.order.market)
        ) {
          return false;
        }

        const availabelCurrencies = filters[FILTER_ACTIONS.SET_CURRENCIES]?.map(
          ({ value }) => value
        );

        if (
          availabelCurrencies.length &&
          !availabelCurrencies.includes(item.transfers.at(-1).currency)
        ) {
          return false;
        }

        return true;
      }),
    [filters, data]
  );

  const downloadZipHandler = async () => {
    const files = await generateFiles(visibleData, selectedMonth.value);
    generateZip(files, selectedMonth.value);
  };

  useEffect(() => {
    loadData();
  }, [selectedMonth]);

  return (
    <Container>
      {hasUnfilledRequest(getValidClaimsReport) && <Loading />}
      <HeadingWrapper>
        <div />
        <SelectInput
          name="Month"
          color={Colors.darkBlue}
          options={LAST_YEAR_MONTHS.filter((month) =>
            moment(month.value, "MMMM YYYY").isAfter(moment("2024-09-30"))
          )}
          selected={selectedMonth}
          setSelected={handleSelectMonth}
        />
        <HeadButtonsContainer>
          {filters &&
            Object.values(filters)?.some((filter) => filter.length) && (
              <ActionButton
                defaultText={"Reset filters"}
                onClick={() => dispatch({ type: FILTER_ACTIONS.RESET })}
              />
            )}
          {!!visibleData?.length && (
            <>
              <ActionButton
                defaultText={"Wyślij do księgowości"}
                onClick={() => setIsSendAccountingPopUp(true)}
              />
              <Icon
                size={32}
                name="fa fa-file-zipper"
                onClick={downloadZipHandler}
              />
            </>
          )}
        </HeadButtonsContainer>
      </HeadingWrapper>
      <TableWrapper>
        {!!visibleData?.length && (
          <Table
            stickyHeader
            className="styled-table"
            headersArray={getHeadersArray(setAction, filters)}
            raws={getRaws(visibleData)}
          />
        )}
      </TableWrapper>
      {action && (
        <ShowAction
          setAction={setAction}
          filters={filters}
          action={action}
          dispatch={dispatch}
        />
      )}
      {isSendAccountingPopUp && (
        <SendAccountingPopUp
          onClose={() => setIsSendAccountingPopUp(false)}
          data={visibleData}
          month={selectedMonth.value}
        />
      )}
    </Container>
  );
};

export default ValidClaimsReport;
