import { Colors } from '@/common/colors/colors'
import PopUp from '@/common/components/PopUp'
import React from 'react'
import { Wrapper, ButtonsWrapper } from './MarkAsRedo.styled'
import ActionButton from '@/common/components/buttons/ActionButton'

const MarkAsRedo = ({ setMarkAsRodo, handleMakeRodo }) => {
	return (
		<PopUp setShow={setMarkAsRodo}>
			<Wrapper>
				<h3 style={{ color: Colors.darkBlue }}>
					Are you sure to{" "}
					<span style={{ color: "red", fontWeight: "bold" }}>DELETE</span>{" "}
					client data?
				</h3>
				<ButtonsWrapper>
					<ActionButton
						color={Colors.green}
						onClick={() => setMarkAsRodo(null)}
						defaultText="No"
					/>
					<ActionButton
						color={Colors.red}
						onClick={(e) => handleMakeRodo(e)}
						defaultText="Yes"
					/>
				</ButtonsWrapper>
			</Wrapper>
		</PopUp>
	)
}

export default MarkAsRedo
