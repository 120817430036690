import React, { useRef } from 'react'
import { SearchBar } from './MailSearchBar.styled'
import Input from '@/common/components/Input'
import moment from 'moment';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { getMailsByFilter } from '@/API/repositories/mail';
import ActionButton from '@/common/components/buttons/ActionButton';

const MailSearchBar = ({
	setShowFilters,
	setData,
	filters
}) => {
	const { mailTemplate, consultant, bouncedType } = filters;
	const createdFromRef = useRef();
  const createdToRef = useRef();

	const { makeRequest } = useRequestsContext();

	const handleSearch = async (e) => {
    e.preventDefault();

    const payload = { ...filters };
    payload.createdFrom =
      createdFromRef.current?.value || moment().startOf("day");
    payload.createdTo = createdToRef.current?.value || moment().endOf("day");
    payload.mailTemplate = mailTemplate?.length ? mailTemplate.map((template) => template.value) : null;
    payload.consultant =  consultant?.length ? consultant.map((consultant) => consultant.value): null;
    payload.bouncedType = bouncedType?.length ? bouncedType?.map((bounce) => bounce.value) : null;

    const response = await makeRequest(getMailsByFilter.bind(null, payload));

    if (response.data) {
      setData(() => response.data);
    }
  };
	
	return (
		<SearchBar>
			<h3>Mail Search:</h3>
			<Input
				inputRef={createdFromRef}
				name="Created from"
				type="date"
				width={140}
				inputWidth={140}
			/>
			<Input
				inputRef={createdToRef}
				name="Created to"
				type="date"
				width={140}
				inputWidth={140}
			/>
			<div>
				<ActionButton
					onClick={() => setShowFilters(true)}
					defaultText="Options"
				/>
			</div>
			<div>
				<ActionButton defaultText="Search" onClick={(e) => handleSearch(e)} />
			</div>
		</SearchBar>
	)
}

export default MailSearchBar
