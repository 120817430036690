import React, { useState } from "react";
import styled from "styled-components";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";
import InvoiceTicket from "@/common/components/invoiceTicket/InvoiceTicket";
import CreateNewTicket from "@/pages/consultant/dashboard/panel/ticket/createNewTicket/CreateNewTicket";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const H5 = styled.strong`
  color: ${Colors.darkBlue};
  width: 110px;
  text-align: right;
  font-size: 24px;
  margin-top: 10px;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 80px;

  input::file-selector-button {
    border: 2px solid ${Colors.darkBlue};
    border-radius: 15px;
    background-color: transparent;
    font-style: italic;
    color: ${Colors.darkBlue};
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const TicketsForCalling = ({ orderId, market }) => {
  const [showQuestionTicket, setShowQuestionTicket] = useState();
  const [showInvoiceTicket, setShowInvoiceTicket] = useState();
  const [success, setSuccess] = useState();

  return (
    <>
      {showQuestionTicket && (
        <CreateNewTicket
          orderId={orderId}
          setShowQuestionTicket={setShowQuestionTicket}
        />
      )}
      <Wrapper>
        <div>
          <ActionButton
            color={success === "question" ? Colors.green : Colors.orange}
            onClick={() => setShowQuestionTicket(true)}
          >
            <i className="fa fa-question" style={{ marginRight: "10px" }}></i>
            {success === "question" ? "Success" : "Question"}
          </ActionButton>
        </div>
        <div>
          <ActionButton
            onClick={() => setShowInvoiceTicket(true)}
          >
            <i
              className="fa fa-file-invoice"
              style={{ marginRight: "10px" }}
            />
            {success === "invoice" ? "Success" : "Invoice"}
          </ActionButton>
        </div>
        {showInvoiceTicket && (
          <InvoiceTicket
            market={market}
            orderId={orderId}
            setShowInvoiceTicket={setShowInvoiceTicket}
          />
        )}
      </Wrapper>
    </>
  );
};

export default TicketsForCalling;
