import { CheckBoxInputsWrapper, SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React, { useMemo, useRef, useState } from 'react'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'
import { CLAIM_STATUSES } from '@/common/constants/claims'
import AddMessage from './components/addMessage/AddMessage'
import userManager from '@/API/userManager'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import { useValidClaim } from '@/common/hooks/useValidClaim'

const ClaimsStatus = ({ handleValidClaimUpdate }) => {
  const [showAddMessage, setShowAddMessage] = useState(false);

  const { addMessage } = useMessageQueueContext();
  const { claimStatus, claim, order } = useValidClaim();

  const currency = claim?.currency || order.shipping?.currency || order.currency;

  const messageRef = useRef();
  const failDateRef = useRef();

  const { id, role } = userManager.getUser();

  const isFailDateRequired = useMemo(() =>
    claimStatus === CLAIM_STATUSES.CHECK_IN_PROGRESS.value && currency === "eur",
    [claimStatus, currency]);

  const handleClaimStatusChange = async (status, message) => {
    if (claimStatus === status) {
      return;
    }

    const isMessageRequired = Object.values(CLAIM_STATUSES).find(s => s.value === status).message_required;

    if (!isMessageRequired) {
      await handleValidClaimUpdate({ status });
      return
    }

    if (!message) {
      addMessage("Message is required", "info", 1000);
      return setShowAddMessage(status);
    }
    const newMessage = {
      original: messageRef.current.value,
      created_at: new Date(),
      sender: id,
      status,
    }

    await handleValidClaimUpdate({
      message: newMessage, status, failed_at: failDateRef.current?.value
    });
  }

  return (
    <SectionWrapper>
      <Subtitle>Claim status:</Subtitle>
      <CheckBoxInputsWrapper>
        {Object.values(CLAIM_STATUSES).map(status => (
          <CheckBoxInput
            disabled={!status.allow_from.includes(claimStatus) || status.from_roles_blocked.includes(role)}
            handleSelect={() => handleClaimStatusChange(status.value)}
            checked={claimStatus === status.value}
            hideCheckMark
            text={status.label}
          />
        ))}
      </CheckBoxInputsWrapper>
      {showAddMessage && (
        <AddMessage
          send={handleClaimStatusChange}
          messageRef={messageRef}
          status={showAddMessage}
          setShow={setShowAddMessage}
          failDateRef={failDateRef}
          requireFailDate={isFailDateRequired}
        />
      )}
    </SectionWrapper>
  )
}

export default ClaimsStatus
