export const ACTIONS = {
  SET_CUSTOMER: "customer",
  SET_CONSULTANT: "consultant",
  SET_MAIL_TEMPLATE: "mailTemplate",
  SET_OPEN_COUNT_FROM: "openCountFrom",
  SET_OPEN_COUNT_TO: "openCountTo",
  SET_CLICK_COUNT_FROM: "clickCountFrom",
  SET_CLICK_COUNT_TO: "clickCountTo",
  SET_SPAM_REPORTS_FROM: "spamReportsFrom",
  SET_SPAM_REPORTS_TO: "spamReportsTo",
  SET_CREATED_DATE_FROM: "createdDateFrom",
  SET_CREATED_DATE_TO: "createdDateTo",
  SET_PROCESSED_DATE_FROM: "processedDateFrom",
  SET_PROCESSED_DATE_TO: "processedDateTo",
  SET_DELIVERED_DATE_FROM: "deliveredDateFrom",
  SET_DELIVERED_DATE_TO: "deliveredDateTo",
  SET_DROPPED_DATE_FROM: "droppedDateFrom",
  SET_DROPPED_DATE_TO: "droppedDateTo",
  SET_BOUNCED_DATE_FROM: "bouncedDateFrom",
  SET_BOUNCED_DATE_TO: "bouncedDateTo",
  SET_BOUNCED_TYPE: "bouncedType",
  SET_FIRST_OPEN_DATE_FROM: "firstOpenDateFrom",
  SET_FIRST_OPEN_DATE_TO: "firstOpenDateTo",
  SET_FIRST_CLICK_DATE_FROM: "firstClickDateFrom",
  SET_FIRST_CLICK_DATE_TO: "firstClickDateTo",
  SET_FIRST_SPAM_REPORT_DATE_FROM: "firstSpamReportDateFrom",
  SET_FIRST_SPAM_REPORT_DATE_TO: "firstSpamReportDateTo",
}

export const INITIAL_STATE = {
	customer: null,
	consultant: null,
	mailTemplate: null,
	openCountFrom: null,
	openCountTo: null,
	clickCountFrom: null,
	clickCountTo: null,
	spamReportsFrom: null,
	spamReportsTo: null,
	createdDateFrom: null,
	createdDateTo: null,
	processedDateFrom: null,
	processedDateTo: null,
	deliveredDateFrom: null,
	deliveredDateTo: null,
	droppedDateFrom: null,
	droppedDateTo: null,
	bouncedDateFrom: null,
	bouncedDateTo: null,
	bouncedType: null,
	firstOpenDateFrom: null,
	firstOpenDateTo: null,
	firstClickDateFrom: null,
	firstClickDateTo: null,
	firstSpamReportDateFrom: null,
	firstSpamReportDateTo: null,
}
