import PopUp from "@/common/components/PopUp";
import { useEffect, useState } from "react";
import {
  FormHeader,
  RecipientsListContainer,
  ButtonsContainer,
  Title,
} from "./SendAccountingPopUp.styled";
import { Colors } from "@/common/colors/colors";
import { generateFiles } from "../../helpers/generateFiles";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { sendValidClaimsReportFiles } from "@/API/repositories/reports";
import Loading from "@/common/components/Loading";
import moment from "moment";
import SubmitButton from "@/common/components/buttons/SubmitButton";
import { getMailingReportsByType } from "@/API/repositories/mailing-report";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const REPORT_TYPE = "VALID_CLAIMS_REPORT";

export default function SendAccountingPopUp({ onClose, data, month }) {
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const [mailingReport, setMailingReport] = useState();

  const handleSend = async (e) => {
    e.preventDefault();

    const buffers = await generateFiles(data, month);
    const files = buffers.map((buffer) => ({
      name: buffer.name,
      data: buffer.data.toString("base64"),
    }));

    const payload = {
      files,
      month: moment(month, "MMMM YYYY").format("MM-YYYY"),
    };

    makeRequest(sendValidClaimsReportFiles.bind(null, payload));

    onClose();
  };

  const loadMailingReport = async () => {
    const response = await makeRequest(
      getMailingReportsByType.bind(null, REPORT_TYPE)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setMailingReport(response.data);
  };

  useEffect(() => {
    loadMailingReport();
  }, []);

  const mailingReportNotFound =
    !hasUnfilledRequest(sendValidClaimsReportFiles) && !mailingReport;
  const isRecipientsValid = mailingReport?.recipients.length > 0;

  return (
    <PopUp setShow={onClose}>
      {hasUnfilledRequest(sendValidClaimsReportFiles) && <Loading />}
      <form onSubmit={handleSend}>
        <FormHeader>
          {mailingReportNotFound
            ? "Nie znaleziono konfiguracji raportu"
            : "Czy na pewno chcesz wysłać raport?"}
        </FormHeader>
        {mailingReportNotFound ? (
          <CheckConfigurationPrompt />
        ) : !isRecipientsValid ? (
          <>
            <p>Brak odbiorców</p>
            <CheckConfigurationPrompt />
          </>
        ) : (
          <>
            <Title>
              <strong>
                Odbiorcy: <span style={{ color: Colors.red }}>*</span>
              </strong>
            </Title>
            <RecipientsListContainer>
              <ul>
                {mailingReport.recipients.map((r) => (
                  <li>{r}</li>
                ))}
              </ul>
              <div>
                Konfiguracja odbiorców w zakładce{" "}
                <a href="/settings/mailing-reports">Mailing Reports</a>
              </div>
            </RecipientsListContainer>
            <ButtonsContainer>
              {isRecipientsValid && (
                <SubmitButton
                  style={{ marginBottom: "-35px", height: "35px" }}
                  color={Colors.orange}
                  text={`Wyślij`}
                />
              )}
            </ButtonsContainer>
          </>
        )}
      </form>
    </PopUp>
  );
}

const CheckConfigurationPrompt = () => {
  return (
    <div>
      Sprawdź konfigurację raportu w zakładce{" "}
      <a href="/settings/mailing-reports">Mailing Reports</a>
    </div>
  );
};
