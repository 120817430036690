import Input from "@/common/components/Input";
import SelectInput from "@/common/components/SelectInput";
import React, { useEffect, useState } from "react";
import { DefaultInputsWrapper, Form, Title } from "../../InvoiceTicket.styled";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { findMarketFromOptions } from "@/pages/admin/sentOrderReport/components/sentOrdersTable/helpers/findMarketFromOptions";
import { shouldDoManualCheck } from "./helpers/shouldDoManualCheck";
import ManualCreation from "./components/manualCreation/ManualCreation";
import AutoCreation from "./components/autoCreation/AutoCreation";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const InvioceByNumber = ({
  companyIdRef,
  addressRef,
  companyNameRef,
  handleCreateValidInvoiceTicket,
  setInvoiceCreateBy,
  commentRef,
  cityRef,
  postalCodeRef,
  streetRef,
  orderMarket,
  handleManualCreation,
  orderId,
}) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [companyId, setCompanyId] = useState();


  const { translation } = useTranslationContext();
  const {
    options: { marketsOptions },
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  useEffect(() => {
    if (!!marketsOptions?.length) {
      setSelectedCountry(() =>
        findMarketFromOptions(orderMarket._id, marketsOptions)
      );
    }
  }, [marketsOptions]);

  return (
    <>
      <Title>Invoice ticket:</Title>
      <Form onSubmit={(e) => handleCreateValidInvoiceTicket(e)}>
        <DefaultInputsWrapper>
          <Input
            disabled={
              companyNameRef.current?.value && addressRef.current?.value
            }
            inputRef={companyIdRef}
            onChange={(e) => setCompanyId(e.target.value)}
            width={190}
            inputWidth={220}
            required
            requiredSign
            name={translation.company_id}
            color={Colors.darkBlue}
          />
          <SelectInput
            disabled={
              companyNameRef.current?.value && addressRef.current?.value
            }
            setSelected={setSelectedCountry}
            width={200}
            selectWidth={230}
            required
            requiredSign
            name={translation.company_country}
            color={Colors.darkBlue}
            options={marketsOptions}
            selected={selectedCountry}
          />
        </DefaultInputsWrapper>
        {shouldDoManualCheck(selectedCountry?.label) ? (
          <ManualCreation
            countryName={selectedCountry?.label}
            orderId={orderId}
            companyId={companyId}
            commentRef={commentRef}
            handleManualCreation={handleManualCreation}
          />
        ) : (
          <AutoCreation
            companyNameRef={companyNameRef}
            addressRef={addressRef}
            commentRef={commentRef}
            streetRef={streetRef}
            cityRef={cityRef}
            postalCodeRef={postalCodeRef}
            setInvoiceCreateBy={setInvoiceCreateBy}
            companyIdRef={companyIdRef}
            selectedCountry={selectedCountry}
          />
        )}
      </Form>
    </>
  );
};

export default InvioceByNumber;
