import Table from '@/common/components/Table'
import React, { useMemo } from 'react'
import { getPaidRaws } from '../../helpers/getPaidRaws';
import { getNotPaidRaws } from '../../helpers/getNotPaidRaws';
import { getPaidHeadersArray, getUnpaidHeadersArray } from '../../constants/headers';
import { INTERNAL_STATUSES } from '@/common/constants/claims';

const ClaimTable = ({
  validClaims,
  setSelectedClaim,
  setAddFile,
  selectedStatus,
  setOpenAction,
  filters
}) => {
  const headersArray = useMemo(() => {
    return selectedStatus.value === INTERNAL_STATUSES.DONE
      ? getPaidHeadersArray(setOpenAction, filters)
      : getUnpaidHeadersArray(setOpenAction, filters);
  }, [selectedStatus, filters, setOpenAction]);

  const raws = useMemo(() => {
    if (!validClaims?.length) return [];

    return selectedStatus.value === INTERNAL_STATUSES.DONE
      ? getPaidRaws(validClaims, setSelectedClaim, setAddFile)
      : getNotPaidRaws(validClaims, setSelectedClaim, setAddFile)
  },
    [validClaims, selectedStatus]);

  return (
    <Table className="styled-table" headersArray={headersArray} raws={raws} />
  )
}

export default ClaimTable
