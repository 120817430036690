import React from "react";
import styled from "styled-components";
import { Colors } from "../colors/colors";
import moment from "moment";
import Loading from "./Loading";
import { useCommonDataContext } from "../hooks/commonDataContext";
import { NavigateLink } from "./links/NavigateLink";

const Wrapper = styled.div`
  border: 1px dashed ${Colors.darkBlue};
  border-radius: 20px;
  padding: 20px;
  height: fit-content;
  max-width: 980px;
`;

const DateContainer = styled.div`
  min-width: 80px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  color: ${Colors.darkBlue};
`;

const Point = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  border: 1px solid red;
  top: 5px;
  left: 77px;
  border-radius: 50%;
`;

const Title = styled.h4`
  margin-bottom: 20px;
  color: ${Colors.darkBlue};
`;
const ChangeWrapper = styled.div`
  font-style: italic;
  display: flex;
  gap: 5px;
`;

const ChangeContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const WrapperChanges = styled.div`
  font-size: 14px;
  word-break: break-all;
  text-align: left;
  padding: 10px 20px 10px 10px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex2 = styled.div`
  display: flex;
`;

const TimeLine = ({ timeline }) => {
  const { commonData, queryValues } = useCommonDataContext();
  const { users, queues, markets, products } = commonData;
  const { isLoading } = queryValues;

  const getProperName = (key, value) => {
    if ([null, undefined, true, false].includes(value)) {
      return value + "";
    }

    if (
      key.toLowerCase().slice(key.length - 2) === "at" ||
      key.toLowerCase().slice(key.length - 4) === "date"
    ) {
      return (
        <p className="animation-scale" style={{ cursor: "pointer" }}>
          {moment(value).format("YYYY-MM-DD HH:mm")}
        </p>
      );
    }

    if (
      key.toLowerCase().slice(key.length - 2) === "by" ||
      key.split(".")[0] === "consultants"
    ) {
      return (
        <NavigateLink
          style={{ border: "none", padding: "0 4px" }}
          text={users.find((user) => user._id === value)?.username}
          color="#000"
          to={`/settings/user/list?mode=show&userId=${value}`}
          target="_blank"
        />
      );
    }

    if (key.split(".").slice(-1).pop() === "product") {
      return (
        <NavigateLink
          style={{ border: "none", padding: "0 4px" }}
          text={products.find((p) => p._id === value)?.name}
          color="#000"
          to={`/settings/product/show/${value}`}
          target="_blank"
        />
      );
    }

    if (key.match("market")) {
      return (
        <NavigateLink
          style={{ border: "none", padding: "0 4px" }}
          text={markets.find((market) => market._id === value)?.name}
          color="#000"
          to={`/settings/market/show/${value}`}
          target="_blank"
        />
      );
    }

    if (key.split("_").slice(-1).pop() === "queue") {
      return (
        <NavigateLink
          style={{ border: "none", padding: "0 4px" }}
          text={queues.find((queue) => queue._id === value)?.name}
          color="#000"
          to={`/settings/queue/show/${value}`}
          target="_blank"
        />
      );
    }

    return value;
  };

  const getCreatedInfo = (element) => {
    return <div style={{ color: Colors.green }}>Created</div>;
  };

  const getChangeSetInfo = (element) => {
    return (
      <WrapperChanges>
        {element?.changeset.map((change, i) => {
          return (
            <div key={i}>
              {[
                ...new Set([
                  ...Object.keys(change[0]),
                  ...Object.keys(change[1]),
                ]),
              ]
                .filter((d) => d !== "shipping")
                .map((key) => (
                  <ChangeContainer>
                    <b>{key}: </b>
                    <ChangeWrapper>
                      {getProperName(key, change[0][key]) || "null"} {"=>"}{" "}
                      {getProperName(key, change[1][key]) || "null"}
                    </ChangeWrapper>
                  </ChangeContainer>
                ))}
            </div>
          );
        })}
      </WrapperChanges>
    );
  };

  const getCallHistoryInfo = (element) => {
    return (
      <Flex>
        <p>
          Type: <b>{element.source === "inbound" ? "inbound" : "outbound"}</b>
        </p>
        <p>
          Contact mark as <b>{element.status}</b>
        </p>
        {element.status === "shift" && (
          <>
            <p>
              Shift reason: <b>{element.shift_reason} </b>
            </p>
            <p>
              Shift set at:{" "}
              <b>{moment(element.shift_date).format("YYYY-MM-DD HH:mm:ss")} </b>
            </p>
          </>
        )}
        {element.status === "no_response" && (
          <p>
            No response reason:{" "}
            <b>{element.no_response_reason || "CALL_CENTER"} </b>
          </p>
        )}
        <p>
          Dequeue count: <b> {element.dequeue_count}</b>
        </p>
        <p>
          Ended at:
          <b>{moment(element.updated_at).format("YYYY-MM-DD HH:mm:ss")}</b>
        </p>
      </Flex>
    );
  };

  const getMailInfo = (element) => {
    return (
      <div style={{ marginLeft: "10px" }}>
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Type: </b>
          {element.mailType}
        </Flex2>
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Clicked: </b>
          {element.clicked_countrer}
        </Flex2>{" "}
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Opened: </b>
          {element.open_countrer}
        </Flex2>{" "}
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Spamed: </b>
          {element.spam_report_countrer}
        </Flex2>{" "}
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Delivered At: </b>
          {(element?.delivered_at &&
            moment(element.delivered_at).format("DD/MM/YYYY HH:mm")) ||
            "-----"}
        </Flex2>{" "}
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Opened At: </b>
          {(element?.first_open_at &&
            moment(element.first_open_at).format("DD/MM/YYYY HH:mm")) ||
            "-----"}
        </Flex2>{" "}
        <Flex2>
          {" "}
          <b style={{ marginRight: "10px" }}>Proccess At: </b>
          {(element?.processed_at &&
            moment(element.processed_at).format("DD/MM/YYYY HH:mm")) ||
            "-----"}
        </Flex2>{" "}
      </div>
    );
  };

  const getCommentsInfo = (element) => {
    return (
      <div style={{ fontStyle: "italic", maxWidth: "500px" }}>
        Comment: {element.comment}
      </div>
    );
  };

  const diplayChangesByType = (element) => {
    switch (element.type) {
      case "creation":
        return getCreatedInfo(element);
      case "change_set":
        return getChangeSetInfo(element);
      case "call_history":
        return getCallHistoryInfo(element);
      case "comment":
        return getCommentsInfo(element);
      case "mail":
        return getMailInfo(element);
      default:
        <div></div>;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        (users && queues && markets && products && (
          <Wrapper>
            <Title>
              <u>Time line:</u>
            </Title>
            {timeline
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              .map((data) => (
                <div style={{ display: "flex" }}>
                  <DateContainer>
                    <Point />
                    {moment(data.created_at).format("HH:mm:ss")}
                  </DateContainer>
                  <div
                    style={{
                      borderLeft: "1px solid black",
                      fontSize: "14px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "5px" }}>
                      <p style={{ color: Colors.darkBlue }}>
                        {" "}
                        {moment(data.created_at).format("YYYY-MM-DD")}
                      </p>{" "}
                      by{" "}
                      <b
                        className="animation-scale"
                        style={{ color: Colors.red, cursor: "pointer" }}
                      >
                        {users && users.find((e) => e._id === data.user_id)
                          ? users.find((e) => e._id === data.user_id).username
                          : "System"}
                      </b>
                      {` (${data.type}) `}
                    </div>
                    <div>{data.log}</div>
                    <WrapperChanges>{diplayChangesByType(data)}</WrapperChanges>
                  </div>
                </div>
              ))}
          </Wrapper>
        )) || <></>
      )}
    </>
  );
};

export default TimeLine;
