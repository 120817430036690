import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const SearchBar = styled.div`
z-index: 1;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
color: ${Colors.darkBlue};
border: 1px solid ${Colors.darkBlue};
min-width: 50vw;
gap: 30px;
border-radius: 15px;
height: 220px;
max-width: 75vw;
`;

