import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import React from "react";
import Table from "@/common/components/Table";
import { getHeaders } from "./helpers/getHeaders";
import { getRaws } from "./helpers/getRaws";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ManagerEffectivePaymentTable = ({ from, to, team }) => {
  const { translation } = useTranslationContext();

const {
  commonData: { users },
  } = useCommonDataContext();

  return (
    <div>
      <Table
        headersArray={getHeaders(from, to, translation)}
        className="styled-table"
        raws={getRaws(team, users)}
      />
    </div>
  );
};

export default ManagerEffectivePaymentTable;
