import moment from "moment";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addMessageToTicket,
  getConsultantTickets,
} from "../../../API/repositories/tickets";
import userManager from "../../../API/userManager";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { getToday } from "@/common/functions/getToday";
import {
  MessagesBox,
  TitlePopUp,
  UserWrapper,
  Wrapper,
} from "./consultantTickets.styles";
import { TICKET_TYPES } from "./consultantTickets.constance";
import SearchWrapper from "@/common/components/newDesign/SearchWrapper";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import ActionButton from "@/common/components/buttons/ActionButton";

const ConsultantTickets = () => {
  const [selected, setSelected] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState();
  const [messageSucces, setMessageSuccess] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();
  const [messages, setMessages] = useState();
  const textareaRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const today = getToday();

  const { commonData } = useCommonDataContext();
  const { users } = commonData;
  const { translation } = useTranslationContext();

  const handleAddCommentToTicket = async (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.message = textareaRef.current.value;

    const response = await makeRequest(
      addMessageToTicket.bind(null, messages._id, payload)
    );

    if (response.data) {
      // add message wyslano
      setMessageSuccess(() => true);
      setTimeout(() => {
        setMessageSuccess(false);
      }, [3000]);
      await hadnleGetConsultantTickets();
      textareaRef.current.value = "";
    }
  };

  const hadnleGetConsultantTickets = async (e) => {
    e && e.preventDefault();
    setDataToDisplay(() => null);

    const payload = Object();

    payload.types = selected.map((data) => data.value);
    if (payload.types.length === 0) return;

    payload.from = fromRef.current
      ? moment(fromRef.current.value).startOf("day")
      : null;
    payload.to = toRef.current
      ? moment(toRef.current.value).endOf("day")
      : null;

    const response = await makeRequest(
      getConsultantTickets.bind(null, payload)
    );

    if (response.data && response.data.length > 0) {
      setDataToDisplay(() =>
        response.data.sort((a, b) => a.type.localeCompare(b.type))
      );
    }
  };

  return (
    <>
      {hasUnfilledRequest(getConsultantTickets) && <Loading />}
      <Wrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <SearchWrapper onSubmit={hadnleGetConsultantTickets}>
            <SelectInput
              options={TICKET_TYPES}
              color={Colors.darkBlue}
              selected={selected}
              setSelected={setSelected}
              multiple={true}
              name={translation["type"]}
              width={80}
              selectWidth={200}
              required
              requiredSign
            />
            <Input
              color={Colors.darkBlue}
              inputRef={fromRef}
              width={100}
              name={translation["start"]}
              type="date"
              inputWidth={150}
              value={today}
              required
              requiredSign
            />
            <Input
              color={Colors.darkBlue}
              inputRef={toRef}
              width={90}
              name={translation["end"]}
              type="date"
              inputWidth={150}
              value={today}
              required
              requiredSign
            />
          </SearchWrapper>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {dataToDisplay && (
            <div>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Updated at</th>
                    <th>Last updated by</th>
                    <th>Last message</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="queue">
                  {dataToDisplay
                    .sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )
                    .sort((a, b) => a.type.localeCompare(b.type))
                    .map((ticket, index) => (
                      <tr>
                        <td>{index + 1}.</td>
                        <td>{ticket.status}</td>
                        <td>{ticket.type}</td>
                        <td>
                          {moment(ticket.updated_at).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>
                          {ticket.type === "correction" &&
                          ticket.last_status_by !== userManager.getUser().id
                            ? "Admin"
                            : ticket._last_status_by?.username}
                        </td>
                        <td>
                          <div
                            style={{
                              width: "150px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {
                              ticket[ticket.type].message?.sort(
                                (a, b) =>
                                  new Date(b.created_at) -
                                  new Date(a.created_at)
                              )[0].original
                            }
                          </div>
                        </td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <i
                              className="fa fa-comments animation-scale"
                              style={{
                                color: Colors.darkBlue,
                                cursor: "pointer",
                              }}
                              onClick={() => setMessages(ticket)}
                            />
                            {ticket.contact && (
                              <i
                                className="fa fa-address-book animation-scale"
                                style={{ color: "grey", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(
                                    `/consultant/contact/${ticket.contact}`
                                  );
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {messages && (
          <PopUp setShow={setMessages}>
            <TitlePopUp>Conversation with:</TitlePopUp>
            <MessagesBox>
              {messages[messages.type].message
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((message) => (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "right",
                        display: "flex",
                      }}
                    >
                      <UserWrapper>
                        <td>
                          {messages.type === "correction" &&
                          message.sender !== userManager.getUser().id
                            ? "Logistics"
                            : users?.find((user) => message.sender === user._id)
                                ?.username}
                        </td>
                      </UserWrapper>
                      <p style={{ textAlign: "right", width: "100%" }}>
                        {message.original}
                      </p>
                    </div>
                    <p style={{ fontSize: "8px", textAlign: "right" }}>
                      {moment(message.created_at).format("YYYY-MM-DD HH:MM")}
                    </p>
                  </div>
                ))}
            </MessagesBox>
            <form
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
                alignItems: "center",
              }}
              onSubmit={(e) => handleAddCommentToTicket(e)}
            >
              <textarea
                ref={textareaRef}
                style={{ width: "350px", resize: "none", fontSize: "12px" }}
                required
                placeholder="type message..."
              />
              <ActionButton
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                color={messageSucces ? Colors.green : Colors.orange}
              >
                <span>{messageSucces ? "Sent" : "Send"}</span>
                <i className="fa fa-send" />
              </ActionButton>
            </form>
          </PopUp>
        )}
      </Wrapper>
    </>
  );
};

export default ConsultantTickets;
