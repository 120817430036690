import React, { useLayoutEffect, useRef, useState } from "react";
import { createQueue } from "../../../API/repositories/queue";
import Form from "../../../common/components/Form";
import Input from "../../../common/components/Input";
import SelectInput from "../../../common/components/SelectInput";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Loading from "../../../common/components/Loading";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const QUEUE_STATUSES = [
  { label: "new", value: "new" },
  { label: "no_response", value: "invalid_number" },
  { label: "confirmed", value: "confirmed" },
  { label: "trash", value: "trash" },
  { label: "shift", value: "shift" },
  { label: "foreign", value: "foreign" },
  { label: "merged", value: "merged" },
  { label: "temp", value: "temp" },
  { label: "resigned", value: "resigned" },
  { label: "inbound", value: "inbound" },
];

const QueueNew = ({ close }) => {
  const nameRef = useRef();
  const t1Ref = useRef();
  const t2Ref = useRef();
  const minutesBetweenCallsRef = useRef();
  const increaseTimeBetweenCallsRef = useRef();
  const increaseTimeBetweenCallsAfterRef = useRef();
  const firstDequeueAfterRef = useRef();
  const maxCallTimesRef = useRef();
  const maxKeepTimeRef = useRef();
  const shiftForConsultantKeepTimeRef = useRef();
  const selectWeightRef = useRef();
  const callWeightRef = useRef();
  const dequeuNotificationLimitRef = useRef();

  const [consultants, setConsultants] = useState();

  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [selectedMarketOption, setSelectedMarketOption] = useState(null);
  const [selectedConsultantsOption, setSelectedConsultantsOption] =
    useState(null);
  const [selectedQueueStatusesOption, setSelectedQueueStatusesOption] =
    useState(null);

  const { filterUsersByRoles, commonData, options } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();
  const { users } = commonData;
  const { marketsOptions, productsOptions } = options;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const hadnleGetData = async () => {
    setConsultants(
      filterUsersByRoles("CALL_CENTER_OPERATOR", "CALL_CENTER_MANAGER")
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const queue = {};
    queue.name = nameRef.current.value;
    queue.product = selectedProductOption.value;
    queue.market = selectedMarketOption.value;
    queue.consultants = selectedConsultantsOption.map(
      (consultant) => consultant.value
    );
    queue.allowed_contact_statuses = selectedQueueStatusesOption.map(
      (status) => status.value
    );
    queue.t1_new = t1Ref.current.value;
    queue.t2_fresh = t2Ref.current.value;
    queue.minutes_between_calls = minutesBetweenCallsRef.current.value;
    queue.increase_time_between_calls =
      increaseTimeBetweenCallsRef.current.value;
    queue.increase_time_between_calls_after =
      increaseTimeBetweenCallsAfterRef.current.value;
    queue.first_dequeue_after = firstDequeueAfterRef.current.value;
    queue.maximum_call_times = maxCallTimesRef.current.value;
    queue.max_keep_time = maxKeepTimeRef.current.value;
    queue.shift_for_consultant_keep_time =
      shiftForConsultantKeepTimeRef.current.value;
    queue.select_weight = selectWeightRef.current.value;
    queue.call_weight = callWeightRef.current.value;
    queue.dequeue_notification_limit = dequeuNotificationLimitRef.current?.value;

    const response = await makeRequest(createQueue.bind(null, queue));

    if (response.data) {
      addMessage("Created", "success");
      close();
    } else {
      addMessage("Couldn't create", "error");
    }
  };

  useLayoutEffect(() => {
    hadnleGetData();
  }, [users]);

  return (
    <>
      {hasUnfilledRequest(createQueue) && <Loading />}
      <h3>Create Queue</h3>
      {consultants && marketsOptions && productsOptions && (
        <Form onSubmit={handleSubmit}>
          <div style={{ display: "flex", gap: "20px" }}>
            <div>
              <Input
                inputRef={nameRef}
                name="Name"
                value=""
                required={true}
                disabled={false}
                width={100}
              />
              <SelectInput
                name="Product"
                options={productsOptions}
                setSelected={setSelectedProductOption}
                selected={selectedProductOption}
                width={100}
              />
              <SelectInput
                name="Market"
                options={marketsOptions}
                setSelected={setSelectedMarketOption}
                selected={selectedMarketOption}
                width={100}
              />
              <SelectInput
                name="Consultants"
                options={consultants}
                setSelected={setSelectedConsultantsOption}
                selected={selectedConsultantsOption}
                width={100}
                multiple={true}
              />
              <SelectInput
                name="Statuses"
                options={QUEUE_STATUSES}
                setSelected={setSelectedQueueStatusesOption}
                selected={selectedQueueStatusesOption}
                width={100}
                multiple={true}
                required
              />
              <Input
                inputRef={t1Ref}
                name="T1 - new "
                value=""
                required={true}
                disabled={false}
                width={100}
                type="number"
              />
              <Input
                inputRef={t2Ref}
                name="T2 - fresh "
                value=""
                required={true}
                disabled={false}
                width={100}
                type="number"
              />
            </div>
            <div>
              <Input
                inputRef={minutesBetweenCallsRef}
                name="Minutes between calls"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={increaseTimeBetweenCallsRef}
                name="Increase Time Between Calls"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={increaseTimeBetweenCallsAfterRef}
                name="Increase Time Between Calls After"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={firstDequeueAfterRef}
                name="First Dequeue After"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={maxCallTimesRef}
                name="Max Call Times"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={maxKeepTimeRef}
                name="Max Keep time"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                inputRef={shiftForConsultantKeepTimeRef}
                name="Shift for consultant keep time"
                value=""
                required={true}
                disabled={false}
                type="number"
              />
              <Input
                name="Select weight"
                value=""
                required={true}
                type="number"
                inputRef={selectWeightRef}
              />
              <Input
                name="Call weight"
                value=""
                required
                type="number"
                inputRef={callWeightRef}
              />
              <Input
                name="Dequeue notification limit"
                value=""
                required
                type="number"
                inputRef={dequeuNotificationLimitRef}
              />
            </div>
          </div>
          <SubmitButton text="Save" />
        </Form>
      )}
    </>
  );
};

export default QueueNew;
