import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getListOfQueuesInfos } from "../../../API/repositories/queue";
import ListTable from "../../../common/components/ListTable";
import Loading from "../../../common/components/Loading";
import PopUp from "../../../common/components/PopUp";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import QueueNew from "./QueueNew";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const Flex = styled.div`
  display: flex;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

export const TableTitle = styled.h3`
  width: 100%;
  color: ${Colors.darkBlue};
  margin: 20px 4px 10px;
`;

const QueueTableWrapper = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0.1)};
  overflow: hidden;
  height: ${(props) => (props.show ? "fit-content" : "100px")};
  transition: all 0.3s;
`;

const ShowArrow = styled.i`
  transform: ${(props) => (props.show ? `rotate(0)` : `rotate(180deg)`)};
  transition: transform 0.3s;
`;

const QueueList = () => {
  const [showActive, setShowActive] = useState(true);
  const [activeRaws, setActiveRaws] = useState([]);
  const [inActiveRaws, setInActiveRaws] = useState([]);
  const HEADERS = ["no", "name", "users", "contacts"];
  const [showNew, setShowNew] = useState(false);
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const getQueueData = async () => {
    const response = await makeRequest(getListOfQueuesInfos);

    if (response.data) {
      setActiveRaws(() =>
        response.data
          .filter((f) => !!f?.is_in_use === true)
          .sort((a, b) => a?.name.localeCompare(b?.name))
          .map((element) => {
            return {
              data: [element.name, element.users, element.contacts],
              _id: element._id,
            };
          })
      );
      setInActiveRaws(() =>
        response.data
          .filter((f) => !!f?.is_in_use === false)
          .sort((a, b) => a?.name.localeCompare(b?.name))
          .map((element) => {
            return {
              data: [element.name, element.users, element.contacts],
              _id: element._id,
            };
          })
      );
    }
  };

  useEffect(() => {
    getQueueData();
  }, [showNew]);

  return (
    <>
      {hasUnfilledRequest(getListOfQueuesInfos) && <Loading />}
      <Wrapper>
        <Flex>
          <ActionButton
            onClick={() => setShowNew(true)}
            defaultText="Create queue"
          />
        </Flex>
        <TableTitle onClick={() => setShowActive((prev) => !prev)}>
          Active queues{" "}
          <ShowArrow show={showActive} className="fa fa-arrow-down" />
        </TableTitle>
        <QueueTableWrapper show={showActive}>
          <ListTable column={HEADERS} row={activeRaws} entityName={"queue"} />
        </QueueTableWrapper>
        <TableTitle onClick={() => setShowActive((prev) => !prev)}>
          Not active quesues{" "}
          <ShowArrow show={showActive} className="fa fa-arrow-down" />
        </TableTitle>
        <QueueTableWrapper show={!showActive}>
          <ListTable column={HEADERS} row={inActiveRaws} entityName={"queue"} />
        </QueueTableWrapper>
      </Wrapper>
      {showNew && (
        <PopUp setShow={setShowNew} top={40}>
          <QueueNew close={() => setShowNew(false)} />
        </PopUp>
      )}
    </>
  );
};

export default QueueList;
