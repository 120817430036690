/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import userManager from "../../../../../API/userManager";
import { Colors } from "../../../../../common/colors/colors";
import { consultantNavigationConfig } from "../../../../../common/constants/navigationConfigs";

const Wrapper = styled.div`
  background-color: #035072;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 15px 15px 0px;
  height: 100vh;
  padding: 10px;
  width: 60px;
`;

const NavigationSection = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  gap: 15px;
  color: white;
  padding: 6px 15px;
  border-radius: 15px;

  ${({ link }) =>
    link && `margin-bottom: 10px; &:hover {  transform: scale(1.1);}`}
  ${({ selected }) => selected && ` background-color: ${Colors.lightBlue};  `}
`;

const Icon = styled.i`
  font-size: 20px;
  color: white;
`;

const IconWrapper = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80vh;
`;

const Navbar = () => {
  const { pathname } = useLocation();
  const [sectionToShow, setSectionToShow] = useState("");

  return (
    <Wrapper>
      <Box>
        {consultantNavigationConfig
          .filter((d) => userManager.checkAccess(d.access))
          .map((navigationSection) => {
            return (
              <React.Fragment key={navigationSection.name}>
                <NavigationSection
                  link={navigationSection.link}
                  to={
                    navigationSection.link ? navigationSection.link : pathname
                  }
                  selected={navigationSection.link === pathname}
                  onClick={() => setSectionToShow(() => navigationSection.name)}
                >
                  <IconWrapper>
                    <Icon className={navigationSection.icon} />
                  </IconWrapper>
                </NavigationSection>
                {sectionToShow === navigationSection.name &&
                  navigationSection.element &&
                  navigationSection.element(setSectionToShow)}
              </React.Fragment>
            );
          })}
      </Box>
    </Wrapper>
  );
};

export default Navbar;
