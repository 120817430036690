import { Colors } from "@/common/colors/colors";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { SearchBarColumn } from "@/common/styles/SearchBars";
import React, { useMemo } from "react";
import {
  ButtonWrapper,
  InputsWrapper,
} from "../../PaymentProductCPLReport.styled";
import { PERIOD_OF_TIME_OPTIONS } from "../../constants";
import CSV from "@/common/components/CSV";
import { HEADERS } from "../../constants/headers";
import Input from "@/common/components/Input";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const ProductCPLSearchBar = ({ formik, setCplData, cplData }) => {
  const headers = useMemo(() => {
    HEADERS[0] = formik?.values?.label || HEADERS[0];
    return HEADERS;
  }, [formik?.values?.label, cplData]);

  const {
    commonData: { products },
  } = useCommonDataContext();

  return (
    <SearchBarColumn>
      <form onSubmit={formik.handleSubmit}>
        <SelectInput
          color={Colors.darkBlue}
          width={130}
          selectWidth={580}
          name="Products"
          multiple
          options={products
            .map((p) => ({ label: p.name, value: p._id, data: p }))
            .filter((p) => p.data.type === "payment")}
          setSelected={(value) => formik.setFieldValue("products", value)}
          selected={formik.values.products}
        />
        <InputsWrapper>
          <SelectInput
            options={PERIOD_OF_TIME_OPTIONS}
            color={Colors.darkBlue}
            width={130}
            selectWidth={200}
            name="Period of time"
            id="time_period"
            setSelected={(value) => {
              setCplData(() => null);
              if (value.value !== "day") {
                formik.setFieldValue(
                  "period_from",
                  value.period_from[value.default_back]
                );
                formik.setFieldValue("period_to", value.period[0]);
              }

              formik.setFieldValue("time_period", value);
            }}
            selected={formik.values.time_period}
          />
        </InputsWrapper>
        <InputsWrapper>
          {formik.values.time_period.value === "day" ? (
            <>
              <Input
                width={130}
                inputWidth={200}
                color={Colors.darkBlue}
                type="date"
                name="Period from"
                id="period_from"
                onChange={(e) => {
                  formik.setFieldValue("period_from", e.target.value);
                }}
              />
              <Input
                width={130}
                inputWidth={200}
                color={Colors.darkBlue}
                type="date"
                name="Period to"
                id="period_to"
                onChange={(e) => {
                  formik.setFieldValue("period_to", e.target.value);
                }}
              />
            </>
          ) : (
            <>
              <SelectInput
                options={formik.values.time_period.period_from}
                color={Colors.darkBlue}
                width={130}
                selectWidth={200}
                name="Period from"
                id="period_from"
                setSelected={(value) => {
                  formik.setFieldValue("period_from", value);
                }}
                selected={formik.values.period_from}
              />
              <SelectInput
                options={formik.values.time_period.period}
                color={Colors.darkBlue}
                width={130}
                selectWidth={200}
                name="Period to"
                id="period_to"
                setSelected={(value) => {
                  formik.setFieldValue("period_to", value);
                }}
                selected={formik.values.period_to}
              />
            </>
          )}
        </InputsWrapper>
        <ButtonWrapper>
          {cplData && (
            <CSV
              header={headers}
              data={cplData.map((cd) => Object.values(cd).map((cd) => cd || 0))}
              filename={"payment_product_cpl.csv"}
            />
          )}
          <SubmitButton
            color={Colors.orange}
            text="Search"
          />
        </ButtonWrapper>
      </form>
    </SearchBarColumn>
  );
};

export default ProductCPLSearchBar;
