import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import {
  getConsultantGrade,
  getConsultantGradeForNewConsultant,
} from "../../../API/repositories/reports";
import userManager from "../../../API/userManager";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import StatisticsGradeTable from "../../../common/components/statisticsGradeTable/StatisticsGradeTable";
import { Wrapper } from "./ConsultantStatistics.styled";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ConsultantStatistics = () => {
  const [grade, setGrade] = useState();
  const [gradeForNewConsultant, setGradeForNewConsultant] = useState();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { translation } = useTranslationContext();

  const handleGetConsultantGrade = async () => {
    const payload = Object();
    payload.from = moment().subtract(35, "days").startOf("day").toDate();
    payload.to = moment().subtract(7, "days").endOf("day").toDate();
    payload.weights = true;
    payload.ids = [userManager.getUser().id];

    let response = await makeRequest(getConsultantGrade.bind(null, payload));

    if (response.data) {
      setGrade(() => response.data[0]);
    }

    payload.from = moment().subtract(28, "days").startOf("day").toDate();
    payload.to = moment().endOf("day").toDate();

    response = await makeRequest(
      getConsultantGradeForNewConsultant.bind(null, payload)
    );

    if (response.data) {
      setGradeForNewConsultant(() => response.data[0]);
    }
  };

  useLayoutEffect(() => {
    handleGetConsultantGrade();
  }, []);

  return (
    <Wrapper>
      {hasUnfilledRequest(getConsultantGrade) && <Loading />}
      {grade && <StatisticsGradeTable grade={grade} />}
      {gradeForNewConsultant && (
        <StatisticsGradeTable grade={gradeForNewConsultant} newConsultant />
      )}
      {!grade && !gradeForNewConsultant && <h4>{translation.noData}</h4>}
    </Wrapper>
  );
};

export default ConsultantStatistics;
