import React, { useEffect, useState } from "react";
import { getConsultantDrafts } from "../../../API/repositories/consultantDocuments";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import Loading from "../../../common/components/Loading";
import {
  Element,
  FilesWrapper,
  Text,
  Wrapper,
} from "./consultantDrafts.styles";
import {
  SubtitleWrapper,
  TitleWrapper,
} from "../consultantFaq/consultantFaq.styles";
import { handleDownloadFile } from "./helper/downloadFile";
import { getFileById } from "@/API/repositories/storedDocument";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const ConsultantDrafts = () => {
  const [data, setData] = useState();

  const { translation } = useTranslationContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleLoadData = async () => {
    const response = await makeRequest(getConsultantDrafts);

    if (response.data) {
      setData(() => response.data);
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      {(hasUnfilledRequest(getConsultantDrafts) ||
        hasUnfilledRequest(getFileById)) && <Loading />}
      {data && (
        <Wrapper>
          <TitleWrapper className="fade__in__animation">
            {translation["title"]}
          </TitleWrapper>
          <SubtitleWrapper className="fade__in__animation">
            {translation["description"]}
          </SubtitleWrapper>
          <FilesWrapper>
            {data.map((d, i) => (
              <Element
                key={d._id}
                className="fade__in__animation"
                style={{
                  animationDelay: `${i * 100}ms`,
                }}
              >
                <h5>
                  {i + 1}. {d._stored_docs.description}
                </h5>
                <i
                  className="fa fa-file animation-scale "
                  onClick={() =>
                    handleDownloadFile(d._stored_docs._id, makeRequest)
                  }
                />
              </Element>
            ))}
          </FilesWrapper>
          {data.length === 0 && <Text>{translation.thereIsNoDraftForYou}</Text>}
        </Wrapper>
      )}
    </>
  );
};

export default ConsultantDrafts;
