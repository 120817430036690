import React, { createContext } from "react";
import { useCommonData } from "../hooks/useCommonData";

export const CommonDataContext = createContext();

export const CommonDataProvider = ({ children }) => {
  const {
    commonData,
    options,
    filterUsersByRoles,
    queryValues,
    queryFunctions,
    getActiveUsersOptions,
    COUNTRIES,
  } = useCommonData();

  return (
    <CommonDataContext.Provider
      value={{
        commonData,
        options,
        filterUsersByRoles,
        queryValues,
        queryFunctions,
        getActiveUsersOptions,
        COUNTRIES
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};
