import PopUp from '@/common/components/PopUp';
import SelectInput from '@/common/components/SelectInput';
import React from 'react'
import { FILTER_ACTIONS } from '../../constants/filter';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { DEFAULT_PAGE } from '../../constants/searchParams';
import { FilterSetterWrapper } from './FilterSetter.styled';
import ActionButton from '@/common/components/buttons/ActionButton';
import { RESIGN_OPTIONS } from '@/common/constants/resignOptions';

const FilterSetter = ({
	openAction,
	tagsOptions,
	setOpenAction,
	filter,
	dispatchFilter,
	reloadData,
	setSearchParams,
}) => {
	const { options: { marketsOptions, productsOptions } } = useCommonDataContext();

	const getActionOptions = () => {
		switch (openAction) {
			case FILTER_ACTIONS.SET_MARKETS:
				return marketsOptions;
			case FILTER_ACTIONS.SET_PRODUCTS:
				return productsOptions;
			case FILTER_ACTIONS.SET_REASONS:
				return RESIGN_OPTIONS;
			case FILTER_ACTIONS.SET_TAGS:
				return tagsOptions;
			default: return [];
		}
	}
	return (
		<PopUp
			minWidth='300px'
			padding='10px 20px'
			setShow={setOpenAction}
		>
			<SelectInput
				multiple
				selectWidth={500}
				showLabel={false}
				options={getActionOptions()}
				selected={filter[openAction]}
				setSelected={(value) => dispatchFilter({ type: openAction, payload: value })}
			/>
			<FilterSetterWrapper>
				<ActionButton onClick={() => {
					setSearchParams({
						page: DEFAULT_PAGE
					})
					setOpenAction(null);
					reloadData(filter);
				}} defaultText="Filter" />
			</FilterSetterWrapper>
		</PopUp>
	)
}

export default FilterSetter
