export const copyLinkForConsultant = async (id, setCopied) => {
  const link = 'https://crm.eou-edu.eu/consultant/contact/' + id;

  const textArea = document.createElement("textarea");
  textArea.value = link;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }

  document.body.removeChild(textArea);

  setCopied(() => true);
  setTimeout(() => {
    setCopied(false);
  }, [3000]);
};
