import Api from "../api";

import { errorHandler } from "../common";
const queryString = require("query-string");
const { axios: api } = Api;

export const createInvoiceTicket = (orderId, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/invoice-ticket/${orderId}?`, {
      ...payload,
    });
    return { data: data.data };
  });

export const createValidInvoiceTicket = (orderId, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/valid-invoice/${orderId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const updateValidInvoiceTicket = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/valid-invoice`, {
      ...payload,
    });

    return { data: data.data };
  });

export const generateCorrectionInvoicePreview = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/generate-correction-invoice-preview?${queryString.stringify(
        cleanObject(payload),
        {
          arrayFormat: "bracket",
        }
      )}`
    );

    return { data: data.data };
  });

export const generateValidInoicePreview = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/generate-valid-invoice-preview?${queryString.stringify(
        cleanObject(payload),
        {
          arrayFormat: "bracket",
        }
      )}`
    );

    return { data: data.data };
  });

export const updateInvoiceTicket = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/update-invoice-ticket/${ticketId}`, {
      ...payload,
    });
    return { data: data.data };
  });

export const addTicketAttachments = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/add-attachments/${ticketId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const getValidInvoiceCorrectionsByStatus = (status) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/valid-invoice-corrections-by-status/${status}`
    );

    return { data: data.data };
  });

export const getValidInvoices = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/valid-invoice?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );

    return { data: data.data };
  });

export const updateCorrectionInvoice = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/correction-invoice/${ticketId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const removeDwUser = (ticketId, userId) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/remove-dw/${ticketId}`, { userId });

    return { data: data.data };
  });

export const addDwUsers = (ticketId, users) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/add-dw/${ticketId}`, { users });

    return { data: data.data };
  });

export const updateTicketTitle = (ticketId, title) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/update-title/${ticketId}`, { title });

    return { data: data.data };
  });

export const markTicketAsSeen = (ticketId) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/mark-as-seen/${ticketId}`);

    return { data: data.data };
  });

export const addInvoiceCorrectionNumber = (ticketId, correctionNumber) =>
  errorHandler(async () => {
    const data = await api.patch(
      `ticket/add-invoice-correction-number/${ticketId}`,
      {
        correctionNumber,
      }
    );

    return { data: data.data };
  });

export const markTicketAsOverdue = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/mark-as-overdue/${ticketId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const createCorrectionTicket = (contactId, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/correction-ticket/${contactId}`, {
      ...payload,
    });
    return { data: data.data };
  });

export const getTicketsToDo = (hash) =>
  errorHandler(async () => {
    const data = await api.get(`/ticket/to-do/${hash}`);
    return { data: data.data };
  });

export const updateDocumentTicket = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/update-document-ticket/${ticketId}`, {
      ...payload,
    });

    return { data: data.data };
  });

export const getConsultantTicketsToDo = () =>
  errorHandler(async () => {
    const data = await api.get(`/ticket/consultant/to-do`);
    return { data: data.data };
  });

export const getConsultantTicketsAll = () =>
  errorHandler(async () => {
    const data = await api.get(`/ticket/consultant/all`);

    return { data: data.data };
  });

export const uploadFileToQuestionTicket = (file, ticketId) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/ticket/ticket-file-upload/${ticketId}`,
      file,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return { data: data.data };
  });

export const getConsultantTickets = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/consultant/tickets/search/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getTicketsByFilter = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/tickets/search/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const createGeneralQuestionTicket = (body) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/general-question-ticket`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return { data: data.data };
  });

export const createFixContactConsultantTicket = (contactId, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/invoice-ticket/${contactId}?`, {
      ...payload,
    });
    return { data: data.data };
  });

export const addMessageToTicket = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/add-message-to-ticket/${ticketId}`, {
      ...payload,
    });
    return { data: data.data };
  });

export const markTicketAsDone = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/mark-ticket-as-done/${ticketId}`);
    return { data: data.data };
  });

export const markTicketAsClose = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/mark-ticket-as-close/${ticketId}`);
    return { data: data.data };
  });

export const deleteInvoiceById = (ticketId) =>
  errorHandler(async () => {
    const data = await api.delete(`/ticket/delete-invoice/${ticketId}`);
    return { data: data.data };
  });

export const searchInvoices = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/ticket/search-invoices/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const markInvoiceStatusAsToCorrect = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(
      `/ticket/invoice/mark-as-to-correct/` + ticketId
    );
    return { data: data.data };
  });

export const markInvoiceStatusCorrected = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/invoice/mark-as-correct/` + ticketId);

    return { data: data.data };
  });

export const markInvoiceStatusAsIssued = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/invoice/mark-as-issued/` + ticketId);
    return { data: data.data };
  });

export const markInvoiceStatusAsDone = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/invoice/mark-as-done/` + ticketId);
    return { data: data.data };
  });

export const createObjectionListeningTicket = (gradeId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/objection-listening/` + gradeId);
    return { data: data.data };
  });

export const createCallingImprovementTicket = (payload) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/calling-improvement/`, payload);
    return { data: data.data };
  });

export const acceptValidInvoice = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/accept-valid-invoice`, { ticketId });

    return { data: data.data };
  });

export const acceptCorrectionInvoice = (ticketId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/accept-correction-invoice`, {
      ticketId,
    });

    return { data: data.data };
  });

export const addCCManagersToDw = (ticketId) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/add-cc-managers-to-dw/${ticketId}`);

    return { data: data.data };
  });

export const createSuspicionTicket = (contactId) =>
  errorHandler(async () => {
    const data = await api.post(`/ticket/suspicion/${contactId}`);

    return { data: data.data };
  });

export const makeSuspicionDecision = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/ticket/suspicion-decision?${queryString.stringify(
        cleanObject(payload),
        { arrayFormat: "bracket" }
      )}`
    );

    return { data: data.data };
  });

export const changeSuspicionData = (payload) =>
  errorHandler(async () => {
    const data = await api.patch("/ticket/suspicion-change-data", payload);

    return { data: data.data };
  });

export const updateTicketStatus = (ticketId, payload) =>
  errorHandler(async () => {
    const data = await api.patch(`/ticket/status-update/${ticketId}`, payload);

    return { data: data.data };
  });

export const updateTicketPriority = (ticketId, priority) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/ticket/priority-update/${ticketId}?priority=${priority}`
    );

    return { data: data.data };
  });

export const updateTicketReceiver = (ticketId, reciverId) =>
  errorHandler(async () => {
    const data = await api.patch(
      `/ticket/ticket-reciver-update/${ticketId}?reciverId=${reciverId}`
    );

    return { data: data.data };
  });

export const getActiveTickets = () =>
  errorHandler(async () => {
    const data = await api.get("/ticket/get-active");

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
