import Api from "../api";

import { errorHandler } from "../common";
const { tokenManager, axios: api } = Api;

const queryString = require("query-string");

export const getConsultantEffectiveSalary = async (_id, from, to) => {
  const data = await api.get(
    `/report/consultant/effective_salary/${_id}?` +
      queryString.stringify(cleanObject({ from, to }), {
        arrayFormat: "bracket",
      })
  );
  return { data: data.data };
};

export const getConsultantsCallHistoryReport = async (payload) => {
  const data = await api.get(
    `/report/consultant/call-history/?` +
      queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
  );
  return { data: data.data };
};

export const getEffectivePaymentReport = async (payload) => {
  const data = await api.get(
    `/report/effective_salary/?` +
      queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
  );
  return { data: data.data };
};

export const getGradeReport = async (payload) => {
  const data = await api.get(
    `/report/grade/?` +
      queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
  );
  return { data: data.data };
};

export const getTeamCurScore = async () => {
  const data = await api.get(`/report/team/grade`);
  return { data: data.data };
};

export const getTeamResult = async () => {
  const data = await api.get(`/report/team/result`);
  return { data: data.data };
};

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
