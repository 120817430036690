import React, { useEffect, useRef, useState } from "react";
import { getCompanies } from "@/API/repositories/company";
import { filterEntitiesByIds } from "@/common/functions/filterEntitiesByIds";
import { filterProdluctIdsByCompanyId } from "@/common/functions/filterProductIdsByCompanyId";
import { findDefaultMarketFromOptions } from "@/common/functions/findDefaultMarketFromOptions";
import { findPossibleProductFieldByMarket } from "@/common/functions/findPossibleProductFieldByMarket";
import { handleMapToOptions } from "@/common/functions/handleMapToOptions";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import Card from "@/common/components/Card";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper, Flex, Wrapper } from "./SearchBarResignReport.styled";
import { getSupportResignsReport } from "@/API/repositories/reports";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const SearchBarResignReport = ({ setResigns }) => {
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [companies, setCompanies] = useState();
  const [companiesOptions, setCompaniesOptions] = useState();
  const [productsOptions, setProductsOptions] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const fromDateRef = useRef();
  const toDateRef = useRef();

  const {
    commonData: { products },
    options: { marketsOptions, queuesOptions },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadData = async () => {
    const response = await makeRequest(getCompanies);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setCompanies(() => response.data);
  };

  const handleSearch = async () => {
    const payload = {};

    if (
      !selectedProduct ||
      !fromDateRef.current?.value ||
      !toDateRef.current?.value
    ) {
      return addMessage("Select all required data", "error");
    }

    payload.market = selectedMarket.value;
    payload.product = selectedProduct.value;
    payload.fromDate = fromDateRef.current.value;
    payload.toDate = toDateRef.current.value;

    const response = await makeRequest(
      getSupportResignsReport.bind(null, payload)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setResigns(() => response.data);
  };

  const handleSetMarket = (market) => {
    const currentMarket =
      market || findDefaultMarketFromOptions(queuesOptions, marketsOptions);

    setSelectedMarket(currentMarket);
    handleMarketChange(currentMarket);
  };

  const handleMarketChange = (market) => {
    setResigns(() => null);
    setSelectedCompany(() => null);
    setCompaniesOptions(() => null);
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (market && companies) {
      const possibleCompanies = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products,
        "company"
      );

      const uniqueCompaniesIds = Array.from(new Set(possibleCompanies));

      const filteredCompanies = filterEntitiesByIds(
        companies,
        uniqueCompaniesIds
      );

      const newCompaniesOptions = handleMapToOptions(
        filteredCompanies,
        "name",
        "_id"
      );

      setCompaniesOptions(newCompaniesOptions);
      const company = newCompaniesOptions[0];

      handleSetCompany(company, market);
    }
  };

  const handleSetCompany = (company, market = selectedMarket) => {
    setSelectedCompany(company);
    handleCompanyChange(company, market);
  };

  const handleCompanyChange = (company, market) => {
    setSelectedProduct(() => null);
    setProductsOptions(() => null);

    if (company) {
      const possibleProductsIds = findPossibleProductFieldByMarket(
        queuesOptions,
        market.value,
        products
      );

      const productIdsFilteredByCompanies = filterProdluctIdsByCompanyId(
        products,
        possibleProductsIds,
        company.value
      );

      const filteredProducts = filterEntitiesByIds(
        products,
        productIdsFilteredByCompanies
      );

      const productsOptions = handleMapToOptions(
        filteredProducts,
        "name",
        "_id"
      );

      setProductsOptions(() => productsOptions);
      setSelectedProduct(() => productsOptions[0]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    handleSetMarket();
  }, [queuesOptions, marketsOptions, products, companies]);

  return (
    <Wrapper>
      <Card>
        <Flex>
          {!marketsOptions?.length && <Loading />}
          <div>
            <SelectInput
              color={Colors.darkBlue}
              options={marketsOptions}
              selected={selectedMarket}
              setSelected={handleSetMarket}
              name="Market"
              width={80}
              required
              hideStar
            />
            <SelectInput
              width={80}
              name="Company"
              options={companiesOptions}
              setSelected={handleSetCompany}
              selected={selectedCompany}
              color={Colors.darkBlue}
              disabled={!companiesOptions}
              required
              hideStar
            />
            <SelectInput
              name="Product"
              width={80}
              color={Colors.darkBlue}
              selected={selectedProduct}
              setSelected={setSelectedProduct}
              options={productsOptions}
              disabled={!productsOptions}
              required
              hideStar
            />
          </div>
          <div>
            <Input
              inputRef={fromDateRef}
              width={80}
              color={Colors.darkBlue}
              name="From"
              type="date"
            />
            <Input
              inputRef={toDateRef}
              width={80}
              color={Colors.darkBlue}
              name="To"
              type="date"
            />
          </div>
        </Flex>
        <ButtonWrapper>
          <ActionButton
            onClick={() => handleSearch()}
            defaultText="Search"
          />
        </ButtonWrapper>
      </Card>
    </Wrapper>
  );
};

export default SearchBarResignReport;
