import styled from 'styled-components';

export const Container = styled.div`
	max-width: 80vw;
	overflow: auto;
	max-height: 80vh;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  overflow-y: auto;
`;

export const Flex = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: center;
`;

export const CellActionsWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	flex-wrap: wrap;
`;
