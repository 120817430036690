import { formatMonthName } from "@/common/functions/dateFormater";
import { saveAs } from "@/common/functions/saveAs";
import { generateFiles } from "./generateFiles";

const JSZip = require("jszip");
let zip = new JSZip();

export const generateZip = async (files, month) => {
  files.forEach(({ data, name }) => {
    zip.file(name, data);
  });

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `EOU_${formatMonthName(month)}_zwroty.zip`);
  });

  zip = new JSZip();
};
