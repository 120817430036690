import React, { useState } from "react";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { SearchBarColumn } from "@/common/styles/SearchBars";
import { PageWrapper } from "@/common/styles/Wrappers";
import SelectInput from "@/common/components/SelectInput";
import { Colors } from "@/common/colors/colors";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import ActionButton from "@/common/components/buttons/ActionButton";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { findGamificationStatsForConsultantsAndMonth } from "@/API/repositories/gamificationStats";
import GamificationStatsTable from "./gamificationStatsTable/GamificationStatsTable";
import Loading from "@/common/components/Loading";
import { ButtonWrapper } from "./GamificationNew.styled";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const GamificationNew = () => {
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[0]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [gamificationStats, setGamificationStats] = useState();

  const {
    options: { queuesOptions, usersOptions },
  } = useCommonDataContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSearch = async () => {
    setGamificationStats(() => null);
    const payload = {};

    payload.month = selectedMonth.value.toDate();
    payload.consultants = selectedConsultants.map(
      (consultant) => consultant.value
    );

    const response = await makeRequest(
      findGamificationStatsForConsultantsAndMonth.bind(null, payload)
    );

    if (response?.data) {
      return setGamificationStats(() => response.data);
    }

    addMessage("Not found");
  };

  const handleSelectQueues = (queues) => {
    let selectedUsersIds = [];

    queues.forEach((data) => {
      selectedUsersIds = [...selectedUsersIds, ...data.value.consultants];
    });

    setSelectedConsultants(() =>
      usersOptions.filter((c) => selectedUsersIds?.includes(c.value))
    );

    setSelectedQueues(() => queues);
  };

  return (
    <PageWrapper>
      {hasUnfilledRequest(findGamificationStatsForConsultantsAndMonth) && (
        <Loading />
      )}
      <SearchBarColumn>
        <div style={{ display: "flex" }}>
          <SelectInput
            name="Month"
            options={LAST_YEAR_MONTHS}
            color={Colors.darkBlue}
            width={50}
            selectWidth={200}
            setSelected={setSelectedMonth}
            selected={selectedMonth}
          />
          <SelectInput
            name="Queue"
            multiple
            options={queuesOptions}
            color={Colors.darkBlue}
            width={50}
            selectWidth={200}
            setSelected={handleSelectQueues}
            selected={selectedQueues}
          />
        </div>
        <SelectInput
          name="Users"
          multiple
          options={usersOptions}
          color={Colors.darkBlue}
          width={50}
          selectWidth={500}
          setSelected={setSelectedConsultants}
          selected={selectedConsultants}
        />
        <ButtonWrapper>
          <ActionButton
            defaultText="Search"
            onClick={() => handleSearch()}
          />
        </ButtonWrapper>
      </SearchBarColumn>
      {!!gamificationStats?.length && (
        <GamificationStatsTable gamificationStats={gamificationStats} />
      )}
    </PageWrapper>
  );
};

export default GamificationNew;
