import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_LIMIT, DEFAULT_PAGE } from './constants/searchParams';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { getResignFeedbackReport } from '@/API/repositories/reports';
import Table from '@/common/components/Table';
import { getRaws } from './helpers/getRaws';
import { useCommonDataContext } from '@/common/hooks/commonDataContext';
import { ButtonsWrapper, Container, ContentWrapper, HeadingWrapper, TableWrapper } from './ResignFeedbackReport.styled';
import Pagination from './components/pagination/Pagination';
import { getTotalPages } from './helpers/getTotalPages';
import { getHeadersArray } from './helpers/getHeadersArray';
import { filterReducer } from './helpers/reducer';
import { DEFAULT_FILTER, FILTER_ACTIONS } from './constants/filter';
import { Colors } from '@/common/colors/colors';
import CSV from '@/common/components/CSV';
import { HEADERS } from './constants/header';
import { getCSVBody } from './helpers/getCSVBody';
import { checkIsFilterApplied } from './helpers/checkIsFilterApplied';
import FilterSetter from './components/filterSetter/FilterSetter';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import ActionButton from '@/common/components/buttons/ActionButton';
import { getResignTags } from '@/API/repositories/resign-tag';
import Input from '@/common/components/Input';
import moment from 'moment';
import { formatToDateTamplate } from '@/common/functions/dateFormater';

const ResignFeedbackReport = () => {
	const [filter, dispatchFilter] = useReducer(filterReducer, DEFAULT_FILTER);
	const [reportData, setReportData] = useState();
	const [count, setCount] = useState(0);
	const [inputPage, setInputPage] = useState();
	const [openAction, setOpenAction] = useState(null);
	const [tagsOptions, setTagsOptions] = useState([]);
	const [cleanTagOptions, setCleanTagOptions] = useState([]);
	const [loadedPages, setLoadedPages] = useState([])
	const [fromDate, setFromDate] = useState(formatToDateTamplate(moment().subtract(1, 'month')));
	const [toDate, setToDate] = useState(formatToDateTamplate());

	const [searchParams, setSearchParams] = useSearchParams({
		page: DEFAULT_PAGE
	});
	const { makeRequest, hasUnfilledRequest } = useRequestsContext();
	const { addMessage } = useMessageQueueContext();
	const {
		commonData: { markets, products },
	} = useCommonDataContext();

	const page = useMemo(() => {
		const page = parseInt(searchParams.get('page'));

		if (isNaN(page)) {
			return DEFAULT_PAGE;
		}

		return page;
	}, [searchParams]);

	const checkAllowedToLoad = () => {
		if (DEFAULT_LIMIT < 1) {
			addMessage("Limit must be greater than 0", "error");
			return false;
		}

		if (page < 1) {
			addMessage("Page must be greater than 0", "error");
			return false;
		}

		return true;
	}

	const reloadFilterData = async (reloadFilter) => {
		setInputPage(DEFAULT_PAGE);

		if (!checkAllowedToLoad()) {
			return;
		}

		const payload = {
			fromDate: fromDate,
			toDate: toDate,
			skip: (page - 1) * DEFAULT_LIMIT,
			limit: DEFAULT_LIMIT,
			...Object.entries(reloadFilter).reduce((acc, [key, selected]) => {
				acc[key] = selected.map(element => element.value);
				return acc;
			}, {})
		}

		const response = await makeRequest(getResignFeedbackReport.bind(null, payload));

		if (!response?.data?.resigns) {
			return addMessage("Error loading data", "error");
		}

		setLoadedPages([DEFAULT_PAGE]);
		setReportData({
			[DEFAULT_PAGE]: response.data.resigns
		});
		setCount(response.data.count || 0);
	}

	const loadData = async () => {
		if (loadedPages.includes(page)) {
			return;
		}

		if (!checkAllowedToLoad()) {
			return;
		}

		const payload = {
			fromDate,
			toDate,
			skip: (page - 1) * DEFAULT_LIMIT,
			limit: DEFAULT_LIMIT, ...Object.entries(filter).reduce((acc, [key, selected]) => {
				acc[key] = selected.map(element => element.value);
				return acc;
			}, {})
		}

		const response = await makeRequest(getResignFeedbackReport.bind(null, payload));

		if (!response?.data?.resigns) {
			return addMessage("Error loading data", "error");
		}

		const nextData = {
			...(reportData || {}),
			[page]: response.data.resigns
		};

		setReportData(() => nextData);
		setLoadedPages(() => [...loadedPages, page]);
		setCount(response.data.count);
	}

	const handleInputPageChange = (({ target: { value } }) => {
		setInputPage(value);
		validateInputPage(value);
	});

	const validateInputPage = (page) => {
		if (!page) {
			return;
		}

		if (page < 1) {
			return setInputPage(1);
		}

		const totalPages = getTotalPages(count, DEFAULT_LIMIT);

		if (page > totalPages) {
			return setInputPage(totalPages);
		}
	};

	const handleResetFilters = () => {
		setSearchParams({
			page: DEFAULT_PAGE
		})
		dispatchFilter({ type: FILTER_ACTIONS.RESET });
		reloadFilterData(DEFAULT_FILTER)
	}

	const loadTags = async () => {
		const response = await makeRequest(getResignTags);

		if (!response?.data) {
			return addMessage("Error while loading tags", "error");
		}

		const mappedOptions = response.data
			.map(tag => ({ value: tag._id, label: <span style={{ color: Colors.darkGray2 }}>{tag.label}</span> }));
		const cleanOptions = response.data
			.map(tag => ({ value: tag._id, label: tag.label }));

		setTagsOptions(() => mappedOptions);
		setCleanTagOptions(() => cleanOptions);
		return mappedOptions;
	};

	useEffect(() => {
		loadTags();
	}, [])

	useEffect(() => {
		loadData();
	}, [page, fromDate, toDate])

	const handleSetFrom = (e) => {
		setFromDate(e.target.value);
		setLoadedPages([])
		setSearchParams({
			page: DEFAULT_PAGE
		})
		setInputPage(DEFAULT_PAGE)
	}

	const handleSetTo = (e) => {
		setToDate(e.target.value);
		setLoadedPages([]);
		setSearchParams({
			page: DEFAULT_PAGE
		})
		setInputPage(DEFAULT_PAGE)
	}

	console.log(reportData)
	return (
		<ContentWrapper>
			{hasUnfilledRequest(getResignFeedbackReport, getResignTags) && <Loading />}
			<Container>
				<HeadingWrapper>
					<div />
					<div style={{ display: "flex", gap: "8px" }}>
						<Input
							width={40}
							name="From"
							type='date'
							color={Colors.darkBlue}
							value={fromDate}
							onChange={handleSetFrom}
						/>
						<Input
							width={40}
							name="To"
							type='date'
							color={Colors.darkBlue}
							value={toDate}
							onChange={handleSetTo}
						/>
					</div>
					<ButtonsWrapper>
						<ActionButton
							disabled={!checkIsFilterApplied(filter)}
							defaultText="Reset filters"
							onClick={() => handleResetFilters()}
							style={{ height: "fit-content" }}
						/>
						{reportData?.[page] && (
							<CSV
								header={HEADERS}
								data={getCSVBody({
									data: reportData?.[page],
									markets,
									products,
									page,
									cleanTagOptions,
								})}
								filename={`ResignFeedbackReport.csv`}
							/>)}
					</ButtonsWrapper>
				</HeadingWrapper>
				<TableWrapper>
					{reportData?.[page] && (
						<Table
							style={{ width: "100%" }}
							className="styled-table sticky-header"
							headersArray={getHeadersArray(filter, setOpenAction)}
							raws={getRaws({ data: reportData[page], markets, products, page, cleanTagOptions })}
						/>
					)}
				</TableWrapper>
			</Container>
			<Pagination
				count={count}
				setSearchParams={setSearchParams}
				handleInputPageChange={handleInputPageChange}
				inputPage={inputPage}
				page={page}
			/>
			{openAction && (
				<FilterSetter
					openAction={openAction}
					tagsOptions={tagsOptions}
					setOpenAction={setOpenAction}
					filter={filter}
					dispatchFilter={dispatchFilter}
					reloadData={reloadFilterData}
					setSearchParams={setSearchParams}
				/>
			)}
		</ContentWrapper>
	)
}

export default ResignFeedbackReport
