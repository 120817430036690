import React, { useEffect, useState } from 'react'
import RecruitmentSearchBar from './components/recruitmentSearchBar/RecruitmentSearchBar'
import { PageWrapper } from '@/common/styles/Wrappers'
import CandidateForm from './components/candidateForm/CandidateForm';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { getCallCenterPositions } from '@/API/repositories/callCenterPosition';
import { getPages } from '@/API/repositories/recruitmentPage';
import { getCallCenterCandidates } from '@/API/repositories/callCenterCandidate';
import { CALL_CENTER_POSITION_OPTIONS } from '../callCenterPosition/constants/callCenterPositions';
import CandidatesTable from './components/candidatesTable/CandidatesTable';
import Loading from '@/common/components/Loading';
import { useSearchParams } from 'react-router-dom';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';

const Recruitment = () => {
  const [showCandidateForm, setShowCandidateForm] = useState(false);
  const [callCenterPositionsOptions, setCallCenterPositionsOptions] = useState([]);
  const [recruitmentPagesOptions, setRecruitmentPagesOptions] = useState([]);
  const [callCenterCandidates, setCallCenterCandidates] = useState([]);
  const [visibleCandidates, setVisibleCandidates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const loadBaseData = async () => {
    await Promise.all([
      loadCallCenterPositions(),
      loadRecruitmentPages(),
      loadCandidates()]);
  };

  const loadCandidates = async () => {
    const response = await makeRequest(getCallCenterCandidates);

    if (!response?.data) {
      return addMessage("Couldn't load call center candidates", "error");
    }

    setCallCenterCandidates(response.data);
  };

  const loadCallCenterPositions = async () => {
    const response = await makeRequest(getCallCenterPositions);

    if (!response?.data) {
      return addMessage("Couldn't load call center positions", "error");
    }

    setCallCenterPositionsOptions(response.data.map(({ title, _id, market }) => ({ label: CALL_CENTER_POSITION_OPTIONS.find(o => o.value === title)?.label, value: _id, market: market })));
  };

  const loadRecruitmentPages = async () => {
    const response = await makeRequest(getPages);

    if (!response?.data) {
      return addMessage("Couldn't load recruitment pages", "error");
    }

    setRecruitmentPagesOptions(response.data.map(({ title, _id, market }) => ({ label: title, value: _id, market: market })));
  };

  const selectBySearchParams = () => {
    if (searchParams.has("candidate")) {
      const candidate = callCenterCandidates.find(c => c._id === searchParams.get("candidate"));
  
      if (candidate) {
        setShowCandidateForm(candidate);
        setSearchParams("");
      }
    }
  }

  useEffect(() => {
    loadBaseData();
  }, []);

  useEffect(() => {
    selectBySearchParams();
  }, [searchParams, callCenterCandidates]);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getCallCenterCandidates, getCallCenterPositions, getPages) && <Loading />}
      <RecruitmentSearchBar 
        setVisibleCandidates={setVisibleCandidates} 
        setShowCandidateForm={setShowCandidateForm}
        candidates={callCenterCandidates}
      />
      {showCandidateForm && (
        <CandidateForm 
          callCenterPositionsOptions={callCenterPositionsOptions}
          recruitmentPagesOptions={recruitmentPagesOptions}
          setShow={setShowCandidateForm}
          selectedCandidate={showCandidateForm}
          reload={loadCandidates}
        />
      )}
      <CandidatesTable setShowCandidateForm={setShowCandidateForm} candidates={visibleCandidates} />
    </PageWrapper>
  )
}

export default Recruitment
