import React from "react";
import ContactInformation from "./components/contactInformation/ContactInformation";
import Documents from "./components/documents/Documents";
import ValidClaimChat from "./components/validClaimChat/ValidClaimChat";
import { InfoSideWrapper } from "./InfoSide.styled";
import CommentForAccounting from "./components/commentForAccounting/CommentForAccounting";

const InfoSide = ({ handleValidClaimUpdate, claim }) => {
  return (
    <InfoSideWrapper>
      <ContactInformation handleValidClaimUpdate={handleValidClaimUpdate} />
      <Documents />
      {claim?._id && <CommentForAccounting claim={claim} />}
      <ValidClaimChat
        chatHeight={270}
        chatWidth={360}
        handleValidClaimUpdate={handleValidClaimUpdate}
      />
    </InfoSideWrapper>
  );
};

export default InfoSide;
