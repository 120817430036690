import React, { useState } from "react";
import PopUp from "../PopUp";
import { useFormik } from "formik";
import Input from "../Input";
import { Colors } from "@/common/colors/colors";
import TextArea from "../TextArea";
import SelectInput from "../SelectInput";
import ToggleSwitch from "../ToggleSwitch";
import { ButtonWrapper, Form, Title } from "./TodoForm.styled";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { CONSULTANT_MANAGER_ROLES } from "@/common/constants/consultantRoles";
import { getBase64 } from "@/common/functions/getBase64";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "../Loading";
import { createTodo, updateTodo } from "@/API/repositories/todo";
import moment from "moment";
import Icon from "../Icon";
import { getFileById } from "@/API/repositories/storedDocument";
import userManager from "@/API/userManager";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "../buttons/SubmitButton";

const TodoForm = ({
  setIsOpen,
  loadData,
  setTodos,
  selectedTodo,
  setSelectedTodo,
}) => {
  const [isFileLoading, setIsFileLoading] = useState();
  const userRole = userManager.getUser().role;
  const isConsultantManager = CONSULTANT_MANAGER_ROLES.includes(userRole);

  const {
    filterUsersByRoles,
    options: { usersOptions },
  } = useCommonDataContext();
  const { addMessage } = useMessageQueueContext();

  const formik = useFormik({
    initialValues: {
      title: selectedTodo?.title || "",
      deadline: selectedTodo
        ? moment(selectedTodo.deadline).format("YYYY-MM-DD HH:mm")
        : "",
      user: selectedTodo?.user
        ? usersOptions.find((option) => selectedTodo.user === option.value)
        : [],
      base64: "",
      high_priority: selectedTodo?.high_priority || false,
      description: selectedTodo?.description || "",
    },
    onSubmit: (values) => handleSubmit(formatBeforeSubmit(values)),
  });

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleFileSelect = async (e) => {
    if (!e.target.files[0]) {
      return formik.setFieldValue("base64", "");
    }

    const base64 = await getBase64(e.target.files[0]);
    formik.setFieldValue("base64", base64);
  };

  const formatBeforeSubmit = (values) => {
    const payload = { ...values };

    payload.user = payload.user.value;

    return payload;
  };

  const handleGetStoredDocument = async () => {
    if (!selectedTodo?._stored_document) {
      return addMessage("No file to get", "error");
    }
    setIsFileLoading(() => true);

    const response = await makeRequest(
      getFileById.bind(null, selectedTodo._stored_document._id)
    );

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }
    setIsFileLoading(() => false);
  };

  const handleSubmit = async (payload) => {
    const request = selectedTodo ? updateTodo : createTodo;
    const response = await makeRequest(
      request.bind(null, payload, selectedTodo?._id)
    );
    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setIsOpen(() => false);
    setTodos(() => null);
    loadData();
  };

  const handleClose = () => {
    setIsOpen(() => false);
    setSelectedTodo(() => null);
  };

  return (
    <PopUp padding="10px 20px 30px" setShow={handleClose}>
      {hasUnfilledRequest(createTodo, updateTodo) && <Loading />}
      <Title>{selectedTodo ? "Edit " : "Create "} todo</Title>
      <Form onSubmit={formik.handleSubmit}>
        <Input
          id="title"
          color={Colors.darkBlue}
          width={130}
          inputWidth={400}
          name="Title"
          required
          requiredSign
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <Input
          id="deadline"
          color={Colors.darkBlue}
          width={130}
          type="datetime-local"
          name="Deadline"
          required
          inputWidth={400}
          requiredSign
          onChange={formik.handleChange}
          value={formik.values.deadline}
        />
        {!isConsultantManager && (
          <SelectInput
            id="user"
            name="User"
            width={130}
            selectWidth={400}
            required
            setSelected={(value) => formik.setFieldValue("user", value)}
            color={Colors.darkBlue}
            options={filterUsersByRoles(CONSULTANT_MANAGER_ROLES)}
            selected={formik.values.user}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Input
            id="base64"
            color={Colors.darkBlue}
            width={130}
            type="file"
            name="Attachment"
            inputWidth={400}
            onChange={handleFileSelect}
            value={formik.values.attachment}
          />
          {selectedTodo?._stored_document && (
            <Icon
              onClick={() => handleGetStoredDocument()}
              size={24}
              name={isFileLoading ? "fa fa-spin fa-spinner" : "fa fa-file"}
            />
          )}
        </div>
        <ToggleSwitch
          name="high_priority"
          onChange={formik.handleChange}
          text="High priority:"
          width={138}
          checked={formik.values.high_priority}
        />
        <TextArea
          name="description"
          onChange={formik.handleChange}
          required
          label="Description"
          placeholder="describe what needs to be done..."
          width="100%"
          marginLeft="20px"
          defaultValue={formik.values.description}
        />
        <ButtonWrapper>
          <SubmitButton text="Save" style={{ margin: "20px 0 0" }} />
        </ButtonWrapper>
      </Form>
    </PopUp>
  );
};

export default TodoForm;
