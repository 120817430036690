import styled from "styled-components";

export const MessageWrapper = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
`;

export const MessageOriginal = styled.p`
  text-align: right;
  width: 100%;
`;

export const MessageDate = styled.p`
  font-size: 8px;
  text-align: right;
`;

export const Textarea = styled.textarea`
  width: 350px;
  resize: none;
  font-size: 12px;
`;

export const Form = styled.form`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
`;
