import moment from "moment";
import React, { useRef, useState } from "react";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import { useRequestsContext } from "@/common/hooks/requestHook";
import Loading from "@/common/components/Loading";
import { addToQueue, getArchives } from "@/API/repositories/order";
import { Flex } from "./VoipArchive.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import ArchiveTable from "./components/archiveTable/ArchiveTable";
import AddToQueue from "./components/addToQueue/AddToQueue";
import { PageWrapper } from "@/common/styles/Wrappers";
import { SearchBarColumn } from "@/common/styles/SearchBars";
import { getToday } from "@/common/functions/getToday";
import { formatDatesPayload } from "@/common/functions/formatDatesPayload";
import { formatDateRef } from "@/common/functions/formatDateRef";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const VoipArchive = () => {
  const [data, setData] = useState();
  const fromRef = useRef();
  const toRef = useRef();
  const today = getToday();
  const monthAgo = formatToDateTamplate(moment().subtract("1", "month"));
  const [selectedToQueue, setSelectedToQueue] = useState([]);
  const [confirmAddToQueue, setConfirmAddToQueue] = useState(false);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e && e.preventDefault();

    const payload = formatDatesPayload({
      to: formatDateRef(toRef, today, "start"),
      from: formatDateRef(fromRef, monthAgo, "end"),
    });

    const response = await makeRequest(getArchives.bind(null, payload));

    if (response.data) {
      setSelectedToQueue(() => []);
      addMessage(`Found ${response.data.length} archives`, "error");
      setData(() => response.data);
    } else {
      addMessage(`There is no archives`, "info");
    }
  };

  const handleAddOrdersToQueue = async () => {
    const response = await makeRequest(addToQueue.bind(null, selectedToQueue));

    if (response.data) {
      setSelectedToQueue(() => []);
      addMessage("Added to queue", "success");
      await handleSearch();
    } else {
      addMessage("Error", "error");
    }

    setConfirmAddToQueue(() => false);
  };

  const { addMessage } = useMessageQueueContext();

  return (
    <PageWrapper>
      <SearchBarColumn>
        <Flex>
          <Input
            name="From"
            type="date"
            inputWidth={160}
            color={Colors.darkBlue}
            width={80}
            inputRef={fromRef}
            value={monthAgo}
          />
          <Input
            inputRef={toRef}
            name="To"
            type="date"
            width={20}
            inputWidth={160}
            color={Colors.darkBlue}
            value={today}
          />
        </Flex>
        <Flex>
          {!!data?.length && (
            <>
              <ActionButton
                onClick={() => setSelectedToQueue(() => data.map((d) => d._id))}
                defaultText="Select All"
              />
              <ActionButton
                onClick={() => setSelectedToQueue(() => [])}
                defaultText="Unselect All"
              />
              <ActionButton
                onClick={(e) => setConfirmAddToQueue(true)}
                defaultText="Add to QUEUE"
              />
            </>
          )}
          <ActionButton
            defaultText="Search"
            onClick={(e) => handleSearch(e)}
          />
        </Flex>
      </SearchBarColumn>
      {data && data.length !== 0 && (
        <ArchiveTable
          data={data}
          selectedToQueue={selectedToQueue}
          setSelectedToQueue={setSelectedToQueue}
        />
      )}
      {confirmAddToQueue && (
        <AddToQueue
          setConfirmAddToQueue={setConfirmAddToQueue}
          selectedToQueue={selectedToQueue}
          handleAddOrdersToQueue={handleAddOrdersToQueue}
        />
      )}
      {hasUnfilledRequest(getArchives, addToQueue) && <Loading />}
    </PageWrapper>
  );
};

export default VoipArchive;
