import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import moment from "moment";
import React from "react";

const OrderWithDeliveryTable = ({
  displayedContact,
  handleShowSearchedOrders,
  getClaimMAX,
  getColor,
  setClaim,
  setResigned,
  getTrackingLink,
  setEditData,
  setEdit,
  setInvoice,
}) => {
  const ordersWithDelivery = displayedContact.orders.filter(
    (order) => !order.payment?.length
  );

  return (
    !!ordersWithDelivery?.length && (
      <table className="styled-table">
        <thead>
          <tr style={{ width: "fit-content" }}>
            <th>No. (shipping)</th>
            <th>Status</th>
            <th>Shipping status</th>
            <th>Items</th>
            <th>LMS Sent</th>
            <th>LMS Delivered NOP</th>
            <th>LMS Delivered</th>
            <th>MAX claim date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ordersWithDelivery.map((data, i) => (
            <tr key={i}>
              <td
                style={{
                  minWidth: "40px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {data?.shipping?.order_number}
              </td>
              <td>{data.status}</td>
              <td style={{ minWidth: "140px", textAlign: "center" }}>
                {data.shipping?.status ? data.shipping.status : "---------"}
              </td>
              <td style={{ minWidth: "100px", textAlign: "center" }}>
                {data.order_items.length > 0
                  ? data.order_items
                      .map((data) => " " + data.product_variant)
                      .toString()
                  : "---------"}
              </td>
              <td style={{ minWidth: "100px", textAlign: "center" }}>
                {data.shipping?.status_lms_sent_at
                  ? moment(data.shipping?.status_lms_sent_at).format(
                      "YYYY-MM-DD"
                    )
                  : "---------"}
              </td>
              <td style={{ minWidth: "170px", textAlign: "center" }}>
                {data.shipping?.status_delivered_no_payment_at
                  ? moment(
                      data.shipping?.status_delivered_no_payment_at
                    ).format("YYYY-MM-DD")
                  : "---------"}
              </td>
              <td style={{ minWidth: "140px", textAlign: "center" }}>
                {data.shipping?.status_delivered_at
                  ? moment(data.shipping?.status_delivered_at).format(
                      "YYYY-MM-DD"
                    )
                  : "---------"}
              </td>
              <td
                style={{
                  minWidth: "150px",
                  textAlign: "center",
                  color: getColor(
                    data.shipping?.status_delivered_at ||
                      data.shipping?.status_delivered_no_payment_at
                  ),
                }}
              >
                {data.shipping?.status_delivered_at ||
                data.shipping?.status_delivered_no_payment_at
                  ? getClaimMAX(
                      data.shipping?.status_delivered_at ||
                        data.shipping?.status_delivered_no_payment_at
                    )
                  : "---------"}
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                    margin: "10px",
                    minWidth: "300px",
                  }}
                >
                  <ActionButton
                    style={{ fontSize: "12px" }}
                    onClick={(e) => handleShowSearchedOrders(e, data._id)}
                    defaultText="Show Order"
                    color={Colors.darkBlue}
                  />
                  <ActionButton
                    disabled={
                      (!data.shipping?.status_delivered_at &&
                        !data.shipping?.status_delivered_no_payment_at)
                    }
                    style={{ fontSize: "12px" }}
                    onClick={(e) => setClaim(data)}
                    defaultText="Claim"
                    color={Colors.darkGray2}
                  />
                  <ActionButton
                    color={Colors.green}
                    disabled={data.status === "resigned"}
                    style={{ fontSize: "12px" }}
                    onClick={(e) => {
                      setResigned(data);
                    }}
                    defaultText="Resign"
                  />
                  <ActionButton
                    disabled={!data.shipping?.waybill_number}
                    style={{ fontSize: "12px" }}
                    onClick={() =>
                      window.open(
                        getTrackingLink(
                          displayedContact.country,
                          data.shipping
                        ),
                        "_blank"
                      )
                    }
                    defaultText="Check Post"
                    color="#000"
                  />
                  <ActionButton
                    style={{ fontSize: "12px" }}
                    disabled={!data.shipping}
                    onClick={() => {
                      setEditData(data);
                      setEdit(true);
                    }}
                    color={Colors.red}
                    defaultText="Set Delivered"
                  />
                  <ActionButton
                    style={{ fontSize: "12px" }}
                    defaultText="Invoice"
                    onClick={() => {
                      setInvoice(data);
                    }}
                    disabled={data._invoices.length > 0}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  );
};

export default OrderWithDeliveryTable;
