import React, { useState } from "react";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import DownloadTable from "@/common/components/downloadTable/DownloadTable";
import NoResponseShiftTable from "@/common/components/noResponseShiftTable/NoResponseShiftTable";
import { WorkingByTake } from "@/common/components/workingByTake/WorkingByTake";
import SearchBar from './components/searchBar/SearchBar'

const DailyReport = () => {
  const [data, setData] = useState();

  const { commonData } = useCommonDataContext();
  const { users } = commonData;

  return (
    <WrapperWithScroll>
			<SearchBar setData={setData} />
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
      {data && data.length > 0 && (
        <DownloadTable
          data={data.map((element) => element._inbound_history)}
          consultants={users}
          name={"Inbound"}
        />
      )}
      {data && data.length > 0 && (
        <NoResponseShiftTable
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
      {data && data.length > 0 && (
        <WorkingByTake
          data={data.map((element) => element._call_history)}
          consultants={users}
          name={"Outbound"}
        />
      )}
    </WrapperWithScroll>
  );
};

export default DailyReport;
