import React, { useEffect, useReducer, useState } from "react";
import { getMailsByFilter } from "../../../API/repositories/mail";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { copyLinkToClipboard } from "../../../common/functions/copyLinkToClipboard";
import { getMailTemplates } from "../../../API/repositories/mailTemplate";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import MailSearchBar from "./components/mailSearchBar/MailSearchBar";
import { Container, Flex, Wrapper } from "./MailSearch.styled";
import MailOptionPopUp from "./components/mailOptionPopUp/MailOptionPopUp";
import { INITIAL_STATE } from "./constants/fiter.constants";
import Table from "@/common/components/Table";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { reducer } from "./helpers/reducer";


const MailSearch = () => {
  const [filters, dispatchFilters] = useReducer(reducer, INITIAL_STATE);
  const [showFilters, setShowFilters] = useState();
  const [data, setData] = useState();
  const [templateOptions, setTemplateOptions] = useState([]);

  const { commonData } = useCommonDataContext();
  const { users } = commonData;
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleCopyIDToClipboard = (id) => {
    copyLinkToClipboard(id);
    addMessage("copied", "success");
  };

  const handleLoadData = async () => {
    const responseTemplates = await makeRequest(getMailTemplates);

    if (responseTemplates.data) {
      setTemplateOptions(() =>
        responseTemplates.data.map((e) => ({
          label: e.template_sengrid_name,
          value: e.template_sengrid_id,
        }))
      );
    }
  };

  useEffect(() => {
    handleLoadData();
  }, [users]);

  return (
    <Wrapper>
      {hasUnfilledRequest(getMailsByFilter) && <Loading />}
      <MailSearchBar
        filters={filters}
        setShowFilters={setShowFilters}
        setData={setData}
      />
      <Container>
        {!!data?.length ? (
          <Table
            className="styled-table" 
            headers={HEADERS} 
            raws={getRaws(data, handleCopyIDToClipboard)}
          />
        ) : data ? (
          <Flex>
            <h3 style={{ color: "red" }}>No results</h3>
          </Flex>
        ) : (
          ""
        )}
        {showFilters && (
          <MailOptionPopUp
            templateOptions={templateOptions}
            setShow={setShowFilters}
            filters={filters}
            dispatchFilters={dispatchFilters}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default MailSearch;
