import { Colors } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import { WORKING_HOURS } from "@/common/constants/CC";
import React, { useMemo, useState } from "react";
import { Title } from "../calendar.styles";
import ActionButton from "@/common/components/buttons/ActionButton";
import { ButtonWrapper } from "@/pages/admin/claimNotEnded/claimNotEnded.styles";
import { WorkingHoursWrapper } from "@/pages/admin/ccHours/CCHours.styled";
import userManager from "@/API/userManager";
import Icon from "@/common/components/Icon";
import styled from 'styled-components';
import HourForm from "../../../../../common/components/hourForm/HourForm";
import { getValidBonuses } from "@/common/functions/getValidBonuses";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const HoursContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Hour = styled.span`
  color: ${({ isSet }) => (isSet ? Colors.green : Colors.darkBlue)};
  font-weight: bold;
`;

const AddWorkedHours = ({
  workingHoursOneSave,
  hanldeOnChange,
  handleSaveConsultantHours,
  setAddWorkedHours,
  addWorkedHours,
  bonuses = [],
  handleRemoveConsultantHours
}) => {
  const [showInfo, setShowInfo] = useState();
  const [showHourFill, setShowHourFill] = useState();

  const { translation } = useTranslationContext();
  const { addMessage } = useMessageQueueContext();

  const availableBonuses = useMemo(() => {
    return getValidBonuses(addWorkedHours, bonuses, addMessage);
  }, [bonuses]);

  const user = userManager.getUser();

  const isHourSet = (hour) => {
    return workingHoursOneSave[hour] && workingHoursOneSave[hour].sign && workingHoursOneSave[hour].bonusId;
  };

  const handleRemoveHour = (hour) => {
    handleRemoveConsultantHours(hour);
  }

  return (
    <PopUp setShow={setAddWorkedHours}>
      {showInfo && (
        <PopUp setShow={setShowInfo}>
          <table className="styled-table" style={{ maxWidth: "800px" }}>
            <thead>
              <tr>
                <th>{translation["hours_thead_one"]}</th>
                <th>{translation["hours_thead_two"]}</th>
                <th>{translation["hours_thead_three"]}</th>
                <th>{translation["hours_thead_four"]}</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: Colors.green }}>
                <td>{translation["one_description"]}</td>
                <td>1</td>
                <td>{translation["Attendance at work"]}</td>
                <td>0,5 h</td>
              </tr>

              {user.contract_type !== "CZ_DPP" && (
                <>
                  <tr style={{ backgroundColor: Colors.orange }}>
                    <td rowspan="3">{translation["two_description"]}</td>
                    <td>h</td>
                    <td>{translation["one_short_description"]}</td>
                    <td rowspan="3">0,5 h</td>
                  </tr>
                  <tr style={{ backgroundColor: Colors.orange }}>
                    <td>s</td>
                    <td>{translation["two_short_description"]}</td>
                  </tr>
                  <tr style={{ backgroundColor: Colors.orange }}>
                    <td>r</td>
                    <td>{translation["three_short_description"]}</td>
                  </tr>
                </>
              )}

              <tr style={{ backgroundColor: "white" }}>
                <td> {translation["three_description"]}</td>
                <td>/</td>
                <td>{translation["four_short_description"]}</td>
                <td>0,5 h</td>
              </tr>
              <tr style={{ backgroundColor: Colors.orange }}>
                <td> {translation["four_description"]}</td>
                <td>u</td>
                <td>{translation["five_short_description"]}</td>
                <td>0,5 h</td>
              </tr>
              <tr style={{ backgroundColor: Colors.red }}>
                <td> {translation["five_description"]}</td>
                <td>n</td>
                <td> {translation["six_short_description"]}</td>
                <td>0,5 h</td>
              </tr>

              {user.role === "CALL_CENTER_MANAGER" && (
                <tr style={{ backgroundColor: Colors.lightBlue }}>
                  <td> {translation["six_description"]}</td>
                  <td>m</td>
                  <td> {translation["seven_short_description"]}</td>
                  <td>0,5 h</td>
                </tr>
              )}
            </tbody>
          </table>
        </PopUp>
      )}
      <Title>
        Hours for day: {addWorkedHours}{" "}
        <i className="fa fa-info-circle" onClick={() => setShowInfo(true)} />
      </Title>
      <WorkingHoursWrapper>
        {WORKING_HOURS.map((hour, i) => (
          <HoursContainer key={i}>
            <Hour isSet={isHourSet(hour)}>{hour}</Hour>
            <Icon onClick={() => setShowHourFill(hour)} color={isHourSet(hour) ? Colors.green : Colors.darkBlue} name={isHourSet(hour) ? 'fa fa-check' : 'fa fa-edit'} />
            {isHourSet(hour) && <Icon color={Colors.red} name="fa fa-trash" onClick={() => handleRemoveHour(hour)} />}
          </HoursContainer>
        ))}
        {showHourFill && (
          <HourForm
            addWorkedHours={addWorkedHours}
            availableBonuses={availableBonuses}
            setShowHourFill={setShowHourFill}
            showHourFill={showHourFill}
            workingHoursOneSave={workingHoursOneSave}
            hanldeOnChange={hanldeOnChange}
          />
        )}
      </WorkingHoursWrapper>
      <ButtonWrapper style={{ marginBottom: "-20px" }}>
        <ActionButton
          defaultText="Save"
          onClick={(e) => handleSaveConsultantHours(e)}
        />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddWorkedHours;
