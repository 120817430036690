import ActionButton from '@/common/components/buttons/ActionButton'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@/common/styles/Wrappers';
import { ContentWrapper } from './ResignTag.styled';
import { useRequestsContext } from '@/common/hooks/requestHook';
import Loading from '@/common/components/Loading';
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue';
import { getResignTags } from '@/API/repositories/resign-tag';
import ResignTagForm from './components/resignTagForm/ResignTagForm';
import ResignTagTable from './components/resignTagTable/ResignTagTable';

const ResignTag = () => {
  const [show, setShow] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const [tags, setTags] = useState([]);

  const { addMessage } = useMessageQueueContext();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const loadTags = async () => {
    setTags([]);
    const response = await makeRequest(getResignTags);

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }

    setTags(response.data);
  };


  useEffect(() => {
    loadTags();
  }, []);

  return (
    <PageWrapper>
      {hasUnfilledRequest(getResignTags) && <Loading />}
      <ContentWrapper>
        <ActionButton
          defaultText="Add tag"
          onClick={() => {
            setSelectedTag(null)
            setShow(true)
          }}
        />
      </ContentWrapper>
      {show && <ResignTagForm selectedTag={selectedTag} reload={loadTags} setShow={setShow} />}
      {!!tags?.length && (
        <ResignTagTable setSelectedTag={setSelectedTag} setShow={setShow} tags={tags} />
      )}
    </PageWrapper>
  )
}

export default ResignTag
