import React from "react";

const EffectiveReportConsultant = ({
  weights,
  products,
  markets,
  users,
  data,
}) => {
  return (
    <div style={{ width: "80vw" }}>
      <div style={{ marginBottom: "40px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Consultant</th>
              <th>Market</th>
              <th>Product</th>
              {weights && <th>Download no</th>}
              <th>COD number</th>
              <th>COD sum</th>
              <th>COD delivered number</th>
              <th>COD delivered sum</th>
              <th>WPPK</th>
              <th>WPPK delivered</th>
              <th>Number of downloaded orders</th>
              <th>Shifts</th>
              <th>Confirmed</th>
              <th>No_response</th>
              <th>Informed</th>
              <th>Trash</th>
              <th>Resigned</th>
              <th>Weight sum</th>
            </tr>
          </thead>
          <tbody className="queue">
            {data
              .sort((a, b) => {
                if (weights)
                  return (
                    parseInt(a._id.split("-")[1]) -
                    parseInt(b._id.split("-")[1])
                  );
                return 1;
              })
              .sort((a, b) => {
                if (weights)
                  return a._id.split("-")[0].localeCompare(b._id.split("-")[0]);
                return a._id.localeCompare(b._id);
              })
              .map((reportElementScore) => (
                <tr key={reportElementScore._id}>
                  <td>
                    {weights &&
                      users.find(
                        (a) => a.value === reportElementScore._id.split("-")[0]
                      ).label}
                    {!weights &&
                      users.find((a) => a.value === reportElementScore._id)
                        .label}
                  </td>
                  <td>
                    {
                      markets.find((a) => a.value === reportElementScore.market)
                        .label
                    }
                  </td>
                  <td>
                    {
                      products.find(
                        (a) => a.value === reportElementScore.product
                      ).label
                    }
                  </td>
                  {weights && <td>{reportElementScore._id.split("-")[1]}</td>}
                  <td>{reportElementScore.cod_count}</td>
                  <td>{Math.round(reportElementScore.cod_sum)}</td>
                  <td>{reportElementScore.cod_delivered_count}</td>
                  <td>{reportElementScore.cod_delivered_sum}</td>
                  <td>
                    {reportElementScore.weight_all_sum > 0
                      ? Math.round(
                        parseFloat(
                          reportElementScore.cod_sum /
                          reportElementScore.weight_all_sum
                        ) * 100
                      ) / 100
                      : 0}
                  </td>
                  <td>
                    {reportElementScore.weight_all_sum > 0
                      ? Math.round(
                        parseFloat(
                          reportElementScore.cod_delivered_sum /
                          reportElementScore.weight_all_sum
                        ) * 100
                      ) / 100
                      : 0}
                  </td>
                  <td>{reportElementScore.dequeue_count}</td>
                  <td>{reportElementScore.shift}</td>
                  <td>{reportElementScore.confirmed}</td>
                  <td>{reportElementScore.no_response}</td>
                  <td>{reportElementScore.informed}</td>
                  <td>{reportElementScore.trash}</td>
                  <td>{reportElementScore.resigned}</td>
                  <td>
                    {Math.round(
                      parseFloat(reportElementScore.weight_all_sum) * 100
                    ) / 100}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EffectiveReportConsultant;
