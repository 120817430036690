export const handleCountNotPickUp = (data) => {
  return data.filter((element) => !element.consultant)?.length || 0;
};

export const countRealTimeCalling = (data) => {
  const seconds = data.reduce(
    (prev, next) => prev + (next.talking_time || 0),
    0
  );

  const result = `${Math.floor(seconds / 60)} minutes ${
    seconds % 60
  } seconds`;

  return result;
};

const countMinutes = (seconds) => {
  return Math.ceil(seconds / 60);
};

export const countClouTalkCalling = (data) => {
  return `${data.reduce(
    (prev, next) => prev + countMinutes(next.talking_time || 0),
    0
  )} minutes`;
};