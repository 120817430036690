import moment from "moment";
import React, { useMemo } from "react";
import { IconWrapper } from "../CallingSearch.styled";
import Table from "../../../../common/components/Table";
import { Link } from "react-router-dom";

const CallingSearchTable = ({ data }) => {
  const rows = useMemo(
    () =>
      data?.map((call, i) => (
        <tr key={call._id}>
          <td>{i + 1}.</td>
          <td>{call._consultant?.username || "-----"}</td>
          <td>{call.billsec || "-----"}</td>
          <td>{call.call_center_phone_number || "-----"}</td>
          <td>{call.client_phone_number || "-----"}</td>
          <td>{call.cost || "-----"}</td>
          <td>
            {(call.started_at &&
              moment(call.started_at).format("YYYY-MM-DD HH:mm")) ||
              "-----"}
          </td>
          <td>
            {(call.answered_at &&
              moment(call.answered_at).format("YYYY-MM-DD HH:mm")) ||
              "-----"}
          </td>
          <td>
            {(call.ended_at &&
              moment(call.ended_at).format("YYYY-MM-DD HH:mm")) ||
              "-----"}
          </td>
          <td>{call.talking_time || "-----"}</td>
          <td>{call.type || "-----"}</td>
          <td>{call.waiting_time || "-----"}</td>
          <td>
            {call.recording_link ? (
              <a
                className="animation-scale"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href={call.recording_link}
                target="_blank"
              >
                Go cloudTalk
              </a>
            ) : (
              "-----"
            )}
          </td>
          <td>
            <IconWrapper>
              <Link
                className="fa fa-user animation-scale"
                to={`/settings/user/list?mode=show&userId=${call._consultant?._id}`}
              />
              <Link
                to={`/dashboard/contact/${call.contact}`}
                className="fa fa-address-book animation-scale"
              />
            </IconWrapper>
          </td>
        </tr>
      )) || [],
    [data]
  );

  const headers = [
    "No.",
    "User",
    "BillSec (s)",
    "CC number",
    "Client Number",
    "Cost (eur)",
    "Started",
    "Answered",
    "Ended",
    "Talking time (s)",
    "Type",
    "Waiting time (s)",
    "Recording",
    "Actions",
  ];

  return (
    <Table
      style={{ width: "100%" }}
      headers={headers}
      className="styled-table"
      raws={rows}
    />
  );
};

export default CallingSearchTable;
