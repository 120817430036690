import React, { useState } from "react";
import PopUp from "../../../common/components/PopUp";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import SelectInput from "../../../common/components/SelectInput";
import { updateListeningGenerator } from "../../../API/repositories/listeningGenerator";
import ListeningGeneratorAddRole from "./ListeningGeneratorAddRule";
import moment from "moment";
import ListeningGeneratorAddTable from "./ListeningGeneratorAddTable";
import { findSuperior } from "@/common/functions/findSuperior";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 40px;
`;

const Title2 = styled(Title)`
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
  margin-top: 100px;
  gap: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  color: ${Colors.darkBlue};
`;
const ListeningGeneratorEdit = ({
  setShow,
  managers,
  users,
  edit,
}) => {
  const [selectedManager, setSelectedManager] = useState(
    managers.find((m) => m.value === edit.manager)
  );
  const [rules, setRules] = useState(edit?.rules || []);
  const [addRole, setAddRole] = useState();
  const [toSave, setToSave] = useState();
  const [table, setTable] = useState(edit?.tableSettings || []);
  const [addTable, setAddTable] = useState();

  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSave = async () => {
    if (!selectedManager) {
      addMessage("Set Manager", "error");
      return;
    }

    const payload = {};
    payload.manager = selectedManager.value;
    payload._id = edit._id;
    payload.rules = rules;
    payload.table = table;

    const response = await makeRequest(
      updateListeningGenerator.bind(null, payload)
    );

    if (response.data) {
      addMessage("Saved", "success");
      setShow(() => null);
    } else {
      addMessage("Error", "error");
    }
  };

  const handleDeleteOneRule = (consultant) => {
    setToSave(() => true);
    setRules((prev) => prev.filter((rule) => rule.consultant !== consultant));
  };
  const handleDeleteOneTableElement = (value) => {
    setToSave(() => true);
    setTable((prev) => prev.filter((element) => element.value !== value));
  };

  return (
    <>
      <PopUp setShow={setShow}>
        <Title>Edit Li Gen:</Title>
        <SelectInput
          width={100}
          selectWidth={200}
          name="Manager"
          options={managers}
          selected={selectedManager}
          setSelected={setSelectedManager}
          color={Colors.darkBlue}
        />
        <Title2>Rules:</Title2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Consultant</th>
              <th>Called from</th>
              <th>Called to</th>
              <th>Minus days</th>
              <th>Statuses</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="queue">
            {rules[0] ? (
              rules.map((rule, i) => (
                <tr key={rule.consultant + 1}>
                  <td>{i + 1}.</td>
                  <td>
                    {users.find((u) => u._id === rule.consultant)?.username}
                  </td>
                  <td>
                    {(rule.calledFrom &&
                      moment(rule.calledFrom).format("DD/MM/YYYY")) ||
                      "----"}
                  </td>
                  <td>
                    {(rule.calledTo &&
                      moment(rule.calledTo).format("DD/MM/YYYY")) ||
                      "----"}
                  </td>
                  <td>{rule.minusDays || "----"}</td>
                  <td>{rule.statuses.map((e) => e.status).join(", ")}</td>
                  <td>
                    <IconWrapper>
                      <i
                        style={{ cursor: "pointer", color: Colors.red }}
                        className="fa fa-trash"
                        onClick={() => handleDeleteOneRule(rule.consultant)}
                      />
                      <i
                        style={{ cursor: "pointer", color: Colors.darkBlue }}
                        className="fa fa-edit"
                        onClick={() => {
                          setAddRole(rule);
                        }}
                      />
                    </IconWrapper>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
        <Title2>Table element</Title2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Value</th>
              <th>Label</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="queue">
            {table[0] ? (
              table
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((element) => (
                  <tr key={element.value}>
                    <td>{element.orderNumber}</td>
                    <td>{element.value}</td>
                    <td>{element.label}</td>
                    <td>
                      <IconWrapper>
                        <i
                          style={{ cursor: "pointer", color: Colors.red }}
                          className="fa fa-trash"
                          onClick={() =>
                            handleDeleteOneTableElement(element.value)
                          }
                        />
                        <i
                          style={{ cursor: "pointer", color: Colors.darkBlue }}
                          className="fa fa-edit"
                          onClick={() => setAddTable(element)}
                        />
                      </IconWrapper>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={4}>There is no data</td>
              </tr>
            )}
          </tbody>
        </table>
        <ButtonWrapper>
          <ActionButton
            defaultText="Add Table Element"
            onClick={() => setAddTable(true)}
          />
          <ActionButton
            defaultText="Add rule"
            onClick={() => setAddRole({})}
          />
          <ActionButton
            defaultText="Save"
            color={toSave ? Colors.red : Colors.orange}
            onClick={() => handleSave()}
          />
        </ButtonWrapper>
      </PopUp>
      {addRole && (
        <ListeningGeneratorAddRole
          setAddRole={setAddRole}
          allUsers={users.map((u) => ({ label: u.username, value: u._id }))}
          addRole={addRole}
          setRules={setRules}
          users={users
            .filter(
              (u) =>
                findSuperior(u?._id, users)?._id === edit.manager &&
                !rules.map((r) => r.consultant).includes(u._id)
            )
            .map((u) => ({ label: u.username, value: u._id }))}
          setToSave={setToSave}
        />
      )}
      {addTable && (
        <ListeningGeneratorAddTable
          setTableElemnets={setTable}
          setShow={setAddTable}
          addTable={addTable}
          setToSave={setToSave}
        />
      )}
    </>
  );
};

export default ListeningGeneratorEdit;
