import React, { useRef, useState } from "react";
import PopUp from "../../../common/components/PopUp";
import {
  AnswerWrapper,
  AnswerWrapperOptions,
  ButtonWrapper,
  Title,
} from "./styles/Test.styles";
import SelectInput from "../../../common/components/SelectInput";
import { ANSWER_OPTIONS } from "../../../common/constants/test";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import TextArea from "../../../common/components/TextArea";
import ActionButton from "@/common/components/buttons/ActionButton";

const TestAnswer = ({ answer, setShow, setAnswers }) => {
  const answerValueRef = useRef();
  const isCorrectRef = useRef();

  const [selectedAnswerType, setSelectedAnswerType] = useState(
    ANSWER_OPTIONS.find((ans) => ans.value === answer.type) || ANSWER_OPTIONS[0]
  );

  const handleAddAnswer = () => {
    const payload = {};

    payload.is_correct = isCorrectRef.current?.checked || false;
    payload.type = selectedAnswerType.value;
    payload.value = answerValueRef.current?.value || answer.value;

    setAnswers((prev) => [
      ...prev
        .filter((e) => answer.id !== e.id)
        .map((e, i) => ({ ...e, id: i + 1 })),
      {
        ...payload,
        id: prev.length + 1,
      },
    ]);
    setShow(() => null);
  };

  return (
    <PopUp setShow={setShow}>
      <AnswerWrapper>
        <Title>Answer:</Title>
        <AnswerWrapperOptions>
          <SelectInput
            name="Type"
            selected={selectedAnswerType}
            setSelected={setSelectedAnswerType}
            options={ANSWER_OPTIONS}
            width={40}
            selectWidth={150}
            color={Colors.darkBlue}
            placeholder="Select user..."
          />
          <Input
            inputRef={isCorrectRef}
            name="isCorrect"
            color={Colors.darkBlue}
            checked={answer.is_correct}
            width={80}
            inputWidth={30}
            type="checkbox"
          />
        </AnswerWrapperOptions>
        {selectedAnswerType.value === "text" && (
          <>
            <TextArea
              name="Value"
              color={Colors.darkBlue}
              textAreaRef={answerValueRef}
              width={500}
              defaultValue={answer.value}
            />
          </>
        )}
        {selectedAnswerType.value === "image" && <Input />}
        <ButtonWrapper
          style={{ justifyContent: "right", marginBottom: "-30px" }}
        >
          <ActionButton
            defaultText="Add"
            onClick={() => handleAddAnswer()}
          />
        </ButtonWrapper>
      </AnswerWrapper>
    </PopUp>
  );
};

export default TestAnswer;
