export const HEADERS = [
  "Consultant",
  "Attendance",
  "Holiday",
  "Special leave",
  "Sick leave",
  "Break during the day",
  "Excused unpaid leave",
  "Absence",
  "Manager hours",
  "Hours a day",
  "Actions",
  "Attachments",
];

export const CSV_HEADERS = [
  "No",
  "Date",
  "Attendance",
  "Holiday",
  "Special leave",
  "Sick leave",
  "Break during the day",
  "Excused unpaid leave",
  "Absence",
  "Manager hours",
  "Hours a day",
];
