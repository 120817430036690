export const PERSONAL_DOCUMENTS_OPTIONS = [
	{
		label: "probationary period",
		value: "probationary",
	},
	{
		label: "fixed-term contract",
		value: "fixed_term"
	},
	{
		label: "permanent contract",
		value: "permanent"
	},
	{
		label: "annex",
		value: "annex"
	}
]