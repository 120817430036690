import { addPersonalDocuments, updateToDate } from '@/API/repositories/user'
import AddPersonalDocument from '@/common/components/addPersonalDocument/AddPersonalDocument'
import Table from '@/common/components/Table'
import { PERSONAL_DOCUMENTS_OPTIONS } from '@/common/constants/personalDocuments'
import { formatToDateTamplate } from '@/common/functions/dateFormater'
import { useRequestsContext } from '@/common/hooks/requestHook'
import { useMessageQueueContext } from '@/common/hooks/useMessageQueue'
import React, { useMemo, useState } from 'react'
import { AddButton, AddButtonWrapper, Container, FlexCell } from './PersonalDocuments.styled'
import Icon from '@/common/components/Icon'

const HEADERS = ["No.", "Name", "From", "To"]

const PersonalDocuments = ({ userId, user }) => {
	const [showAddDocument, setShowAddDocument] = useState();
	const [showEditDocument, setShowEditDocument] = useState();
	const [editDocument, setEditDocument] = useState();
	const [triggerDocument, setTriggerDocuments] = useState(false);

	const { makeRequest } = useRequestsContext();
	const { addMessage } = useMessageQueueContext();

	const raws = useMemo(() => {
		return user.personal_documents?.map((doc, idx) => (
			<tr key={idx + 1}>
				<td>{idx + 1}</td>
				<td>{PERSONAL_DOCUMENTS_OPTIONS.find(d => d.value === doc.name)?.label}</td>
				<td>{formatToDateTamplate(doc.from)}</td>
				<td>
					<FlexCell>
						<span>{formatToDateTamplate(doc.to)}</span>
						<Icon onClick={() => { setEditDocument(doc); handleEdit(); }} name="fa fa-edit" />
					</FlexCell>
				</td>
			</tr>
		))
	}, [triggerDocument, userId])

	const handleEdit = async (newTo) => {
		if (!newTo?.length) {
			return setShowEditDocument(true)
		}

		const payload = {
			userId,
			oldTo: editDocument.to,
			newTo: newTo[0].to,
			name: editDocument.name,
		}

		const prevDocuments = JSON.parse(JSON.stringify(user.personal_documents));

		user.presonal_documents = user.personal_documents.map(doc => {
			if (doc.name === editDocument.name && doc.to === editDocument.to) {
				doc.to = newTo[0].to;
			}

			return doc;
		})

		setTriggerDocuments((prev) => !prev);
		setEditDocument(null);

		const response = await makeRequest(updateToDate.bind(null, payload));

		if (!response?.data) {
			user.personal_documents = prevDocuments;
			setTriggerDocuments((prev) => !prev)
			return addMessage("Something went wrong", "error")
		}
	}

	const handleAdd = async (document) => {
		if (!user.personal_documents) {
			user.personal_documents = [];
		}

		user.personal_documents.push(document[0]);
		setTriggerDocuments((prev) => !prev);

		const response = await makeRequest(addPersonalDocuments.bind(null, userId, document));

		if (!response?.data) {
			user.personal_documents.pop();
			return addMessage("Something went wrong", "error")
		}
	};

	return (
		<Container>
			<AddButtonWrapper>
				<AddButton
					onClick={() => {
						setShowAddDocument(true);
					}}
				>
					<i className="fa fa-plus" />
				</AddButton>
			</AddButtonWrapper>
			{
				!!user.personal_documents?.length && (
					<Table
						headers={HEADERS}
						raws={raws}
						className="styled-table"
					/>
				)
			}
			{showAddDocument && <AddPersonalDocument setShow={setShowAddDocument} submitFn={handleAdd} />}
			{showEditDocument &&
				<AddPersonalDocument
					setShow={setShowEditDocument}
					edit
					submitFn={handleEdit}
				/>}
		</Container >
	)
}

export default PersonalDocuments
