import { SHIPPING_ON_THE_WAY_STATUSES } from "@/common/constants/shipping";
import { checkIsOrderEditable } from "@/common/functions/checkIsOrderEditable";

export const handleCheckAddOrder = (orders) =>
	!orders?.some((order) => checkIsOrderEditable(order)) &&
	!orders?.some((order) => checkOnTheWay(order));

export const handleCheckAdditionalOrder = (orders) =>
	!orders?.some((order) => checkIsOrderEditable(order)) &&
	orders?.some((order) => checkOnTheWay(order));

export const checkOnTheWay = (order) =>
	SHIPPING_ON_THE_WAY_STATUSES.includes(order?.shipping?.status); 