import React, { useEffect, useState } from "react";
import { PageWrapper } from "@/common/styles/Wrappers";
import ValidInvoicesSearchBar from "./components/validInvoicesSearchBar/ValidInvoicesSearchBar";
import ValidInvoicesTable from "./components/validInvoicesTable/ValidInvoicesTable";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  acceptCorrectionInvoice,
  acceptValidInvoice,
  generateCorrectionInvoicePreview,
  generateValidInoicePreview,
  getValidInvoiceCorrectionsByStatus,
  getValidInvoices,
  updateCorrectionInvoice,
  updateValidInvoiceTicket,
} from "@/API/repositories/tickets";
import Loading from "@/common/components/Loading";
import { getCompanies } from "@/API/repositories/company";
import { preventFn } from "@/common/functions/preventEvent";
import { getFileById } from "@/API/repositories/storedDocument";

const ValidInvoices = () => {
  const [validInvoicesTickets, setValidInvoicesTickets] = useState();
  const [visibleTickets, setVisibleTickets] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleSearch = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.marketId = selectedMarket.value;
    payload.status = selectedStatus.value;
    payload.companyId = selectedCompany.value;

    const response = await makeRequest(getValidInvoices.bind(null, payload));

    if (!response?.data) {
      setValidInvoicesTickets(() => null);
      setSelectedTicket(() => null);
      return;
    }

    setValidInvoicesTickets(() => response.data);
    setSelectedTicket(() =>
      response.data.find((ticket) => ticket._id === selectedTicket?._id)
    );
  };

  const handleSearchCorrectionsByStatus = async (e) => {
    e && preventFn(e);

    const response = await makeRequest(getValidInvoiceCorrectionsByStatus.bind(null, selectedStatus.value));

    if (!response?.data) {
      setValidInvoicesTickets(() => null);
      setSelectedTicket(() => null);
      return;
    }

    setValidInvoicesTickets(() => response.data);
    setSelectedTicket(() =>
      response.data.find((ticket) => ticket._id === selectedTicket?._id)
    );
  };

  const resetData = () => {
    setSelectedTicket(() => null);
    setValidInvoicesTickets(() => null);
  };

  useEffect(() => {
    resetData();
  }, [selectedMarket, selectedStatus]);

  return (
    <PageWrapper>
      {hasUnfilledRequest(
        getValidInvoices,
        updateValidInvoiceTicket,
        generateValidInoicePreview,
        acceptValidInvoice,
        getFileById,
        updateCorrectionInvoice,
        generateCorrectionInvoicePreview,
        acceptCorrectionInvoice,
        getCompanies
      ) && <Loading />}
      <ValidInvoicesSearchBar
        validInvoicesTickets={validInvoicesTickets}
        setVisibleTickets={setVisibleTickets}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleSearch={handleSearch}
        selectedMarket={selectedMarket}
        setSelectedMarket={setSelectedMarket}
        handleSearchCorrectionsByStatus={handleSearchCorrectionsByStatus}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
      {!!validInvoicesTickets?.length && (
        <ValidInvoicesTable
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          handleSearch={handleSearch}
          selectedStatus={selectedStatus}
          tickets={visibleTickets}
        />
      )}
    </PageWrapper>
  );
};

export default ValidInvoices;
