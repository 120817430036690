import moment from "moment";
import React, { useRef, useState } from "react";
import { getNotPickUpCalls } from "../../../API/repositories/reports";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import { WrapperWithScroll } from "@/common/styles/Wrappers";
import NotPickUpSearchBar from "./components/notPickUpSearchBar/NotPickUpSearchBar";
import NotPickUpTable from "./components/notPickUpTable/NotPickUpTable";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";

const NotPickUpCalls = () => {
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [data, setData] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const dateFromRef = useRef();
  const dateToRef = useRef();

  const hanldeSearch = async (e) => {
    e && e.preventDefault();
    const today = moment();

    if (!selectedMarkets.length) {
      addMessage("Select markets", "error");

      return;
    }

    const payload = {};
    payload.from = dateFromRef.current?.value || today;
    payload.to = dateToRef.current?.value || today;
    payload.markets = selectedMarkets.map((market) => market.value);

    payload.from = moment(payload.from).startOf("date").toISOString();
    payload.to = moment(payload.to).endOf("date").toISOString();

    const response = await makeRequest(getNotPickUpCalls.bind(null, payload));

    if (response.data && !response.data.length) {
      addMessage("Not found");
      setData(() => null);

      return;
    }

    if (response.data) {
      setData(() => response.data);
    }
  };

  return (
    <WrapperWithScroll>
      {hasUnfilledRequest(getNotPickUpCalls) && <Loading />}
      <NotPickUpSearchBar
        dateFromRef={dateFromRef}
        dateToRef={dateToRef}
        setSelectedMarkets={setSelectedMarkets}
        selectedMarkets={selectedMarkets}
        hanldeSearch={hanldeSearch}
      />
      {data && <NotPickUpTable data={data} />}
    </WrapperWithScroll>
  );
};

export default NotPickUpCalls;
