import Table from '@/common/components/Table'
import React from 'react'
import { HEADERS } from './constants/headers'
import { getRaws } from './helpers/getRaws'

const ContactsRoadTable = ({ data }) => {
  return (
    <Table className="styled-table" raws={getRaws(data)} headers={HEADERS} />
  )
}

export default ContactsRoadTable
