import React, { useEffect, useMemo, useState } from "react";
import { AddOrdersWrapper, CardWrapper, ContactTableWrapper, PromotionResultTitle, Wrapper } from "./ConsultantContactInbound.styled";
import userManager from "@/API/userManager";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import moment from "moment";
import { getContactWithOrders, saveConsultantContact } from "@/API/repositories/contact";
import { createSuspicionTicket } from "@/API/repositories/tickets";
import Loading from "@/common/components/Loading";
import ContactForm from "./components/contactForm/ContactForm";
import Card from "@/common/components/Card";
import { Colors } from "@/common/colors/colors";
import { handleCheckAdditionalOrder, handleCheckAddOrder, isCzechWithoutPromotion } from "./helpers/orderCheckers";
import ActionButton from "@/common/components/buttons/ActionButton";
import Comments from "@/pages/consultant/contact/comments/Comments";
import PopUp from "@/common/components/PopUp";
import OrderEditInbound from "@/pages/consultant/consultantContactInbound/components/orderEditInbound/OrderEditInbound";
import AdditionalOrderCreate from "@/common/components/additionalOrderCreate/AdditionalOrderCreate";
import { checkOnTheWay } from "@/common/functions/checkOnTheWay";
import MailsTimeline from "@/common/components/timelines/mailsTimeline/MailsTimeline";
import ShippingTimeline from "@/common/components/timelines/shippingTimeline/ShippingTimeline";
import { getProductByUserQueue } from "@/API/repositories/product";
import { saveInboundCall } from "@/API/repositories/queue";
import { HEADERS } from "./constants/headers";
import Table from "@/common/components/Table";
import { getRaws } from "./helpers/getRaws";

const ConsultantContactInbound = () => {
	const [country, setCountry] = useState();
	const [create, setCreate] = useState(false);
	const [dataToDisplay, setDataToDisplay] = useState(null);
	const [promotionResult, setPromotionResult] = useState();
	const [mailsTimeline, setMailsTimeline] = useState();
	const [shippingTimeline, setShippingTimeline] = useState();
	const [showAdditionalOrderCreate, setShowAdditionalOrderCreate] = useState();
	const [show, setShow] = useState(false);
	const [order, setOrder] = useState({});

	const user = userManager?.getUser();

	const { id } = useParams();
	const navigate = useNavigate();
	const { makeRequest, hasUnfilledRequest } = useRequestsContext();
	const { COUNTRIES } = useCommonDataContext();
	const {
		commonData: { markets },
	} = useCommonDataContext();

	const market = useMemo(() => {
		return markets.find((m) => m.short === dataToDisplay?.country);
	}, [dataToDisplay]);


	const canEnd = useMemo(() => {
		const threshold = moment().subtract(20, "minutes").utc();
		let isCommentAddedByUser =
			dataToDisplay?._comments.find((c) => {
				const commentTimeUTC = moment(c.created_at).utc();
				return c.user === user.id && threshold.isBefore(commentTimeUTC);
			}) || false;

		return !!isCommentAddedByUser;
	}, [dataToDisplay, user.id]);

	const handleEndInboundContact = async (e) => {
		e.preventDefault();

		const response = await makeRequest(saveInboundCall);

		if (response.data) {
			navigate("/consultant/dashboard");
		}
	};

	const handleShowOrderEdit = (e, data, create) => {
		e.preventDefault();
		setCreate(() => create);
		setOrder(() => data);
		setShow(() => true);
	};

	const handleGetContactWithOrders = async () => {
		const response = await makeRequest(getContactWithOrders.bind(null, id));
		const responseProduct = await makeRequest(
			getProductByUserQueue.bind(null, userManager.getUser().id)
		);

		if (response.data && responseProduct.data) {
			setCountry(
				COUNTRIES.find((country) => country.value === response.data.country)
			);

			setDataToDisplay(() => null);
			setDataToDisplay(() => response.data);
		} else {
			setDataToDisplay(() => null);
		}
	};

	const checkExtraCondition = (order) => {
		return order?.shipping?.status === 'return'
			&& !order?.payment?.length
			&& order?._product?.short === 'er';
	};

	useEffect(() => {
		handleGetContactWithOrders();
	}, [show]);

	return (
		<Wrapper>
			{hasUnfilledRequest(
				saveConsultantContact,
				getContactWithOrders,
				createSuspicionTicket
			) && <Loading />}
			{dataToDisplay && <CardWrapper>
				<ActionButton
					onClick={(e) => handleEndInboundContact(e)}
					disabled={!canEnd}
				>
					{canEnd
						? " End Inbound Contact "
						: "Please add a comment to end a contact"}
				</ActionButton>
				<ContactForm
					dataToDisplay={dataToDisplay}
					country={country}
					setCountry={setCountry}
					reload={handleGetContactWithOrders}
					setPromotionResult={setPromotionResult}
				/>
				<Card>
					<div style={{ width: "1000px" }}>
						<h3 style={{ color: Colors.darkBlue, marginBottom: "30px" }}>
							Orders:
						</h3>
						<ContactTableWrapper>
							<Table
								className="styled-table"
								headers={HEADERS}
								raws={getRaws({
									dataToDisplay,
									setMailsTimeline,
									setShippingTimeline,
									handleShowOrderEdit,
									checkExtraCondition,
								})}
							/>
						</ContactTableWrapper>
					</div>
					<AddOrdersWrapper>
						{handleCheckAdditionalOrder() && (
							<ActionButton
								onClick={() => setShowAdditionalOrderCreate(true)}
								disabled={isCzechWithoutPromotion()}
								defaultText="Add additional order"
							/>
						)}
						{handleCheckAddOrder() && (
							<ActionButton
								onClick={(e) => handleShowOrderEdit(e, {}, true)}
								disabled={isCzechWithoutPromotion()}
								defaultText="Add Order"
							/>
						)}
					</AddOrdersWrapper>
				</Card>
				{dataToDisplay && (
					<Card>
						<div style={{ width: "1000px" }}>
							<h3 style={{ color: Colors.darkBlue }}>Comments:</h3>
							<Comments
								comments={dataToDisplay?._comments || []}
								contactId={id}
								handleGetDawnContact={() => handleGetContactWithOrders()}
							/>
						</div>
					</Card>
				)}
				{promotionResult && (
					<PopUp setShow={setPromotionResult}>
						<PromotionResultTitle isGood={promotionResult.isGood}
						>
							{promotionResult.message}
						</PromotionResultTitle>
					</PopUp>
				)}
			</CardWrapper>}
			{show && (
				<OrderEditInbound
					comments={dataToDisplay?._comments}
					inbound={true}
					order={order}
					setShow={setShow}
					setOrder={setOrder}
					contact={dataToDisplay}
					country={country}
					market={market}
					create={create}
				/>
			)}
			{showAdditionalOrderCreate && (
				<AdditionalOrderCreate
					setShow={setShowAdditionalOrderCreate}
					orderById={
						dataToDisplay?.orders?.find((order) => checkOnTheWay(order))?._id
					}
					reloadFn={handleGetContactWithOrders}
				/>
			)}
			{mailsTimeline?.length && (
				<MailsTimeline setShow={setMailsTimeline} mails={mailsTimeline} />
			)}
			{shippingTimeline && (
				<ShippingTimeline
					setShow={setShippingTimeline}
					shipping={shippingTimeline}
				/>
			)}
		</Wrapper>
	)
};

export default ConsultantContactInbound;
