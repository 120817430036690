import React from "react";
import moment from "moment";
import Card from "@/common/components/Card";
import { Colors } from "@/common/colors/colors";
import {
  ElementWrapper,
  Flex,
  InfoWrapper,
  NavigationWrapper,
  RestInfoWrapper
} from "./ContactView.styled";

const ContactView = ({ order }) => {
  return (
    <Card>
      <InfoWrapper>
        <h4 style={{ color: Colors.darkBlue, gap: "10px" }}>
          {order._contact?.full_name}, {order._contact.email},{" "}
          {order._contact.phone_number}
        </h4>
        <RestInfoWrapper>
          <div>
            <Flex>
              <ElementWrapper>Postal code:</ElementWrapper>{" "}
              {order._contact.postal_code ? order._contact.postal_code : "----"}
            </Flex>
            <Flex>
              <ElementWrapper>City:</ElementWrapper>{" "}
              {order._contact.city ? order._contact.city : "----"}
            </Flex>
            <Flex>
              <ElementWrapper>Street:</ElementWrapper>{" "}
              {order._contact.street ? order._contact.street : "----"}
            </Flex>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <Flex>
              <ElementWrapper>Country:</ElementWrapper> {order._contact.country}
            </Flex>
            <Flex>
              <ElementWrapper>Additional info:</ElementWrapper>{" "}
              {order._contact.additional_info
                ? order._contact.additional_info
                : "----"}
            </Flex>
          </div>
        </RestInfoWrapper>
      </InfoWrapper>
      <NavigationWrapper>
        <h6 style={{ color: Colors.darkBlue, margin: "0px" }}>
          {moment(order._contact.created_at).format("YYYY/MM/DD HH:mm")}
        </h6>
        <i
          className="fa fa-address-book animation-scale"
          style={{
            cursor: "pointer",
            color: Colors.darkBlue,
            fontSize: "16px",
          }}
          onClick={() => {
            window.open(`/dashboard/contact/${order._contact._id}`, {
              traget: "_blank",
            });
          }}
        ></i>
      </NavigationWrapper>
    </Card>
  );
};

export default ContactView;
