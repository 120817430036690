import React from "react";
import PopUp from "@/common/components/PopUp";
import { ButtonWrapper, Title } from "./AddToQueue.styled";
import ActionButton from "@/common/components/buttons/ActionButton";
import { Colors } from "@/common/colors/colors";

const AddToQueue = ({
  setConfirmAddToQueue,
  selectedToQueue,
  handleAddOrdersToQueue,
}) => {
  return (
    <PopUp setShow={setConfirmAddToQueue}>
      <Title>
        Do you want do add to queue {selectedToQueue.length} Orders?
      </Title>
      <ButtonWrapper>
        <ActionButton
          onClick={() => setConfirmAddToQueue(false)}
          defaultText="No"
        />
        <ActionButton
          color={Colors.green}
          disabled={selectedToQueue.length === 0}
          onClick={(e) => handleAddOrdersToQueue(e)}
          defaultText="Yes"
        />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AddToQueue;
