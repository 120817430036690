import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { useFormik } from "formik";
import React from "react";
import {
  InputsWrapper,
} from "./DynamicMailAttachmentForm.styled";
import { ButtonWrapper } from "../../DynamicMailAttachment.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { createDynamicMailAttachment, updateDynamicMailAttachment } from "@/API/repositories/dynamicMailAttachment";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import SubmitButton from "@/common/components/buttons/SubmitButton";

const DynamicMailAttachmentForm = ({
  setShow,
  selectedDynamicContent,
  reload,
  filesOptions,
}) => {
  const { options: { marketsOptions } } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const handleSubmit = async (values) => {
    const payload = {
      title: values.title,
      file: values.file.value,
      market: values.market.value,
    };

    const req = selectedDynamicContent ? updateDynamicMailAttachment : createDynamicMailAttachment;
    const response = await makeRequest(
      req.bind(null, payload, selectedDynamicContent?._id)
    );

    if (!response?.data) {
      return addMessage("Something went wrong", "error");
    }
  
    setShow(false);
    reload();
  };

  const formik = useFormik({
    initialValues: {
      title: selectedDynamicContent?.title || "",
      file: filesOptions?.find(file => file.value === selectedDynamicContent?.file) || "",
      market: marketsOptions?.find(market => market.value === selectedDynamicContent?.market) || "",
      //todo: dynamic_positions
    },
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <PopUp padding="10px 20px 15px" setShow={setShow}>
      <form onSubmit={formik.handleSubmit}>
        <InputsWrapper>
          <Input
            name="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            color={Colors.darkBlue}
            id="title"
            required
            width={70}
            inputWidth={400}
          />
          <SelectInput
            name="File"
            selected={formik.values.file}
            setSelected={(value) => formik.setFieldValue("file", value)}
            options={filesOptions}
            color={Colors.darkBlue}
            id="file"
            required
            hideStar
            width={70}
            selectWidth={400}
          />
          <SelectInput
            name="Market"
            selected={formik.values.market}
            setSelected={(value) => formik.setFieldValue("market", value)}
            options={marketsOptions}
            color={Colors.darkBlue}
            id="market"
            required
            hideStar
            width={70}
            selectWidth={400}
          />
        </InputsWrapper>
        <ButtonWrapper>
          <SubmitButton text="Save" />
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default DynamicMailAttachmentForm;
