import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import moment from "moment";
import SelectInput from "../../../common/components/SelectInput";
import { Colors } from "../../../common/colors/colors";
import Input from "../../../common/components/Input";
import { formatToDateTamplate } from "../../../common/functions/dateFormater";
import Loading from "../../../common/components/Loading";
import { useRequestsContext } from "../../../common/hooks/requestHook";
import {
  getScoringReport,
  updateListeningScoring,
} from "../../../API/repositories/listeningScoring";
import PopUp from "../../../common/components/PopUp";
import { useCommonDataContext } from "../../../common/hooks/commonDataContext";
import TextArea from "@/common/components/TextArea";
import { Wrapper, SearchBar, ButtonWrapper } from "./listeningScores.styles";
import TableConsultantScores from "./components/TableConsultantScores";
import TableManagerScores from "./components/TableManagerScores";
import Scores from "./components/Scores";
import ImporvementTicket from "./components/ImporvementTicket";
import { SCORING_OPTIONS } from "@/common/constants/scoringOptions";
import { scoreToOption } from "./helpers/scoreToOption";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import ActionButton from "@/common/components/buttons/ActionButton";

const ListeningScores = () => {
  const [showEditCurrent, setShowEditCurrent] = useState();
  const [managersOptions, setManagersOptions] = useState([]);
  const [showEdit, setShowEdit] = useState();
  const [showTicketTalkingImprovement, setShowTicketTalkingImprovement] =
    useState();
  const [editScoring, setEditScoring] = useState();

  const [data, setData] = useState(null);
  const [managerData, setManagerData] = useState(null);
  const {
    filterUsersByRoles,
    commonData: { users },
    queryValues: { isFetching },
  } = useCommonDataContext();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const handleSearch = async (values) => {
    values.fromDate = moment(values.fromDate).startOf("day").toISOString();
    values.toDate = moment(values.toDate).endOf("day").toISOString();

    const payload = {
      fromDate: moment(values.fromDate).startOf("day").toISOString(),
      toDate: moment(values.toDate).endOf("day").toISOString(),
      managers: values?.managers?.map((c) => c.value),
    };

    const response = await makeRequest(getScoringReport.bind(null, payload));

    if (response.data) {
      const scoreResults = response.data.reduce((prev, next) => {
        prev[next._user.username] = [
          ...(prev[next?._user.username] || []),
          next,
        ];
        return prev;
      }, {});

      const scoreManagerResults = response.data.reduce((prev, next) => {
        prev[next._marked_by.username] = [
          ...(prev[next?._marked_by.username] || []),
          next,
        ];
        return prev;
      }, {});

      setData(() => scoreResults);
      setManagerData(() => scoreManagerResults);
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: formatToDateTamplate(new Date()),
      toDate: formatToDateTamplate(new Date()),
      managers: [],
    },
    onSubmit: (values) => handleSearch(values),
  });

  const laodData = async () => {
    setManagersOptions(() =>
      filterUsersByRoles(["CALL_CENTER_MANAGER", "HEAD_CC_MANAGER"])
    );
  };

  const handleUpdateOnChange = (id, option) => {
    const toChange = editScoring.find((s) => s.score_id === id) || {};

    if (!toChange.grade) {
      toChange.label = "Additional Info";
      toChange.score_id = id;
    }

    toChange.grade = option.value;

    setEditScoring((prev) => [
      ...(prev.filter((c) => c.score_id !== id) || []),
      toChange,
    ]);
  };

  const handleUpdateScore = async (e) => {
    const payload = {};
    payload._id = showEditCurrent._id;
    payload.scores = editScoring;

    const response = await makeRequest(
      updateListeningScoring.bind(null, payload)
    );

    if (response.data) {
      await formik.submitForm();
      setShowEditCurrent(() => null);
      setShowEdit(() => null);
    }
  };
  const { addMessage } = useMessageQueueContext();

  useEffect(() => {
    laodData();
  }, [users]);

  return (
    <>
      {(hasUnfilledRequest(getScoringReport, updateListeningScoring) ||
        isFetching) && <Loading />}
      <Wrapper>
        <SearchBar>
          <Input
            type="date"
            name={"From"}
            id="fromDate"
            value={formik.values.fromDate}
            onChange={formik.handleChange}
            width={60}
            inputWidth={130}
            color={Colors.darkBlue}
          />
          <Input
            type="date"
            name={"To"}
            id="toDate"
            value={formik.values.toDate}
            onChange={formik.handleChange}
            width={20}
            inputWidth={130}
            color={Colors.darkBlue}
          />
          <SelectInput
            name={"Managers"}
            id="managers"
            selected={formik.values.managers}
            setSelected={(value) => formik.setFieldValue("managers", value)}
            options={managersOptions}
            width={70}
            multiple={true}
            selectWidth={220}
            color={Colors.darkBlue}
          />
          <ButtonWrapper>
            <ActionButton defaultText="Search" onClick={formik.handleSubmit} />
          </ButtonWrapper>
        </SearchBar>
        {data && (
          <TableConsultantScores data={data} setShowEdit={setShowEdit} />
        )}
        {managerData && (
          <TableManagerScores
            managerData={managerData}
            setShowEdit={setShowEdit}
          />
        )}
      </Wrapper>
      {showEdit && (
        <Scores
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          setShowTicketTalkingImprovement={setShowTicketTalkingImprovement}
          setShowEditCurrent={setShowEditCurrent}
          setEditScoring={setEditScoring}
        />
      )}
      {showEditCurrent && (
        <PopUp setShow={setShowEditCurrent}>
          <table className="styled-table">
            <thead>
              <tr>
                <th colSpan={2}>
                  Rating Call - Yes = 1, No = 0 - Explain here if 0 or not
                  applicable (na)
                </th>
              </tr>
              <tr>
                <th>Description</th>
                <th>Mark</th>
              </tr>
            </thead>
            <tbody className="queue">
              {showEditCurrent.scores
                .filter((element) => element.score_id !== "additional_info")
                .map((element, i) => (
                  <tr key={element.score_id + i}>
                    <td>{element.score_id}</td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <SelectInput
                        showLabel={false}
                        selectWidth={160}
                        selected={scoreToOption(
                          editScoring.find(
                            (savedScore) =>
                              savedScore.score_id === element.score_id
                          )
                        )}
                        setSelected={handleUpdateOnChange.bind(
                          this,
                          element.score_id
                        )}
                        id={element.score_id}
                        options={SCORING_OPTIONS}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td>Additional Info</td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <TextArea
                    width="400px"
                    onChange={(e) =>
                      handleUpdateOnChange("additional_info", e.target)
                    }
                    id={"additional_info"}
                    defaultValue={
                      showEditCurrent.scores.find(
                        (s) => s.score_id === "additional_info"
                      )?.grade
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "30px",
              marginBottom: "-20px",
            }}
          >
            <ActionButton
              defaultText="Update"
              onClick={() => handleUpdateScore()}
            />
          </div>
        </PopUp>
      )}{" "}
      {showTicketTalkingImprovement && (
        <ImporvementTicket
          showTicketTalkingImprovement={showTicketTalkingImprovement}
          setShowTicketTalkingImprovement={setShowTicketTalkingImprovement}
        />
      )}
    </>
  );
};

export default ListeningScores;
