import { Colors } from '@/common/colors/colors';
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;

export const Flex = styled.div`
  display: grid;
  grid-template-columns: 36fr 64fr;
  width: 100%;
  gap: 30px;
  padding: 30px;
`;

export const ContactWrapper = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
`;

export const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: scroll;
`;

export const Container = styled.div`
	background-color: ${Colors.lightlightBlue};
	width: 100vw;
	padding-top: 30px;
`;

export const ActionButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	align-items: center;
	width: fit-content;
`;
export const ContentBodyWrapper = styled.div`
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	gap: 20px;
`;

