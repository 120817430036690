import React from "react";
import CSV from "../CSV";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";

const StatisticsGradeTable = ({
  grade,
  dontShowDescription,
  name,
  csv = false,
  newConsultant,
}) => {
  const GRADE_COLOR =
    grade.wppkSum.grade > 2
      ? grade.wppkSum.grade > 4
        ? grade.wppkSum.grade > 6
          ? grade.wppkSum.grade > 8
            ? "#469E2B"
            : "#93DB41"
          : "#EEF743"
        : "#F7AF43"
      : "#FC593C";

  const { translation } = useTranslationContext();

  const getCSVdata = () => {
    const result = [];

    grade.wppk
      .sort((a, b) => parseInt(a.dequeueCount) - parseInt(b.dequeueCount))
      .map((row) => {
        const temp = [];

        temp.push(row.dequeueCount);
        temp.push(row.downloaded);
        temp.push(row.costs);
        temp.push(row.deliverd);
        temp.push(row.deliveredSum);
        temp.push(row.wppk);
        temp.push(row.grade);

        result.push(temp);
      });

    const temp = [];

    temp.push("Total");
    temp.push(grade.wppkSum.downloadedSum);
    temp.push(grade.wppkSum.costsSum);
    temp.push(grade.wppkSum.deliverdSum);
    temp.push(grade.wppkSum.deliveredValueSum);
    temp.push(grade.wppkSum.wppk);
    temp.push(grade.wppkSum.grade);

    result.push(temp);

    return result;
  };

  const textFourField = newConsultant ? "textFour_new" : "textFour";
  const textFiveField = newConsultant ? "textFive_new" : "textFive";

  return (
    <div
      style={{
        width: "50vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {name && (
        <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
          {name()}{" "}
          {csv && (
            <CSV
              filename={`${name()}.csv`}
              header={[
                "Dequeue number",
                "Downloaded",
                "Cost",
                "Delivered",
                "Delivered sum",
                "Grade",
              ]}
              data={getCSVdata()}
            />
          )}
        </h4>
      )}
      <table className="styled-table">
        <thead>
          <tr>
            <th> {translation["textOne"]} </th>
            <th> {translation["textTwo"]}</th>
            <th> {translation["textThree"]} </th>
            <th> {translation[textFourField]} </th>
            <th> {translation[textFiveField]} </th>
            <th> CUR </th>
            <th> {translation["textSix"]} </th>
          </tr>
        </thead>
        <tbody className="queue">
          {grade.wppk
            .sort((a, b) => parseInt(a.dequeueCount) - parseInt(b.dequeueCount))
            .map((row) => {
              return (
                <tr style={{ borderTop: "1px solid grey" }}>
                  <td>{row.dequeueCount} </td>
                  <td>{row.downloaded} </td>
                  <td>{row.costs} </td>
                  <td>{row.deliverd} </td>
                  <td>{row.deliveredSum}</td>
                  <td>{row.wppk} </td>
                  <td>{row.grade}</td>
                </tr>
              );
            })}
          <tr style={{ background: "lightGrey" }}>
            <td> Total </td>
            <td>{grade.wppkSum.downloadedSum} </td>
            <td>{grade.wppkSum.costsSum}</td>
            <td>{grade.wppkSum.deliverdSum}</td>
            <td>{grade.wppkSum.deliveredValueSum}</td>
            <td>{grade.wppkSum.wppk}</td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: GRADE_COLOR,
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {grade.wppkSum.grade}
            </td>
          </tr>
        </tbody>
      </table>
      {!dontShowDescription && (
        <p style={{ marginTop: "20px" }}>{translation["desctiption"]}</p>
      )}
    </div>
  );
};

export default StatisticsGradeTable;
